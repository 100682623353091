<template>
  <div class="app-page" @touchstart.passive="onTouchStart()">
    <!-- <div class="page" :style="pageStyles">-->
    <div ref="outsideLeft" class="outside">
      <TheSquareCanvas
        v-if="outsideLeft"
        :x="0"
        :y="0"
        :width="outsideLeftSize[0]"
        :height="outsideLeftSize[1]"
        color="black"
      />
    </div>
    <div class="page">
      <slot />
    </div>
    <div ref="outsideRight" class="outside">
      <TheSquareCanvas
        v-if="outsideRight"
        :x="0"
        :y="0"
        :width="outsideRightSize[0]"
        :height="outsideRightSize[1]"
        color="black"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
// TODO: 不要そうなら消す
// import { StyleValue } from 'vue';
// import { useResize } from '~/composables/useResize';

// const pageStyles = ref<StyleValue>();
// // 指定サイズ以下であれば自動でscaleさせる
// const scaleMaxAspect = useRuntimeConfig().public.layouts.scaleMaxAspect;
// useResize((rate) => {
//   const height = rate >= 1 ? '100vh' : Math.min(Math.ceil(screen.height / rate), screen.width / scaleMaxAspect) + 'px';
//   pageStyles.value = {
//     transform: `scale(${rate})`,
//     height,
//   };
// });
const onTouchStart = () => {
  // スマホでのCSS:activeを動作させるために入れているので、特に何もしない
};

const outsideLeft = ref<HTMLDivElement>();
const outsideRight = ref<HTMLDivElement>();

/**
 * 画面左の黒帯のサイズを取得する(width, height)
 */
const outsideLeftSize = computed(() => {
  if (outsideLeft.value) {
    return [outsideLeft.value.clientWidth, outsideLeft.value.clientHeight];
  }
  return [0, 0];
});

/**
 * 画面右の黒帯のサイズを取得する(width, height)
 */
const outsideRightSize = computed(() => {
  if (outsideRight.value) {
    return [outsideRight.value.clientWidth, outsideRight.value.clientHeight];
  }
  return [0, 0];
});
</script>

<style lang="scss" scoped>
@use "assets/css/base" as base;
@use "assets/css/mixin" as mixin;

.app-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.page {
  width: 100%;
  max-width: base.$desktop-width;
  //max-height: base.aspect-height(base.$mobile-max-aspect);
  margin: 0 auto;
  position: relative;

  @include base.window-height();

  // TODO: 不要そうなら消す
  //@include base.mobile-max() {
  //  width: base.$mobile-width;
  //  //height: base.aspect-height(base.$mobile-max-aspect);
  //  position: absolute;
  //  left: 0;
  //  right: 0;
  //  top: 0;
  //  bottom: 0;
  //  margin: auto;
  //  max-width: none;
  //  max-height: none;
  //  overflow: hidden;
  //  transform-origin: left center;
  //}

  //@include base.tablet() {
  //  max-height: base.aspect-height(base.$tablet-max-aspect);
  //}

  //@include base.desktop() {
  //  max-height: none;
  //}
}

// 黒帯
.outside {
  display: none;
  height: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;
  z-index: 800;
  // タブレットの時のみ有効
  @include mixin.tablet() {
    display: block;
  }
}
</style>
