/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCharaSave
 */
export interface UserCharaSave {
    /**
     * プレゼントアイテムコード
     * @type {string}
     * @memberof UserCharaSave
     */
    presentItemCd: string;
    /**
     * 現在のチュートリアルコード
     * @type {string}
     * @memberof UserCharaSave
     */
    currentTutorialCd: string;
}

/**
 * Check if a given object implements the UserCharaSave interface.
 */
export function instanceOfUserCharaSave(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "presentItemCd" in value;
    isInstance = isInstance && "currentTutorialCd" in value;

    return isInstance;
}

export function UserCharaSaveFromJSON(json: any): UserCharaSave {
    return UserCharaSaveFromJSONTyped(json, false);
}

export function UserCharaSaveFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCharaSave {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presentItemCd': json['present_item_cd'],
        'currentTutorialCd': json['current_tutorial_cd'],
    };
}

export function UserCharaSaveToJSON(value?: UserCharaSave | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present_item_cd': value.presentItemCd,
        'current_tutorial_cd': value.currentTutorialCd,
    };
}

