/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SelectRoute } from './SelectRoute';
import {
    SelectRouteFromJSON,
    SelectRouteFromJSONTyped,
    SelectRouteToJSON,
} from './SelectRoute';
import type { VoiceList } from './VoiceList';
import {
    VoiceListFromJSON,
    VoiceListFromJSONTyped,
    VoiceListToJSON,
} from './VoiceList';

/**
 * 
 * @export
 * @interface EventChara
 */
export interface EventChara {
    /**
     * キャラコード
     * @type {string}
     * @memberof EventChara
     */
    charaCd: string;
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof EventChara
     */
    eventCharaCd: string;
    /**
     * イベントルートコード
     * @type {string}
     * @memberof EventChara
     */
    eventRouteCd: string;
    /**
     * ボイスリスト
     * @type {Array<VoiceList>}
     * @memberof EventChara
     */
    voiceList: Array<VoiceList>;
    /**
     * プレビュー
     * @type {string}
     * @memberof EventChara
     */
    preview: string;
    /**
     * プロローグチケットコード
     * @type {string}
     * @memberof EventChara
     */
    prologueTicketCd: string;
    /**
     * 
     * @type {SelectRoute}
     * @memberof EventChara
     */
    selectRoute: SelectRoute;
}

/**
 * Check if a given object implements the EventChara interface.
 */
export function instanceOfEventChara(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "eventRouteCd" in value;
    isInstance = isInstance && "voiceList" in value;
    isInstance = isInstance && "preview" in value;
    isInstance = isInstance && "prologueTicketCd" in value;
    isInstance = isInstance && "selectRoute" in value;

    return isInstance;
}

export function EventCharaFromJSON(json: any): EventChara {
    return EventCharaFromJSONTyped(json, false);
}

export function EventCharaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventChara {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'eventCharaCd': json['event_chara_cd'],
        'eventRouteCd': json['event_route_cd'],
        'voiceList': ((json['voice_list'] as Array<any>).map(VoiceListFromJSON)),
        'preview': json['preview'],
        'prologueTicketCd': json['prologue_ticket_cd'],
        'selectRoute': SelectRouteFromJSON(json['select_route']),
    };
}

export function EventCharaToJSON(value?: EventChara | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'event_chara_cd': value.eventCharaCd,
        'event_route_cd': value.eventRouteCd,
        'voice_list': ((value.voiceList as Array<any>).map(VoiceListToJSON)),
        'preview': value.preview,
        'prologue_ticket_cd': value.prologueTicketCd,
        'select_route': SelectRouteToJSON(value.selectRoute),
    };
}

