/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPresentDeleteResponseData
 */
export interface UserPresentDeleteResponseData {
    /**
     * 削除前のユーザーのゲーム内通貨
     * @type {number}
     * @memberof UserPresentDeleteResponseData
     */
    beforeGold: number;
    /**
     * 削除後のユーザーのゲーム内通貨
     * @type {number}
     * @memberof UserPresentDeleteResponseData
     */
    afterGold: number;
}

/**
 * Check if a given object implements the UserPresentDeleteResponseData interface.
 */
export function instanceOfUserPresentDeleteResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "beforeGold" in value;
    isInstance = isInstance && "afterGold" in value;

    return isInstance;
}

export function UserPresentDeleteResponseDataFromJSON(json: any): UserPresentDeleteResponseData {
    return UserPresentDeleteResponseDataFromJSONTyped(json, false);
}

export function UserPresentDeleteResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresentDeleteResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'beforeGold': json['before_gold'],
        'afterGold': json['after_gold'],
    };
}

export function UserPresentDeleteResponseDataToJSON(value?: UserPresentDeleteResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'before_gold': value.beforeGold,
        'after_gold': value.afterGold,
    };
}

