/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * マイアバターリスト
 * @export
 * @interface EquipMyAvatarList
 */
export interface EquipMyAvatarList {
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipMyAvatarList
     */
    userAvatarId: Array<number>;
}

/**
 * Check if a given object implements the EquipMyAvatarList interface.
 */
export function instanceOfEquipMyAvatarList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userAvatarId" in value;

    return isInstance;
}

export function EquipMyAvatarListFromJSON(json: any): EquipMyAvatarList {
    return EquipMyAvatarListFromJSONTyped(json, false);
}

export function EquipMyAvatarListFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipMyAvatarList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAvatarId': json['user_avatar_id'],
    };
}

export function EquipMyAvatarListToJSON(value?: EquipMyAvatarList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_avatar_id': value.userAvatarId,
    };
}

