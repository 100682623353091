/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventCharaChangeResponseData
 */
export interface UserEventCharaChangeResponseData {
    /**
     * 特典リスト
     * @type {Array<string>}
     * @memberof UserEventCharaChangeResponseData
     */
    bonusList?: Array<string>;
}

/**
 * Check if a given object implements the UserEventCharaChangeResponseData interface.
 */
export function instanceOfUserEventCharaChangeResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserEventCharaChangeResponseDataFromJSON(json: any): UserEventCharaChangeResponseData {
    return UserEventCharaChangeResponseDataFromJSONTyped(json, false);
}

export function UserEventCharaChangeResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventCharaChangeResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bonusList': !exists(json, 'bonus_list') ? undefined : json['bonus_list'],
    };
}

export function UserEventCharaChangeResponseDataToJSON(value?: UserEventCharaChangeResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bonus_list': value.bonusList,
    };
}

