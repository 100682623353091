import { FetchContext, FetchResponse } from 'ofetch';
import { Dexie } from 'dexie';
import { NitroFetchOptions } from 'nitropack';
import { useNuxtApp } from '#app';
import { ResourceDownloadError } from '~/libs/errors';
import { useLog } from '~/composables/useLog';
import { ApplicationDatabase, ResourceHashModel } from '~/configs/database';
import { escapeStringRegexp } from '~/libs/utils';
import { resourceConfig } from '~/configs/resource';
import { getResourceUrl } from '~/libs/platform';
import { useLoading } from '~/composables/useLoading';

const logGroup = 'Resource';
/**
 * Workbox用IndexedDBの定義
 */
interface CacheEntries {
  id: string;
  url: string;
  cacheName: string;
  timestamp: number;
}
const getCacheEntriesId = (cacheName: string, url: string) => {
  return `${cacheName}|${url}`;
};

export interface ResourceInterface{
  url: string,
  options?: NitroFetchOptions<string>,
}
export type Resource = string | ResourceInterface;
export type ResourceProgress = {
  loaded: number,
  failed: number,
  total: number,
};
type ResourceDownloadOption = {
  onProgress?: (event: ResourceProgress) => void,
  raw?: boolean,
};
/**
 * リソースダウンロード
 *
 * @param resources
 * @param options
 */
const resourceDownloads = async (resources: Resource[], options?: ResourceDownloadOption) => {
  let loaded = 0;
  let failed = 0;
  const total = resources.length;
  const resultAll = await Promise.all(resources.map((res) => {
    return (async () => {
      const url: string = (typeof res === 'string') ? res : res.url;
      const opts = (typeof res === 'string')
        ? {
          onRequestError (ctx: FetchContext & { error: Error }): Promise<void> {
            throw (new ResourceDownloadError(ctx.error, url));
          },
          onResponseError (ctx: FetchContext & { response: FetchResponse<ResponseType> }): Promise<void> {
            throw (new ResourceDownloadError(ctx.error, url));
          },
        } as NitroFetchOptions<string>
        : res.options;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const f: Promise<any> = options?.raw ? $fetch.raw(url, opts) : useFetch(url, opts);
      try {
        const result = await f;
        options?.onProgress?.({
          loaded: ++loaded,
          failed,
          total,
        });
        return result;
      } catch (e) {
        options?.onProgress?.({
          loaded,
          failed: ++failed,
          total,
        });
        throw e;
      }
    })();
  }));
  return resultAll.filter(i => i ?? false);
};
/**
 * リソースダウンロードを行う(自動でローディング処理)
 *
 * @param resources ダウンロードするリソースデータ
 * @param isRaw 生のデータを取得する場合はtrue
 * @param isLoading ローディング表示する場合
 */
export const useResourceDownloads = async (resources: Resource[], isRaw = false, isLoading = true) => {
  const { loader } = useLoading();
  let loadingKey: symbol|null = null;
  if (isLoading) {
    loadingKey = loader.start({
      isProgress: true,
      total: resources.length,
    });
  }
  try {
    return await resourceDownloads(resources, {
      onProgress: (event: ResourceProgress) => {
        if (loadingKey) {
          loader.setProgress(loadingKey, event.loaded);
        }
      },
      raw: isRaw,
    });
  } finally {
    if (loadingKey) {
      loader.stop(loadingKey);
    }
  }
};

interface Manifest {
  path: string,
  hash: string,
}

/**
 * キャッシュの管理
 */
class ManifestCacheManager {
  protected database: ApplicationDatabase;
  protected workerDB: Dexie;

  constructor (database: ApplicationDatabase) {
    this.database = database;
    this.workerDB = new Dexie(resourceConfig.dbName);
  }

  /**
   * マニフェストファイルの取得
   */
  protected async getManifest (): Promise<Manifest[]> {
    const manifest = (await useApi().master.getResourceManifest()).data;
    const data: Manifest[] = [];
    for (const manifestKey in manifest) {
      const manifestData = manifest[manifestKey];
      if (!manifestData.hash) {
        continue;
      }
      data.push({
        path: manifestKey,
        hash: manifestData.hash,
      });
    }
    // URLの長い順(深い階層)に並び替え/親階層の削除が不要なため
    data.sort((a, b) => a.path.length < b.path.length ? 1 : -1);
    return data;
  }

  /**
   * リソースキャッシュの削除
   * @param path
   */
  protected async clearResourceCache (path: string) {
    useLog(logGroup).debug('clear cache path.', path);
    // IndexedDBから対象のキャッシュを検索
    const url = `${getResourceUrl()}${path}`;
    for (const cacheKey of resourceConfig.cacheKeys) {
      if (!(await caches.has(cacheKey))) {
        continue;
      }
      const cacheFile = await caches.open(cacheKey);
      // キャッシュキーの前方一致テスト
      const idTest = new RegExp(`^${escapeStringRegexp(getCacheEntriesId(cacheKey, url))}`);
      // ServiceWorkerのIndexedDBから対象のキャッシュを検索
      const table = this.workerDB.table<CacheEntries, string>(resourceConfig.dbSchemaName);
      const results = await table
        .where('cacheName')
        .equals(cacheKey)
        .filter(v => idTest.test(v.id))
        .toArray();
      // 対象のキャッシュを全て削除
      results.map(async (v) => {
        await cacheFile.delete(v.url, {
          ignoreVary: true,
          ignoreMethod: true,
          ignoreSearch: true,
        });
        await table.delete(v.id);
        useLog(logGroup).debug('clear cache from manifest.', cacheKey, v.url);
      });
    }
  }

  /**
   * リソースハッシュの更新
   */
  public async updateResourceHash () {
    let existsCacheTable = true;
    if (!this.workerDB.isOpen()) {
      try {
        await this.workerDB.open();
      } catch (e) {
        // 開けない場合はindexedDBがないものとし、全削除
        existsCacheTable = false;
        await this.clearAll();
        useLog(logGroup).warn('clear all caches when open DB error.');
      }
    }
    // リソースマニュフェストを取得
    const manifest = await this.getManifest();
    const deleteCachePaths: string[] = [];
    const deleteProcess: Promise<void>[] = [];
    // indexDB上のハッシュ値を更新
    for (const manifestData of manifest) {
      const newHash: string = manifestData.hash;
      let deleteFlg = false;
      // 現在のハッシュ値を取得
      let data = await this.database.resourceHash.get(manifestData.path);
      if (!data) {
        data = new ResourceHashModel(manifestData.path, newHash);
      } else {
        deleteFlg = data.hash !== newHash;
        data.hash = newHash;
      }
      // キャッシュを削除する場合
      if (existsCacheTable && deleteFlg) {
        // 子階層のファイルを削除していたら削除させない
        if (!deleteCachePaths.find(v => v.match(new RegExp(`^${escapeStringRegexp(manifestData.path)}`)))) {
          // キャッシュの削除
          deleteProcess.push((async function (self: ManifestCacheManager) {
            await self.clearResourceCache(manifestData.path);
          })(this));
          // 削除済みとしてマーク
          deleteCachePaths.push(manifestData.path);
          useLog(logGroup).debug('Manifest path cache deleted', manifestData.path);
        }
      }
      // ハッシュの更新
      await this.database.resourceHash.put(data);
    }
    if (deleteProcess.length > 0) {
      // 並列処理にて実行
      await Promise.all(deleteProcess);
      useLog(logGroup).debug('Manifest all cache deleted');
    }
  }

  /**
   * キャッシュを削除
   */
  protected async clearCache (name: string) {
    return await caches.delete(name);
  }

  /**
   * 全リソースキャッシュを削除
   */
  public async clearAll () {
    // キャッシュに含まれるキーを全て探して削除する
    const keys = await caches.keys();
    await Promise.all(keys.map((key) => {
      if (!resourceConfig.cacheKeys.includes(key)) {
        return Promise.resolve();
      }
      useLog(logGroup).debug(`delete cache ${key}`);
      return caches.delete(key);
    }));
  }
}

// 更新したハッシュ値(複数回呼ばれるため)
let updatedResourceHash: string;
let isCacheApiEnable: boolean | undefined;
/**
 * リソースハッシュの保存/新しければ既存のキャッシュを削除
 */
export const saveResourceHash = async (hash: string) => {
  // Cache APIによるキャッシュの削除
  // Service Worker側でキャッシュされるためここで削除を行う
  if (isCacheApiEnable === false) {
    return;
  }
  if (isCacheApiEnable === undefined) {
    if (!('caches' in window)) {
      useLog(logGroup).warn('Cache API not found.');
      isCacheApiEnable = false;
      return;
    }
    isCacheApiEnable = true;
  }
  if (!hash || updatedResourceHash === hash) {
    return Promise.resolve();
  }
  // 一時的にハッシュを保存
  updatedResourceHash = hash;
  const { $database } = useNuxtApp();
  // 既存のハッシュ値
  const old = await $database.resourceHash.get('/');
  if (!old || old.hash !== hash) {
    const manager = new ManifestCacheManager($database);
    await manager.updateResourceHash();
    useLog(logGroup).debug('Manifest update hash.', hash);
  }
};
