/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CardFilterType } from './CardFilterType';
import {
    CardFilterTypeFromJSON,
    CardFilterTypeFromJSONTyped,
    CardFilterTypeToJSON,
} from './CardFilterType';
import type { CardMessage } from './CardMessage';
import {
    CardMessageFromJSON,
    CardMessageFromJSONTyped,
    CardMessageToJSON,
} from './CardMessage';

/**
 * 
 * @export
 * @interface Card
 */
export interface Card {
    /**
     * カードコード
     * @type {string}
     * @memberof Card
     */
    cardCd: string;
    /**
     * カード名
     * @type {string}
     * @memberof Card
     */
    name: string;
    /**
     * 獲得場所
     * @type {string}
     * @memberof Card
     */
    getSituation: string;
    /**
     * 
     * @type {CardFilterType}
     * @memberof Card
     */
    filterType: CardFilterType;
    /**
     * アニメーション
     * @type {boolean}
     * @memberof Card
     */
    animationFlg: boolean;
    /**
     * 画像ファイル名
     * @type {string}
     * @memberof Card
     */
    charaFileName: string;
    /**
     * パラメータ
     * @type {number}
     * @memberof Card
     */
    parameter: number;
    /**
     * 最大合成回数
     * @type {number}
     * @memberof Card
     */
    synthesisCountMax: number;
    /**
     * 1:イラスト変更あり 0:なし
     * @type {boolean}
     * @memberof Card
     */
    illustChangeFlg: boolean;
    /**
     * 追加ボイスがアンロックされる合成回数
     * @type {number}
     * @memberof Card
     */
    voiceUnlockCount: number;
    /**
     * キャラコード
     * @type {string}
     * @memberof Card
     */
    charaCd: string;
    /**
     * 開始日時
     * @type {string}
     * @memberof Card
     */
    start: string | null;
    /**
     * 終了日時
     * @type {string}
     * @memberof Card
     */
    end: string | null;
    /**
     * 表示順
     * @type {number}
     * @memberof Card
     */
    orderNo: number;
    /**
     * 短冊型表示時に使用する横方向のオフセット値
     * @type {number}
     * @memberof Card
     */
    slimOffsetX: number;
    /**
     * 短冊型表示時に使用する縦方向のオフセット値
     * @type {number}
     * @memberof Card
     */
    slimOffsetY: number;
    /**
     * スケーリング
     * @type {number}
     * @memberof Card
     */
    slimScaling: number;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof Card
     */
    resourcePartition: number;
    /**
     * メッセージリスト
     * @type {Array<CardMessage>}
     * @memberof Card
     */
    messages: Array<CardMessage>;
    /**
     * アイテムコード
     * @type {string}
     * @memberof Card
     */
    itemCd: string;
    /**
     * レアリティ
     * @type {number}
     * @memberof Card
     */
    rarity: number;
    /**
     * スチルコード
     * @type {string}
     * @memberof Card
     */
    stillCd: string;
}

/**
 * Check if a given object implements the Card interface.
 */
export function instanceOfCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "getSituation" in value;
    isInstance = isInstance && "filterType" in value;
    isInstance = isInstance && "animationFlg" in value;
    isInstance = isInstance && "charaFileName" in value;
    isInstance = isInstance && "parameter" in value;
    isInstance = isInstance && "synthesisCountMax" in value;
    isInstance = isInstance && "illustChangeFlg" in value;
    isInstance = isInstance && "voiceUnlockCount" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "start" in value;
    isInstance = isInstance && "end" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "slimOffsetX" in value;
    isInstance = isInstance && "slimOffsetY" in value;
    isInstance = isInstance && "slimScaling" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "messages" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "rarity" in value;
    isInstance = isInstance && "stillCd" in value;

    return isInstance;
}

export function CardFromJSON(json: any): Card {
    return CardFromJSONTyped(json, false);
}

export function CardFromJSONTyped(json: any, ignoreDiscriminator: boolean): Card {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardCd': json['card_cd'],
        'name': json['name'],
        'getSituation': json['get_situation'],
        'filterType': CardFilterTypeFromJSON(json['filter_type']),
        'animationFlg': json['animation_flg'],
        'charaFileName': json['chara_file_name'],
        'parameter': json['parameter'],
        'synthesisCountMax': json['synthesis_count_max'],
        'illustChangeFlg': json['illust_change_flg'],
        'voiceUnlockCount': json['voice_unlock_count'],
        'charaCd': json['chara_cd'],
        'start': json['start'],
        'end': json['end'],
        'orderNo': json['order_no'],
        'slimOffsetX': json['slim_offset_x'],
        'slimOffsetY': json['slim_offset_y'],
        'slimScaling': json['slim_scaling'],
        'resourcePartition': json['resource_partition'],
        'messages': ((json['messages'] as Array<any>).map(CardMessageFromJSON)),
        'itemCd': json['item_cd'],
        'rarity': json['rarity'],
        'stillCd': json['still_cd'],
    };
}

export function CardToJSON(value?: Card | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_cd': value.cardCd,
        'name': value.name,
        'get_situation': value.getSituation,
        'filter_type': CardFilterTypeToJSON(value.filterType),
        'animation_flg': value.animationFlg,
        'chara_file_name': value.charaFileName,
        'parameter': value.parameter,
        'synthesis_count_max': value.synthesisCountMax,
        'illust_change_flg': value.illustChangeFlg,
        'voice_unlock_count': value.voiceUnlockCount,
        'chara_cd': value.charaCd,
        'start': value.start,
        'end': value.end,
        'order_no': value.orderNo,
        'slim_offset_x': value.slimOffsetX,
        'slim_offset_y': value.slimOffsetY,
        'slim_scaling': value.slimScaling,
        'resource_partition': value.resourcePartition,
        'messages': ((value.messages as Array<any>).map(CardMessageToJSON)),
        'item_cd': value.itemCd,
        'rarity': value.rarity,
        'still_cd': value.stillCd,
    };
}

