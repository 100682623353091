import { useAppBack } from '~/composables/useAppBack';

const logGroup = 'Debug';
const debugKey = {
  left: 'ArrowLeft',
};
/**
 * デバッグ用のキーイベント
 */
const debugKeyEvent = (event: KeyboardEvent) => {
  // devでもテストできるようにバックボタンを追加
  if (event.key === debugKey.left) {
    useLog(logGroup).warn('call useAppBack()');
    useAppBack();
  }
};

export default defineNuxtPlugin((_) => {
  if (process.dev) {
    useLog(logGroup).warn(`inject debug key event. ${debugKey.left}: appBack`);
    // デバッグ用にキーイベントを追加
    document.addEventListener('keydown', debugKeyEvent, false);
  }
});
