/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * カテゴリーのタイプ。〇〇に用いられる。
 * @export
 */
export const ItemCategoryType = {
    Nml: 'nml',
    Rav: 'rav',
    Mav: 'mav',
    Pav: 'pav',
    Cex: 'cex',
    Freegem: 'freegem',
    Gold: 'gold',
    Card: 'card',
    Letter: 'letter',
    Set: 'set',
    Gacha: 'gacha',
    Story: 'story'
} as const;
export type ItemCategoryType = typeof ItemCategoryType[keyof typeof ItemCategoryType];


export function ItemCategoryTypeFromJSON(json: any): ItemCategoryType {
    return ItemCategoryTypeFromJSONTyped(json, false);
}

export function ItemCategoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemCategoryType {
    return json as ItemCategoryType;
}

export function ItemCategoryTypeToJSON(value?: ItemCategoryType | null): any {
    return value as any;
}

