/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PresentType } from './PresentType';
import {
    PresentTypeFromJSON,
    PresentTypeFromJSONTyped,
    PresentTypeToJSON,
} from './PresentType';

/**
 * 
 * @export
 * @interface UserMinigameBonusPresent
 */
export interface UserMinigameBonusPresent {
    /**
     * プレゼントアイテムコード
     * @type {string}
     * @memberof UserMinigameBonusPresent
     */
    presentItemCd: string;
    /**
     * 
     * @type {PresentType}
     * @memberof UserMinigameBonusPresent
     */
    type: PresentType;
    /**
     * アイテムコード
     * @type {string}
     * @memberof UserMinigameBonusPresent
     */
    itemCd: string;
    /**
     * カードコード
     * @type {string}
     * @memberof UserMinigameBonusPresent
     */
    cardCd: string;
    /**
     * アバターコード
     * @type {string}
     * @memberof UserMinigameBonusPresent
     */
    avatarCd?: string;
    /**
     * アイテム名
     * @type {string}
     * @memberof UserMinigameBonusPresent
     */
    itemName: string;
    /**
     * アイテム個数
     * @type {number}
     * @memberof UserMinigameBonusPresent
     */
    itemCount: number;
    /**
     * 説明文字列
     * @type {string}
     * @memberof UserMinigameBonusPresent
     */
    description: string;
    /**
     * リソースアイテムコード
     * @type {string}
     * @memberof UserMinigameBonusPresent
     */
    resourceItemCd: string;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof UserMinigameBonusPresent
     */
    resourcePartition: number | null;
}

/**
 * Check if a given object implements the UserMinigameBonusPresent interface.
 */
export function instanceOfUserMinigameBonusPresent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "presentItemCd" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "resourceItemCd" in value;
    isInstance = isInstance && "resourcePartition" in value;

    return isInstance;
}

export function UserMinigameBonusPresentFromJSON(json: any): UserMinigameBonusPresent {
    return UserMinigameBonusPresentFromJSONTyped(json, false);
}

export function UserMinigameBonusPresentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMinigameBonusPresent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presentItemCd': json['present_item_cd'],
        'type': PresentTypeFromJSON(json['type']),
        'itemCd': json['item_cd'],
        'cardCd': json['card_cd'],
        'avatarCd': !exists(json, 'avatar_cd') ? undefined : json['avatar_cd'],
        'itemName': json['item_name'],
        'itemCount': json['item_count'],
        'description': json['description'],
        'resourceItemCd': json['resource_item_cd'],
        'resourcePartition': json['resource_partition'],
    };
}

export function UserMinigameBonusPresentToJSON(value?: UserMinigameBonusPresent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present_item_cd': value.presentItemCd,
        'type': PresentTypeToJSON(value.type),
        'item_cd': value.itemCd,
        'card_cd': value.cardCd,
        'avatar_cd': value.avatarCd,
        'item_name': value.itemName,
        'item_count': value.itemCount,
        'description': value.description,
        'resource_item_cd': value.resourceItemCd,
        'resource_partition': value.resourcePartition,
    };
}

