/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ItemCategoryType } from './ItemCategoryType';
import {
    ItemCategoryTypeFromJSON,
    ItemCategoryTypeFromJSONTyped,
    ItemCategoryTypeToJSON,
} from './ItemCategoryType';

/**
 * 
 * @export
 * @interface Item
 */
export interface Item {
    /**
     * アイテムコード
     * @type {string}
     * @memberof Item
     */
    itemCd: string;
    /**
     * アイテム名
     * @type {string}
     * @memberof Item
     */
    itemName: string;
    /**
     * アイテムカテゴリーコード
     * @type {string}
     * @memberof Item
     */
    categoryCd: string;
    /**
     * アイテムカテゴリー名
     * @type {string}
     * @memberof Item
     */
    categoryName: string;
    /**
     * 
     * @type {ItemCategoryType}
     * @memberof Item
     */
    categoryType?: ItemCategoryType;
    /**
     * 説明
     * @type {string}
     * @memberof Item
     */
    description: string;
    /**
     * 利用したときに値を変化させる対象
     * @type {string}
     * @memberof Item
     */
    itemTarget: string;
    /**
     * 利用したときに変化させる値
     * @type {number}
     * @memberof Item
     */
    itemEffect: number;
    /**
     * 一度に利用できる数
     * @type {number}
     * @memberof Item
     */
    useLimit: number;
    /**
     * 表示開始日時。無期限の場合はNULL。
     * @type {string}
     * @memberof Item
     */
    startedAt?: string | null;
    /**
     * 表示終了日時。無期限の場合はNULL。
     * @type {string}
     * @memberof Item
     */
    endedAt?: string | null;
    /**
     * ユーザーが利用可能な開始日時。無期限の場合はNULL。
     * @type {string}
     * @memberof Item
     */
    useStartedAt?: string | null;
    /**
     * ユーザーが利用可能な終了日時。無期限の場合はNULL。
     * @type {string}
     * @memberof Item
     */
    useEndedAt?: string | null;
    /**
     * リソースアイテムコード
     * @type {string}
     * @memberof Item
     */
    resourceItemCd: string;
    /**
     * リソースパーティション番号
     * @type {number}
     * @memberof Item
     */
    resourcePartition: number;
}

/**
 * Check if a given object implements the Item interface.
 */
export function instanceOfItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "categoryCd" in value;
    isInstance = isInstance && "categoryName" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "itemTarget" in value;
    isInstance = isInstance && "itemEffect" in value;
    isInstance = isInstance && "useLimit" in value;
    isInstance = isInstance && "resourceItemCd" in value;
    isInstance = isInstance && "resourcePartition" in value;

    return isInstance;
}

export function ItemFromJSON(json: any): Item {
    return ItemFromJSONTyped(json, false);
}

export function ItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): Item {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemCd': json['item_cd'],
        'itemName': json['item_name'],
        'categoryCd': json['category_cd'],
        'categoryName': json['category_name'],
        'categoryType': !exists(json, 'category_type') ? undefined : ItemCategoryTypeFromJSON(json['category_type']),
        'description': json['description'],
        'itemTarget': json['item_target'],
        'itemEffect': json['item_effect'],
        'useLimit': json['use_limit'],
        'startedAt': !exists(json, 'started_at') ? undefined : json['started_at'],
        'endedAt': !exists(json, 'ended_at') ? undefined : json['ended_at'],
        'useStartedAt': !exists(json, 'use_started_at') ? undefined : json['use_started_at'],
        'useEndedAt': !exists(json, 'use_ended_at') ? undefined : json['use_ended_at'],
        'resourceItemCd': json['resource_item_cd'],
        'resourcePartition': json['resource_partition'],
    };
}

export function ItemToJSON(value?: Item | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_cd': value.itemCd,
        'item_name': value.itemName,
        'category_cd': value.categoryCd,
        'category_name': value.categoryName,
        'category_type': ItemCategoryTypeToJSON(value.categoryType),
        'description': value.description,
        'item_target': value.itemTarget,
        'item_effect': value.itemEffect,
        'use_limit': value.useLimit,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'use_started_at': value.useStartedAt,
        'use_ended_at': value.useEndedAt,
        'resource_item_cd': value.resourceItemCd,
        'resource_partition': value.resourcePartition,
    };
}

