/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MissionParameterConditions } from './MissionParameterConditions';
import {
    MissionParameterConditionsFromJSON,
    MissionParameterConditionsFromJSONTyped,
    MissionParameterConditionsToJSON,
} from './MissionParameterConditions';

/**
 * 
 * @export
 * @interface MissionParameter
 */
export interface MissionParameter {
    /**
     * ミッションコード
     * @type {string}
     * @memberof MissionParameter
     */
    missionCd: string;
    /**
     * 
     * @type {MissionParameterConditions}
     * @memberof MissionParameter
     */
    conditions: MissionParameterConditions;
}

/**
 * Check if a given object implements the MissionParameter interface.
 */
export function instanceOfMissionParameter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "conditions" in value;

    return isInstance;
}

export function MissionParameterFromJSON(json: any): MissionParameter {
    return MissionParameterFromJSONTyped(json, false);
}

export function MissionParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'conditions': MissionParameterConditionsFromJSON(json['conditions']),
    };
}

export function MissionParameterToJSON(value?: MissionParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'conditions': MissionParameterConditionsToJSON(value.conditions),
    };
}

