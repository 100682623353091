export const linkUrlConfig = {
  // お問い合わせフォームURL
  contactUsUrl: {
    ios: 'https://my.cybird.ne.jp/sp-inq-ext/IS2002/1?id={0}',
    android: 'https://my.cybird.ne.jp/sp-inq-ext/IS2001/1?id={0}',
    animate: 'https://my.cybird.ne.jp/sp-inq-ext/IS2003/1?id={0}',
  },
  // 未成年者の課金に関するお問い合わせフォームURL
  minorityUrl: 'https://my.cybird.ne.jp/sp-inq/A00003/1?id={0}',
  // 資金決済法に関するお問い合わせ
  paymentServiceActUrl: 'https://my.cybird.ne.jp/sp-inq/A00001/1?id={0}',
  // 公式サイトURL
  officialSiteUrl: 'https://p-one.site/morganaticidol/',
};
