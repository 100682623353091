/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 上限種別
 * total: 累計購入数制限
 * daily: 1日購入数制限
 * @export
 */
export const ShopItemConstraintType = {
    Total: 'total',
    Daily: 'daily'
} as const;
export type ShopItemConstraintType = typeof ShopItemConstraintType[keyof typeof ShopItemConstraintType];


export function ShopItemConstraintTypeFromJSON(json: any): ShopItemConstraintType {
    return ShopItemConstraintTypeFromJSONTyped(json, false);
}

export function ShopItemConstraintTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItemConstraintType {
    return json as ShopItemConstraintType;
}

export function ShopItemConstraintTypeToJSON(value?: ShopItemConstraintType | null): any {
    return value as any;
}

