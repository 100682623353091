/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventSceneSetting
 */
export interface EventSceneSetting {
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof EventSceneSetting
     */
    eventCharaCd: string;
    /**
     * イベントルートコード
     * @type {string}
     * @memberof EventSceneSetting
     */
    eventRouteCd: string;
    /**
     * キャラコード
     * @type {string}
     * @memberof EventSceneSetting
     */
    charaCd: string;
    /**
     * 進行状況
     * @type {string}
     * @memberof EventSceneSetting
     */
    progress: string;
    /**
     * ボイスコード
     * @type {string}
     * @memberof EventSceneSetting
     */
    voiceCd: string | null;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof EventSceneSetting
     */
    voiceResourcePartition: number | null;
    /**
     * キャライメージコード
     * @type {string}
     * @memberof EventSceneSetting
     */
    charaImageCd: string;
    /**
     * キャラ素体画像コード
     * @type {string}
     * @memberof EventSceneSetting
     */
    bodyImageCd: string | null;
    /**
     * キャラ素体リソースパーティション
     * @type {number}
     * @memberof EventSceneSetting
     */
    bodyResourcePartition: number | null;
    /**
     * キャラ表情画像コード
     * @type {string}
     * @memberof EventSceneSetting
     */
    faceImageCd: string | null;
    /**
     * キャラ表情リソースパーティション
     * @type {number}
     * @memberof EventSceneSetting
     */
    faceResourcePartition: number | null;
    /**
     * 背景画像コード
     * @type {string}
     * @memberof EventSceneSetting
     */
    bgCd: string | null;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof EventSceneSetting
     */
    bgResourcePartition: number | null;
}

/**
 * Check if a given object implements the EventSceneSetting interface.
 */
export function instanceOfEventSceneSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "eventRouteCd" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "progress" in value;
    isInstance = isInstance && "voiceCd" in value;
    isInstance = isInstance && "voiceResourcePartition" in value;
    isInstance = isInstance && "charaImageCd" in value;
    isInstance = isInstance && "bodyImageCd" in value;
    isInstance = isInstance && "bodyResourcePartition" in value;
    isInstance = isInstance && "faceImageCd" in value;
    isInstance = isInstance && "faceResourcePartition" in value;
    isInstance = isInstance && "bgCd" in value;
    isInstance = isInstance && "bgResourcePartition" in value;

    return isInstance;
}

export function EventSceneSettingFromJSON(json: any): EventSceneSetting {
    return EventSceneSettingFromJSONTyped(json, false);
}

export function EventSceneSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventSceneSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventCharaCd': json['event_chara_cd'],
        'eventRouteCd': json['event_route_cd'],
        'charaCd': json['chara_cd'],
        'progress': json['progress'],
        'voiceCd': json['voice_cd'],
        'voiceResourcePartition': json['voice_resource_partition'],
        'charaImageCd': json['chara_image_cd'],
        'bodyImageCd': json['body_image_cd'],
        'bodyResourcePartition': json['body_resource_partition'],
        'faceImageCd': json['face_image_cd'],
        'faceResourcePartition': json['face_resource_partition'],
        'bgCd': json['bg_cd'],
        'bgResourcePartition': json['bg_resource_partition'],
    };
}

export function EventSceneSettingToJSON(value?: EventSceneSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_chara_cd': value.eventCharaCd,
        'event_route_cd': value.eventRouteCd,
        'chara_cd': value.charaCd,
        'progress': value.progress,
        'voice_cd': value.voiceCd,
        'voice_resource_partition': value.voiceResourcePartition,
        'chara_image_cd': value.charaImageCd,
        'body_image_cd': value.bodyImageCd,
        'body_resource_partition': value.bodyResourcePartition,
        'face_image_cd': value.faceImageCd,
        'face_resource_partition': value.faceResourcePartition,
        'bg_cd': value.bgCd,
        'bg_resource_partition': value.bgResourcePartition,
    };
}

