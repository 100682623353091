/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserStatus } from './UserStatus';
import {
    UserStatusFromJSON,
    UserStatusFromJSONTyped,
    UserStatusToJSON,
} from './UserStatus';

/**
 * 
 * @export
 * @interface UserStatusResponse
 */
export interface UserStatusResponse {
    /**
     * 
     * @type {UserStatus}
     * @memberof UserStatusResponse
     */
    data: UserStatus;
}

/**
 * Check if a given object implements the UserStatusResponse interface.
 */
export function instanceOfUserStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserStatusResponseFromJSON(json: any): UserStatusResponse {
    return UserStatusResponseFromJSONTyped(json, false);
}

export function UserStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserStatusFromJSON(json['data']),
    };
}

export function UserStatusResponseToJSON(value?: UserStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserStatusToJSON(value.data),
    };
}

