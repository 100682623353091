/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 現在の進行情報
 * @export
 * @interface StoryStatusCurrent
 */
export interface StoryStatusCurrent {
    /**
     * ルート
     * @type {string}
     * @memberof StoryStatusCurrent
     */
    route: string;
    /**
     * チケットコード
     * @type {string}
     * @memberof StoryStatusCurrent
     */
    storyTicketCd: string;
    /**
     * チャプターNo
     * @type {number}
     * @memberof StoryStatusCurrent
     */
    chapterNo: number;
    /**
     * チケットNo
     * @type {number}
     * @memberof StoryStatusCurrent
     */
    ticketNo: number;
    /**
     * 現在がクリア状態か 1;クリア 0未クリア
     * @type {boolean}
     * @memberof StoryStatusCurrent
     */
    clearFlg: boolean;
}

/**
 * Check if a given object implements the StoryStatusCurrent interface.
 */
export function instanceOfStoryStatusCurrent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "route" in value;
    isInstance = isInstance && "storyTicketCd" in value;
    isInstance = isInstance && "chapterNo" in value;
    isInstance = isInstance && "ticketNo" in value;
    isInstance = isInstance && "clearFlg" in value;

    return isInstance;
}

export function StoryStatusCurrentFromJSON(json: any): StoryStatusCurrent {
    return StoryStatusCurrentFromJSONTyped(json, false);
}

export function StoryStatusCurrentFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryStatusCurrent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'route': json['route'],
        'storyTicketCd': json['story_ticket_cd'],
        'chapterNo': json['chapter_no'],
        'ticketNo': json['ticket_no'],
        'clearFlg': json['clear_flg'],
    };
}

export function StoryStatusCurrentToJSON(value?: StoryStatusCurrent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'route': value.route,
        'story_ticket_cd': value.storyTicketCd,
        'chapter_no': value.chapterNo,
        'ticket_no': value.ticketNo,
        'clear_flg': value.clearFlg,
    };
}

