/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventRankingLeaderCardInfo
 */
export interface UserEventRankingLeaderCardInfo {
    /**
     * リーダーカードコード
     * @type {string}
     * @memberof UserEventRankingLeaderCardInfo
     */
    cardCd: string;
    /**
     * リーダーカードパーティション
     * @type {number}
     * @memberof UserEventRankingLeaderCardInfo
     */
    cardPartition: number;
    /**
     * 合成回数
     * @type {number}
     * @memberof UserEventRankingLeaderCardInfo
     */
    synthesisCount: number;
}

/**
 * Check if a given object implements the UserEventRankingLeaderCardInfo interface.
 */
export function instanceOfUserEventRankingLeaderCardInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "cardPartition" in value;
    isInstance = isInstance && "synthesisCount" in value;

    return isInstance;
}

export function UserEventRankingLeaderCardInfoFromJSON(json: any): UserEventRankingLeaderCardInfo {
    return UserEventRankingLeaderCardInfoFromJSONTyped(json, false);
}

export function UserEventRankingLeaderCardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventRankingLeaderCardInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardCd': json['card_cd'],
        'cardPartition': json['card_partition'],
        'synthesisCount': json['synthesis_count'],
    };
}

export function UserEventRankingLeaderCardInfoToJSON(value?: UserEventRankingLeaderCardInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_cd': value.cardCd,
        'card_partition': value.cardPartition,
        'synthesis_count': value.synthesisCount,
    };
}

