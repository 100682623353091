/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StoryAuth
 */
export interface StoryAuth {
    /**
     * ポリシー
     * @type {string}
     * @memberof StoryAuth
     */
    cloudfrontPolicy: string;
    /**
     * シグネイチャー
     * @type {string}
     * @memberof StoryAuth
     */
    cloudfrontSignature: string;
    /**
     * キーペアアイディー
     * @type {string}
     * @memberof StoryAuth
     */
    cloudfrontKeyPairId: string;
}

/**
 * Check if a given object implements the StoryAuth interface.
 */
export function instanceOfStoryAuth(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cloudfrontPolicy" in value;
    isInstance = isInstance && "cloudfrontSignature" in value;
    isInstance = isInstance && "cloudfrontKeyPairId" in value;

    return isInstance;
}

export function StoryAuthFromJSON(json: any): StoryAuth {
    return StoryAuthFromJSONTyped(json, false);
}

export function StoryAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cloudfrontPolicy': json['cloudfront_policy'],
        'cloudfrontSignature': json['cloudfront_signature'],
        'cloudfrontKeyPairId': json['cloudfront_key_pair_id'],
    };
}

export function StoryAuthToJSON(value?: StoryAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cloudfront_policy': value.cloudfrontPolicy,
        'cloudfront_signature': value.cloudfrontSignature,
        'cloudfront_key_pair_id': value.cloudfrontKeyPairId,
    };
}

