/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvatarMstParts
 */
export interface AvatarMstParts {
    /**
     * アバターパーツコード
     * @type {string}
     * @memberof AvatarMstParts
     */
    avatarPartsCategoryCd?: string;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof AvatarMstParts
     */
    resourcePartition?: number;
    /**
     * 空レイヤーフラグ
     * @type {boolean}
     * @memberof AvatarMstParts
     */
    blankLayerFlg: boolean;
    /**
     * 重なり順
     * @type {number}
     * @memberof AvatarMstParts
     */
    zOrder: number;
}

/**
 * Check if a given object implements the AvatarMstParts interface.
 */
export function instanceOfAvatarMstParts(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "blankLayerFlg" in value;
    isInstance = isInstance && "zOrder" in value;

    return isInstance;
}

export function AvatarMstPartsFromJSON(json: any): AvatarMstParts {
    return AvatarMstPartsFromJSONTyped(json, false);
}

export function AvatarMstPartsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvatarMstParts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatarPartsCategoryCd': !exists(json, 'avatar_parts_category_cd') ? undefined : json['avatar_parts_category_cd'],
        'resourcePartition': !exists(json, 'resource_partition') ? undefined : json['resource_partition'],
        'blankLayerFlg': json['blank_layer_flg'],
        'zOrder': json['z_order'],
    };
}

export function AvatarMstPartsToJSON(value?: AvatarMstParts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_parts_category_cd': value.avatarPartsCategoryCd,
        'resource_partition': value.resourcePartition,
        'blank_layer_flg': value.blankLayerFlg,
        'z_order': value.zOrder,
    };
}

