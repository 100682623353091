/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserMinigameTop } from './UserMinigameTop';
import {
    UserMinigameTopFromJSON,
    UserMinigameTopFromJSONTyped,
    UserMinigameTopToJSON,
} from './UserMinigameTop';

/**
 * 
 * @export
 * @interface UserMinigameTopResponse
 */
export interface UserMinigameTopResponse {
    /**
     * 
     * @type {UserMinigameTop}
     * @memberof UserMinigameTopResponse
     */
    data: UserMinigameTop;
}

/**
 * Check if a given object implements the UserMinigameTopResponse interface.
 */
export function instanceOfUserMinigameTopResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserMinigameTopResponseFromJSON(json: any): UserMinigameTopResponse {
    return UserMinigameTopResponseFromJSONTyped(json, false);
}

export function UserMinigameTopResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMinigameTopResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserMinigameTopFromJSON(json['data']),
    };
}

export function UserMinigameTopResponseToJSON(value?: UserMinigameTopResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserMinigameTopToJSON(value.data),
    };
}

