/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AccountDeleteResponseData } from './AccountDeleteResponseData';
import {
    AccountDeleteResponseDataFromJSON,
    AccountDeleteResponseDataFromJSONTyped,
    AccountDeleteResponseDataToJSON,
} from './AccountDeleteResponseData';

/**
 * 
 * @export
 * @interface AccountDeleteResponse
 */
export interface AccountDeleteResponse {
    /**
     * 
     * @type {AccountDeleteResponseData}
     * @memberof AccountDeleteResponse
     */
    data: AccountDeleteResponseData;
}

/**
 * Check if a given object implements the AccountDeleteResponse interface.
 */
export function instanceOfAccountDeleteResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function AccountDeleteResponseFromJSON(json: any): AccountDeleteResponse {
    return AccountDeleteResponseFromJSONTyped(json, false);
}

export function AccountDeleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountDeleteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': AccountDeleteResponseDataFromJSON(json['data']),
    };
}

export function AccountDeleteResponseToJSON(value?: AccountDeleteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AccountDeleteResponseDataToJSON(value.data),
    };
}

