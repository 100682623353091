import { useNuxtApp } from '#app';
import {
  defaultTitle,
  getLoginRequestNotificationText,
  getLoginRequestDays,
  LocalNotificationId,
  LoginRequestDay,
  staminaMaxText
} from '~/configs/localNotification';
import { isDevServer, isMobileApp } from '~/libs/platform';

const logGroup = 'localNotification';
interface UserLocalNotification {
  id: LocalNotificationId,
  title: string,
  text: string,
  seconds: number,
  now: boolean,
  timeAdjust?: boolean,
}
interface UserLocalNotificationData {
  notifications: UserLocalNotification[],
}

interface CancelUserLocalNotification {
  id: LocalNotificationId,
}
interface CancelUserLocalNotificationData {
  notifications: CancelUserLocalNotification[],
}

/**
 * ログイン訴求のプッシュ通知の間隔を取得
 * @return number
 */
export const getLoginRequestSeconds = () => {
  // devサーバーの場合は1日を1分にして返す
  if (isDevServer()) {
    return 60;
  }
  return 3600 * 24;
};

/**
 * ローカルプッシュ通知を行う
 * @param data
 */
const sendNotification = async (data: UserLocalNotificationData) => {
  // useLog(logGroup).debug('sendNotification', data);
  // モバイルアプリの場合以外は処理させない
  if (!isMobileApp()) {
    return;
  }
  const { $nativeBridge } = useNuxtApp();
  // ローカル通知の登録
  await $nativeBridge.send('notification', data);
};
const cancelNotification = async (data: CancelUserLocalNotificationData) => {
  // useLog(logGroup).debug('cancelNotification', data);
  // モバイルアプリの場合以外は処理させない
  if (!isMobileApp()) {
    return;
  }
  const { $nativeBridge } = useNuxtApp();
  // ローカル通知を一度キャンセル
  await $nativeBridge.send('notificationCancel', data);
};
/**
 * スタミナ回復通知
 * @param staminaMaxSchedule 送信時間
 * @param now 即時登録を行うか
 * @param timeAdjust タイミング調整をおこなうか
 */
const sendStamina = (staminaMaxSchedule: number, now = false, timeAdjust = true) => {
  // useLog('useLocalNotification').debug('sendStamina', staminaMaxSchedule);
  // モバイルアプリの場合以外は処理させない
  if (!isMobileApp()) {
    return;
  }
  // 既に回復済み
  if (staminaMaxSchedule <= 0) {
    cancelNotification({
      notifications: [
        { id: LocalNotificationId.StaminaMax, },
      ],
    }).catch((e) => {
      useLog(logGroup).error('sendStamina cancel error', e);
    });
    return;
  }
  const title = defaultTitle;
  sendNotification({
    notifications: [
      {
        id: LocalNotificationId.StaminaMax,
        title,
        text: staminaMaxText,
        seconds: staminaMaxSchedule,
        now,
        timeAdjust,
      },
    ],
  }).catch((e) => {
    useLog(logGroup).error('sendStamina error', e);
  });
};
/**
 * ログイン請求通知情報を取得
 * @param day
 * @param charaCd
 * @return ?UserLocalNotification
 */
const getLoginRequestNotificationData = (day: LoginRequestDay, charaCd: string|null) => {
  const text = getLoginRequestNotificationText(day, charaCd);
  if (!text) {
    return null;
  }
  const title = defaultTitle;
  let id = 0;
  switch (day) {
    case 1: id = LocalNotificationId.OneDayNoLogin; break;
    case 3: id = LocalNotificationId.ThreeDayNoLogin; break;
    case 7: id = LocalNotificationId.SevenDayNoLogin; break;
    case 14: id = LocalNotificationId.FourteenDayNoLogin; break;
  }
  if (!id) {
    useLog(logGroup).warn('LoginRequestNotificationData invalid id.');
    return null;
  }
  return { id, title, text, seconds: day * getLoginRequestSeconds(), now: false, };
};

// ログインキャッシュの設定(アプリへのリクエストを減らすため)
let loginRequestCache: string|undefined;
/**
 * ログイン請求通知
 */
const sendLoginRequest = (charaCd: string|null, isTutorial: number) => {
  // キャッシュ用のキーチェック
  const key = `${charaCd}-${isTutorial}`;
  if (loginRequestCache === key) {
    // 既に設定済みの場合は無視させる
    return;
  }
  loginRequestCache = key;

  // モバイルアプリの場合以外は処理させない
  if (!isMobileApp()) {
    return;
  }
  const notifications: UserLocalNotification[] = [];
  const days = getLoginRequestDays();
  for (const day of days) {
    const d = getLoginRequestNotificationData(day as LoginRequestDay, isTutorial ? null : charaCd);
    if (d) {
      notifications.push(d);
    }
  }
  sendNotification({ notifications }).catch((e) => {
    useLog(logGroup).error('sendLoginRequest error', e);
  });
};

/**
 * キャッシュの削除
 */
const clearCache = () => {
  loginRequestCache = undefined;
};

/**
 * 画面間で共通で使用する基本ユーザーデータを保存しておく
 */
export const useLocalNotification = () => {
  return {
    sendStamina,
    sendLoginRequest,
    clearCache,
  };
};
