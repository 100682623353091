/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ガチャタイプ
 * @export
 */
export const GachaType = {
    Card: 'card',
    Avatar: 'avatar',
    Gold: 'gold'
} as const;
export type GachaType = typeof GachaType[keyof typeof GachaType];


export function GachaTypeFromJSON(json: any): GachaType {
    return GachaTypeFromJSONTyped(json, false);
}

export function GachaTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GachaType {
    return json as GachaType;
}

export function GachaTypeToJSON(value?: GachaType | null): any {
    return value as any;
}

