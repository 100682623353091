/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventMinigameExecute } from './UserEventMinigameExecute';
import {
    UserEventMinigameExecuteFromJSON,
    UserEventMinigameExecuteFromJSONTyped,
    UserEventMinigameExecuteToJSON,
} from './UserEventMinigameExecute';

/**
 * 
 * @export
 * @interface UserEventMinigameExecuteResponse
 */
export interface UserEventMinigameExecuteResponse {
    /**
     * 
     * @type {UserEventMinigameExecute}
     * @memberof UserEventMinigameExecuteResponse
     */
    data: UserEventMinigameExecute;
}

/**
 * Check if a given object implements the UserEventMinigameExecuteResponse interface.
 */
export function instanceOfUserEventMinigameExecuteResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserEventMinigameExecuteResponseFromJSON(json: any): UserEventMinigameExecuteResponse {
    return UserEventMinigameExecuteResponseFromJSONTyped(json, false);
}

export function UserEventMinigameExecuteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventMinigameExecuteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserEventMinigameExecuteFromJSON(json['data']),
    };
}

export function UserEventMinigameExecuteResponseToJSON(value?: UserEventMinigameExecuteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserEventMinigameExecuteToJSON(value.data),
    };
}

