export enum AvatarListSortType {
    byIdAsc,
    byIdDesc,
    byParameterAsc,
    byParameterDesc,
    byInUseDesc,
    byNotInUseDesc
}

export interface ItemButtonType {
    code: string,
    label: string,
    handleClick: boolean,
}

export interface ItemButtons {
    label: string,
    code: string,
    icon: string,
    handleClick: boolean,
}

export interface ItemData {
    resourcePartition: number,
    avatarCd: string,
    avatarCategoryCd: string,
    isNew: boolean,
    userAvatarId: number,
}

export interface EquipDataType {
    resourcePartition: number,
    avatarCd: string,
    blankLayerFlg: boolean,
    zOrder: number,
    avatarPartsCategoryCd: string,
}
