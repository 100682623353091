/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSerialcodeSaveItem
 */
export interface UserSerialcodeSaveItem {
    /**
     * アイテム名
     * @type {string}
     * @memberof UserSerialcodeSaveItem
     */
    title: string;
    /**
     * アイテム数
     * @type {number}
     * @memberof UserSerialcodeSaveItem
     */
    count: number;
}

/**
 * Check if a given object implements the UserSerialcodeSaveItem interface.
 */
export function instanceOfUserSerialcodeSaveItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function UserSerialcodeSaveItemFromJSON(json: any): UserSerialcodeSaveItem {
    return UserSerialcodeSaveItemFromJSONTyped(json, false);
}

export function UserSerialcodeSaveItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSerialcodeSaveItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'count': json['count'],
    };
}

export function UserSerialcodeSaveItemToJSON(value?: UserSerialcodeSaveItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'count': value.count,
    };
}

