/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * イベントストーリーのルート
 * @export
 */
export const EventStoryRouteType = {
    A: 'a',
    B: 'b',
    C: 'c',
    _0: '0'
} as const;
export type EventStoryRouteType = typeof EventStoryRouteType[keyof typeof EventStoryRouteType];


export function EventStoryRouteTypeFromJSON(json: any): EventStoryRouteType {
    return EventStoryRouteTypeFromJSONTyped(json, false);
}

export function EventStoryRouteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStoryRouteType {
    return json as EventStoryRouteType;
}

export function EventStoryRouteTypeToJSON(value?: EventStoryRouteType | null): any {
    return value as any;
}

