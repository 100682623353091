/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PanelmissionBonusAvatar } from './PanelmissionBonusAvatar';
import {
    PanelmissionBonusAvatarFromJSON,
    PanelmissionBonusAvatarFromJSONTyped,
    PanelmissionBonusAvatarToJSON,
} from './PanelmissionBonusAvatar';
import type { PanelmissionBonusCard } from './PanelmissionBonusCard';
import {
    PanelmissionBonusCardFromJSON,
    PanelmissionBonusCardFromJSONTyped,
    PanelmissionBonusCardToJSON,
} from './PanelmissionBonusCard';
import type { PanelmissionBonusItem } from './PanelmissionBonusItem';
import {
    PanelmissionBonusItemFromJSON,
    PanelmissionBonusItemFromJSONTyped,
    PanelmissionBonusItemToJSON,
} from './PanelmissionBonusItem';
import type { PanelmissionBonusPresent } from './PanelmissionBonusPresent';
import {
    PanelmissionBonusPresentFromJSON,
    PanelmissionBonusPresentFromJSONTyped,
    PanelmissionBonusPresentToJSON,
} from './PanelmissionBonusPresent';
import type { PanelmissionBonusSpecialStory } from './PanelmissionBonusSpecialStory';
import {
    PanelmissionBonusSpecialStoryFromJSON,
    PanelmissionBonusSpecialStoryFromJSONTyped,
    PanelmissionBonusSpecialStoryToJSON,
} from './PanelmissionBonusSpecialStory';

/**
 * 
 * @export
 * @interface PanelmissionBonus
 */
export interface PanelmissionBonus {
    /**
     * 
     * @type {PanelmissionBonusPresent}
     * @memberof PanelmissionBonus
     */
    present?: PanelmissionBonusPresent;
    /**
     * 
     * @type {PanelmissionBonusItem}
     * @memberof PanelmissionBonus
     */
    item?: PanelmissionBonusItem;
    /**
     * 
     * @type {PanelmissionBonusCard}
     * @memberof PanelmissionBonus
     */
    card?: PanelmissionBonusCard;
    /**
     * 
     * @type {PanelmissionBonusAvatar}
     * @memberof PanelmissionBonus
     */
    avatar?: PanelmissionBonusAvatar;
    /**
     * 
     * @type {PanelmissionBonusSpecialStory}
     * @memberof PanelmissionBonus
     */
    specialStory?: PanelmissionBonusSpecialStory;
}

/**
 * Check if a given object implements the PanelmissionBonus interface.
 */
export function instanceOfPanelmissionBonus(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PanelmissionBonusFromJSON(json: any): PanelmissionBonus {
    return PanelmissionBonusFromJSONTyped(json, false);
}

export function PanelmissionBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'present': !exists(json, 'present') ? undefined : PanelmissionBonusPresentFromJSON(json['present']),
        'item': !exists(json, 'item') ? undefined : PanelmissionBonusItemFromJSON(json['item']),
        'card': !exists(json, 'card') ? undefined : PanelmissionBonusCardFromJSON(json['card']),
        'avatar': !exists(json, 'avatar') ? undefined : PanelmissionBonusAvatarFromJSON(json['avatar']),
        'specialStory': !exists(json, 'special_story') ? undefined : PanelmissionBonusSpecialStoryFromJSON(json['special_story']),
    };
}

export function PanelmissionBonusToJSON(value?: PanelmissionBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present': PanelmissionBonusPresentToJSON(value.present),
        'item': PanelmissionBonusItemToJSON(value.item),
        'card': PanelmissionBonusCardToJSON(value.card),
        'avatar': PanelmissionBonusAvatarToJSON(value.avatar),
        'special_story': PanelmissionBonusSpecialStoryToJSON(value.specialStory),
    };
}

