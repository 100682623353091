/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MissionCompleteRequest
 */
export interface MissionCompleteRequest {
    /**
     * ミッションコード
     * @type {string}
     * @memberof MissionCompleteRequest
     */
    missionCd: string;
    /**
     * アバターミッションをどちらでクリアしたか avanml, avaprm
     * @type {string}
     * @memberof MissionCompleteRequest
     */
    selectAvatar?: string;
    /**
     * どのルートを選択したか a, b
     * @type {string}
     * @memberof MissionCompleteRequest
     */
    selectRoute?: string;
}

/**
 * Check if a given object implements the MissionCompleteRequest interface.
 */
export function instanceOfMissionCompleteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;

    return isInstance;
}

export function MissionCompleteRequestFromJSON(json: any): MissionCompleteRequest {
    return MissionCompleteRequestFromJSONTyped(json, false);
}

export function MissionCompleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionCompleteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'selectAvatar': !exists(json, 'select_avatar') ? undefined : json['select_avatar'],
        'selectRoute': !exists(json, 'select_route') ? undefined : json['select_route'],
    };
}

export function MissionCompleteRequestToJSON(value?: MissionCompleteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'select_avatar': value.selectAvatar,
        'select_route': value.selectRoute,
    };
}

