import { useLog } from '~/composables/useLog';
// import { nativeBridgeCall } from '~/libs/nativeBridge';

interface SafeArea {
  top: number,
  right: number,
  bottom: number,
  left: number,
}

const setSafeArea = (portraitSizes: SafeArea, landscapeSizes: SafeArea) => {
  const style = document.createElement('style');
  style.type = 'text/css';
  style.innerHTML = `
:root {
  --sat: ${portraitSizes.top}px;
  --sar: ${portraitSizes.right}px;
  --sab: ${portraitSizes.bottom}px;
  --sal: ${portraitSizes.left}px;
}
@media (orientation: landscape) {
  :root {
    --sat: ${landscapeSizes.top}px;
    --sar: ${landscapeSizes.right}px;
    --sab: ${landscapeSizes.bottom}px;
    --sal: ${landscapeSizes.left}px;
  }
}
`;
  document.head.appendChild(style);
  return true;
};
export default defineNuxtPlugin(() => {
  const safeAreaTest = useRuntimeConfig().public.safeAreaTest;
  if (safeAreaTest && process.dev) {
    const portraitSizes: SafeArea = {
      top: 44,
      right: 0,
      bottom: 34,
      left: 0,
    };
    const landscapeSizes: SafeArea = {
      top: 0,
      right: 47,
      bottom: 21,
      left: 47,
    };
    useLog('SafeAreaTest').warn('Running in test mode.', portraitSizes, landscapeSizes);
    setSafeArea(portraitSizes, landscapeSizes);

    // const sizes = {
    //   top: 44,
    //   right: 0,
    //   bottom: 28,
    //   left: 0,
    // };
    // useLog('SafeAreaTest').warn('Running in test mode.', sizes);
    // nativeBridgeCall.setSafeArea(sizes.top, sizes.right, sizes.bottom, sizes.left);
  }
});
