/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCard
 */
export interface UserCard {
    /**
     * カードコード
     * @type {string}
     * @memberof UserCard
     */
    cardCd: string;
    /**
     * 獲得日時
     * @type {string}
     * @memberof UserCard
     */
    openedAt: string;
    /**
     * 確認フラグ 1:確認済み
     * @type {boolean}
     * @memberof UserCard
     */
    readFlg: boolean;
    /**
     * パラメータ
     * @type {number}
     * @memberof UserCard
     */
    parameter: number;
    /**
     * 合成回数
     * @type {number}
     * @memberof UserCard
     */
    synthesisCount: number;
    /**
     * ボイス開放フラグ 1:開放済み 0:未開放
     * @type {boolean}
     * @memberof UserCard
     */
    unlockVoiceFlg: boolean;
    /**
     * イラスト変更フラグ 1:変更可 0:変更不可
     * @type {boolean}
     * @memberof UserCard
     */
    changeIllustFlg: boolean;
}

/**
 * Check if a given object implements the UserCard interface.
 */
export function instanceOfUserCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "openedAt" in value;
    isInstance = isInstance && "readFlg" in value;
    isInstance = isInstance && "parameter" in value;
    isInstance = isInstance && "synthesisCount" in value;
    isInstance = isInstance && "unlockVoiceFlg" in value;
    isInstance = isInstance && "changeIllustFlg" in value;

    return isInstance;
}

export function UserCardFromJSON(json: any): UserCard {
    return UserCardFromJSONTyped(json, false);
}

export function UserCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardCd': json['card_cd'],
        'openedAt': json['opened_at'],
        'readFlg': json['read_flg'],
        'parameter': json['parameter'],
        'synthesisCount': json['synthesis_count'],
        'unlockVoiceFlg': json['unlock_voice_flg'],
        'changeIllustFlg': json['change_illust_flg'],
    };
}

export function UserCardToJSON(value?: UserCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_cd': value.cardCd,
        'opened_at': value.openedAt,
        'read_flg': value.readFlg,
        'parameter': value.parameter,
        'synthesis_count': value.synthesisCount,
        'unlock_voice_flg': value.unlockVoiceFlg,
        'change_illust_flg': value.changeIllustFlg,
    };
}

