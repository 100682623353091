import {
  UserShopCombinedsaleItem,
  ShopItem,
  ShopItemBuyType,
  UserItem,
  UserShopItem,
  UserShopLimitItem,
  ShopCombinedsaleItem,
  UserStatus,
  ShopItemBuyResponseData,
  UserStatusMin,
  Avatar,
} from 'api';
import { inPeriod } from '~/libs/date';
import { isAnimatePlatform } from '~/libs/platform';
import { ShopBuyCost, Wallet } from '~/composables/useWallet';
import { AnimateWallet } from '~/composables/useAnimateWallet';
import { shopItemCategoryCd } from '~/configs/shop';

const logGroup = 'libs/shop';
/**
 * ショップ購入に際して変化する値のデータ
 */
export interface ShopChangeData {
  name: string,
  before: number,
  value: number,
}

const changeParameterName = {
  item: 'アイテム所持数',
  avatarP: 'ルーム魅力',
  minigameP: '親密度',
  staminaP: '体力',
  roomAvatarMax: 'クローゼット',
  loveP1: '恋情度',
  loveP2: '愛情度',
  dia: 'ダイヤ',
  purchasedDia: '有償ダイヤ',
  animateCoin: 'ゲソコイン',
  gold: 'コイン',
};

/**
 * ショップアイテムを購入して変化する値を取得
 * @return boolean
 */
export const getShopChangeData = (data: {
  shopItem: ShopItem,
  userItem?: UserItem,
  buyCount: number,
  userStatus?: UserStatus,
}): ShopChangeData[] => {
  const items: ShopChangeData[] = [];
  if (data.shopItem.shopCategoryCd === shopItemCategoryCd.avatarStorageUpItem) {
    items.push({
      name: changeParameterName.roomAvatarMax,
      before: data.userStatus?.roomAvatarMax ?? 0,
      value: data.buyCount * data.shopItem.itemCount,
    });
  } else {
    items.push({
      name: changeParameterName.item,
      before: data.userItem?.itemCount ?? 0,
      value: data.buyCount * data.shopItem.itemCount,
    });
  }
  return items;
};

/**
 * アバターを購入して変化する値を取得
 * @param data
 * @return {ShopChangeData[]}
 */
export const getAvatarChangeData = (data: {
  avatar: Avatar,
  userStatusMin?: UserStatusMin,
  buyCount: number,
}): ShopChangeData[] => {
  const items: ShopChangeData[] = [];

  items.push({
    name: changeParameterName.avatarP,
    before: data.userStatusMin?.avatarP ?? 0,
    value: data.buyCount * data.avatar.charmValue,
  });

  return items;
};

/**
 * ショップアイテムを購入して変化する値を取得
 */
export interface ShopChangeResultDataDisplay {
  itemCount?: boolean,
  avatarP?: boolean,
  minigameP?: boolean,
  staminaP?: boolean,
  loveP1?: boolean,
  loveP2?: boolean,
  roomAvatarMax?: boolean,
  gesoCoin?: boolean,
  gem?: boolean,
  gold?: boolean,
}

/**
 * ショップアイテムを購入して変化する値を取得
 * @return boolean
 */
export const getShopChangeResultData = (data: {
  buyResponse: ShopItemBuyResponseData,
  userItem?: UserItem,
  userStatus?: UserStatus,
  wallet: Wallet,
  animateWallet: AnimateWallet,
}, display?: ShopChangeResultDataDisplay): ShopChangeData[] => {
  const items: ShopChangeData[] = [];
  if ((!display || display.itemCount) && data.buyResponse.itemCount !== (data.userItem?.itemCount ?? 0)) {
    const before = (data.userItem?.itemCount ?? 0);
    const value = data.buyResponse.itemCount - before;
    items.push({
      name: changeParameterName.item,
      before,
      value,
    });
  }
  if ((!display || display.avatarP) && data.buyResponse.avatarP !== (data.userStatus?.avatarP ?? 0)) {
    const before = data.userStatus?.avatarP ?? 0;
    const value = data.buyResponse.avatarP - before;
    items.push({
      name: changeParameterName.avatarP,
      before,
      value,
    });
  }
  if ((!display || display.minigameP) && data.buyResponse.minigameP !== (data.userStatus?.minigameP ?? 0)) {
    const before = data.userStatus?.minigameP ?? 0;
    const value = data.buyResponse.minigameP - before;
    items.push({
      name: changeParameterName.minigameP,
      before,
      value,
    });
  }
  if ((!display || display.staminaP) && data.buyResponse.staminaP !== (data.userStatus?.staminaP ?? 0)) {
    const before = data.userStatus?.staminaP ?? 0;
    const value = data.buyResponse.staminaP - before;
    items.push({
      name: changeParameterName.staminaP,
      before,
      value,
    });
  }
  if ((!display || display.loveP1) && data.buyResponse.loveP1 !== (data.userStatus?.lovePA ?? 0)) {
    const before = data.userStatus?.lovePA ?? 0;
    const value = data.buyResponse.loveP1 - before;
    items.push({
      name: changeParameterName.loveP1,
      before,
      value,
    });
  }
  if ((!display || display.loveP2) && data.buyResponse.loveP2 !== (data.userStatus?.lovePB ?? 0)) {
    const before = data.userStatus?.lovePB ?? 0;
    const value = data.buyResponse.loveP2 - before;
    items.push({
      name: changeParameterName.loveP2,
      before,
      value,
    });
  }
  if ((!display || display.roomAvatarMax) && data.buyResponse.roomAvatarMax !== (data.userStatus?.roomAvatarMax ?? 0)) {
    const before = data.userStatus?.roomAvatarMax ?? 0;
    const value = data.buyResponse.roomAvatarMax - before;
    items.push({
      name: changeParameterName.roomAvatarMax,
      before,
      value,
    });
  }
  if ((!display || display.gesoCoin) && data.buyResponse.gesoCoin !== null && data.buyResponse.gesoCoin !== data.animateWallet.coin) {
    const before = data.animateWallet.coin;
    const value = data.buyResponse.gesoCoin - before;
    items.push({
      name: changeParameterName.animateCoin,
      before,
      value,
    });
  }
  if (
    isAnimatePlatform() &&
    (!display || display.gem) &&
    data.buyResponse.freeGem !== data.wallet.freeGem
  ) {
    const before = data.wallet.freeGem;
    const value = data.buyResponse.freeGem - before;
    items.push({
      name: changeParameterName.dia,
      before,
      value,
    });
  } else if (
    (!display || display.gem) &&
    (data.buyResponse.purchasedGem + data.buyResponse.freeGem) !==
    (data.wallet.purchasedGem + data.wallet.freeGem)
  ) {
    const before = data.wallet.purchasedGem + data.wallet.freeGem;
    const value = data.buyResponse.purchasedGem + data.buyResponse.freeGem - before;
    items.push({
      name: changeParameterName.dia,
      before,
      value,
    });
  }
  if ((!display || display.gold) && data.buyResponse.gold !== data.wallet.gold) {
    const before = data.wallet.gold;
    const value = data.buyResponse.gold - before;
    items.push({
      name: changeParameterName.gold,
      before,
      value,
    });
  }
  return items;
};

/**
 * ショップアイテムを購入して変化するウォレット値を取得
 * @param data: {
 *   cost: 費用
 *   wallet: 所持金
 *   animateWallet: アニメイトの所持金
 * }
 * @return ShopChangeData[]
 */
export const getShopCostChangeData = (data: {
  cost: ShopBuyCost,
  wallet: Wallet,
  animateWallet: AnimateWallet,
}): ShopChangeData[] => {
  const items: ShopChangeData[] = [];
  // アニメイト環境かつ無償ダイヤ購入の商品の場合
  if (isAnimatePlatform() && data.cost.freeGem) {
    items.push({
      name: changeParameterName.dia,
      before: data.wallet.freeGem,
      value: data.cost.freeGem * -1,
    });
  } else if (data.cost.purchasedGem > 0 || data.cost.freeGem) {
    // 有償ダイヤ購入もしくは無償ダイヤ購入の商品の場合
    // 有償ダイヤ購入限定の商品の場合
    if (data.cost.purchasedOnlyFlg) {
      items.push({
        name: changeParameterName.purchasedDia,
        before: data.wallet.purchasedGem,
        value: data.cost.purchasedGem * -1,
      });
    } else {
      // その他の場合
      // 商品の値段(有償・無償)を合算
      const v = data.cost.purchasedGem + data.cost.freeGem;
      items.push({
        name: changeParameterName.dia,
        // 所持金(有償・無償)を合算
        before: data.wallet.purchasedGem + data.wallet.freeGem,
        value: v * -1,
      });
    }
  }

  // ゲソコイン購入の商品の場合
  if (data.cost.gesoCoin) {
    items.push({
      name: changeParameterName.animateCoin,
      before: data.animateWallet.coin,
      value: data.cost.gesoCoin * -1,
    });
  }

  // コイン購入の商品の場合
  if (data.cost.gold) {
    items.push({
      name: changeParameterName.gold,
      before: data.wallet.gold,
      value: data.cost.gold * -1,
    });
  }
  return items;
};

/**
 * 指定コストを購入できるかチェック
 * @return boolean
 */
export const isCanBuyCost = (data: {
  cost: ShopBuyCost,
  wallet: Wallet,
  animateWallet: AnimateWallet,
}): boolean => {
  if (data.cost.purchasedGem > data.wallet.purchasedGem) {
    return false;
  }
  if (data.cost.freeGem > data.wallet.freeGem) {
    return false;
  }
  if (data.cost.gold > data.wallet.gold) {
    return false;
  }
  return data.cost.gesoCoin <= data.animateWallet.coin;
};

/**
 * ショップアイテムから販売種別を取得
 * @return ShopItemBuyType
 */
export const getBuyType = (shopItem: ShopItem): ShopItemBuyType => {
  return shopItem.goldPrice > 0 ? 'gold' : 'gem';
};

/**
 * ショップアイテムが購入できるかどうかを確認
 */
export const isCanBuyShopItem = (shopItem: ShopItem, now: string|Date, shopLimitItem?: UserShopLimitItem) => {
  if (!inPeriod(shopItem.startedAt, shopItem.endedAt, now)) {
    return false;
  }
  if (shopLimitItem) {
    return isCanBuyShopLimitItem(shopLimitItem);
  }
  return true;
};

/**
 * セットアイテムが購入できるかどうかを確認
 */
export const isCanBuyShopCombinedsaleItem = (userShopCombinedsaleItem: UserShopCombinedsaleItem, now: string|Date, shopLimitItem?: UserShopLimitItem) => {
  if (!inPeriod(userShopCombinedsaleItem.startedAt, userShopCombinedsaleItem.endedAt, now)) {
    return false;
  }
  if (shopLimitItem) {
    return isCanBuyShopLimitItem(shopLimitItem);
  }
  return true;
};

/**
 * 購入制限から購入できるか
 * @param shopLimitItem
 * @return boolean
 */
export const isCanBuyShopLimitItem = (shopLimitItem: UserShopLimitItem) => {
  return shopLimitItem.buyCount < shopLimitItem.buyLimit;
};

/**
 * 有効な購入制限情報を取得する
 * @param {string} shopItemCd
 * @param {UserShopLimitItem[]} shopLimitItemList
 * @return {UserShopLimitItem?}
 */
export const getEnableBuyShopLimitItemFromShopItemCd = (shopItemCd: string, shopLimitItemList: UserShopLimitItem[]): UserShopLimitItem|undefined => {
  const userShopLimitItemList = shopLimitItemList.filter((it: UserShopLimitItem) => {
    return it.shopItemCd === shopItemCd;
  });
  return getEnableBuyShopLimitItem(userShopLimitItemList);
};

/**
 * 有効な購入制限情報を取得する
 * @param {UserShopLimitItem[]} shopLimitItemList
 * @return {UserShopLimitItem?}
 */
export const getEnableBuyShopLimitItem = (shopLimitItemList: UserShopLimitItem[]): UserShopLimitItem|undefined => {
  // 上限を満たしているものをチェック
  const item = shopLimitItemList.find(isCanBuyShopLimitItem);
  if (item) {
    return item;
  }
  // 存在しない場合は先頭の制限情報を取得
  return shopLimitItemList.length > 0 ? shopLimitItemList[0] : undefined;
};

/**
 * 残り購入可能数を取得
 */
export const getBuyMaxCountShopItem = (shopItem: ShopItem, userShopLimitItem?: UserShopLimitItem, total?: boolean) => {
  // 購入上限を取得
  let max = total ? 1 : shopItem.oneTimeLimitCount;
  if (userShopLimitItem) {
    max = Math.min(userShopLimitItem.buyLimit - userShopLimitItem.buyCount, max);
  }
  return Math.max(max, 0);
};

/**
 * 残り購入可能数を取得
 */
export const getBuyMaxCountShopCombinedsaleItem = (shopCombinedsaleItem: ShopCombinedsaleItem, userShopLimitItem?: UserShopLimitItem, total?: boolean) => {
  // 購入上限を取得
  let max = total ? 1 : shopCombinedsaleItem.oneTimeLimitCount;
  if (userShopLimitItem) {
    max = Math.max(userShopLimitItem.buyLimit - userShopLimitItem.buyCount, 0);
  }
  return Math.max(max, 0);
};

/**
 * ショップ購入モーダルに必要な情報
 */
export interface ShopItemData {
  shopItem: ShopItem,
  userItem?: UserItem,
  userShopItem: UserShopItem,
  userShopLimitItem?: UserShopLimitItem,
}

/**
 * ショップ購入モーダルに必要な情報を一括取得
 * @param userShopItem
 * @param shopItems
 * @param userItems
 * @param userShopLimitItems
 */
export const getShopItemData = (
  userShopItem: UserShopItem,
  shopItems: ShopItem[],
  userItems: UserItem[],
  userShopLimitItems: UserShopLimitItem[],
): ShopItemData|undefined => {
  const shopItem = shopItems.find((it: ShopItem) => {
    return it.shopItemCd === userShopItem.shopItemCd;
  });
  if (!shopItem) {
    useLog(logGroup).warn(`Not Found shopItemCd: ${userShopItem.shopItemCd}`);
    return undefined;
  }
  if (!shopItem.showFlg || !inPeriod(shopItem.startedAt, shopItem.endedAt, useLabotan().date)) {
    useLog(logGroup).debug(`disable shopItemCd: ${shopItem.shopItemCd}`, shopItem);
    return undefined;
  }
  const userItem = userItems.find((it: UserItem) => {
    return it.itemCd === shopItem.itemCd;
  });
  const userShopLimitItem = getEnableBuyShopLimitItemFromShopItemCd(shopItem.shopItemCd, userShopLimitItems);
  return {
    userItem,
    shopItem,
    userShopItem,
    userShopLimitItem,
  };
};

/**
 * 体力回復モーダルに必要な情報を取得
 * @param userShopItem
 * @param shopItems
 * @param userItems
 * @param userShopLimitItems
 */
export const getShopStaminaItemData = (
  userShopItem: UserShopItem,
  shopItems: ShopItem[],
  userItems: UserItem[],
  userShopLimitItems: UserShopLimitItem[],
): ShopItemData|undefined => {
  const shopItem = shopItems.find((it: ShopItem) => {
    return it.shopItemCd === userShopItem.shopItemCd;
  });
  if (!shopItem) {
    useLog(logGroup).warn(`Not Found shopItemCd: ${userShopItem.shopItemCd}`);
    return undefined;
  }
  if (!inPeriod(shopItem.startedAt, shopItem.endedAt, useLabotan().date)) {
    useLog(logGroup).debug(`disable shopItemCd: ${shopItem.shopItemCd}`, shopItem);
    return undefined;
  }
  const userItem = userItems.find((it: UserItem) => {
    return it.itemCd === shopItem.itemCd;
  });
  const userShopLimitItem = getEnableBuyShopLimitItemFromShopItemCd(shopItem.shopItemCd, userShopLimitItems);
  return {
    userItem,
    shopItem,
    userShopItem,
    userShopLimitItem,
  };
};

/**
 * ショップセット販売購入モーダルに必要な情報
 */
export interface ShopCombinedsaleItemData {
  shopCombinedsaleItem: ShopCombinedsaleItem,
  userShopCombinedsaleItem: UserShopCombinedsaleItem,
  userShopLimitItem?: UserShopLimitItem,
  userItem?: UserItem,
}
