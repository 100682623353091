/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EventEpiloguePurchaseStatus = {
    DisablePurchased: 0,
    EnablePurchased: 1,
    AlreadyPurchased: 2
} as const;
export type EventEpiloguePurchaseStatus = typeof EventEpiloguePurchaseStatus[keyof typeof EventEpiloguePurchaseStatus];


export function EventEpiloguePurchaseStatusFromJSON(json: any): EventEpiloguePurchaseStatus {
    return EventEpiloguePurchaseStatusFromJSONTyped(json, false);
}

export function EventEpiloguePurchaseStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEpiloguePurchaseStatus {
    return json as EventEpiloguePurchaseStatus;
}

export function EventEpiloguePurchaseStatusToJSON(value?: EventEpiloguePurchaseStatus | null): any {
    return value as any;
}

