/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserSerialcodeSaveCard } from './UserSerialcodeSaveCard';
import {
    UserSerialcodeSaveCardFromJSON,
    UserSerialcodeSaveCardFromJSONTyped,
    UserSerialcodeSaveCardToJSON,
} from './UserSerialcodeSaveCard';
import type { UserSerialcodeSaveItem } from './UserSerialcodeSaveItem';
import {
    UserSerialcodeSaveItemFromJSON,
    UserSerialcodeSaveItemFromJSONTyped,
    UserSerialcodeSaveItemToJSON,
} from './UserSerialcodeSaveItem';
import type { UserSerialcodeSaveSpecialStory } from './UserSerialcodeSaveSpecialStory';
import {
    UserSerialcodeSaveSpecialStoryFromJSON,
    UserSerialcodeSaveSpecialStoryFromJSONTyped,
    UserSerialcodeSaveSpecialStoryToJSON,
} from './UserSerialcodeSaveSpecialStory';
import type { UserSerialcodeSaveStill } from './UserSerialcodeSaveStill';
import {
    UserSerialcodeSaveStillFromJSON,
    UserSerialcodeSaveStillFromJSONTyped,
    UserSerialcodeSaveStillToJSON,
} from './UserSerialcodeSaveStill';

/**
 * 
 * @export
 * @interface UserSerialcodeSave
 */
export interface UserSerialcodeSave {
    /**
     * 
     * @type {UserSerialcodeSaveItem}
     * @memberof UserSerialcodeSave
     */
    item: UserSerialcodeSaveItem | null;
    /**
     * 
     * @type {UserSerialcodeSaveCard}
     * @memberof UserSerialcodeSave
     */
    card: UserSerialcodeSaveCard | null;
    /**
     * 
     * @type {UserSerialcodeSaveStill}
     * @memberof UserSerialcodeSave
     */
    still: UserSerialcodeSaveStill | null;
    /**
     * 
     * @type {UserSerialcodeSaveSpecialStory}
     * @memberof UserSerialcodeSave
     */
    specialStory: UserSerialcodeSaveSpecialStory | null;
}

/**
 * Check if a given object implements the UserSerialcodeSave interface.
 */
export function instanceOfUserSerialcodeSave(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "item" in value;
    isInstance = isInstance && "card" in value;
    isInstance = isInstance && "still" in value;
    isInstance = isInstance && "specialStory" in value;

    return isInstance;
}

export function UserSerialcodeSaveFromJSON(json: any): UserSerialcodeSave {
    return UserSerialcodeSaveFromJSONTyped(json, false);
}

export function UserSerialcodeSaveFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSerialcodeSave {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': UserSerialcodeSaveItemFromJSON(json['item']),
        'card': UserSerialcodeSaveCardFromJSON(json['card']),
        'still': UserSerialcodeSaveStillFromJSON(json['still']),
        'specialStory': UserSerialcodeSaveSpecialStoryFromJSON(json['special_story']),
    };
}

export function UserSerialcodeSaveToJSON(value?: UserSerialcodeSave | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': UserSerialcodeSaveItemToJSON(value.item),
        'card': UserSerialcodeSaveCardToJSON(value.card),
        'still': UserSerialcodeSaveStillToJSON(value.still),
        'special_story': UserSerialcodeSaveSpecialStoryToJSON(value.specialStory),
    };
}

