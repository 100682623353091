/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoryListMission } from './StoryListMission';
import {
    StoryListMissionFromJSON,
    StoryListMissionFromJSONTyped,
    StoryListMissionToJSON,
} from './StoryListMission';
import type { StoryListReread } from './StoryListReread';
import {
    StoryListRereadFromJSON,
    StoryListRereadFromJSONTyped,
    StoryListRereadToJSON,
} from './StoryListReread';
import type { StoryRouteType } from './StoryRouteType';
import {
    StoryRouteTypeFromJSON,
    StoryRouteTypeFromJSONTyped,
    StoryRouteTypeToJSON,
} from './StoryRouteType';
import type { UserStoryClearMissionListInner } from './UserStoryClearMissionListInner';
import {
    UserStoryClearMissionListInnerFromJSON,
    UserStoryClearMissionListInnerFromJSONTyped,
    UserStoryClearMissionListInnerToJSON,
} from './UserStoryClearMissionListInner';

/**
 * 
 * @export
 * @interface UserStory
 */
export interface UserStory {
    /**
     * 選択中のキャラコード
     * @type {string}
     * @memberof UserStory
     */
    charaCd: string;
    /**
     * 恋情度
     * @type {number}
     * @memberof UserStory
     */
    loveP1: number;
    /**
     * 愛情度
     * @type {number}
     * @memberof UserStory
     */
    loveP2: number;
    /**
     * 
     * @type {StoryRouteType}
     * @memberof UserStory
     */
    route: StoryRouteType;
    /**
     * 会話で選んだ選択肢 ストーリー再生中の選択後〜読了API実行までに何らかの問題で落ちた場合の読み直し時に使用する
     * @type {number}
     * @memberof UserStory
     */
    answerNo: number | null;
    /**
     * 現在選択中のキャラのクリア回数
     * @type {number}
     * @memberof UserStory
     */
    clearCount: number;
    /**
     * 無料チケット所持数
     * @type {number}
     * @memberof UserStory
     */
    ticketFree: number;
    /**
     * チケット所持数
     * @type {number}
     * @memberof UserStory
     */
    ticket: number;
    /**
     * 宝石所持数
     * @type {number}
     * @memberof UserStory
     */
    point: number;
    /**
     * 彼目線ストーリー解放鍵所持数
     * @type {number}
     * @memberof UserStory
     */
    key: number;
    /**
     * クリア済みルート
     * @type {Array<string>}
     * @memberof UserStory
     */
    clearRouteList: Array<string>;
    /**
     * クリア済みミッション情報
     * @type {Array<UserStoryClearMissionListInner>}
     * @memberof UserStory
     */
    clearMissionList: Array<UserStoryClearMissionListInner>;
    /**
     * 購入済みチャプター情報
     * @type {Array<string>}
     * @memberof UserStory
     */
    canReadTicketCdList: Array<string>;
    /**
     * 
     * @type {StoryListReread}
     * @memberof UserStory
     */
    reread: StoryListReread;
    /**
     * チャプター内のチケット順序
     * @type {number}
     * @memberof UserStory
     */
    ticketNo: number;
    /**
     * 次に読むチケットのチャプターコード
     * @type {string}
     * @memberof UserStory
     */
    chapterCd: string;
    /**
     * 次に読むチケットコード
     * @type {string}
     * @memberof UserStory
     */
    ticketCd: string;
    /**
     * 
     * @type {StoryListMission}
     * @memberof UserStory
     */
    mission: StoryListMission;
}

/**
 * Check if a given object implements the UserStory interface.
 */
export function instanceOfUserStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "loveP1" in value;
    isInstance = isInstance && "loveP2" in value;
    isInstance = isInstance && "route" in value;
    isInstance = isInstance && "answerNo" in value;
    isInstance = isInstance && "clearCount" in value;
    isInstance = isInstance && "ticketFree" in value;
    isInstance = isInstance && "ticket" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "clearRouteList" in value;
    isInstance = isInstance && "clearMissionList" in value;
    isInstance = isInstance && "canReadTicketCdList" in value;
    isInstance = isInstance && "reread" in value;
    isInstance = isInstance && "ticketNo" in value;
    isInstance = isInstance && "chapterCd" in value;
    isInstance = isInstance && "ticketCd" in value;
    isInstance = isInstance && "mission" in value;

    return isInstance;
}

export function UserStoryFromJSON(json: any): UserStory {
    return UserStoryFromJSONTyped(json, false);
}

export function UserStoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'loveP1': json['love_p1'],
        'loveP2': json['love_p2'],
        'route': StoryRouteTypeFromJSON(json['route']),
        'answerNo': json['answer_no'],
        'clearCount': json['clear_count'],
        'ticketFree': json['ticket_free'],
        'ticket': json['ticket'],
        'point': json['point'],
        'key': json['key'],
        'clearRouteList': json['clear_route_list'],
        'clearMissionList': ((json['clear_mission_list'] as Array<any>).map(UserStoryClearMissionListInnerFromJSON)),
        'canReadTicketCdList': json['can_read_ticket_cd_list'],
        'reread': StoryListRereadFromJSON(json['reread']),
        'ticketNo': json['ticket_no'],
        'chapterCd': json['chapter_cd'],
        'ticketCd': json['ticket_cd'],
        'mission': StoryListMissionFromJSON(json['mission']),
    };
}

export function UserStoryToJSON(value?: UserStory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'love_p1': value.loveP1,
        'love_p2': value.loveP2,
        'route': StoryRouteTypeToJSON(value.route),
        'answer_no': value.answerNo,
        'clear_count': value.clearCount,
        'ticket_free': value.ticketFree,
        'ticket': value.ticket,
        'point': value.point,
        'key': value.key,
        'clear_route_list': value.clearRouteList,
        'clear_mission_list': ((value.clearMissionList as Array<any>).map(UserStoryClearMissionListInnerToJSON)),
        'can_read_ticket_cd_list': value.canReadTicketCdList,
        'reread': StoryListRereadToJSON(value.reread),
        'ticket_no': value.ticketNo,
        'chapter_cd': value.chapterCd,
        'ticket_cd': value.ticketCd,
        'mission': StoryListMissionToJSON(value.mission),
    };
}

