export interface Snackbar {
  leftImageResourceAsset?: string,
  leftImageResourcePartition?: number,
  leftImageResourceCd?: string,
  leftImagePath?: string,
  title: string,
  text: string,
  long?: boolean,
  timeout?: number,
  layout?: string,
  message?: string,
  onClickParam?: string,
  onClick?: () => void,
}
export interface SnackbarData extends Snackbar {
  id: string,
}

// スナックバーのレイアウト
export enum Layout {
  Normal = 'normal',
  Panelmission = 'panelmission',
}

/**
 * キーの取得
 * @protected
 */
const generateId = () => {
  return Math.random().toString(32).substring(2);
};

/**
 * データの追加
 * @param {Snackbar} data
 * @return {string}
 */
const add = (data: Snackbar) => {
  const id = generateId();
  useSnackbar().snackBar.value.push({
    ...data,
    id,
  });
  return id;
};

/**
 * データの削除
 * @param {string} id
 */
const remove = (id: string) => {
  const data = useSnackbar().snackBar.value;
  for (const k in data) {
    if (data[k].id === id) {
      useSnackbar().snackBar.value.splice(Number(k), 1);
    }
  }
};

/**
 * スナックバーのデータ
 * 画面を阻害しないモーダレスの表示
 */
export const useSnackbar = () => {
  const snackBar = useState<SnackbarData[]>('snackBar', () => []);

  return {
    snackBar,
    add,
    remove,
  };
};
