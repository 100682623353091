/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventRouteMission
 */
export interface EventRouteMission {
    /**
     * ミッションコード
     * @type {string}
     * @memberof EventRouteMission
     */
    missionCd: string;
    /**
     * Aルート
     * @type {string}
     * @memberof EventRouteMission
     */
    clearRequiredRouteA: string;
    /**
     * Bルート
     * @type {string}
     * @memberof EventRouteMission
     */
    clearRequiredRouteB: string;
    /**
     * Aルートに必要なパラメーター
     * @type {number}
     * @memberof EventRouteMission
     */
    routeAParameter: number;
    /**
     * Bルートに必要なパラメーター
     * @type {number}
     * @memberof EventRouteMission
     */
    routeBParameter: number;
}

/**
 * Check if a given object implements the EventRouteMission interface.
 */
export function instanceOfEventRouteMission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "clearRequiredRouteA" in value;
    isInstance = isInstance && "clearRequiredRouteB" in value;
    isInstance = isInstance && "routeAParameter" in value;
    isInstance = isInstance && "routeBParameter" in value;

    return isInstance;
}

export function EventRouteMissionFromJSON(json: any): EventRouteMission {
    return EventRouteMissionFromJSONTyped(json, false);
}

export function EventRouteMissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventRouteMission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'clearRequiredRouteA': json['clear_required_route_a'],
        'clearRequiredRouteB': json['clear_required_route_b'],
        'routeAParameter': json['route_a_parameter'],
        'routeBParameter': json['route_b_parameter'],
    };
}

export function EventRouteMissionToJSON(value?: EventRouteMission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'clear_required_route_a': value.clearRequiredRouteA,
        'clear_required_route_b': value.clearRequiredRouteB,
        'route_a_parameter': value.routeAParameter,
        'route_b_parameter': value.routeBParameter,
    };
}

