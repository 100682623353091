/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';
import type { MinigameDirection } from './MinigameDirection';
import {
    MinigameDirectionFromJSON,
    MinigameDirectionFromJSONTyped,
    MinigameDirectionToJSON,
} from './MinigameDirection';

/**
 * 
 * @export
 * @interface MinigameDirectionMstResponse
 */
export interface MinigameDirectionMstResponse {
    /**
     * 
     * @type {Array<MinigameDirection>}
     * @memberof MinigameDirectionMstResponse
     */
    data: Array<MinigameDirection>;
    /**
     * 
     * @type {MetaCommon}
     * @memberof MinigameDirectionMstResponse
     */
    meta?: MetaCommon;
}

/**
 * Check if a given object implements the MinigameDirectionMstResponse interface.
 */
export function instanceOfMinigameDirectionMstResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function MinigameDirectionMstResponseFromJSON(json: any): MinigameDirectionMstResponse {
    return MinigameDirectionMstResponseFromJSONTyped(json, false);
}

export function MinigameDirectionMstResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinigameDirectionMstResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MinigameDirectionFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : MetaCommonFromJSON(json['meta']),
    };
}

export function MinigameDirectionMstResponseToJSON(value?: MinigameDirectionMstResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(MinigameDirectionToJSON)),
        'meta': MetaCommonToJSON(value.meta),
    };
}

