/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * カードのボイス使用シチュエーション
 * @export
 */
export const CardSituationType = {
    Normal: 'normal',
    Unlock: 'unlock',
    Morning: 'morning',
    Afternoon: 'afternoon',
    Evening: 'evening',
    Night: 'night',
    Birthday: 'birthday'
} as const;
export type CardSituationType = typeof CardSituationType[keyof typeof CardSituationType];


export function CardSituationTypeFromJSON(json: any): CardSituationType {
    return CardSituationTypeFromJSONTyped(json, false);
}

export function CardSituationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardSituationType {
    return json as CardSituationType;
}

export function CardSituationTypeToJSON(value?: CardSituationType | null): any {
    return value as any;
}

