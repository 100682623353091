/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PresentType } from './PresentType';
import {
    PresentTypeFromJSON,
    PresentTypeFromJSONTyped,
    PresentTypeToJSON,
} from './PresentType';

/**
 * 
 * @export
 * @interface ShopCombinedsaleItemPresent
 */
export interface ShopCombinedsaleItemPresent {
    /**
     * プレゼントコード
     * @type {string}
     * @memberof ShopCombinedsaleItemPresent
     */
    presentCd: string;
    /**
     * クライアント側で利用する表示順序(0に近いほうが優先的に扱われる)
     * @type {number}
     * @memberof ShopCombinedsaleItemPresent
     */
    orderNo: number;
    /**
     * ショップアイテムのアイテムコード
     * @type {string}
     * @memberof ShopCombinedsaleItemPresent
     */
    itemCd: string;
    /**
     * アイテム名
     * @type {string}
     * @memberof ShopCombinedsaleItemPresent
     */
    itemName: string;
    /**
     * アイテム個数
     * @type {number}
     * @memberof ShopCombinedsaleItemPresent
     */
    itemCount: number;
    /**
     * 商品説明
     * @type {string}
     * @memberof ShopCombinedsaleItemPresent
     */
    description: string;
    /**
     * 
     * @type {PresentType}
     * @memberof ShopCombinedsaleItemPresent
     */
    type: PresentType;
}

/**
 * Check if a given object implements the ShopCombinedsaleItemPresent interface.
 */
export function instanceOfShopCombinedsaleItemPresent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "presentCd" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function ShopCombinedsaleItemPresentFromJSON(json: any): ShopCombinedsaleItemPresent {
    return ShopCombinedsaleItemPresentFromJSONTyped(json, false);
}

export function ShopCombinedsaleItemPresentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopCombinedsaleItemPresent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presentCd': json['present_cd'],
        'orderNo': json['order_no'],
        'itemCd': json['item_cd'],
        'itemName': json['item_name'],
        'itemCount': json['item_count'],
        'description': json['description'],
        'type': PresentTypeFromJSON(json['type']),
    };
}

export function ShopCombinedsaleItemPresentToJSON(value?: ShopCombinedsaleItemPresent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present_cd': value.presentCd,
        'order_no': value.orderNo,
        'item_cd': value.itemCd,
        'item_name': value.itemName,
        'item_count': value.itemCount,
        'description': value.description,
        'type': PresentTypeToJSON(value.type),
    };
}

