import { defineNuxtPlugin } from '#app';
import { isMobileApp } from '~/libs/platform';

/**
 * モバイルアプリ版専用のロジック
 */
const setupMobileApp = () => {
  // モバイル版は高さにずれが生じるので画面サイズに合わせるように変更
  const style = document.createElement('style');
  style.innerHTML = 'html, body { height: 100vh !important; }';
  document.head.appendChild(style);
};

export default defineNuxtPlugin((_) => {
  if (isMobileApp()) {
    setupMobileApp();
    useLog('MobileApp').debug('Enable setupMobileApp');
  }
});
