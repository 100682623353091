/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuCharaRequiredItem
 */
export interface CommuCharaRequiredItem {
    /**
     * アイテムコード
     * @type {string}
     * @memberof CommuCharaRequiredItem
     */
    itemCd: string;
    /**
     * アイテム数
     * @type {number}
     * @memberof CommuCharaRequiredItem
     */
    count: number;
}

/**
 * Check if a given object implements the CommuCharaRequiredItem interface.
 */
export function instanceOfCommuCharaRequiredItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "count" in value;

    return isInstance;
}

export function CommuCharaRequiredItemFromJSON(json: any): CommuCharaRequiredItem {
    return CommuCharaRequiredItemFromJSONTyped(json, false);
}

export function CommuCharaRequiredItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaRequiredItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemCd': json['item_cd'],
        'count': json['count'],
    };
}

export function CommuCharaRequiredItemToJSON(value?: CommuCharaRequiredItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_cd': value.itemCd,
        'count': value.count,
    };
}

