/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAuthAppAuthResponseData } from './UserAuthAppAuthResponseData';
import {
    UserAuthAppAuthResponseDataFromJSON,
    UserAuthAppAuthResponseDataFromJSONTyped,
    UserAuthAppAuthResponseDataToJSON,
} from './UserAuthAppAuthResponseData';

/**
 * 
 * @export
 * @interface UserAuthAppAuthResponse
 */
export interface UserAuthAppAuthResponse {
    /**
     * 
     * @type {UserAuthAppAuthResponseData}
     * @memberof UserAuthAppAuthResponse
     */
    data: UserAuthAppAuthResponseData;
}

/**
 * Check if a given object implements the UserAuthAppAuthResponse interface.
 */
export function instanceOfUserAuthAppAuthResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserAuthAppAuthResponseFromJSON(json: any): UserAuthAppAuthResponse {
    return UserAuthAppAuthResponseFromJSONTyped(json, false);
}

export function UserAuthAppAuthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthAppAuthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserAuthAppAuthResponseDataFromJSON(json['data']),
    };
}

export function UserAuthAppAuthResponseToJSON(value?: UserAuthAppAuthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserAuthAppAuthResponseDataToJSON(value.data),
    };
}

