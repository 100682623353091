/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuAddPoint } from './CommuAddPoint';
import {
    CommuAddPointFromJSON,
    CommuAddPointFromJSONTyped,
    CommuAddPointToJSON,
} from './CommuAddPoint';
import type { UserDeckRental } from './UserDeckRental';
import {
    UserDeckRentalFromJSON,
    UserDeckRentalFromJSONTyped,
    UserDeckRentalToJSON,
} from './UserDeckRental';
import type { UserMinigameBonusPresent1 } from './UserMinigameBonusPresent1';
import {
    UserMinigameBonusPresent1FromJSON,
    UserMinigameBonusPresent1FromJSONTyped,
    UserMinigameBonusPresent1ToJSON,
} from './UserMinigameBonusPresent1';

/**
 * 
 * @export
 * @interface UserEventMinigameExecute
 */
export interface UserEventMinigameExecute {
    /**
     * ミニゲームの報酬でもらえる獲得サイト内通貨
     * @type {number}
     * @memberof UserEventMinigameExecute
     */
    addGold: number;
    /**
     * ミニゲーム実行後のサイト内通貨の所持数
     * @type {number}
     * @memberof UserEventMinigameExecute
     */
    afterGold: number;
    /**
     * ミニゲームの報酬でもらえる討伐度
     * @type {number}
     * @memberof UserEventMinigameExecute
     */
    addMinigameP: number;
    /**
     * ミニゲーム実行後の討伐度
     * @type {number}
     * @memberof UserEventMinigameExecute
     */
    afterMinigameP: number;
    /**
     * ミニゲーム実行後のスタミナ
     * @type {number}
     * @memberof UserEventMinigameExecute
     */
    staminaP: number;
    /**
     * キャラコード
     * @type {string}
     * @memberof UserEventMinigameExecute
     */
    charaCd: string;
    /**
     * 0:中央値より小 1:中央値より高
     * @type {number}
     * @memberof UserEventMinigameExecute
     */
    resultStatus: number;
    /**
     * 獲得コミュpt情報
     * @type {Array<CommuAddPoint>}
     * @memberof UserEventMinigameExecute
     */
    commuAddPointList: Array<CommuAddPoint>;
    /**
     * 宝箱特典
     * @type {Array<UserMinigameBonusPresent1>}
     * @memberof UserEventMinigameExecute
     */
    bonusPresentList: Array<UserMinigameBonusPresent1>;
    /**
     * 
     * @type {UserDeckRental}
     * @memberof UserEventMinigameExecute
     */
    rental: UserDeckRental;
}

/**
 * Check if a given object implements the UserEventMinigameExecute interface.
 */
export function instanceOfUserEventMinigameExecute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "addGold" in value;
    isInstance = isInstance && "afterGold" in value;
    isInstance = isInstance && "addMinigameP" in value;
    isInstance = isInstance && "afterMinigameP" in value;
    isInstance = isInstance && "staminaP" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "resultStatus" in value;
    isInstance = isInstance && "commuAddPointList" in value;
    isInstance = isInstance && "bonusPresentList" in value;
    isInstance = isInstance && "rental" in value;

    return isInstance;
}

export function UserEventMinigameExecuteFromJSON(json: any): UserEventMinigameExecute {
    return UserEventMinigameExecuteFromJSONTyped(json, false);
}

export function UserEventMinigameExecuteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventMinigameExecute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'addGold': json['add_gold'],
        'afterGold': json['after_gold'],
        'addMinigameP': json['add_minigame_p'],
        'afterMinigameP': json['after_minigame_p'],
        'staminaP': json['stamina_p'],
        'charaCd': json['chara_cd'],
        'resultStatus': json['result_status'],
        'commuAddPointList': ((json['commu_add_point_list'] as Array<any>).map(CommuAddPointFromJSON)),
        'bonusPresentList': ((json['bonus_present_list'] as Array<any>).map(UserMinigameBonusPresent1FromJSON)),
        'rental': UserDeckRentalFromJSON(json['rental']),
    };
}

export function UserEventMinigameExecuteToJSON(value?: UserEventMinigameExecute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'add_gold': value.addGold,
        'after_gold': value.afterGold,
        'add_minigame_p': value.addMinigameP,
        'after_minigame_p': value.afterMinigameP,
        'stamina_p': value.staminaP,
        'chara_cd': value.charaCd,
        'result_status': value.resultStatus,
        'commu_add_point_list': ((value.commuAddPointList as Array<any>).map(CommuAddPointToJSON)),
        'bonus_present_list': ((value.bonusPresentList as Array<any>).map(UserMinigameBonusPresent1ToJSON)),
        'rental': UserDeckRentalToJSON(value.rental),
    };
}

