import { useNuxtApp } from '#app';
import { isMobileApp } from '~/libs/platform';

type FirebaseLogEventName = 'launch' | 'tutorial_clear';
interface FirebaseLogEventParam {
  paramType: 'string' | 'long' | 'double',
  paramName: string,
  paramValue: string,
}

/**
 * イベント送信
 * @param eventName
 * @param params
 */
const send = (eventName: FirebaseLogEventName, params: FirebaseLogEventParam[] = []) => {
  if (!isMobileApp()) {
    return;
  }
  const { $nativeBridge } = useNuxtApp();
  $nativeBridge.send('firebaseLogEvent', {
    name: eventName,
    params,
  });
};

/**
 * adjust成果の送信
 */
export const useFirebaseLogEvent = () => {
  return {
    send,
  };
};
