/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopProductBuyResponseDataUserWallet
 */
export interface ShopProductBuyResponseDataUserWallet {
    /**
     * ゴールド
     * @type {number}
     * @memberof ShopProductBuyResponseDataUserWallet
     */
    gold: number;
    /**
     * 無償ジェム
     * @type {number}
     * @memberof ShopProductBuyResponseDataUserWallet
     */
    freeGem: number;
    /**
     * 有償ジェム
     * @type {number}
     * @memberof ShopProductBuyResponseDataUserWallet
     */
    purchasedGem: number;
}

/**
 * Check if a given object implements the ShopProductBuyResponseDataUserWallet interface.
 */
export function instanceOfShopProductBuyResponseDataUserWallet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gold" in value;
    isInstance = isInstance && "freeGem" in value;
    isInstance = isInstance && "purchasedGem" in value;

    return isInstance;
}

export function ShopProductBuyResponseDataUserWalletFromJSON(json: any): ShopProductBuyResponseDataUserWallet {
    return ShopProductBuyResponseDataUserWalletFromJSONTyped(json, false);
}

export function ShopProductBuyResponseDataUserWalletFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopProductBuyResponseDataUserWallet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gold': json['gold'],
        'freeGem': json['free_gem'],
        'purchasedGem': json['purchased_gem'],
    };
}

export function ShopProductBuyResponseDataUserWalletToJSON(value?: ShopProductBuyResponseDataUserWallet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gold': value.gold,
        'free_gem': value.freeGem,
        'purchased_gem': value.purchasedGem,
    };
}

