/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MissionRouteConditions } from './MissionRouteConditions';
import {
    MissionRouteConditionsFromJSON,
    MissionRouteConditionsFromJSONTyped,
    MissionRouteConditionsToJSON,
} from './MissionRouteConditions';

/**
 * 
 * @export
 * @interface MissionRoute
 */
export interface MissionRoute {
    /**
     * ミッションコード
     * @type {string}
     * @memberof MissionRoute
     */
    missionCd: string;
    /**
     * 
     * @type {MissionRouteConditions}
     * @memberof MissionRoute
     */
    conditions: MissionRouteConditions;
}

/**
 * Check if a given object implements the MissionRoute interface.
 */
export function instanceOfMissionRoute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "conditions" in value;

    return isInstance;
}

export function MissionRouteFromJSON(json: any): MissionRoute {
    return MissionRouteFromJSONTyped(json, false);
}

export function MissionRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionRoute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'conditions': MissionRouteConditionsFromJSON(json['conditions']),
    };
}

export function MissionRouteToJSON(value?: MissionRoute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'conditions': MissionRouteConditionsToJSON(value.conditions),
    };
}

