/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventRouteName
 */
export interface EventRouteName {
    /**
     * イベントルートコード
     * @type {string}
     * @memberof EventRouteName
     */
    eventRouteCd: string;
    /**
     * ルート名
     * @type {string}
     * @memberof EventRouteName
     */
    routeName: string;
}

/**
 * Check if a given object implements the EventRouteName interface.
 */
export function instanceOfEventRouteName(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventRouteCd" in value;
    isInstance = isInstance && "routeName" in value;

    return isInstance;
}

export function EventRouteNameFromJSON(json: any): EventRouteName {
    return EventRouteNameFromJSONTyped(json, false);
}

export function EventRouteNameFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventRouteName {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventRouteCd': json['event_route_cd'],
        'routeName': json['route_name'],
    };
}

export function EventRouteNameToJSON(value?: EventRouteName | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_route_cd': value.eventRouteCd,
        'route_name': value.routeName,
    };
}

