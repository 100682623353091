/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventEpilogueChara } from './UserEventEpilogueChara';
import {
    UserEventEpilogueCharaFromJSON,
    UserEventEpilogueCharaFromJSONTyped,
    UserEventEpilogueCharaToJSON,
} from './UserEventEpilogueChara';

/**
 * 
 * @export
 * @interface UserEventEpilogue
 */
export interface UserEventEpilogue {
    /**
     * アバター所持上限フラグ
     * @type {boolean}
     * @memberof UserEventEpilogue
     */
    avatarMaxFlg: boolean;
    /**
     * エピローグキャラ情報
     * @type {Array<UserEventEpilogueChara>}
     * @memberof UserEventEpilogue
     */
    charaList: Array<UserEventEpilogueChara>;
}

/**
 * Check if a given object implements the UserEventEpilogue interface.
 */
export function instanceOfUserEventEpilogue(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatarMaxFlg" in value;
    isInstance = isInstance && "charaList" in value;

    return isInstance;
}

export function UserEventEpilogueFromJSON(json: any): UserEventEpilogue {
    return UserEventEpilogueFromJSONTyped(json, false);
}

export function UserEventEpilogueFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventEpilogue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatarMaxFlg': json['avatar_max_flg'],
        'charaList': ((json['chara_list'] as Array<any>).map(UserEventEpilogueCharaFromJSON)),
    };
}

export function UserEventEpilogueToJSON(value?: UserEventEpilogue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_max_flg': value.avatarMaxFlg,
        'chara_list': ((value.charaList as Array<any>).map(UserEventEpilogueCharaToJSON)),
    };
}

