/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGachaItem } from './UserGachaItem';
import {
    UserGachaItemFromJSON,
    UserGachaItemFromJSONTyped,
    UserGachaItemToJSON,
} from './UserGachaItem';

/**
 * 
 * @export
 * @interface UserGachaPresentList
 */
export interface UserGachaPresentList {
    /**
     * 星◯
     * @type {number}
     * @memberof UserGachaPresentList
     */
    rarity: number;
    /**
     * ガチャアイテムコード
     * @type {string}
     * @memberof UserGachaPresentList
     */
    givePresentCd: string;
    /**
     * 
     * @type {UserGachaItem}
     * @memberof UserGachaPresentList
     */
    giveItem: UserGachaItem;
    /**
     * 星◯
     * @type {boolean}
     * @memberof UserGachaPresentList
     */
    newFlg: boolean;
}

/**
 * Check if a given object implements the UserGachaPresentList interface.
 */
export function instanceOfUserGachaPresentList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rarity" in value;
    isInstance = isInstance && "givePresentCd" in value;
    isInstance = isInstance && "giveItem" in value;
    isInstance = isInstance && "newFlg" in value;

    return isInstance;
}

export function UserGachaPresentListFromJSON(json: any): UserGachaPresentList {
    return UserGachaPresentListFromJSONTyped(json, false);
}

export function UserGachaPresentListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaPresentList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rarity': json['rarity'],
        'givePresentCd': json['give_present_cd'],
        'giveItem': UserGachaItemFromJSON(json['give_item']),
        'newFlg': json['new_flg'],
    };
}

export function UserGachaPresentListToJSON(value?: UserGachaPresentList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rarity': value.rarity,
        'give_present_cd': value.givePresentCd,
        'give_item': UserGachaItemToJSON(value.giveItem),
        'new_flg': value.newFlg,
    };
}

