<template>
  <div class="btn" :class="classNames" @click.stop="onClick()">
    <div class="flex flex-col justify-center items-center h-full">
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useSe } from '~/composables/useAudio';

const props = withDefaults(defineProps<{
  to?: string,
  type?: ButtonType,
  sound?: boolean,
  disabled?: boolean,
  clickPrevention?: boolean,
}>(), {
  to: undefined,
  type: 'primary',
  sound: true,
  disabled: false,
  clickPrevention: false,
});
interface Emits {
  (e: 'actionClick'): void,
}
const emit = defineEmits<Emits>();

const classNames = computed(() => {
  const names = ['i-btn', 'i-btn-' + props.type];
  if (noActive.value) {
    names.push('no-active');
  }
  if (props.disabled) {
    names.push('disabled');
  }
  return names;
});

// 二重クリックの抑制
const beforeTime = ref<number>();
const noActive = ref<boolean>(false);
// クリックを抑制する期間
const clickDisableTime = 300;
const onClick = () => {
  if (props.disabled) {
    return;
  }
  // 二重クリックの抑制
  if (props.clickPrevention) {
    const now = Date.now();
    if (beforeTime.value && ((now - beforeTime.value) <= clickDisableTime)) {
      noActive.value = true;
      return;
    }
    beforeTime.value = now;
  }
  noActive.value = false;
  if (props.sound) {
    if (props.type === 'secondary') {
      useSe(0, '2002')?.play();
    } else {
      useSe(0, '2001')?.play();
    }
  }
  if (props.to) {
    navigateTo(props.to);
  }
  emit('actionClick');
};
</script>

<style lang="scss" scoped>
.btn {
  cursor: pointer;
}
</style>
