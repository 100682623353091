/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventUserMinigameHistoryHistoriesInner
 */
export interface EventUserMinigameHistoryHistoriesInner {
    /**
     * シナイベミニゲーム実施日時
     * @type {string}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    playedAt: string;
    /**
     * キャラCD
     * @type {string}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    charaCd: string;
    /**
     * 加算したゴールド
     * @type {number}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    addGold: number;
    /**
     * シナイベミニゲーム実行後の所持ゴールド
     * @type {number}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    afterGold: number;
    /**
     * 加算したシナイベミニゲームポイント
     * @type {number}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    addEventMinigameP: number;
    /**
     * シナイベミニゲーム実行後のシナイベミニゲームポイント
     * @type {number}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    afterEventMinigameP: number;
    /**
     * シナイベミニゲーム実行後のスタミナポイント
     * @type {number}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    consumedStaminaP: number;
    /**
     * 消費スタミナポイント
     * @type {number}
     * @memberof EventUserMinigameHistoryHistoriesInner
     */
    afterStaminaP: number;
}

/**
 * Check if a given object implements the EventUserMinigameHistoryHistoriesInner interface.
 */
export function instanceOfEventUserMinigameHistoryHistoriesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "playedAt" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "addGold" in value;
    isInstance = isInstance && "afterGold" in value;
    isInstance = isInstance && "addEventMinigameP" in value;
    isInstance = isInstance && "afterEventMinigameP" in value;
    isInstance = isInstance && "consumedStaminaP" in value;
    isInstance = isInstance && "afterStaminaP" in value;

    return isInstance;
}

export function EventUserMinigameHistoryHistoriesInnerFromJSON(json: any): EventUserMinigameHistoryHistoriesInner {
    return EventUserMinigameHistoryHistoriesInnerFromJSONTyped(json, false);
}

export function EventUserMinigameHistoryHistoriesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventUserMinigameHistoryHistoriesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playedAt': json['played_at'],
        'charaCd': json['chara_cd'],
        'addGold': json['add_gold'],
        'afterGold': json['after_gold'],
        'addEventMinigameP': json['add_event_minigame_p'],
        'afterEventMinigameP': json['after_event_minigame_p'],
        'consumedStaminaP': json['consumed_stamina_p'],
        'afterStaminaP': json['after_stamina_p'],
    };
}

export function EventUserMinigameHistoryHistoriesInnerToJSON(value?: EventUserMinigameHistoryHistoriesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'played_at': value.playedAt,
        'chara_cd': value.charaCd,
        'add_gold': value.addGold,
        'after_gold': value.afterGold,
        'add_event_minigame_p': value.addEventMinigameP,
        'after_event_minigame_p': value.afterEventMinigameP,
        'consumed_stamina_p': value.consumedStaminaP,
        'after_stamina_p': value.afterStaminaP,
    };
}

