/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';
import type { UserMypage } from './UserMypage';
import {
    UserMypageFromJSON,
    UserMypageFromJSONTyped,
    UserMypageToJSON,
} from './UserMypage';

/**
 * 
 * @export
 * @interface UserMypageResponse
 */
export interface UserMypageResponse {
    /**
     * 
     * @type {UserMypage}
     * @memberof UserMypageResponse
     */
    data: UserMypage;
    /**
     * 
     * @type {MetaCommon}
     * @memberof UserMypageResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the UserMypageResponse interface.
 */
export function instanceOfUserMypageResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function UserMypageResponseFromJSON(json: any): UserMypageResponse {
    return UserMypageResponseFromJSONTyped(json, false);
}

export function UserMypageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMypageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserMypageFromJSON(json['data']),
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function UserMypageResponseToJSON(value?: UserMypageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserMypageToJSON(value.data),
        'meta': MetaCommonToJSON(value.meta),
    };
}

