/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventMstEpilogChara } from './EventMstEpilogChara';
import {
    EventMstEpilogCharaFromJSON,
    EventMstEpilogCharaFromJSONTyped,
    EventMstEpilogCharaToJSON,
} from './EventMstEpilogChara';

/**
 * 
 * @export
 * @interface EventEpilog
 */
export interface EventEpilog {
    /**
     * 開始日時
     * @type {string}
     * @memberof EventEpilog
     */
    startedAt: string;
    /**
     * 終了日時
     * @type {string}
     * @memberof EventEpilog
     */
    endedAt: string;
    /**
     * キャラリスト
     * @type {Array<EventMstEpilogChara>}
     * @memberof EventEpilog
     */
    charaList: Array<EventMstEpilogChara>;
}

/**
 * Check if a given object implements the EventEpilog interface.
 */
export function instanceOfEventEpilog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "charaList" in value;

    return isInstance;
}

export function EventEpilogFromJSON(json: any): EventEpilog {
    return EventEpilogFromJSONTyped(json, false);
}

export function EventEpilogFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventEpilog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'charaList': ((json['chara_list'] as Array<any>).map(EventMstEpilogCharaFromJSON)),
    };
}

export function EventEpilogToJSON(value?: EventEpilog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'chara_list': ((value.charaList as Array<any>).map(EventMstEpilogCharaToJSON)),
    };
}

