/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Still } from './Still';
import {
    StillFromJSON,
    StillFromJSONTyped,
    StillToJSON,
} from './Still';

/**
 * 
 * @export
 * @interface StillMstResponse
 */
export interface StillMstResponse {
    /**
     * 
     * @type {Array<Still>}
     * @memberof StillMstResponse
     */
    data: Array<Still>;
}

/**
 * Check if a given object implements the StillMstResponse interface.
 */
export function instanceOfStillMstResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function StillMstResponseFromJSON(json: any): StillMstResponse {
    return StillMstResponseFromJSONTyped(json, false);
}

export function StillMstResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StillMstResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(StillFromJSON)),
    };
}

export function StillMstResponseToJSON(value?: StillMstResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(StillToJSON)),
    };
}

