/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuLevel } from './CommuLevel';
import {
    CommuLevelFromJSON,
    CommuLevelFromJSONTyped,
    CommuLevelToJSON,
} from './CommuLevel';

/**
 * 
 * @export
 * @interface CommuAddPoint
 */
export interface CommuAddPoint {
    /**
     * キャラコード
     * @type {string}
     * @memberof CommuAddPoint
     */
    charaCd: string;
    /**
     * 限界突破コード
     * @type {string}
     * @memberof CommuAddPoint
     */
    commuCharaLimitBreakCd: string;
    /**
     * 加算コミュポイント
     * @type {number}
     * @memberof CommuAddPoint
     */
    addCommuPoint: number;
    /**
     * 累計コミュポイント
     * @type {number}
     * @memberof CommuAddPoint
     */
    totalCommuPoint: number;
    /**
     * 現在のレベル
     * @type {number}
     * @memberof CommuAddPoint
     */
    nowLevel: number;
    /**
     * レベルアップ後のレベル(レベルアップなしならnull)
     * @type {number}
     * @memberof CommuAddPoint
     */
    nextLevel: number | null;
    /**
     * 現在のレベルでの次のレベルまでに必要なポイント
     * @type {number}
     * @memberof CommuAddPoint
     */
    nowRequiredPoint: number;
    /**
     * レベルアップ後のレベルでの次のレベルまでに必要なポイント(レベルアップなしならnull)
     * @type {number}
     * @memberof CommuAddPoint
     */
    nextRequiredPoint: number | null;
    /**
     * 加算前のレベルの残り必要コミュポイント
     * @type {number}
     * @memberof CommuAddPoint
     */
    nowRemainPoint: number | null;
    /**
     * レベルアップ後のレベルの残り必要コミュポイント(レベルアップなしならnull)
     * @type {number}
     * @memberof CommuAddPoint
     */
    nextRemainPoint: number | null;
    /**
     * レベルパターンリスト
     * @type {Array<CommuLevel>}
     * @memberof CommuAddPoint
     */
    commuLevelList: Array<CommuLevel>;
}

/**
 * Check if a given object implements the CommuAddPoint interface.
 */
export function instanceOfCommuAddPoint(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "commuCharaLimitBreakCd" in value;
    isInstance = isInstance && "addCommuPoint" in value;
    isInstance = isInstance && "totalCommuPoint" in value;
    isInstance = isInstance && "nowLevel" in value;
    isInstance = isInstance && "nextLevel" in value;
    isInstance = isInstance && "nowRequiredPoint" in value;
    isInstance = isInstance && "nextRequiredPoint" in value;
    isInstance = isInstance && "nowRemainPoint" in value;
    isInstance = isInstance && "nextRemainPoint" in value;
    isInstance = isInstance && "commuLevelList" in value;

    return isInstance;
}

export function CommuAddPointFromJSON(json: any): CommuAddPoint {
    return CommuAddPointFromJSONTyped(json, false);
}

export function CommuAddPointFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuAddPoint {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'commuCharaLimitBreakCd': json['commu_chara_limit_break_cd'],
        'addCommuPoint': json['add_commu_point'],
        'totalCommuPoint': json['total_commu_point'],
        'nowLevel': json['now_level'],
        'nextLevel': json['next_level'],
        'nowRequiredPoint': json['now_required_point'],
        'nextRequiredPoint': json['next_required_point'],
        'nowRemainPoint': json['now_remain_point'],
        'nextRemainPoint': json['next_remain_point'],
        'commuLevelList': ((json['commu_level_list'] as Array<any>).map(CommuLevelFromJSON)),
    };
}

export function CommuAddPointToJSON(value?: CommuAddPoint | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'commu_chara_limit_break_cd': value.commuCharaLimitBreakCd,
        'add_commu_point': value.addCommuPoint,
        'total_commu_point': value.totalCommuPoint,
        'now_level': value.nowLevel,
        'next_level': value.nextLevel,
        'now_required_point': value.nowRequiredPoint,
        'next_required_point': value.nextRequiredPoint,
        'now_remain_point': value.nowRemainPoint,
        'next_remain_point': value.nextRemainPoint,
        'commu_level_list': ((value.commuLevelList as Array<any>).map(CommuLevelToJSON)),
    };
}

