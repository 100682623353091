/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserShopCombinedsaleItem
 */
export interface UserShopCombinedsaleItem {
    /**
     * セット販売アイテムコード
     * @type {string}
     * @memberof UserShopCombinedsaleItem
     */
    combinedsaleItemCd: string;
    /**
     * ショップアイテムのアイテムコード
     * @type {string}
     * @memberof UserShopCombinedsaleItem
     */
    itemCd?: string;
    /**
     * 開始日時
     * @type {string}
     * @memberof UserShopCombinedsaleItem
     */
    startedAt: string | null;
    /**
     * 終了日時
     * @type {string}
     * @memberof UserShopCombinedsaleItem
     */
    endedAt: string | null;
    /**
     * セットアイテムに紐づくストーリーを所持している(所持済み：1、未所持のストーリーあり：0)
     * @type {boolean}
     * @memberof UserShopCombinedsaleItem
     */
    hasStoryFlg: boolean;
}

/**
 * Check if a given object implements the UserShopCombinedsaleItem interface.
 */
export function instanceOfUserShopCombinedsaleItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "combinedsaleItemCd" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "hasStoryFlg" in value;

    return isInstance;
}

export function UserShopCombinedsaleItemFromJSON(json: any): UserShopCombinedsaleItem {
    return UserShopCombinedsaleItemFromJSONTyped(json, false);
}

export function UserShopCombinedsaleItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserShopCombinedsaleItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'combinedsaleItemCd': json['combinedsale_item_cd'],
        'itemCd': !exists(json, 'item_cd') ? undefined : json['item_cd'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'hasStoryFlg': json['has_story_flg'],
    };
}

export function UserShopCombinedsaleItemToJSON(value?: UserShopCombinedsaleItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'combinedsale_item_cd': value.combinedsaleItemCd,
        'item_cd': value.itemCd,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'has_story_flg': value.hasStoryFlg,
    };
}

