/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * お着替え情報
 * @export
 * @interface AvatarEquip
 */
export interface AvatarEquip {
    /**
     * キャラコード
     * @type {string}
     * @memberof AvatarEquip
     */
    charaCd: string | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof AvatarEquip
     */
    userAvatarId: Array<number>;
}

/**
 * Check if a given object implements the AvatarEquip interface.
 */
export function instanceOfAvatarEquip(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "userAvatarId" in value;

    return isInstance;
}

export function AvatarEquipFromJSON(json: any): AvatarEquip {
    return AvatarEquipFromJSONTyped(json, false);
}

export function AvatarEquipFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvatarEquip {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'userAvatarId': json['user_avatar_id'],
    };
}

export function AvatarEquipToJSON(value?: AvatarEquip | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'user_avatar_id': value.userAvatarId,
    };
}

