/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SynthesisCard
 */
export interface SynthesisCard {
    /**
     * カードコード
     * @type {string}
     * @memberof SynthesisCard
     */
    cardCd: string;
    /**
     * 受け取り前個数
     * @type {number}
     * @memberof SynthesisCard
     */
    beforeCount: number;
    /**
     * 受け取り後個数
     * @type {number}
     * @memberof SynthesisCard
     */
    afterCount: number;
    /**
     * 受け取り前パラメータ
     * @type {number}
     * @memberof SynthesisCard
     */
    beforeParameter: number;
    /**
     * 受け取り後パラメータ
     * @type {number}
     * @memberof SynthesisCard
     */
    afterParameter: number;
}

/**
 * Check if a given object implements the SynthesisCard interface.
 */
export function instanceOfSynthesisCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "beforeCount" in value;
    isInstance = isInstance && "afterCount" in value;
    isInstance = isInstance && "beforeParameter" in value;
    isInstance = isInstance && "afterParameter" in value;

    return isInstance;
}

export function SynthesisCardFromJSON(json: any): SynthesisCard {
    return SynthesisCardFromJSONTyped(json, false);
}

export function SynthesisCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): SynthesisCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardCd': json['card_cd'],
        'beforeCount': json['before_count'],
        'afterCount': json['after_count'],
        'beforeParameter': json['before_parameter'],
        'afterParameter': json['after_parameter'],
    };
}

export function SynthesisCardToJSON(value?: SynthesisCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_cd': value.cardCd,
        'before_count': value.beforeCount,
        'after_count': value.afterCount,
        'before_parameter': value.beforeParameter,
        'after_parameter': value.afterParameter,
    };
}

