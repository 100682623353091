/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoryStatusCurrent } from './StoryStatusCurrent';
import {
    StoryStatusCurrentFromJSON,
    StoryStatusCurrentFromJSONTyped,
    StoryStatusCurrentToJSON,
} from './StoryStatusCurrent';
import type { StoryStatusNext } from './StoryStatusNext';
import {
    StoryStatusNextFromJSON,
    StoryStatusNextFromJSONTyped,
    StoryStatusNextToJSON,
} from './StoryStatusNext';

/**
 * 
 * @export
 * @interface UserStoryStatus
 */
export interface UserStoryStatus {
    /**
     * キャラコード
     * @type {string}
     * @memberof UserStoryStatus
     */
    charaCd: string;
    /**
     * クリア回数
     * @type {number}
     * @memberof UserStoryStatus
     */
    clearCount: number;
    /**
     * 現在選択中か
     * @type {boolean}
     * @memberof UserStoryStatus
     */
    selectFlg: boolean;
    /**
     * 
     * @type {StoryStatusCurrent}
     * @memberof UserStoryStatus
     */
    current: StoryStatusCurrent;
    /**
     * 
     * @type {StoryStatusNext}
     * @memberof UserStoryStatus
     */
    next: StoryStatusNext;
    /**
     * aルートクリア
     * @type {boolean}
     * @memberof UserStoryStatus
     */
    clearAFlg: boolean;
    /**
     * bルートクリア
     * @type {boolean}
     * @memberof UserStoryStatus
     */
    clearBFlg: boolean;
    /**
     * お相手選択時に獲得できるアイテムのプレゼントアイテムコード
     * @type {string}
     * @memberof UserStoryStatus
     */
    partnerCardPresentCd: string;
}

/**
 * Check if a given object implements the UserStoryStatus interface.
 */
export function instanceOfUserStoryStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "clearCount" in value;
    isInstance = isInstance && "selectFlg" in value;
    isInstance = isInstance && "current" in value;
    isInstance = isInstance && "next" in value;
    isInstance = isInstance && "clearAFlg" in value;
    isInstance = isInstance && "clearBFlg" in value;
    isInstance = isInstance && "partnerCardPresentCd" in value;

    return isInstance;
}

export function UserStoryStatusFromJSON(json: any): UserStoryStatus {
    return UserStoryStatusFromJSONTyped(json, false);
}

export function UserStoryStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStoryStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'clearCount': json['clear_count'],
        'selectFlg': json['select_flg'],
        'current': StoryStatusCurrentFromJSON(json['current']),
        'next': StoryStatusNextFromJSON(json['next']),
        'clearAFlg': json['clear_a_flg'],
        'clearBFlg': json['clear_b_flg'],
        'partnerCardPresentCd': json['partner_card_present_cd'],
    };
}

export function UserStoryStatusToJSON(value?: UserStoryStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'clear_count': value.clearCount,
        'select_flg': value.selectFlg,
        'current': StoryStatusCurrentToJSON(value.current),
        'next': StoryStatusNextToJSON(value.next),
        'clear_a_flg': value.clearAFlg,
        'clear_b_flg': value.clearBFlg,
        'partner_card_present_cd': value.partnerCardPresentCd,
    };
}

