/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MissionType } from './MissionType';
import {
    MissionTypeFromJSON,
    MissionTypeFromJSONTyped,
    MissionTypeToJSON,
} from './MissionType';

/**
 * 発生中のミッション
 * @export
 * @interface EventStoryListMission
 */
export interface EventStoryListMission {
    /**
     * ミッションコード
     * @type {string}
     * @memberof EventStoryListMission
     */
    missionCd: string;
    /**
     * 
     * @type {MissionType}
     * @memberof EventStoryListMission
     */
    type: MissionType;
}

/**
 * Check if a given object implements the EventStoryListMission interface.
 */
export function instanceOfEventStoryListMission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function EventStoryListMissionFromJSON(json: any): EventStoryListMission {
    return EventStoryListMissionFromJSONTyped(json, false);
}

export function EventStoryListMissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStoryListMission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'type': MissionTypeFromJSON(json['type']),
    };
}

export function EventStoryListMissionToJSON(value?: EventStoryListMission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'type': MissionTypeToJSON(value.type),
    };
}

