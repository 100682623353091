/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuAddPoint } from './CommuAddPoint';
import {
    CommuAddPointFromJSON,
    CommuAddPointFromJSONTyped,
    CommuAddPointToJSON,
} from './CommuAddPoint';
import type { UserStoryBothRouteClearBonus } from './UserStoryBothRouteClearBonus';
import {
    UserStoryBothRouteClearBonusFromJSON,
    UserStoryBothRouteClearBonusFromJSONTyped,
    UserStoryBothRouteClearBonusToJSON,
} from './UserStoryBothRouteClearBonus';
import type { UserStoryCompleteHisStoryData } from './UserStoryCompleteHisStoryData';
import {
    UserStoryCompleteHisStoryDataFromJSON,
    UserStoryCompleteHisStoryDataFromJSONTyped,
    UserStoryCompleteHisStoryDataToJSON,
} from './UserStoryCompleteHisStoryData';
import type { UserStoryCompleteMission } from './UserStoryCompleteMission';
import {
    UserStoryCompleteMissionFromJSON,
    UserStoryCompleteMissionFromJSONTyped,
    UserStoryCompleteMissionToJSON,
} from './UserStoryCompleteMission';
import type { UserStoryInitBonus } from './UserStoryInitBonus';
import {
    UserStoryInitBonusFromJSON,
    UserStoryInitBonusFromJSONTyped,
    UserStoryInitBonusToJSON,
} from './UserStoryInitBonus';
import type { UserStoryRouteClearBonus } from './UserStoryRouteClearBonus';
import {
    UserStoryRouteClearBonusFromJSON,
    UserStoryRouteClearBonusFromJSONTyped,
    UserStoryRouteClearBonusToJSON,
} from './UserStoryRouteClearBonus';

/**
 * 
 * @export
 * @interface UserStoryComplete
 */
export interface UserStoryComplete {
    /**
     * 読んだチケットのチャプターコード
     * @type {string}
     * @memberof UserStoryComplete
     */
    readTicketChapterCd: string;
    /**
     * 読んだチケットのチケットコード
     * @type {string}
     * @memberof UserStoryComplete
     */
    readTicketTicketCd: string;
    /**
     * 読んだチケットがチャプターの何チケット目か
     * @type {number}
     * @memberof UserStoryComplete
     */
    readTicketOrderNo: number;
    /**
     * 読んだチケットがチャプターの最後のチケットか 1:最後 0:最後ではない
     * @type {boolean}
     * @memberof UserStoryComplete
     */
    isChapterLastTicket: boolean;
    /**
     * 次のチケットコード
     * @type {string}
     * @memberof UserStoryComplete
     */
    nextTicketCd: string;
    /**
     * 次のチケットのチャプターコード
     * @type {string}
     * @memberof UserStoryComplete
     */
    nextTicketChapterCd: string;
    /**
     * 次のチケットが何チケット目か
     * @type {number}
     * @memberof UserStoryComplete
     */
    nextTicketOrderNo: number;
    /**
     * アバターミッションをプレミアルートでクリアした場合に獲得できる特別ストーリーのチケットCD
     * @type {string}
     * @memberof UserStoryComplete
     */
    specialStoryTicketCd: string;
    /**
     * 
     * @type {UserStoryCompleteHisStoryData}
     * @memberof UserStoryComplete
     */
    hisStoryData: UserStoryCompleteHisStoryData;
    /**
     * チケットアイテムの消費数
     * @type {number}
     * @memberof UserStoryComplete
     */
    useTicketItemCount: number;
    /**
     * ジェムの消費数
     * @type {number}
     * @memberof UserStoryComplete
     */
    useGemCount: number;
    /**
     * 次回予告文言
     * @type {string}
     * @memberof UserStoryComplete
     */
    notice: string;
    /**
     * 好感度パラメーターの上昇値
     * @type {number}
     * @memberof UserStoryComplete
     */
    addLovePA: number | null;
    /**
     * 好感度パラメーターの上昇値後の値
     * @type {number}
     * @memberof UserStoryComplete
     */
    totalLovePA: number | null;
    /**
     * 好感度パラメーターの上昇値
     * @type {number}
     * @memberof UserStoryComplete
     */
    addLovePB: number | null;
    /**
     * 好感度パラメーターの上昇値後の値
     * @type {number}
     * @memberof UserStoryComplete
     */
    totalLovePB: number | null;
    /**
     * 読了報酬プレゼントアイテム情報
     * @type {Array<UserStoryRouteClearBonus>}
     * @memberof UserStoryComplete
     */
    routeClearBonus: Array<UserStoryRouteClearBonus>;
    /**
     * 読了報酬プレゼントアイテム情報
     * @type {Array<UserStoryBothRouteClearBonus>}
     * @memberof UserStoryComplete
     */
    bothRouteClearBonus: Array<UserStoryBothRouteClearBonus>;
    /**
     * 読了報酬プレゼントアイテム情報
     * @type {Array<UserStoryInitBonus>}
     * @memberof UserStoryComplete
     */
    initBonus: Array<UserStoryInitBonus>;
    /**
     * スチルコード
     * @type {string}
     * @memberof UserStoryComplete
     */
    stillCd: string;
    /**
     * カードコード
     * @type {string}
     * @memberof UserStoryComplete
     */
    cardCd: string;
    /**
     * 逆ルート訴求の画像名 訴求を表示しない場合はnull
     * @type {string}
     * @memberof UserStoryComplete
     */
    routeAppealName: string;
    /**
     * 両ルートクリア時に獲得できる報酬の画像名 訴求を表示しない場合はnull
     * @type {string}
     * @memberof UserStoryComplete
     */
    bothRouteBonusAppealName: string | null;
    /**
     * コミュpt上昇結果
     * @type {Array<CommuAddPoint>}
     * @memberof UserStoryComplete
     */
    commuAddPointList: Array<CommuAddPoint>;
    /**
     * 現在選択中のキャラのクリア回数
     * @type {number}
     * @memberof UserStoryComplete
     */
    clearCount: number;
    /**
     * 
     * @type {UserStoryCompleteMission}
     * @memberof UserStoryComplete
     */
    mission: UserStoryCompleteMission;
}

/**
 * Check if a given object implements the UserStoryComplete interface.
 */
export function instanceOfUserStoryComplete(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "readTicketChapterCd" in value;
    isInstance = isInstance && "readTicketTicketCd" in value;
    isInstance = isInstance && "readTicketOrderNo" in value;
    isInstance = isInstance && "isChapterLastTicket" in value;
    isInstance = isInstance && "nextTicketCd" in value;
    isInstance = isInstance && "nextTicketChapterCd" in value;
    isInstance = isInstance && "nextTicketOrderNo" in value;
    isInstance = isInstance && "specialStoryTicketCd" in value;
    isInstance = isInstance && "hisStoryData" in value;
    isInstance = isInstance && "useTicketItemCount" in value;
    isInstance = isInstance && "useGemCount" in value;
    isInstance = isInstance && "notice" in value;
    isInstance = isInstance && "addLovePA" in value;
    isInstance = isInstance && "totalLovePA" in value;
    isInstance = isInstance && "addLovePB" in value;
    isInstance = isInstance && "totalLovePB" in value;
    isInstance = isInstance && "routeClearBonus" in value;
    isInstance = isInstance && "bothRouteClearBonus" in value;
    isInstance = isInstance && "initBonus" in value;
    isInstance = isInstance && "stillCd" in value;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "routeAppealName" in value;
    isInstance = isInstance && "bothRouteBonusAppealName" in value;
    isInstance = isInstance && "commuAddPointList" in value;
    isInstance = isInstance && "clearCount" in value;
    isInstance = isInstance && "mission" in value;

    return isInstance;
}

export function UserStoryCompleteFromJSON(json: any): UserStoryComplete {
    return UserStoryCompleteFromJSONTyped(json, false);
}

export function UserStoryCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStoryComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'readTicketChapterCd': json['read_ticket_chapter_cd'],
        'readTicketTicketCd': json['read_ticket_ticket_cd'],
        'readTicketOrderNo': json['read_ticket_order_no'],
        'isChapterLastTicket': json['is_chapter_last_ticket'],
        'nextTicketCd': json['next_ticket_cd'],
        'nextTicketChapterCd': json['next_ticket_chapter_cd'],
        'nextTicketOrderNo': json['next_ticket_order_no'],
        'specialStoryTicketCd': json['special_story_ticket_cd'],
        'hisStoryData': UserStoryCompleteHisStoryDataFromJSON(json['his_story_data']),
        'useTicketItemCount': json['use_ticket_item_count'],
        'useGemCount': json['use_gem_count'],
        'notice': json['notice'],
        'addLovePA': json['add_love_p_a'],
        'totalLovePA': json['total_love_p_a'],
        'addLovePB': json['add_love_p_b'],
        'totalLovePB': json['total_love_p_b'],
        'routeClearBonus': ((json['route_clear_bonus'] as Array<any>).map(UserStoryRouteClearBonusFromJSON)),
        'bothRouteClearBonus': ((json['both_route_clear_bonus'] as Array<any>).map(UserStoryBothRouteClearBonusFromJSON)),
        'initBonus': ((json['init_bonus'] as Array<any>).map(UserStoryInitBonusFromJSON)),
        'stillCd': json['still_cd'],
        'cardCd': json['card_cd'],
        'routeAppealName': json['route_appeal_name'],
        'bothRouteBonusAppealName': json['both_route_bonus_appeal_name'],
        'commuAddPointList': ((json['commu_add_point_list'] as Array<any>).map(CommuAddPointFromJSON)),
        'clearCount': json['clear_count'],
        'mission': UserStoryCompleteMissionFromJSON(json['mission']),
    };
}

export function UserStoryCompleteToJSON(value?: UserStoryComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'read_ticket_chapter_cd': value.readTicketChapterCd,
        'read_ticket_ticket_cd': value.readTicketTicketCd,
        'read_ticket_order_no': value.readTicketOrderNo,
        'is_chapter_last_ticket': value.isChapterLastTicket,
        'next_ticket_cd': value.nextTicketCd,
        'next_ticket_chapter_cd': value.nextTicketChapterCd,
        'next_ticket_order_no': value.nextTicketOrderNo,
        'special_story_ticket_cd': value.specialStoryTicketCd,
        'his_story_data': UserStoryCompleteHisStoryDataToJSON(value.hisStoryData),
        'use_ticket_item_count': value.useTicketItemCount,
        'use_gem_count': value.useGemCount,
        'notice': value.notice,
        'add_love_p_a': value.addLovePA,
        'total_love_p_a': value.totalLovePA,
        'add_love_p_b': value.addLovePB,
        'total_love_p_b': value.totalLovePB,
        'route_clear_bonus': ((value.routeClearBonus as Array<any>).map(UserStoryRouteClearBonusToJSON)),
        'both_route_clear_bonus': ((value.bothRouteClearBonus as Array<any>).map(UserStoryBothRouteClearBonusToJSON)),
        'init_bonus': ((value.initBonus as Array<any>).map(UserStoryInitBonusToJSON)),
        'still_cd': value.stillCd,
        'card_cd': value.cardCd,
        'route_appeal_name': value.routeAppealName,
        'both_route_bonus_appeal_name': value.bothRouteBonusAppealName,
        'commu_add_point_list': ((value.commuAddPointList as Array<any>).map(CommuAddPointToJSON)),
        'clear_count': value.clearCount,
        'mission': UserStoryCompleteMissionToJSON(value.mission),
    };
}

