/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CardSituationType } from './CardSituationType';
import {
    CardSituationTypeFromJSON,
    CardSituationTypeFromJSONTyped,
    CardSituationTypeToJSON,
} from './CardSituationType';

/**
 * 
 * @export
 * @interface CardMessage
 */
export interface CardMessage {
    /**
     * ボイスコード
     * @type {string}
     * @memberof CardMessage
     */
    voiceCd: string;
    /**
     * ボイスキャラコード
     * @type {string}
     * @memberof CardMessage
     */
    charaCd: string;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof CardMessage
     */
    resourcePartition: number | null;
    /**
     * ボイステキスト
     * @type {string}
     * @memberof CardMessage
     */
    messageText: string;
    /**
     * テキスト量が多い場合に使用
     * @type {string}
     * @memberof CardMessage
     */
    messageTextOver: string;
    /**
     * 
     * @type {CardSituationType}
     * @memberof CardMessage
     */
    situationType: CardSituationType;
}

/**
 * Check if a given object implements the CardMessage interface.
 */
export function instanceOfCardMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "voiceCd" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "messageText" in value;
    isInstance = isInstance && "messageTextOver" in value;
    isInstance = isInstance && "situationType" in value;

    return isInstance;
}

export function CardMessageFromJSON(json: any): CardMessage {
    return CardMessageFromJSONTyped(json, false);
}

export function CardMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'voiceCd': json['voice_cd'],
        'charaCd': json['chara_cd'],
        'resourcePartition': json['resource_partition'],
        'messageText': json['message_text'],
        'messageTextOver': json['message_text_over'],
        'situationType': CardSituationTypeFromJSON(json['situation_type']),
    };
}

export function CardMessageToJSON(value?: CardMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voice_cd': value.voiceCd,
        'chara_cd': value.charaCd,
        'resource_partition': value.resourcePartition,
        'message_text': value.messageText,
        'message_text_over': value.messageTextOver,
        'situation_type': CardSituationTypeToJSON(value.situationType),
    };
}

