// @ts-ignore
import {defineNuxtPlugin} from "#app";

export default defineNuxtPlugin((nuxtApp) => {
  if (!('serviceWorker' in navigator)) {
    console.error('serviceWorker is not supported in current browser!');
    return;
  }
  // @ts-ignore
  window.$workbox = navigator.serviceWorker.register('/sw.js')
    .then((registration) => {
       console.debug('serviceWorker registered.'); 
      return registration;
    })
    .catch(error => { console.error('Error registering workbox:', error) });

  navigator.serviceWorker.getRegistration()
    .then(async (registration) => {
      if (registration && registration.update) {
        await registration.update();
         console.debug('serviceWorker update.'); 
      }
      return registration;
    })
    .catch(error => { console.error('Error update workbox:', error) });
});
