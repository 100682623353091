import { getOSVersion, isIOSDevice } from '~/libs/platform';

/**
 * 参考: https://github.com/playcanvas/engine/tree/main/src/platform/sound
 */
const enableLog = false;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const log = (...args: any[]) => {
  if (!enableLog) {
    return;
  }
  args.unshift('[sound]');
  // eslint-disable-next-line no-console
  console.debug.apply(null, args);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const logError = (...args: any[]) => {
  if (!enableLog) {
    return;
  }
  args.unshift('[sound]');
  // eslint-disable-next-line no-console
  console.error.apply(null, args);
};
class SoundError extends Error {
  protected error?: Error;
  constructor (message: string, error?: Error) {
    super(message);
    this.error = error;
  }
}
export interface SoundOption {
  volume: number,
  gainVolume: number,
  loop: boolean,
  group: string,
}
export interface SoundFadeParam {
  start: number,
  end: number,
  duration?: number,
}
export type SoundEventCallback = () => void;
interface SoundEventListener <T extends string> {
  event: T,
  callback: SoundEventCallback,
  onTime: boolean,
}
const defaultFadeOutDuration = 500;
type SoundCreateType = 'wave' | 'url' | 'object';
interface OscillatorOption {
  type: OscillatorType,
  frequency: number,
}

/**
 * イベントハンドラー
 */
export abstract class SoundEventHandler <T extends string> {
  /**
   * イベント
   * @protected
   */
  protected events: SoundEventListener<T>[];

  protected constructor () {
    this.events = [];
  }

  /**
   * イベントの取得
   */
  public getEvents () {
    return this.events;
  }

  /**
   * 全イベント削除
   */
  public removeAllEvents () {
    this.events = [];
  }

  /**
   * イベント追加
   * @param event
   * @param func
   * @param onTime
   * @protected
   */
  public on (event: T, func: SoundEventCallback, onTime = false) {
    this.events.push({
      event,
      callback: func,
      onTime,
    });
    log('SoundEventHandler.on', event, Object.assign({}, this.events), this.events.length, this);
  }

  /**
   * イベント追加(1度のみ)
   * @param event
   * @param func
   * @protected
   */
  public once (event: T, func: SoundEventCallback) {
    this.on(event, func, true);
  }

  /**
   * イベント削除
   * @param event
   * @param func
   * @protected
   */
  public off (event: T, func: SoundEventCallback) {
    this.events = this.events.filter(v => v.event !== event || v.callback !== func);
    log('SoundEventHandler.off', event, Object.assign({}, this.events), this.events.length, this);
  }

  /**
   * イベント実行
   * @param event
   * @protected
   */
  protected fireEvent (event: T) {
    const fireEvents = this.events.filter(v => v.event === event);
    log('SoundEventHandler.fireEvent', event, fireEvents.length, this, Object.assign({}, this.events), this.events.length);
    this.events = this.events.filter((v) => {
      return !(v.event === event && v.onTime);
    });
    fireEvents.forEach((v) => {
      v.callback();
    });
  }
}

type SoundEventType = 'load' | 'loadError' | 'fade' | 'play' | 'end' | 'stop' | 'pause';
/**
 * サウンドオブジェクト
 */
export class Sound extends SoundEventHandler<SoundEventType> {
  /**
   * ボリューム
   */
  public _volume: number;
  get volume () { return this._volume; }
  set volume (v: number) {
    this._volume = v;
    if (this.gainNode) {
      this.gainNode.gain.value = this.currentVolume;
    }
  }

  /**
   * GAIN全体のボリューム
   */
  public _gainVolume: number;
  get gainVolume () { return this._gainVolume; }
  set gainVolume (v: number) {
    this._gainVolume = v;
    if (this.gainNode) {
      this.gainNode.gain.value = this.currentVolume;
    }
  }

  /**
   * 最終的なボリューム
   * @protected
   */
  get currentVolume () {
    return this._volume * this._gainVolume;
  }

  /**
   * ループ再生
   */
  protected _loop: boolean;
  get loop () { return this._loop; }
  set loop (v: boolean) {
    this._loop = v;
    if (this.node instanceof AudioBufferSourceNode) {
      this.node.loop = this._loop;
    }
  }

  /**
   * グループ名
   */
  public group?: string;

  /**
   * 再生中かどうか
   */
  public playing: boolean;

  /**
   * ロード中かどうか
   */
  public loading: boolean;

  /**
   * AudioBuffer
   * @protected
   */
  protected audioBuffer?: AudioBuffer;

  /**
   * OscillatorOption
   * @protected
   */
  protected oscillatorOption?: OscillatorOption;
  /**
   * AudioScheduledSourceNode|AudioBufferSourceNode
   * @protected
   */
  protected node?: AudioScheduledSourceNode | AudioBufferSourceNode;

  /**
   * GainNode
   * @protected
   */
  protected gainNode?: GainNode;

  /**
   * 一時停止時間
   */
  protected pauseTime: number;

  /**
   * 再生時間
   */
  protected playStartTime: number;

  /**
   * 作成種別
   */
  protected type: SoundCreateType;

  /**
   * AudioContextがsuspended中にplay()できないブラウザの仕様があるため、resume後に再生させるフラグ
   */
  protected waitingContextSuspension: boolean;
  /**
   * suspendモードチェック
   */
  protected suspended: boolean;
  protected suspendCallback: SoundEventCallback;
  protected resumeCallback: SoundEventCallback;
  protected destroyCallback: SoundEventCallback;

  constructor (type: SoundCreateType, options?: Partial<SoundOption>) {
    super();
    this.type = type;
    this.group = options?.group;
    this._volume = options?.volume ?? 1;
    this._gainVolume = options?.gainVolume ?? 1;
    this._loop = options?.loop ?? false;
    this.playing = false;
    this.loading = false;
    this.pauseTime = 0;
    this.playStartTime = 0;
    this.waitingContextSuspension = false;
    this.suspended = false;
    this.suspendCallback = this.onManagerSuspend.bind(this);
    this.resumeCallback = this.onManagerResume.bind(this);
    this.destroyCallback = this.onManagerDestroy.bind(this);
  }

  /**
   * suspend移行時の処理
   * @protected
   */
  protected onManagerSuspend () {
    log('Sound.onManagerSuspend', this.suspended);
    if (!this.suspended) {
      this.suspended = true;
      this.suspend();
    }
  }

  /**
   * resume移行時の処理
   * @protected
   */
  protected onManagerResume () {
    log('Sound.onManagerResume', this.suspended);
    if (this.suspended) {
      this.suspended = false;
      this.resume();
    } else if (this.playing) {
      SoundManager.once('resume', this.resumeCallback);
    }
  }

  /**
   * destroy移行時の処理
   * @protected
   */
  protected onManagerDestroy () {
    log('Sound.onManagerDestroy', this.playing, this.suspended);
    if (this.node && this.playing && !this.suspended) {
      this.suspended = true;
      this.suspend();
    } else {
      this.releaseNode();
    }
  }

  /**
   * 音声停止
   * @protected
   */
  protected suspend () {
    log('Sound.suspend', this.playing);
    if (this.playing) {
      this.pauseTime = SoundManager.getContext()?.currentTime - this.playStartTime;
      this.releaseNode();
    }
  }

  /**
   * 音声再開
   * @protected
   */
  protected resume () {
    log('Sound.resume', this.playing);
    if (this.playing) {
      this.playAudioImmediate();
    }
  }

  /**
   * @protected
   */
  protected generateNode () {
    if (this.type === 'wave') {
      const node = SoundManager.getContext()?.createOscillator();
      node.type = this.oscillatorOption?.type ?? 'sine';
      node.frequency.value = this.oscillatorOption?.frequency ?? 440;
      return node;
    } else {
      const node = SoundManager.getContext().createBufferSource();
      node.loop = this.loop;
      node.buffer = this.audioBuffer ?? null;
      return node;
    }
  }

  /**
   * @protected
   */
  protected getNode () {
    if (!this.node) {
      const node = this.generateNode();
      node.onended = () => {
        if (!this.suspended) {
          log('node ended');
          this.stop();
          this.fireEvent('end');
        }
      };
      node.connect(this.getGainNode());
      this.node = node;
    }
    return this.node;
  }

  /**
   * Nodeの解放
   * @protected
   */
  protected releaseNode () {
    const node = this.node;
    if (node) {
      node.onended = null;
      node.stop();
      node?.disconnect();
      if ('buffer' in node) {
        node.buffer = null;
      }
      this.node = undefined;
    }
    const gainNode = this.gainNode;
    if (gainNode) {
      gainNode.gain.value = 0;
      gainNode?.disconnect();
      this.gainNode = undefined;
    }
  }

  /**
   * GainNodeの作成
   * @protected
   */
  protected getGainNode () {
    if (!this.gainNode) {
      this.gainNode = SoundManager.getContext().createGain();
      if (this.gainNode) {
        this.gainNode.gain.value = this.currentVolume;
        if (this.group) {
          const node = SoundManager.getGroupGainNode(this.group).node;
          if (node) {
            this.gainNode.connect(node);
          } else {
            logError('create error group gain node');
          }
        } else {
          this.gainNode.connect(SoundManager.getGainNode());
        }
      }
    }
    return this.gainNode;
  }

  protected resumeFnc?: SoundEventCallback = undefined;
  /**
   * オーディオの再生
   */
  public play () {
    log(
      'play()',
      this.playing,
      this.waitingContextSuspension,
      SoundManager.suspended,
      SoundManager.getContext().state,
      this
    );
    if (this.playing) {
      return true;
    }
    if (this.waitingContextSuspension) {
      return false;
    }
    if (SoundManager.suspended) {
      if (!this.resumeFnc) {
        this.resumeFnc = () => {
          if (this.waitingContextSuspension) {
            this.playAudioImmediate();
          }
        };
        SoundManager.once('resume', this.resumeFnc);
      }
      this.waitingContextSuspension = true;
      return false;
    }
    return this.playAudioImmediate();
  }

  protected registerManagerEvent (isRegister: boolean) {
    log('registerManagerEvent', isRegister);
    SoundManager.off('suspend', this.suspendCallback);
    SoundManager.off('resume', this.resumeCallback);
    SoundManager.off('destroy', this.destroyCallback);
    if (isRegister) {
      SoundManager.once('suspend', this.suspendCallback);
      SoundManager.once('resume', this.resumeCallback);
      SoundManager.once('destroy', this.destroyCallback);
    }
  }

  /**
   * 音声の再生
   * @protected
   */
  protected playAudioImmediate () {
    log('playAudioImmediate', this);
    this.waitingContextSuspension = false;
    // 再生はAudioBufferSourceNodeが生きている間の一度のみ
    const node = this.getNode();
    if (node instanceof AudioBufferSourceNode) {
      node.start(0, this.pauseTime);
    } else {
      node.start(0);
    }
    this.playing = true;
    this.playStartTime = SoundManager.getContext()?.currentTime - this.pauseTime;
    this.pauseTime = 0;
    this.fireEvent('play');
    this.registerManagerEvent(true);
    return true;
  }

  /**
   * fade用タイマー
   * @protected
   */
  protected fadeInterval: number | NodeJS.Timeout = 0;

  /**
   * fade再生
   * @param params
   */
  public fade (params: SoundFadeParam) {
    if (this.fadeInterval) {
      this.fadeStop();
    }
    // interval(ms)
    const interval = 10;
    const exp = 10000;
    const count = Math.ceil((params?.duration ?? defaultFadeOutDuration) / interval);
    this.volume = params.start;
    // 桁溢れ対策
    const diff = Math.floor((params.start - params.end) / count * exp) / exp;
    this.fadeInterval = setInterval(() => {
      this.fadeExecute(diff, params);
    }, interval);
    return this.play();
  }

  /**
   * fade再生
   * @param diff
   * @param params
   */
  protected fadeExecute (diff: number, params: SoundFadeParam) {
    if (!diff || (diff > 0 && this.volume <= params.end) || (diff < 0 && this.volume >= params.end)) {
      this.volume = params.end;
      this.fadeStop();
      if (params.end <= 0) {
        this.stop();
      }
      return;
    }
    this.volume = Math.max(Math.min(this.volume - diff, 1), 0);
  }

  /**
   * fade処理の停止
   */
  protected fadeStop () {
    if (this.fadeInterval) {
      clearInterval(this.fadeInterval);
      this.fadeInterval = 0;
      this.fireEvent('fade');
    }
  }

  /**
   * オーディオの停止
   */
  public stop () {
    log('stop', this);
    if (!this.playing) {
      return;
    }
    if (this.resumeFnc) {
      SoundManager.off('resume', this.resumeFnc);
      this.resumeFnc = undefined;
    }
    this.playStartTime = 0;
    this.pauseTime = 0;
    this.releaseNode();
    this.playing = false;
    this.fireEvent('stop');
    this.waitingContextSuspension = false;
    this.fadeStop();
    this.registerManagerEvent(false);
  }

  /**
   * オーディオの一時再生
   */
  public pause () {
    log('pause', this);
    if (this.playing) {
      this.pauseTime = SoundManager.getContext()?.currentTime - this.playStartTime;
      this.releaseNode();
      this.playing = false;
      this.fireEvent('pause');
      this.registerManagerEvent(false);
    }
    this.fadeStop();
  }

  /**
   * オーディオの破棄
   */
  public disconnect () {
    this.releaseNode();
    this.playing = false;
    this.fadeStop();
  }

  /**
   * サウンドオブジェクトから生成
   * @param type
   * @param frequency
   * @param options
   */
  public static createFromWave (type: OscillatorType, frequency: number, options?: Partial<SoundOption>) {
    const o = new Sound('wave', options);
    o.oscillatorOption = {
      type,
      frequency,
    };
    o.fireEvent('load');
    return o;
  }

  /**
   * サウンドオブジェクトから生成
   * @param sound
   * @param options
   */
  public static createFromSound (sound: Sound, options?: Partial<SoundOption>) {
    let base: Sound | undefined = sound;
    let o: Sound | undefined = new Sound('object', options);
    if (base.audioBuffer) {
      o.audioBuffer = base.audioBuffer;
      o.fireEvent('load');
    } else if (base.loading) {
      o.loading = true;
      const onLoad = () => {
        if (o) {
          o.audioBuffer = base?.audioBuffer;
          o.loading = false;
          o.fireEvent(base?.audioBuffer ? 'load' : 'loadError');
          o = undefined;
        }
        base?.off('load', onLoad);
        base?.off('loadError', onLoadError);
        base = undefined;
      };
      const onLoadError = () => {
        if (o) {
          o = undefined;
        }
        base?.off('load', onLoad);
        base?.off('loadError', onLoadError);
        base = undefined;
      };
      base.once('load', onLoad);
      base.once('loadError', onLoadError);
    }
    return o;
  }

  /**
   * URLから生成
   * @param url
   * @param options
   */
  public static createFromUrl (url: string, options?: Partial<SoundOption>) {
    let o: Sound | undefined = new Sound('url', options);
    o.loading = true;
    SoundManager.getAudioBufferFromUrl(url).then((buffer) => {
      if (o) {
        o.audioBuffer = buffer;
        o.loading = false;
        o.fireEvent('load');
        o = undefined;
      }
      return buffer;
    }).catch((e) => {
      logError(e);
      if (o) {
        o.loading = false;
        o.fireEvent('loadError');
        o = undefined;
      }
    });
    return o;
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;

interface Pool {
  url: string,
  request: Promise<AudioBuffer>,
}

type SoundManagerEventType = 'resume' | 'suspend' | 'unlock' | 'destroy';
const CONTEXT_STATE_RUNNING = 'running';
const CONTEXT_STATE_SUSPENDED = 'suspended';
const CONTEXT_STATE_INTERRUPTED = 'interrupted';
interface GroupNodeData {
  node?: GainNode,
  volume: number,
}

/**
 * サウンドマネージャーオブジェクト
 */
export class SoundManagerObject extends SoundEventHandler<SoundManagerEventType> {
  protected _context?: AudioContext;
  protected _gainNode?: GainNode;
  protected groupGainNode: {[key: string]: GroupNodeData};
  protected audioPool: Pool[];
  // pool最大数
  protected audioPoolSize = 10;
  protected userSuspended: boolean;
  protected unlockHandler: () => void;

  /**
   * デフォルトのリクエストタイムアウト(ms)
   */
  public static defaultRequestTimeout = 10000;

  constructor () {
    super();
    this.groupGainNode = {};
    this.audioPool = [];
    this.userSuspended = false;
    this.unlockHandler = this.unlock.bind(this);
  }

  get suspended () {
    return this.userSuspended;
  }

  /**
   * URLからAudio読み込み
   * @param url
   * @protected
   */
  public getAudioBufferFromUrl (url: string): Promise<AudioBuffer> {
    // poolから取得
    const p = this.audioPool.find(v => v.url === url);
    if (p) {
      return p.request.then((v) => {
        return v;
      });
    }
    const request = this.requestAudioBufferFromUrl(url);
    request.catch(() => this.removeAudioPool(url, request));
    this.addAudioPool(url, request);
    return request;
  }

  /**
   * URLからAudio読み込み
   * @param url
   * @param options
   * @protected
   */
  protected requestAudioBufferFromUrl (url: string, options?: { timeout?: number }): Promise<AudioBuffer> {
    const timeout = options?.timeout !== undefined ? options?.timeout : SoundManagerObject.defaultRequestTimeout;
    return new Promise((resolve, reject) => {
      let request: XMLHttpRequest | null = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'arraybuffer';
      request.timeout = timeout;
      request.onload = () => {
        if (!request || !(request.status >= 200 && request.status < 400)) {
          reject(new SoundError('ajax request error.'));
          request = null;
          return;
        }
        if (!(request.response instanceof ArrayBuffer)) {
          reject(new SoundError('ajax response error.'));
          request = null;
          return;
        }
        const decode = SoundManager.getContext().decodeAudioData(request.response);
        request = null;
        decode.then((data: AudioBuffer) => {
          resolve(data);
        }).catch((error) => {
          reject(new SoundError('decodeAudioData error.', error));
        });
      };
      request.onerror = () => {
        reject(new SoundError('ajax request error.'));
        request = null;
      };
      request.send();
    });
  }

  /**
   * 音声の追加
   * @param {string} url
   * @param {Promise<AudioBuffer>} request
   */
  public addAudioPool (url: string, request: Promise<AudioBuffer>) {
    // poolの解放
    let size = 0;
    let add = true;
    for (let i = 0; i < this.audioPool.length; i++) {
      const v = this.audioPool[i];
      // 既に追加済み
      if (url === v.url) {
        add = false;
        break;
      }
      if (((size + 1) >= this.audioPoolSize)) {
        this.audioPool.splice(i, 1);
        i -= 1;
        continue;
      }
      size++;
    }
    if (add) {
      this.audioPool.unshift({
        url,
        request,
      });
    }
  }

  /**
   * poolの削除
   * @param url
   * @param request
   */
  public removeAudioPool (url: string, request: Promise<AudioBuffer>) {
    this.audioPool = this.audioPool.filter(v => v.url !== url || v.request !== request);
  }

  /**
   * poolの全削除
   */
  public removeAllAudioPool () {
    this.audioPool = [];
  }

  get volume () {
    return this._gainNode ? this._gainNode.gain.value : 1;
  }

  set volume (v: number) {
    if (this._gainNode) {
      this._gainNode.gain.value = v;
    }
  }

  /**
   * 破棄
   */
  public destroy () {
    this.fireEvent('destroy');
    if (this._gainNode) {
      this._gainNode?.disconnect();
      this._gainNode = undefined;
    }
    for (const key in this.groupGainNode) {
      const data = this.groupGainNode[key];
      if (data && data.node) {
        data.node.disconnect();
        this.groupGainNode[key].node = undefined;
      }
    }
    if (this._context) {
      this.removeUnlockListeners();
      this._context?.close().then(() => {
        // no-op
      });
      this._context = undefined;
    }
  }

  /**
   * リセット
   */
  public reset () {
    this.destroy();
    this.groupGainNode = {};
  }

  /**
   * AudioContextの作成
   */
  public getContext () {
    if (!this._context) {
      this._context = new AudioContext();
      if (!this._context || this._context?.state !== CONTEXT_STATE_RUNNING) {
        this.registerUnlockListeners();
      }
    }
    return this._context;
  }

  /**
   * AudioContextが存在するか
   */
  public hasContext () {
    return !!this._context;
  }

  /**
   * GainNodeの作成
   * @protected
   */
  public getGainNode () {
    if (!this._gainNode) {
      this._gainNode = this.getContext().createGain();
      if (this._gainNode) {
        this._gainNode.gain.value = 1;
        this._gainNode.connect(this.getContext()?.destination);
      }
    }
    return this._gainNode;
  }

  /**
   * グループ用のgainNodeを再生
   * @protected
   */
  protected createGroupGain (volume: number) {
    const node = this.getContext().createGain();
    if (node) {
      node.gain.value = volume;
      node.connect(this.getGainNode());
    }
    return node;
  }

  /**
   * GainNodeの作成
   */
  public getGroupGainNode (group: string) {
    if (!(group in this.groupGainNode)) {
      this.groupGainNode[group] = {
        node: this.createGroupGain(1),
        volume: 1,
      };
    } else if (!this.groupGainNode[group].node) {
      this.groupGainNode[group].node = this.createGroupGain(this.groupGainNode[group].volume);
    }
    return this.groupGainNode[group];
  }

  /**
   * GainNodeの音量設定
   */
  public setGroupGainNodeVolume (group: string, volume: number) {
    if (!(group in this.groupGainNode)) {
      this.groupGainNode[group] = {
        volume,
      };
    } else {
      const data = this.groupGainNode[group];
      data.volume = volume;
      if (data.node) {
        data.node.gain.value = volume;
      }
    }
  }

  protected hidden = 'hidden';
  /**
   * 音声ロック解除イベントの登録
   * @protected
   */
  public registerEvents () {
    // Standards
    if (this.hidden in document) {
      document.addEventListener('visibilitychange', this.onVisibleChange.bind(this));
    } else if ((this.hidden = 'mozHidden') in document) {
      document.addEventListener('mozvisibilitychange', this.onVisibleChange.bind(this));
    } else if ((this.hidden = 'webkitHidden') in document) {
      document.addEventListener('webkitvisibilitychange', this.onVisibleChange.bind(this));
    } else if ((this.hidden = 'msHidden') in document) {
      document.addEventListener('msvisibilitychange', this.onVisibleChange.bind(this));
    } else {
      window.addEventListener('onpageshow', this.onVisibleChange.bind(this));
      window.addEventListener('onpagehide', this.onVisibleChange.bind(this));
      window.addEventListener('onfocus', this.onVisibleChange.bind(this));
      window.addEventListener('onblur', this.onVisibleChange.bind(this));
    }
    if (document.hidden !== undefined) {
      this.onVisibleChange({
        type: document.hidden ? 'blur' : 'focus',
      });
    }
  }

  static readonly clickEvents = [
    'touchstart',
    'touchend',
    'click',
    'mousedown',
  ];

  /**
   * clickイベントの登録
   * @protected
   */
  public registerUnlockListeners () {
    this.isUnlock = false;
    this.removeUnlockListeners();
    log('registerUnlockListeners');
    for (const event of SoundManagerObject.clickEvents) {
      document.addEventListener(event, this.unlockHandler, true);
    }
  }

  /**
   * clickイベントの登録
   * @protected
   */
  public removeUnlockListeners () {
    log('removeUnlockListeners');
    for (const event of SoundManagerObject.clickEvents) {
      document.removeEventListener(event, this.unlockHandler, true);
    }
  }

  protected isUnlock = false;
  /**
   * アンロック処理(クリックイベントにて実行する必要がある)
   * @protected
   */
  protected unlock () {
    this.isUnlock = true;
    log('unlock', this.userSuspended, this._context?.state);
    this.removeUnlockListeners();
    if (!this.userSuspended) {
      this._resume();
    }
  }

  /**
   * ダミー音声の再生
   * @protected
   */
  protected playDummySound () {
    return new Promise((resolve) => {
      // ダミー音声を再生
      const context = SoundManager.getContext();
      const source = context.createBufferSource();
      source.buffer = context.createBuffer(1, 1, context.sampleRate);
      source.connect(context.destination);
      source.start(0);
      source.onended = () => {
        source.disconnect();
        source.buffer = null;
        resolve(undefined);
      };
    });
  }

  /**
   * イベントの登録
   * @protected
   */
  protected onVisibleChange (evt: Event | {type: string}) {
    const evtMap = {
      focus: false,
      focusin: false,
      pageshow: false,
      blur: true,
      focusout: true,
      pagehide: true,
    };
    const e = evt || window.event;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const windowHidden = (e.type in evtMap) ? evtMap[e.type] : document[this.hidden];
    if (windowHidden !== undefined) {
      if (windowHidden) {
        this.suspend();
      } else {
        this.resume();
      }
    } else {
      log('window hidden disable', windowHidden);
    }
  }

  /**
   * 音声一時停止
   */
  public suspend () {
    log('SoundManager.suspend()', this._context?.state);
    if (!this.userSuspended) {
      this.userSuspended = true;
      if (this._context && this._context.state === CONTEXT_STATE_RUNNING) {
        this._suspend();
      }
    }
  }

  /**
   * 音声一時停止
   */
  protected _suspend () {
    if (!this._context) {
      return;
    }
    this._context?.suspend().then(() => {
      log('AudioContext.suspend() executed');
      this.fireEvent('suspend');
    }, (e) => {
      logError('AudioContext.suspend() throw rejected', e);
    }).catch((e) => {
      logError('AudioContext.suspend() throw exception', e);
    });
  }

  /**
   * backgroundから復帰した際にAudioContextを再構築させるか
   * @protected
   */
  protected isBackgroundDestroy () {
    // return isIOSDevice();
    // iOS15にてbackgroundにしばらく滞在するとAudioContextが壊れるため一度解放させる
    const version = getOSVersion();
    if (!isIOSDevice() || !version) {
      return false;
    }
    const majorVersion = Number(version.split('.')[0]);
    return majorVersion === 15;
  }

  /**
   * 音声再開
   */
  public resume () {
    log('SoundManager.resume()', this.userSuspended, this._context?.state);
    if (this.userSuspended) {
      this.userSuspended = false;
      if (this.isBackgroundDestroy()) {
        this.destroy();
        this.getContext();
        // 破棄させるだけ、次回のアンロックで再開させる
        this.registerUnlockListeners();
        return;
      }
      if (this._context && this._context.state !== CONTEXT_STATE_RUNNING) {
        this._resume();
      }
    }
  }

  protected _resume () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (this._context?.state !== CONTEXT_STATE_SUSPENDED && this._context?.state !== CONTEXT_STATE_INTERRUPTED) {
      this.playDummySound().then(() => {
        this.fireEvent('resume');
      });
      return;
    }
    this.getContext().resume().then(() => {
      log('AudioContext.resume() executed');
      this.playDummySound().then(() => {
        this.fireEvent('resume');
      });
    }, (e) => {
      logError('AudioContext.resume() throw rejected', e);
    }).catch((e) => {
      logError('AudioContext.resume() throw exception', e);
    });
  }
}
export const SoundManager = new SoundManagerObject();
