/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PanelmissionEventPanel } from './PanelmissionEventPanel';
import {
    PanelmissionEventPanelFromJSON,
    PanelmissionEventPanelFromJSONTyped,
    PanelmissionEventPanelToJSON,
} from './PanelmissionEventPanel';

/**
 * 
 * @export
 * @interface PanelmissionEventPanelResponse
 */
export interface PanelmissionEventPanelResponse {
    /**
     * 
     * @type {PanelmissionEventPanel}
     * @memberof PanelmissionEventPanelResponse
     */
    data: PanelmissionEventPanel;
}

/**
 * Check if a given object implements the PanelmissionEventPanelResponse interface.
 */
export function instanceOfPanelmissionEventPanelResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function PanelmissionEventPanelResponseFromJSON(json: any): PanelmissionEventPanelResponse {
    return PanelmissionEventPanelResponseFromJSONTyped(json, false);
}

export function PanelmissionEventPanelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionEventPanelResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': PanelmissionEventPanelFromJSON(json['data']),
    };
}

export function PanelmissionEventPanelResponseToJSON(value?: PanelmissionEventPanelResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PanelmissionEventPanelToJSON(value.data),
    };
}

