/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuChara } from './CommuChara';
import {
    CommuCharaFromJSON,
    CommuCharaFromJSONTyped,
    CommuCharaToJSON,
} from './CommuChara';
import type { CommuCharaMental } from './CommuCharaMental';
import {
    CommuCharaMentalFromJSON,
    CommuCharaMentalFromJSONTyped,
    CommuCharaMentalToJSON,
} from './CommuCharaMental';
import type { CommuLevelPattern } from './CommuLevelPattern';
import {
    CommuLevelPatternFromJSON,
    CommuLevelPatternFromJSONTyped,
    CommuLevelPatternToJSON,
} from './CommuLevelPattern';

/**
 * 
 * @export
 * @interface Commu
 */
export interface Commu {
    /**
     * レベルパターンリスト
     * @type {Array<CommuLevelPattern>}
     * @memberof Commu
     */
    levelPatternList: Array<CommuLevelPattern>;
    /**
     * キャラとの心の距離リスト
     * @type {Array<CommuCharaMental>}
     * @memberof Commu
     */
    charaMentalList: Array<CommuCharaMental>;
    /**
     * キャラリスト
     * @type {Array<CommuChara>}
     * @memberof Commu
     */
    charaList: Array<CommuChara>;
}

/**
 * Check if a given object implements the Commu interface.
 */
export function instanceOfCommu(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "levelPatternList" in value;
    isInstance = isInstance && "charaMentalList" in value;
    isInstance = isInstance && "charaList" in value;

    return isInstance;
}

export function CommuFromJSON(json: any): Commu {
    return CommuFromJSONTyped(json, false);
}

export function CommuFromJSONTyped(json: any, ignoreDiscriminator: boolean): Commu {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'levelPatternList': ((json['level_pattern_list'] as Array<any>).map(CommuLevelPatternFromJSON)),
        'charaMentalList': ((json['chara_mental_list'] as Array<any>).map(CommuCharaMentalFromJSON)),
        'charaList': ((json['chara_list'] as Array<any>).map(CommuCharaFromJSON)),
    };
}

export function CommuToJSON(value?: Commu | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level_pattern_list': ((value.levelPatternList as Array<any>).map(CommuLevelPatternToJSON)),
        'chara_mental_list': ((value.charaMentalList as Array<any>).map(CommuCharaMentalToJSON)),
        'chara_list': ((value.charaList as Array<any>).map(CommuCharaToJSON)),
    };
}

