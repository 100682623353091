/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventRankingLeaderCardInfo } from './UserEventRankingLeaderCardInfo';
import {
    UserEventRankingLeaderCardInfoFromJSON,
    UserEventRankingLeaderCardInfoFromJSONTyped,
    UserEventRankingLeaderCardInfoToJSON,
} from './UserEventRankingLeaderCardInfo';

/**
 * 
 * @export
 * @interface UserEventRanking
 */
export interface UserEventRanking {
    /**
     * ランク
     * @type {number}
     * @memberof UserEventRanking
     */
    rank: number;
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof UserEventRanking
     */
    nickname: string;
    /**
     * 討伐度
     * @type {number}
     * @memberof UserEventRanking
     */
    eventP: number;
    /**
     * アバターサムネイルのURL
     * @type {string}
     * @memberof UserEventRanking
     */
    avatarThumbnailUrl: string;
    /**
     * 
     * @type {UserEventRankingLeaderCardInfo}
     * @memberof UserEventRanking
     */
    leaderCardInfo: UserEventRankingLeaderCardInfo;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserEventRanking
     */
    playerCode: string;
}

/**
 * Check if a given object implements the UserEventRanking interface.
 */
export function instanceOfUserEventRanking(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rank" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "eventP" in value;
    isInstance = isInstance && "avatarThumbnailUrl" in value;
    isInstance = isInstance && "leaderCardInfo" in value;
    isInstance = isInstance && "playerCode" in value;

    return isInstance;
}

export function UserEventRankingFromJSON(json: any): UserEventRanking {
    return UserEventRankingFromJSONTyped(json, false);
}

export function UserEventRankingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventRanking {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rank': json['rank'],
        'nickname': json['nickname'],
        'eventP': json['event_p'],
        'avatarThumbnailUrl': json['avatar_thumbnail_url'],
        'leaderCardInfo': UserEventRankingLeaderCardInfoFromJSON(json['leader_card_info']),
        'playerCode': json['player_code'],
    };
}

export function UserEventRankingToJSON(value?: UserEventRanking | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rank': value.rank,
        'nickname': value.nickname,
        'event_p': value.eventP,
        'avatar_thumbnail_url': value.avatarThumbnailUrl,
        'leader_card_info': UserEventRankingLeaderCardInfoToJSON(value.leaderCardInfo),
        'player_code': value.playerCode,
    };
}

