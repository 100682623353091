/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGachaLineupRarityProbability } from './UserGachaLineupRarityProbability';
import {
    UserGachaLineupRarityProbabilityFromJSON,
    UserGachaLineupRarityProbabilityFromJSONTyped,
    UserGachaLineupRarityProbabilityToJSON,
} from './UserGachaLineupRarityProbability';

/**
 * 
 * @export
 * @interface UserGachaLineupListResponseData
 */
export interface UserGachaLineupListResponseData {
    /**
     * ガチャコード
     * @type {string}
     * @memberof UserGachaLineupListResponseData
     */
    gachaCd: string;
    /**
     * ガチャタイトル
     * @type {string}
     * @memberof UserGachaLineupListResponseData
     */
    gachaTitle: string;
    /**
     * 引き直しフラグ
     * @type {number}
     * @memberof UserGachaLineupListResponseData
     */
    drawAgainFlg: number;
    /**
     * 
     * @type {UserGachaLineupRarityProbability}
     * @memberof UserGachaLineupListResponseData
     */
    rarityProbability: UserGachaLineupRarityProbability;
}

/**
 * Check if a given object implements the UserGachaLineupListResponseData interface.
 */
export function instanceOfUserGachaLineupListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gachaCd" in value;
    isInstance = isInstance && "gachaTitle" in value;
    isInstance = isInstance && "drawAgainFlg" in value;
    isInstance = isInstance && "rarityProbability" in value;

    return isInstance;
}

export function UserGachaLineupListResponseDataFromJSON(json: any): UserGachaLineupListResponseData {
    return UserGachaLineupListResponseDataFromJSONTyped(json, false);
}

export function UserGachaLineupListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaLineupListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gachaCd': json['gacha_cd'],
        'gachaTitle': json['gacha_title'],
        'drawAgainFlg': json['draw_again_flg'],
        'rarityProbability': UserGachaLineupRarityProbabilityFromJSON(json['rarity_probability']),
    };
}

export function UserGachaLineupListResponseDataToJSON(value?: UserGachaLineupListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gacha_cd': value.gachaCd,
        'gacha_title': value.gachaTitle,
        'draw_again_flg': value.drawAgainFlg,
        'rarity_probability': UserGachaLineupRarityProbabilityToJSON(value.rarityProbability),
    };
}

