/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuLevel
 */
export interface CommuLevel {
    /**
     * コミュレベル
     * @type {number}
     * @memberof CommuLevel
     */
    level: number;
    /**
     * 必要なコミュポイント(累積)
     * @type {number}
     * @memberof CommuLevel
     */
    requiredCommuPoint: number;
}

/**
 * Check if a given object implements the CommuLevel interface.
 */
export function instanceOfCommuLevel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "requiredCommuPoint" in value;

    return isInstance;
}

export function CommuLevelFromJSON(json: any): CommuLevel {
    return CommuLevelFromJSONTyped(json, false);
}

export function CommuLevelFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuLevel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'level': json['level'],
        'requiredCommuPoint': json['required_commu_point'],
    };
}

export function CommuLevelToJSON(value?: CommuLevel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level': value.level,
        'required_commu_point': value.requiredCommuPoint,
    };
}

