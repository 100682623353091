/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * コミュ特典タイプ
 * @export
 */
export const CommuBonusType = {
    Card: 'card',
    RoomAvatar: 'room_avatar',
    Item: 'item',
    CommuStory: 'commu_story',
    HisStory: 'his_story',
    Still: 'still'
} as const;
export type CommuBonusType = typeof CommuBonusType[keyof typeof CommuBonusType];


export function CommuBonusTypeFromJSON(json: any): CommuBonusType {
    return CommuBonusTypeFromJSONTyped(json, false);
}

export function CommuBonusTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuBonusType {
    return json as CommuBonusType;
}

export function CommuBonusTypeToJSON(value?: CommuBonusType | null): any {
    return value as any;
}

