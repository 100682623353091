<template>
  <ModalAlert
    :show="props.show"
    :title="errorName()"
    ok-text="タイトル画面へ"
    :z-index="props.zIndex"
    @click-ok="onClick"
  >
    <div v-if="error" class="text-center">
      <div v-if="isNetworkError()">
        通信エラー<span v-if="errorCode">({{ errorCode }})</span>が発生しました<br>
        タイトル画面に戻ります
      </div>
      <div v-else>
        エラーが発生しました<br>
        タイトル画面に戻ります
      </div>
      <div class="mt-1">
        ※うまく動作しない場合には<br>
        アプリの再起動をお試しください
      </div>
      <ModalErrorDebugErrorText :error="props.error" />
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import { getDisplayErrorCode, NetworkError } from '~/libs/errors';
import { watch } from '#imports';

const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});

const errorCode = ref<number>(getDisplayErrorCode(props.error));
watch(() => props.error, (current) => {
  errorCode.value = getDisplayErrorCode(current);
});

interface Emits {
  (e: 'click'): void;
}
const emit = defineEmits<Emits>();
const onClick = () => {
  emit('click');
};
const isNetworkError = () => {
  return props.error instanceof NetworkError;
};
const errorName = () => {
  return isNetworkError() ? '通信エラー' : 'エラー';
};
</script>
