/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGacha } from './UserGacha';
import {
    UserGachaFromJSON,
    UserGachaFromJSONTyped,
    UserGachaToJSON,
} from './UserGacha';
import type { UserGachaListResponseDataHoldGachaResult } from './UserGachaListResponseDataHoldGachaResult';
import {
    UserGachaListResponseDataHoldGachaResultFromJSON,
    UserGachaListResponseDataHoldGachaResultFromJSONTyped,
    UserGachaListResponseDataHoldGachaResultToJSON,
} from './UserGachaListResponseDataHoldGachaResult';

/**
 * 
 * @export
 * @interface UserGachaListResponseData
 */
export interface UserGachaListResponseData {
    /**
     * 
     * @type {Array<UserGacha>}
     * @memberof UserGachaListResponseData
     */
    card: Array<UserGacha>;
    /**
     * 
     * @type {Array<UserGacha>}
     * @memberof UserGachaListResponseData
     */
    avatar: Array<UserGacha>;
    /**
     * 
     * @type {Array<UserGacha>}
     * @memberof UserGachaListResponseData
     */
    gold: Array<UserGacha>;
    /**
     * 
     * @type {UserGachaListResponseDataHoldGachaResult}
     * @memberof UserGachaListResponseData
     */
    holdGachaResult: UserGachaListResponseDataHoldGachaResult;
}

/**
 * Check if a given object implements the UserGachaListResponseData interface.
 */
export function instanceOfUserGachaListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "card" in value;
    isInstance = isInstance && "avatar" in value;
    isInstance = isInstance && "gold" in value;
    isInstance = isInstance && "holdGachaResult" in value;

    return isInstance;
}

export function UserGachaListResponseDataFromJSON(json: any): UserGachaListResponseData {
    return UserGachaListResponseDataFromJSONTyped(json, false);
}

export function UserGachaListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'card': ((json['card'] as Array<any>).map(UserGachaFromJSON)),
        'avatar': ((json['avatar'] as Array<any>).map(UserGachaFromJSON)),
        'gold': ((json['gold'] as Array<any>).map(UserGachaFromJSON)),
        'holdGachaResult': UserGachaListResponseDataHoldGachaResultFromJSON(json['hold_gacha_result']),
    };
}

export function UserGachaListResponseDataToJSON(value?: UserGachaListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card': ((value.card as Array<any>).map(UserGachaToJSON)),
        'avatar': ((value.avatar as Array<any>).map(UserGachaToJSON)),
        'gold': ((value.gold as Array<any>).map(UserGachaToJSON)),
        'hold_gacha_result': UserGachaListResponseDataHoldGachaResultToJSON(value.holdGachaResult),
    };
}

