/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserNewsListResponseData } from './UserNewsListResponseData';
import {
    UserNewsListResponseDataFromJSON,
    UserNewsListResponseDataFromJSONTyped,
    UserNewsListResponseDataToJSON,
} from './UserNewsListResponseData';

/**
 * 
 * @export
 * @interface UserNewsListResponse
 */
export interface UserNewsListResponse {
    /**
     * 
     * @type {UserNewsListResponseData}
     * @memberof UserNewsListResponse
     */
    data: UserNewsListResponseData;
}

/**
 * Check if a given object implements the UserNewsListResponse interface.
 */
export function instanceOfUserNewsListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserNewsListResponseFromJSON(json: any): UserNewsListResponse {
    return UserNewsListResponseFromJSONTyped(json, false);
}

export function UserNewsListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNewsListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserNewsListResponseDataFromJSON(json['data']),
    };
}

export function UserNewsListResponseToJSON(value?: UserNewsListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserNewsListResponseDataToJSON(value.data),
    };
}

