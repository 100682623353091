/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDeck } from './UserDeck';
import {
    UserDeckFromJSON,
    UserDeckFromJSONTyped,
    UserDeckToJSON,
} from './UserDeck';

/**
 * 
 * @export
 * @interface DeckListResponse
 */
export interface DeckListResponse {
    /**
     * 
     * @type {Array<UserDeck>}
     * @memberof DeckListResponse
     */
    data: Array<UserDeck>;
}

/**
 * Check if a given object implements the DeckListResponse interface.
 */
export function instanceOfDeckListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DeckListResponseFromJSON(json: any): DeckListResponse {
    return DeckListResponseFromJSONTyped(json, false);
}

export function DeckListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeckListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserDeckFromJSON)),
    };
}

export function DeckListResponseToJSON(value?: DeckListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserDeckToJSON)),
    };
}

