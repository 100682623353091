/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 彼目線シナリオ情報
 * @export
 * @interface StoryPov
 */
export interface StoryPov {
    /**
     * １:彼目線シナリオ有り 0：無し
     * @type {boolean}
     * @memberof StoryPov
     */
    visible: boolean;
    /**
     * 何周目に彼目線シナリオを解放することが出来るか、ない場合は0
     * @type {number}
     * @memberof StoryPov
     */
    needCount: number;
    /**
     * 何チケット目に彼目線シナリオを解放することが出来るか、ない場合は0
     * @type {number}
     * @memberof StoryPov
     */
    openTicketNum: number;
}

/**
 * Check if a given object implements the StoryPov interface.
 */
export function instanceOfStoryPov(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "visible" in value;
    isInstance = isInstance && "needCount" in value;
    isInstance = isInstance && "openTicketNum" in value;

    return isInstance;
}

export function StoryPovFromJSON(json: any): StoryPov {
    return StoryPovFromJSONTyped(json, false);
}

export function StoryPovFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryPov {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visible': json['visible'],
        'needCount': json['need_count'],
        'openTicketNum': json['open_ticket_num'],
    };
}

export function StoryPovToJSON(value?: StoryPov | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visible': value.visible,
        'need_count': value.needCount,
        'open_ticket_num': value.openTicketNum,
    };
}

