/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CardSaveRequest
 */
export interface CardSaveRequest {
    /**
     * カードコードの配列
     * @type {Array<string>}
     * @memberof CardSaveRequest
     */
    cardList?: Array<string>;
}

/**
 * Check if a given object implements the CardSaveRequest interface.
 */
export function instanceOfCardSaveRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CardSaveRequestFromJSON(json: any): CardSaveRequest {
    return CardSaveRequestFromJSONTyped(json, false);
}

export function CardSaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardSaveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardList': !exists(json, 'card_list') ? undefined : json['card_list'],
    };
}

export function CardSaveRequestToJSON(value?: CardSaveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_list': value.cardList,
    };
}

