/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserShopCombinedsaleItem } from './UserShopCombinedsaleItem';
import {
    UserShopCombinedsaleItemFromJSON,
    UserShopCombinedsaleItemFromJSONTyped,
    UserShopCombinedsaleItemToJSON,
} from './UserShopCombinedsaleItem';

/**
 * 
 * @export
 * @interface UserShopCombinedsale
 */
export interface UserShopCombinedsale {
    /**
     * セット販売コード
     * @type {string}
     * @memberof UserShopCombinedsale
     */
    combinedsaleCd: string;
    /**
     * 開始日時
     * @type {string}
     * @memberof UserShopCombinedsale
     */
    startedAt: string | null;
    /**
     * 終了日時
     * @type {string}
     * @memberof UserShopCombinedsale
     */
    endedAt: string | null;
    /**
     * 
     * @type {Array<UserShopCombinedsaleItem>}
     * @memberof UserShopCombinedsale
     */
    combinedsaleItemList: Array<UserShopCombinedsaleItem>;
}

/**
 * Check if a given object implements the UserShopCombinedsale interface.
 */
export function instanceOfUserShopCombinedsale(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "combinedsaleCd" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "combinedsaleItemList" in value;

    return isInstance;
}

export function UserShopCombinedsaleFromJSON(json: any): UserShopCombinedsale {
    return UserShopCombinedsaleFromJSONTyped(json, false);
}

export function UserShopCombinedsaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserShopCombinedsale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'combinedsaleCd': json['combinedsale_cd'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'combinedsaleItemList': ((json['combinedsale_item_list'] as Array<any>).map(UserShopCombinedsaleItemFromJSON)),
    };
}

export function UserShopCombinedsaleToJSON(value?: UserShopCombinedsale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'combinedsale_cd': value.combinedsaleCd,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'combinedsale_item_list': ((value.combinedsaleItemList as Array<any>).map(UserShopCombinedsaleItemToJSON)),
    };
}

