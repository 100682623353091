/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAuthProvider
 */
export interface UserAuthProvider {
    /**
     * プロバイダーのID
     * @type {string}
     * @memberof UserAuthProvider
     */
    providerId: string;
    /**
     * プロバイダーのUUID
     * @type {string}
     * @memberof UserAuthProvider
     */
    providerUid: string;
}

/**
 * Check if a given object implements the UserAuthProvider interface.
 */
export function instanceOfUserAuthProvider(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "providerId" in value;
    isInstance = isInstance && "providerUid" in value;

    return isInstance;
}

export function UserAuthProviderFromJSON(json: any): UserAuthProvider {
    return UserAuthProviderFromJSONTyped(json, false);
}

export function UserAuthProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providerId': json['provider_id'],
        'providerUid': json['provider_uid'],
    };
}

export function UserAuthProviderToJSON(value?: UserAuthProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider_id': value.providerId,
        'provider_uid': value.providerUid,
    };
}

