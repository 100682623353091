/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendList } from './UserFriendList';
import {
    UserFriendListFromJSON,
    UserFriendListFromJSONTyped,
    UserFriendListToJSON,
} from './UserFriendList';

/**
 * 
 * @export
 * @interface UserFriendLikeListResponseData
 */
export interface UserFriendLikeListResponseData {
    /**
     * 本日送ったいいね数
     * @type {number}
     * @memberof UserFriendLikeListResponseData
     */
    dailySendLikeCount: number;
    /**
     * 本日送れるいいね上限数
     * @type {number}
     * @memberof UserFriendLikeListResponseData
     */
    maxDailySendLikeCount: number;
    /**
     * 本日受け取ったいいね数
     * @type {number}
     * @memberof UserFriendLikeListResponseData
     */
    dailyReceivedLikeCount: number;
    /**
     * 本日受け取れるいいね上限数
     * @type {number}
     * @memberof UserFriendLikeListResponseData
     */
    totalReceivedLikeCount: number;
    /**
     * 送ったユーザーリスト
     * @type {Array<UserFriendList>}
     * @memberof UserFriendLikeListResponseData
     */
    sendLikeUserList: Array<UserFriendList>;
    /**
     * 受け取ったユーザーリスト
     * @type {Array<UserFriendList>}
     * @memberof UserFriendLikeListResponseData
     */
    receivedLikeUserList: Array<UserFriendList>;
    /**
     * 相互フォローリスト
     * @type {Array<UserFriendList>}
     * @memberof UserFriendLikeListResponseData
     */
    mutualFollowList?: Array<UserFriendList>;
}

/**
 * Check if a given object implements the UserFriendLikeListResponseData interface.
 */
export function instanceOfUserFriendLikeListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "dailySendLikeCount" in value;
    isInstance = isInstance && "maxDailySendLikeCount" in value;
    isInstance = isInstance && "dailyReceivedLikeCount" in value;
    isInstance = isInstance && "totalReceivedLikeCount" in value;
    isInstance = isInstance && "sendLikeUserList" in value;
    isInstance = isInstance && "receivedLikeUserList" in value;

    return isInstance;
}

export function UserFriendLikeListResponseDataFromJSON(json: any): UserFriendLikeListResponseData {
    return UserFriendLikeListResponseDataFromJSONTyped(json, false);
}

export function UserFriendLikeListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendLikeListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dailySendLikeCount': json['daily_send_like_count'],
        'maxDailySendLikeCount': json['max_daily_send_like_count'],
        'dailyReceivedLikeCount': json['daily_received_like_count'],
        'totalReceivedLikeCount': json['total_received_like_count'],
        'sendLikeUserList': ((json['send_like_user_list'] as Array<any>).map(UserFriendListFromJSON)),
        'receivedLikeUserList': ((json['received_like_user_list'] as Array<any>).map(UserFriendListFromJSON)),
        'mutualFollowList': !exists(json, 'mutual_follow_list') ? undefined : ((json['mutual_follow_list'] as Array<any>).map(UserFriendListFromJSON)),
    };
}

export function UserFriendLikeListResponseDataToJSON(value?: UserFriendLikeListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'daily_send_like_count': value.dailySendLikeCount,
        'max_daily_send_like_count': value.maxDailySendLikeCount,
        'daily_received_like_count': value.dailyReceivedLikeCount,
        'total_received_like_count': value.totalReceivedLikeCount,
        'send_like_user_list': ((value.sendLikeUserList as Array<any>).map(UserFriendListToJSON)),
        'received_like_user_list': ((value.receivedLikeUserList as Array<any>).map(UserFriendListToJSON)),
        'mutual_follow_list': value.mutualFollowList === undefined ? undefined : ((value.mutualFollowList as Array<any>).map(UserFriendListToJSON)),
    };
}

