import { Ref } from 'vue';
import { useNuxtApp } from '#app';
import { isIOS } from '~/libs/platform';

class ATT {
  protected iOSATT: Ref<boolean>;
  constructor (iOSATT: Ref<boolean>) {
    this.iOSATT = iOSATT;
  }

  public set (iOSATT: boolean) {
    this.iOSATT.value = iOSATT;
  }

  /**
   * iOS ATTモーダルを表示する必要があるか
   */
  get shouldShowATT () {
    const { $storage } = useNuxtApp();
    return isIOS() && this.iOSATT.value && !$storage.get('showIOSATT');
  }
}

/**
 * iOS ATTモーダルを表示する必要があるかを保存・取得する
 */
export const useATT = () => {
  const iOSATT = useState<boolean>('iOSATT', () => false);
  return new ATT(iOSATT);
};
