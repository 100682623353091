// eslint-disable-next-line import/no-named-as-default
import BugsnagPerformance from '@bugsnag/browser-performance';

interface BugSnagPerformanceConfig {
  disabled: boolean,
  // @see https://docs.bugsnag.com/performance/integration-guides/web/
  config: {
    apiKey: string,
    enabledReleaseStages?: string[],
    releaseStage?: string,
    appVersion?: string,
  },
}

export default defineNuxtPlugin(() => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const configure: BugSnagPerformanceConfig = useRuntimeConfig().public.bugsnagPerformance;
  if (configure.disabled || !configure.config.apiKey) {
    useLog('bugsnagPerformance').debug('plugin disable.');
    return;
  }
  BugsnagPerformance.start(configure.config);
  useLog('bugsnagPerformance').debug('plugin enabled.');
});
