/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * リーダーカード情報
 * @export
 * @interface UserFriendListLeaderCardInfo
 */
export interface UserFriendListLeaderCardInfo {
    /**
     * 
     * @type {number}
     * @memberof UserFriendListLeaderCardInfo
     */
    position: number;
    /**
     * リーダーのカードコード
     * @type {string}
     * @memberof UserFriendListLeaderCardInfo
     */
    cardCd: string;
    /**
     * 魅力値
     * @type {number}
     * @memberof UserFriendListLeaderCardInfo
     */
    parameter: number;
    /**
     * 合成回数
     * @type {number}
     * @memberof UserFriendListLeaderCardInfo
     */
    synthesisCount: number;
    /**
     * イラスト変更フラグ
     * @type {boolean}
     * @memberof UserFriendListLeaderCardInfo
     */
    changeIllustFlg: boolean;
}

/**
 * Check if a given object implements the UserFriendListLeaderCardInfo interface.
 */
export function instanceOfUserFriendListLeaderCardInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "parameter" in value;
    isInstance = isInstance && "synthesisCount" in value;
    isInstance = isInstance && "changeIllustFlg" in value;

    return isInstance;
}

export function UserFriendListLeaderCardInfoFromJSON(json: any): UserFriendListLeaderCardInfo {
    return UserFriendListLeaderCardInfoFromJSONTyped(json, false);
}

export function UserFriendListLeaderCardInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendListLeaderCardInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': json['position'],
        'cardCd': json['card_cd'],
        'parameter': json['parameter'],
        'synthesisCount': json['synthesis_count'],
        'changeIllustFlg': json['change_illust_flg'],
    };
}

export function UserFriendListLeaderCardInfoToJSON(value?: UserFriendListLeaderCardInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'card_cd': value.cardCd,
        'parameter': value.parameter,
        'synthesis_count': value.synthesisCount,
        'change_illust_flg': value.changeIllustFlg,
    };
}

