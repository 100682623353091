import { defineNuxtPlugin } from '#app';
import { useAuth, useBugsnag, useUser } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // nuxtApp.hook('vue:error', (...args) => {
  //   useLog().error('[app] vue:error', args);
  // });
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // nuxtApp.hook('app:error', (err: any) => {
  //   useLog().error('[app] app:error', err);
  // });
  nuxtApp.vueApp.config.errorHandler = (error, context) => {
    useLog().error('[app] errorHandler', error, context);
  };
  // エラーレポートの設定
  if (useBugsnag()) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    useBugsnag().addOnError((event: any|Event) => {
      // ユーザー情報を付与する
      const userId = useAuth().getCurrentUser()?.uid;
      if (userId) {
        const userName = useUser().user.value?.nickname;
        event.setUser(userId, undefined, userName ?? undefined);
      }
    });
  }
});
