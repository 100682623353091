/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingProduct } from './BillingProduct';
import {
    BillingProductFromJSON,
    BillingProductFromJSONTyped,
    BillingProductToJSON,
} from './BillingProduct';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';

/**
 * 
 * @export
 * @interface ShopProductListResponse
 */
export interface ShopProductListResponse {
    /**
     * 
     * @type {Array<BillingProduct>}
     * @memberof ShopProductListResponse
     */
    data: Array<BillingProduct>;
    /**
     * 
     * @type {MetaCommon}
     * @memberof ShopProductListResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the ShopProductListResponse interface.
 */
export function instanceOfShopProductListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function ShopProductListResponseFromJSON(json: any): ShopProductListResponse {
    return ShopProductListResponseFromJSONTyped(json, false);
}

export function ShopProductListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopProductListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BillingProductFromJSON)),
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function ShopProductListResponseToJSON(value?: ShopProductListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(BillingProductToJSON)),
        'meta': MetaCommonToJSON(value.meta),
    };
}

