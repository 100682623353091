/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserNews } from './UserNews';
import {
    UserNewsFromJSON,
    UserNewsFromJSONTyped,
    UserNewsToJSON,
} from './UserNews';

/**
 * 
 * @export
 * @interface UserNewsListResponseData
 */
export interface UserNewsListResponseData {
    /**
     * 
     * @type {boolean}
     * @memberof UserNewsListResponseData
     */
    unread: boolean;
    /**
     * 
     * @type {Array<UserNews>}
     * @memberof UserNewsListResponseData
     */
    posts: Array<UserNews>;
}

/**
 * Check if a given object implements the UserNewsListResponseData interface.
 */
export function instanceOfUserNewsListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "unread" in value;
    isInstance = isInstance && "posts" in value;

    return isInstance;
}

export function UserNewsListResponseDataFromJSON(json: any): UserNewsListResponseData {
    return UserNewsListResponseDataFromJSONTyped(json, false);
}

export function UserNewsListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNewsListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unread': json['unread'],
        'posts': ((json['posts'] as Array<any>).map(UserNewsFromJSON)),
    };
}

export function UserNewsListResponseDataToJSON(value?: UserNewsListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unread': value.unread,
        'posts': ((value.posts as Array<any>).map(UserNewsToJSON)),
    };
}

