import {
  Event, EventSceneType,
  EventStatus,
} from 'api';
import { RouteLocationNormalized } from 'vue-router';
import { useNuxtApp } from '#app';
import { formatDate, inPeriod, parseDate } from '~/libs/date';
import { useLabotan } from '~/composables/useLabotan';
import { storageKey } from '~/configs/storage';
import { eventFeverAppealSleepTime } from '~/configs/event';
/**
 * URLからeventCdを取得
 */
export const getRouteEventCd = (route?: RouteLocationNormalized) => {
  const to = route ?? useRoute();
  return to.params.event_cd?.toString();
};

// キャッシュする時間
const cacheTime = 60000;
// 前回のキャッシュ時間(ms)
let cacheSetTime = 0;

interface UpdateOption {
  cache: boolean,
}

let eventList: Event[];
const updateEvent = async (options?: UpdateOption) => {
  // キャッシュ有効な場合はリクエストさせない
  if (options?.cache && eventList && (cacheSetTime + cacheTime) >= Date.now()) {
    return eventList;
  }
  const list = (await useApi().master.getEventListMst()).data;
  eventList = list;
  cacheSetTime = Date.now();
  return list;
};
/**
 * 現在アクティブなイベント情報を更新
 * @param options
 */
const updateCurrentEvent = async (options?: UpdateOption) => {
  await updateEvent(options);
  // 現在アクティブなイベント情報
  useEvent().currentEvent.value = eventList ? getEnableCurrentEvent(eventList) : undefined;
  useEvent().endEvent.value = eventList ? getEndCurrentEvent(eventList) : undefined;
};
const resetCurrentEvent = () => {
  useEvent().currentEvent.value = undefined;
  useEvent().endEvent.value = undefined;
};
/**
 * 現在のページのイベント情報を更新
 * @param route
 * @param options
 */
const updatePageEvent = async (route: RouteLocationNormalized, options?: UpdateOption) => {
  const eventCd = getRouteEventCd(route);
  if (!eventCd) {
    resetPageEvent();
    return false;
  }
  await updateEvent(options);
  // イベントページのイベント情報
  const currentEvent = eventList.find(v => v.eventCd === eventCd && isEnableEvent(v));
  if (!currentEvent) {
    resetPageEvent();
    return false;
  }
  const eventStatus = currentEvent ? (await useApi().user.getEventStatus({ eventCd: currentEvent.eventCd })).data : undefined;
  useEvent().event.value = currentEvent;
  useEvent().status.value = eventStatus;
  return true;
};
const resetPageEvent = () => {
  useEvent().event.value = undefined;
  useEvent().status.value = undefined;
};

/**
 * 画面間で共通で使用するイベントデータを保存しておく
 */
export const useEvent = () => {
  const currentEvent = useState<Event | undefined>('currentEvent');
  const endEvent = useState<Event | undefined>('endEvent');
  const pageEvent = useState<Event | undefined>('pageEvent');
  const pageEventStatus = useState<EventStatus | undefined>('pageEventStatus');
  return {
    // 現在アクティブなイベント情報
    currentEvent,
    // 終了済み(ランキング表示中)のイベント
    endEvent,
    // イベントページのイベント情報
    event: pageEvent,
    status: pageEventStatus,
    // 情報の更新
    updateCurrentEvent,
    resetCurrentEvent,
    updatePageEvent,
    resetPageEvent,
  };
};

/**
 * 画面間で共通で使用するイベントデータ(受け取ったボーナス)を保存しておく
 */
export const useEventReceivedBonusCds = () => {
  return useState<string[]>('eventReceivedBonus', () => []);
};

/**
 * イベントページへ遷移する
 */
export const navigateEventTo = async (eventCd: string, page?: string) => {
  return await navigateTo(getEventUrl(eventCd, page));
};

/**
 * イベントページへ遷移する
 */
export const getEventUrl = (eventCd: string, page?: string) => {
  return `/event/${eventCd}${page ?? ''}`;
};

/**
 * 現在開催中のイベントデータを返す
 * @param eventList
 */
const getEnableCurrentEvent = (eventList: Event[]) => {
  return eventList.find(function (eventMst) {
    return isActiveEvent(eventMst);
  });
};

/**
 * 現在開催終了(ランキング期間中)のイベントデータを返す
 * @param eventList
 */
const getEndCurrentEvent = (eventList: Event[]) => {
  return eventList.find(function (eventMst) {
    return isEnableEvent(eventMst) && !isActiveEvent(eventMst);
  });
};

/**
 * イベントが開催しているかチェック
 */
export const isActiveEvent = (event: Event | string): boolean => {
  const eventMst: Event | undefined = typeof event === 'string' ? eventList.find(v => v.eventCd === event && isActiveEvent(v)) : event;
  if (!eventMst) {
    return false;
  }
  const currentDate = useLabotan().date;
  return inPeriod(eventMst.startedAt, eventMst.endedAt, currentDate);
};

/**
 * イベントページが有効かチェック
 */
export const isEnableEvent = (event: Event | string): boolean => {
  const eventMst: Event | undefined = typeof event === 'string' ? eventList.find(v => v.eventCd === event && isEnableEvent(v)) : event;
  if (!eventMst) {
    return false;
  }
  const currentDate = useLabotan().date;
  return inPeriod(eventMst.startedAt, eventMst.rankingEndedAt, currentDate);
};

export interface EventMetaImageData {
  id: string,
  common: boolean,
  text: string,
}
export interface EventMetaInfoData {
  title: string,
  images: EventMetaImageData[],
}
export interface EventMetaGuideData {
  event_description: EventMetaInfoData,
  love_mission: EventMetaInfoData,
  minigame: EventMetaInfoData,
}
export interface EventMetaData {
  guides: EventMetaGuideData,
}

/**
 * meta.jsonの取得
 */
export const fetchMetaData = async () => {
  const { $resourceTextureUrl } = useNuxtApp();
  const event = useEvent().event.value;
  if (!event) {
    return;
  }
  const { data } = await useFetch<EventMetaData>($resourceTextureUrl('event', event.resourcePartition, 'meta', 'json', 'common/guides'));
  return data.value;
};

/**
 * イベントフィーバー請求を行うかのチェックと保存
 */
export const isShowEventFeverAppeal = (event: Event, feverCd: string) => {
  const { $storage } = useNuxtApp();
  const now = useLabotan().date;
  const eventFeverAppeal = $storage.get(storageKey.eventFeverAppeal);
  const currentSaveValue = getEventFeverAppealData(event, feverCd, now);
  if (!eventFeverAppeal || typeof eventFeverAppeal !== 'string') {
    $storage.save(storageKey.eventFeverAppeal, currentSaveValue);
    return true;
  }
  const [
    dEventCd,
    dFeverCd,
    dDateStr,
  ] = eventFeverAppeal.split('/');
  // データ不正
  if (!dEventCd || !dFeverCd || !dDateStr) {
    $storage.save(storageKey.eventFeverAppeal, currentSaveValue);
    return true;
  }
  const dDate = parseDate(dDateStr);
  if (dDate && dEventCd === event.eventCd && dFeverCd === feverCd) {
    // 指定時間内の同じイベント請求は処理しない
    dDate.setSeconds(dDate.getSeconds() + eventFeverAppealSleepTime);
    if (inPeriod(null, dDate, now)) {
      return false;
    }
  }
  return true;
};
/**
 * イベントフィーバー請求を行うかのデータ保存
 */
export const saveShowEventFeverAppeal = (event: Event, feverCd: string) => {
  const { $storage } = useNuxtApp();
  const now = useLabotan().date;
  const currentSaveValue = getEventFeverAppealData(event, feverCd, now);
  $storage.save(storageKey.eventFeverAppeal, currentSaveValue);
};

const getEventFeverAppealData = (event: Event, feverCd: string, now: string | Date) => {
  return `${event.eventCd}/${feverCd}/${formatDate(now)}`;
};

/**
 * パラメータ特典受け取り
 * @param event
 * @param scene
 */
export const postEventBonusReceiveBonus = async (event: Event, scene: EventSceneType) => {
  const eventBonusReceiveResponse = await useApi().user.postEventBonusReceive({
    eventCd: event.eventCd,
    userEventBonusReceiveRequest: {
      sceneType: scene,
    },
  });
  // 受け取った特典を追加
  const eventBonusReceive = eventBonusReceiveResponse.data;
  eventBonusReceive.forEach((eventBonusCd: string) => {
    useEventReceivedBonusCds().value.push(eventBonusCd);
  });
  return eventBonusReceive;
};

/**
 * 受け取ったイベント特典データの取得/slackから削除
 */
export const popEventReceivedBonusList = (event: Event) => {
  // 受け取った特典を取得
  const eventBonusList = [];
  const eventBonusCds = useEventReceivedBonusCds().value;
  for (const eventBonusCdsKey in eventBonusCds) {
    const eventBonusCd = eventBonusCds[eventBonusCdsKey];
    const bonus = event.bonusList.find(v => v.eventBonusCd === eventBonusCd);
    if (bonus) {
      eventBonusList.push(bonus);
    }
  }
  // 表示する特典をクリア
  useEventReceivedBonusCds().value = [];
  return eventBonusList;
};
