/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingProductPurchaseType } from './BillingProductPurchaseType';
import {
    BillingProductPurchaseTypeFromJSON,
    BillingProductPurchaseTypeFromJSONTyped,
    BillingProductPurchaseTypeToJSON,
} from './BillingProductPurchaseType';
import type { BillingProductSaleType } from './BillingProductSaleType';
import {
    BillingProductSaleTypeFromJSON,
    BillingProductSaleTypeFromJSONTyped,
    BillingProductSaleTypeToJSON,
} from './BillingProductSaleType';

/**
 * 
 * @export
 * @interface BillingProduct
 */
export interface BillingProduct {
    /**
     * 課金アイテムコード
     * @type {string}
     * @memberof BillingProduct
     */
    billingProductCd: string;
    /**
     * 名称
     * @type {string}
     * @memberof BillingProduct
     */
    productName: string;
    /**
     * 付与する無償ダイヤ
     * @type {number}
     * @memberof BillingProduct
     */
    freeQuantity: number;
    /**
     * 付与する有償ダイヤ
     * @type {number}
     * @memberof BillingProduct
     */
    quantity: number;
    /**
     * 価格
     * @type {number}
     * @memberof BillingProduct
     */
    price: number;
    /**
     * 優先度
     * @type {number}
     * @memberof BillingProduct
     */
    priority: number;
    /**
     * 説明
     * @type {string}
     * @memberof BillingProduct
     */
    description: string;
    /**
     * プロダクトコード
     * @type {string}
     * @memberof BillingProduct
     */
    inapp: string;
    /**
     * 
     * @type {BillingProductPurchaseType}
     * @memberof BillingProduct
     */
    purchaseType: BillingProductPurchaseType;
    /**
     * 
     * @type {BillingProductSaleType}
     * @memberof BillingProduct
     */
    saleType: BillingProductSaleType;
    /**
     * 購入回数
     * @type {number}
     * @memberof BillingProduct
     */
    buyCount: number;
    /**
     * 購入上限回数。上限が無い場合は0。
     * @type {number}
     * @memberof BillingProduct
     */
    limitCount: number;
    /**
     * 表示開始日時。無期限の場合はNULL。
     * @type {string}
     * @memberof BillingProduct
     */
    startedAt: string | null;
    /**
     * 表示終了日時。無期限の場合はNULL。
     * @type {string}
     * @memberof BillingProduct
     */
    endedAt: string | null;
}

/**
 * Check if a given object implements the BillingProduct interface.
 */
export function instanceOfBillingProduct(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "billingProductCd" in value;
    isInstance = isInstance && "productName" in value;
    isInstance = isInstance && "freeQuantity" in value;
    isInstance = isInstance && "quantity" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "priority" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "inapp" in value;
    isInstance = isInstance && "purchaseType" in value;
    isInstance = isInstance && "saleType" in value;
    isInstance = isInstance && "buyCount" in value;
    isInstance = isInstance && "limitCount" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;

    return isInstance;
}

export function BillingProductFromJSON(json: any): BillingProduct {
    return BillingProductFromJSONTyped(json, false);
}

export function BillingProductFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingProduct {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingProductCd': json['billing_product_cd'],
        'productName': json['product_name'],
        'freeQuantity': json['free_quantity'],
        'quantity': json['quantity'],
        'price': json['price'],
        'priority': json['priority'],
        'description': json['description'],
        'inapp': json['inapp'],
        'purchaseType': BillingProductPurchaseTypeFromJSON(json['purchase_type']),
        'saleType': BillingProductSaleTypeFromJSON(json['sale_type']),
        'buyCount': json['buy_count'],
        'limitCount': json['limit_count'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
    };
}

export function BillingProductToJSON(value?: BillingProduct | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billing_product_cd': value.billingProductCd,
        'product_name': value.productName,
        'free_quantity': value.freeQuantity,
        'quantity': value.quantity,
        'price': value.price,
        'priority': value.priority,
        'description': value.description,
        'inapp': value.inapp,
        'purchase_type': BillingProductPurchaseTypeToJSON(value.purchaseType),
        'sale_type': BillingProductSaleTypeToJSON(value.saleType),
        'buy_count': value.buyCount,
        'limit_count': value.limitCount,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
    };
}

