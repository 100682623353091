/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAvatarHaveAvatarListCoordinate
 */
export interface UserAvatarHaveAvatarListCoordinate {
    /**
     * X座標
     * @type {number}
     * @memberof UserAvatarHaveAvatarListCoordinate
     */
    x: number;
    /**
     * Y座標
     * @type {number}
     * @memberof UserAvatarHaveAvatarListCoordinate
     */
    y: number;
    /**
     * 角度
     * @type {number}
     * @memberof UserAvatarHaveAvatarListCoordinate
     */
    degree: number;
    /**
     * 重なり順
     * @type {number}
     * @memberof UserAvatarHaveAvatarListCoordinate
     */
    zOrder: number;
    /**
     * 反転フラグ
     * @type {boolean}
     * @memberof UserAvatarHaveAvatarListCoordinate
     */
    reverseFlg: boolean;
}

/**
 * Check if a given object implements the UserAvatarHaveAvatarListCoordinate interface.
 */
export function instanceOfUserAvatarHaveAvatarListCoordinate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "x" in value;
    isInstance = isInstance && "y" in value;
    isInstance = isInstance && "degree" in value;
    isInstance = isInstance && "zOrder" in value;
    isInstance = isInstance && "reverseFlg" in value;

    return isInstance;
}

export function UserAvatarHaveAvatarListCoordinateFromJSON(json: any): UserAvatarHaveAvatarListCoordinate {
    return UserAvatarHaveAvatarListCoordinateFromJSONTyped(json, false);
}

export function UserAvatarHaveAvatarListCoordinateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAvatarHaveAvatarListCoordinate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': json['x'],
        'y': json['y'],
        'degree': json['degree'],
        'zOrder': json['z_order'],
        'reverseFlg': json['reverse_flg'],
    };
}

export function UserAvatarHaveAvatarListCoordinateToJSON(value?: UserAvatarHaveAvatarListCoordinate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': value.x,
        'y': value.y,
        'degree': value.degree,
        'z_order': value.zOrder,
        'reverse_flg': value.reverseFlg,
    };
}

