/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuAddPoint } from './CommuAddPoint';
import {
    CommuAddPointFromJSON,
    CommuAddPointFromJSONTyped,
    CommuAddPointToJSON,
} from './CommuAddPoint';

/**
 * 
 * @export
 * @interface UserEventStoryComplete
 */
export interface UserEventStoryComplete {
    /**
     * 話数
     * @type {number}
     * @memberof UserEventStoryComplete
     */
    chapterOrderNo: number;
    /**
     * 場面
     * @type {number}
     * @memberof UserEventStoryComplete
     */
    ticketOrderNo: number;
    /**
     * 現在のチャプターの最後の場面
     * @type {number}
     * @memberof UserEventStoryComplete
     */
    lastTicketOrderNo: number;
    /**
     * ミッションコード
     * @type {string}
     * @memberof UserEventStoryComplete
     */
    missionCd: string;
    /**
     * ミッショングループコード
     * @type {string}
     * @memberof UserEventStoryComplete
     */
    missionGroupCd: string;
    /**
     * ストーリーチケットコード
     * @type {string}
     * @memberof UserEventStoryComplete
     */
    nextTicketCd: string;
    /**
     * ルートをクリアしたかどうか
     * @type {boolean}
     * @memberof UserEventStoryComplete
     */
    isRouteEnd: boolean;
    /**
     * 読了時に獲得したボーナスリスト
     * @type {Array<string>}
     * @memberof UserEventStoryComplete
     */
    routeClearBonusList: Array<string>;
    /**
     * このイベントで一番最初にクリアした場合にtrue
     * @type {boolean}
     * @memberof UserEventStoryComplete
     */
    isFirstCharaClear: boolean;
    /**
     * コミュpt上昇結果
     * @type {Array<CommuAddPoint>}
     * @memberof UserEventStoryComplete
     */
    commuAddPointList: Array<CommuAddPoint>;
}

/**
 * Check if a given object implements the UserEventStoryComplete interface.
 */
export function instanceOfUserEventStoryComplete(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "chapterOrderNo" in value;
    isInstance = isInstance && "ticketOrderNo" in value;
    isInstance = isInstance && "lastTicketOrderNo" in value;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "missionGroupCd" in value;
    isInstance = isInstance && "nextTicketCd" in value;
    isInstance = isInstance && "isRouteEnd" in value;
    isInstance = isInstance && "routeClearBonusList" in value;
    isInstance = isInstance && "isFirstCharaClear" in value;
    isInstance = isInstance && "commuAddPointList" in value;

    return isInstance;
}

export function UserEventStoryCompleteFromJSON(json: any): UserEventStoryComplete {
    return UserEventStoryCompleteFromJSONTyped(json, false);
}

export function UserEventStoryCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventStoryComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chapterOrderNo': json['chapter_order_no'],
        'ticketOrderNo': json['ticket_order_no'],
        'lastTicketOrderNo': json['last_ticket_order_no'],
        'missionCd': json['mission_cd'],
        'missionGroupCd': json['mission_group_cd'],
        'nextTicketCd': json['next_ticket_cd'],
        'isRouteEnd': json['is_route_end'],
        'routeClearBonusList': json['route_clear_bonus_list'],
        'isFirstCharaClear': json['is_first_chara_clear'],
        'commuAddPointList': ((json['commu_add_point_list'] as Array<any>).map(CommuAddPointFromJSON)),
    };
}

export function UserEventStoryCompleteToJSON(value?: UserEventStoryComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chapter_order_no': value.chapterOrderNo,
        'ticket_order_no': value.ticketOrderNo,
        'last_ticket_order_no': value.lastTicketOrderNo,
        'mission_cd': value.missionCd,
        'mission_group_cd': value.missionGroupCd,
        'next_ticket_cd': value.nextTicketCd,
        'is_route_end': value.isRouteEnd,
        'route_clear_bonus_list': value.routeClearBonusList,
        'is_first_chara_clear': value.isFirstCharaClear,
        'commu_add_point_list': ((value.commuAddPointList as Array<any>).map(CommuAddPointToJSON)),
    };
}

