/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserMinigameHistoryHistoriesInner } from './UserMinigameHistoryHistoriesInner';
import {
    UserMinigameHistoryHistoriesInnerFromJSON,
    UserMinigameHistoryHistoriesInnerFromJSONTyped,
    UserMinigameHistoryHistoriesInnerToJSON,
} from './UserMinigameHistoryHistoriesInner';

/**
 * 
 * @export
 * @interface UserMinigameHistory
 */
export interface UserMinigameHistory {
    /**
     * ミニゲーム実施日
     * @type {string}
     * @memberof UserMinigameHistory
     */
    date: string;
    /**
     * ミニゲーム履歴
     * @type {Array<UserMinigameHistoryHistoriesInner>}
     * @memberof UserMinigameHistory
     */
    histories: Array<UserMinigameHistoryHistoriesInner>;
}

/**
 * Check if a given object implements the UserMinigameHistory interface.
 */
export function instanceOfUserMinigameHistory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "histories" in value;

    return isInstance;
}

export function UserMinigameHistoryFromJSON(json: any): UserMinigameHistory {
    return UserMinigameHistoryFromJSONTyped(json, false);
}

export function UserMinigameHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMinigameHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'date': json['date'],
        'histories': ((json['histories'] as Array<any>).map(UserMinigameHistoryHistoriesInnerFromJSON)),
    };
}

export function UserMinigameHistoryToJSON(value?: UserMinigameHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'date': value.date,
        'histories': ((value.histories as Array<any>).map(UserMinigameHistoryHistoriesInnerToJSON)),
    };
}

