/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * パネルミッションタイプ
 * @export
 */
export const PanelmissionType = {
    Newcomer: 'newcomer',
    Event: 'event',
    Achievement: 'achievement'
} as const;
export type PanelmissionType = typeof PanelmissionType[keyof typeof PanelmissionType];


export function PanelmissionTypeFromJSON(json: any): PanelmissionType {
    return PanelmissionTypeFromJSONTyped(json, false);
}

export function PanelmissionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionType {
    return json as PanelmissionType;
}

export function PanelmissionTypeToJSON(value?: PanelmissionType | null): any {
    return value as any;
}

