import { Dexie } from 'dexie';
import { SystemError } from '~/libs/errors';
import { databaseConfig } from '~/configs/database';

/**
 * indexDBへのアクセス
 */
export class DatabaseLibrary {
  static connection (name: string | null = null) {
    if (!name) {
      name = databaseConfig.default;
    }
    const define = this.getDefine(name);
    const db = new Dexie(name);
    const schemes: Record<string, string> = {};
    for (const schemesKey in define.schemes) {
      schemes[schemesKey] = define.schemes[schemesKey].join(',');
    }
    db.version(define.version).stores(schemes);
    return db;
  }

  /**
   * 設定の取得
   * @param key
   * @protected
   */
  protected static getDefine (key: string) {
    const data = databaseConfig.connections[key];
    if (!data) {
      throw new SystemError(`not define databaseConfig key "${key}"`);
    }
    return data;
  }
}
