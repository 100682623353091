/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuCharaVoice
 */
export interface CommuCharaVoice {
    /**
     * ボイスコード
     * @type {string}
     * @memberof CommuCharaVoice
     */
    voiceCd: string;
    /**
     * ボイステキスト
     * @type {string}
     * @memberof CommuCharaVoice
     */
    voiceText: string;
    /**
     * リソースパーティション番号
     * @type {number}
     * @memberof CommuCharaVoice
     */
    resourcePartition: number;
}

/**
 * Check if a given object implements the CommuCharaVoice interface.
 */
export function instanceOfCommuCharaVoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "voiceCd" in value;
    isInstance = isInstance && "voiceText" in value;
    isInstance = isInstance && "resourcePartition" in value;

    return isInstance;
}

export function CommuCharaVoiceFromJSON(json: any): CommuCharaVoice {
    return CommuCharaVoiceFromJSONTyped(json, false);
}

export function CommuCharaVoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaVoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'voiceCd': json['voice_cd'],
        'voiceText': json['voice_text'],
        'resourcePartition': json['resource_partition'],
    };
}

export function CommuCharaVoiceToJSON(value?: CommuCharaVoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voice_cd': value.voiceCd,
        'voice_text': value.voiceText,
        'resource_partition': value.resourcePartition,
    };
}

