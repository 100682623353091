import {
  BaseAPI,
  Configuration, ConfigurationParameters, ErrorContext,
  FetchParams, HTTPHeaders, HTTPMethod,
  MasterApi,
  Middleware,
  RequestContext,
  ResponseContext,
  UserApi,
  HealthcheckApi,
} from 'api';
import { navigateTo } from '#app';
import { version } from '~/package.json';
import { useAuth } from '~/composables/useAuth';
import {
  ApiAnimateExpiredIdTokenError,
  ApiAuthenticationError,
  ApiError,
  ApiExpiredIdTokenError,
  BanUserError,
  MaintenanceError,
  NetworkError
} from '~/libs/errors';
import { useLog } from '~/composables/useLog';
import { errorConfig } from '~/configs/errors';
import { saveResourceHash } from '~/composables/useResource';
import { getApiUrlPath, getPlatformString } from '~/libs/platform';
import { useTutorial } from '~/composables/useTutorial';
import { defaultStorage } from '~/libs/storage';

const logGroup = 'Api';
// デフォルトタイムアウト値(ms)
const timeout = 30000;

let config: Configuration;

/**
 * リトライ処理用のリクエストクラス
 */
class RetryApi extends BaseAPI {
  async retryRequest (context: ResponseContext) {
    const body = context.init.body;
    return await this.request({
      path: context.url,
      method: (context.init.method || 'GET') as HTTPMethod,
      headers: (context.init.headers || {}) as HTTPHeaders,
      query: {},
      body: (typeof body === 'string') ? JSON.parse(body as string) : body,
    });
  }
}
const retryApiRequest = async (context: ResponseContext) => {
  const auth = useAuth();
  // トークンを更新してリトライ
  await auth.getNewestIdToken(true);
  const config = new Configuration({
    ...defaultConfig,
    middleware: [
      {
        ...apiMiddleware,
        async post (context: ResponseContext): Promise<Response | void> {
          if (!context.response.ok) {
            throw await createApiError(context.response);
          }
          return context.response;
        }
      }
    ],
  });
  const api = new RetryApi(config);
  return await api.retryRequest(context);
};

const createApiError = async (response: Response): Promise<ApiError> => {
  const res = await response.json();
  if (response.status === 401) {
    // 認証エラー
    return new ApiAuthenticationError(res.error_code ?? 0, res.message ?? errorConfig.messages.unauthorized, response);
  } else if (response.status === 403) {
    if (res.error_code === errorConfig.errorCodes.userBannedError) {
      // アカウントBAN
      return new BanUserError(res.error_code ?? 0, res.message ?? errorConfig.messages.ban, response);
    }
  } else if (response.status === 503) {
    const route = useRoute();
    const isAuth = useAuth().isRequiredAuth(route);
    if (isAuth && route.name !== 'title') {
      useLog('useApi').debug('navigate to title.');
      // タイトル画面に遷移する
      await navigateTo('/title');
    }
    // メンテナンス
    return new MaintenanceError(res.error_code ?? 0, res.message, response);
  }
  if (!res || !res.error_code) {
    let message = errorConfig.messages.invalidError;
    if (response.status === 404) {
      message = errorConfig.messages.notFound;
    } else if (response.status === 429) {
      message = errorConfig.messages.tooManyRequests;
    } else if (response.status === 500) {
      message = errorConfig.messages.serverError;
    }
    return new ApiError(0, message, response);
  }
  return new ApiError(res.error_code, res.message, response);
};

const injectHeaders = async (headers: HeadersInit) => {
  if ('Authorization' in headers) {
    const auth = useAuth();
    const idToken = await auth.getNewestIdToken();
    headers.Authorization = `Bearer ${idToken ?? ''}`;
  }
  if ('X-Platform' in headers) {
    const platform = getPlatformString();
    if (typeof platform === 'string') {
      headers['X-Platform'] = platform;
      headers['X-Binary-Version'] = defaultStorage.get('appVersion');
    }
  }
  return headers;
};
/**
 * 全API共通処理(header)
 * @param response
 */
const responseHeadersExecute = async (response: Response) => {
  await (async () => {
    // ハッシュ値を取得
    const hashKey = 'X-Resource-Hash';
    if (response.headers.has(hashKey)) {
      const hash = response.headers.get(hashKey);
      if (hash) {
        await saveResourceHash(hash);
      }
    }
  })();
  await (async () => {
    // バイナリバージョン値を取得
    const hashKey = 'X-Binary-Version';
    if (response.headers.has(hashKey)) {
      const version = response.headers.get(hashKey);
      if (version) {
        await useAppBinary().update({
          version,
          url: response.headers.get('X-Binary-Url') ?? '',
          minimumOsVersion: response.headers.get('X-Minimum-OS-Version') ?? '',
        });
      }
    }
  })();
};
/**
 * 全API共通処理
 * @param response
 */
const responseExecute = async (response: Response) => {
  // ヘッダー情報を更新
  await responseHeadersExecute(response);
  try {
    const body = await response.clone().json();
    if (body && 'meta' in body) {
      // メタ情報取得
      const meta = body.meta;
      if ('stamina_max_schedule' in meta) {
        const staminaMaxSchedule = meta.stamina_max_schedule;
        useLocalNotification().sendStamina(staminaMaxSchedule);
      }
      if ('in_tutorial' in meta) {
        const isTutorial = ('in_tutorial' in meta) ? meta.in_tutorial : true;
        const tutorialCd = ('tutorial_cd' in meta) ? meta.tutorial_cd : null;
        const charaCd = ('chara_cd' in meta) ? meta.chara_cd : null;
        useTutorial().set(tutorialCd);
        useLocalNotification().sendLoginRequest(charaCd, isTutorial);
      }
      if ('time' in meta) {
        useLabotan().set(meta.time);
      }
    }
  } catch (e) {
    // 特に不要なためここでは何もしない
    useLog(logGroup).debug(e);
  }
};
/**
 * APIの追加middleware
 */
interface ApiMiddleware extends Middleware {
  timer?: NodeJS.Timeout;
}
const apiMiddleware: ApiMiddleware = {
  timer: undefined,
  async pre (context: RequestContext): Promise<FetchParams | void> {
    // 常に最新のtokenを利用する
    if (context.init.headers) {
      context.init.headers = await injectHeaders(context.init.headers);
    }
    // タイムアウトの実装
    const controller = new AbortController();
    this.timer = setTimeout(() => controller.abort(), timeout);
    return Promise.resolve({
      fetch: context.fetch,
      url: context.url,
      init: {
        ...context.init,
        mode: 'cors',
        signal: controller.signal,
      },
    });
  },
  async post (context: ResponseContext): Promise<Response | void> {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    // エラー処理
    let response = context.response;
    if (!context.response.ok) {
      const error = await createApiError(context.response);
      if (errorConfig.errorCodes.forcesFirebaseIdToken === error.code) {
        // トークンの更新が必要
        useLog(logGroup).debug('auto retry refreshIdToken and api call.');
        // トークンを更新してリトライ
        response = await retryApiRequest(context);
      } else if (errorConfig.errorCodes.animateInvalidAccessToken === error.code) {
        // トークンの有効期限切れ
        throw new ApiAnimateExpiredIdTokenError(error.code, error.message, error.response);
      } else if (errorConfig.errorCodes.expiredIdToken === error.code) {
        // トークンの有効期限切れ
        throw new ApiExpiredIdTokenError(error.code, error.message, error.response);
      } else {
        throw error;
      }
    }
    await responseExecute(response);
    return response;
  },
  onError (context: ErrorContext): Promise<Response | void> {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = undefined;
    }
    if (context.error instanceof Error) {
      throw new NetworkError(context.error.message);
    }
    return Promise.resolve();
  }
};
const defaultConfig: ConfigurationParameters = {
  basePath: '',
  // 共通ヘッダーを定義
  headers: {
    Accept: 'application/json',
    'X-App-Version': version,
    'X-Platform': 'none',
    'X-Binary-Version': defaultStorage.get('appVersion'),
  },
  // キーを生成するためにデフォルトを設定
  accessToken: 'XXX',
};
const getConfig = () => {
  if (!config) {
    config = new Configuration({
      ...defaultConfig,
      basePath: getApiUrlPath(''),
      middleware: [apiMiddleware],
    });
  }
  return config;
};

let apis: {
  user: UserApi,
  master: MasterApi,
  healthcheck: HealthcheckApi,
};
const getApis = () => {
  if (!apis) {
    apis = {
      user: new UserApi(getConfig()),
      master: new MasterApi(getConfig()),
      healthcheck: new HealthcheckApi(getConfig()),
    };
  }
  return apis;
};

/**
 * API通信処理
 */
export const useApi = () => {
  return getApis();
};
