/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ErrorResponse,
  HealthcheckPingResponse,
} from '../models';
import {
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    HealthcheckPingResponseFromJSON,
    HealthcheckPingResponseToJSON,
} from '../models';

export interface GetHealthcheckPingRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    pageName?: string;
}

/**
 * 
 */
export class HealthcheckApi extends runtime.BaseAPI {

    /**
     * ヘルスチェックAPI
     */
    async getHealthcheckPingRaw(requestParameters: GetHealthcheckPingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HealthcheckPingResponse>> {
        const queryParameters: any = {};

        if (requestParameters.pageName !== undefined) {
            queryParameters['page_name'] = requestParameters.pageName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/healthcheck/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthcheckPingResponseFromJSON(jsonValue));
    }

    /**
     * ヘルスチェックAPI
     */
    async getHealthcheckPing(requestParameters: GetHealthcheckPingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HealthcheckPingResponse> {
        const response = await this.getHealthcheckPingRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
