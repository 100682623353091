/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GachaItemCategory } from './GachaItemCategory';
import {
    GachaItemCategoryFromJSON,
    GachaItemCategoryFromJSONTyped,
    GachaItemCategoryToJSON,
} from './GachaItemCategory';
import type { UserGachaLineupItemProbabilityPickup } from './UserGachaLineupItemProbabilityPickup';
import {
    UserGachaLineupItemProbabilityPickupFromJSON,
    UserGachaLineupItemProbabilityPickupFromJSONTyped,
    UserGachaLineupItemProbabilityPickupToJSON,
} from './UserGachaLineupItemProbabilityPickup';

/**
 * 
 * @export
 * @interface UserGachaItem
 */
export interface UserGachaItem {
    /**
     * アイテム画像コード
     * @type {string}
     * @memberof UserGachaItem
     */
    image: string;
    /**
     * アイテム画像リソースパーティション
     * @type {number}
     * @memberof UserGachaItem
     */
    itemResourcePartition: number;
    /**
     * アイテム名
     * @type {string}
     * @memberof UserGachaItem
     */
    title: string;
    /**
     * 星◯
     * @type {number}
     * @memberof UserGachaItem
     */
    itemRarity: number;
    /**
     * アイテム個数
     * @type {number}
     * @memberof UserGachaItem
     */
    itemCount: number;
    /**
     * 確率(%)
     * @type {number}
     * @memberof UserGachaItem
     */
    probability: number;
    /**
     * イラスト変化ありなし
     * @type {boolean}
     * @memberof UserGachaItem
     */
    illustChangeFlg: boolean;
    /**
     * 
     * @type {GachaItemCategory}
     * @memberof UserGachaItem
     */
    itemCategory: GachaItemCategory;
    /**
     * 入手経路
     * @type {string}
     * @memberof UserGachaItem
     */
    getSituation?: string;
    /**
     * カード魅力値
     * @type {number}
     * @memberof UserGachaItem
     */
    charm: number;
    /**
     * 
     * @type {UserGachaLineupItemProbabilityPickup}
     * @memberof UserGachaItem
     */
    pickup: UserGachaLineupItemProbabilityPickup | null;
    /**
     * NEW表示有無フラグ
     * @type {boolean}
     * @memberof UserGachaItem
     */
    newDispFlg: boolean;
}

/**
 * Check if a given object implements the UserGachaItem interface.
 */
export function instanceOfUserGachaItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "image" in value;
    isInstance = isInstance && "itemResourcePartition" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "itemRarity" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "probability" in value;
    isInstance = isInstance && "illustChangeFlg" in value;
    isInstance = isInstance && "itemCategory" in value;
    isInstance = isInstance && "charm" in value;
    isInstance = isInstance && "pickup" in value;
    isInstance = isInstance && "newDispFlg" in value;

    return isInstance;
}

export function UserGachaItemFromJSON(json: any): UserGachaItem {
    return UserGachaItemFromJSONTyped(json, false);
}

export function UserGachaItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'image': json['image'],
        'itemResourcePartition': json['item_resource_partition'],
        'title': json['title'],
        'itemRarity': json['item_rarity'],
        'itemCount': json['item_count'],
        'probability': json['probability'],
        'illustChangeFlg': json['illust_change_flg'],
        'itemCategory': GachaItemCategoryFromJSON(json['item_category']),
        'getSituation': !exists(json, 'get_situation') ? undefined : json['get_situation'],
        'charm': json['charm'],
        'pickup': UserGachaLineupItemProbabilityPickupFromJSON(json['pickup']),
        'newDispFlg': json['new_disp_flg'],
    };
}

export function UserGachaItemToJSON(value?: UserGachaItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'image': value.image,
        'item_resource_partition': value.itemResourcePartition,
        'title': value.title,
        'item_rarity': value.itemRarity,
        'item_count': value.itemCount,
        'probability': value.probability,
        'illust_change_flg': value.illustChangeFlg,
        'item_category': GachaItemCategoryToJSON(value.itemCategory),
        'get_situation': value.getSituation,
        'charm': value.charm,
        'pickup': UserGachaLineupItemProbabilityPickupToJSON(value.pickup),
        'new_disp_flg': value.newDispFlg,
    };
}

