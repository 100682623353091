/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserNewsReadResponseData } from './UserNewsReadResponseData';
import {
    UserNewsReadResponseDataFromJSON,
    UserNewsReadResponseDataFromJSONTyped,
    UserNewsReadResponseDataToJSON,
} from './UserNewsReadResponseData';

/**
 * 
 * @export
 * @interface UserNewsReadResponse
 */
export interface UserNewsReadResponse {
    /**
     * 
     * @type {UserNewsReadResponseData}
     * @memberof UserNewsReadResponse
     */
    data: UserNewsReadResponseData;
}

/**
 * Check if a given object implements the UserNewsReadResponse interface.
 */
export function instanceOfUserNewsReadResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserNewsReadResponseFromJSON(json: any): UserNewsReadResponse {
    return UserNewsReadResponseFromJSONTyped(json, false);
}

export function UserNewsReadResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNewsReadResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserNewsReadResponseDataFromJSON(json['data']),
    };
}

export function UserNewsReadResponseToJSON(value?: UserNewsReadResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserNewsReadResponseDataToJSON(value.data),
    };
}

