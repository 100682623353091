/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CharaSelect } from './CharaSelect';
import {
    CharaSelectFromJSON,
    CharaSelectFromJSONTyped,
    CharaSelectToJSON,
} from './CharaSelect';

/**
 * 
 * @export
 * @interface Chara
 */
export interface Chara {
    /**
     * キャラコード
     * @type {string}
     * @memberof Chara
     */
    charaCd: string;
    /**
     * キャラ名
     * @type {string}
     * @memberof Chara
     */
    charaName: string;
    /**
     * キャラ名(フルネーム)
     * @type {string}
     * @memberof Chara
     */
    charaFullname: string;
    /**
     * 属性
     * @type {string}
     * @memberof Chara
     */
    attribute: string;
    /**
     * 種別 main:キャラ etc:その他（プロローグなど）
     * @type {string}
     * @memberof Chara
     */
    type: string;
    /**
     * 誕生日
     * @type {string}
     * @memberof Chara
     */
    birthday: string | null;
    /**
     * 章
     * @type {number}
     * @memberof Chara
     */
    charaChapterNo: number;
    /**
     * 表示順
     * @type {number}
     * @memberof Chara
     */
    sortOrder: number;
    /**
     * 1:新規 0:既存
     * @type {boolean}
     * @memberof Chara
     */
    newDispFlg: boolean;
    /**
     * 開始日時
     * @type {string}
     * @memberof Chara
     */
    startedAt: string | null;
    /**
     * 本編配信日時
     * @type {string}
     * @memberof Chara
     */
    storyStartedAt: string | null;
    /**
     * キャラサイズ s:小 m:中 l:大 sp:特殊
     * @type {string}
     * @memberof Chara
     */
    size: string;
    /**
     * 声優
     * @type {string}
     * @memberof Chara
     */
    voiceActor: string;
    /**
     * キャラグループコード
     * @type {string}
     * @memberof Chara
     */
    charaGroupCd: string;
    /**
     * 
     * @type {CharaSelect}
     * @memberof Chara
     */
    select: CharaSelect;
}

/**
 * Check if a given object implements the Chara interface.
 */
export function instanceOfChara(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "charaName" in value;
    isInstance = isInstance && "charaFullname" in value;
    isInstance = isInstance && "attribute" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "charaChapterNo" in value;
    isInstance = isInstance && "sortOrder" in value;
    isInstance = isInstance && "newDispFlg" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "storyStartedAt" in value;
    isInstance = isInstance && "size" in value;
    isInstance = isInstance && "voiceActor" in value;
    isInstance = isInstance && "charaGroupCd" in value;
    isInstance = isInstance && "select" in value;

    return isInstance;
}

export function CharaFromJSON(json: any): Chara {
    return CharaFromJSONTyped(json, false);
}

export function CharaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Chara {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'charaName': json['chara_name'],
        'charaFullname': json['chara_fullname'],
        'attribute': json['attribute'],
        'type': json['type'],
        'birthday': json['birthday'],
        'charaChapterNo': json['chara_chapter_no'],
        'sortOrder': json['sort_order'],
        'newDispFlg': json['new_disp_flg'],
        'startedAt': json['started_at'],
        'storyStartedAt': json['story_started_at'],
        'size': json['size'],
        'voiceActor': json['voice_actor'],
        'charaGroupCd': json['chara_group_cd'],
        'select': CharaSelectFromJSON(json['select']),
    };
}

export function CharaToJSON(value?: Chara | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'chara_name': value.charaName,
        'chara_fullname': value.charaFullname,
        'attribute': value.attribute,
        'type': value.type,
        'birthday': value.birthday,
        'chara_chapter_no': value.charaChapterNo,
        'sort_order': value.sortOrder,
        'new_disp_flg': value.newDispFlg,
        'started_at': value.startedAt,
        'story_started_at': value.storyStartedAt,
        'size': value.size,
        'voice_actor': value.voiceActor,
        'chara_group_cd': value.charaGroupCd,
        'select': CharaSelectToJSON(value.select),
    };
}

