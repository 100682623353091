import { useNuxtApp } from '#app';
import { PlayerPrefsName } from '~/libs/nativeBridge';
import { isMobileApp } from '~/libs/platform';

/**
 * ゲームトークンを保持しておく
 */
export const useGameToken = () => {
  return useState<string>('game_token');
};

/**
 * ゲームトークンを端末に更新する
 */
export const useGameTokenSync = async () => {
  if (!isMobileApp()) {
    return;
  }
  const { $nativeBridge } = useNuxtApp();
  await $nativeBridge.send('setPlayerPrefs', {
    key: PlayerPrefsName.gameToken,
    value: useGameToken().value,
  });
};
