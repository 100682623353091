/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDeckRental } from './UserDeckRental';
import {
    UserDeckRentalFromJSON,
    UserDeckRentalFromJSONTyped,
    UserDeckRentalToJSON,
} from './UserDeckRental';
import type { UserDeckRentalListResponseGuestList } from './UserDeckRentalListResponseGuestList';
import {
    UserDeckRentalListResponseGuestListFromJSON,
    UserDeckRentalListResponseGuestListFromJSONTyped,
    UserDeckRentalListResponseGuestListToJSON,
} from './UserDeckRentalListResponseGuestList';

/**
 * 
 * @export
 * @interface UserDeckRentalListResponseData
 */
export interface UserDeckRentalListResponseData {
    /**
     * ゲストリスト再リクエストを送るまでの秒数。
     * @type {number}
     * @memberof UserDeckRentalListResponseData
     */
    userCacheExpiredSeconds: number;
    /**
     * 
     * @type {UserDeckRentalListResponseGuestList}
     * @memberof UserDeckRentalListResponseData
     */
    guestList: UserDeckRentalListResponseGuestList;
    /**
     * 
     * @type {Array<UserDeckRental>}
     * @memberof UserDeckRentalListResponseData
     */
    followList: Array<UserDeckRental>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserDeckRentalListResponseData
     */
    rentaledList: Array<string>;
}

/**
 * Check if a given object implements the UserDeckRentalListResponseData interface.
 */
export function instanceOfUserDeckRentalListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userCacheExpiredSeconds" in value;
    isInstance = isInstance && "guestList" in value;
    isInstance = isInstance && "followList" in value;
    isInstance = isInstance && "rentaledList" in value;

    return isInstance;
}

export function UserDeckRentalListResponseDataFromJSON(json: any): UserDeckRentalListResponseData {
    return UserDeckRentalListResponseDataFromJSONTyped(json, false);
}

export function UserDeckRentalListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeckRentalListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userCacheExpiredSeconds': json['user_cache_expired_seconds'],
        'guestList': UserDeckRentalListResponseGuestListFromJSON(json['guest_list']),
        'followList': ((json['follow_list'] as Array<any>).map(UserDeckRentalFromJSON)),
        'rentaledList': json['rentaled_list'],
    };
}

export function UserDeckRentalListResponseDataToJSON(value?: UserDeckRentalListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_cache_expired_seconds': value.userCacheExpiredSeconds,
        'guest_list': UserDeckRentalListResponseGuestListToJSON(value.guestList),
        'follow_list': ((value.followList as Array<any>).map(UserDeckRentalToJSON)),
        'rentaled_list': value.rentaledList,
    };
}

