/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MetaCommon
 */
export interface MetaCommon {
    /**
     * ラボ端時刻
     * クライアントではここの時間を使って動いているのでクライアント側での時間での出し分けなどを行うことができる。
     * ラボ端って何！？ってことに関しては、一旦ドキュメントは無いので不明な人は聞いてください。
     * @type {string}
     * @memberof MetaCommon
     */
    time: string;
    /**
     * スタミナ全回復予定時間(秒)
     * スタミナが全回復していない場合に、スタミナが全回復する予想時間(あと、何秒後に)の情報を返す
     * クライアントではここの時間を使ってOSのローカルPUSHのスケジューリングを行う。
     * 
     * ### 返却値のパターン
     * 0:  スタミナが既に全回復している
     * -1: スタミナ全回復時間が更新されていない。(スタミナ増減が発生するAPIを実行しなかった場合は、-1を返す). この場合はクライアント側は何も振る舞う必要はなし。
     * 1以上の整数値: スタミナ全回復予定時間
     * @type {number}
     * @memberof MetaCommon
     */
    staminaMaxSchedule: number;
    /**
     * チュートリアルか否か(1: チュートリアル中, 0: チュートリアル中ではない(突破済みユーザー))
     * @type {number}
     * @memberof MetaCommon
     */
    inTutorial: number;
    /**
     * チュートリアルコード
     * @type {string}
     * @memberof MetaCommon
     */
    tutorialCd?: string | null;
    /**
     * 現在選択中のキャラCD
     * @type {string}
     * @memberof MetaCommon
     */
    charaCd: string;
}

/**
 * Check if a given object implements the MetaCommon interface.
 */
export function instanceOfMetaCommon(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "time" in value;
    isInstance = isInstance && "staminaMaxSchedule" in value;
    isInstance = isInstance && "inTutorial" in value;
    isInstance = isInstance && "charaCd" in value;

    return isInstance;
}

export function MetaCommonFromJSON(json: any): MetaCommon {
    return MetaCommonFromJSONTyped(json, false);
}

export function MetaCommonFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetaCommon {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'time': json['time'],
        'staminaMaxSchedule': json['stamina_max_schedule'],
        'inTutorial': json['in_tutorial'],
        'tutorialCd': !exists(json, 'tutorial_cd') ? undefined : json['tutorial_cd'],
        'charaCd': json['chara_cd'],
    };
}

export function MetaCommonToJSON(value?: MetaCommon | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'time': value.time,
        'stamina_max_schedule': value.staminaMaxSchedule,
        'in_tutorial': value.inTutorial,
        'tutorial_cd': value.tutorialCd,
        'chara_cd': value.charaCd,
    };
}

