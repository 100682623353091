/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConvertGoldCard } from './ConvertGoldCard';
import {
    ConvertGoldCardFromJSON,
    ConvertGoldCardFromJSONTyped,
    ConvertGoldCardToJSON,
} from './ConvertGoldCard';
import type { SynthesisCard } from './SynthesisCard';
import {
    SynthesisCardFromJSON,
    SynthesisCardFromJSONTyped,
    SynthesisCardToJSON,
} from './SynthesisCard';
import type { UnlockVoice } from './UnlockVoice';
import {
    UnlockVoiceFromJSON,
    UnlockVoiceFromJSONTyped,
    UnlockVoiceToJSON,
} from './UnlockVoice';
import type { UserPresentDeleteResponseDataReceiveCount } from './UserPresentDeleteResponseDataReceiveCount';
import {
    UserPresentDeleteResponseDataReceiveCountFromJSON,
    UserPresentDeleteResponseDataReceiveCountFromJSONTyped,
    UserPresentDeleteResponseDataReceiveCountToJSON,
} from './UserPresentDeleteResponseDataReceiveCount';

/**
 * 
 * @export
 * @interface UserPresentReceiveResponseData
 */
export interface UserPresentReceiveResponseData {
    /**
     * 受取処理有無
     * 
     * * `all` - 受取完了
     * * `part` - 部分的受取完了
     * * `error` - 受取失敗
     * @type {string}
     * @memberof UserPresentReceiveResponseData
     */
    status: UserPresentReceiveResponseDataStatusEnum;
    /**
     * エラーコード
     * @type {Array<number>}
     * @memberof UserPresentReceiveResponseData
     */
    errorCds: Array<number>;
    /**
     * 
     * @type {UserPresentDeleteResponseDataReceiveCount}
     * @memberof UserPresentReceiveResponseData
     */
    receiveCount: UserPresentDeleteResponseDataReceiveCount;
    /**
     * 受け取ったプレゼントの総件数
     * @type {number}
     * @memberof UserPresentReceiveResponseData
     */
    presentReceivedCount: number;
    /**
     * プレゼント残り件数
     * @type {number}
     * @memberof UserPresentReceiveResponseData
     */
    presentCount: number;
    /**
     * プレゼント受け取り最大件数
     * @type {number}
     * @memberof UserPresentReceiveResponseData
     */
    receiveMaxCount: number;
    /**
     * 合成が発生した場合のみキー追加 合成したカードの一覧
     * @type {Array<SynthesisCard>}
     * @memberof UserPresentReceiveResponseData
     */
    synthesisCardList: Array<SynthesisCard>;
    /**
     * コイン変換が発生した場合のみキー追加 コインへ変換したカードの一覧
     * @type {Array<ConvertGoldCard>}
     * @memberof UserPresentReceiveResponseData
     */
    convertGoldCardList: Array<ConvertGoldCard>;
    /**
     * ボイス追加のリスト
     * @type {Array<UnlockVoice>}
     * @memberof UserPresentReceiveResponseData
     */
    unlockVoiceList: Array<UnlockVoice>;
}


/**
 * @export
 */
export const UserPresentReceiveResponseDataStatusEnum = {
    All: 'all',
    Part: 'part',
    Error: 'error'
} as const;
export type UserPresentReceiveResponseDataStatusEnum = typeof UserPresentReceiveResponseDataStatusEnum[keyof typeof UserPresentReceiveResponseDataStatusEnum];


/**
 * Check if a given object implements the UserPresentReceiveResponseData interface.
 */
export function instanceOfUserPresentReceiveResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "errorCds" in value;
    isInstance = isInstance && "receiveCount" in value;
    isInstance = isInstance && "presentReceivedCount" in value;
    isInstance = isInstance && "presentCount" in value;
    isInstance = isInstance && "receiveMaxCount" in value;
    isInstance = isInstance && "synthesisCardList" in value;
    isInstance = isInstance && "convertGoldCardList" in value;
    isInstance = isInstance && "unlockVoiceList" in value;

    return isInstance;
}

export function UserPresentReceiveResponseDataFromJSON(json: any): UserPresentReceiveResponseData {
    return UserPresentReceiveResponseDataFromJSONTyped(json, false);
}

export function UserPresentReceiveResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresentReceiveResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': json['status'],
        'errorCds': json['error_cds'],
        'receiveCount': UserPresentDeleteResponseDataReceiveCountFromJSON(json['receive_count']),
        'presentReceivedCount': json['present_received_count'],
        'presentCount': json['present_count'],
        'receiveMaxCount': json['receive_max_count'],
        'synthesisCardList': ((json['synthesis_card_list'] as Array<any>).map(SynthesisCardFromJSON)),
        'convertGoldCardList': ((json['convert_gold_card_list'] as Array<any>).map(ConvertGoldCardFromJSON)),
        'unlockVoiceList': ((json['unlock_voice_list'] as Array<any>).map(UnlockVoiceFromJSON)),
    };
}

export function UserPresentReceiveResponseDataToJSON(value?: UserPresentReceiveResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
        'error_cds': value.errorCds,
        'receive_count': UserPresentDeleteResponseDataReceiveCountToJSON(value.receiveCount),
        'present_received_count': value.presentReceivedCount,
        'present_count': value.presentCount,
        'receive_max_count': value.receiveMaxCount,
        'synthesis_card_list': ((value.synthesisCardList as Array<any>).map(SynthesisCardToJSON)),
        'convert_gold_card_list': ((value.convertGoldCardList as Array<any>).map(ConvertGoldCardToJSON)),
        'unlock_voice_list': ((value.unlockVoiceList as Array<any>).map(UnlockVoiceToJSON)),
    };
}

