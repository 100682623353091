/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConvertGoldCard } from './ConvertGoldCard';
import {
    ConvertGoldCardFromJSON,
    ConvertGoldCardFromJSONTyped,
    ConvertGoldCardToJSON,
} from './ConvertGoldCard';
import type { SynthesisCard } from './SynthesisCard';
import {
    SynthesisCardFromJSON,
    SynthesisCardFromJSONTyped,
    SynthesisCardToJSON,
} from './SynthesisCard';
import type { UnlockVoice } from './UnlockVoice';
import {
    UnlockVoiceFromJSON,
    UnlockVoiceFromJSONTyped,
    UnlockVoiceToJSON,
} from './UnlockVoice';
import type { UserGachaPresentList } from './UserGachaPresentList';
import {
    UserGachaPresentListFromJSON,
    UserGachaPresentListFromJSONTyped,
    UserGachaPresentListToJSON,
} from './UserGachaPresentList';

/**
 * 
 * @export
 * @interface UserGachaListResponseDataHoldGachaResult
 */
export interface UserGachaListResponseDataHoldGachaResult {
    /**
     * ガチャコード
     * @type {string}
     * @memberof UserGachaListResponseDataHoldGachaResult
     */
    gachaCd?: string;
    /**
     * ガチャ残り実行回数
     * @type {number}
     * @memberof UserGachaListResponseDataHoldGachaResult
     */
    drawRemainingCount?: number;
    /**
     * ガチャ期限切れフラグ
     * @type {boolean}
     * @memberof UserGachaListResponseDataHoldGachaResult
     */
    periodFlg?: boolean;
    /**
     * 
     * @type {Array<UserGachaPresentList>}
     * @memberof UserGachaListResponseDataHoldGachaResult
     */
    presentList?: Array<UserGachaPresentList>;
    /**
     * 
     * @type {Array<SynthesisCard>}
     * @memberof UserGachaListResponseDataHoldGachaResult
     */
    synthesisCardList?: Array<SynthesisCard>;
    /**
     * 
     * @type {Array<UnlockVoice>}
     * @memberof UserGachaListResponseDataHoldGachaResult
     */
    unlockVoiceList?: Array<UnlockVoice>;
    /**
     * 
     * @type {Array<ConvertGoldCard>}
     * @memberof UserGachaListResponseDataHoldGachaResult
     */
    convertGoldCardList?: Array<ConvertGoldCard>;
}

/**
 * Check if a given object implements the UserGachaListResponseDataHoldGachaResult interface.
 */
export function instanceOfUserGachaListResponseDataHoldGachaResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserGachaListResponseDataHoldGachaResultFromJSON(json: any): UserGachaListResponseDataHoldGachaResult {
    return UserGachaListResponseDataHoldGachaResultFromJSONTyped(json, false);
}

export function UserGachaListResponseDataHoldGachaResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaListResponseDataHoldGachaResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gachaCd': !exists(json, 'gacha_cd') ? undefined : json['gacha_cd'],
        'drawRemainingCount': !exists(json, 'draw_remaining_count') ? undefined : json['draw_remaining_count'],
        'periodFlg': !exists(json, 'period_flg') ? undefined : json['period_flg'],
        'presentList': !exists(json, 'present_list') ? undefined : ((json['present_list'] as Array<any>).map(UserGachaPresentListFromJSON)),
        'synthesisCardList': !exists(json, 'synthesis_card_list') ? undefined : ((json['synthesis_card_list'] as Array<any>).map(SynthesisCardFromJSON)),
        'unlockVoiceList': !exists(json, 'unlock_voice_list') ? undefined : ((json['unlock_voice_list'] as Array<any>).map(UnlockVoiceFromJSON)),
        'convertGoldCardList': !exists(json, 'convert_gold_card_list') ? undefined : ((json['convert_gold_card_list'] as Array<any>).map(ConvertGoldCardFromJSON)),
    };
}

export function UserGachaListResponseDataHoldGachaResultToJSON(value?: UserGachaListResponseDataHoldGachaResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gacha_cd': value.gachaCd,
        'draw_remaining_count': value.drawRemainingCount,
        'period_flg': value.periodFlg,
        'present_list': value.presentList === undefined ? undefined : ((value.presentList as Array<any>).map(UserGachaPresentListToJSON)),
        'synthesis_card_list': value.synthesisCardList === undefined ? undefined : ((value.synthesisCardList as Array<any>).map(SynthesisCardToJSON)),
        'unlock_voice_list': value.unlockVoiceList === undefined ? undefined : ((value.unlockVoiceList as Array<any>).map(UnlockVoiceToJSON)),
        'convert_gold_card_list': value.convertGoldCardList === undefined ? undefined : ((value.convertGoldCardList as Array<any>).map(ConvertGoldCardToJSON)),
    };
}

