/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnlockVoice
 */
export interface UnlockVoice {
    /**
     * カードコード
     * @type {string}
     * @memberof UnlockVoice
     */
    cardCd: string;
}

/**
 * Check if a given object implements the UnlockVoice interface.
 */
export function instanceOfUnlockVoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardCd" in value;

    return isInstance;
}

export function UnlockVoiceFromJSON(json: any): UnlockVoice {
    return UnlockVoiceFromJSONTyped(json, false);
}

export function UnlockVoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnlockVoice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardCd': json['card_cd'],
    };
}

export function UnlockVoiceToJSON(value?: UnlockVoice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_cd': value.cardCd,
    };
}

