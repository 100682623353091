import { Ref } from 'vue';
import { formatDate, toDate } from '~/libs/date';

class Labotan {
  protected time: Ref<string|null>;
  constructor (time: Ref<string|null>) {
    this.time = time;
  }

  public set (time: string|null) {
    // useLog('useLabotan').debug(`set labotan ${time}`);
    this.time.value = time;
  }

  /**
   * ラボ端の取得
  */
  get date () {
    return formatDate(this.dateTime);
  }

  /**
   * ラボ端の取得
   */
  get dateTime () {
    return toDate(this.time.value ?? new Date());
  }
}

/**
 * API共通パラメータよりラボ端末時間を保存・取得する
 */
export const useLabotan = () => {
  const time = useState<string|null>('labotan', () => null);
  return new Labotan(time);
};
