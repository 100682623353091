/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeckRecommendRequest
 */
export interface DeckRecommendRequest {
    /**
     * フィルタリング指定するキャラコードの配列
     * @type {Array<string>}
     * @memberof DeckRecommendRequest
     */
    charaList: Array<string>;
    /**
     * フィルタリング指定するイベント種別の配列
     * @type {Array<string>}
     * @memberof DeckRecommendRequest
     */
    filterList: Array<DeckRecommendRequestFilterListEnum>;
}


/**
 * @export
 */
export const DeckRecommendRequestFilterListEnum = {
    Scenario: 'scenario',
    Collect: 'collect',
    Commu: 'commu'
} as const;
export type DeckRecommendRequestFilterListEnum = typeof DeckRecommendRequestFilterListEnum[keyof typeof DeckRecommendRequestFilterListEnum];


/**
 * Check if a given object implements the DeckRecommendRequest interface.
 */
export function instanceOfDeckRecommendRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaList" in value;
    isInstance = isInstance && "filterList" in value;

    return isInstance;
}

export function DeckRecommendRequestFromJSON(json: any): DeckRecommendRequest {
    return DeckRecommendRequestFromJSONTyped(json, false);
}

export function DeckRecommendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeckRecommendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaList': json['chara_list'],
        'filterList': json['filter_list'],
    };
}

export function DeckRecommendRequestToJSON(value?: DeckRecommendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_list': value.charaList,
        'filter_list': value.filterList,
    };
}

