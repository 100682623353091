/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';
import type { UserShopCombinedsale } from './UserShopCombinedsale';
import {
    UserShopCombinedsaleFromJSON,
    UserShopCombinedsaleFromJSONTyped,
    UserShopCombinedsaleToJSON,
} from './UserShopCombinedsale';

/**
 * 
 * @export
 * @interface ShopCombinedsaleListResponse
 */
export interface ShopCombinedsaleListResponse {
    /**
     * 
     * @type {Array<UserShopCombinedsale>}
     * @memberof ShopCombinedsaleListResponse
     */
    data: Array<UserShopCombinedsale>;
    /**
     * 
     * @type {MetaCommon}
     * @memberof ShopCombinedsaleListResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the ShopCombinedsaleListResponse interface.
 */
export function instanceOfShopCombinedsaleListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function ShopCombinedsaleListResponseFromJSON(json: any): ShopCombinedsaleListResponse {
    return ShopCombinedsaleListResponseFromJSONTyped(json, false);
}

export function ShopCombinedsaleListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopCombinedsaleListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserShopCombinedsaleFromJSON)),
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function ShopCombinedsaleListResponseToJSON(value?: ShopCombinedsaleListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserShopCombinedsaleToJSON)),
        'meta': MetaCommonToJSON(value.meta),
    };
}

