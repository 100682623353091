/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopProductBuyRequest
 */
export interface ShopProductBuyRequest {
    /**
     * プロダクトID
     * @type {string}
     * @memberof ShopProductBuyRequest
     */
    productId: string;
    /**
     * レシート情報
     * @type {string}
     * @memberof ShopProductBuyRequest
     */
    receipt: string;
    /**
     * 購入成功可否(失敗: 0,成功: 1,コンビニ決済待ち: 2)
     * @type {number}
     * @memberof ShopProductBuyRequest
     */
    result: number;
    /**
     * 課金情報コード
     * @type {string}
     * @memberof ShopProductBuyRequest
     */
    billingProductCd: string;
    /**
     * リカバリか否か
     * @type {boolean}
     * @memberof ShopProductBuyRequest
     */
    isRecovery: boolean;
}

/**
 * Check if a given object implements the ShopProductBuyRequest interface.
 */
export function instanceOfShopProductBuyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "receipt" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "billingProductCd" in value;
    isInstance = isInstance && "isRecovery" in value;

    return isInstance;
}

export function ShopProductBuyRequestFromJSON(json: any): ShopProductBuyRequest {
    return ShopProductBuyRequestFromJSONTyped(json, false);
}

export function ShopProductBuyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopProductBuyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['product_id'],
        'receipt': json['receipt'],
        'result': json['result'],
        'billingProductCd': json['billing_product_cd'],
        'isRecovery': json['is_recovery'],
    };
}

export function ShopProductBuyRequestToJSON(value?: ShopProductBuyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'product_id': value.productId,
        'receipt': value.receipt,
        'result': value.result,
        'billing_product_cd': value.billingProductCd,
        'is_recovery': value.isRecovery,
    };
}

