export default defineAppConfig({
  adjust: {
    tracking: {
      // ①ゲーム起動 ※ログインという意味
      launch: 'u9c9iz',
      // ②課金成功
      sales: 'iwl273',
      // ③チュートリアル完了
      tutorial: 'hezbeq',
      // ⑥14話読了
      story_14_5: 'q0cxen',
      // ⑦本編ストーリー読了時
      story_25_5: 'x1jm97',
      // シナリオイベント参加
      story_event: '33lotg',
    },
  },
});
