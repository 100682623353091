/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventCharaChangeResponseData } from './UserEventCharaChangeResponseData';
import {
    UserEventCharaChangeResponseDataFromJSON,
    UserEventCharaChangeResponseDataFromJSONTyped,
    UserEventCharaChangeResponseDataToJSON,
} from './UserEventCharaChangeResponseData';

/**
 * 
 * @export
 * @interface UserEventCharaChangeResponse
 */
export interface UserEventCharaChangeResponse {
    /**
     * 
     * @type {UserEventCharaChangeResponseData}
     * @memberof UserEventCharaChangeResponse
     */
    data: UserEventCharaChangeResponseData;
}

/**
 * Check if a given object implements the UserEventCharaChangeResponse interface.
 */
export function instanceOfUserEventCharaChangeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserEventCharaChangeResponseFromJSON(json: any): UserEventCharaChangeResponse {
    return UserEventCharaChangeResponseFromJSONTyped(json, false);
}

export function UserEventCharaChangeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventCharaChangeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserEventCharaChangeResponseDataFromJSON(json['data']),
    };
}

export function UserEventCharaChangeResponseToJSON(value?: UserEventCharaChangeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserEventCharaChangeResponseDataToJSON(value.data),
    };
}

