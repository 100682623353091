/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventRanking } from './UserEventRanking';
import {
    UserEventRankingFromJSON,
    UserEventRankingFromJSONTyped,
    UserEventRankingToJSON,
} from './UserEventRanking';

/**
 * 
 * @export
 * @interface UserEventRankingListResponseData
 */
export interface UserEventRankingListResponseData {
    /**
     * 集計日時
     * @type {string}
     * @memberof UserEventRankingListResponseData
     */
    aggrigateAt: string;
    /**
     * 
     * @type {Array<UserEventRanking>}
     * @memberof UserEventRankingListResponseData
     */
    rankingList: Array<UserEventRanking>;
    /**
     * 
     * @type {Array<UserEventRanking>}
     * @memberof UserEventRankingListResponseData
     */
    borderRankingList: Array<UserEventRanking>;
}

/**
 * Check if a given object implements the UserEventRankingListResponseData interface.
 */
export function instanceOfUserEventRankingListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "aggrigateAt" in value;
    isInstance = isInstance && "rankingList" in value;
    isInstance = isInstance && "borderRankingList" in value;

    return isInstance;
}

export function UserEventRankingListResponseDataFromJSON(json: any): UserEventRankingListResponseData {
    return UserEventRankingListResponseDataFromJSONTyped(json, false);
}

export function UserEventRankingListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventRankingListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'aggrigateAt': json['aggrigate_at'],
        'rankingList': ((json['ranking_list'] as Array<any>).map(UserEventRankingFromJSON)),
        'borderRankingList': ((json['border_ranking_list'] as Array<any>).map(UserEventRankingFromJSON)),
    };
}

export function UserEventRankingListResponseDataToJSON(value?: UserEventRankingListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'aggrigate_at': value.aggrigateAt,
        'ranking_list': ((value.rankingList as Array<any>).map(UserEventRankingToJSON)),
        'border_ranking_list': ((value.borderRankingList as Array<any>).map(UserEventRankingToJSON)),
    };
}

