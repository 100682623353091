/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChapterList } from './ChapterList';
import {
    ChapterListFromJSON,
    ChapterListFromJSONTyped,
    ChapterListToJSON,
} from './ChapterList';

/**
 * 
 * @export
 * @interface EventStory
 */
export interface EventStory {
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof EventStory
     */
    eventCharaCd: string;
    /**
     * イベントルートコード
     * @type {string}
     * @memberof EventStory
     */
    eventRouteCd: string;
    /**
     * チャプターリスト
     * @type {Array<ChapterList>}
     * @memberof EventStory
     */
    chapterList: Array<ChapterList>;
}

/**
 * Check if a given object implements the EventStory interface.
 */
export function instanceOfEventStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "eventRouteCd" in value;
    isInstance = isInstance && "chapterList" in value;

    return isInstance;
}

export function EventStoryFromJSON(json: any): EventStory {
    return EventStoryFromJSONTyped(json, false);
}

export function EventStoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventCharaCd': json['event_chara_cd'],
        'eventRouteCd': json['event_route_cd'],
        'chapterList': ((json['chapter_list'] as Array<any>).map(ChapterListFromJSON)),
    };
}

export function EventStoryToJSON(value?: EventStory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_chara_cd': value.eventCharaCd,
        'event_route_cd': value.eventRouteCd,
        'chapter_list': ((value.chapterList as Array<any>).map(ChapterListToJSON)),
    };
}

