<template>
  <Teleport to="body">
    <Transition name="fade">
      <ThePage v-if="isActive" class="loading" :class="classNames" @click="onClickWindow">
        <div class="full-window loading-window safe-area-all">
          <div class="w-full h-full flex relative">
            <div class="w-full flex items-center justify-center mb-[8rem]">
              <div v-if="!blocked" class="flex justify-center items-center">
                <div class="flex justify-center items-center loading-text pr-6">
                  <img src="~assets/images/loading/N.png" width="27" class="loading-img loading-img-1">
                  <img src="~assets/images/loading/O.png" width="27" class="loading-img loading-img-2">
                  <img src="~assets/images/loading/W.png" width="27" class="loading-img loading-img-3">
                  <img src="~assets/images/loading/L.png" width="27" class="loading-img loading-img-4">
                  <img src="~assets/images/loading/O.png" width="27" class="loading-img loading-img-5">
                  <img src="~assets/images/loading/a.png" width="27" class="loading-img loading-img-6">
                  <img src="~assets/images/loading/d.png" width="27" class="loading-img loading-img-7">
                  <img src="~assets/images/loading/i.png" width="27" class="loading-img loading-img-8">
                  <img src="~assets/images/loading/n1.png" width="27" class="loading-img loading-img-9">
                  <img src="~assets/images/loading/g.png" width="27" class="loading-img loading-img-10">
                  <img src="~assets/images/loading/dot.png" width="27" class="loading-img loading-img-11">
                  <img src="~assets/images/loading/dot.png" width="27" class="loading-img loading-img-11">
                  <img src="~assets/images/loading/dot.png" width="27" class="loading-img loading-img-11">
                  <div v-for="(_, i) in [...Array(particleNum)]" :key="i" class="particle" :class="[`particle-${i}`]" />
                </div>
              </div>
              <!--        <div v-if="total.isProgress" class="loading-progress">-->
              <!--          <div class="text-center">-->
              <!--            {{ total.progressNum }} / {{ total.progressTotal }}-->
              <!--          </div>-->
              <!--          <div class="w-full bg-gray-200 rounded-full h-1.5 mb-4 dark:bg-gray-700">-->
              <!--            <div class="bg-blue-600 h-1.5 rounded-full dark:bg-blue-500" :style="progressStyle" />-->
              <!--          </div>-->
              <!--        </div>-->
              <img src="~assets/images/loading/connecting_icon.png" width="31" class="loading-spin">
            </div>
          </div>
        </div>
      </ThePage>
    </Transition>
  </Teleport>
</template>

<script lang="ts" setup>
import { Ref } from 'vue';
import { LoadingData } from '~/composables/useLoading';

// const progress = ref(0);
// const progressStyle = computed(() => {
//   return {
//     width: progress.value + '%',
//   };
// });
const { total } = useLoading();
const isActive = ref<boolean>(total.value.isActive);
const blocked = ref<boolean>(false);
watch(() => total, (now: Ref<LoadingData>, _: Ref<LoadingData>) => {
  if (!isActive.value && now.value.isActive) {
    blocked.value = now.value.blocked;
  }
  isActive.value = now.value.isActive;
  // const v = now.value;
  // progress.value = v.progressTotal > 0 ? ((v.progressNum / v.progressTotal) * 100) : 0;
}, { deep: true });

const particleNum = 11;

const emits = defineEmits<{(e: 'clickWindow'): void;}>();
const onClickWindow = () => {
  emits('clickWindow');
};
/**
 * クラス名を取得
 */
const classNames = computed(() => {
  const names: string[] = [];
  if (blocked.value) {
    names.push('loading-style-simple');
  }
  return names;
});
</script>

<style lang="scss" scoped>
@use "sass:math";
@use "assets/css/base" as base;
@use "assets/css/mixin" as mixin;

$animation-duration: 1.2s;

.loading {
  position: absolute;
  left: 0;
  top: 0;
  background: url("~/assets/images/common/common_bg.jpg") base.$bg-color center no-repeat;
  background-size: cover;

  @include base.zIndexFromLayer("overlay", 0);

  &.loading-style-simple {
    background: none;
  }
}

/**
 * ローディング文字画像
 */
.loading-img {
  margin-right: -9px;
}

.loading-img-3 {
  margin-right: 3px;
}

.loading-img-4 {
  margin-right: -10px;
}

.loading-img-5 {
  margin-right: -12px;
}

.loading-img-6 {
  margin-right: -12px;
}

.loading-img-7 {
  margin-right: -14px;
}

.loading-img-8 {
  margin-right: -14px;
}

.loading-img-9 {
  margin-right: -11px;
}

.loading-img-10 {
  margin-right: -16px;
}

.loading-img-11 {
  margin-right: -20px;
}

@each $i in 1, 4, 10 {
  .loading-img-#{$i} {
    animation: loading-bound $animation-duration infinite;
    animation-delay: ($i) * 0.1s;
  }
}

@keyframes loading-bound {
  0% {
    transform: translateY(0);
  }

  25% {
    transform: translateY(-10px);
  }

  50% {
    transform: translateY(0);
  }
}

/**
 * キラキラパーティクル
 */
.particle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: url("~/assets/images/loading/particle.png") center no-repeat;
  background-size: cover;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  animation: particle $animation-duration ease-in-out infinite;
}

@mixin particle-time($p) {
  animation-delay: #{math.div($animation-duration, 100) * $p};
}
// stylelint-disable declaration-block-single-line-max-declarations
// stylelint-disable at-rule-empty-line-before
// stylelint-disable declaration-block-semicolon-space-after
// stylelint-disable block-opening-brace-space-before
// stylelint-disable declaration-colon-space-after
.particle-0  { left: 7%;  bottom:     0; transform: scale(0.4); @include particle-time(20); }
.particle-1  { left: 3%;  bottom: -27px; transform: scale(0.6); @include particle-time(10); }
.particle-2  { left: 11%; bottom: -10px; transform: scale(0.7); @include particle-time(30); }
.particle-3  { left: 16%; bottom: -18px; transform: scale(0.7); @include particle-time(35); }
.particle-4  { left: 22%; bottom:  -8px; transform: scale(0.5); @include particle-time(38); }
.particle-5  { left: 51%; bottom:  -7px; transform: scale(0.4); @include particle-time(64); }
.particle-6  { left: 37%; bottom:  -4px; transform: scale(0.7); @include particle-time(46); }
.particle-7  { left: 40%; bottom: -14px; transform: scale(0.4); @include particle-time(51); }
.particle-8  { left: 60%; bottom:  -4px; transform: scale(0.5); @include particle-time(60); }
.particle-9  { left: 72%; bottom: -12px; transform: scale(0.8); @include particle-time(70); }
.particle-10 { left: 75%; bottom:  -5px; transform: scale(0.3); @include particle-time(80); }
// stylelint-enable

@keyframes particle {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.loading-progress {
  width: 70%;
}

.loading-window {
  width: 100%;
}

.loading-text {
  position: relative;
}

.loading-spin {
  position: absolute;
  right: base.$layout-right;
  bottom: base.$layout-bottom;
  animation: spin 1s linear infinite;
}
</style>
