import { ShopItem, ShopCombinedsaleItem } from 'api';
import { isAnimatePlatform } from '~/libs/platform';

/**
 * ユーザーのお財布情報
 */
export interface Wallet {
  gold: number,
  freeGem: number,
  purchasedGem: number,
}

// キャッシュする時間
const cacheTime = 60000;
// 前回のキャッシュ時間(ms)
let cacheSetTime = 0;

interface UpdateOption {
  cache: boolean,
}
/**
 * 値の更新
 */
const update = async (options?: UpdateOption) => {
  // キャッシュ有効な場合はリクエストさせない
  if (options?.cache && (cacheSetTime + cacheTime) >= Date.now()) {
    return;
  }
  await useUser().update();
};

/**
 * 値の設定
 * @param val
 */
const set = (val: Partial<Wallet>) => {
  cacheSetTime = Date.now();
  const wallet = { ...useWallet().wallet.value, ...val };
  useWallet().wallet.value = wallet;
  return wallet;
};

/**
 * 画面間で共通で使用する基本財布情報を保存しておく
 */
export const useWallet = () => {
  const wallet = useState<Wallet>('wallet', () => {
    return {
      gold: 0,
      freeGem: 0,
      purchasedGem: 0,
    };
  });
  return {
    wallet,
    set,
    update,
  };
};

/**
 * ショップ購入に必要なアプリ内費用
 */
export interface ShopBuyCost {
  purchasedGem: number,
  freeGem: number,
  gesoCoin: number,
  gold: number,
  purchasedOnlyFlg: boolean
}

/**
 * 購入に必要な費用を取得
 * @param {ShopItem} shopItem 商品情報
 * @param {number} num 個数
 * @return ShopBuyCost
 */
export const getShopBuyCost = (shopItem: ShopItem, num: number): ShopBuyCost => {
  const data: ShopBuyCost = {
    purchasedGem: 0,
    freeGem: 0,
    gesoCoin: 0,
    gold: 0,
    purchasedOnlyFlg: false
  };
  // ウォレットからの差し引きで計算
  if (shopItem.gemPrice > 0) {
    let gem = shopItem.gemPrice * num;
    // 無償ダイヤから取得
    if (useWallet().wallet.value.freeGem > 0) {
      data.freeGem = Math.min(useWallet().wallet.value.freeGem, gem);
      gem -= data.freeGem;
    }
    if (gem > 0) {
      if (isAnimatePlatform()) {
        data.gesoCoin = gem;
      } else {
        data.purchasedGem = gem;
      }
    }
  }

  if (shopItem.goldPrice > 0) {
    data.gold = shopItem.goldPrice * num;
  }
  return data;
};

/**
 * 購入に必要な費用を取得
 * @param {ShopCombinedsaleItem} shopCombinedsaleItem セット販売商品情報
 * @param {number} num 個数
 * @return ShopBuyCost
 */
export const getShopBuyCostShopCombinedsaleItem = (shopCombinedsaleItem: ShopCombinedsaleItem, num: number): ShopBuyCost => {
  const data: ShopBuyCost = {
    purchasedGem: 0,
    freeGem: 0,
    gesoCoin: 0,
    gold: 0,
    purchasedOnlyFlg: shopCombinedsaleItem.purchasedOnlyFlg
  };

  // ウォレットからの差し引きで計算
  if (shopCombinedsaleItem.gemPrice > 0) {
    // 費用算出(単価x個数)
    let gem = shopCombinedsaleItem.gemPrice * num;

    // 有償ダイヤ限定ではない場合
    // 所持無償ダイヤが有る場合
    if ((!shopCombinedsaleItem.purchasedOnlyFlg) && (useWallet().wallet.value.freeGem > 0)) {
      // 無償ダイヤから取得
      data.freeGem = Math.min(useWallet().wallet.value.freeGem, gem);
      gem -= data.freeGem;
    }

    // 有償ダイヤ(ゲソコイン)も費用として必要な場合
    if (gem > 0) {
      // アニメイト環境の場合
      if (isAnimatePlatform()) {
        data.gesoCoin = gem;
      } else {
        // その他の場合
        data.purchasedGem = gem;
      }
    }
  }
  return data;
};

/**
 * 費用がたりているか
 * @param {ShopBuyCost} cost
 * @return boolean
 */
export const haveShopBuyCost = (cost: ShopBuyCost) => {
  if (cost.freeGem > 0) {
    if (cost.freeGem > useWallet().wallet.value.freeGem) {
      return false;
    }
  }
  if (cost.purchasedGem > 0) {
    if (cost.purchasedGem > useWallet().wallet.value.purchasedGem) {
      return false;
    }
  }
  if (cost.gesoCoin > 0) {
    if (cost.gesoCoin > useAnimateWallet().wallet.value.coin) {
      return false;
    }
  }
  if (cost.gold > 0) {
    if (cost.gold > useWallet().wallet.value.gold) {
      return false;
    }
  }
  return true;
};

/**
 * 全ウォレット情報の更新
 */
const updateAll = async (options?: UpdateOption) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const requests: Promise<any>[] = [
    useWallet().update(options)
  ];
  if (isAnimatePlatform()) {
    requests.push(useAnimateWallet().update(options));
  }
  await Promise.all(requests);
};

/**
 * 画面間で共通で使用する基本全財布情報を保存しておく
 */
export const useAllWallet = () => {
  return {
    update: updateAll,
  };
};
