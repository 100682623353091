/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * アイテムカテゴリ
 * @export
 */
export const GachaItemCategory = {
    Card: 'card',
    Avatar: 'avatar',
    Item: 'item'
} as const;
export type GachaItemCategory = typeof GachaItemCategory[keyof typeof GachaItemCategory];


export function GachaItemCategoryFromJSON(json: any): GachaItemCategory {
    return GachaItemCategoryFromJSONTyped(json, false);
}

export function GachaItemCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): GachaItemCategory {
    return json as GachaItemCategory;
}

export function GachaItemCategoryToJSON(value?: GachaItemCategory | null): any {
    return value as any;
}

