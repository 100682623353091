/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipHisAvatarList } from './EquipHisAvatarList';
import {
    EquipHisAvatarListFromJSON,
    EquipHisAvatarListFromJSONTyped,
    EquipHisAvatarListToJSON,
} from './EquipHisAvatarList';
import type { EquipMyAvatarList } from './EquipMyAvatarList';
import {
    EquipMyAvatarListFromJSON,
    EquipMyAvatarListFromJSONTyped,
    EquipMyAvatarListToJSON,
} from './EquipMyAvatarList';
import type { UserAvatarHaveAvatarList } from './UserAvatarHaveAvatarList';
import {
    UserAvatarHaveAvatarListFromJSON,
    UserAvatarHaveAvatarListFromJSONTyped,
    UserAvatarHaveAvatarListToJSON,
} from './UserAvatarHaveAvatarList';

/**
 * 
 * @export
 * @interface AvatarListResponseData
 */
export interface AvatarListResponseData {
    /**
     * 最大所有数
     * @type {number}
     * @memberof AvatarListResponseData
     */
    roomMax: number;
    /**
     * 魅力値
     * @type {number}
     * @memberof AvatarListResponseData
     */
    avatarP: number;
    /**
     * 
     * @type {Array<UserAvatarHaveAvatarList>}
     * @memberof AvatarListResponseData
     */
    haveAvatarList: Array<UserAvatarHaveAvatarList>;
    /**
     * 
     * @type {EquipMyAvatarList}
     * @memberof AvatarListResponseData
     */
    equipMyAvatarList: EquipMyAvatarList;
    /**
     * 
     * @type {Array<EquipHisAvatarList>}
     * @memberof AvatarListResponseData
     */
    equipHisAvatarList: Array<EquipHisAvatarList>;
}

/**
 * Check if a given object implements the AvatarListResponseData interface.
 */
export function instanceOfAvatarListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roomMax" in value;
    isInstance = isInstance && "avatarP" in value;
    isInstance = isInstance && "haveAvatarList" in value;
    isInstance = isInstance && "equipMyAvatarList" in value;
    isInstance = isInstance && "equipHisAvatarList" in value;

    return isInstance;
}

export function AvatarListResponseDataFromJSON(json: any): AvatarListResponseData {
    return AvatarListResponseDataFromJSONTyped(json, false);
}

export function AvatarListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvatarListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'roomMax': json['room_max'],
        'avatarP': json['avatar_p'],
        'haveAvatarList': ((json['have_avatar_list'] as Array<any>).map(UserAvatarHaveAvatarListFromJSON)),
        'equipMyAvatarList': EquipMyAvatarListFromJSON(json['equip_my_avatar_list']),
        'equipHisAvatarList': ((json['equip_his_avatar_list'] as Array<any>).map(EquipHisAvatarListFromJSON)),
    };
}

export function AvatarListResponseDataToJSON(value?: AvatarListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'room_max': value.roomMax,
        'avatar_p': value.avatarP,
        'have_avatar_list': ((value.haveAvatarList as Array<any>).map(UserAvatarHaveAvatarListToJSON)),
        'equip_my_avatar_list': EquipMyAvatarListToJSON(value.equipMyAvatarList),
        'equip_his_avatar_list': ((value.equipHisAvatarList as Array<any>).map(EquipHisAvatarListToJSON)),
    };
}

