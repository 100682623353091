/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventStatus } from './EventStatus';
import {
    EventStatusFromJSON,
    EventStatusFromJSONTyped,
    EventStatusToJSON,
} from './EventStatus';

/**
 * 
 * @export
 * @interface EventStatusResponse
 */
export interface EventStatusResponse {
    /**
     * 
     * @type {EventStatus}
     * @memberof EventStatusResponse
     */
    data: EventStatus;
}

/**
 * Check if a given object implements the EventStatusResponse interface.
 */
export function instanceOfEventStatusResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function EventStatusResponseFromJSON(json: any): EventStatusResponse {
    return EventStatusResponseFromJSONTyped(json, false);
}

export function EventStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': EventStatusFromJSON(json['data']),
    };
}

export function EventStatusResponseToJSON(value?: EventStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': EventStatusToJSON(value.data),
    };
}

