/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserCharaSave } from './UserCharaSave';
import {
    UserCharaSaveFromJSON,
    UserCharaSaveFromJSONTyped,
    UserCharaSaveToJSON,
} from './UserCharaSave';

/**
 * 
 * @export
 * @interface CharaSaveResponse
 */
export interface CharaSaveResponse {
    /**
     * 
     * @type {UserCharaSave}
     * @memberof CharaSaveResponse
     */
    data: UserCharaSave;
}

/**
 * Check if a given object implements the CharaSaveResponse interface.
 */
export function instanceOfCharaSaveResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CharaSaveResponseFromJSON(json: any): CharaSaveResponse {
    return CharaSaveResponseFromJSONTyped(json, false);
}

export function CharaSaveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CharaSaveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserCharaSaveFromJSON(json['data']),
    };
}

export function CharaSaveResponseToJSON(value?: CharaSaveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserCharaSaveToJSON(value.data),
    };
}

