/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuCharaVoice } from './CommuCharaVoice';
import {
    CommuCharaVoiceFromJSON,
    CommuCharaVoiceFromJSONTyped,
    CommuCharaVoiceToJSON,
} from './CommuCharaVoice';

/**
 * 
 * @export
 * @interface CommuCharaVoicePattern
 */
export interface CommuCharaVoicePattern {
    /**
     * ボイスリスト
     * @type {Array<CommuCharaVoice>}
     * @memberof CommuCharaVoicePattern
     */
    voiceList: Array<CommuCharaVoice>;
    /**
     * レベル下限
     * @type {number}
     * @memberof CommuCharaVoicePattern
     */
    lowerLevel: number;
    /**
     * レベル上限
     * @type {number}
     * @memberof CommuCharaVoicePattern
     */
    upperLevel: number;
}

/**
 * Check if a given object implements the CommuCharaVoicePattern interface.
 */
export function instanceOfCommuCharaVoicePattern(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "voiceList" in value;
    isInstance = isInstance && "lowerLevel" in value;
    isInstance = isInstance && "upperLevel" in value;

    return isInstance;
}

export function CommuCharaVoicePatternFromJSON(json: any): CommuCharaVoicePattern {
    return CommuCharaVoicePatternFromJSONTyped(json, false);
}

export function CommuCharaVoicePatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaVoicePattern {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'voiceList': ((json['voice_list'] as Array<any>).map(CommuCharaVoiceFromJSON)),
        'lowerLevel': json['lower_level'],
        'upperLevel': json['upper_level'],
    };
}

export function CommuCharaVoicePatternToJSON(value?: CommuCharaVoicePattern | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'voice_list': ((value.voiceList as Array<any>).map(CommuCharaVoiceToJSON)),
        'lower_level': value.lowerLevel,
        'upper_level': value.upperLevel,
    };
}

