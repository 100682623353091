import { useNuxtApp } from '#app';
import { isMobileApp } from '~/libs/platform';
import { useFirebaseLogEvent } from '~/composables/useFirebaseLogEvent';

interface AdjustEventParam {
  paramName: string,
  paramValue: string,
}

interface AdjustRevenueParam {
  localizedPrice: number,
  isoCurrencyCode: string,
}

/**
 * イベントトラッキング
 * @param eventToken
 * @param params
 * @param revenueParam
 */
const sendEvent = (eventToken: string, params: AdjustEventParam[] = [], revenueParam?: AdjustRevenueParam) => {
  if (!isMobileApp()) {
    return;
  }
  const { $nativeBridge } = useNuxtApp();
  $nativeBridge.send('adjustLogEvent', {
    eventToken,
    params,
    revenueParam,
  });
};

/**
 * 起動イベント送信
 */
const sendEventLaunch = () => {
  const config = useAppConfig().adjust.tracking;
  sendEvent(config.launch);
  // firebaseにも送信
  useFirebaseLogEvent().send('launch');
};

/**
 * 課金イベント送信
 */
const sendEventSales = (revenue: number, currency: string) => {
  const config = useAppConfig().adjust.tracking;
  sendEvent(config.sales, [], {
    localizedPrice: revenue,
    isoCurrencyCode: currency,
  });
};

/**
 * チュートリアル完了イベント送信
 */
const sendEventTutorial = () => {
  const config = useAppConfig().adjust.tracking;
  sendEvent(config.tutorial);
  // firebaseにも送信
  useFirebaseLogEvent().send('tutorial_clear');
};

/**
 * 本編ストーリー14話読了イベント送信
 */
const sendEventStory14 = () => {
  const config = useAppConfig().adjust.tracking;
  sendEvent(config.story_14_5);
};

/**
 * 本編ストーリー25話読了イベント送信
 */
const sendEventStory25 = () => {
  const config = useAppConfig().adjust.tracking;
  sendEvent(config.story_25_5);
};

/**
 * シナリオイベント参加イベント送信
 */
const sendEventStoryEvent = () => {
  const config = useAppConfig().adjust.tracking;
  sendEvent(config.story_event);
};

/**
 * adjust成果の送信
 */
export const useAdjust = () => {
  return {
    sendEventLaunch,
    sendEventSales,
    sendEventTutorial,
    sendEventStory14,
    sendEventStory25,
    sendEventStoryEvent,
  };
};

/**
 * adjustのID
 */
export const useAdjustId = () => {
  return useState<string>('adjust_id', () => '');
};

/**
 * 広告ID(IDFA/AAID)
 */
export const useAdvertisingId = () => {
  return useState<string>('advertising_id', () => '');
};
