/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAuthAppGameTokenResponseData } from './UserAuthAppGameTokenResponseData';
import {
    UserAuthAppGameTokenResponseDataFromJSON,
    UserAuthAppGameTokenResponseDataFromJSONTyped,
    UserAuthAppGameTokenResponseDataToJSON,
} from './UserAuthAppGameTokenResponseData';

/**
 * 
 * @export
 * @interface UserAuthAppGameTokenResponse
 */
export interface UserAuthAppGameTokenResponse {
    /**
     * 
     * @type {UserAuthAppGameTokenResponseData}
     * @memberof UserAuthAppGameTokenResponse
     */
    data: UserAuthAppGameTokenResponseData;
}

/**
 * Check if a given object implements the UserAuthAppGameTokenResponse interface.
 */
export function instanceOfUserAuthAppGameTokenResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserAuthAppGameTokenResponseFromJSON(json: any): UserAuthAppGameTokenResponse {
    return UserAuthAppGameTokenResponseFromJSONTyped(json, false);
}

export function UserAuthAppGameTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthAppGameTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserAuthAppGameTokenResponseDataFromJSON(json['data']),
    };
}

export function UserAuthAppGameTokenResponseToJSON(value?: UserAuthAppGameTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserAuthAppGameTokenResponseDataToJSON(value.data),
    };
}

