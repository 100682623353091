<template>
  <ModalBase :show="showFlg" :close-button="props.closeButton" :z-index="props.zIndex" @close="onClose">
    <div class="py-5 px-3 text-center">
      <h3 v-if="props.title" class="mb-3 p-2 text-lg modal-title">
        {{ props.title }}
      </h3>
      <div class="modal-body text-sm" :class="[`modal-size-${props.size}`]">
        <slot />
      </div>
      <div v-if="props.isEnableOk || props.isEnableCancel" class="flex justify-center modal-buttons mt-3">
        <CommonButton v-if="props.isEnableCancel" :type="props.cancelButtonType" class="modal-btn flex flex-col items-center justify-center" :click-prevention="true" @action-click="onClickCancel">
          <div v-for="(str, index) of props.cancelText.split('\n')" :key="index">
            {{ str }}
          </div>
        </CommonButton>
        <CommonButton v-if="props.isEnableOk" :type="props.okButtonType" class="modal-btn flex flex-col items-center justify-center" :click-prevention="true" @action-click="onClickOk">
          <div v-for="(str, index) of props.okText.split('\n')" :key="index">
            {{ str }}
          </div>
        </CommonButton>
      </div>
    </div>
  </ModalBase>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  title: string,
  okText?: string,
  okButtonType?: ButtonType,
  isEnableOk?: boolean,
  cancelText?: string,
  cancelButtonType?: ButtonType,
  isEnableCancel?: boolean,
  show?: boolean,
  size?: ModalSizeType,
  closeButton?: boolean,
  // theme?: 'normal' | 'info' | 'danger',
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  okText: 'OK',
  okButtonType: 'primary',
  cancelText: 'キャンセル',
  cancelButtonType: 'secondary',
  isEnableOk: true,
  isEnableCancel: false,
  show: false,
  size: 'auto',
  closeButton: false,
  // theme: 'normal',
});
interface Emits {
  (e: 'clickOk'): void;
  (e: 'clickCancel'): void;
  (e: 'close'): void;
}
const emit = defineEmits<Emits>();
const showFlg = ref<boolean>(false);
onMounted(() => {
  showFlg.value = props.show;
});
watch(() => props.show, (current) => {
  showFlg.value = current;
});

const onClickOk = () => {
  emit('clickOk');
};
const onClickCancel = () => {
  emit('clickCancel');
};
const onClose = () => {
  emit('close');
};
// const getClass = () => {
//   return [
//     `theme-${props.theme}`,
//   ];
// };
</script>

<style lang="scss" scoped>
.modal-title {
  border-image: url("~/assets/images/common/common_popup_header.png") 50 60 fill / 25px 30px;
  color: #fffbf5;
  text-shadow: #58413d 0 1px;
}

//.theme-info {
//  .modal-title {
//    border-image: url("~/assets/images/common/common_popup_header02.png") 40 90 fill / 20px 30px;
//  }
//}
//.theme-danger {
//  .modal-title {
//    border-image: url("~/assets/images/common/common_popup_header03.png") 40 90 fill / 20px 30px;
//  }
//}

.modal-buttons {
  > * {
    margin: 0 0.8em;
  }
}

.modal-body {
  padding-left: 10px;
  padding-right: 10px;
}

// サイズ
$modalSizes: (sm: "max(12vh, 120px)", md: "max(25vh, 220px)", lg: "max(50vh, 300px)", xl: "70vh");

@each $key, $size in $modalSizes {
  .modal-size-#{$key} {
    height: #{$size};
  }
}

.modal-btn {
  min-width: 120px;
}

.flex {
  display: flex !important;
}

</style>
