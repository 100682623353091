/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAuthProviderAccessTokenResponseData
 */
export interface UserAuthProviderAccessTokenResponseData {
    /**
     * プロバイダ接続用のアクセストークン
     * @type {string}
     * @memberof UserAuthProviderAccessTokenResponseData
     */
    accessToken: string;
}

/**
 * Check if a given object implements the UserAuthProviderAccessTokenResponseData interface.
 */
export function instanceOfUserAuthProviderAccessTokenResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "accessToken" in value;

    return isInstance;
}

export function UserAuthProviderAccessTokenResponseDataFromJSON(json: any): UserAuthProviderAccessTokenResponseData {
    return UserAuthProviderAccessTokenResponseDataFromJSONTyped(json, false);
}

export function UserAuthProviderAccessTokenResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthProviderAccessTokenResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['access_token'],
    };
}

export function UserAuthProviderAccessTokenResponseDataToJSON(value?: UserAuthProviderAccessTokenResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'access_token': value.accessToken,
    };
}

