/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginstampGetPanel
 */
export interface LoginstampGetPanel {
    /**
     * パネル情報
     * @type {number}
     * @memberof LoginstampGetPanel
     */
    panelNo: number;
    /**
     * プレゼントアイテムCD
     * @type {string}
     * @memberof LoginstampGetPanel
     */
    presentItemCd: string;
    /**
     * キャラCD
     * @type {string}
     * @memberof LoginstampGetPanel
     */
    charaCd: string;
    /**
     * 立ち絵画像コード
     * @type {string}
     * @memberof LoginstampGetPanel
     */
    bodyImageCd: string;
    /**
     * 立ち絵リソースパーティション
     * @type {number}
     * @memberof LoginstampGetPanel
     */
    bodyResourcePartition: number;
    /**
     * 表情差分画像コード
     * @type {string}
     * @memberof LoginstampGetPanel
     */
    faceImageCd: string;
    /**
     * 表情差分リソースパーティション
     * @type {number}
     * @memberof LoginstampGetPanel
     */
    faceResourcePartition: number;
    /**
     * ストーリー背景画像コード
     * @type {string}
     * @memberof LoginstampGetPanel
     */
    backgroundImageCd: string;
}

/**
 * Check if a given object implements the LoginstampGetPanel interface.
 */
export function instanceOfLoginstampGetPanel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "panelNo" in value;
    isInstance = isInstance && "presentItemCd" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "bodyImageCd" in value;
    isInstance = isInstance && "bodyResourcePartition" in value;
    isInstance = isInstance && "faceImageCd" in value;
    isInstance = isInstance && "faceResourcePartition" in value;
    isInstance = isInstance && "backgroundImageCd" in value;

    return isInstance;
}

export function LoginstampGetPanelFromJSON(json: any): LoginstampGetPanel {
    return LoginstampGetPanelFromJSONTyped(json, false);
}

export function LoginstampGetPanelFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginstampGetPanel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'panelNo': json['panel_no'],
        'presentItemCd': json['present_item_cd'],
        'charaCd': json['chara_cd'],
        'bodyImageCd': json['body_image_cd'],
        'bodyResourcePartition': json['body_resource_partition'],
        'faceImageCd': json['face_image_cd'],
        'faceResourcePartition': json['face_resource_partition'],
        'backgroundImageCd': json['background_image_cd'],
    };
}

export function LoginstampGetPanelToJSON(value?: LoginstampGetPanel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'panel_no': value.panelNo,
        'present_item_cd': value.presentItemCd,
        'chara_cd': value.charaCd,
        'body_image_cd': value.bodyImageCd,
        'body_resource_partition': value.bodyResourcePartition,
        'face_image_cd': value.faceImageCd,
        'face_resource_partition': value.faceResourcePartition,
        'background_image_cd': value.backgroundImageCd,
    };
}

