/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FriendLikeSendRequest
 */
export interface FriendLikeSendRequest {
    /**
     * いいねを送信するフレンドのプレイヤーコードの配列
     * @type {Array<string>}
     * @memberof FriendLikeSendRequest
     */
    friendList: Array<string>;
    /**
     * いいねリストの種類
     * @type {string}
     * @memberof FriendLikeSendRequest
     */
    listType: string;
}

/**
 * Check if a given object implements the FriendLikeSendRequest interface.
 */
export function instanceOfFriendLikeSendRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "friendList" in value;
    isInstance = isInstance && "listType" in value;

    return isInstance;
}

export function FriendLikeSendRequestFromJSON(json: any): FriendLikeSendRequest {
    return FriendLikeSendRequestFromJSONTyped(json, false);
}

export function FriendLikeSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendLikeSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'friendList': json['friend_list'],
        'listType': json['list_type'],
    };
}

export function FriendLikeSendRequestToJSON(value?: FriendLikeSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'friend_list': value.friendList,
        'list_type': value.listType,
    };
}

