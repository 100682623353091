/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserStoryCompleteHisStoryData
 */
export interface UserStoryCompleteHisStoryData {
    /**
     * 彼目線ストーリーのチケットコード
     * @type {string}
     * @memberof UserStoryCompleteHisStoryData
     */
    hisStoryTicketCd: string | null;
    /**
     * コミュ彼目線フラグ
     * @type {boolean}
     * @memberof UserStoryCompleteHisStoryData
     */
    isCommu: boolean;
    /**
     * 必要コミュLv
     * @type {number}
     * @memberof UserStoryCompleteHisStoryData
     */
    commuLevel?: number;
}

/**
 * Check if a given object implements the UserStoryCompleteHisStoryData interface.
 */
export function instanceOfUserStoryCompleteHisStoryData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "hisStoryTicketCd" in value;
    isInstance = isInstance && "isCommu" in value;

    return isInstance;
}

export function UserStoryCompleteHisStoryDataFromJSON(json: any): UserStoryCompleteHisStoryData {
    return UserStoryCompleteHisStoryDataFromJSONTyped(json, false);
}

export function UserStoryCompleteHisStoryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStoryCompleteHisStoryData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'hisStoryTicketCd': json['his_story_ticket_cd'],
        'isCommu': json['is_commu'],
        'commuLevel': !exists(json, 'commu_level') ? undefined : json['commu_level'],
    };
}

export function UserStoryCompleteHisStoryDataToJSON(value?: UserStoryCompleteHisStoryData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'his_story_ticket_cd': value.hisStoryTicketCd,
        'is_commu': value.isCommu,
        'commu_level': value.commuLevel,
    };
}

