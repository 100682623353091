/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ミッションタイプ
 * @export
 */
export const MissionType = {
    Avatar: 'avatar',
    CardAnnounce: 'card_announce',
    Parameter: 'parameter',
    Route: 'route',
    EventChoiceRoute: 'event_choice_route',
    EventParameter: 'event_parameter'
} as const;
export type MissionType = typeof MissionType[keyof typeof MissionType];


export function MissionTypeFromJSON(json: any): MissionType {
    return MissionTypeFromJSONTyped(json, false);
}

export function MissionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionType {
    return json as MissionType;
}

export function MissionTypeToJSON(value?: MissionType | null): any {
    return value as any;
}

