/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 1チケット読むのに必要なストーリーチケットとポイント
 * @export
 * @interface StoryNeedTicket
 */
export interface StoryNeedTicket {
    /**
     * 必要チケット数
     * @type {number}
     * @memberof StoryNeedTicket
     */
    count: number;
    /**
     * 必要ポイント数
     * @type {number}
     * @memberof StoryNeedTicket
     */
    point: number;
}

/**
 * Check if a given object implements the StoryNeedTicket interface.
 */
export function instanceOfStoryNeedTicket(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "point" in value;

    return isInstance;
}

export function StoryNeedTicketFromJSON(json: any): StoryNeedTicket {
    return StoryNeedTicketFromJSONTyped(json, false);
}

export function StoryNeedTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryNeedTicket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'point': json['point'],
    };
}

export function StoryNeedTicketToJSON(value?: StoryNeedTicket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'point': value.point,
    };
}

