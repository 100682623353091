/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAuthProvider } from './UserAuthProvider';
import {
    UserAuthProviderFromJSON,
    UserAuthProviderFromJSONTyped,
    UserAuthProviderToJSON,
} from './UserAuthProvider';

/**
 * 
 * @export
 * @interface UserAuthProviderResponse
 */
export interface UserAuthProviderResponse {
    /**
     * 
     * @type {Array<UserAuthProvider>}
     * @memberof UserAuthProviderResponse
     */
    data: Array<UserAuthProvider>;
}

/**
 * Check if a given object implements the UserAuthProviderResponse interface.
 */
export function instanceOfUserAuthProviderResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserAuthProviderResponseFromJSON(json: any): UserAuthProviderResponse {
    return UserAuthProviderResponseFromJSONTyped(json, false);
}

export function UserAuthProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthProviderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserAuthProviderFromJSON)),
    };
}

export function UserAuthProviderResponseToJSON(value?: UserAuthProviderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserAuthProviderToJSON)),
    };
}

