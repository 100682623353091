/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSerialcodeSaveCard
 */
export interface UserSerialcodeSaveCard {
    /**
     * カード名
     * @type {string}
     * @memberof UserSerialcodeSaveCard
     */
    title: string;
}

/**
 * Check if a given object implements the UserSerialcodeSaveCard interface.
 */
export function instanceOfUserSerialcodeSaveCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function UserSerialcodeSaveCardFromJSON(json: any): UserSerialcodeSaveCard {
    return UserSerialcodeSaveCardFromJSONTyped(json, false);
}

export function UserSerialcodeSaveCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSerialcodeSaveCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
    };
}

export function UserSerialcodeSaveCardToJSON(value?: UserSerialcodeSaveCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
    };
}

