/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TutorialSaveRequest
 */
export interface TutorialSaveRequest {
    /**
     * チュートリアルコード
     * @type {string}
     * @memberof TutorialSaveRequest
     */
    tutorialCd: string;
}

/**
 * Check if a given object implements the TutorialSaveRequest interface.
 */
export function instanceOfTutorialSaveRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tutorialCd" in value;

    return isInstance;
}

export function TutorialSaveRequestFromJSON(json: any): TutorialSaveRequest {
    return TutorialSaveRequestFromJSONTyped(json, false);
}

export function TutorialSaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialSaveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tutorialCd': json['tutorial_cd'],
    };
}

export function TutorialSaveRequestToJSON(value?: TutorialSaveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tutorial_cd': value.tutorialCd,
    };
}

