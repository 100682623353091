export const audioConfig = {
  // 音のソース拡張子
  sourceExtensions: [
    'mp3',
  ],
  // デフォルトの拡張子
  defaultExtension: 'mp3',
  // ボリュームの全体の割合
  volumes: {
    bgm: 0.125,
    se: 0.5,
    voice: 1,
  },
};

// タイトル用ボイスコードリスト
export const titleVoiceCds: {[key: string]: string} = {
  1: '1_title_1',
  2: '2_title_1',
  3: '3_title_1',
  4: '4_title_1',
  5: '5_title_1',
  6: '6_title_1',
  7: '7_title_1',
  8: '8_title_1',
};
// デフォルトタイトル用ボイスコード
export const defaultTitleVoiceCd = '1_title_1';
