import { AvatarListSortType, ItemButtons } from '~/libs/page/avatar/type';

/**
 * avatar_category_mstの値
 */
export const AvatarCategoryCdValues = {
  /** [マイ]マイマネキン */
  MyAvatarBody: 'mav_01',
  /** [マイ]セット */
  MyAvatarSet: 'mav_02',
  /** [マイ]メイク */
  MyAvatarMaking: 'mav_03',
  /** [マイ]髪型 */
  MyAvatarHairStyle: 'mav_04',
  /** [マイ]ワンピース */
  MyAvatarDress: 'mav_06',
  /** [マイ]トップス */
  MyAvatarTops: 'mav_05',
  /** [マイ]ボトムス */
  MyAvatarBottoms: 'mav_07',
  /** [マイ]上着 */
  MyAvatarJacket: 'mav_08',
  /** [マイ]靴下・靴 */
  MyAvatarFoot: 'mav_09',
  /** [マイ]アクセサリー */
  MyAvatarAccessory: 'mav_10',
  /** [マイ]小物・エフェクト */
  MyAvatarEffect: 'mav_11',
  /** [彼]彼マネキン */
  PartnerAvatarBody: 'pav_01',
  /** [彼]セット */
  PartnerAvatarSet: 'pav_02',
  /** [彼]髪飾り */
  PartnerAvatarHairAccent: 'pav_03',
  /** [彼]トップス */
  PartnerAvatarTops: 'pav_04',
  /** [彼]ボトムス */
  PartnerAvatarBottoms: 'pav_05',
  /** [彼]上着 */
  PartnerAvatarJacket: 'pav_06',
  /** [彼]靴下・靴 */
  PartnerAvatarFoot: 'pav_07',
  /** [彼]アクセサリー */
  PartnerAvatarAccessory: 'pav_08',
  /** [彼]小物・エフェクト */
  PartnerAvatarEffect: 'pav_09',
  /** [彼]彼専用 */
  PartnerAvatarCharacterExclusive: 'pav_10',
  /** [部屋]背景 */
  RoomAvatarBackground: 'rav_01',
  /** [部屋]壁 */
  RoomAvatarWall: 'rav_02',
  /** [部屋]床 */
  RoomAvatarFloor: 'rav_03',
  /** [部屋]天井 */
  RoomAvatarCeiling: 'rav_04',
  /** [部屋]置物 */
  RoomAvatarOnTheFloor: 'rav_05',
  /** [部屋]エフェクト */
  RoomAvatarEffect: 'rav_06',
};

/**
 * アバター選択UIのボタン定義 (マイキャラ)
 */
export const MyButtons: Array<ItemButtons> = [
  { label: 'セット', code: AvatarCategoryCdValues.MyAvatarSet, icon: 'avatar_user_icon_01', handleClick: false },
  { label: 'メイク', code: AvatarCategoryCdValues.MyAvatarMaking, icon: 'avatar_user_icon_02', handleClick: false },
  { label: '髪型', code: AvatarCategoryCdValues.MyAvatarHairStyle, icon: 'avatar_user_icon_03', handleClick: false },
  { label: 'ワンピース', code: AvatarCategoryCdValues.MyAvatarDress, icon: 'avatar_user_icon_04', handleClick: false },
  { label: 'トップス', code: AvatarCategoryCdValues.MyAvatarTops, icon: 'avatar_user_icon_05', handleClick: false },
  { label: 'ボトムス', code: AvatarCategoryCdValues.MyAvatarBottoms, icon: 'avatar_user_icon_06', handleClick: false },
  { label: '上着', code: AvatarCategoryCdValues.MyAvatarJacket, icon: 'avatar_user_icon_07', handleClick: false },
  { label: '靴・靴下', code: AvatarCategoryCdValues.MyAvatarFoot, icon: 'avatar_user_icon_08', handleClick: false },
  { label: 'アクセサリー', code: AvatarCategoryCdValues.MyAvatarAccessory, icon: 'avatar_user_icon_09', handleClick: false },
  { label: '小物・エフェクト', code: AvatarCategoryCdValues.MyAvatarEffect, icon: 'avatar_user_icon_10', handleClick: false },
];

/**
 * アバター選択UIのボタン定義 (彼キャラ)
 */
export const PartnerButtons: Array<ItemButtons> = [
  { label: 'セット', code: AvatarCategoryCdValues.PartnerAvatarSet, icon: 'avatar_partner_icon_02', handleClick: false },
  { label: '髪飾り', code: AvatarCategoryCdValues.PartnerAvatarHairAccent, icon: 'avatar_partner_icon_03', handleClick: false },
  { label: 'トップス', code: AvatarCategoryCdValues.PartnerAvatarTops, icon: 'avatar_partner_icon_04', handleClick: false },
  { label: 'ボトムス', code: AvatarCategoryCdValues.PartnerAvatarBottoms, icon: 'avatar_partner_icon_05', handleClick: false },
  { label: '上着', code: AvatarCategoryCdValues.PartnerAvatarJacket, icon: 'avatar_partner_icon_06', handleClick: false },
  { label: '靴・靴下', code: AvatarCategoryCdValues.PartnerAvatarFoot, icon: 'avatar_partner_icon_07', handleClick: false },
  { label: 'アクセサリー', code: AvatarCategoryCdValues.PartnerAvatarAccessory, icon: 'avatar_partner_icon_08', handleClick: false },
  { label: '小物・エフェクト', code: AvatarCategoryCdValues.PartnerAvatarEffect, icon: 'avatar_partner_icon_09', handleClick: false },
  { label: '彼専用', code: AvatarCategoryCdValues.PartnerAvatarCharacterExclusive, icon: 'avatar_partner_icon_10', handleClick: false },
];

/**
 * アバター選択UIのボタン定義 (模様替え)
 */
export const Decorate: Array<ItemButtons> = [
  { label: '背景', code: AvatarCategoryCdValues.RoomAvatarBackground, icon: 'avatar_room_icon_01', handleClick: false },
  { label: '壁', code: AvatarCategoryCdValues.RoomAvatarWall, icon: 'avatar_room_icon_02', handleClick: false },
  { label: '床', code: AvatarCategoryCdValues.RoomAvatarFloor, icon: 'avatar_room_icon_03', handleClick: false },
  { label: '天井', code: AvatarCategoryCdValues.RoomAvatarCeiling, icon: 'avatar_room_icon_04', handleClick: false },
  { label: '置物', code: AvatarCategoryCdValues.RoomAvatarOnTheFloor, icon: 'avatar_room_icon_05', handleClick: false },
  { label: 'エフェクト', code: AvatarCategoryCdValues.RoomAvatarEffect, icon: 'avatar_room_icon_06', handleClick: false },
  { label: 'マイキャラ', code: '', icon: 'avatar_room_icon_07', handleClick: true },
  { label: '彼キャラ', code: AvatarCategoryCdValues.PartnerAvatarBody, icon: 'avatar_room_icon_08', handleClick: false },
];

/**
 * クローゼットなどでの並び替えの文字列定義
 */
export const AvatarClosetSortNames = <{ [index: number]: string }>({
  [AvatarListSortType.byIdDesc]: '取得が新しい順',
  [AvatarListSortType.byIdAsc]: '取得が古い順',
  [AvatarListSortType.byParameterDesc]: '魅力が高い順',
  [AvatarListSortType.byParameterAsc]: '魅力が低い順',
  [AvatarListSortType.byInUseDesc]: '着用中',
  [AvatarListSortType.byNotInUseDesc]: '未着用'
});

export const AvatarType = {
  MY: 'my',
  PARTNER: 'partner',
  ROOM: 'room',
};

export const AvatarConfig = {
  // アバター所持数の最大値
  maxAvailableRoomAvatar: 99999,
};

/**
 * 配置可能なアバターの上限数
 */
export const MaxInstallableAvatarCount = 50;

export const DefaultBackgroundAvatarCd = '000001_r_bg';

export const DefaultBackgroundAvatarCategoryCd = 'r_bg';
