/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ItemConsumeResponseData } from './ItemConsumeResponseData';
import {
    ItemConsumeResponseDataFromJSON,
    ItemConsumeResponseDataFromJSONTyped,
    ItemConsumeResponseDataToJSON,
} from './ItemConsumeResponseData';

/**
 * 
 * @export
 * @interface ItemConsumeResponse
 */
export interface ItemConsumeResponse {
    /**
     * 
     * @type {ItemConsumeResponseData}
     * @memberof ItemConsumeResponse
     */
    data: ItemConsumeResponseData;
}

/**
 * Check if a given object implements the ItemConsumeResponse interface.
 */
export function instanceOfItemConsumeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ItemConsumeResponseFromJSON(json: any): ItemConsumeResponse {
    return ItemConsumeResponseFromJSONTyped(json, false);
}

export function ItemConsumeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemConsumeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ItemConsumeResponseDataFromJSON(json['data']),
    };
}

export function ItemConsumeResponseToJSON(value?: ItemConsumeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ItemConsumeResponseDataToJSON(value.data),
    };
}

