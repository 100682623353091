/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventStoryRouteType } from './EventStoryRouteType';
import {
    EventStoryRouteTypeFromJSON,
    EventStoryRouteTypeFromJSONTyped,
    EventStoryRouteTypeToJSON,
} from './EventStoryRouteType';
import type { EvnetMissionList } from './EvnetMissionList';
import {
    EvnetMissionListFromJSON,
    EvnetMissionListFromJSONTyped,
    EvnetMissionListToJSON,
} from './EvnetMissionList';

/**
 * 
 * @export
 * @interface ChapterList
 */
export interface ChapterList {
    /**
     * ストーリーチャプターの順序
     * @type {number}
     * @memberof ChapterList
     */
    storyNum: number;
    /**
     * チャプターコード
     * @type {string}
     * @memberof ChapterList
     */
    chapterCdMain: string;
    /**
     * キャラコード
     * @type {string}
     * @memberof ChapterList
     */
    charaCd: string;
    /**
     * タイトル
     * @type {string}
     * @memberof ChapterList
     */
    title: string;
    /**
     * プレビュー
     * @type {string}
     * @memberof ChapterList
     */
    preview: string;
    /**
     * ミッションリスト
     * @type {Array<EvnetMissionList>}
     * @memberof ChapterList
     */
    mission?: Array<EvnetMissionList>;
    /**
     * 
     * @type {EventStoryRouteType}
     * @memberof ChapterList
     */
    routeType: EventStoryRouteType;
    /**
     * チャプターのチケット一覧
     * @type {Array<string>}
     * @memberof ChapterList
     */
    ticketList: Array<string>;
}

/**
 * Check if a given object implements the ChapterList interface.
 */
export function instanceOfChapterList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "storyNum" in value;
    isInstance = isInstance && "chapterCdMain" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "preview" in value;
    isInstance = isInstance && "routeType" in value;
    isInstance = isInstance && "ticketList" in value;

    return isInstance;
}

export function ChapterListFromJSON(json: any): ChapterList {
    return ChapterListFromJSONTyped(json, false);
}

export function ChapterListFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChapterList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storyNum': json['story_num'],
        'chapterCdMain': json['chapter_cd_main'],
        'charaCd': json['chara_cd'],
        'title': json['title'],
        'preview': json['preview'],
        'mission': !exists(json, 'mission') ? undefined : ((json['mission'] as Array<any>).map(EvnetMissionListFromJSON)),
        'routeType': EventStoryRouteTypeFromJSON(json['route_type']),
        'ticketList': json['ticket_list'],
    };
}

export function ChapterListToJSON(value?: ChapterList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'story_num': value.storyNum,
        'chapter_cd_main': value.chapterCdMain,
        'chara_cd': value.charaCd,
        'title': value.title,
        'preview': value.preview,
        'mission': value.mission === undefined ? undefined : ((value.mission as Array<any>).map(EvnetMissionListToJSON)),
        'route_type': EventStoryRouteTypeToJSON(value.routeType),
        'ticket_list': value.ticketList,
    };
}

