import { RouteLocationNormalized } from 'vue-router';
import { useTutorial } from '~/composables/useTutorial';
import { tutorialConfig } from '~/configs/tutorial';
import { useUser } from '~/composables/useUser';

/**
 * リダイレクトが必要か
 * @param {RouteLocationNormalized} route
 * @return boolean
 */
const isRequiredRedirect = (route: RouteLocationNormalized) => {
  // リダイレクトが必要でないページか判定する
  const noRequiredRoutes = tutorialConfig.noRequiredRoutes;
  for (const p of noRequiredRoutes) {
    if (typeof p === 'string') {
      if (route.path === p) {
        return false;
      }
    } else if (p instanceof RegExp) {
      if (route.path.match(p)) {
        return false;
      }
    }
  }
  return true;
};

export default defineNuxtRouteMiddleware(async (to, _) => {
  if (!process.client) {
    return;
  }
  // リダイレクトが必要でないページであれば処理を抜ける
  const isRedirect = isRequiredRedirect(to);
  if (!isRedirect) {
    return;
  }
  // チュートリアルコードが取得できなければ、適当なAPIをリクエストして最新のメタ情報を取得する
  if (!useTutorial().tutorialCd.value) {
    try {
      await useUser().update();
    } catch (e) {
      useReportAppError(e);
      return;
    }
  }
  // チュートリアル中ではなければ処理を抜ける
  if (useTutorial().isTutorialEnd()) {
    return;
  }
  const redirectRoute = tutorialConfig.redirectRoute;
  for (const key of Object.keys(redirectRoute)) {
    if (key === useTutorial().tutorialCd.value && to.path !== redirectRoute[key]) {
      useLog('middleware/tutorial.global').debug('Redirect to ' + redirectRoute[key]);
      return navigateTo(redirectRoute[key]);
    }
  }
});
