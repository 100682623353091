/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserAvatarHaveAvatarListCoordinate } from './UserAvatarHaveAvatarListCoordinate';
import {
    UserAvatarHaveAvatarListCoordinateFromJSON,
    UserAvatarHaveAvatarListCoordinateFromJSONTyped,
    UserAvatarHaveAvatarListCoordinateToJSON,
} from './UserAvatarHaveAvatarListCoordinate';

/**
 * 
 * @export
 * @interface UserAvatarHaveAvatarList
 */
export interface UserAvatarHaveAvatarList {
    /**
     * ユーザアバターID
     * @type {number}
     * @memberof UserAvatarHaveAvatarList
     */
    userAvatarId: number;
    /**
     * アバターコード
     * @type {string}
     * @memberof UserAvatarHaveAvatarList
     */
    avatarCd: string;
    /**
     * 新規獲得フラグ（一度も着たことのないアバター）
     * @type {boolean}
     * @memberof UserAvatarHaveAvatarList
     */
    newItemFlg: boolean;
    /**
     * 
     * @type {UserAvatarHaveAvatarListCoordinate}
     * @memberof UserAvatarHaveAvatarList
     */
    coordinate: UserAvatarHaveAvatarListCoordinate | null;
}

/**
 * Check if a given object implements the UserAvatarHaveAvatarList interface.
 */
export function instanceOfUserAvatarHaveAvatarList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userAvatarId" in value;
    isInstance = isInstance && "avatarCd" in value;
    isInstance = isInstance && "newItemFlg" in value;
    isInstance = isInstance && "coordinate" in value;

    return isInstance;
}

export function UserAvatarHaveAvatarListFromJSON(json: any): UserAvatarHaveAvatarList {
    return UserAvatarHaveAvatarListFromJSONTyped(json, false);
}

export function UserAvatarHaveAvatarListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAvatarHaveAvatarList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAvatarId': json['user_avatar_id'],
        'avatarCd': json['avatar_cd'],
        'newItemFlg': json['new_item_flg'],
        'coordinate': UserAvatarHaveAvatarListCoordinateFromJSON(json['coordinate']),
    };
}

export function UserAvatarHaveAvatarListToJSON(value?: UserAvatarHaveAvatarList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_avatar_id': value.userAvatarId,
        'avatar_cd': value.avatarCd,
        'new_item_flg': value.newItemFlg,
        'coordinate': UserAvatarHaveAvatarListCoordinateToJSON(value.coordinate),
    };
}

