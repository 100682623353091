import {
  nativeBridgeCall,
  NativeBridgeCall,
  nativeBridge,
  PlayerPrefsResponse,
  PlayerPrefsName
} from '~/libs/nativeBridge';
import { useAppBack } from '~/composables/useAppBack';
import { isMobileApp } from '~/libs/platform';

interface Window {
  nativeBridge: NativeBridgeCall;
}
declare let window: Window;

export default defineNuxtPlugin(async (_) => {
  window.nativeBridge = nativeBridgeCall;
  nativeBridge.subscribe('onAndroidBack', () => {
    useAppBack();
  });
  // アプリが停止
  nativeBridge.subscribe('onWindowBlur', () => {
    nativeBridge.setActive(false);
  });
  // アプリがアクティブ
  nativeBridge.subscribe('onWindowFocus', () => {
    nativeBridge.setActive(true);
    // 通知のキャッシュを削除
    useLocalNotification().clearCache();
  });
  /**
   * gameTokenの取得
   */
  nativeBridge.subscribe('onGetPlayerPrefs', (args: PlayerPrefsResponse) => {
    if (args.key === PlayerPrefsName.gameToken) {
      useGameToken().value = args.value;
    }
  });
  if (isMobileApp()) {
    await nativeBridge.send('getPlayerPrefs', {
      key: PlayerPrefsName.gameToken,
    });
  }
  // // ブラウザキャッシュを削除
  // if (isMobileApp()) {
  //   // ネイティブ側のキャッシュを削除
  //   await nativeBridge.send('clearCache');
  // }
  return {
    provide: {
      nativeBridge,
    }
  };
});
