export interface ResourceConfig {
  /**
   * IndexDBのDB名
   */
  dbName: string,
  /**
   * IndexDBのスキーマ名
   */
  dbSchemaName: string,
  /**
   * 対象のキャッシュ名
   */
  cacheKeys: string[],
}
export const resourceConfig: ResourceConfig = {
  dbName: 'workbox-expiration',
  dbSchemaName: 'cache-entries',
  cacheKeys: [
    'resource',
  ],
};

/**
 * スクランブル画像で表示するassets
 */
export const scrambleImgTargets = [
  'card/full',
];
