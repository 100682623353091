<template>
  <ModalOverlay :show="props.show" :z-index="props.zIndex">
    <div tabindex="-1" class="md:inset-0 h-full flex items-center justify-center">
      <div class="relative w-full p-2 md:h-auto max-h-full" :class="{'h-full': props.full}">
        <div class="modal relative max-h-full" :class="{'h-full': props.full}">
          <div class="modal-content relative" :class="{'h-full': props.full}">
            <slot />
          </div>
          <!-- デフォルトのcloseボタン -->
          <div v-if="props.closeButton" class="modal-close relative">
            <div class="close-btn" @click="onClose" />
          </div>
        </div>
      </div>
    </div>
  </ModalOverlay>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  show?: boolean,
  closeButton?: boolean,
  full?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
  closeButton: false,
  full: false,
});
const emits = defineEmits<{(e: 'close'): void;}>();
const closeSe = useSe(0, '2002');
const onClose = () => {
  closeSe?.play();
  emits('close');
};
</script>
<style lang="scss" scoped>
@use "assets/css/base" as base;

.modal {
  &-content {
    border-image: url("~/assets/images/common/common_popup_background.png") 60 60 fill / 20px 20px stretch;
  }

  &-close {
    top: -4px;

    .close-btn {
      width: 178px;
      height: 47px;
      background: url("~/assets/images/common/common_btn_close.png") center no-repeat;
      background-size: contain;
      margin-left: auto;
      margin-right: auto;
      cursor: pointer;
    }
  }
}

.overlay-enter-active,
.overlay-leave-active {
  .modal {
    transition: transform 0.5s ease;
  }
}

.overlay-enter-from,
.overlay-leave-to {
  .modal {
    transform: translateY(-10px);
  }
}

</style>
