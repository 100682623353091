/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserStoryRereadAnswer } from './UserStoryRereadAnswer';
import {
    UserStoryRereadAnswerFromJSON,
    UserStoryRereadAnswerFromJSONTyped,
    UserStoryRereadAnswerToJSON,
} from './UserStoryRereadAnswer';

/**
 * 発生中のミッション
 * @export
 * @interface StoryListReread
 */
export interface StoryListReread {
    /**
     * チャプターコード
     * @type {string}
     * @memberof StoryListReread
     */
    chapterCd: string | null;
    /**
     * 読み返し時の選択肢情報
     * @type {Array<UserStoryRereadAnswer>}
     * @memberof StoryListReread
     */
    answerList: Array<UserStoryRereadAnswer>;
}

/**
 * Check if a given object implements the StoryListReread interface.
 */
export function instanceOfStoryListReread(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "chapterCd" in value;
    isInstance = isInstance && "answerList" in value;

    return isInstance;
}

export function StoryListRereadFromJSON(json: any): StoryListReread {
    return StoryListRereadFromJSONTyped(json, false);
}

export function StoryListRereadFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryListReread {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chapterCd': json['chapter_cd'],
        'answerList': ((json['answer_list'] as Array<any>).map(UserStoryRereadAnswerFromJSON)),
    };
}

export function StoryListRereadToJSON(value?: StoryListReread | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chapter_cd': value.chapterCd,
        'answer_list': ((value.answerList as Array<any>).map(UserStoryRereadAnswerToJSON)),
    };
}

