import { errorConfig } from '~/configs/errors';
import { useLog } from '~/composables/useLog';

/**
 * ポップアップモーダル用のエラー
 * @note useError(),createError()ではaddEventListener()やPromiseないでのエラー制御がなぜかうまくいかないため
 */
export const useAppError = () => {
  return useState<Error | null>('appError');
};

/**
 * エラーを設定する
 * @param e
 */
export const useReportAppError = (e: Error | unknown) => {
  useLog('appError').error('useReportAppError', JSON.stringify(e));
  // エラーをモニタリングツールに送信
  // TODO: ここでは捕捉できているので必要ないかもしれない
  useMonitoring().notify(e);
  for (const mKey in errorConfig.handleErrors) {
    if (e instanceof errorConfig.handleErrors[mKey]) {
      useAppError().value = e;
      return true;
    }
  }
  return false;
};

/**
 * エラーをクリアする
 */
export const clearAppError = () => {
  useAppError().value = null;
};
