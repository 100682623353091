<template>
  <ModalAlert
    :show="props.show"
    title="BAN警告"
    size="md"
    ok-text="お問い合わせ"
    :z-index="props.zIndex"
    @click-ok="onClick"
  >
    <div class="flex flex-col justify-center overflow-y-auto h-full">
      <div v-if="props.error" class="text-center">
        <CommonText v-if="message" :text="message" />
        <ModalErrorDebugErrorText :error="props.error" />
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import { errorConfig } from '~/configs/errors';

const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});
interface Emits {
  (e: 'click'): void;
}
const emit = defineEmits<Emits>();

const message = computed(() => {
  return props.error?.message ?? errorConfig.messages.ban;
});
const onClick = () => {
  emit('click');
};
</script>
