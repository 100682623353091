/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FriendMessageDeleteRequest
 */
export interface FriendMessageDeleteRequest {
    /**
     * 削除するユーザーソーシャルIDの配列
     * @type {Array<number>}
     * @memberof FriendMessageDeleteRequest
     */
    messageList: Array<number>;
}

/**
 * Check if a given object implements the FriendMessageDeleteRequest interface.
 */
export function instanceOfFriendMessageDeleteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "messageList" in value;

    return isInstance;
}

export function FriendMessageDeleteRequestFromJSON(json: any): FriendMessageDeleteRequest {
    return FriendMessageDeleteRequestFromJSONTyped(json, false);
}

export function FriendMessageDeleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendMessageDeleteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messageList': json['message_list'],
    };
}

export function FriendMessageDeleteRequestToJSON(value?: FriendMessageDeleteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message_list': value.messageList,
    };
}

