/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserAuthAppGameTokenRequest
 */
export interface UserAuthAppGameTokenRequest {
    /**
     * 端末に保存された認証用gameToken
     * @type {string}
     * @memberof UserAuthAppGameTokenRequest
     */
    gameToken: string;
}

/**
 * Check if a given object implements the UserAuthAppGameTokenRequest interface.
 */
export function instanceOfUserAuthAppGameTokenRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gameToken" in value;

    return isInstance;
}

export function UserAuthAppGameTokenRequestFromJSON(json: any): UserAuthAppGameTokenRequest {
    return UserAuthAppGameTokenRequestFromJSONTyped(json, false);
}

export function UserAuthAppGameTokenRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAuthAppGameTokenRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gameToken': json['game_token'],
    };
}

export function UserAuthAppGameTokenRequestToJSON(value?: UserAuthAppGameTokenRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'game_token': value.gameToken,
    };
}

