/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MissionParameterConditions
 */
export interface MissionParameterConditions {
    /**
     * クリアに必要なパラメータ
     * @type {number}
     * @memberof MissionParameterConditions
     */
    parameter: number;
    /**
     * プレゼントアイテムコード
     * @type {string}
     * @memberof MissionParameterConditions
     */
    presentItemCd: string;
}

/**
 * Check if a given object implements the MissionParameterConditions interface.
 */
export function instanceOfMissionParameterConditions(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parameter" in value;
    isInstance = isInstance && "presentItemCd" in value;

    return isInstance;
}

export function MissionParameterConditionsFromJSON(json: any): MissionParameterConditions {
    return MissionParameterConditionsFromJSONTyped(json, false);
}

export function MissionParameterConditionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionParameterConditions {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameter': json['parameter'],
        'presentItemCd': json['present_item_cd'],
    };
}

export function MissionParameterConditionsToJSON(value?: MissionParameterConditions | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameter': value.parameter,
        'present_item_cd': value.presentItemCd,
    };
}

