export enum LocalNotificationId {
    // スタミナ全回復
    StaminaMax = 1,
    // 1日ログインが無い場合
    OneDayNoLogin = 100,
    // 3日ログインが無い場合
    ThreeDayNoLogin = 101,
    // 7日ログインが無い場合
    SevenDayNoLogin = 102,
    // 14日ログインが無い場合
    FourteenDayNoLogin = 103,
    // // チュートリアル完了後に設定する通知
    // TutorialEnd = 200,
}

/**
 * デフォルトのタイトル
 */
export const defaultTitle = 'モルガナティック・アイドル';
/**
 * 体力が全回復した際ユーザーへログインを促す通知
 */
export const staminaMaxText = '体力が全回復しました。お出かけに出よう！';

export type LoginRequestDay = 1 | 3 | 7 | 14;
export interface LoginRequestDayList {
  [key: number]: string,
}
export const loginRequestDefaultCharaCd = '1';
/**
 * （A）1日、3日ログインがない場合に表示されるテキスト
 */
export const loginRequestOneDayTextList: LoginRequestDayList = {
  1: 'Xeno「今日は顔を見せに来ないのか？」',
  2: 'Ivy「少し話をしない？　来てくれると嬉しいな」',
  3: 'Jace「今日も君と会えないなんて、テンション上がんないな」',
  4: 'Finn「今なにしてんだ？　ちょっと顔見せに来いよ」',
  5: 'Hugh「……早くあなたに会いたいな。ダメ……？」',
  6: 'Miu「お前のこと、ずっと待ってるんだけど？」',
  7: 'Hitaki「今日は忙しいの？　時間があれば会いに来てほしいな」',
  8: 'Nagi「元気か？　顔見れねーと気になるだろ」',
  9: '佐倉「君に会えたら、もっと仕事にも精が出るんだけどな」',
};

export const loginRequestThreeDayTextList: LoginRequestDayList = loginRequestOneDayTextList;

/**
 * （B）7日日ログインがない場合に表示されるテキスト
 */
export const loginRequestSevenDayTextList: LoginRequestDayList = {
  1: 'Xeno「お前がいないと調子が出ない。もどかしいな」',
  2: 'Ivy「今日も忙しいのかな？　君の顔、早く見たいな……」',
  3: 'Jace「何かあった？　オレが抱きしめて癒してあげよっか？」',
  4: 'Finn「なんで今日も来ねぇんだよ。……待ってるからな」',
  5: 'Hugh「具合悪いの……？　俺、なんでもするから言って……？」',
  6: 'Miu「オレのこと放置するなんて、いい度胸してるな」',
  7: 'Hitaki「話したいことがあるんだけど、今日は来られるかな？」',
  8: 'Nagi「時間がないなら、オレから会いに行ってやるよ」',
  9: '佐倉「君も忙しいみたいだね。大丈夫ならいいんだけど……」',
};

/**
 * （C）14日日ログインがない場合に表示されるテキスト
 */
export const loginRequestFourteenDayTextList: LoginRequestDayList = {
  1: 'Xeno「お前の居場所は俺の隣だろう。早くこの手を取れ」',
  2: 'Ivy「もう我慢できないな……今から迎えに行ってもいい？」',
  3: 'Jace「待ちきれなくて、君のことさらいに来ちゃった」',
  4: 'Finn「オレが迎えに来てやったんだ。早く帰るぞ」',
  5: 'Hugh「あなたがいないと踊れない……ねえ、こっち来て……？」',
  6: 'Miu「早く来いよ。オレを待たせた分、たっぷり遊んであげる」',
  7: 'Hitaki「僕をこんなに放っておくなんて、お仕置きしなくちゃね」',
  8: 'Nagi「待つだけなんて性に合わねぇからな。迎えに来てやったぜ」',
  9: '佐倉「早く君に会いたい……こんなに焦らしてどうするつもり？」',
};

const loginRequestDaysTextList: {[key: number]: LoginRequestDayList} = {
  1: loginRequestOneDayTextList,
  3: loginRequestThreeDayTextList,
  7: loginRequestSevenDayTextList,
  14: loginRequestFourteenDayTextList,
};
/**
 * ログイン請求通知のテキスト取得
 * @param day
 * @param charaCd
 */
export const getLoginRequestNotificationText = (day: LoginRequestDay, charaCd: string|null) => {
  if (!(day in loginRequestDaysTextList)) {
    return undefined;
  }
  const list = loginRequestDaysTextList[day];
  const cd = Number(!charaCd || !(charaCd in list) ? loginRequestDefaultCharaCd : charaCd);
  if (!(cd in list)) {
    return undefined;
  }
  return list[cd];
};

/**
 * ログイン日数一覧を取得
 */
export const getLoginRequestDays = () => {
  return Object.keys(loginRequestDaysTextList).map(v => Number(v));
};
