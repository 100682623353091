/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * スペシャルストーリータイプ
 * @export
 */
export const SpecialStoryType = {
    Avanml: 'avanml',
    Avaprm: 'avaprm',
    His: 'his',
    Commu: 'commu',
    Evt: 'evt',
    Prl: 'prl',
    Shop: 'shop',
    Grnat: 'grnat',
    Evtepi: 'evtepi',
    Pmis: 'pmis'
} as const;
export type SpecialStoryType = typeof SpecialStoryType[keyof typeof SpecialStoryType];


export function SpecialStoryTypeFromJSON(json: any): SpecialStoryType {
    return SpecialStoryTypeFromJSONTyped(json, false);
}

export function SpecialStoryTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialStoryType {
    return json as SpecialStoryType;
}

export function SpecialStoryTypeToJSON(value?: SpecialStoryType | null): any {
    return value as any;
}

