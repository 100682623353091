/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FirstUseBonus
 */
export interface FirstUseBonus {
    /**
     * 初回利用キー
     * @type {string}
     * @memberof FirstUseBonus
     */
    firstUseKey: string;
    /**
     * アイテム名
     * @type {string}
     * @memberof FirstUseBonus
     */
    itemName: string;
    /**
     * アイテムコード
     * @type {string}
     * @memberof FirstUseBonus
     */
    itemCd: string;
    /**
     * アイテム個数
     * @type {number}
     * @memberof FirstUseBonus
     */
    itemCount: number;
}

/**
 * Check if a given object implements the FirstUseBonus interface.
 */
export function instanceOfFirstUseBonus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "firstUseKey" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemCount" in value;

    return isInstance;
}

export function FirstUseBonusFromJSON(json: any): FirstUseBonus {
    return FirstUseBonusFromJSONTyped(json, false);
}

export function FirstUseBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FirstUseBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstUseKey': json['first_use_key'],
        'itemName': json['item_name'],
        'itemCd': json['item_cd'],
        'itemCount': json['item_count'],
    };
}

export function FirstUseBonusToJSON(value?: FirstUseBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'first_use_key': value.firstUseKey,
        'item_name': value.itemName,
        'item_cd': value.itemCd,
        'item_count': value.itemCount,
    };
}

