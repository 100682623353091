/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AccountDeleteResponse,
  AnimateWalletResponse,
  AvatarEquipListResponse,
  AvatarEquipSaveRequest,
  AvatarListResponse,
  AvatarReadRequest,
  AvatarSaveRequest,
  BillingProductPurchaseType,
  CardListResponse,
  CardReadRequest,
  CardSaveRequest,
  CharaSaveRequest,
  CharaSaveResponse,
  CharaSelectedResponse,
  CommuBonusRequest,
  CommuCharaLimitbreakRequest,
  CommuCharaLimitbreakResponse,
  CommuCharaListResponse,
  CommuCharaReadRequest,
  CommuCharaStatusResponse,
  DeckListResponse,
  DeckRecommendRequest,
  DeckRecommendResponse,
  DeckSaveRequest,
  DeckSetMainRequest,
  DeckSetNameRequest,
  DeleteAvatarRequest,
  DeleteAvatarResponse,
  EntryflashListResponse,
  EntryflashReadRequest,
  ErrorResponse,
  EventCharaChangeRequest,
  EventMissionCompleteRequest,
  EventMissionCompleteResponse,
  EventStatusResponse,
  EventStoryCompleteRequest,
  EventStoryListResponse,
  FriendCancelRequest,
  FriendDeleteRequest,
  FriendFollowRequest,
  FriendLikeReceiveResponse,
  FriendLikeSendRequest,
  FriendLikeSendResponse,
  FriendMessageDeleteRequest,
  FriendMessageListResponse,
  FriendMessageSendRequest,
  FriendRecommendListResponse,
  FriendStatusResponse,
  GachaDecideRequest,
  GachaExecRequest,
  GachaType,
  GetLoginStampResponse,
  ItemConsumeRequest,
  ItemConsumeResponse,
  ItemListResponse,
  MissionCompleteRequest,
  MissionCompleteResponse,
  PanelmissionBonusFinishedResponse,
  PanelmissionEventPanelResponse,
  PanelmissionNoticeResponse,
  PatchStoryInitRequest,
  PostLoginStampRequest,
  PostStoryCompleteRequest,
  SerialCodeSaveRequest,
  SerialCodeSaveResponse,
  ShopCombinedsaleListResponse,
  ShopItemBuyRequest,
  ShopItemBuyResponse,
  ShopItemLimitResponse,
  ShopItemListResponse,
  ShopProductBuyRequest,
  ShopProductBuyResponse,
  ShopProductListResponse,
  ShopSaleType,
  SpecialStoryReadRequest,
  SpecialStoryUnlockRequest,
  StillReadRequest,
  StoryAuthResponse,
  StoryCompleteResponse,
  StoryListRequestEnum,
  StoryListResponse,
  StoryStatusResponse,
  TutorialSaveRequest,
  TutorialSaveResponse,
  UserAuthAppAuthResponse,
  UserAuthAppGameTokenRequest,
  UserAuthAppGameTokenResponse,
  UserAuthProviderAccessTokenRequest,
  UserAuthProviderAccessTokenResponse,
  UserAuthProviderResponse,
  UserAuthProviderUnlinkRequest,
  UserAuthProviderUnlinkResponse,
  UserBadgeResponse,
  UserBannerListResponse,
  UserCollectionBadgeResponse,
  UserDeckRentalListResponse,
  UserEventBonusListResponse,
  UserEventBonusReceiveRequest,
  UserEventBonusReceiveResponse,
  UserEventCharaChangeResponse,
  UserEventEpilogueResponse,
  UserEventMinigameExecuteRequest,
  UserEventMinigameExecuteResponse,
  UserEventMinigameHistoryResponse,
  UserEventMinigameTopResponse,
  UserEventRankingListResponse,
  UserEventStoryCompleteResponse,
  UserFirstUseRequest,
  UserFirstUseResponse,
  UserFriendFollowListResponse,
  UserFriendFollowerListResponse,
  UserFriendLikeListResponse,
  UserGachaDecideResponse,
  UserGachaExecResponse,
  UserGachaLineupListResponse,
  UserGachaListResponse,
  UserGameStartRequest,
  UserGameStartResponse,
  UserIdentifyCodeResponse,
  UserMinigameExecuteRequest,
  UserMinigameExecuteResponse,
  UserMinigameHistoryResponse,
  UserMinigameTopResponse,
  UserMypageResponse,
  UserNameSaveRequest,
  UserNewsListResponse,
  UserNewsReadRequest,
  UserNewsReadResponse,
  UserPanelmissionBonusReceiveRequest,
  UserPanelmissionListResponse,
  UserPresentDeleteRequest,
  UserPresentDeleteResponse,
  UserPresentHistoryResponse,
  UserPresentListResponse,
  UserPresentReceiveRequest,
  UserPresentReceiveResponse,
  UserPrivateNewsListResponse,
  UserPrivateNewsReadRequest,
  UserSaveBirthdayRequest,
  UserSaveSelfIntroductionRequest,
  UserSpecialStoryListResponse,
  UserStatusMinResponse,
  UserStatusResponse,
  UserStillListResponse,
} from '../models';
import {
    AccountDeleteResponseFromJSON,
    AccountDeleteResponseToJSON,
    AnimateWalletResponseFromJSON,
    AnimateWalletResponseToJSON,
    AvatarEquipListResponseFromJSON,
    AvatarEquipListResponseToJSON,
    AvatarEquipSaveRequestFromJSON,
    AvatarEquipSaveRequestToJSON,
    AvatarListResponseFromJSON,
    AvatarListResponseToJSON,
    AvatarReadRequestFromJSON,
    AvatarReadRequestToJSON,
    AvatarSaveRequestFromJSON,
    AvatarSaveRequestToJSON,
    BillingProductPurchaseTypeFromJSON,
    BillingProductPurchaseTypeToJSON,
    CardListResponseFromJSON,
    CardListResponseToJSON,
    CardReadRequestFromJSON,
    CardReadRequestToJSON,
    CardSaveRequestFromJSON,
    CardSaveRequestToJSON,
    CharaSaveRequestFromJSON,
    CharaSaveRequestToJSON,
    CharaSaveResponseFromJSON,
    CharaSaveResponseToJSON,
    CharaSelectedResponseFromJSON,
    CharaSelectedResponseToJSON,
    CommuBonusRequestFromJSON,
    CommuBonusRequestToJSON,
    CommuCharaLimitbreakRequestFromJSON,
    CommuCharaLimitbreakRequestToJSON,
    CommuCharaLimitbreakResponseFromJSON,
    CommuCharaLimitbreakResponseToJSON,
    CommuCharaListResponseFromJSON,
    CommuCharaListResponseToJSON,
    CommuCharaReadRequestFromJSON,
    CommuCharaReadRequestToJSON,
    CommuCharaStatusResponseFromJSON,
    CommuCharaStatusResponseToJSON,
    DeckListResponseFromJSON,
    DeckListResponseToJSON,
    DeckRecommendRequestFromJSON,
    DeckRecommendRequestToJSON,
    DeckRecommendResponseFromJSON,
    DeckRecommendResponseToJSON,
    DeckSaveRequestFromJSON,
    DeckSaveRequestToJSON,
    DeckSetMainRequestFromJSON,
    DeckSetMainRequestToJSON,
    DeckSetNameRequestFromJSON,
    DeckSetNameRequestToJSON,
    DeleteAvatarRequestFromJSON,
    DeleteAvatarRequestToJSON,
    DeleteAvatarResponseFromJSON,
    DeleteAvatarResponseToJSON,
    EntryflashListResponseFromJSON,
    EntryflashListResponseToJSON,
    EntryflashReadRequestFromJSON,
    EntryflashReadRequestToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    EventCharaChangeRequestFromJSON,
    EventCharaChangeRequestToJSON,
    EventMissionCompleteRequestFromJSON,
    EventMissionCompleteRequestToJSON,
    EventMissionCompleteResponseFromJSON,
    EventMissionCompleteResponseToJSON,
    EventStatusResponseFromJSON,
    EventStatusResponseToJSON,
    EventStoryCompleteRequestFromJSON,
    EventStoryCompleteRequestToJSON,
    EventStoryListResponseFromJSON,
    EventStoryListResponseToJSON,
    FriendCancelRequestFromJSON,
    FriendCancelRequestToJSON,
    FriendDeleteRequestFromJSON,
    FriendDeleteRequestToJSON,
    FriendFollowRequestFromJSON,
    FriendFollowRequestToJSON,
    FriendLikeReceiveResponseFromJSON,
    FriendLikeReceiveResponseToJSON,
    FriendLikeSendRequestFromJSON,
    FriendLikeSendRequestToJSON,
    FriendLikeSendResponseFromJSON,
    FriendLikeSendResponseToJSON,
    FriendMessageDeleteRequestFromJSON,
    FriendMessageDeleteRequestToJSON,
    FriendMessageListResponseFromJSON,
    FriendMessageListResponseToJSON,
    FriendMessageSendRequestFromJSON,
    FriendMessageSendRequestToJSON,
    FriendRecommendListResponseFromJSON,
    FriendRecommendListResponseToJSON,
    FriendStatusResponseFromJSON,
    FriendStatusResponseToJSON,
    GachaDecideRequestFromJSON,
    GachaDecideRequestToJSON,
    GachaExecRequestFromJSON,
    GachaExecRequestToJSON,
    GachaTypeFromJSON,
    GachaTypeToJSON,
    GetLoginStampResponseFromJSON,
    GetLoginStampResponseToJSON,
    ItemConsumeRequestFromJSON,
    ItemConsumeRequestToJSON,
    ItemConsumeResponseFromJSON,
    ItemConsumeResponseToJSON,
    ItemListResponseFromJSON,
    ItemListResponseToJSON,
    MissionCompleteRequestFromJSON,
    MissionCompleteRequestToJSON,
    MissionCompleteResponseFromJSON,
    MissionCompleteResponseToJSON,
    PanelmissionBonusFinishedResponseFromJSON,
    PanelmissionBonusFinishedResponseToJSON,
    PanelmissionEventPanelResponseFromJSON,
    PanelmissionEventPanelResponseToJSON,
    PanelmissionNoticeResponseFromJSON,
    PanelmissionNoticeResponseToJSON,
    PatchStoryInitRequestFromJSON,
    PatchStoryInitRequestToJSON,
    PostLoginStampRequestFromJSON,
    PostLoginStampRequestToJSON,
    PostStoryCompleteRequestFromJSON,
    PostStoryCompleteRequestToJSON,
    SerialCodeSaveRequestFromJSON,
    SerialCodeSaveRequestToJSON,
    SerialCodeSaveResponseFromJSON,
    SerialCodeSaveResponseToJSON,
    ShopCombinedsaleListResponseFromJSON,
    ShopCombinedsaleListResponseToJSON,
    ShopItemBuyRequestFromJSON,
    ShopItemBuyRequestToJSON,
    ShopItemBuyResponseFromJSON,
    ShopItemBuyResponseToJSON,
    ShopItemLimitResponseFromJSON,
    ShopItemLimitResponseToJSON,
    ShopItemListResponseFromJSON,
    ShopItemListResponseToJSON,
    ShopProductBuyRequestFromJSON,
    ShopProductBuyRequestToJSON,
    ShopProductBuyResponseFromJSON,
    ShopProductBuyResponseToJSON,
    ShopProductListResponseFromJSON,
    ShopProductListResponseToJSON,
    ShopSaleTypeFromJSON,
    ShopSaleTypeToJSON,
    SpecialStoryReadRequestFromJSON,
    SpecialStoryReadRequestToJSON,
    SpecialStoryUnlockRequestFromJSON,
    SpecialStoryUnlockRequestToJSON,
    StillReadRequestFromJSON,
    StillReadRequestToJSON,
    StoryAuthResponseFromJSON,
    StoryAuthResponseToJSON,
    StoryCompleteResponseFromJSON,
    StoryCompleteResponseToJSON,
    StoryListRequestEnumFromJSON,
    StoryListRequestEnumToJSON,
    StoryListResponseFromJSON,
    StoryListResponseToJSON,
    StoryStatusResponseFromJSON,
    StoryStatusResponseToJSON,
    TutorialSaveRequestFromJSON,
    TutorialSaveRequestToJSON,
    TutorialSaveResponseFromJSON,
    TutorialSaveResponseToJSON,
    UserAuthAppAuthResponseFromJSON,
    UserAuthAppAuthResponseToJSON,
    UserAuthAppGameTokenRequestFromJSON,
    UserAuthAppGameTokenRequestToJSON,
    UserAuthAppGameTokenResponseFromJSON,
    UserAuthAppGameTokenResponseToJSON,
    UserAuthProviderAccessTokenRequestFromJSON,
    UserAuthProviderAccessTokenRequestToJSON,
    UserAuthProviderAccessTokenResponseFromJSON,
    UserAuthProviderAccessTokenResponseToJSON,
    UserAuthProviderResponseFromJSON,
    UserAuthProviderResponseToJSON,
    UserAuthProviderUnlinkRequestFromJSON,
    UserAuthProviderUnlinkRequestToJSON,
    UserAuthProviderUnlinkResponseFromJSON,
    UserAuthProviderUnlinkResponseToJSON,
    UserBadgeResponseFromJSON,
    UserBadgeResponseToJSON,
    UserBannerListResponseFromJSON,
    UserBannerListResponseToJSON,
    UserCollectionBadgeResponseFromJSON,
    UserCollectionBadgeResponseToJSON,
    UserDeckRentalListResponseFromJSON,
    UserDeckRentalListResponseToJSON,
    UserEventBonusListResponseFromJSON,
    UserEventBonusListResponseToJSON,
    UserEventBonusReceiveRequestFromJSON,
    UserEventBonusReceiveRequestToJSON,
    UserEventBonusReceiveResponseFromJSON,
    UserEventBonusReceiveResponseToJSON,
    UserEventCharaChangeResponseFromJSON,
    UserEventCharaChangeResponseToJSON,
    UserEventEpilogueResponseFromJSON,
    UserEventEpilogueResponseToJSON,
    UserEventMinigameExecuteRequestFromJSON,
    UserEventMinigameExecuteRequestToJSON,
    UserEventMinigameExecuteResponseFromJSON,
    UserEventMinigameExecuteResponseToJSON,
    UserEventMinigameHistoryResponseFromJSON,
    UserEventMinigameHistoryResponseToJSON,
    UserEventMinigameTopResponseFromJSON,
    UserEventMinigameTopResponseToJSON,
    UserEventRankingListResponseFromJSON,
    UserEventRankingListResponseToJSON,
    UserEventStoryCompleteResponseFromJSON,
    UserEventStoryCompleteResponseToJSON,
    UserFirstUseRequestFromJSON,
    UserFirstUseRequestToJSON,
    UserFirstUseResponseFromJSON,
    UserFirstUseResponseToJSON,
    UserFriendFollowListResponseFromJSON,
    UserFriendFollowListResponseToJSON,
    UserFriendFollowerListResponseFromJSON,
    UserFriendFollowerListResponseToJSON,
    UserFriendLikeListResponseFromJSON,
    UserFriendLikeListResponseToJSON,
    UserGachaDecideResponseFromJSON,
    UserGachaDecideResponseToJSON,
    UserGachaExecResponseFromJSON,
    UserGachaExecResponseToJSON,
    UserGachaLineupListResponseFromJSON,
    UserGachaLineupListResponseToJSON,
    UserGachaListResponseFromJSON,
    UserGachaListResponseToJSON,
    UserGameStartRequestFromJSON,
    UserGameStartRequestToJSON,
    UserGameStartResponseFromJSON,
    UserGameStartResponseToJSON,
    UserIdentifyCodeResponseFromJSON,
    UserIdentifyCodeResponseToJSON,
    UserMinigameExecuteRequestFromJSON,
    UserMinigameExecuteRequestToJSON,
    UserMinigameExecuteResponseFromJSON,
    UserMinigameExecuteResponseToJSON,
    UserMinigameHistoryResponseFromJSON,
    UserMinigameHistoryResponseToJSON,
    UserMinigameTopResponseFromJSON,
    UserMinigameTopResponseToJSON,
    UserMypageResponseFromJSON,
    UserMypageResponseToJSON,
    UserNameSaveRequestFromJSON,
    UserNameSaveRequestToJSON,
    UserNewsListResponseFromJSON,
    UserNewsListResponseToJSON,
    UserNewsReadRequestFromJSON,
    UserNewsReadRequestToJSON,
    UserNewsReadResponseFromJSON,
    UserNewsReadResponseToJSON,
    UserPanelmissionBonusReceiveRequestFromJSON,
    UserPanelmissionBonusReceiveRequestToJSON,
    UserPanelmissionListResponseFromJSON,
    UserPanelmissionListResponseToJSON,
    UserPresentDeleteRequestFromJSON,
    UserPresentDeleteRequestToJSON,
    UserPresentDeleteResponseFromJSON,
    UserPresentDeleteResponseToJSON,
    UserPresentHistoryResponseFromJSON,
    UserPresentHistoryResponseToJSON,
    UserPresentListResponseFromJSON,
    UserPresentListResponseToJSON,
    UserPresentReceiveRequestFromJSON,
    UserPresentReceiveRequestToJSON,
    UserPresentReceiveResponseFromJSON,
    UserPresentReceiveResponseToJSON,
    UserPrivateNewsListResponseFromJSON,
    UserPrivateNewsListResponseToJSON,
    UserPrivateNewsReadRequestFromJSON,
    UserPrivateNewsReadRequestToJSON,
    UserSaveBirthdayRequestFromJSON,
    UserSaveBirthdayRequestToJSON,
    UserSaveSelfIntroductionRequestFromJSON,
    UserSaveSelfIntroductionRequestToJSON,
    UserSpecialStoryListResponseFromJSON,
    UserSpecialStoryListResponseToJSON,
    UserStatusMinResponseFromJSON,
    UserStatusMinResponseToJSON,
    UserStatusResponseFromJSON,
    UserStatusResponseToJSON,
    UserStillListResponseFromJSON,
    UserStillListResponseToJSON,
} from '../models';

export interface DeleteAccountRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface DeleteAvatarOperationRequest {
    deleteAvatarRequest: DeleteAvatarRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetAnimateWalletRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetAvatarEquipListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    playerCode?: string;
}

export interface GetAvatarListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    playerCode?: string;
}

export interface GetBannerListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetCardListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetCharaSelectedRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetCommuCharaListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetCommuCharaStatusRequest {
    charaCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetDeckListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetDeckRentalListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetEntryflashListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetEventBonusListRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetEventEpilogueRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetEventMinigameHistoryRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetEventMinigameTopRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    playerCode?: string;
}

export interface GetEventRankingListRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetEventStatusRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetEventStoryStatusRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetFriendFollowListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetFriendFollowerListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetFriendLikeListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetFriendMessageListRequest {
    messageKey: GetFriendMessageListMessageKeyEnum;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetFriendRecommendListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetFriendStatusRequest {
    playerCode: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetGachaLineupListRequest {
    type: GachaType;
    gachaCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetGachaListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetItemListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetLoginstampGetRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetMinigameHistoryRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetMinigameTopRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    playerCode?: string;
}

export interface GetNewsListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetPanelmissionBonusFinishedRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetPanelmissionEventPanelRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetPanelmissionListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetPanelmissionNoticeRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetPresentHistoryRequest {
    presentType: GetPresentHistoryPresentTypeEnum;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetPresentListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetPrivateNewsListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetShopCombinedsaleListRequest {
    type: ShopSaleType;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetShopItemListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetShopLimitItemListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetShopProductListRequest {
    purchaseType: BillingProductPurchaseType;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetSpecialStoryListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetStillListRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetStoryAuthRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetStoryListRequest {
    type: StoryListRequestEnum;
    charaCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetStoryStatusRequest {
    isActiveChara: number;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    playerCode?: string;
}

export interface GetUserAppAuthRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserBadgeRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserCollectionBadgeRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserFirstUseRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserIdentifyCodeRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserMypageRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserProviderRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserProviderAccessTokenRequest {
    userAuthProviderAccessTokenRequest: UserAuthProviderAccessTokenRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserStatusRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetUserStatusminRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchAvatarReadRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    avatarReadRequest?: AvatarReadRequest;
}

export interface PatchCardReadRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    cardReadRequest?: CardReadRequest;
}

export interface PatchCommuCharaReadRequest {
    commuCharaReadRequest: CommuCharaReadRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchDeckSetMainRequest {
    deckSetMainRequest: DeckSetMainRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchDeckSetNameRequest {
    deckSetNameRequest: DeckSetNameRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchFriendLikeReadRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchSpecialStoryReadRequest {
    specialStoryReadRequest: SpecialStoryReadRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchSpecialStoryUnlockRequest {
    specialStoryUnlockRequest: SpecialStoryUnlockRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchStillReadRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    stillReadRequest?: StillReadRequest;
}

export interface PatchStoryInitOperationRequest {
    patchStoryInitRequest: PatchStoryInitRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PatchUserFirstUseRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    userFirstUseRequest?: UserFirstUseRequest;
}

export interface PostAvatarEquipSaveRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    avatarEquipSaveRequest?: AvatarEquipSaveRequest;
}

export interface PostAvatarSaveRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    avatarSaveRequest?: AvatarSaveRequest;
}

export interface PostCardSaveRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    cardSaveRequest?: CardSaveRequest;
}

export interface PostCharaSaveRequest {
    charaSaveRequest: CharaSaveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostCommuBonusRequest {
    commuBonusRequest: CommuBonusRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostCommuCharaLimitbreakRequest {
    commuCharaLimitbreakRequest: CommuCharaLimitbreakRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostDeckRecommendRequest {
    deckRecommendRequest: DeckRecommendRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostEntryflashReadRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    entryflashReadRequest?: EntryflashReadRequest;
}

export interface PostEventBonusReceiveRequest {
    eventCd: string;
    userEventBonusReceiveRequest: UserEventBonusReceiveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostEventCharaChangeRequest {
    eventCd: string;
    eventCharaChangeRequest: EventCharaChangeRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostEventEntryRequest {
    eventCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostEventMigameExecuteRequest {
    eventCd: string;
    userEventMinigameExecuteRequest: UserEventMinigameExecuteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostEventMissionCompleteRequest {
    eventCd: string;
    eventMissionCompleteRequest: EventMissionCompleteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostEventStoryCompleteRequest {
    eventCd: string;
    eventStoryCompleteRequest: EventStoryCompleteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostFriendCancelRequest {
    friendCancelRequest: FriendCancelRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostFriendDeleteRequest {
    friendDeleteRequest: FriendDeleteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostFriendFollowRequest {
    friendFollowRequest: FriendFollowRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostFriendLikeReceiveRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostFriendLikeSendRequest {
    friendLikeSendRequest: FriendLikeSendRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostFriendMessageDeleteRequest {
    friendMessageDeleteRequest: FriendMessageDeleteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostFriendMessageSendRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    friendMessageSendRequest?: FriendMessageSendRequest;
}

export interface PostGachaDecideRequest {
    gachaDecideRequest: GachaDecideRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostGachaExecRequest {
    gachaExecRequest: GachaExecRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostItemConsumeRequest {
    itemConsumeRequest: ItemConsumeRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostLoginstampSaveRequest {
    postLoginStampRequest: PostLoginStampRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostMigameExecuteRequest {
    userMinigameExecuteRequest: UserMinigameExecuteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostMissionCompleteRequest {
    missionCompleteRequest: MissionCompleteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostNewsReadRequest {
    userNewsReadRequest: UserNewsReadRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostPanelmissionBonusReceiveRequest {
    userPanelmissionBonusReceiveRequest: UserPanelmissionBonusReceiveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostPresentDeleteRequest {
    userPresentDeleteRequest: UserPresentDeleteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostPresentReceiveRequest {
    userPresentReceiveRequest: UserPresentReceiveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostPrivateNewsReadRequest {
    userPrivateNewsReadRequest: UserPrivateNewsReadRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostSerialCodeSaveRequest {
    serialCodeSaveRequest: SerialCodeSaveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostShopItemBuyRequest {
    shopItemBuyRequest: ShopItemBuyRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostShopProductBuyRequest {
    shopProductBuyRequest: ShopProductBuyRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostStoryCompleteOperationRequest {
    postStoryCompleteRequest: PostStoryCompleteRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostTutorialSaveRequest {
    tutorialSaveRequest: TutorialSaveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostUserAppGameTokenRequest {
    userAuthAppGameTokenRequest: UserAuthAppGameTokenRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostUserGamestartRequest {
    userGameStartRequest: UserGameStartRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostUserIntroductionSaveRequest {
    userSaveSelfIntroductionRequest: UserSaveSelfIntroductionRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PostUserProviderUnlinkRequest {
    userAuthProviderUnlinkRequest: UserAuthProviderUnlinkRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface PutDeckSaveRequest {
    deckSaveRequest: DeckSaveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface SaveUserBirthdayRequest {
    userSaveBirthdayRequest: UserSaveBirthdayRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface SaveUserNameRequest {
    userNameSaveRequest: UserNameSaveRequest;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * モルガナティック・アイドルアカウントを削除する。
     */
    async deleteAccountRaw(requestParameters: DeleteAccountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AccountDeleteResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/account`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountDeleteResponseFromJSON(jsonValue));
    }

    /**
     * モルガナティック・アイドルアカウントを削除する。
     */
    async deleteAccount(requestParameters: DeleteAccountRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AccountDeleteResponse> {
        const response = await this.deleteAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アバター削除
     */
    async deleteAvatarRaw(requestParameters: DeleteAvatarOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeleteAvatarResponse>> {
        if (requestParameters.deleteAvatarRequest === null || requestParameters.deleteAvatarRequest === undefined) {
            throw new runtime.RequiredError('deleteAvatarRequest','Required parameter requestParameters.deleteAvatarRequest was null or undefined when calling deleteAvatar.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/avatar/delete`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteAvatarRequestToJSON(requestParameters.deleteAvatarRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteAvatarResponseFromJSON(jsonValue));
    }

    /**
     * アバター削除
     */
    async deleteAvatar(requestParameters: DeleteAvatarOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeleteAvatarResponse> {
        const response = await this.deleteAvatarRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アニメイトウォレット情報取得API
     */
    async getAnimateWalletRaw(requestParameters: GetAnimateWalletRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AnimateWalletResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/animate/wallet`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AnimateWalletResponseFromJSON(jsonValue));
    }

    /**
     * アニメイトウォレット情報取得API
     */
    async getAnimateWallet(requestParameters: GetAnimateWalletRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AnimateWalletResponse> {
        const response = await this.getAnimateWalletRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アバターお着替え情報リスト
     */
    async getAvatarEquipListRaw(requestParameters: GetAvatarEquipListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvatarEquipListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.playerCode !== undefined) {
            queryParameters['player_code'] = requestParameters.playerCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/avatar/equip/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvatarEquipListResponseFromJSON(jsonValue));
    }

    /**
     * アバターお着替え情報リスト
     */
    async getAvatarEquipList(requestParameters: GetAvatarEquipListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvatarEquipListResponse> {
        const response = await this.getAvatarEquipListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アバターリスト
     */
    async getAvatarListRaw(requestParameters: GetAvatarListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvatarListResponse>> {
        const queryParameters: any = {};

        if (requestParameters.playerCode !== undefined) {
            queryParameters['player_code'] = requestParameters.playerCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/avatar/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvatarListResponseFromJSON(jsonValue));
    }

    /**
     * アバターリスト
     */
    async getAvatarList(requestParameters: GetAvatarListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvatarListResponse> {
        const response = await this.getAvatarListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * バナー一覧を取得する  ## banner_category_cdについて mypage_lower（マイページバナー）の場合は\"mypage\"固定（カテゴリは１件のみ） （サーバ側の実装を統一化するためにロケーション毎でレスポンスの階層構造を変更はしないこととしている）  |コード値|カテゴリ名称| | --- | --- | | rookie_event | 新人イベント | | chara_release | 本編配信 | | important | 重要 | | scenario_event | シナリオイベント | | collect_event | 本編促進 | | party_event | パーティーイベント | | gacha | ガチャ | | scenario_sale | シナリオ販売 | | combinedsale | セット販売 | | event_ranking | シナイベ最終ランキング | | collect_ranking | 本促最終ランキング | | party_ranking | パーティーイベ最終ランキング | | loginstamp | ログインスタンプ | | soquuun | 回遊 | | other | その他 | 
     */
    async getBannerListRaw(requestParameters: GetBannerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserBannerListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/banner/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBannerListResponseFromJSON(jsonValue));
    }

    /**
     * バナー一覧を取得する  ## banner_category_cdについて mypage_lower（マイページバナー）の場合は\"mypage\"固定（カテゴリは１件のみ） （サーバ側の実装を統一化するためにロケーション毎でレスポンスの階層構造を変更はしないこととしている）  |コード値|カテゴリ名称| | --- | --- | | rookie_event | 新人イベント | | chara_release | 本編配信 | | important | 重要 | | scenario_event | シナリオイベント | | collect_event | 本編促進 | | party_event | パーティーイベント | | gacha | ガチャ | | scenario_sale | シナリオ販売 | | combinedsale | セット販売 | | event_ranking | シナイベ最終ランキング | | collect_ranking | 本促最終ランキング | | party_ranking | パーティーイベ最終ランキング | | loginstamp | ログインスタンプ | | soquuun | 回遊 | | other | その他 | 
     */
    async getBannerList(requestParameters: GetBannerListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserBannerListResponse> {
        const response = await this.getBannerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 所持カード一覧を取得する
     */
    async getCardListRaw(requestParameters: GetCardListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/card/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardListResponseFromJSON(jsonValue));
    }

    /**
     * 所持カード一覧を取得する
     */
    async getCardList(requestParameters: GetCardListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardListResponse> {
        const response = await this.getCardListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 選択済みのキャラコードを取得する
     */
    async getCharaSelectedRaw(requestParameters: GetCharaSelectedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CharaSelectedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/chara/selected`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CharaSelectedResponseFromJSON(jsonValue));
    }

    /**
     * 選択済みのキャラコードを取得する
     */
    async getCharaSelected(requestParameters: GetCharaSelectedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CharaSelectedResponse> {
        const response = await this.getCharaSelectedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュのキャラ一覧を取得する
     */
    async getCommuCharaListRaw(requestParameters: GetCommuCharaListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommuCharaListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/commu/chara/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommuCharaListResponseFromJSON(jsonValue));
    }

    /**
     * コミュのキャラ一覧を取得する
     */
    async getCommuCharaList(requestParameters: GetCommuCharaListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommuCharaListResponse> {
        const response = await this.getCommuCharaListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュのキャラステータスを取得する
     */
    async getCommuCharaStatusRaw(requestParameters: GetCommuCharaStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommuCharaStatusResponse>> {
        if (requestParameters.charaCd === null || requestParameters.charaCd === undefined) {
            throw new runtime.RequiredError('charaCd','Required parameter requestParameters.charaCd was null or undefined when calling getCommuCharaStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/commu/chara/status/{chara_cd}`.replace(`{${"chara_cd"}}`, encodeURIComponent(String(requestParameters.charaCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommuCharaStatusResponseFromJSON(jsonValue));
    }

    /**
     * コミュのキャラステータスを取得する
     */
    async getCommuCharaStatus(requestParameters: GetCommuCharaStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommuCharaStatusResponse> {
        const response = await this.getCommuCharaStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 設定されている部隊（デッキ）一覧を取得する
     */
    async getDeckListRaw(requestParameters: GetDeckListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeckListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/deck/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeckListResponseFromJSON(jsonValue));
    }

    /**
     * 設定されている部隊（デッキ）一覧を取得する
     */
    async getDeckList(requestParameters: GetDeckListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeckListResponse> {
        const response = await this.getDeckListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * レンタル候補ゲストリスト取得API
     */
    async getDeckRentalListRaw(requestParameters: GetDeckRentalListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserDeckRentalListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/deck/rental/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDeckRentalListResponseFromJSON(jsonValue));
    }

    /**
     * レンタル候補ゲストリスト取得API
     */
    async getDeckRentalList(requestParameters: GetDeckRentalListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserDeckRentalListResponse> {
        const response = await this.getDeckRentalListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * エントリーフラッシュ一覧を取得する
     */
    async getEntryflashListRaw(requestParameters: GetEntryflashListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EntryflashListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/entryflash/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EntryflashListResponseFromJSON(jsonValue));
    }

    /**
     * エントリーフラッシュ一覧を取得する
     */
    async getEntryflashList(requestParameters: GetEntryflashListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EntryflashListResponse> {
        const response = await this.getEntryflashListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのシナイベ特典情報を取得するAPI。
     */
    async getEventBonusListRaw(requestParameters: GetEventBonusListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventBonusListResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getEventBonusList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/bonus/list`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventBonusListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのシナイベ特典情報を取得するAPI。
     */
    async getEventBonusList(requestParameters: GetEventBonusListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventBonusListResponse> {
        const response = await this.getEventBonusListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのシナイベエピローグ情報を取得するAPI。
     */
    async getEventEpilogueRaw(requestParameters: GetEventEpilogueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventEpilogueResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getEventEpilogue.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/epilogue/get`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventEpilogueResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのシナイベエピローグ情報を取得するAPI。
     */
    async getEventEpilogue(requestParameters: GetEventEpilogueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventEpilogueResponse> {
        const response = await this.getEventEpilogueRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * シナイベミニゲームの履歴を返すAPI
     */
    async getEventMinigameHistoryRaw(requestParameters: GetEventMinigameHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventMinigameHistoryResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getEventMinigameHistory.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/minigame/history`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventMinigameHistoryResponseFromJSON(jsonValue));
    }

    /**
     * シナイベミニゲームの履歴を返すAPI
     */
    async getEventMinigameHistory(requestParameters: GetEventMinigameHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventMinigameHistoryResponse> {
        const response = await this.getEventMinigameHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * シナイベミニゲームTOP画面表示用API
     */
    async getEventMinigameTopRaw(requestParameters: GetEventMinigameTopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventMinigameTopResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getEventMinigameTop.');
        }

        const queryParameters: any = {};

        if (requestParameters.playerCode !== undefined) {
            queryParameters['player_code'] = requestParameters.playerCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/minigame/top`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventMinigameTopResponseFromJSON(jsonValue));
    }

    /**
     * シナイベミニゲームTOP画面表示用API
     */
    async getEventMinigameTop(requestParameters: GetEventMinigameTopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventMinigameTopResponse> {
        const response = await this.getEventMinigameTopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのシナイベランキングデータを取得するPI。
     */
    async getEventRankingListRaw(requestParameters: GetEventRankingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventRankingListResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getEventRankingList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/ranking/list`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventRankingListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのシナイベランキングデータを取得するPI。
     */
    async getEventRankingList(requestParameters: GetEventRankingListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventRankingListResponse> {
        const response = await this.getEventRankingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのシナイベ進捗情報を取得するAPI。
     */
    async getEventStatusRaw(requestParameters: GetEventStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventStatusResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getEventStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/status`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventStatusResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのシナイベ進捗情報を取得するAPI。
     */
    async getEventStatus(requestParameters: GetEventStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventStatusResponse> {
        const response = await this.getEventStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * イベントストーリーステータス
     */
    async getEventStoryStatusRaw(requestParameters: GetEventStoryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventStoryListResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getEventStoryStatus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/story/status`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventStoryListResponseFromJSON(jsonValue));
    }

    /**
     * イベントストーリーステータス
     */
    async getEventStoryStatus(requestParameters: GetEventStoryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventStoryListResponse> {
        const response = await this.getEventStoryStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * フレンドフォローリスト取得API
     */
    async getFriendFollowListRaw(requestParameters: GetFriendFollowListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFriendFollowListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/follow/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFriendFollowListResponseFromJSON(jsonValue));
    }

    /**
     * フレンドフォローリスト取得API
     */
    async getFriendFollowList(requestParameters: GetFriendFollowListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFriendFollowListResponse> {
        const response = await this.getFriendFollowListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * フレンドフォロワーリスト取得API
     */
    async getFriendFollowerListRaw(requestParameters: GetFriendFollowerListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFriendFollowerListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/follower/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFriendFollowerListResponseFromJSON(jsonValue));
    }

    /**
     * フレンドフォロワーリスト取得API
     */
    async getFriendFollowerList(requestParameters: GetFriendFollowerListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFriendFollowerListResponse> {
        const response = await this.getFriendFollowerListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * フレンドいいね取得API
     */
    async getFriendLikeListRaw(requestParameters: GetFriendLikeListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFriendLikeListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/like/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFriendLikeListResponseFromJSON(jsonValue));
    }

    /**
     * フレンドいいね取得API
     */
    async getFriendLikeList(requestParameters: GetFriendLikeListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFriendLikeListResponse> {
        const response = await this.getFriendLikeListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * フレンドメッセージリスト取得API
     */
    async getFriendMessageListRaw(requestParameters: GetFriendMessageListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FriendMessageListResponse>> {
        if (requestParameters.messageKey === null || requestParameters.messageKey === undefined) {
            throw new runtime.RequiredError('messageKey','Required parameter requestParameters.messageKey was null or undefined when calling getFriendMessageList.');
        }

        const queryParameters: any = {};

        if (requestParameters.messageKey !== undefined) {
            queryParameters['message_key'] = requestParameters.messageKey;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/message/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FriendMessageListResponseFromJSON(jsonValue));
    }

    /**
     * フレンドメッセージリスト取得API
     */
    async getFriendMessageList(requestParameters: GetFriendMessageListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FriendMessageListResponse> {
        const response = await this.getFriendMessageListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * おすすめフレンドリスト取得API
     */
    async getFriendRecommendListRaw(requestParameters: GetFriendRecommendListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FriendRecommendListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/recommend/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FriendRecommendListResponseFromJSON(jsonValue));
    }

    /**
     * おすすめフレンドリスト取得API
     */
    async getFriendRecommendList(requestParameters: GetFriendRecommendListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FriendRecommendListResponse> {
        const response = await this.getFriendRecommendListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 他ユーザーステータスを取得するAPI。
     */
    async getFriendStatusRaw(requestParameters: GetFriendStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FriendStatusResponse>> {
        if (requestParameters.playerCode === null || requestParameters.playerCode === undefined) {
            throw new runtime.RequiredError('playerCode','Required parameter requestParameters.playerCode was null or undefined when calling getFriendStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.playerCode !== undefined) {
            queryParameters['player_code'] = requestParameters.playerCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FriendStatusResponseFromJSON(jsonValue));
    }

    /**
     * 他ユーザーステータスを取得するAPI。
     */
    async getFriendStatus(requestParameters: GetFriendStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FriendStatusResponse> {
        const response = await this.getFriendStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ガチャラインナップ一覧の取得
     */
    async getGachaLineupListRaw(requestParameters: GetGachaLineupListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGachaLineupListResponse>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getGachaLineupList.');
        }

        if (requestParameters.gachaCd === null || requestParameters.gachaCd === undefined) {
            throw new runtime.RequiredError('gachaCd','Required parameter requestParameters.gachaCd was null or undefined when calling getGachaLineupList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/gacha/lineup/{type}/{gacha_cd}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"gacha_cd"}}`, encodeURIComponent(String(requestParameters.gachaCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGachaLineupListResponseFromJSON(jsonValue));
    }

    /**
     * ガチャラインナップ一覧の取得
     */
    async getGachaLineupList(requestParameters: GetGachaLineupListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGachaLineupListResponse> {
        const response = await this.getGachaLineupListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ガチャ一覧の取得
     */
    async getGachaListRaw(requestParameters: GetGachaListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGachaListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/gacha/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGachaListResponseFromJSON(jsonValue));
    }

    /**
     * ガチャ一覧の取得
     */
    async getGachaList(requestParameters: GetGachaListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGachaListResponse> {
        const response = await this.getGachaListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの所持アイテムを取得する。
     */
    async getItemListRaw(requestParameters: GetItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ItemListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/item/item`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの所持アイテムを取得する。
     */
    async getItemList(requestParameters: GetItemListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ItemListResponse> {
        const response = await this.getItemListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ログインスタンプ情報の取得
     */
    async getLoginstampGetRaw(requestParameters: GetLoginstampGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLoginStampResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/loginstamp/get`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLoginStampResponseFromJSON(jsonValue));
    }

    /**
     * ログインスタンプ情報の取得
     */
    async getLoginstampGet(requestParameters: GetLoginstampGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLoginStampResponse> {
        const response = await this.getLoginstampGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ミニゲームの履歴を返すAPI
     */
    async getMinigameHistoryRaw(requestParameters: GetMinigameHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMinigameHistoryResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/minigame/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMinigameHistoryResponseFromJSON(jsonValue));
    }

    /**
     * ミニゲームの履歴を返すAPI
     */
    async getMinigameHistory(requestParameters: GetMinigameHistoryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMinigameHistoryResponse> {
        const response = await this.getMinigameHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ミニゲームTOP画面表示用API
     */
    async getMinigameTopRaw(requestParameters: GetMinigameTopRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMinigameTopResponse>> {
        const queryParameters: any = {};

        if (requestParameters.playerCode !== undefined) {
            queryParameters['player_code'] = requestParameters.playerCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/minigame/top`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMinigameTopResponseFromJSON(jsonValue));
    }

    /**
     * ミニゲームTOP画面表示用API
     */
    async getMinigameTop(requestParameters: GetMinigameTopRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMinigameTopResponse> {
        const response = await this.getMinigameTopRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * お知らせの取得
     */
    async getNewsListRaw(requestParameters: GetNewsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserNewsListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/news/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserNewsListResponseFromJSON(jsonValue));
    }

    /**
     * お知らせの取得
     */
    async getNewsList(requestParameters: GetNewsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserNewsListResponse> {
        const response = await this.getNewsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのパネルミッション終了イベント未受け取り報酬有無取得API。
     */
    async getPanelmissionBonusFinishedRaw(requestParameters: GetPanelmissionBonusFinishedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PanelmissionBonusFinishedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/panelmission/bonus/finished`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PanelmissionBonusFinishedResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのパネルミッション終了イベント未受け取り報酬有無取得API。
     */
    async getPanelmissionBonusFinished(requestParameters: GetPanelmissionBonusFinishedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PanelmissionBonusFinishedResponse> {
        const response = await this.getPanelmissionBonusFinishedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * シナリオイベントCDから関連するパネルミッションを取得するAPI。
     */
    async getPanelmissionEventPanelRaw(requestParameters: GetPanelmissionEventPanelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PanelmissionEventPanelResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling getPanelmissionEventPanel.');
        }

        const queryParameters: any = {};

        if (requestParameters.eventCd !== undefined) {
            queryParameters['event_cd'] = requestParameters.eventCd;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/panelmission/event/panel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PanelmissionEventPanelResponseFromJSON(jsonValue));
    }

    /**
     * シナリオイベントCDから関連するパネルミッションを取得するAPI。
     */
    async getPanelmissionEventPanel(requestParameters: GetPanelmissionEventPanelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PanelmissionEventPanelResponse> {
        const response = await this.getPanelmissionEventPanelRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのパネルミッション一覧を取得する。
     */
    async getPanelmissionListRaw(requestParameters: GetPanelmissionListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPanelmissionListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/panelmission/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPanelmissionListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのパネルミッション一覧を取得する。
     */
    async getPanelmissionList(requestParameters: GetPanelmissionListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPanelmissionListResponse> {
        const response = await this.getPanelmissionListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * パネルミッション通知を取得するAPI。
     */
    async getPanelmissionNoticeRaw(requestParameters: GetPanelmissionNoticeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PanelmissionNoticeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/panelmission/notice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PanelmissionNoticeResponseFromJSON(jsonValue));
    }

    /**
     * パネルミッション通知を取得するAPI。
     */
    async getPanelmissionNotice(requestParameters: GetPanelmissionNoticeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PanelmissionNoticeResponse> {
        const response = await this.getPanelmissionNoticeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのプレゼント受取履歴を取得する。
     */
    async getPresentHistoryRaw(requestParameters: GetPresentHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPresentHistoryResponse>> {
        if (requestParameters.presentType === null || requestParameters.presentType === undefined) {
            throw new runtime.RequiredError('presentType','Required parameter requestParameters.presentType was null or undefined when calling getPresentHistory.');
        }

        const queryParameters: any = {};

        if (requestParameters.presentType !== undefined) {
            queryParameters['present_type'] = requestParameters.presentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/present/history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPresentHistoryResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのプレゼント受取履歴を取得する。
     */
    async getPresentHistory(requestParameters: GetPresentHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPresentHistoryResponse> {
        const response = await this.getPresentHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの所持プレゼントアイテムを取得する。
     */
    async getPresentListRaw(requestParameters: GetPresentListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPresentListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/present/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPresentListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの所持プレゼントアイテムを取得する。
     */
    async getPresentList(requestParameters: GetPresentListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPresentListResponse> {
        const response = await this.getPresentListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別（重要な）お知らせの取得
     */
    async getPrivateNewsListRaw(requestParameters: GetPrivateNewsListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPrivateNewsListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/privatenews/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPrivateNewsListResponseFromJSON(jsonValue));
    }

    /**
     * 個別（重要な）お知らせの取得
     */
    async getPrivateNewsList(requestParameters: GetPrivateNewsListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPrivateNewsListResponse> {
        const response = await this.getPrivateNewsListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 現在行われているセット販売の情報を取得する  セット販売によっては、 * ユーザー登録後、X日以内にしか買えないセット販売 * 本編クリア後にしか買えないセット販売 があり、ユーザー毎に販売期間が変わる可能性があるため本APIも必要です。 
     */
    async getShopCombinedsaleListRaw(requestParameters: GetShopCombinedsaleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopCombinedsaleListResponse>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getShopCombinedsaleList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/shop/combinedsale/{type}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopCombinedsaleListResponseFromJSON(jsonValue));
    }

    /**
     * 現在行われているセット販売の情報を取得する  セット販売によっては、 * ユーザー登録後、X日以内にしか買えないセット販売 * 本編クリア後にしか買えないセット販売 があり、ユーザー毎に販売期間が変わる可能性があるため本APIも必要です。 
     */
    async getShopCombinedsaleList(requestParameters: GetShopCombinedsaleListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopCombinedsaleListResponse> {
        const response = await this.getShopCombinedsaleListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザ固有のショップアイテムのマスタ情報を返す 
     */
    async getShopItemListRaw(requestParameters: GetShopItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopItemListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/shop/item/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopItemListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザ固有のショップアイテムのマスタ情報を返す 
     */
    async getShopItemList(requestParameters: GetShopItemListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopItemListResponse> {
        const response = await this.getShopItemListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 購入上限が設定されたショップアイテム(セット販売以外)と購入ステータスを取得  1日購入数制限制限に関しての1日の幅は『00:00:00 ~ 23:59:59』として扱っている。 セット販売の購入上限も一緒に取得しているが、shop/combinedsale/list APIにも同様の情報が付加されている 
     */
    async getShopLimitItemListRaw(requestParameters: GetShopLimitItemListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopItemLimitResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/shop/item/limit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopItemLimitResponseFromJSON(jsonValue));
    }

    /**
     * 購入上限が設定されたショップアイテム(セット販売以外)と購入ステータスを取得  1日購入数制限制限に関しての1日の幅は『00:00:00 ~ 23:59:59』として扱っている。 セット販売の購入上限も一緒に取得しているが、shop/combinedsale/list APIにも同様の情報が付加されている 
     */
    async getShopLimitItemList(requestParameters: GetShopLimitItemListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopItemLimitResponse> {
        const response = await this.getShopLimitItemListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 販売することが可能な課金商品情報
     */
    async getShopProductListRaw(requestParameters: GetShopProductListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopProductListResponse>> {
        if (requestParameters.purchaseType === null || requestParameters.purchaseType === undefined) {
            throw new runtime.RequiredError('purchaseType','Required parameter requestParameters.purchaseType was null or undefined when calling getShopProductList.');
        }

        const queryParameters: any = {};

        if (requestParameters.purchaseType !== undefined) {
            queryParameters['purchase_type'] = requestParameters.purchaseType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/shop/product/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopProductListResponseFromJSON(jsonValue));
    }

    /**
     * 販売することが可能な課金商品情報
     */
    async getShopProductList(requestParameters: GetShopProductListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopProductListResponse> {
        const response = await this.getShopProductListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーが所持しているスペシャルストーリーのリストを返す
     */
    async getSpecialStoryListRaw(requestParameters: GetSpecialStoryListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserSpecialStoryListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/special_story/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserSpecialStoryListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーが所持しているスペシャルストーリーのリストを返す
     */
    async getSpecialStoryList(requestParameters: GetSpecialStoryListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserSpecialStoryListResponse> {
        const response = await this.getSpecialStoryListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの所持スチルを取得する。
     */
    async getStillListRaw(requestParameters: GetStillListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserStillListResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/still/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserStillListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの所持スチルを取得する。
     */
    async getStillList(requestParameters: GetStillListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserStillListResponse> {
        const response = await this.getStillListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * クッキーのセットが成功したかどうかのAPI。
     */
    async getStoryAuthRaw(requestParameters: GetStoryAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoryAuthResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/story/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoryAuthResponseFromJSON(jsonValue));
    }

    /**
     * クッキーのセットが成功したかどうかのAPI。
     */
    async getStoryAuth(requestParameters: GetStoryAuthRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoryAuthResponse> {
        const response = await this.getStoryAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのストーリーリストの情報取得するAPI。
     */
    async getStoryListRaw(requestParameters: GetStoryListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoryListResponse>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling getStoryList.');
        }

        if (requestParameters.charaCd === null || requestParameters.charaCd === undefined) {
            throw new runtime.RequiredError('charaCd','Required parameter requestParameters.charaCd was null or undefined when calling getStoryList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/story/list/{type}/{chara_cd}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"chara_cd"}}`, encodeURIComponent(String(requestParameters.charaCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoryListResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのストーリーリストの情報取得するAPI。
     */
    async getStoryList(requestParameters: GetStoryListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoryListResponse> {
        const response = await this.getStoryListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのストーリー進捗情報を取得するAPI。
     */
    async getStoryStatusRaw(requestParameters: GetStoryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoryStatusResponse>> {
        if (requestParameters.isActiveChara === null || requestParameters.isActiveChara === undefined) {
            throw new runtime.RequiredError('isActiveChara','Required parameter requestParameters.isActiveChara was null or undefined when calling getStoryStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.playerCode !== undefined) {
            queryParameters['player_code'] = requestParameters.playerCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/story/status/{is_active_chara}`.replace(`{${"is_active_chara"}}`, encodeURIComponent(String(requestParameters.isActiveChara))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoryStatusResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのストーリー進捗情報を取得するAPI。
     */
    async getStoryStatus(requestParameters: GetStoryStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoryStatusResponse> {
        const response = await this.getStoryStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アプリからの認証。カスタムトークンの取得
     */
    async getUserAppAuthRaw(requestParameters: GetUserAppAuthRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAuthAppAuthResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user_auth/app/auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthAppAuthResponseFromJSON(jsonValue));
    }

    /**
     * アプリからの認証。カスタムトークンの取得
     */
    async getUserAppAuth(requestParameters: GetUserAppAuthRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAuthAppAuthResponse> {
        const response = await this.getUserAppAuthRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * メニューのバッジを表示するためのAPI。
     */
    async getUserBadgeRaw(requestParameters: GetUserBadgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserBadgeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/badge`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserBadgeResponseFromJSON(jsonValue));
    }

    /**
     * メニューのバッジを表示するためのAPI。
     */
    async getUserBadge(requestParameters: GetUserBadgeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserBadgeResponse> {
        const response = await this.getUserBadgeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザに対して通知が必要なコレクション情報をキャラごとに取得する
     */
    async getUserCollectionBadgeRaw(requestParameters: GetUserCollectionBadgeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserCollectionBadgeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/collectionbadge`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserCollectionBadgeResponseFromJSON(jsonValue));
    }

    /**
     * ユーザに対して通知が必要なコレクション情報をキャラごとに取得する
     */
    async getUserCollectionBadge(requestParameters: GetUserCollectionBadgeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserCollectionBadgeResponse> {
        const response = await this.getUserCollectionBadgeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの初回利用履歴を取得する。
     */
    async getUserFirstUseRaw(requestParameters: GetUserFirstUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserFirstUseResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/tutorial/firstuse`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFirstUseResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの初回利用履歴を取得する。
     */
    async getUserFirstUse(requestParameters: GetUserFirstUseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserFirstUseResponse> {
        const response = await this.getUserFirstUseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * お客様コードを取得するAPI。
     */
    async getUserIdentifyCodeRaw(requestParameters: GetUserIdentifyCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserIdentifyCodeResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/identifycode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserIdentifyCodeResponseFromJSON(jsonValue));
    }

    /**
     * お客様コードを取得するAPI。
     */
    async getUserIdentifyCode(requestParameters: GetUserIdentifyCodeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserIdentifyCodeResponse> {
        const response = await this.getUserIdentifyCodeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * マイページ情報取得API
     */
    async getUserMypageRaw(requestParameters: GetUserMypageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMypageResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/mypage`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMypageResponseFromJSON(jsonValue));
    }

    /**
     * マイページ情報取得API
     */
    async getUserMypage(requestParameters: GetUserMypageRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMypageResponse> {
        const response = await this.getUserMypageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アカウント接続プロバイダーの取得
     */
    async getUserProviderRaw(requestParameters: GetUserProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAuthProviderResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user_auth/provider`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthProviderResponseFromJSON(jsonValue));
    }

    /**
     * アカウント接続プロバイダーの取得
     */
    async getUserProvider(requestParameters: GetUserProviderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAuthProviderResponse> {
        const response = await this.getUserProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アカウント接続用のアクセストークンを取得
     */
    async getUserProviderAccessTokenRaw(requestParameters: GetUserProviderAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAuthProviderAccessTokenResponse>> {
        if (requestParameters.userAuthProviderAccessTokenRequest === null || requestParameters.userAuthProviderAccessTokenRequest === undefined) {
            throw new runtime.RequiredError('userAuthProviderAccessTokenRequest','Required parameter requestParameters.userAuthProviderAccessTokenRequest was null or undefined when calling getUserProviderAccessToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user_auth/provider/access_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAuthProviderAccessTokenRequestToJSON(requestParameters.userAuthProviderAccessTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthProviderAccessTokenResponseFromJSON(jsonValue));
    }

    /**
     * アカウント接続用のアクセストークンを取得
     */
    async getUserProviderAccessToken(requestParameters: GetUserProviderAccessTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAuthProviderAccessTokenResponse> {
        const response = await this.getUserProviderAccessTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーステータスを取得するAPI。
     */
    async getUserStatusRaw(requestParameters: GetUserStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserStatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserStatusResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーステータスを取得するAPI。
     */
    async getUserStatus(requestParameters: GetUserStatusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserStatusResponse> {
        const response = await this.getUserStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーステータスを取得するAPIの軽量版。
     */
    async getUserStatusminRaw(requestParameters: GetUserStatusminRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserStatusMinResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/statusmin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserStatusMinResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーステータスを取得するAPIの軽量版。
     */
    async getUserStatusmin(requestParameters: GetUserStatusminRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserStatusMinResponse> {
        const response = await this.getUserStatusminRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アバター既読API
     */
    async patchAvatarReadRaw(requestParameters: PatchAvatarReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/avatar/read`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AvatarReadRequestToJSON(requestParameters.avatarReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アバター既読API
     */
    async patchAvatarRead(requestParameters: PatchAvatarReadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchAvatarReadRaw(requestParameters, initOverrides);
    }

    /**
     * カード既読API
     */
    async patchCardReadRaw(requestParameters: PatchCardReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/card/read`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CardReadRequestToJSON(requestParameters.cardReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * カード既読API
     */
    async patchCardRead(requestParameters: PatchCardReadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchCardReadRaw(requestParameters, initOverrides);
    }

    /**
     * コミュのキャラレベルアップ通知の日時更新を行う
     */
    async patchCommuCharaReadRaw(requestParameters: PatchCommuCharaReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.commuCharaReadRequest === null || requestParameters.commuCharaReadRequest === undefined) {
            throw new runtime.RequiredError('commuCharaReadRequest','Required parameter requestParameters.commuCharaReadRequest was null or undefined when calling patchCommuCharaRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/commu/chara/read`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CommuCharaReadRequestToJSON(requestParameters.commuCharaReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュのキャラレベルアップ通知の日時更新を行う
     */
    async patchCommuCharaRead(requestParameters: PatchCommuCharaReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchCommuCharaReadRaw(requestParameters, initOverrides);
    }

    /**
     * メイン部隊（デッキ）設定API
     */
    async patchDeckSetMainRaw(requestParameters: PatchDeckSetMainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deckSetMainRequest === null || requestParameters.deckSetMainRequest === undefined) {
            throw new runtime.RequiredError('deckSetMainRequest','Required parameter requestParameters.deckSetMainRequest was null or undefined when calling patchDeckSetMain.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/deck/main`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeckSetMainRequestToJSON(requestParameters.deckSetMainRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メイン部隊（デッキ）設定API
     */
    async patchDeckSetMain(requestParameters: PatchDeckSetMainRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchDeckSetMainRaw(requestParameters, initOverrides);
    }

    /**
     * 部隊（デッキ）名称設定API
     */
    async patchDeckSetNameRaw(requestParameters: PatchDeckSetNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deckSetNameRequest === null || requestParameters.deckSetNameRequest === undefined) {
            throw new runtime.RequiredError('deckSetNameRequest','Required parameter requestParameters.deckSetNameRequest was null or undefined when calling patchDeckSetName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/deck/name`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: DeckSetNameRequestToJSON(requestParameters.deckSetNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 部隊（デッキ）名称設定API
     */
    async patchDeckSetName(requestParameters: PatchDeckSetNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchDeckSetNameRaw(requestParameters, initOverrides);
    }

    /**
     * いいね既読API
     */
    async patchFriendLikeReadRaw(requestParameters: PatchFriendLikeReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/like/read`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * いいね既読API
     */
    async patchFriendLikeRead(requestParameters: PatchFriendLikeReadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchFriendLikeReadRaw(requestParameters, initOverrides);
    }

    /**
     * スペシャルストーリーを既読状態にする
     */
    async patchSpecialStoryReadRaw(requestParameters: PatchSpecialStoryReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.specialStoryReadRequest === null || requestParameters.specialStoryReadRequest === undefined) {
            throw new runtime.RequiredError('specialStoryReadRequest','Required parameter requestParameters.specialStoryReadRequest was null or undefined when calling patchSpecialStoryRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/special_story/read`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialStoryReadRequestToJSON(requestParameters.specialStoryReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * スペシャルストーリーを既読状態にする
     */
    async patchSpecialStoryRead(requestParameters: PatchSpecialStoryReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchSpecialStoryReadRaw(requestParameters, initOverrides);
    }

    /**
     * スペシャルストーリーをアンロックする
     */
    async patchSpecialStoryUnlockRaw(requestParameters: PatchSpecialStoryUnlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.specialStoryUnlockRequest === null || requestParameters.specialStoryUnlockRequest === undefined) {
            throw new runtime.RequiredError('specialStoryUnlockRequest','Required parameter requestParameters.specialStoryUnlockRequest was null or undefined when calling patchSpecialStoryUnlock.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/special_story/unlock`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SpecialStoryUnlockRequestToJSON(requestParameters.specialStoryUnlockRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * スペシャルストーリーをアンロックする
     */
    async patchSpecialStoryUnlock(requestParameters: PatchSpecialStoryUnlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchSpecialStoryUnlockRaw(requestParameters, initOverrides);
    }

    /**
     * スチル既読API
     */
    async patchStillReadRaw(requestParameters: PatchStillReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/still/read`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: StillReadRequestToJSON(requestParameters.stillReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * スチル既読API
     */
    async patchStillRead(requestParameters: PatchStillReadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchStillReadRaw(requestParameters, initOverrides);
    }

    /**
     * ストーリー進行状況を初期化するAPI。
     */
    async patchStoryInitRaw(requestParameters: PatchStoryInitOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patchStoryInitRequest === null || requestParameters.patchStoryInitRequest === undefined) {
            throw new runtime.RequiredError('patchStoryInitRequest','Required parameter requestParameters.patchStoryInitRequest was null or undefined when calling patchStoryInit.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/story/init`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchStoryInitRequestToJSON(requestParameters.patchStoryInitRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ストーリー進行状況を初期化するAPI。
     */
    async patchStoryInit(requestParameters: PatchStoryInitOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchStoryInitRaw(requestParameters, initOverrides);
    }

    /**
     * ユーザーの初回利用履歴を登録する。
     */
    async patchUserFirstUseRaw(requestParameters: PatchUserFirstUseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/tutorial/firstuse`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UserFirstUseRequestToJSON(requestParameters.userFirstUseRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザーの初回利用履歴を登録する。
     */
    async patchUserFirstUse(requestParameters: PatchUserFirstUseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchUserFirstUseRaw(requestParameters, initOverrides);
    }

    /**
     * アバターお着替え情報リスト更新API
     */
    async postAvatarEquipSaveRaw(requestParameters: PostAvatarEquipSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/avatar/equip/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AvatarEquipSaveRequestToJSON(requestParameters.avatarEquipSaveRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アバターお着替え情報リスト更新API
     */
    async postAvatarEquipSave(requestParameters: PostAvatarEquipSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postAvatarEquipSaveRaw(requestParameters, initOverrides);
    }

    /**
     * アバター配置更新API
     */
    async postAvatarSaveRaw(requestParameters: PostAvatarSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/avatar/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AvatarSaveRequestToJSON(requestParameters.avatarSaveRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * アバター配置更新API
     */
    async postAvatarSave(requestParameters: PostAvatarSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postAvatarSaveRaw(requestParameters, initOverrides);
    }

    /**
     * マイページカード設定API
     */
    async postCardSaveRaw(requestParameters: PostCardSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/card/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CardSaveRequestToJSON(requestParameters.cardSaveRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * マイページカード設定API
     */
    async postCardSave(requestParameters: PostCardSaveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postCardSaveRaw(requestParameters, initOverrides);
    }

    /**
     * 本編キャラを選択する
     */
    async postCharaSaveRaw(requestParameters: PostCharaSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CharaSaveResponse>> {
        if (requestParameters.charaSaveRequest === null || requestParameters.charaSaveRequest === undefined) {
            throw new runtime.RequiredError('charaSaveRequest','Required parameter requestParameters.charaSaveRequest was null or undefined when calling postCharaSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/chara/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CharaSaveRequestToJSON(requestParameters.charaSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CharaSaveResponseFromJSON(jsonValue));
    }

    /**
     * 本編キャラを選択する
     */
    async postCharaSave(requestParameters: PostCharaSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CharaSaveResponse> {
        const response = await this.postCharaSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュの報酬を付与する
     */
    async postCommuBonusRaw(requestParameters: PostCommuBonusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.commuBonusRequest === null || requestParameters.commuBonusRequest === undefined) {
            throw new runtime.RequiredError('commuBonusRequest','Required parameter requestParameters.commuBonusRequest was null or undefined when calling postCommuBonus.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/commu/bonus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommuBonusRequestToJSON(requestParameters.commuBonusRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * コミュの報酬を付与する
     */
    async postCommuBonus(requestParameters: PostCommuBonusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postCommuBonusRaw(requestParameters, initOverrides);
    }

    /**
     * コミュの特定キャラのコミュレベルを限界突破する
     */
    async postCommuCharaLimitbreakRaw(requestParameters: PostCommuCharaLimitbreakRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommuCharaLimitbreakResponse>> {
        if (requestParameters.commuCharaLimitbreakRequest === null || requestParameters.commuCharaLimitbreakRequest === undefined) {
            throw new runtime.RequiredError('commuCharaLimitbreakRequest','Required parameter requestParameters.commuCharaLimitbreakRequest was null or undefined when calling postCommuCharaLimitbreak.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/commu/chara/limitbreak`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CommuCharaLimitbreakRequestToJSON(requestParameters.commuCharaLimitbreakRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommuCharaLimitbreakResponseFromJSON(jsonValue));
    }

    /**
     * コミュの特定キャラのコミュレベルを限界突破する
     */
    async postCommuCharaLimitbreak(requestParameters: PostCommuCharaLimitbreakRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommuCharaLimitbreakResponse> {
        const response = await this.postCommuCharaLimitbreakRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * おすすめ部隊（デッキ）取得API
     */
    async postDeckRecommendRaw(requestParameters: PostDeckRecommendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeckRecommendResponse>> {
        if (requestParameters.deckRecommendRequest === null || requestParameters.deckRecommendRequest === undefined) {
            throw new runtime.RequiredError('deckRecommendRequest','Required parameter requestParameters.deckRecommendRequest was null or undefined when calling postDeckRecommend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/deck/recommend`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: DeckRecommendRequestToJSON(requestParameters.deckRecommendRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeckRecommendResponseFromJSON(jsonValue));
    }

    /**
     * おすすめ部隊（デッキ）取得API
     */
    async postDeckRecommend(requestParameters: PostDeckRecommendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeckRecommendResponse> {
        const response = await this.postDeckRecommendRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * エントリーフラッシュの再生情報を保存する
     */
    async postEntryflashReadRaw(requestParameters: PostEntryflashReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/entryflash/read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EntryflashReadRequestToJSON(requestParameters.entryflashReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * エントリーフラッシュの再生情報を保存する
     */
    async postEntryflashRead(requestParameters: PostEntryflashReadRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postEntryflashReadRaw(requestParameters, initOverrides);
    }

    /**
     * ユーザーのシナイベ特典を付与するAPI。
     */
    async postEventBonusReceiveRaw(requestParameters: PostEventBonusReceiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventBonusReceiveResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling postEventBonusReceive.');
        }

        if (requestParameters.userEventBonusReceiveRequest === null || requestParameters.userEventBonusReceiveRequest === undefined) {
            throw new runtime.RequiredError('userEventBonusReceiveRequest','Required parameter requestParameters.userEventBonusReceiveRequest was null or undefined when calling postEventBonusReceive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/bonus/receive`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserEventBonusReceiveRequestToJSON(requestParameters.userEventBonusReceiveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventBonusReceiveResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのシナイベ特典を付与するAPI。
     */
    async postEventBonusReceive(requestParameters: PostEventBonusReceiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventBonusReceiveResponse> {
        const response = await this.postEventBonusReceiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * イベントキャラの変更
     */
    async postEventCharaChangeRaw(requestParameters: PostEventCharaChangeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventCharaChangeResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling postEventCharaChange.');
        }

        if (requestParameters.eventCharaChangeRequest === null || requestParameters.eventCharaChangeRequest === undefined) {
            throw new runtime.RequiredError('eventCharaChangeRequest','Required parameter requestParameters.eventCharaChangeRequest was null or undefined when calling postEventCharaChange.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/chara/change`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventCharaChangeRequestToJSON(requestParameters.eventCharaChangeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventCharaChangeResponseFromJSON(jsonValue));
    }

    /**
     * イベントキャラの変更
     */
    async postEventCharaChange(requestParameters: PostEventCharaChangeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventCharaChangeResponse> {
        const response = await this.postEventCharaChangeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * イベントへエントリー
     */
    async postEventEntryRaw(requestParameters: PostEventEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling postEventEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/entry`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * イベントへエントリー
     */
    async postEventEntry(requestParameters: PostEventEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postEventEntryRaw(requestParameters, initOverrides);
    }

    /**
     * シナイベミニゲームの実行結果を保存し、出力する
     */
    async postEventMigameExecuteRaw(requestParameters: PostEventMigameExecuteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventMinigameExecuteResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling postEventMigameExecute.');
        }

        if (requestParameters.userEventMinigameExecuteRequest === null || requestParameters.userEventMinigameExecuteRequest === undefined) {
            throw new runtime.RequiredError('userEventMinigameExecuteRequest','Required parameter requestParameters.userEventMinigameExecuteRequest was null or undefined when calling postEventMigameExecute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/minigame/execute`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserEventMinigameExecuteRequestToJSON(requestParameters.userEventMinigameExecuteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventMinigameExecuteResponseFromJSON(jsonValue));
    }

    /**
     * シナイベミニゲームの実行結果を保存し、出力する
     */
    async postEventMigameExecute(requestParameters: PostEventMigameExecuteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventMinigameExecuteResponse> {
        const response = await this.postEventMigameExecuteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * イベントミッション突破処理
     */
    async postEventMissionCompleteRaw(requestParameters: PostEventMissionCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventMissionCompleteResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling postEventMissionComplete.');
        }

        if (requestParameters.eventMissionCompleteRequest === null || requestParameters.eventMissionCompleteRequest === undefined) {
            throw new runtime.RequiredError('eventMissionCompleteRequest','Required parameter requestParameters.eventMissionCompleteRequest was null or undefined when calling postEventMissionComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/mission/complete`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventMissionCompleteRequestToJSON(requestParameters.eventMissionCompleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventMissionCompleteResponseFromJSON(jsonValue));
    }

    /**
     * イベントミッション突破処理
     */
    async postEventMissionComplete(requestParameters: PostEventMissionCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventMissionCompleteResponse> {
        const response = await this.postEventMissionCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 読了処理
     */
    async postEventStoryCompleteRaw(requestParameters: PostEventStoryCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserEventStoryCompleteResponse>> {
        if (requestParameters.eventCd === null || requestParameters.eventCd === undefined) {
            throw new runtime.RequiredError('eventCd','Required parameter requestParameters.eventCd was null or undefined when calling postEventStoryComplete.');
        }

        if (requestParameters.eventStoryCompleteRequest === null || requestParameters.eventStoryCompleteRequest === undefined) {
            throw new runtime.RequiredError('eventStoryCompleteRequest','Required parameter requestParameters.eventStoryCompleteRequest was null or undefined when calling postEventStoryComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/event/{event_cd}/story/complete`.replace(`{${"event_cd"}}`, encodeURIComponent(String(requestParameters.eventCd))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EventStoryCompleteRequestToJSON(requestParameters.eventStoryCompleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserEventStoryCompleteResponseFromJSON(jsonValue));
    }

    /**
     * 読了処理
     */
    async postEventStoryComplete(requestParameters: PostEventStoryCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserEventStoryCompleteResponse> {
        const response = await this.postEventStoryCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * フレンドフォロー解除API
     */
    async postFriendCancelRaw(requestParameters: PostFriendCancelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.friendCancelRequest === null || requestParameters.friendCancelRequest === undefined) {
            throw new runtime.RequiredError('friendCancelRequest','Required parameter requestParameters.friendCancelRequest was null or undefined when calling postFriendCancel.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/cancel`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FriendCancelRequestToJSON(requestParameters.friendCancelRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * フレンドフォロー解除API
     */
    async postFriendCancel(requestParameters: PostFriendCancelRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFriendCancelRaw(requestParameters, initOverrides);
    }

    /**
     * フレンドフォローワー削除API
     */
    async postFriendDeleteRaw(requestParameters: PostFriendDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.friendDeleteRequest === null || requestParameters.friendDeleteRequest === undefined) {
            throw new runtime.RequiredError('friendDeleteRequest','Required parameter requestParameters.friendDeleteRequest was null or undefined when calling postFriendDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FriendDeleteRequestToJSON(requestParameters.friendDeleteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * フレンドフォローワー削除API
     */
    async postFriendDelete(requestParameters: PostFriendDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFriendDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * フレンドフォローAPI
     */
    async postFriendFollowRaw(requestParameters: PostFriendFollowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.friendFollowRequest === null || requestParameters.friendFollowRequest === undefined) {
            throw new runtime.RequiredError('friendFollowRequest','Required parameter requestParameters.friendFollowRequest was null or undefined when calling postFriendFollow.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/follow`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FriendFollowRequestToJSON(requestParameters.friendFollowRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * フレンドフォローAPI
     */
    async postFriendFollow(requestParameters: PostFriendFollowRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFriendFollowRaw(requestParameters, initOverrides);
    }

    /**
     * いいね受け取りAPI
     */
    async postFriendLikeReceiveRaw(requestParameters: PostFriendLikeReceiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FriendLikeReceiveResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/like/receive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FriendLikeReceiveResponseFromJSON(jsonValue));
    }

    /**
     * いいね受け取りAPI
     */
    async postFriendLikeReceive(requestParameters: PostFriendLikeReceiveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FriendLikeReceiveResponse> {
        const response = await this.postFriendLikeReceiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * いいね送信API
     */
    async postFriendLikeSendRaw(requestParameters: PostFriendLikeSendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FriendLikeSendResponse>> {
        if (requestParameters.friendLikeSendRequest === null || requestParameters.friendLikeSendRequest === undefined) {
            throw new runtime.RequiredError('friendLikeSendRequest','Required parameter requestParameters.friendLikeSendRequest was null or undefined when calling postFriendLikeSend.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/like/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FriendLikeSendRequestToJSON(requestParameters.friendLikeSendRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FriendLikeSendResponseFromJSON(jsonValue));
    }

    /**
     * いいね送信API
     */
    async postFriendLikeSend(requestParameters: PostFriendLikeSendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FriendLikeSendResponse> {
        const response = await this.postFriendLikeSendRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * メッセージ削除API
     */
    async postFriendMessageDeleteRaw(requestParameters: PostFriendMessageDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.friendMessageDeleteRequest === null || requestParameters.friendMessageDeleteRequest === undefined) {
            throw new runtime.RequiredError('friendMessageDeleteRequest','Required parameter requestParameters.friendMessageDeleteRequest was null or undefined when calling postFriendMessageDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/message/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FriendMessageDeleteRequestToJSON(requestParameters.friendMessageDeleteRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メッセージ削除API
     */
    async postFriendMessageDelete(requestParameters: PostFriendMessageDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFriendMessageDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * メッセージ送信API
     */
    async postFriendMessageSendRaw(requestParameters: PostFriendMessageSendRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/friend/message/send`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FriendMessageSendRequestToJSON(requestParameters.friendMessageSendRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メッセージ送信API
     */
    async postFriendMessageSend(requestParameters: PostFriendMessageSendRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postFriendMessageSendRaw(requestParameters, initOverrides);
    }

    /**
     * 引き直しガチャ決定
     */
    async postGachaDecideRaw(requestParameters: PostGachaDecideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGachaDecideResponse>> {
        if (requestParameters.gachaDecideRequest === null || requestParameters.gachaDecideRequest === undefined) {
            throw new runtime.RequiredError('gachaDecideRequest','Required parameter requestParameters.gachaDecideRequest was null or undefined when calling postGachaDecide.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/gacha/decide`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GachaDecideRequestToJSON(requestParameters.gachaDecideRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGachaDecideResponseFromJSON(jsonValue));
    }

    /**
     * 引き直しガチャ決定
     */
    async postGachaDecide(requestParameters: PostGachaDecideRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGachaDecideResponse> {
        const response = await this.postGachaDecideRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ガチャ実行
     */
    async postGachaExecRaw(requestParameters: PostGachaExecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGachaExecResponse>> {
        if (requestParameters.gachaExecRequest === null || requestParameters.gachaExecRequest === undefined) {
            throw new runtime.RequiredError('gachaExecRequest','Required parameter requestParameters.gachaExecRequest was null or undefined when calling postGachaExec.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/gacha/exec`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GachaExecRequestToJSON(requestParameters.gachaExecRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGachaExecResponseFromJSON(jsonValue));
    }

    /**
     * ガチャ実行
     */
    async postGachaExec(requestParameters: PostGachaExecRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGachaExecResponse> {
        const response = await this.postGachaExecRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーの所持アイテムを消費する。
     */
    async postItemConsumeRaw(requestParameters: PostItemConsumeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ItemConsumeResponse>> {
        if (requestParameters.itemConsumeRequest === null || requestParameters.itemConsumeRequest === undefined) {
            throw new runtime.RequiredError('itemConsumeRequest','Required parameter requestParameters.itemConsumeRequest was null or undefined when calling postItemConsume.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/item/item`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ItemConsumeRequestToJSON(requestParameters.itemConsumeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemConsumeResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーの所持アイテムを消費する。
     */
    async postItemConsume(requestParameters: PostItemConsumeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ItemConsumeResponse> {
        const response = await this.postItemConsumeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ログインボーナスを付与する
     */
    async postLoginstampSaveRaw(requestParameters: PostLoginstampSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.postLoginStampRequest === null || requestParameters.postLoginStampRequest === undefined) {
            throw new runtime.RequiredError('postLoginStampRequest','Required parameter requestParameters.postLoginStampRequest was null or undefined when calling postLoginstampSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/loginstamp/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLoginStampRequestToJSON(requestParameters.postLoginStampRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ログインボーナスを付与する
     */
    async postLoginstampSave(requestParameters: PostLoginstampSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postLoginstampSaveRaw(requestParameters, initOverrides);
    }

    /**
     * ミニゲームの実行結果を保存し、出力する
     */
    async postMigameExecuteRaw(requestParameters: PostMigameExecuteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserMinigameExecuteResponse>> {
        if (requestParameters.userMinigameExecuteRequest === null || requestParameters.userMinigameExecuteRequest === undefined) {
            throw new runtime.RequiredError('userMinigameExecuteRequest','Required parameter requestParameters.userMinigameExecuteRequest was null or undefined when calling postMigameExecute.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/minigame/execute`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserMinigameExecuteRequestToJSON(requestParameters.userMinigameExecuteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserMinigameExecuteResponseFromJSON(jsonValue));
    }

    /**
     * ミニゲームの実行結果を保存し、出力する
     */
    async postMigameExecute(requestParameters: PostMigameExecuteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserMinigameExecuteResponse> {
        const response = await this.postMigameExecuteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ミッションを完了するAPI。
     */
    async postMissionCompleteRaw(requestParameters: PostMissionCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MissionCompleteResponse>> {
        if (requestParameters.missionCompleteRequest === null || requestParameters.missionCompleteRequest === undefined) {
            throw new runtime.RequiredError('missionCompleteRequest','Required parameter requestParameters.missionCompleteRequest was null or undefined when calling postMissionComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/mission/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MissionCompleteRequestToJSON(requestParameters.missionCompleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MissionCompleteResponseFromJSON(jsonValue));
    }

    /**
     * ミッションを完了するAPI。
     */
    async postMissionComplete(requestParameters: PostMissionCompleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MissionCompleteResponse> {
        const response = await this.postMissionCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * お知らせの既読
     */
    async postNewsReadRaw(requestParameters: PostNewsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserNewsReadResponse>> {
        if (requestParameters.userNewsReadRequest === null || requestParameters.userNewsReadRequest === undefined) {
            throw new runtime.RequiredError('userNewsReadRequest','Required parameter requestParameters.userNewsReadRequest was null or undefined when calling postNewsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/news/read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserNewsReadRequestToJSON(requestParameters.userNewsReadRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserNewsReadResponseFromJSON(jsonValue));
    }

    /**
     * お知らせの既読
     */
    async postNewsRead(requestParameters: PostNewsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserNewsReadResponse> {
        const response = await this.postNewsReadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのパネルミッション特典を付与するAPI。
     */
    async postPanelmissionBonusReceiveRaw(requestParameters: PostPanelmissionBonusReceiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userPanelmissionBonusReceiveRequest === null || requestParameters.userPanelmissionBonusReceiveRequest === undefined) {
            throw new runtime.RequiredError('userPanelmissionBonusReceiveRequest','Required parameter requestParameters.userPanelmissionBonusReceiveRequest was null or undefined when calling postPanelmissionBonusReceive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/panelmission/bonus/receive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPanelmissionBonusReceiveRequestToJSON(requestParameters.userPanelmissionBonusReceiveRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザーのパネルミッション特典を付与するAPI。
     */
    async postPanelmissionBonusReceive(requestParameters: PostPanelmissionBonusReceiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postPanelmissionBonusReceiveRaw(requestParameters, initOverrides);
    }

    /**
     * ユーザーのプレゼントを削除する
     */
    async postPresentDeleteRaw(requestParameters: PostPresentDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPresentDeleteResponse>> {
        if (requestParameters.userPresentDeleteRequest === null || requestParameters.userPresentDeleteRequest === undefined) {
            throw new runtime.RequiredError('userPresentDeleteRequest','Required parameter requestParameters.userPresentDeleteRequest was null or undefined when calling postPresentDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/present/delete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPresentDeleteRequestToJSON(requestParameters.userPresentDeleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPresentDeleteResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのプレゼントを削除する
     */
    async postPresentDelete(requestParameters: PostPresentDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPresentDeleteResponse> {
        const response = await this.postPresentDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ユーザーのプレゼントを受け取る
     */
    async postPresentReceiveRaw(requestParameters: PostPresentReceiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserPresentReceiveResponse>> {
        if (requestParameters.userPresentReceiveRequest === null || requestParameters.userPresentReceiveRequest === undefined) {
            throw new runtime.RequiredError('userPresentReceiveRequest','Required parameter requestParameters.userPresentReceiveRequest was null or undefined when calling postPresentReceive.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/present/receive`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPresentReceiveRequestToJSON(requestParameters.userPresentReceiveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPresentReceiveResponseFromJSON(jsonValue));
    }

    /**
     * ユーザーのプレゼントを受け取る
     */
    async postPresentReceive(requestParameters: PostPresentReceiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserPresentReceiveResponse> {
        const response = await this.postPresentReceiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 個別お知らせの既読
     */
    async postPrivateNewsReadRaw(requestParameters: PostPrivateNewsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userPrivateNewsReadRequest === null || requestParameters.userPrivateNewsReadRequest === undefined) {
            throw new runtime.RequiredError('userPrivateNewsReadRequest','Required parameter requestParameters.userPrivateNewsReadRequest was null or undefined when calling postPrivateNewsRead.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/privatenews/read`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserPrivateNewsReadRequestToJSON(requestParameters.userPrivateNewsReadRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 個別お知らせの既読
     */
    async postPrivateNewsRead(requestParameters: PostPrivateNewsReadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postPrivateNewsReadRaw(requestParameters, initOverrides);
    }

    /**
     * シリアルコード登録API
     */
    async postSerialCodeSaveRaw(requestParameters: PostSerialCodeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SerialCodeSaveResponse>> {
        if (requestParameters.serialCodeSaveRequest === null || requestParameters.serialCodeSaveRequest === undefined) {
            throw new runtime.RequiredError('serialCodeSaveRequest','Required parameter requestParameters.serialCodeSaveRequest was null or undefined when calling postSerialCodeSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/serialcode/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SerialCodeSaveRequestToJSON(requestParameters.serialCodeSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SerialCodeSaveResponseFromJSON(jsonValue));
    }

    /**
     * シリアルコード登録API
     */
    async postSerialCodeSave(requestParameters: PostSerialCodeSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SerialCodeSaveResponse> {
        const response = await this.postSerialCodeSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ショップアイテムを購入する 
     */
    async postShopItemBuyRaw(requestParameters: PostShopItemBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopItemBuyResponse>> {
        if (requestParameters.shopItemBuyRequest === null || requestParameters.shopItemBuyRequest === undefined) {
            throw new runtime.RequiredError('shopItemBuyRequest','Required parameter requestParameters.shopItemBuyRequest was null or undefined when calling postShopItemBuy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/shop/item/buy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShopItemBuyRequestToJSON(requestParameters.shopItemBuyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopItemBuyResponseFromJSON(jsonValue));
    }

    /**
     * ショップアイテムを購入する 
     */
    async postShopItemBuy(requestParameters: PostShopItemBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopItemBuyResponse> {
        const response = await this.postShopItemBuyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 課金商品の購入  レシート情報はUnityの[レシート情報](https://docs.unity3d.com/ja/2021.3/Manual/UnityIAPPurchaseReceipts.html)をそのまま送信 
     */
    async postShopProductBuyRaw(requestParameters: PostShopProductBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopProductBuyResponse>> {
        if (requestParameters.shopProductBuyRequest === null || requestParameters.shopProductBuyRequest === undefined) {
            throw new runtime.RequiredError('shopProductBuyRequest','Required parameter requestParameters.shopProductBuyRequest was null or undefined when calling postShopProductBuy.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/shop/product/buy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ShopProductBuyRequestToJSON(requestParameters.shopProductBuyRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopProductBuyResponseFromJSON(jsonValue));
    }

    /**
     * 課金商品の購入  レシート情報はUnityの[レシート情報](https://docs.unity3d.com/ja/2021.3/Manual/UnityIAPPurchaseReceipts.html)をそのまま送信 
     */
    async postShopProductBuy(requestParameters: PostShopProductBuyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopProductBuyResponse> {
        const response = await this.postShopProductBuyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ストーリーの読了するAPI。
     */
    async postStoryCompleteRaw(requestParameters: PostStoryCompleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoryCompleteResponse>> {
        if (requestParameters.postStoryCompleteRequest === null || requestParameters.postStoryCompleteRequest === undefined) {
            throw new runtime.RequiredError('postStoryCompleteRequest','Required parameter requestParameters.postStoryCompleteRequest was null or undefined when calling postStoryComplete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/story/complete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostStoryCompleteRequestToJSON(requestParameters.postStoryCompleteRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoryCompleteResponseFromJSON(jsonValue));
    }

    /**
     * ストーリーの読了するAPI。
     */
    async postStoryComplete(requestParameters: PostStoryCompleteOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoryCompleteResponse> {
        const response = await this.postStoryCompleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * チュートリアルのステータスを変更する
     */
    async postTutorialSaveRaw(requestParameters: PostTutorialSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TutorialSaveResponse>> {
        if (requestParameters.tutorialSaveRequest === null || requestParameters.tutorialSaveRequest === undefined) {
            throw new runtime.RequiredError('tutorialSaveRequest','Required parameter requestParameters.tutorialSaveRequest was null or undefined when calling postTutorialSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/tutorial/save`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TutorialSaveRequestToJSON(requestParameters.tutorialSaveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TutorialSaveResponseFromJSON(jsonValue));
    }

    /**
     * チュートリアルのステータスを変更する
     */
    async postTutorialSave(requestParameters: PostTutorialSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TutorialSaveResponse> {
        const response = await this.postTutorialSaveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * gameTokenからの認証。カスタムトークンの取得 401エラーの場合は、gameTokenが不正なためローカル端末から削除を行うこと 
     */
    async postUserAppGameTokenRaw(requestParameters: PostUserAppGameTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAuthAppGameTokenResponse>> {
        if (requestParameters.userAuthAppGameTokenRequest === null || requestParameters.userAuthAppGameTokenRequest === undefined) {
            throw new runtime.RequiredError('userAuthAppGameTokenRequest','Required parameter requestParameters.userAuthAppGameTokenRequest was null or undefined when calling postUserAppGameToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        const response = await this.request({
            path: `/user/user_auth/app/game_token`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAuthAppGameTokenRequestToJSON(requestParameters.userAuthAppGameTokenRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthAppGameTokenResponseFromJSON(jsonValue));
    }

    /**
     * gameTokenからの認証。カスタムトークンの取得 401エラーの場合は、gameTokenが不正なためローカル端末から削除を行うこと 
     */
    async postUserAppGameToken(requestParameters: PostUserAppGameTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAuthAppGameTokenResponse> {
        const response = await this.postUserAppGameTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ゲーム開始時のAPI。
     */
    async postUserGamestartRaw(requestParameters: PostUserGamestartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGameStartResponse>> {
        if (requestParameters.userGameStartRequest === null || requestParameters.userGameStartRequest === undefined) {
            throw new runtime.RequiredError('userGameStartRequest','Required parameter requestParameters.userGameStartRequest was null or undefined when calling postUserGamestart.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/gamestart`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserGameStartRequestToJSON(requestParameters.userGameStartRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGameStartResponseFromJSON(jsonValue));
    }

    /**
     * ゲーム開始時のAPI。
     */
    async postUserGamestart(requestParameters: PostUserGamestartRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGameStartResponse> {
        const response = await this.postUserGamestartRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 自己紹介を保存する
     */
    async postUserIntroductionSaveRaw(requestParameters: PostUserIntroductionSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSaveSelfIntroductionRequest === null || requestParameters.userSaveSelfIntroductionRequest === undefined) {
            throw new runtime.RequiredError('userSaveSelfIntroductionRequest','Required parameter requestParameters.userSaveSelfIntroductionRequest was null or undefined when calling postUserIntroductionSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/introduction`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSaveSelfIntroductionRequestToJSON(requestParameters.userSaveSelfIntroductionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 自己紹介を保存する
     */
    async postUserIntroductionSave(requestParameters: PostUserIntroductionSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUserIntroductionSaveRaw(requestParameters, initOverrides);
    }

    /**
     * アカウント接続プロバイダーの接続解除
     */
    async postUserProviderUnlinkRaw(requestParameters: PostUserProviderUnlinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAuthProviderUnlinkResponse>> {
        if (requestParameters.userAuthProviderUnlinkRequest === null || requestParameters.userAuthProviderUnlinkRequest === undefined) {
            throw new runtime.RequiredError('userAuthProviderUnlinkRequest','Required parameter requestParameters.userAuthProviderUnlinkRequest was null or undefined when calling postUserProviderUnlink.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user_auth/provider/unlink`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAuthProviderUnlinkRequestToJSON(requestParameters.userAuthProviderUnlinkRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAuthProviderUnlinkResponseFromJSON(jsonValue));
    }

    /**
     * アカウント接続プロバイダーの接続解除
     */
    async postUserProviderUnlink(requestParameters: PostUserProviderUnlinkRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAuthProviderUnlinkResponse> {
        const response = await this.postUserProviderUnlinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 部隊（デッキ）一括設定API
     */
    async putDeckSaveRaw(requestParameters: PutDeckSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deckSaveRequest === null || requestParameters.deckSaveRequest === undefined) {
            throw new runtime.RequiredError('deckSaveRequest','Required parameter requestParameters.deckSaveRequest was null or undefined when calling putDeckSave.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/deck/save`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DeckSaveRequestToJSON(requestParameters.deckSaveRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 部隊（デッキ）一括設定API
     */
    async putDeckSave(requestParameters: PutDeckSaveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putDeckSaveRaw(requestParameters, initOverrides);
    }

    /**
     * 誕生日を保存する
     */
    async saveUserBirthdayRaw(requestParameters: SaveUserBirthdayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userSaveBirthdayRequest === null || requestParameters.userSaveBirthdayRequest === undefined) {
            throw new runtime.RequiredError('userSaveBirthdayRequest','Required parameter requestParameters.userSaveBirthdayRequest was null or undefined when calling saveUserBirthday.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/birthday`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserSaveBirthdayRequestToJSON(requestParameters.userSaveBirthdayRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 誕生日を保存する
     */
    async saveUserBirthday(requestParameters: SaveUserBirthdayRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveUserBirthdayRaw(requestParameters, initOverrides);
    }

    /**
     * ユーザー名を保存する
     */
    async saveUserNameRaw(requestParameters: SaveUserNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userNameSaveRequest === null || requestParameters.userNameSaveRequest === undefined) {
            throw new runtime.RequiredError('userNameSaveRequest','Required parameter requestParameters.userNameSaveRequest was null or undefined when calling saveUserName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/user/name`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserNameSaveRequestToJSON(requestParameters.userNameSaveRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザー名を保存する
     */
    async saveUserName(requestParameters: SaveUserNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveUserNameRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetFriendMessageListMessageKeyEnum = {
    TO_ME: '0',
    ALL: '1'
} as const;
export type GetFriendMessageListMessageKeyEnum = typeof GetFriendMessageListMessageKeyEnum[keyof typeof GetFriendMessageListMessageKeyEnum];
/**
 * @export
 */
export const GetPresentHistoryPresentTypeEnum = {
    Item: 'item',
    Avatar: 'avatar',
    Card: 'card'
} as const;
export type GetPresentHistoryPresentTypeEnum = typeof GetPresentHistoryPresentTypeEnum[keyof typeof GetPresentHistoryPresentTypeEnum];
