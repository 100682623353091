export type MinigameMovieType = 'normal' | 'event';
/**
 * ミニゲームの動画URLを取得
 * @param {MinigameMovieType} type
 * @param {number} step
 */
export const getMinigameMovieUrl = (type: MinigameMovieType, step: number) => {
  if (type === 'event') {
    // MEMO: event用動画に差し替え
    return `/static/movies/minigame_lottie/abc_minigame_event_0${step}.lottie.json`;
  }
  return `/static/movies/minigame_lottie/abc_minigame_0${step}.lottie.json`;
};

/**
 * 全minigameの動画URL(preload用)
 * @param {MinigameMovieType} type
 */
export const getMinigameMovieAllUrls = (type: MinigameMovieType) => {
  return [
    getMinigameMovieUrl(type, 1),
    getMinigameMovieUrl(type, 2),
    getMinigameMovieUrl(type, 3),
  ];
};

/**
 * 吹き出しの種類
 */
export const minigameTopAppealMessageType = {
  eventFever: 'scenario_event_fever',
};

// 選択済みレンタルデータを保持するURL。
export const keepSelectedRentalUrls = [
  '/minigame/rental',
  '/minigame/history',
  '/card/deck',
];

// レンタルページの選択中タブ、スクロール位置、ゲストレンタルリスト状態を保持するURL。
export const keepRentalPageStateUrls = [
  '/profile/friend',
];

/**
 * ミニゲームタイプ
 * @export
 */
export const MinigameType = {
  Event: 'event',
  Normal: 'normal',
} as const;
// eslint-disable-next-line no-redeclare
export type MinigameType = typeof MinigameType[keyof typeof MinigameType];
