<template>
  <ModalAlert
    :show="props.show"
    title="認証エラー"
    ok-text="タイトル画面へ"
    :z-index="props.zIndex"
    @click-ok="onClick"
  >
    <div v-if="props.error" class="text-center">
      <div class="mb-1">
        セッションがタイムアウト致しました<br>
        タイトル画面に戻ります
      </div>
      <div>
        ※うまく動作しない場合には<br>
        アプリの再起動をお試しください
      </div>
      <ModalErrorDebugErrorText :error="props.error" />
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import { getDisplayErrorCode } from '~/libs/errors';
import { watch } from '#imports';

const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});

const errorCode = ref<number>(getDisplayErrorCode(props.error));
watch(() => props.error, (current) => {
  errorCode.value = getDisplayErrorCode(current);
});

interface Emits {
  (e: 'click'): void;
}
const emit = defineEmits<Emits>();
const onClick = () => {
  emit('click');
};
</script>
