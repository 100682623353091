import { UserDeckRental } from 'api';
import { RentalTabType } from '~/libs/page/minigame/rental';

/**
 * レンタルページの選択中タブ、スクロール位置、レンタルリスト状態をクリアする
 */
const clearPageState = () => {
  useDeckRental().currentTab.value = null;
  useDeckRental().currentGuestListScrollTop.value = null;
  useDeckRental().currentFollowListScrollTop.value = null;
  useDeckRental().currentGuestList.value = null;
  useDeckRental().currentFollowList.value = null;
};

export const useDeckRental = () => {
  const selectedRental = useState<UserDeckRental|null>('selectedRental');
  const currentTab = useState<RentalTabType|null>('rentalTab');
  const currentGuestListScrollTop = useState<number|null>('rentalGuestListScrollTop');
  const currentFollowListScrollTop = useState<number|null>('rentalFollowListScrollTop');
  const currentGuestList = useState<UserDeckRental[]|null>('rentalGuestList');
  const currentFollowList = useState<UserDeckRental[]|null>('rentalFollowList');
  return {
    selectedRental,
    currentTab,
    currentGuestListScrollTop,
    currentFollowListScrollTop,
    currentGuestList,
    currentFollowList,
    clearPageState,
  };
};
