/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuCharaStatusBonus
 */
export interface CommuCharaStatusBonus {
    /**
     * コミュレベル
     * @type {number}
     * @memberof CommuCharaStatusBonus
     */
    commuLevel: number;
    /**
     * コミュキャラ報酬コード
     * @type {string}
     * @memberof CommuCharaStatusBonus
     */
    commuCharaBonusCd: string;
    /**
     * 報酬獲得フラグ 1:獲得 0:未獲得
     * @type {boolean}
     * @memberof CommuCharaStatusBonus
     */
    receiveFlg: boolean;
}

/**
 * Check if a given object implements the CommuCharaStatusBonus interface.
 */
export function instanceOfCommuCharaStatusBonus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commuLevel" in value;
    isInstance = isInstance && "commuCharaBonusCd" in value;
    isInstance = isInstance && "receiveFlg" in value;

    return isInstance;
}

export function CommuCharaStatusBonusFromJSON(json: any): CommuCharaStatusBonus {
    return CommuCharaStatusBonusFromJSONTyped(json, false);
}

export function CommuCharaStatusBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaStatusBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commuLevel': json['commu_level'],
        'commuCharaBonusCd': json['commu_chara_bonus_cd'],
        'receiveFlg': json['receive_flg'],
    };
}

export function CommuCharaStatusBonusToJSON(value?: CommuCharaStatusBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commu_level': value.commuLevel,
        'commu_chara_bonus_cd': value.commuCharaBonusCd,
        'receive_flg': value.receiveFlg,
    };
}

