interface AuthConfig {
  noRequiredRoutes: Array<string | RegExp>,
  redirectSignIn: string,
  redirectTo: string,
  forceUpdateIdTokenTime: number,
}

export const authConfig: AuthConfig = {
  // 認証が不要なページ
  noRequiredRoutes: [
    '/',
    '/att',
    '/auth',
    '/no-support',
    '/version-up',
    '/update',
    '/terms-of-service',
    /\/_debug\/?.*/,
    /\/debug\/?.*/,
  ],
  // 認証ページ
  redirectSignIn: '/auth',
  // 認証後のページ
  redirectTo: '/title',
  // 強制的にIdトークンを更新する秒数(FirebaseIDトークンは1時間できれるため以内に収める)
  forceUpdateIdTokenTime: 1800,
};
