/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConvertGoldCard } from './ConvertGoldCard';
import {
    ConvertGoldCardFromJSON,
    ConvertGoldCardFromJSONTyped,
    ConvertGoldCardToJSON,
} from './ConvertGoldCard';
import type { SynthesisCard } from './SynthesisCard';
import {
    SynthesisCardFromJSON,
    SynthesisCardFromJSONTyped,
    SynthesisCardToJSON,
} from './SynthesisCard';
import type { UnlockVoice } from './UnlockVoice';
import {
    UnlockVoiceFromJSON,
    UnlockVoiceFromJSONTyped,
    UnlockVoiceToJSON,
} from './UnlockVoice';
import type { UserGachaPresentList } from './UserGachaPresentList';
import {
    UserGachaPresentListFromJSON,
    UserGachaPresentListFromJSONTyped,
    UserGachaPresentListToJSON,
} from './UserGachaPresentList';

/**
 * 
 * @export
 * @interface UserGachaDecideResponseData
 */
export interface UserGachaDecideResponseData {
    /**
     * 
     * @type {Array<UserGachaPresentList>}
     * @memberof UserGachaDecideResponseData
     */
    presentList: Array<UserGachaPresentList>;
    /**
     * 
     * @type {Array<SynthesisCard>}
     * @memberof UserGachaDecideResponseData
     */
    synthesisCardList: Array<SynthesisCard>;
    /**
     * 
     * @type {Array<UnlockVoice>}
     * @memberof UserGachaDecideResponseData
     */
    unlockVoiceList: Array<UnlockVoice>;
    /**
     * 
     * @type {Array<ConvertGoldCard>}
     * @memberof UserGachaDecideResponseData
     */
    convertGoldCardList: Array<ConvertGoldCard>;
}

/**
 * Check if a given object implements the UserGachaDecideResponseData interface.
 */
export function instanceOfUserGachaDecideResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "presentList" in value;
    isInstance = isInstance && "synthesisCardList" in value;
    isInstance = isInstance && "unlockVoiceList" in value;
    isInstance = isInstance && "convertGoldCardList" in value;

    return isInstance;
}

export function UserGachaDecideResponseDataFromJSON(json: any): UserGachaDecideResponseData {
    return UserGachaDecideResponseDataFromJSONTyped(json, false);
}

export function UserGachaDecideResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaDecideResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presentList': ((json['present_list'] as Array<any>).map(UserGachaPresentListFromJSON)),
        'synthesisCardList': ((json['synthesis_card_list'] as Array<any>).map(SynthesisCardFromJSON)),
        'unlockVoiceList': ((json['unlock_voice_list'] as Array<any>).map(UnlockVoiceFromJSON)),
        'convertGoldCardList': ((json['convert_gold_card_list'] as Array<any>).map(ConvertGoldCardFromJSON)),
    };
}

export function UserGachaDecideResponseDataToJSON(value?: UserGachaDecideResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present_list': ((value.presentList as Array<any>).map(UserGachaPresentListToJSON)),
        'synthesis_card_list': ((value.synthesisCardList as Array<any>).map(SynthesisCardToJSON)),
        'unlock_voice_list': ((value.unlockVoiceList as Array<any>).map(UnlockVoiceToJSON)),
        'convert_gold_card_list': ((value.convertGoldCardList as Array<any>).map(ConvertGoldCardToJSON)),
    };
}

