/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 選択時ボイスリスト
 * @export
 * @interface CharaSelectPeeping
 */
export interface CharaSelectPeeping {
    /**
     * チャプターコード
     * @type {string}
     * @memberof CharaSelectPeeping
     */
    chapterCd: string;
    /**
     * チケットコード
     * @type {string}
     * @memberof CharaSelectPeeping
     */
    ticketCd: string;
}

/**
 * Check if a given object implements the CharaSelectPeeping interface.
 */
export function instanceOfCharaSelectPeeping(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "chapterCd" in value;
    isInstance = isInstance && "ticketCd" in value;

    return isInstance;
}

export function CharaSelectPeepingFromJSON(json: any): CharaSelectPeeping {
    return CharaSelectPeepingFromJSONTyped(json, false);
}

export function CharaSelectPeepingFromJSONTyped(json: any, ignoreDiscriminator: boolean): CharaSelectPeeping {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chapterCd': json['chapter_cd'],
        'ticketCd': json['ticket_cd'],
    };
}

export function CharaSelectPeepingToJSON(value?: CharaSelectPeeping | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chapter_cd': value.chapterCd,
        'ticket_cd': value.ticketCd,
    };
}

