/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PresentType } from './PresentType';
import {
    PresentTypeFromJSON,
    PresentTypeFromJSONTyped,
    PresentTypeToJSON,
} from './PresentType';

/**
 * 
 * @export
 * @interface PresentItem
 */
export interface PresentItem {
    /**
     * プレゼントアイテムコード
     * @type {string}
     * @memberof PresentItem
     */
    presentItemCd: string;
    /**
     * アイテムコード
     * @type {string}
     * @memberof PresentItem
     */
    itemCd: string;
    /**
     * アイテム個数
     * @type {number}
     * @memberof PresentItem
     */
    itemCount: number;
    /**
     * キャラ説明文字列
     * @type {string}
     * @memberof PresentItem
     */
    description: string;
    /**
     * 
     * @type {PresentType}
     * @memberof PresentItem
     */
    type: PresentType;
}

/**
 * Check if a given object implements the PresentItem interface.
 */
export function instanceOfPresentItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "presentItemCd" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "type" in value;

    return isInstance;
}

export function PresentItemFromJSON(json: any): PresentItem {
    return PresentItemFromJSONTyped(json, false);
}

export function PresentItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresentItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presentItemCd': json['present_item_cd'],
        'itemCd': json['item_cd'],
        'itemCount': json['item_count'],
        'description': json['description'],
        'type': PresentTypeFromJSON(json['type']),
    };
}

export function PresentItemToJSON(value?: PresentItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present_item_cd': value.presentItemCd,
        'item_cd': value.itemCd,
        'item_count': value.itemCount,
        'description': value.description,
        'type': PresentTypeToJSON(value.type),
    };
}

