/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMinigameTopAppealMessage
 */
export interface UserMinigameTopAppealMessage {
    /**
     * 
     * @type {string}
     * @memberof UserMinigameTopAppealMessage
     */
    type: string;
    /**
     * 可変の数値
     * @type {number}
     * @memberof UserMinigameTopAppealMessage
     */
    bindParameter: number;
}

/**
 * Check if a given object implements the UserMinigameTopAppealMessage interface.
 */
export function instanceOfUserMinigameTopAppealMessage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "bindParameter" in value;

    return isInstance;
}

export function UserMinigameTopAppealMessageFromJSON(json: any): UserMinigameTopAppealMessage {
    return UserMinigameTopAppealMessageFromJSONTyped(json, false);
}

export function UserMinigameTopAppealMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMinigameTopAppealMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'bindParameter': json['bind_parameter'],
    };
}

export function UserMinigameTopAppealMessageToJSON(value?: UserMinigameTopAppealMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'bind_parameter': value.bindParameter,
    };
}

