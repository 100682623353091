/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuCharaRequiredItem } from './CommuCharaRequiredItem';
import {
    CommuCharaRequiredItemFromJSON,
    CommuCharaRequiredItemFromJSONTyped,
    CommuCharaRequiredItemToJSON,
} from './CommuCharaRequiredItem';

/**
 * 
 * @export
 * @interface CommuCharaLimitBreak
 */
export interface CommuCharaLimitBreak {
    /**
     * コミュキャラ限界突破コード
     * @type {string}
     * @memberof CommuCharaLimitBreak
     */
    commuCharaLimitBreakCd: string;
    /**
     * レベル下限
     * @type {number}
     * @memberof CommuCharaLimitBreak
     */
    lowerLevel: number;
    /**
     * レベル上限
     * @type {number}
     * @memberof CommuCharaLimitBreak
     */
    upperLevel: number;
    /**
     * 限界突破の開放日時
     * @type {string}
     * @memberof CommuCharaLimitBreak
     */
    startedAt: string;
    /**
     * レベル限界突破必要アイテムリスト
     * @type {Array<CommuCharaRequiredItem>}
     * @memberof CommuCharaLimitBreak
     */
    requiredItemList: Array<CommuCharaRequiredItem>;
}

/**
 * Check if a given object implements the CommuCharaLimitBreak interface.
 */
export function instanceOfCommuCharaLimitBreak(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commuCharaLimitBreakCd" in value;
    isInstance = isInstance && "lowerLevel" in value;
    isInstance = isInstance && "upperLevel" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "requiredItemList" in value;

    return isInstance;
}

export function CommuCharaLimitBreakFromJSON(json: any): CommuCharaLimitBreak {
    return CommuCharaLimitBreakFromJSONTyped(json, false);
}

export function CommuCharaLimitBreakFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaLimitBreak {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commuCharaLimitBreakCd': json['commu_chara_limit_break_cd'],
        'lowerLevel': json['lower_level'],
        'upperLevel': json['upper_level'],
        'startedAt': json['started_at'],
        'requiredItemList': ((json['required_item_list'] as Array<any>).map(CommuCharaRequiredItemFromJSON)),
    };
}

export function CommuCharaLimitBreakToJSON(value?: CommuCharaLimitBreak | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commu_chara_limit_break_cd': value.commuCharaLimitBreakCd,
        'lower_level': value.lowerLevel,
        'upper_level': value.upperLevel,
        'started_at': value.startedAt,
        'required_item_list': ((value.requiredItemList as Array<any>).map(CommuCharaRequiredItemToJSON)),
    };
}

