/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopItem
 */
export interface ShopItem {
    /**
     * shop_item_cd
     * @type {string}
     * @memberof ShopItem
     */
    shopItemCd: string;
    /**
     * ショップアイテム名
     * @type {string}
     * @memberof ShopItem
     */
    shopItemName: string;
    /**
     * ショップアイテムの詳細説明
     * @type {string}
     * @memberof ShopItem
     */
    shopItemDescription: string;
    /**
     * アイテムの数量
     * @type {number}
     * @memberof ShopItem
     */
    itemCount: number;
    /**
     * 購入に必要なジェム数
     * @type {number}
     * @memberof ShopItem
     */
    gemPrice: number;
    /**
     * 購入に必要なサイト内通貨数
     * @type {number}
     * @memberof ShopItem
     */
    goldPrice: number;
    /**
     * 割引前の購入に必要なジェム数
     * @type {number}
     * @memberof ShopItem
     */
    undiscountedGemPrice: number | null;
    /**
     * 割引前の購入に必要なサイト内通貨数
     * @type {number}
     * @memberof ShopItem
     */
    undiscountedGoldPrice: number | null;
    /**
     * 購入制限(1日累計) ※0は制限無し
     * @type {number}
     * @memberof ShopItem
     */
    purchaseLimitCount: number;
    /**
     * 購入制限(期間累計) ※0は制限無し
     * @type {number}
     * @memberof ShopItem
     */
    limitCount: number;
    /**
     * 1回のアイテム最大購入可能数
     * @type {number}
     * @memberof ShopItem
     */
    oneTimeLimitCount: number;
    /**
     * クライアントでの並び順(値が小さいもののほうが優先的な扱い方をする)
     * @type {number}
     * @memberof ShopItem
     */
    orderNo: number;
    /**
     * 表示フラグ
     * @type {boolean}
     * @memberof ShopItem
     */
    showFlg: boolean;
    /**
     * 開始日時
     * @type {string}
     * @memberof ShopItem
     */
    startedAt: string | null;
    /**
     * 終了日時
     * @type {string}
     * @memberof ShopItem
     */
    endedAt: string | null;
    /**
     * ショップアイテムのアイテムコード
     * @type {string}
     * @memberof ShopItem
     */
    itemCd: string;
    /**
     * item_mst.targetの値
     * @type {string}
     * @memberof ShopItem
     */
    itemTarget: string;
    /**
     * 使用効果数量(+5)とかって付いてるアイテムの5の部分を表現している。
     * @type {number}
     * @memberof ShopItem
     */
    itemEffect: number;
    /**
     * ショップアイテムのカテゴリコード
     * @type {string}
     * @memberof ShopItem
     */
    shopCategoryCd: string;
    /**
     * ショップアイテムのカテゴリ名
     * @type {string}
     * @memberof ShopItem
     */
    shopCategoryName: string;
    /**
     * 即時使用フラグ
     * @type {boolean}
     * @memberof ShopItem
     */
    immediateUseFlg: boolean;
    /**
     * リソースアイテムコード
     * @type {string}
     * @memberof ShopItem
     */
    resourceItemCd: string;
    /**
     * リソースパーティション番号
     * @type {number}
     * @memberof ShopItem
     */
    resourcePartition: number;
}

/**
 * Check if a given object implements the ShopItem interface.
 */
export function instanceOfShopItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shopItemCd" in value;
    isInstance = isInstance && "shopItemName" in value;
    isInstance = isInstance && "shopItemDescription" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "gemPrice" in value;
    isInstance = isInstance && "goldPrice" in value;
    isInstance = isInstance && "undiscountedGemPrice" in value;
    isInstance = isInstance && "undiscountedGoldPrice" in value;
    isInstance = isInstance && "purchaseLimitCount" in value;
    isInstance = isInstance && "limitCount" in value;
    isInstance = isInstance && "oneTimeLimitCount" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "showFlg" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemTarget" in value;
    isInstance = isInstance && "itemEffect" in value;
    isInstance = isInstance && "shopCategoryCd" in value;
    isInstance = isInstance && "shopCategoryName" in value;
    isInstance = isInstance && "immediateUseFlg" in value;
    isInstance = isInstance && "resourceItemCd" in value;
    isInstance = isInstance && "resourcePartition" in value;

    return isInstance;
}

export function ShopItemFromJSON(json: any): ShopItem {
    return ShopItemFromJSONTyped(json, false);
}

export function ShopItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopItemCd': json['shop_item_cd'],
        'shopItemName': json['shop_item_name'],
        'shopItemDescription': json['shop_item_description'],
        'itemCount': json['item_count'],
        'gemPrice': json['gem_price'],
        'goldPrice': json['gold_price'],
        'undiscountedGemPrice': json['undiscounted_gem_price'],
        'undiscountedGoldPrice': json['undiscounted_gold_price'],
        'purchaseLimitCount': json['purchase_limit_count'],
        'limitCount': json['limit_count'],
        'oneTimeLimitCount': json['one_time_limit_count'],
        'orderNo': json['order_no'],
        'showFlg': json['show_flg'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'itemCd': json['item_cd'],
        'itemTarget': json['item_target'],
        'itemEffect': json['item_effect'],
        'shopCategoryCd': json['shop_category_cd'],
        'shopCategoryName': json['shop_category_name'],
        'immediateUseFlg': json['immediate_use_flg'],
        'resourceItemCd': json['resource_item_cd'],
        'resourcePartition': json['resource_partition'],
    };
}

export function ShopItemToJSON(value?: ShopItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop_item_cd': value.shopItemCd,
        'shop_item_name': value.shopItemName,
        'shop_item_description': value.shopItemDescription,
        'item_count': value.itemCount,
        'gem_price': value.gemPrice,
        'gold_price': value.goldPrice,
        'undiscounted_gem_price': value.undiscountedGemPrice,
        'undiscounted_gold_price': value.undiscountedGoldPrice,
        'purchase_limit_count': value.purchaseLimitCount,
        'limit_count': value.limitCount,
        'one_time_limit_count': value.oneTimeLimitCount,
        'order_no': value.orderNo,
        'show_flg': value.showFlg,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'item_cd': value.itemCd,
        'item_target': value.itemTarget,
        'item_effect': value.itemEffect,
        'shop_category_cd': value.shopCategoryCd,
        'shop_category_name': value.shopCategoryName,
        'immediate_use_flg': value.immediateUseFlg,
        'resource_item_cd': value.resourceItemCd,
        'resource_partition': value.resourcePartition,
    };
}

