/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StillReadStillList } from './StillReadStillList';
import {
    StillReadStillListFromJSON,
    StillReadStillListFromJSONTyped,
    StillReadStillListToJSON,
} from './StillReadStillList';

/**
 * 
 * @export
 * @interface StillReadRequest
 */
export interface StillReadRequest {
    /**
     * スチルコードの配列
     * @type {Array<StillReadStillList>}
     * @memberof StillReadRequest
     */
    stillList?: Array<StillReadStillList>;
}

/**
 * Check if a given object implements the StillReadRequest interface.
 */
export function instanceOfStillReadRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function StillReadRequestFromJSON(json: any): StillReadRequest {
    return StillReadRequestFromJSONTyped(json, false);
}

export function StillReadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): StillReadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stillList': !exists(json, 'still_list') ? undefined : ((json['still_list'] as Array<any>).map(StillReadStillListFromJSON)),
    };
}

export function StillReadRequestToJSON(value?: StillReadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'still_list': value.stillList === undefined ? undefined : ((value.stillList as Array<any>).map(StillReadStillListToJSON)),
    };
}

