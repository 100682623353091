import { default as indexuyWYj3XMEhMeta } from "/codebuild/output/src4034965912/src/src/pages/account/index.vue?macro=true";
import { default as _91announce_cd_93XHFyMHegwoMeta } from "/codebuild/output/src4034965912/src/src/pages/announce/[announce_cd].vue?macro=true";
import { default as indexhBkSqDKmYUMeta } from "/codebuild/output/src4034965912/src/src/pages/att/index.vue?macro=true";
import { default as indexNAXR92GBLvMeta } from "/codebuild/output/src4034965912/src/src/pages/auth/index.vue?macro=true";
import { default as _91type_93_45clothesS8NRsUCuDCMeta } from "/codebuild/output/src4034965912/src/src/pages/avatar/[type]-clothes.vue?macro=true";
import { default as closetq4FIuuk8PqMeta } from "/codebuild/output/src4034965912/src/src/pages/avatar/closet.vue?macro=true";
import { default as indexYkFH3kOeb2Meta } from "/codebuild/output/src4034965912/src/src/pages/avatar/index.vue?macro=true";
import { default as redecoratesrFLwyQWfMMeta } from "/codebuild/output/src4034965912/src/src/pages/avatar/redecorate.vue?macro=true";
import { default as sell8Gg4vmKNRKMeta } from "/codebuild/output/src4034965912/src/src/pages/avatar/sell.vue?macro=true";
import { default as deck9xldJVHPxDMeta } from "/codebuild/output/src4034965912/src/src/pages/card/deck.vue?macro=true";
import { default as indexmChK3kagNQMeta } from "/codebuild/output/src4034965912/src/src/pages/card/index.vue?macro=true";
import { default as mypageCtwAMnOGzhMeta } from "/codebuild/output/src4034965912/src/src/pages/card/mypage.vue?macro=true";
import { default as selectJrfGSwIE7gMeta } from "/codebuild/output/src4034965912/src/src/pages/chara/select.vue?macro=true";
import { default as indexjKoAlCwPE6Meta } from "/codebuild/output/src4034965912/src/src/pages/commu/[chara_cd]/index.vue?macro=true";
import { default as indexS2MnQSn4BRMeta } from "/codebuild/output/src4034965912/src/src/pages/commu/index.vue?macro=true";
import { default as indexGzJDnUniDxMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/bonus/index.vue?macro=true";
import { default as selectwKPunIZ8cKMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/chara/select.vue?macro=true";
import { default as endnboZeaRtyGMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/end.vue?macro=true";
import { default as indexa3E149WCfoMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/epilogue/index.vue?macro=true";
import { default as historydnv6EWK0B1Meta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/minigame/history.vue?macro=true";
import { default as topPMFZDJMIMTMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/minigame/top.vue?macro=true";
import { default as mypageiBw8vYVmC8Meta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/mypage.vue?macro=true";
import { default as ranking4FjqqNECPQMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/ranking.vue?macro=true";
import { default as completeinhG39RDThMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/complete.vue?macro=true";
import { default as listwzyZIM1hKEMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/list.vue?macro=true";
import { default as mission1UjtbkFUkzMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/mission.vue?macro=true";
import { default as missionCompleteEcccgUXGQlMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/missionComplete.vue?macro=true";
import { default as topan6GIYgJAWMeta } from "/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/top.vue?macro=true";
import { default as endGe7ZG7yRlWMeta } from "/codebuild/output/src4034965912/src/src/pages/event/end.vue?macro=true";
import { default as followerlistdZ6MYKH1nXMeta } from "/codebuild/output/src4034965912/src/src/pages/friend/followerlist.vue?macro=true";
import { default as followlist7oGo68YlRZMeta } from "/codebuild/output/src4034965912/src/src/pages/friend/followlist.vue?macro=true";
import { default as indexZLFPa7RbyFMeta } from "/codebuild/output/src4034965912/src/src/pages/friend/index.vue?macro=true";
import { default as like9gzYJxKsQ7Meta } from "/codebuild/output/src4034965912/src/src/pages/friend/like.vue?macro=true";
import { default as messageboxeV55f9mu6gMeta } from "/codebuild/output/src4034965912/src/src/pages/friend/messagebox.vue?macro=true";
import { default as searchV8T9ZGR2bkMeta } from "/codebuild/output/src4034965912/src/src/pages/friend/search.vue?macro=true";
import { default as index6lXldfO2rYMeta } from "/codebuild/output/src4034965912/src/src/pages/gacha/index.vue?macro=true";
import { default as _91type_93_45_91gachaCd_93AXPbYOszffMeta } from "/codebuild/output/src4034965912/src/src/pages/gacha/lineup/[type]-[gachaCd].vue?macro=true";
import { default as resultggq5acqQ6jMeta } from "/codebuild/output/src4034965912/src/src/pages/gacha/result.vue?macro=true";
import { default as captureCPPHYTwPwvMeta } from "/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/capture.vue?macro=true";
import { default as cardSnjXfDsBqlMeta } from "/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/card.vue?macro=true";
import { default as indexnQI4l3G2gjMeta } from "/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/index.vue?macro=true";
import { default as stillGnXF27dBswMeta } from "/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/still.vue?macro=true";
import { default as storyj5oyb4YgL2Meta } from "/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/story.vue?macro=true";
import { default as indexeYteQacttXMeta } from "/codebuild/output/src4034965912/src/src/pages/his-collection/index.vue?macro=true";
import { default as complete9iEiYmtYqTMeta } from "/codebuild/output/src4034965912/src/src/pages/his-collection/story/complete.vue?macro=true";
import { default as index0oyHGV0Q4WMeta } from "/codebuild/output/src4034965912/src/src/pages/index.vue?macro=true";
import { default as listDaXx97ILP0Meta } from "/codebuild/output/src4034965912/src/src/pages/item/list.vue?macro=true";
import { default as historyDQAwiM2bccMeta } from "/codebuild/output/src4034965912/src/src/pages/minigame/history.vue?macro=true";
import { default as rentalsFt78wb7UXMeta } from "/codebuild/output/src4034965912/src/src/pages/minigame/rental.vue?macro=true";
import { default as top4OIOJE3KL9Meta } from "/codebuild/output/src4034965912/src/src/pages/minigame/top.vue?macro=true";
import { default as mypageuMIv4hMfeOMeta } from "/codebuild/output/src4034965912/src/src/pages/mypage.vue?macro=true";
import { default as no_45supportJzpgytdJsDMeta } from "/codebuild/output/src4034965912/src/src/pages/no-support.vue?macro=true";
import { default as indexO2mFVhXLVuMeta } from "/codebuild/output/src4034965912/src/src/pages/panelmission/index.vue?macro=true";
import { default as history_45_91type_93DeLnHf12pLMeta } from "/codebuild/output/src4034965912/src/src/pages/present/history-[type].vue?macro=true";
import { default as listTgxbAD7IUqMeta } from "/codebuild/output/src4034965912/src/src/pages/present/list.vue?macro=true";
import { default as friendPWMceFgDSRMeta } from "/codebuild/output/src4034965912/src/src/pages/profile/friend.vue?macro=true";
import { default as indexkk66bZTKuNMeta } from "/codebuild/output/src4034965912/src/src/pages/profile/index.vue?macro=true";
import { default as _91combinedsale_cd_93e1iYGtMCbVMeta } from "/codebuild/output/src4034965912/src/src/pages/scenario/[combinedsale_cd].vue?macro=true";
import { default as index0f1kvXS9bcMeta } from "/codebuild/output/src4034965912/src/src/pages/serialcode/index.vue?macro=true";
import { default as account_45tradingRur21uFnCyMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/account-trading.vue?macro=true";
import { default as copyright_45ristrictzYSMNlZIqZMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/copyright-ristrict.vue?macro=true";
import { default as copyright8Ig2SNChdEMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/copyright.vue?macro=true";
import { default as creditsSo6rNMa22SMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/credits.vue?macro=true";
import { default as helpCH62ldCh5yMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/help.vue?macro=true";
import { default as licenseBpCLjJ7VHTMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/license.vue?macro=true";
import { default as menu7nJwT5nV2HMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/menu.vue?macro=true";
import { default as payment_45service_45actgQvHbzWDOtMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/payment-service-act.vue?macro=true";
import { default as term_45of_45servicecVMrSOo2PCMeta } from "/codebuild/output/src4034965912/src/src/pages/setting/term-of-service.vue?macro=true";
import { default as diayvBShTwaXCMeta } from "/codebuild/output/src4034965912/src/src/pages/shop/dia.vue?macro=true";
import { default as indexL9TQqQoq0pMeta } from "/codebuild/output/src4034965912/src/src/pages/shop/index.vue?macro=true";
import { default as itemqOitiKXK52Meta } from "/codebuild/output/src4034965912/src/src/pages/shop/item.vue?macro=true";
import { default as setXIzIqfPwlqMeta } from "/codebuild/output/src4034965912/src/src/pages/shop/set.vue?macro=true";
import { default as completeSUU8bJUCm0Meta } from "/codebuild/output/src4034965912/src/src/pages/story/complete.vue?macro=true";
import { default as item_45_91type_93NcoTDKbhHXMeta } from "/codebuild/output/src4034965912/src/src/pages/story/item-[type].vue?macro=true";
import { default as list6xoah1svM3Meta } from "/codebuild/output/src4034965912/src/src/pages/story/list.vue?macro=true";
import { default as missionYRNmvhXgxRMeta } from "/codebuild/output/src4034965912/src/src/pages/story/mission.vue?macro=true";
import { default as missionCompletelHJ0Nbk014Meta } from "/codebuild/output/src4034965912/src/src/pages/story/missionComplete.vue?macro=true";
import { default as playzdkK48fBDxMeta } from "/codebuild/output/src4034965912/src/src/pages/story/play.vue?macro=true";
import { default as viammW4x06Hv3Meta } from "/codebuild/output/src4034965912/src/src/pages/story/via.vue?macro=true";
import { default as indexgglOK12HNpMeta } from "/codebuild/output/src4034965912/src/src/pages/terms-of-service/index.vue?macro=true";
import { default as titleddJI9tKdnOMeta } from "/codebuild/output/src4034965912/src/src/pages/title.vue?macro=true";
import { default as namezNFSQ3XY6QMeta } from "/codebuild/output/src4034965912/src/src/pages/tutorial/name.vue?macro=true";
import { default as prologueAPGhxsY18CMeta } from "/codebuild/output/src4034965912/src/src/pages/tutorial/prologue.vue?macro=true";
import { default as updateW8cOWZrPuWMeta } from "/codebuild/output/src4034965912/src/src/pages/update.vue?macro=true";
import { default as version_45upb04pVJXrI3Meta } from "/codebuild/output/src4034965912/src/src/pages/version-up.vue?macro=true";
import { default as indexd3QWcTojeiMeta } from "/codebuild/output/src4034965912/src/src/node_modules/nuxt-debug/dist/runtime/pages/index.vue?macro=true";
import { default as cacheE0HNAVPbJZMeta } from "/codebuild/output/src4034965912/src/src/node_modules/nuxt-debug/dist/runtime/pages/cache.vue?macro=true";
import { default as logFJ0lY1w3dgMeta } from "/codebuild/output/src4034965912/src/src/node_modules/nuxt-debug/dist/runtime/pages/log.vue?macro=true";
import { default as animateKuZG0XEmqCMeta } from "/codebuild/output/src4034965912/src/src/debug/animate.vue?macro=true";
import { default as audioYVU5L7bhRqMeta } from "/codebuild/output/src4034965912/src/src/debug/audio.vue?macro=true";
import { default as authpKzMU8DAh7Meta } from "/codebuild/output/src4034965912/src/src/debug/auth.vue?macro=true";
import { default as debugButtonyBfflcWIYnMeta } from "/codebuild/output/src4034965912/src/src/debug/debugButton.vue?macro=true";
import { default as directionoG8aPTVuiPMeta } from "/codebuild/output/src4034965912/src/src/debug/direction.vue?macro=true";
import { default as environmentGFwFTYkrM5Meta } from "/codebuild/output/src4034965912/src/src/debug/environment.vue?macro=true";
import { default as lottieD1S3GO0OczMeta } from "/codebuild/output/src4034965912/src/src/debug/lottie.vue?macro=true";
import { default as moviepDwB6lm78tMeta } from "/codebuild/output/src4034965912/src/src/debug/movie.vue?macro=true";
import { default as playerPrefsZocNzFPXD7Meta } from "/codebuild/output/src4034965912/src/src/debug/playerPrefs.vue?macro=true";
import { default as push8pEcrQ4YlTMeta } from "/codebuild/output/src4034965912/src/src/debug/push.vue?macro=true";
import { default as screenfeJZciM8mSMeta } from "/codebuild/output/src4034965912/src/src/debug/screen.vue?macro=true";
import { default as serverMZJyK4YeI8Meta } from "/codebuild/output/src4034965912/src/src/debug/server.vue?macro=true";
import { default as storageS7GrT2Xo2pMeta } from "/codebuild/output/src4034965912/src/src/debug/storage.vue?macro=true";
import { default as ui9v2HlHGz1aMeta } from "/codebuild/output/src4034965912/src/src/debug/ui.vue?macro=true";
export default [
  {
    name: indexuyWYj3XMEhMeta?.name ?? "account",
    path: indexuyWYj3XMEhMeta?.path ?? "/account",
    meta: indexuyWYj3XMEhMeta || {},
    alias: indexuyWYj3XMEhMeta?.alias || [],
    redirect: indexuyWYj3XMEhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91announce_cd_93XHFyMHegwoMeta?.name ?? "announce-announce_cd",
    path: _91announce_cd_93XHFyMHegwoMeta?.path ?? "/announce/:announce_cd()",
    meta: _91announce_cd_93XHFyMHegwoMeta || {},
    alias: _91announce_cd_93XHFyMHegwoMeta?.alias || [],
    redirect: _91announce_cd_93XHFyMHegwoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/announce/[announce_cd].vue").then(m => m.default || m)
  },
  {
    name: indexhBkSqDKmYUMeta?.name ?? "att",
    path: indexhBkSqDKmYUMeta?.path ?? "/att",
    meta: indexhBkSqDKmYUMeta || {},
    alias: indexhBkSqDKmYUMeta?.alias || [],
    redirect: indexhBkSqDKmYUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/att/index.vue").then(m => m.default || m)
  },
  {
    name: indexNAXR92GBLvMeta?.name ?? "auth",
    path: indexNAXR92GBLvMeta?.path ?? "/auth",
    meta: indexNAXR92GBLvMeta || {},
    alias: indexNAXR92GBLvMeta?.alias || [],
    redirect: indexNAXR92GBLvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93_45clothesS8NRsUCuDCMeta?.name ?? "avatar-type-clothes",
    path: _91type_93_45clothesS8NRsUCuDCMeta?.path ?? "/avatar/:type()-clothes",
    meta: _91type_93_45clothesS8NRsUCuDCMeta || {},
    alias: _91type_93_45clothesS8NRsUCuDCMeta?.alias || [],
    redirect: _91type_93_45clothesS8NRsUCuDCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/avatar/[type]-clothes.vue").then(m => m.default || m)
  },
  {
    name: closetq4FIuuk8PqMeta?.name ?? "avatar-closet",
    path: closetq4FIuuk8PqMeta?.path ?? "/avatar/closet",
    meta: closetq4FIuuk8PqMeta || {},
    alias: closetq4FIuuk8PqMeta?.alias || [],
    redirect: closetq4FIuuk8PqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/avatar/closet.vue").then(m => m.default || m)
  },
  {
    name: indexYkFH3kOeb2Meta?.name ?? "avatar",
    path: indexYkFH3kOeb2Meta?.path ?? "/avatar",
    meta: indexYkFH3kOeb2Meta || {},
    alias: indexYkFH3kOeb2Meta?.alias || [],
    redirect: indexYkFH3kOeb2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/avatar/index.vue").then(m => m.default || m)
  },
  {
    name: redecoratesrFLwyQWfMMeta?.name ?? "avatar-redecorate",
    path: redecoratesrFLwyQWfMMeta?.path ?? "/avatar/redecorate",
    meta: redecoratesrFLwyQWfMMeta || {},
    alias: redecoratesrFLwyQWfMMeta?.alias || [],
    redirect: redecoratesrFLwyQWfMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/avatar/redecorate.vue").then(m => m.default || m)
  },
  {
    name: sell8Gg4vmKNRKMeta?.name ?? "avatar-sell",
    path: sell8Gg4vmKNRKMeta?.path ?? "/avatar/sell",
    meta: sell8Gg4vmKNRKMeta || {},
    alias: sell8Gg4vmKNRKMeta?.alias || [],
    redirect: sell8Gg4vmKNRKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/avatar/sell.vue").then(m => m.default || m)
  },
  {
    name: deck9xldJVHPxDMeta?.name ?? "card-deck",
    path: deck9xldJVHPxDMeta?.path ?? "/card/deck",
    meta: deck9xldJVHPxDMeta || {},
    alias: deck9xldJVHPxDMeta?.alias || [],
    redirect: deck9xldJVHPxDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/card/deck.vue").then(m => m.default || m)
  },
  {
    name: indexmChK3kagNQMeta?.name ?? "card",
    path: indexmChK3kagNQMeta?.path ?? "/card",
    meta: indexmChK3kagNQMeta || {},
    alias: indexmChK3kagNQMeta?.alias || [],
    redirect: indexmChK3kagNQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/card/index.vue").then(m => m.default || m)
  },
  {
    name: mypageCtwAMnOGzhMeta?.name ?? "card-mypage",
    path: mypageCtwAMnOGzhMeta?.path ?? "/card/mypage",
    meta: mypageCtwAMnOGzhMeta || {},
    alias: mypageCtwAMnOGzhMeta?.alias || [],
    redirect: mypageCtwAMnOGzhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/card/mypage.vue").then(m => m.default || m)
  },
  {
    name: selectJrfGSwIE7gMeta?.name ?? "chara-select",
    path: selectJrfGSwIE7gMeta?.path ?? "/chara/select",
    meta: selectJrfGSwIE7gMeta || {},
    alias: selectJrfGSwIE7gMeta?.alias || [],
    redirect: selectJrfGSwIE7gMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/chara/select.vue").then(m => m.default || m)
  },
  {
    name: indexjKoAlCwPE6Meta?.name ?? "commu-chara_cd",
    path: indexjKoAlCwPE6Meta?.path ?? "/commu/:chara_cd()",
    meta: indexjKoAlCwPE6Meta || {},
    alias: indexjKoAlCwPE6Meta?.alias || [],
    redirect: indexjKoAlCwPE6Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/commu/[chara_cd]/index.vue").then(m => m.default || m)
  },
  {
    name: indexS2MnQSn4BRMeta?.name ?? "commu",
    path: indexS2MnQSn4BRMeta?.path ?? "/commu",
    meta: indexS2MnQSn4BRMeta || {},
    alias: indexS2MnQSn4BRMeta?.alias || [],
    redirect: indexS2MnQSn4BRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/commu/index.vue").then(m => m.default || m)
  },
  {
    name: indexGzJDnUniDxMeta?.name ?? "event-event_cd-bonus",
    path: indexGzJDnUniDxMeta?.path ?? "/event/:event_cd()/bonus",
    meta: indexGzJDnUniDxMeta || {},
    alias: indexGzJDnUniDxMeta?.alias || [],
    redirect: indexGzJDnUniDxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/bonus/index.vue").then(m => m.default || m)
  },
  {
    name: selectwKPunIZ8cKMeta?.name ?? "event-event_cd-chara-select",
    path: selectwKPunIZ8cKMeta?.path ?? "/event/:event_cd()/chara/select",
    meta: selectwKPunIZ8cKMeta || {},
    alias: selectwKPunIZ8cKMeta?.alias || [],
    redirect: selectwKPunIZ8cKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/chara/select.vue").then(m => m.default || m)
  },
  {
    name: endnboZeaRtyGMeta?.name ?? "event-event_cd-end",
    path: endnboZeaRtyGMeta?.path ?? "/event/:event_cd()/end",
    meta: endnboZeaRtyGMeta || {},
    alias: endnboZeaRtyGMeta?.alias || [],
    redirect: endnboZeaRtyGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/end.vue").then(m => m.default || m)
  },
  {
    name: indexa3E149WCfoMeta?.name ?? "event-event_cd-epilogue",
    path: indexa3E149WCfoMeta?.path ?? "/event/:event_cd()/epilogue",
    meta: indexa3E149WCfoMeta || {},
    alias: indexa3E149WCfoMeta?.alias || [],
    redirect: indexa3E149WCfoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/epilogue/index.vue").then(m => m.default || m)
  },
  {
    name: historydnv6EWK0B1Meta?.name ?? "event-event_cd-minigame-history",
    path: historydnv6EWK0B1Meta?.path ?? "/event/:event_cd()/minigame/history",
    meta: historydnv6EWK0B1Meta || {},
    alias: historydnv6EWK0B1Meta?.alias || [],
    redirect: historydnv6EWK0B1Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/minigame/history.vue").then(m => m.default || m)
  },
  {
    name: topPMFZDJMIMTMeta?.name ?? "event-event_cd-minigame-top",
    path: topPMFZDJMIMTMeta?.path ?? "/event/:event_cd()/minigame/top",
    meta: topPMFZDJMIMTMeta || {},
    alias: topPMFZDJMIMTMeta?.alias || [],
    redirect: topPMFZDJMIMTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/minigame/top.vue").then(m => m.default || m)
  },
  {
    name: mypageiBw8vYVmC8Meta?.name ?? "event-event_cd-mypage",
    path: mypageiBw8vYVmC8Meta?.path ?? "/event/:event_cd()/mypage",
    meta: mypageiBw8vYVmC8Meta || {},
    alias: mypageiBw8vYVmC8Meta?.alias || [],
    redirect: mypageiBw8vYVmC8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/mypage.vue").then(m => m.default || m)
  },
  {
    name: ranking4FjqqNECPQMeta?.name ?? "event-event_cd-ranking",
    path: ranking4FjqqNECPQMeta?.path ?? "/event/:event_cd()/ranking",
    meta: ranking4FjqqNECPQMeta || {},
    alias: ranking4FjqqNECPQMeta?.alias || [],
    redirect: ranking4FjqqNECPQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/ranking.vue").then(m => m.default || m)
  },
  {
    name: completeinhG39RDThMeta?.name ?? "event-event_cd-story-complete",
    path: completeinhG39RDThMeta?.path ?? "/event/:event_cd()/story/complete",
    meta: completeinhG39RDThMeta || {},
    alias: completeinhG39RDThMeta?.alias || [],
    redirect: completeinhG39RDThMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/complete.vue").then(m => m.default || m)
  },
  {
    name: listwzyZIM1hKEMeta?.name ?? "event-event_cd-story-list",
    path: listwzyZIM1hKEMeta?.path ?? "/event/:event_cd()/story/list",
    meta: listwzyZIM1hKEMeta || {},
    alias: listwzyZIM1hKEMeta?.alias || [],
    redirect: listwzyZIM1hKEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/list.vue").then(m => m.default || m)
  },
  {
    name: mission1UjtbkFUkzMeta?.name ?? "event-event_cd-story-mission",
    path: mission1UjtbkFUkzMeta?.path ?? "/event/:event_cd()/story/mission",
    meta: mission1UjtbkFUkzMeta || {},
    alias: mission1UjtbkFUkzMeta?.alias || [],
    redirect: mission1UjtbkFUkzMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/mission.vue").then(m => m.default || m)
  },
  {
    name: missionCompleteEcccgUXGQlMeta?.name ?? "event-event_cd-story-missionComplete",
    path: missionCompleteEcccgUXGQlMeta?.path ?? "/event/:event_cd()/story/missionComplete",
    meta: missionCompleteEcccgUXGQlMeta || {},
    alias: missionCompleteEcccgUXGQlMeta?.alias || [],
    redirect: missionCompleteEcccgUXGQlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/story/missionComplete.vue").then(m => m.default || m)
  },
  {
    name: topan6GIYgJAWMeta?.name ?? "event-event_cd-top",
    path: topan6GIYgJAWMeta?.path ?? "/event/:event_cd()/top",
    meta: topan6GIYgJAWMeta || {},
    alias: topan6GIYgJAWMeta?.alias || [],
    redirect: topan6GIYgJAWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/[event_cd]/top.vue").then(m => m.default || m)
  },
  {
    name: endGe7ZG7yRlWMeta?.name ?? "event-end",
    path: endGe7ZG7yRlWMeta?.path ?? "/event/end",
    meta: endGe7ZG7yRlWMeta || {},
    alias: endGe7ZG7yRlWMeta?.alias || [],
    redirect: endGe7ZG7yRlWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/event/end.vue").then(m => m.default || m)
  },
  {
    name: followerlistdZ6MYKH1nXMeta?.name ?? "friend-followerlist",
    path: followerlistdZ6MYKH1nXMeta?.path ?? "/friend/followerlist",
    meta: followerlistdZ6MYKH1nXMeta || {},
    alias: followerlistdZ6MYKH1nXMeta?.alias || [],
    redirect: followerlistdZ6MYKH1nXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/friend/followerlist.vue").then(m => m.default || m)
  },
  {
    name: followlist7oGo68YlRZMeta?.name ?? "friend-followlist",
    path: followlist7oGo68YlRZMeta?.path ?? "/friend/followlist",
    meta: followlist7oGo68YlRZMeta || {},
    alias: followlist7oGo68YlRZMeta?.alias || [],
    redirect: followlist7oGo68YlRZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/friend/followlist.vue").then(m => m.default || m)
  },
  {
    name: indexZLFPa7RbyFMeta?.name ?? "friend",
    path: indexZLFPa7RbyFMeta?.path ?? "/friend",
    meta: indexZLFPa7RbyFMeta || {},
    alias: indexZLFPa7RbyFMeta?.alias || [],
    redirect: indexZLFPa7RbyFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/friend/index.vue").then(m => m.default || m)
  },
  {
    name: like9gzYJxKsQ7Meta?.name ?? "friend-like",
    path: like9gzYJxKsQ7Meta?.path ?? "/friend/like",
    meta: like9gzYJxKsQ7Meta || {},
    alias: like9gzYJxKsQ7Meta?.alias || [],
    redirect: like9gzYJxKsQ7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/friend/like.vue").then(m => m.default || m)
  },
  {
    name: messageboxeV55f9mu6gMeta?.name ?? "friend-messagebox",
    path: messageboxeV55f9mu6gMeta?.path ?? "/friend/messagebox",
    meta: messageboxeV55f9mu6gMeta || {},
    alias: messageboxeV55f9mu6gMeta?.alias || [],
    redirect: messageboxeV55f9mu6gMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/friend/messagebox.vue").then(m => m.default || m)
  },
  {
    name: searchV8T9ZGR2bkMeta?.name ?? "friend-search",
    path: searchV8T9ZGR2bkMeta?.path ?? "/friend/search",
    meta: searchV8T9ZGR2bkMeta || {},
    alias: searchV8T9ZGR2bkMeta?.alias || [],
    redirect: searchV8T9ZGR2bkMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/friend/search.vue").then(m => m.default || m)
  },
  {
    name: index6lXldfO2rYMeta?.name ?? "gacha",
    path: index6lXldfO2rYMeta?.path ?? "/gacha",
    meta: index6lXldfO2rYMeta || {},
    alias: index6lXldfO2rYMeta?.alias || [],
    redirect: index6lXldfO2rYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/gacha/index.vue").then(m => m.default || m)
  },
  {
    name: _91type_93_45_91gachaCd_93AXPbYOszffMeta?.name ?? "gacha-lineup-type-gachaCd",
    path: _91type_93_45_91gachaCd_93AXPbYOszffMeta?.path ?? "/gacha/lineup/:type()-:gachaCd()",
    meta: _91type_93_45_91gachaCd_93AXPbYOszffMeta || {},
    alias: _91type_93_45_91gachaCd_93AXPbYOszffMeta?.alias || [],
    redirect: _91type_93_45_91gachaCd_93AXPbYOszffMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/gacha/lineup/[type]-[gachaCd].vue").then(m => m.default || m)
  },
  {
    name: resultggq5acqQ6jMeta?.name ?? "gacha-result",
    path: resultggq5acqQ6jMeta?.path ?? "/gacha/result",
    meta: resultggq5acqQ6jMeta || {},
    alias: resultggq5acqQ6jMeta?.alias || [],
    redirect: resultggq5acqQ6jMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/gacha/result.vue").then(m => m.default || m)
  },
  {
    name: captureCPPHYTwPwvMeta?.name ?? "his-collection-chara_cd-capture",
    path: captureCPPHYTwPwvMeta?.path ?? "/his-collection/:chara_cd()/capture",
    meta: captureCPPHYTwPwvMeta || {},
    alias: captureCPPHYTwPwvMeta?.alias || [],
    redirect: captureCPPHYTwPwvMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/capture.vue").then(m => m.default || m)
  },
  {
    name: cardSnjXfDsBqlMeta?.name ?? "his-collection-chara_cd-card",
    path: cardSnjXfDsBqlMeta?.path ?? "/his-collection/:chara_cd()/card",
    meta: cardSnjXfDsBqlMeta || {},
    alias: cardSnjXfDsBqlMeta?.alias || [],
    redirect: cardSnjXfDsBqlMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/card.vue").then(m => m.default || m)
  },
  {
    name: indexnQI4l3G2gjMeta?.name ?? "his-collection-chara_cd",
    path: indexnQI4l3G2gjMeta?.path ?? "/his-collection/:chara_cd()",
    meta: indexnQI4l3G2gjMeta || {},
    alias: indexnQI4l3G2gjMeta?.alias || [],
    redirect: indexnQI4l3G2gjMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/index.vue").then(m => m.default || m)
  },
  {
    name: stillGnXF27dBswMeta?.name ?? "his-collection-chara_cd-still",
    path: stillGnXF27dBswMeta?.path ?? "/his-collection/:chara_cd()/still",
    meta: stillGnXF27dBswMeta || {},
    alias: stillGnXF27dBswMeta?.alias || [],
    redirect: stillGnXF27dBswMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/still.vue").then(m => m.default || m)
  },
  {
    name: storyj5oyb4YgL2Meta?.name ?? "his-collection-chara_cd-story",
    path: storyj5oyb4YgL2Meta?.path ?? "/his-collection/:chara_cd()/story",
    meta: storyj5oyb4YgL2Meta || {},
    alias: storyj5oyb4YgL2Meta?.alias || [],
    redirect: storyj5oyb4YgL2Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/his-collection/[chara_cd]/story.vue").then(m => m.default || m)
  },
  {
    name: indexeYteQacttXMeta?.name ?? "his-collection",
    path: indexeYteQacttXMeta?.path ?? "/his-collection",
    meta: indexeYteQacttXMeta || {},
    alias: indexeYteQacttXMeta?.alias || [],
    redirect: indexeYteQacttXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/his-collection/index.vue").then(m => m.default || m)
  },
  {
    name: complete9iEiYmtYqTMeta?.name ?? "his-collection-story-complete",
    path: complete9iEiYmtYqTMeta?.path ?? "/his-collection/story/complete",
    meta: complete9iEiYmtYqTMeta || {},
    alias: complete9iEiYmtYqTMeta?.alias || [],
    redirect: complete9iEiYmtYqTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/his-collection/story/complete.vue").then(m => m.default || m)
  },
  {
    name: index0oyHGV0Q4WMeta?.name ?? "index",
    path: index0oyHGV0Q4WMeta?.path ?? "/",
    meta: index0oyHGV0Q4WMeta || {},
    alias: index0oyHGV0Q4WMeta?.alias || [],
    redirect: index0oyHGV0Q4WMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: listDaXx97ILP0Meta?.name ?? "item-list",
    path: listDaXx97ILP0Meta?.path ?? "/item/list",
    meta: listDaXx97ILP0Meta || {},
    alias: listDaXx97ILP0Meta?.alias || [],
    redirect: listDaXx97ILP0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/item/list.vue").then(m => m.default || m)
  },
  {
    name: historyDQAwiM2bccMeta?.name ?? "minigame-history",
    path: historyDQAwiM2bccMeta?.path ?? "/minigame/history",
    meta: historyDQAwiM2bccMeta || {},
    alias: historyDQAwiM2bccMeta?.alias || [],
    redirect: historyDQAwiM2bccMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/minigame/history.vue").then(m => m.default || m)
  },
  {
    name: rentalsFt78wb7UXMeta?.name ?? "minigame-rental",
    path: rentalsFt78wb7UXMeta?.path ?? "/minigame/rental",
    meta: rentalsFt78wb7UXMeta || {},
    alias: rentalsFt78wb7UXMeta?.alias || [],
    redirect: rentalsFt78wb7UXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/minigame/rental.vue").then(m => m.default || m)
  },
  {
    name: top4OIOJE3KL9Meta?.name ?? "minigame-top",
    path: top4OIOJE3KL9Meta?.path ?? "/minigame/top",
    meta: top4OIOJE3KL9Meta || {},
    alias: top4OIOJE3KL9Meta?.alias || [],
    redirect: top4OIOJE3KL9Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/minigame/top.vue").then(m => m.default || m)
  },
  {
    name: mypageuMIv4hMfeOMeta?.name ?? "mypage",
    path: mypageuMIv4hMfeOMeta?.path ?? "/mypage",
    meta: mypageuMIv4hMfeOMeta || {},
    alias: mypageuMIv4hMfeOMeta?.alias || [],
    redirect: mypageuMIv4hMfeOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/mypage.vue").then(m => m.default || m)
  },
  {
    name: no_45supportJzpgytdJsDMeta?.name ?? "no-support",
    path: no_45supportJzpgytdJsDMeta?.path ?? "/no-support",
    meta: no_45supportJzpgytdJsDMeta || {},
    alias: no_45supportJzpgytdJsDMeta?.alias || [],
    redirect: no_45supportJzpgytdJsDMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/no-support.vue").then(m => m.default || m)
  },
  {
    name: indexO2mFVhXLVuMeta?.name ?? "panelmission",
    path: indexO2mFVhXLVuMeta?.path ?? "/panelmission",
    meta: indexO2mFVhXLVuMeta || {},
    alias: indexO2mFVhXLVuMeta?.alias || [],
    redirect: indexO2mFVhXLVuMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/panelmission/index.vue").then(m => m.default || m)
  },
  {
    name: history_45_91type_93DeLnHf12pLMeta?.name ?? "present-history-type",
    path: history_45_91type_93DeLnHf12pLMeta?.path ?? "/present/history-:type()",
    meta: history_45_91type_93DeLnHf12pLMeta || {},
    alias: history_45_91type_93DeLnHf12pLMeta?.alias || [],
    redirect: history_45_91type_93DeLnHf12pLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/present/history-[type].vue").then(m => m.default || m)
  },
  {
    name: listTgxbAD7IUqMeta?.name ?? "present-list",
    path: listTgxbAD7IUqMeta?.path ?? "/present/list",
    meta: listTgxbAD7IUqMeta || {},
    alias: listTgxbAD7IUqMeta?.alias || [],
    redirect: listTgxbAD7IUqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/present/list.vue").then(m => m.default || m)
  },
  {
    name: friendPWMceFgDSRMeta?.name ?? "profile-friend",
    path: friendPWMceFgDSRMeta?.path ?? "/profile/friend",
    meta: friendPWMceFgDSRMeta || {},
    alias: friendPWMceFgDSRMeta?.alias || [],
    redirect: friendPWMceFgDSRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/profile/friend.vue").then(m => m.default || m)
  },
  {
    name: indexkk66bZTKuNMeta?.name ?? "profile",
    path: indexkk66bZTKuNMeta?.path ?? "/profile",
    meta: indexkk66bZTKuNMeta || {},
    alias: indexkk66bZTKuNMeta?.alias || [],
    redirect: indexkk66bZTKuNMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: _91combinedsale_cd_93e1iYGtMCbVMeta?.name ?? "scenario-combinedsale_cd",
    path: _91combinedsale_cd_93e1iYGtMCbVMeta?.path ?? "/scenario/:combinedsale_cd()",
    meta: _91combinedsale_cd_93e1iYGtMCbVMeta || {},
    alias: _91combinedsale_cd_93e1iYGtMCbVMeta?.alias || [],
    redirect: _91combinedsale_cd_93e1iYGtMCbVMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/scenario/[combinedsale_cd].vue").then(m => m.default || m)
  },
  {
    name: index0f1kvXS9bcMeta?.name ?? "serialcode",
    path: index0f1kvXS9bcMeta?.path ?? "/serialcode",
    meta: index0f1kvXS9bcMeta || {},
    alias: index0f1kvXS9bcMeta?.alias || [],
    redirect: index0f1kvXS9bcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/serialcode/index.vue").then(m => m.default || m)
  },
  {
    name: account_45tradingRur21uFnCyMeta?.name ?? "setting-account-trading",
    path: account_45tradingRur21uFnCyMeta?.path ?? "/setting/account-trading",
    meta: account_45tradingRur21uFnCyMeta || {},
    alias: account_45tradingRur21uFnCyMeta?.alias || [],
    redirect: account_45tradingRur21uFnCyMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/account-trading.vue").then(m => m.default || m)
  },
  {
    name: copyright_45ristrictzYSMNlZIqZMeta?.name ?? "setting-copyright-ristrict",
    path: copyright_45ristrictzYSMNlZIqZMeta?.path ?? "/setting/copyright-ristrict",
    meta: copyright_45ristrictzYSMNlZIqZMeta || {},
    alias: copyright_45ristrictzYSMNlZIqZMeta?.alias || [],
    redirect: copyright_45ristrictzYSMNlZIqZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/copyright-ristrict.vue").then(m => m.default || m)
  },
  {
    name: copyright8Ig2SNChdEMeta?.name ?? "setting-copyright",
    path: copyright8Ig2SNChdEMeta?.path ?? "/setting/copyright",
    meta: copyright8Ig2SNChdEMeta || {},
    alias: copyright8Ig2SNChdEMeta?.alias || [],
    redirect: copyright8Ig2SNChdEMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/copyright.vue").then(m => m.default || m)
  },
  {
    name: creditsSo6rNMa22SMeta?.name ?? "setting-credits",
    path: creditsSo6rNMa22SMeta?.path ?? "/setting/credits",
    meta: creditsSo6rNMa22SMeta || {},
    alias: creditsSo6rNMa22SMeta?.alias || [],
    redirect: creditsSo6rNMa22SMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/credits.vue").then(m => m.default || m)
  },
  {
    name: helpCH62ldCh5yMeta?.name ?? "setting-help",
    path: helpCH62ldCh5yMeta?.path ?? "/setting/help",
    meta: helpCH62ldCh5yMeta || {},
    alias: helpCH62ldCh5yMeta?.alias || [],
    redirect: helpCH62ldCh5yMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/help.vue").then(m => m.default || m)
  },
  {
    name: licenseBpCLjJ7VHTMeta?.name ?? "setting-license",
    path: licenseBpCLjJ7VHTMeta?.path ?? "/setting/license",
    meta: licenseBpCLjJ7VHTMeta || {},
    alias: licenseBpCLjJ7VHTMeta?.alias || [],
    redirect: licenseBpCLjJ7VHTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/license.vue").then(m => m.default || m)
  },
  {
    name: menu7nJwT5nV2HMeta?.name ?? "setting-menu",
    path: menu7nJwT5nV2HMeta?.path ?? "/setting/menu",
    meta: menu7nJwT5nV2HMeta || {},
    alias: menu7nJwT5nV2HMeta?.alias || [],
    redirect: menu7nJwT5nV2HMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/menu.vue").then(m => m.default || m)
  },
  {
    name: payment_45service_45actgQvHbzWDOtMeta?.name ?? "setting-payment-service-act",
    path: payment_45service_45actgQvHbzWDOtMeta?.path ?? "/setting/payment-service-act",
    meta: payment_45service_45actgQvHbzWDOtMeta || {},
    alias: payment_45service_45actgQvHbzWDOtMeta?.alias || [],
    redirect: payment_45service_45actgQvHbzWDOtMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/payment-service-act.vue").then(m => m.default || m)
  },
  {
    name: term_45of_45servicecVMrSOo2PCMeta?.name ?? "setting-term-of-service",
    path: term_45of_45servicecVMrSOo2PCMeta?.path ?? "/setting/term-of-service",
    meta: term_45of_45servicecVMrSOo2PCMeta || {},
    alias: term_45of_45servicecVMrSOo2PCMeta?.alias || [],
    redirect: term_45of_45servicecVMrSOo2PCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/setting/term-of-service.vue").then(m => m.default || m)
  },
  {
    name: diayvBShTwaXCMeta?.name ?? "shop-dia",
    path: diayvBShTwaXCMeta?.path ?? "/shop/dia",
    meta: diayvBShTwaXCMeta || {},
    alias: diayvBShTwaXCMeta?.alias || [],
    redirect: diayvBShTwaXCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/shop/dia.vue").then(m => m.default || m)
  },
  {
    name: indexL9TQqQoq0pMeta?.name ?? "shop",
    path: indexL9TQqQoq0pMeta?.path ?? "/shop",
    meta: indexL9TQqQoq0pMeta || {},
    alias: indexL9TQqQoq0pMeta?.alias || [],
    redirect: indexL9TQqQoq0pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: itemqOitiKXK52Meta?.name ?? "shop-item",
    path: itemqOitiKXK52Meta?.path ?? "/shop/item",
    meta: itemqOitiKXK52Meta || {},
    alias: itemqOitiKXK52Meta?.alias || [],
    redirect: itemqOitiKXK52Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/shop/item.vue").then(m => m.default || m)
  },
  {
    name: setXIzIqfPwlqMeta?.name ?? "shop-set",
    path: setXIzIqfPwlqMeta?.path ?? "/shop/set",
    meta: setXIzIqfPwlqMeta || {},
    alias: setXIzIqfPwlqMeta?.alias || [],
    redirect: setXIzIqfPwlqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/shop/set.vue").then(m => m.default || m)
  },
  {
    name: completeSUU8bJUCm0Meta?.name ?? "story-complete",
    path: completeSUU8bJUCm0Meta?.path ?? "/story/complete",
    meta: completeSUU8bJUCm0Meta || {},
    alias: completeSUU8bJUCm0Meta?.alias || [],
    redirect: completeSUU8bJUCm0Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/story/complete.vue").then(m => m.default || m)
  },
  {
    name: item_45_91type_93NcoTDKbhHXMeta?.name ?? "story-item-type",
    path: item_45_91type_93NcoTDKbhHXMeta?.path ?? "/story/item-:type()",
    meta: item_45_91type_93NcoTDKbhHXMeta || {},
    alias: item_45_91type_93NcoTDKbhHXMeta?.alias || [],
    redirect: item_45_91type_93NcoTDKbhHXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/story/item-[type].vue").then(m => m.default || m)
  },
  {
    name: list6xoah1svM3Meta?.name ?? "story-list",
    path: list6xoah1svM3Meta?.path ?? "/story/list",
    meta: list6xoah1svM3Meta || {},
    alias: list6xoah1svM3Meta?.alias || [],
    redirect: list6xoah1svM3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/story/list.vue").then(m => m.default || m)
  },
  {
    name: missionYRNmvhXgxRMeta?.name ?? "story-mission",
    path: missionYRNmvhXgxRMeta?.path ?? "/story/mission",
    meta: missionYRNmvhXgxRMeta || {},
    alias: missionYRNmvhXgxRMeta?.alias || [],
    redirect: missionYRNmvhXgxRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/story/mission.vue").then(m => m.default || m)
  },
  {
    name: missionCompletelHJ0Nbk014Meta?.name ?? "story-missionComplete",
    path: missionCompletelHJ0Nbk014Meta?.path ?? "/story/missionComplete",
    meta: missionCompletelHJ0Nbk014Meta || {},
    alias: missionCompletelHJ0Nbk014Meta?.alias || [],
    redirect: missionCompletelHJ0Nbk014Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/story/missionComplete.vue").then(m => m.default || m)
  },
  {
    name: playzdkK48fBDxMeta?.name ?? "story-play",
    path: playzdkK48fBDxMeta?.path ?? "/story/play",
    meta: playzdkK48fBDxMeta || {},
    alias: playzdkK48fBDxMeta?.alias || [],
    redirect: playzdkK48fBDxMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/story/play.vue").then(m => m.default || m)
  },
  {
    name: viammW4x06Hv3Meta?.name ?? "story-via",
    path: viammW4x06Hv3Meta?.path ?? "/story/via",
    meta: viammW4x06Hv3Meta || {},
    alias: viammW4x06Hv3Meta?.alias || [],
    redirect: viammW4x06Hv3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/story/via.vue").then(m => m.default || m)
  },
  {
    name: indexgglOK12HNpMeta?.name ?? "terms-of-service",
    path: indexgglOK12HNpMeta?.path ?? "/terms-of-service",
    meta: indexgglOK12HNpMeta || {},
    alias: indexgglOK12HNpMeta?.alias || [],
    redirect: indexgglOK12HNpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/terms-of-service/index.vue").then(m => m.default || m)
  },
  {
    name: titleddJI9tKdnOMeta?.name ?? "title",
    path: titleddJI9tKdnOMeta?.path ?? "/title",
    meta: titleddJI9tKdnOMeta || {},
    alias: titleddJI9tKdnOMeta?.alias || [],
    redirect: titleddJI9tKdnOMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/title.vue").then(m => m.default || m)
  },
  {
    name: namezNFSQ3XY6QMeta?.name ?? "tutorial-name",
    path: namezNFSQ3XY6QMeta?.path ?? "/tutorial/name",
    meta: namezNFSQ3XY6QMeta || {},
    alias: namezNFSQ3XY6QMeta?.alias || [],
    redirect: namezNFSQ3XY6QMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/tutorial/name.vue").then(m => m.default || m)
  },
  {
    name: prologueAPGhxsY18CMeta?.name ?? "tutorial-prologue",
    path: prologueAPGhxsY18CMeta?.path ?? "/tutorial/prologue",
    meta: prologueAPGhxsY18CMeta || {},
    alias: prologueAPGhxsY18CMeta?.alias || [],
    redirect: prologueAPGhxsY18CMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/tutorial/prologue.vue").then(m => m.default || m)
  },
  {
    name: updateW8cOWZrPuWMeta?.name ?? "update",
    path: updateW8cOWZrPuWMeta?.path ?? "/update",
    meta: updateW8cOWZrPuWMeta || {},
    alias: updateW8cOWZrPuWMeta?.alias || [],
    redirect: updateW8cOWZrPuWMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/update.vue").then(m => m.default || m)
  },
  {
    name: version_45upb04pVJXrI3Meta?.name ?? "version-up",
    path: version_45upb04pVJXrI3Meta?.path ?? "/version-up",
    meta: version_45upb04pVJXrI3Meta || {},
    alias: version_45upb04pVJXrI3Meta?.alias || [],
    redirect: version_45upb04pVJXrI3Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/pages/version-up.vue").then(m => m.default || m)
  },
  {
    name: indexd3QWcTojeiMeta?.name ?? "_debug",
    path: indexd3QWcTojeiMeta?.path ?? "/_debug",
    meta: indexd3QWcTojeiMeta || {},
    alias: indexd3QWcTojeiMeta?.alias || [],
    redirect: indexd3QWcTojeiMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/node_modules/nuxt-debug/dist/runtime/pages/index.vue").then(m => m.default || m)
  },
  {
    name: cacheE0HNAVPbJZMeta?.name ?? "_debug-cache",
    path: cacheE0HNAVPbJZMeta?.path ?? "/_debug/cache",
    meta: cacheE0HNAVPbJZMeta || {},
    alias: cacheE0HNAVPbJZMeta?.alias || [],
    redirect: cacheE0HNAVPbJZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/node_modules/nuxt-debug/dist/runtime/pages/cache.vue").then(m => m.default || m)
  },
  {
    name: logFJ0lY1w3dgMeta?.name ?? "_debug-log",
    path: logFJ0lY1w3dgMeta?.path ?? "/_debug/log",
    meta: logFJ0lY1w3dgMeta || {},
    alias: logFJ0lY1w3dgMeta?.alias || [],
    redirect: logFJ0lY1w3dgMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/node_modules/nuxt-debug/dist/runtime/pages/log.vue").then(m => m.default || m)
  },
  {
    name: animateKuZG0XEmqCMeta?.name ?? "debugAnimate",
    path: animateKuZG0XEmqCMeta?.path ?? "/debug/animate",
    meta: animateKuZG0XEmqCMeta || {},
    alias: animateKuZG0XEmqCMeta?.alias || [],
    redirect: animateKuZG0XEmqCMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/animate.vue").then(m => m.default || m)
  },
  {
    name: audioYVU5L7bhRqMeta?.name ?? "debugAudio",
    path: audioYVU5L7bhRqMeta?.path ?? "/debug/audio",
    meta: audioYVU5L7bhRqMeta || {},
    alias: audioYVU5L7bhRqMeta?.alias || [],
    redirect: audioYVU5L7bhRqMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/audio.vue").then(m => m.default || m)
  },
  {
    name: authpKzMU8DAh7Meta?.name ?? "debugAuth",
    path: authpKzMU8DAh7Meta?.path ?? "/debug/auth",
    meta: authpKzMU8DAh7Meta || {},
    alias: authpKzMU8DAh7Meta?.alias || [],
    redirect: authpKzMU8DAh7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/auth.vue").then(m => m.default || m)
  },
  {
    name: debugButtonyBfflcWIYnMeta?.name ?? "debugDebugbutton",
    path: debugButtonyBfflcWIYnMeta?.path ?? "/debug/debugButton",
    meta: debugButtonyBfflcWIYnMeta || {},
    alias: debugButtonyBfflcWIYnMeta?.alias || [],
    redirect: debugButtonyBfflcWIYnMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/debugButton.vue").then(m => m.default || m)
  },
  {
    name: directionoG8aPTVuiPMeta?.name ?? "debugDirection",
    path: directionoG8aPTVuiPMeta?.path ?? "/debug/direction",
    meta: directionoG8aPTVuiPMeta || {},
    alias: directionoG8aPTVuiPMeta?.alias || [],
    redirect: directionoG8aPTVuiPMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/direction.vue").then(m => m.default || m)
  },
  {
    name: environmentGFwFTYkrM5Meta?.name ?? "debugEnvironment",
    path: environmentGFwFTYkrM5Meta?.path ?? "/debug/environment",
    meta: environmentGFwFTYkrM5Meta || {},
    alias: environmentGFwFTYkrM5Meta?.alias || [],
    redirect: environmentGFwFTYkrM5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/environment.vue").then(m => m.default || m)
  },
  {
    name: lottieD1S3GO0OczMeta?.name ?? "debugLottie",
    path: lottieD1S3GO0OczMeta?.path ?? "/debug/lottie",
    meta: lottieD1S3GO0OczMeta || {},
    alias: lottieD1S3GO0OczMeta?.alias || [],
    redirect: lottieD1S3GO0OczMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/lottie.vue").then(m => m.default || m)
  },
  {
    name: moviepDwB6lm78tMeta?.name ?? "debugMovie",
    path: moviepDwB6lm78tMeta?.path ?? "/debug/movie",
    meta: moviepDwB6lm78tMeta || {},
    alias: moviepDwB6lm78tMeta?.alias || [],
    redirect: moviepDwB6lm78tMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/movie.vue").then(m => m.default || m)
  },
  {
    name: playerPrefsZocNzFPXD7Meta?.name ?? "debugPlayerprefs",
    path: playerPrefsZocNzFPXD7Meta?.path ?? "/debug/playerPrefs",
    meta: playerPrefsZocNzFPXD7Meta || {},
    alias: playerPrefsZocNzFPXD7Meta?.alias || [],
    redirect: playerPrefsZocNzFPXD7Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/playerPrefs.vue").then(m => m.default || m)
  },
  {
    name: push8pEcrQ4YlTMeta?.name ?? "debugPush",
    path: push8pEcrQ4YlTMeta?.path ?? "/debug/push",
    meta: push8pEcrQ4YlTMeta || {},
    alias: push8pEcrQ4YlTMeta?.alias || [],
    redirect: push8pEcrQ4YlTMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/push.vue").then(m => m.default || m)
  },
  {
    name: screenfeJZciM8mSMeta?.name ?? "debugScreen",
    path: screenfeJZciM8mSMeta?.path ?? "/debug/screen",
    meta: screenfeJZciM8mSMeta || {},
    alias: screenfeJZciM8mSMeta?.alias || [],
    redirect: screenfeJZciM8mSMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/screen.vue").then(m => m.default || m)
  },
  {
    name: serverMZJyK4YeI8Meta?.name ?? "debugServer",
    path: serverMZJyK4YeI8Meta?.path ?? "/debug/server",
    meta: serverMZJyK4YeI8Meta || {},
    alias: serverMZJyK4YeI8Meta?.alias || [],
    redirect: serverMZJyK4YeI8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/server.vue").then(m => m.default || m)
  },
  {
    name: storageS7GrT2Xo2pMeta?.name ?? "debugStorage",
    path: storageS7GrT2Xo2pMeta?.path ?? "/debug/storage",
    meta: storageS7GrT2Xo2pMeta || {},
    alias: storageS7GrT2Xo2pMeta?.alias || [],
    redirect: storageS7GrT2Xo2pMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/storage.vue").then(m => m.default || m)
  },
  {
    name: ui9v2HlHGz1aMeta?.name ?? "debugUi",
    path: ui9v2HlHGz1aMeta?.path ?? "/debug/ui",
    meta: ui9v2HlHGz1aMeta || {},
    alias: ui9v2HlHGz1aMeta?.alias || [],
    redirect: ui9v2HlHGz1aMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src4034965912/src/src/debug/ui.vue").then(m => m.default || m)
  }
]