/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * スペシャルストーリーのアンロック時に消費消費するアイテムのタイプ
 * @export
 */
export const SpecialStoryUnlockPayType = {
    Gem: 'gem',
    Key: 'key'
} as const;
export type SpecialStoryUnlockPayType = typeof SpecialStoryUnlockPayType[keyof typeof SpecialStoryUnlockPayType];


export function SpecialStoryUnlockPayTypeFromJSON(json: any): SpecialStoryUnlockPayType {
    return SpecialStoryUnlockPayTypeFromJSONTyped(json, false);
}

export function SpecialStoryUnlockPayTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialStoryUnlockPayType {
    return json as SpecialStoryUnlockPayType;
}

export function SpecialStoryUnlockPayTypeToJSON(value?: SpecialStoryUnlockPayType | null): any {
    return value as any;
}

