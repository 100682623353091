/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuCharaBonus } from './CommuCharaBonus';
import {
    CommuCharaBonusFromJSON,
    CommuCharaBonusFromJSONTyped,
    CommuCharaBonusToJSON,
} from './CommuCharaBonus';
import type { CommuCharaLevelUpVoice } from './CommuCharaLevelUpVoice';
import {
    CommuCharaLevelUpVoiceFromJSON,
    CommuCharaLevelUpVoiceFromJSONTyped,
    CommuCharaLevelUpVoiceToJSON,
} from './CommuCharaLevelUpVoice';
import type { CommuCharaLimitBreak } from './CommuCharaLimitBreak';
import {
    CommuCharaLimitBreakFromJSON,
    CommuCharaLimitBreakFromJSONTyped,
    CommuCharaLimitBreakToJSON,
} from './CommuCharaLimitBreak';
import type { CommuCharaPortrait } from './CommuCharaPortrait';
import {
    CommuCharaPortraitFromJSON,
    CommuCharaPortraitFromJSONTyped,
    CommuCharaPortraitToJSON,
} from './CommuCharaPortrait';
import type { CommuCharaVoicePattern } from './CommuCharaVoicePattern';
import {
    CommuCharaVoicePatternFromJSON,
    CommuCharaVoicePatternFromJSONTyped,
    CommuCharaVoicePatternToJSON,
} from './CommuCharaVoicePattern';

/**
 * 
 * @export
 * @interface CommuChara
 */
export interface CommuChara {
    /**
     * キャラコード
     * @type {string}
     * @memberof CommuChara
     */
    charaCd: string;
    /**
     * レベルパターンコード
     * @type {string}
     * @memberof CommuChara
     */
    levelPatternCd: string;
    /**
     * デフォルト最大レベル
     * @type {number}
     * @memberof CommuChara
     */
    defaultMaxLevel: number;
    /**
     * コミュ機能解放日時
     * @type {string}
     * @memberof CommuChara
     */
    startedAt: string;
    /**
     * コミュ機能終了日時
     * @type {string}
     * @memberof CommuChara
     */
    endedAt: string;
    /**
     * 
     * @type {CommuCharaLevelUpVoice}
     * @memberof CommuChara
     */
    levelUpVoice: CommuCharaLevelUpVoice;
    /**
     * レベル限界突破リスト
     * @type {Array<CommuCharaLimitBreak>}
     * @memberof CommuChara
     */
    limitBreakList: Array<CommuCharaLimitBreak>;
    /**
     * ボイスパターンリスト
     * @type {Array<CommuCharaVoicePattern>}
     * @memberof CommuChara
     */
    voicePatternList: Array<CommuCharaVoicePattern>;
    /**
     * 立ち絵リスト
     * @type {Array<CommuCharaPortrait>}
     * @memberof CommuChara
     */
    portraitList: Array<CommuCharaPortrait>;
    /**
     * 特典リスト
     * @type {Array<CommuCharaBonus>}
     * @memberof CommuChara
     */
    bonusList: Array<CommuCharaBonus>;
}

/**
 * Check if a given object implements the CommuChara interface.
 */
export function instanceOfCommuChara(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "levelPatternCd" in value;
    isInstance = isInstance && "defaultMaxLevel" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "levelUpVoice" in value;
    isInstance = isInstance && "limitBreakList" in value;
    isInstance = isInstance && "voicePatternList" in value;
    isInstance = isInstance && "portraitList" in value;
    isInstance = isInstance && "bonusList" in value;

    return isInstance;
}

export function CommuCharaFromJSON(json: any): CommuChara {
    return CommuCharaFromJSONTyped(json, false);
}

export function CommuCharaFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuChara {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'levelPatternCd': json['level_pattern_cd'],
        'defaultMaxLevel': json['default_max_level'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'levelUpVoice': CommuCharaLevelUpVoiceFromJSON(json['level_up_voice']),
        'limitBreakList': ((json['limit_break_list'] as Array<any>).map(CommuCharaLimitBreakFromJSON)),
        'voicePatternList': ((json['voice_pattern_list'] as Array<any>).map(CommuCharaVoicePatternFromJSON)),
        'portraitList': ((json['portrait_list'] as Array<any>).map(CommuCharaPortraitFromJSON)),
        'bonusList': ((json['bonus_list'] as Array<any>).map(CommuCharaBonusFromJSON)),
    };
}

export function CommuCharaToJSON(value?: CommuChara | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'level_pattern_cd': value.levelPatternCd,
        'default_max_level': value.defaultMaxLevel,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'level_up_voice': CommuCharaLevelUpVoiceToJSON(value.levelUpVoice),
        'limit_break_list': ((value.limitBreakList as Array<any>).map(CommuCharaLimitBreakToJSON)),
        'voice_pattern_list': ((value.voicePatternList as Array<any>).map(CommuCharaVoicePatternToJSON)),
        'portrait_list': ((value.portraitList as Array<any>).map(CommuCharaPortraitToJSON)),
        'bonus_list': ((value.bonusList as Array<any>).map(CommuCharaBonusToJSON)),
    };
}

