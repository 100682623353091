/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventStoryListNextTicketMission } from './EventStoryListNextTicketMission';
import {
    EventStoryListNextTicketMissionFromJSON,
    EventStoryListNextTicketMissionFromJSONTyped,
    EventStoryListNextTicketMissionToJSON,
} from './EventStoryListNextTicketMission';

/**
 * 次のチケット情報（ミッション発生中は次に進めないため現在のチケット情報が入る）
 * @export
 * @interface EventStoryListNextTicket
 */
export interface EventStoryListNextTicket {
    /**
     * 次のチケットのチケットコード
     * @type {string}
     * @memberof EventStoryListNextTicket
     */
    ticketCd: string;
    /**
     * 次のチケットのチケットNo
     * @type {number}
     * @memberof EventStoryListNextTicket
     */
    ticketNo: number;
    /**
     * 次のチケットのチャプターコード
     * @type {string}
     * @memberof EventStoryListNextTicket
     */
    chapterCd: string;
    /**
     * 次のチケットのチャプターNo
     * @type {number}
     * @memberof EventStoryListNextTicket
     */
    chapterNo: number;
    /**
     * 
     * @type {EventStoryListNextTicketMission}
     * @memberof EventStoryListNextTicket
     */
    mission: EventStoryListNextTicketMission | null;
}

/**
 * Check if a given object implements the EventStoryListNextTicket interface.
 */
export function instanceOfEventStoryListNextTicket(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ticketCd" in value;
    isInstance = isInstance && "ticketNo" in value;
    isInstance = isInstance && "chapterCd" in value;
    isInstance = isInstance && "chapterNo" in value;
    isInstance = isInstance && "mission" in value;

    return isInstance;
}

export function EventStoryListNextTicketFromJSON(json: any): EventStoryListNextTicket {
    return EventStoryListNextTicketFromJSONTyped(json, false);
}

export function EventStoryListNextTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStoryListNextTicket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticketCd': json['ticket_cd'],
        'ticketNo': json['ticket_no'],
        'chapterCd': json['chapter_cd'],
        'chapterNo': json['chapter_no'],
        'mission': EventStoryListNextTicketMissionFromJSON(json['mission']),
    };
}

export function EventStoryListNextTicketToJSON(value?: EventStoryListNextTicket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticket_cd': value.ticketCd,
        'ticket_no': value.ticketNo,
        'chapter_cd': value.chapterCd,
        'chapter_no': value.chapterNo,
        'mission': EventStoryListNextTicketMissionToJSON(value.mission),
    };
}

