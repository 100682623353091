export const CommonNaming = {
  avatar: 'ルームアイテム',
  avatarRoom: 'ルーム',
  avatarMy: 'マイキャラ',
  avatarHis: '彼キャラ',
  avatarInterior: 'インテリア',
  avatarDressing: '着替え',
  avatarRedecorate: '模様替え',
  avatarCloset: 'クローゼット',
  avatarP: '魅力値',
  totalAvatar: '総合魅力',
  totalAvatarP: '総合魅力値',
  avatarMission: 'ルームミッション',
  collection: '彼コレ',
  commu: 'コミュ',
  commuP: 'コミュpt',
  commuLv: 'コミュLv',
  deck: 'ユニット',
  deckLeader: 'リーダー',
  gem: 'ダイヤ',
  gesoCoin: 'ゲソコイン',
  gold: 'コイン',
  itemBox: '持ち物',
  loveP: '好感度',
  lovePItem: 'ドロップ',
  lovePItem1: 'Meltyドロップ',
  lovePItem2: 'Passionドロップ',
  lovePItemBoth: 'Specialドロップ',
  minigame: 'お出かけ',
  minigameP: '親密度',
  minigameItem: '香水',
  parameterMission: '親密度ミッション',
  presentBox: 'プレゼントBOX',
  shop: 'ショップ',
  gemShop: 'ダイヤショップ',
  staminaP: '体力',
  staminaItem: 'ショコラ',
  story: 'ストーリー',
  storyTicket: 'ストーリーチケット',
  storyScene: 'シーン',
  storyRouteNameA: 'Melty',
  storyRouteNameB: 'Passion',
  event: 'ストーリーイベント',
  eventParameterMission: 'ラブ度ミッション',
  eventP: 'ラブ度',
  eventItem: 'カクテル',
  eventMinigame: 'スペシャルデート',
  eventMinigameName: 'スペシャルデート',
  card: 'カード',
  feverTime: 'フィーバータイム',
  followCount: 'フォロー数',
  followerCount: 'フォロワー数',
  likeCount: 'いいね',
};
