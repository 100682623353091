/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventMstEpilogChara
 */
export interface EventMstEpilogChara {
    /**
     * エピローグアイテムコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    epilogItemCd: string;
    /**
     * キャラコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    charaCd: string;
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    eventCharaCd: string;
    /**
     * イベントルートコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    eventRouteCd: string;
    /**
     * ダイジェストチケットコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    digestTicketCd: string;
    /**
     * ダイジェストチャプターコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    digestChapterCd: string;
    /**
     * プレゼントアイテムコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    presentItemCd: string;
    /**
     * アバターアイテムコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    avatarItemCd: string;
    /**
     * ショップアイテムコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    shopItemCd: string;
    /**
     * 値段
     * @type {number}
     * @memberof EventMstEpilogChara
     */
    gemPrice: number;
    /**
     * 特別ストーリーコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    specialStoryCd: string;
    /**
     * 特別ストーリーチケットコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    specialStoryTicketCd?: string;
    /**
     * 特別ストーリーチャプターコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    specialStoryChapterCd?: string;
    /**
     * ダイジェストプレビュー
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    digestPreview: string;
    /**
     * プレビュー
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    preview: string;
    /**
     * スチルコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    stillCd: string;
    /**
     * キャライメージコード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    charaImageCd: string;
    /**
     * キャラ素体画像コード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    bodyImageCd: string | null;
    /**
     * キャラ素体リソースパーティション
     * @type {number}
     * @memberof EventMstEpilogChara
     */
    bodyResourcePartition: number | null;
    /**
     * キャラ表情画像コード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    faceImageCd: string | null;
    /**
     * キャラ表情リソースパーティション
     * @type {number}
     * @memberof EventMstEpilogChara
     */
    faceResourcePartition: number | null;
    /**
     * 背景画像コード
     * @type {string}
     * @memberof EventMstEpilogChara
     */
    bgCd: string | null;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof EventMstEpilogChara
     */
    bgResourcePartition: number | null;
}

/**
 * Check if a given object implements the EventMstEpilogChara interface.
 */
export function instanceOfEventMstEpilogChara(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "epilogItemCd" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "eventRouteCd" in value;
    isInstance = isInstance && "digestTicketCd" in value;
    isInstance = isInstance && "digestChapterCd" in value;
    isInstance = isInstance && "presentItemCd" in value;
    isInstance = isInstance && "avatarItemCd" in value;
    isInstance = isInstance && "shopItemCd" in value;
    isInstance = isInstance && "gemPrice" in value;
    isInstance = isInstance && "specialStoryCd" in value;
    isInstance = isInstance && "digestPreview" in value;
    isInstance = isInstance && "preview" in value;
    isInstance = isInstance && "stillCd" in value;
    isInstance = isInstance && "charaImageCd" in value;
    isInstance = isInstance && "bodyImageCd" in value;
    isInstance = isInstance && "bodyResourcePartition" in value;
    isInstance = isInstance && "faceImageCd" in value;
    isInstance = isInstance && "faceResourcePartition" in value;
    isInstance = isInstance && "bgCd" in value;
    isInstance = isInstance && "bgResourcePartition" in value;

    return isInstance;
}

export function EventMstEpilogCharaFromJSON(json: any): EventMstEpilogChara {
    return EventMstEpilogCharaFromJSONTyped(json, false);
}

export function EventMstEpilogCharaFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventMstEpilogChara {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epilogItemCd': json['epilog_item_cd'],
        'charaCd': json['chara_cd'],
        'eventCharaCd': json['event_chara_cd'],
        'eventRouteCd': json['event_route_cd'],
        'digestTicketCd': json['digest_ticket_cd'],
        'digestChapterCd': json['digest_chapter_cd'],
        'presentItemCd': json['present_item_cd'],
        'avatarItemCd': json['avatar_item_cd'],
        'shopItemCd': json['shop_item_cd'],
        'gemPrice': json['gem_price'],
        'specialStoryCd': json['special_story_cd'],
        'specialStoryTicketCd': !exists(json, 'special_story_ticket_cd') ? undefined : json['special_story_ticket_cd'],
        'specialStoryChapterCd': !exists(json, 'special_story_chapter_cd') ? undefined : json['special_story_chapter_cd'],
        'digestPreview': json['digest_preview'],
        'preview': json['preview'],
        'stillCd': json['still_cd'],
        'charaImageCd': json['chara_image_cd'],
        'bodyImageCd': json['body_image_cd'],
        'bodyResourcePartition': json['body_resource_partition'],
        'faceImageCd': json['face_image_cd'],
        'faceResourcePartition': json['face_resource_partition'],
        'bgCd': json['bg_cd'],
        'bgResourcePartition': json['bg_resource_partition'],
    };
}

export function EventMstEpilogCharaToJSON(value?: EventMstEpilogChara | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epilog_item_cd': value.epilogItemCd,
        'chara_cd': value.charaCd,
        'event_chara_cd': value.eventCharaCd,
        'event_route_cd': value.eventRouteCd,
        'digest_ticket_cd': value.digestTicketCd,
        'digest_chapter_cd': value.digestChapterCd,
        'present_item_cd': value.presentItemCd,
        'avatar_item_cd': value.avatarItemCd,
        'shop_item_cd': value.shopItemCd,
        'gem_price': value.gemPrice,
        'special_story_cd': value.specialStoryCd,
        'special_story_ticket_cd': value.specialStoryTicketCd,
        'special_story_chapter_cd': value.specialStoryChapterCd,
        'digest_preview': value.digestPreview,
        'preview': value.preview,
        'still_cd': value.stillCd,
        'chara_image_cd': value.charaImageCd,
        'body_image_cd': value.bodyImageCd,
        'body_resource_partition': value.bodyResourcePartition,
        'face_image_cd': value.faceImageCd,
        'face_resource_partition': value.faceResourcePartition,
        'bg_cd': value.bgCd,
        'bg_resource_partition': value.bgResourcePartition,
    };
}

