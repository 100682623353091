/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Card } from './Card';
import {
    CardFromJSON,
    CardFromJSONTyped,
    CardToJSON,
} from './Card';

/**
 * 
 * @export
 * @interface CardMstResponse
 */
export interface CardMstResponse {
    /**
     * 
     * @type {Array<Card>}
     * @memberof CardMstResponse
     */
    data: Array<Card>;
}

/**
 * Check if a given object implements the CardMstResponse interface.
 */
export function instanceOfCardMstResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CardMstResponseFromJSON(json: any): CardMstResponse {
    return CardMstResponseFromJSONTyped(json, false);
}

export function CardMstResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardMstResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(CardFromJSON)),
    };
}

export function CardMstResponseToJSON(value?: CardMstResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(CardToJSON)),
    };
}

