/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoryAuth } from './StoryAuth';
import {
    StoryAuthFromJSON,
    StoryAuthFromJSONTyped,
    StoryAuthToJSON,
} from './StoryAuth';

/**
 * 
 * @export
 * @interface StoryAuthResponse
 */
export interface StoryAuthResponse {
    /**
     * 
     * @type {StoryAuth}
     * @memberof StoryAuthResponse
     */
    data: StoryAuth;
}

/**
 * Check if a given object implements the StoryAuthResponse interface.
 */
export function instanceOfStoryAuthResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function StoryAuthResponseFromJSON(json: any): StoryAuthResponse {
    return StoryAuthResponseFromJSONTyped(json, false);
}

export function StoryAuthResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryAuthResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': StoryAuthFromJSON(json['data']),
    };
}

export function StoryAuthResponseToJSON(value?: StoryAuthResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': StoryAuthToJSON(value.data),
    };
}

