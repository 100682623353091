/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventStoryStatus } from './UserEventStoryStatus';
import {
    UserEventStoryStatusFromJSON,
    UserEventStoryStatusFromJSONTyped,
    UserEventStoryStatusToJSON,
} from './UserEventStoryStatus';

/**
 * 
 * @export
 * @interface EventStoryListResponse
 */
export interface EventStoryListResponse {
    /**
     * 
     * @type {UserEventStoryStatus}
     * @memberof EventStoryListResponse
     */
    data: UserEventStoryStatus;
}

/**
 * Check if a given object implements the EventStoryListResponse interface.
 */
export function instanceOfEventStoryListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function EventStoryListResponseFromJSON(json: any): EventStoryListResponse {
    return EventStoryListResponseFromJSONTyped(json, false);
}

export function EventStoryListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStoryListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserEventStoryStatusFromJSON(json['data']),
    };
}

export function EventStoryListResponseToJSON(value?: EventStoryListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserEventStoryStatusToJSON(value.data),
    };
}

