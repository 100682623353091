/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCollectionBadge
 */
export interface UserCollectionBadge {
    /**
     * キャラコード
     * @type {string}
     * @memberof UserCollectionBadge
     */
    charaCd: string;
    /**
     * キャラマスタのタイプ
     * @type {string}
     * @memberof UserCollectionBadge
     */
    type: UserCollectionBadgeTypeEnum;
    /**
     * 所持カードの詳細未読数
     * @type {number}
     * @memberof UserCollectionBadge
     */
    card: number;
    /**
     * 所持スチルの詳細未読数
     * @type {number}
     * @memberof UserCollectionBadge
     */
    still: number;
    /**
     * 保存ストーリーの未読数
     * @type {number}
     * @memberof UserCollectionBadge
     */
    story: number;
}


/**
 * @export
 */
export const UserCollectionBadgeTypeEnum = {
    Main: 'main',
    Etc: 'etc'
} as const;
export type UserCollectionBadgeTypeEnum = typeof UserCollectionBadgeTypeEnum[keyof typeof UserCollectionBadgeTypeEnum];


/**
 * Check if a given object implements the UserCollectionBadge interface.
 */
export function instanceOfUserCollectionBadge(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "card" in value;
    isInstance = isInstance && "still" in value;
    isInstance = isInstance && "story" in value;

    return isInstance;
}

export function UserCollectionBadgeFromJSON(json: any): UserCollectionBadge {
    return UserCollectionBadgeFromJSONTyped(json, false);
}

export function UserCollectionBadgeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCollectionBadge {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'type': json['type'],
        'card': json['card'],
        'still': json['still'],
        'story': json['story'],
    };
}

export function UserCollectionBadgeToJSON(value?: UserCollectionBadge | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'type': value.type,
        'card': value.card,
        'still': value.still,
        'story': value.story,
    };
}

