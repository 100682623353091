/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuAddPoint } from './CommuAddPoint';
import {
    CommuAddPointFromJSON,
    CommuAddPointFromJSONTyped,
    CommuAddPointToJSON,
} from './CommuAddPoint';
import type { MissionCompleteAvatar } from './MissionCompleteAvatar';
import {
    MissionCompleteAvatarFromJSON,
    MissionCompleteAvatarFromJSONTyped,
    MissionCompleteAvatarToJSON,
} from './MissionCompleteAvatar';
import type { MissionCompleteNextTicket } from './MissionCompleteNextTicket';
import {
    MissionCompleteNextTicketFromJSON,
    MissionCompleteNextTicketFromJSONTyped,
    MissionCompleteNextTicketToJSON,
} from './MissionCompleteNextTicket';
import type { MissionCompleteNowTicket } from './MissionCompleteNowTicket';
import {
    MissionCompleteNowTicketFromJSON,
    MissionCompleteNowTicketFromJSONTyped,
    MissionCompleteNowTicketToJSON,
} from './MissionCompleteNowTicket';
import type { UserMissionCompleteMission } from './UserMissionCompleteMission';
import {
    UserMissionCompleteMissionFromJSON,
    UserMissionCompleteMissionFromJSONTyped,
    UserMissionCompleteMissionToJSON,
} from './UserMissionCompleteMission';
import type { UserStoryCompleteMissionBonus } from './UserStoryCompleteMissionBonus';
import {
    UserStoryCompleteMissionBonusFromJSON,
    UserStoryCompleteMissionBonusFromJSONTyped,
    UserStoryCompleteMissionBonusToJSON,
} from './UserStoryCompleteMissionBonus';

/**
 * 
 * @export
 * @interface UserMissionComplete
 */
export interface UserMissionComplete {
    /**
     * クリアしたミッションコード
     * @type {string}
     * @memberof UserMissionComplete
     */
    missionCd: string;
    /**
     * クリアしたミッションのタイプ avatar, route, parameter
     * @type {string}
     * @memberof UserMissionComplete
     */
    missionType: string;
    /**
     * 
     * @type {MissionCompleteNowTicket}
     * @memberof UserMissionComplete
     */
    nowTicket: MissionCompleteNowTicket;
    /**
     * 
     * @type {MissionCompleteNextTicket}
     * @memberof UserMissionComplete
     */
    nextTicket: MissionCompleteNextTicket;
    /**
     * 
     * @type {MissionCompleteAvatar}
     * @memberof UserMissionComplete
     */
    avatar: MissionCompleteAvatar;
    /**
     * 
     * @type {UserMissionCompleteMission}
     * @memberof UserMissionComplete
     */
    mission: UserMissionCompleteMission;
    /**
     * ミッションクリア報酬アイテム情報
     * @type {Array<UserStoryCompleteMissionBonus>}
     * @memberof UserMissionComplete
     */
    bonusList: Array<UserStoryCompleteMissionBonus>;
    /**
     * コミュpt上昇結果
     * @type {Array<CommuAddPoint>}
     * @memberof UserMissionComplete
     */
    commuAddPointList: Array<CommuAddPoint>;
}

/**
 * Check if a given object implements the UserMissionComplete interface.
 */
export function instanceOfUserMissionComplete(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "missionType" in value;
    isInstance = isInstance && "nowTicket" in value;
    isInstance = isInstance && "nextTicket" in value;
    isInstance = isInstance && "avatar" in value;
    isInstance = isInstance && "mission" in value;
    isInstance = isInstance && "bonusList" in value;
    isInstance = isInstance && "commuAddPointList" in value;

    return isInstance;
}

export function UserMissionCompleteFromJSON(json: any): UserMissionComplete {
    return UserMissionCompleteFromJSONTyped(json, false);
}

export function UserMissionCompleteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMissionComplete {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'missionType': json['mission_type'],
        'nowTicket': MissionCompleteNowTicketFromJSON(json['now_ticket']),
        'nextTicket': MissionCompleteNextTicketFromJSON(json['next_ticket']),
        'avatar': MissionCompleteAvatarFromJSON(json['avatar']),
        'mission': UserMissionCompleteMissionFromJSON(json['mission']),
        'bonusList': ((json['bonus_list'] as Array<any>).map(UserStoryCompleteMissionBonusFromJSON)),
        'commuAddPointList': ((json['commu_add_point_list'] as Array<any>).map(CommuAddPointFromJSON)),
    };
}

export function UserMissionCompleteToJSON(value?: UserMissionComplete | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'mission_type': value.missionType,
        'now_ticket': MissionCompleteNowTicketToJSON(value.nowTicket),
        'next_ticket': MissionCompleteNextTicketToJSON(value.nextTicket),
        'avatar': MissionCompleteAvatarToJSON(value.avatar),
        'mission': UserMissionCompleteMissionToJSON(value.mission),
        'bonus_list': ((value.bonusList as Array<any>).map(UserStoryCompleteMissionBonusToJSON)),
        'commu_add_point_list': ((value.commuAddPointList as Array<any>).map(CommuAddPointToJSON)),
    };
}

