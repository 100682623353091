/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserStill
 */
export interface UserStill {
    /**
     * スチルコード
     * @type {string}
     * @memberof UserStill
     */
    stillCd: string;
    /**
     * 既読フラグ。スチルコレクションの画面で詳細表示をしたかどうかを評価。
     * @type {boolean}
     * @memberof UserStill
     */
    readFlg: boolean;
}

/**
 * Check if a given object implements the UserStill interface.
 */
export function instanceOfUserStill(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stillCd" in value;
    isInstance = isInstance && "readFlg" in value;

    return isInstance;
}

export function UserStillFromJSON(json: any): UserStill {
    return UserStillFromJSONTyped(json, false);
}

export function UserStillFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stillCd': json['still_cd'],
        'readFlg': json['read_flg'],
    };
}

export function UserStillToJSON(value?: UserStill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'still_cd': value.stillCd,
        'read_flg': value.readFlg,
    };
}

