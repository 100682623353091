/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventBonus
 */
export interface UserEventBonus {
    /**
     * イベントボーナスコード
     * @type {string}
     * @memberof UserEventBonus
     */
    eventBonusCd: string;
    /**
     * 取得済み状況(true:取得済み)
     * @type {boolean}
     * @memberof UserEventBonus
     */
    getFlg: boolean;
    /**
     * 有効期限有無フラグ
     * @type {boolean}
     * @memberof UserEventBonus
     */
    isLimitFlg: boolean;
    /**
     * 特典有効期限(sec)
     * 特典有効期限内の場合は0以上の残り時間を返す
     * 特典有効期限を過ぎている場合は-1を返す
     * 有効期限がない特典の場合も-1を返す
     * 特典未獲得、獲得済みに関わらず、残り時間を返す
     * @type {number}
     * @memberof UserEventBonus
     */
    limitTime: number;
}

/**
 * Check if a given object implements the UserEventBonus interface.
 */
export function instanceOfUserEventBonus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventBonusCd" in value;
    isInstance = isInstance && "getFlg" in value;
    isInstance = isInstance && "isLimitFlg" in value;
    isInstance = isInstance && "limitTime" in value;

    return isInstance;
}

export function UserEventBonusFromJSON(json: any): UserEventBonus {
    return UserEventBonusFromJSONTyped(json, false);
}

export function UserEventBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventBonusCd': json['event_bonus_cd'],
        'getFlg': json['get_flg'],
        'isLimitFlg': json['is_limit_flg'],
        'limitTime': json['limit_time'],
    };
}

export function UserEventBonusToJSON(value?: UserEventBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_bonus_cd': value.eventBonusCd,
        'get_flg': value.getFlg,
        'is_limit_flg': value.isLimitFlg,
        'limit_time': value.limitTime,
    };
}

