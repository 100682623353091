/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';

/**
 * 
 * @export
 * @interface TutorialSaveResponse
 */
export interface TutorialSaveResponse {
    /**
     * API実行後のユーザーのtutorial_cd
     * @type {string}
     * @memberof TutorialSaveResponse
     */
    currentTutorialCd: string;
    /**
     * 
     * @type {MetaCommon}
     * @memberof TutorialSaveResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the TutorialSaveResponse interface.
 */
export function instanceOfTutorialSaveResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "currentTutorialCd" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function TutorialSaveResponseFromJSON(json: any): TutorialSaveResponse {
    return TutorialSaveResponseFromJSONTyped(json, false);
}

export function TutorialSaveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TutorialSaveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'currentTutorialCd': json['current_tutorial_cd'],
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function TutorialSaveResponseToJSON(value?: TutorialSaveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'current_tutorial_cd': value.currentTutorialCd,
        'meta': MetaCommonToJSON(value.meta),
    };
}

