/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserPresentHistory } from './UserPresentHistory';
import {
    UserPresentHistoryFromJSON,
    UserPresentHistoryFromJSONTyped,
    UserPresentHistoryToJSON,
} from './UserPresentHistory';

/**
 * 
 * @export
 * @interface UserPresentHistoryResponse
 */
export interface UserPresentHistoryResponse {
    /**
     * 
     * @type {Array<UserPresentHistory>}
     * @memberof UserPresentHistoryResponse
     */
    data: Array<UserPresentHistory>;
}

/**
 * Check if a given object implements the UserPresentHistoryResponse interface.
 */
export function instanceOfUserPresentHistoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserPresentHistoryResponseFromJSON(json: any): UserPresentHistoryResponse {
    return UserPresentHistoryResponseFromJSONTyped(json, false);
}

export function UserPresentHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresentHistoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserPresentHistoryFromJSON)),
    };
}

export function UserPresentHistoryResponseToJSON(value?: UserPresentHistoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserPresentHistoryToJSON)),
    };
}

