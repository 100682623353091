/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGameStartResponseData
 */
export interface UserGameStartResponseData {
    /**
     * Firebase UID
     * @type {string}
     * @memberof UserGameStartResponseData
     */
    uid: string;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserGameStartResponseData
     */
    playerCode: string;
    /**
     * チュートリアルコード
     * @type {string}
     * @memberof UserGameStartResponseData
     */
    tutorialCd: string;
    /**
     * 端末に保存する認証用gameToken
     * @type {string}
     * @memberof UserGameStartResponseData
     */
    gameToken?: string;
}

/**
 * Check if a given object implements the UserGameStartResponseData interface.
 */
export function instanceOfUserGameStartResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "uid" in value;
    isInstance = isInstance && "playerCode" in value;
    isInstance = isInstance && "tutorialCd" in value;

    return isInstance;
}

export function UserGameStartResponseDataFromJSON(json: any): UserGameStartResponseData {
    return UserGameStartResponseDataFromJSONTyped(json, false);
}

export function UserGameStartResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGameStartResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uid': json['uid'],
        'playerCode': json['player_code'],
        'tutorialCd': json['tutorial_cd'],
        'gameToken': !exists(json, 'game_token') ? undefined : json['game_token'],
    };
}

export function UserGameStartResponseDataToJSON(value?: UserGameStartResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uid': value.uid,
        'player_code': value.playerCode,
        'tutorial_cd': value.tutorialCd,
        'game_token': value.gameToken,
    };
}

