<template>
  <div>
    <div v-for="(v, i) in textList" :key="i" :class="{'mt-4': !v}">
      {{ v }}
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  text: string,
}>();
/**
 * 改行コードでテキストを分割
 */
const textList = computed(() => {
  return props.text.split(/\\n|\n/);
});
</script>
