/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGachaLineupItemProbabilityPickup
 */
export interface UserGachaLineupItemProbabilityPickup {
    /**
     * 声優名
     * @type {string}
     * @memberof UserGachaLineupItemProbabilityPickup
     */
    cv: string;
    /**
     * ボイス再生コード
     * @type {string}
     * @memberof UserGachaLineupItemProbabilityPickup
     */
    voiceCd: string | null;
    /**
     * ボイスキャラコード
     * @type {string}
     * @memberof UserGachaLineupItemProbabilityPickup
     */
    voiceCharaCd: string | null;
    /**
     * ボイス再生リソースパーティション
     * @type {number}
     * @memberof UserGachaLineupItemProbabilityPickup
     */
    voiceResourcePartition: number | null;
}

/**
 * Check if a given object implements the UserGachaLineupItemProbabilityPickup interface.
 */
export function instanceOfUserGachaLineupItemProbabilityPickup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cv" in value;
    isInstance = isInstance && "voiceCd" in value;
    isInstance = isInstance && "voiceCharaCd" in value;
    isInstance = isInstance && "voiceResourcePartition" in value;

    return isInstance;
}

export function UserGachaLineupItemProbabilityPickupFromJSON(json: any): UserGachaLineupItemProbabilityPickup {
    return UserGachaLineupItemProbabilityPickupFromJSONTyped(json, false);
}

export function UserGachaLineupItemProbabilityPickupFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaLineupItemProbabilityPickup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cv': json['cv'],
        'voiceCd': json['voice_cd'],
        'voiceCharaCd': json['voice_chara_cd'],
        'voiceResourcePartition': json['voice_resource_partition'],
    };
}

export function UserGachaLineupItemProbabilityPickupToJSON(value?: UserGachaLineupItemProbabilityPickup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cv': value.cv,
        'voice_cd': value.voiceCd,
        'voice_chara_cd': value.voiceCharaCd,
        'voice_resource_partition': value.voiceResourcePartition,
    };
}

