/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';
import type { UserStill } from './UserStill';
import {
    UserStillFromJSON,
    UserStillFromJSONTyped,
    UserStillToJSON,
} from './UserStill';

/**
 * 
 * @export
 * @interface UserStillListResponse
 */
export interface UserStillListResponse {
    /**
     * 
     * @type {Array<UserStill>}
     * @memberof UserStillListResponse
     */
    data: Array<UserStill>;
    /**
     * 
     * @type {MetaCommon}
     * @memberof UserStillListResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the UserStillListResponse interface.
 */
export function instanceOfUserStillListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function UserStillListResponseFromJSON(json: any): UserStillListResponse {
    return UserStillListResponseFromJSONTyped(json, false);
}

export function UserStillListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStillListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserStillFromJSON)),
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function UserStillListResponseToJSON(value?: UserStillListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserStillToJSON)),
        'meta': MetaCommonToJSON(value.meta),
    };
}

