/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserCommuCharaLimitbreak } from './UserCommuCharaLimitbreak';
import {
    UserCommuCharaLimitbreakFromJSON,
    UserCommuCharaLimitbreakFromJSONTyped,
    UserCommuCharaLimitbreakToJSON,
} from './UserCommuCharaLimitbreak';

/**
 * 
 * @export
 * @interface CommuCharaLimitbreakResponse
 */
export interface CommuCharaLimitbreakResponse {
    /**
     * 
     * @type {UserCommuCharaLimitbreak}
     * @memberof CommuCharaLimitbreakResponse
     */
    data: UserCommuCharaLimitbreak;
}

/**
 * Check if a given object implements the CommuCharaLimitbreakResponse interface.
 */
export function instanceOfCommuCharaLimitbreakResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CommuCharaLimitbreakResponseFromJSON(json: any): CommuCharaLimitbreakResponse {
    return CommuCharaLimitbreakResponseFromJSONTyped(json, false);
}

export function CommuCharaLimitbreakResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaLimitbreakResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserCommuCharaLimitbreakFromJSON(json['data']),
    };
}

export function CommuCharaLimitbreakResponseToJSON(value?: CommuCharaLimitbreakResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserCommuCharaLimitbreakToJSON(value.data),
    };
}

