/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGachaGuaranteed } from './UserGachaGuaranteed';
import {
    UserGachaGuaranteedFromJSON,
    UserGachaGuaranteedFromJSONTyped,
    UserGachaGuaranteedToJSON,
} from './UserGachaGuaranteed';
import type { UserGachaPortal } from './UserGachaPortal';
import {
    UserGachaPortalFromJSON,
    UserGachaPortalFromJSONTyped,
    UserGachaPortalToJSON,
} from './UserGachaPortal';
import type { UserGachaPrice } from './UserGachaPrice';
import {
    UserGachaPriceFromJSON,
    UserGachaPriceFromJSONTyped,
    UserGachaPriceToJSON,
} from './UserGachaPrice';

/**
 * 
 * @export
 * @interface UserGacha
 */
export interface UserGacha {
    /**
     * ガチャコード
     * @type {string}
     * @memberof UserGacha
     */
    gachaCd: string;
    /**
     * ガチャ名
     * @type {string}
     * @memberof UserGacha
     */
    gachaName: string;
    /**
     * ガチャ画像リソースパーティション
     * @type {number}
     * @memberof UserGacha
     */
    gachaResourcePartition: number;
    /**
     * ガチャ画像リソースコード
     * @type {string}
     * @memberof UserGacha
     */
    resourceGachaCd: string;
    /**
     * イベントガチャかどうか(true: イベントガチャ, false: 常設ガチャ)
     * @type {boolean}
     * @memberof UserGacha
     */
    eventFlg: boolean;
    /**
     * 複数ガチャが開催されている際の表示順序(数値が小さいほど優位な扱いとする)
     * @type {number}
     * @memberof UserGacha
     */
    orderNo: number;
    /**
     * ガチャ開催期間(開始日)
     * @type {string}
     * @memberof UserGacha
     */
    startedAt: string;
    /**
     * ガチャ開催期間(終了日)
     * @type {string}
     * @memberof UserGacha
     */
    endedAt: string;
    /**
     * ディスカウントフラグ
     * @type {number}
     * @memberof UserGacha
     */
    discountFlg: number;
    /**
     * 無料対象フラグ
     * @type {number}
     * @memberof UserGacha
     */
    freeFlg: number;
    /**
     * 有償ダイヤのみフラグ
     * @type {number}
     * @memberof UserGacha
     */
    purchaseOnlyFlg: number;
    /**
     * 引き直しフラグ
     * @type {number}
     * @memberof UserGacha
     */
    drawAgainFlg: number;
    /**
     * 引き直し上限回数
     * @type {number}
     * @memberof UserGacha
     */
    drawAgainLimit: number;
    /**
     * 残りの実行回数
     * @type {number}
     * @memberof UserGacha
     */
    drawRemainingCount: number;
    /**
     * 実行タイプ
     * @type {string}
     * @memberof UserGacha
     */
    drawType: string;
    /**
     * 
     * @type {UserGachaGuaranteed}
     * @memberof UserGacha
     */
    guaranteed: UserGachaGuaranteed;
    /**
     * ガチャの実行回数
     * @type {number}
     * @memberof UserGacha
     */
    execCount: number;
    /**
     * 訴求文言
     * @type {string}
     * @memberof UserGacha
     */
    appealWord: string;
    /**
     * 注意書き
     * @type {string}
     * @memberof UserGacha
     */
    cautionWord: string;
    /**
     * 背景として表示するカード
     * @type {Array<UserGachaPortal>}
     * @memberof UserGacha
     */
    portalList: Array<UserGachaPortal>;
    /**
     * 
     * @type {UserGachaPrice}
     * @memberof UserGacha
     */
    price: UserGachaPrice;
}

/**
 * Check if a given object implements the UserGacha interface.
 */
export function instanceOfUserGacha(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gachaCd" in value;
    isInstance = isInstance && "gachaName" in value;
    isInstance = isInstance && "gachaResourcePartition" in value;
    isInstance = isInstance && "resourceGachaCd" in value;
    isInstance = isInstance && "eventFlg" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "discountFlg" in value;
    isInstance = isInstance && "freeFlg" in value;
    isInstance = isInstance && "purchaseOnlyFlg" in value;
    isInstance = isInstance && "drawAgainFlg" in value;
    isInstance = isInstance && "drawAgainLimit" in value;
    isInstance = isInstance && "drawRemainingCount" in value;
    isInstance = isInstance && "drawType" in value;
    isInstance = isInstance && "guaranteed" in value;
    isInstance = isInstance && "execCount" in value;
    isInstance = isInstance && "appealWord" in value;
    isInstance = isInstance && "cautionWord" in value;
    isInstance = isInstance && "portalList" in value;
    isInstance = isInstance && "price" in value;

    return isInstance;
}

export function UserGachaFromJSON(json: any): UserGacha {
    return UserGachaFromJSONTyped(json, false);
}

export function UserGachaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGacha {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gachaCd': json['gacha_cd'],
        'gachaName': json['gacha_name'],
        'gachaResourcePartition': json['gacha_resource_partition'],
        'resourceGachaCd': json['resource_gacha_cd'],
        'eventFlg': json['event_flg'],
        'orderNo': json['order_no'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'discountFlg': json['discount_flg'],
        'freeFlg': json['free_flg'],
        'purchaseOnlyFlg': json['purchase_only_flg'],
        'drawAgainFlg': json['draw_again_flg'],
        'drawAgainLimit': json['draw_again_limit'],
        'drawRemainingCount': json['draw_remaining_count'],
        'drawType': json['draw_type'],
        'guaranteed': UserGachaGuaranteedFromJSON(json['guaranteed']),
        'execCount': json['exec_count'],
        'appealWord': json['appeal_word'],
        'cautionWord': json['caution_word'],
        'portalList': ((json['portal_list'] as Array<any>).map(UserGachaPortalFromJSON)),
        'price': UserGachaPriceFromJSON(json['price']),
    };
}

export function UserGachaToJSON(value?: UserGacha | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gacha_cd': value.gachaCd,
        'gacha_name': value.gachaName,
        'gacha_resource_partition': value.gachaResourcePartition,
        'resource_gacha_cd': value.resourceGachaCd,
        'event_flg': value.eventFlg,
        'order_no': value.orderNo,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'discount_flg': value.discountFlg,
        'free_flg': value.freeFlg,
        'purchase_only_flg': value.purchaseOnlyFlg,
        'draw_again_flg': value.drawAgainFlg,
        'draw_again_limit': value.drawAgainLimit,
        'draw_remaining_count': value.drawRemainingCount,
        'draw_type': value.drawType,
        'guaranteed': UserGachaGuaranteedToJSON(value.guaranteed),
        'exec_count': value.execCount,
        'appeal_word': value.appealWord,
        'caution_word': value.cautionWord,
        'portal_list': ((value.portalList as Array<any>).map(UserGachaPortalToJSON)),
        'price': UserGachaPriceToJSON(value.price),
    };
}

