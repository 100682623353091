import {
  getAuth as getFirebaseAuth,
  onAuthStateChanged,
  signInAnonymously,
  signOut,
  TwitterAuthProvider,
  GoogleAuthProvider,
  User,
  OAuthProvider,
  signInWithCustomToken,
  Auth,
  AuthProvider,
  linkWithRedirect,
  signInWithRedirect,
  unlink, linkWithPopup, signInWithPopup
} from 'firebase/auth';
import { RouteLocationNormalized } from 'vue-router';
import { Ref } from 'vue';
import { navigateTo, useNuxtApp, useRuntimeConfig } from '#app';
import { AppError } from '~/libs/errors';
import { authConfig } from '~/configs/auth';
import { useLog } from '~/composables/useLog';
import { addUrlQuery } from '~/libs/utils';
import { useApi } from '~/composables/useApi';
import { useLoading } from '~/composables/useLoading';
import { getAnimateOAuthUrl, getPlatformString, isMobileApp } from '~/libs/platform';

const logGroup = 'Auth';
class UserAuthProvider {
  twitter = false;
  google = false;
  apple = false;
  animate = false;
  anonymous = false;

  /**
   * SNS接続しているか
   */
  get isSnsLinked (): boolean {
    return this.twitter || this.google || this.apple || this.animate;
  }

  /**
   * 連携しているSNSプロバイダーの数返す。
   */
  public getLinkedCount (): number {
    // @ts-expect-error : boolean を number として加算しているためeslintでエラーが出るが無視する。
    return this.twitter + this.google + this.apple + this.animate;
  }
}
export const GoogleProviderId = GoogleAuthProvider.PROVIDER_ID;
export const TwitterProviderId = TwitterAuthProvider.PROVIDER_ID;
export const AppleProviderId = 'apple.com';
export const GesotenProviderId = 'gesoten.com';
export const AnonymouslyProviderId = 'anonymously';
const ProviderId = {
  google: GoogleProviderId,
  twitter: TwitterProviderId,
  apple: AppleProviderId,
  animate: GesotenProviderId,
  anonymous: AnonymouslyProviderId,
} as const;
const ProviderName = {
  google: 'Google',
  twitter: 'X',
  apple: 'Apple',
  animate: 'CLUB animate',
  anonymous: 'ゲストアカウント',
} as const;
type ProviderIds = typeof ProviderId[keyof typeof ProviderId];

export const getProviderName = (id: string) => {
  if (id === GoogleProviderId) {
    return ProviderName.google;
  } else if (id === TwitterProviderId) {
    return ProviderName.twitter;
  } else if (id === AppleProviderId) {
    return ProviderName.apple;
  } else if (id === GesotenProviderId) {
    return ProviderName.animate;
  } else if (id === AnonymouslyProviderId) {
    return ProviderName.anonymous;
  }
};

type SignInMethod = 'popup' | 'redirect';

/**
 * 認証処理の実行状態
 */
type SignInProcess = 'none' | 'processing' | 'done';
class UserAuth {
  protected firebaseToken?: string;
  public readonly providers: Ref<UserAuthProvider>;
  public readonly signInProcess: Ref<SignInProcess>;
  protected readonly auth: Auth;
  // NOTE: Redirect方式はうまくいかなさそうなので、Popupに変更
  // @see https://firebase.google.com/docs/auth/web/third-party-storage-mitigation
  protected readonly signInMethod: SignInMethod = 'popup';

  protected updateIdTokenTime: Date | null = null;
  protected updateIdTokenTask: Promise<string> | null = null;

  constructor (
    auth: Auth,
    providers: Ref<UserAuthProvider>,
    signInProcess: Ref<SignInProcess>
  ) {
    this.auth = auth;
    this.providers = providers;
    this.signInProcess = signInProcess;
  }

  // /**
  //  * リダイレクト認証時のエラーチェック
  //  */
  // protected async checkRedirectResult () {
  //   // トークンのリクエストがあった場合は、カスタム認証を行いリダイレクト
  //   const route = useRoute();
  //   const token = route.query.token ?? null;
  //   if (typeof token === 'string') {
  //     const provider = typeof route.query.provider === 'string' ? route.query.provider : '';
  //     await useAuth().signInCustomToken(provider, token);
  //   }
  //   // if (this.signInMethod === 'redirect') {
  //   //   const res = await getRedirectResult(this.auth);
  //   //   useLog('Auth').debug('getRedirectResult', res);
  //   //   await useAuth().updateProviders();
  //   //   onSignInResultExecute(res ? res.providerId : null);
  //   // }
  // }

  /**
   * 現在の認証状態を取得
   */
  public async updateProviders (force = true) {
    const providers = new UserAuthProvider();
    const user = this.auth.currentUser;
    if (user) {
      if (force) {
        await user.reload();
      }
      let providerData = user.providerData;
      // NOTE: user.reload()を行ってもユーザーの情報が更新されるわけではない
      // FIXME: privateプロパティのreloadUserInfoは更新されるため、仕方なくそこから読み取る
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (user.reloadUserInfo && user.reloadUserInfo.providerUserInfo) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        providerData = user.reloadUserInfo.providerUserInfo;
      }
      // NOTE: 匿名認証ユーザーフラグ user.isAnonymous に正しい値が代入されていないことがあるようだ
      // providers.anonymous の代わりに、isSnsLinked() の使用を推奨する
      providers.anonymous = user.isAnonymous || false;
      if (providerData) {
        for (const p of providerData) {
          if (p.providerId === GoogleProviderId) {
            providers.google = true;
          } else if (p.providerId === TwitterProviderId) {
            providers.twitter = true;
          } else if (p.providerId === AppleProviderId) {
            providers.apple = true;
          }
        }
      }
      const userProviders = (await useApi().user.getUserProvider()).data;
      for (const p of userProviders) {
        if (p.providerId === GesotenProviderId) {
          providers.animate = true;
        }
      }
      // useLog(logGroup).info('update provider', providers);
    }
    this.providers.value = providers;
    useLog(logGroup).debug('update providers', this.providers.value);
  }

  /**
   * ゲストログイン(テスト用)
   */
  public async signInAnonymously () {
    this.signInProcess.value = 'processing';
    const user = await signInAnonymously(this.auth);
    this.signInProcess.value = 'done';
    onSignInResultExecute(user ? ProviderId.anonymous : null);
  }

  /**
   * Googleログイン
   */
  public async signInGoogle () {
    const provider = new GoogleAuthProvider();
    await this.signInWithProvider(provider);
  }

  /**
   * Apple接続解除
   */
  public async unlinkGoogle () {
    await this.unlink(GoogleProviderId);
  }

  /**
   * Twitterログイン
   */
  public async signInTwitter () {
    const provider = new TwitterAuthProvider();
    await this.signInWithProvider(provider);
  }

  /**
   * Apple接続解除
   */
  public async unlinkTwitter () {
    await this.unlink(TwitterProviderId);
  }

  /**
   * Appleログイン
   */
  public async signInApple () {
    const provider = new OAuthProvider(AppleProviderId);
    const config = useRuntimeConfig();
    provider.setCustomParameters({
      locale: config.public.firebase.locale,
    });
    await this.signInWithProvider(provider);
  }

  /**
   * Apple接続解除
   */
  public async unlinkApple () {
    await this.unlink(AppleProviderId);
  }

  /**
   * providerログイン
   */
  protected async signInWithProvider (provider: AuthProvider) {
    const { loader } = useLoading();
    const key = loader.start();
    try {
      // if (isMobileApp()) {
      //   const { $nativeBridge } = useNuxtApp();
      //   $nativeBridge.send('signIn', {
      //     provider: provider.providerId,
      //     link,
      //   });
      if (this.auth.currentUser) {
        if (this.signInMethod === 'popup') {
          try {
            this.signInProcess.value = 'processing';
            const user = await linkWithPopup(this.auth.currentUser, provider);
            await this.updateUser(this.auth.currentUser, true);
            onSignInResultExecute(user ? provider.providerId : null);
          } finally {
            this.signInProcess.value = 'done';
          }
        } else {
          await linkWithRedirect(this.auth.currentUser, provider);
        }
      } else if (this.signInMethod === 'popup') {
        try {
          this.signInProcess.value = 'processing';
          const user = await signInWithPopup(this.auth, provider);
          await this.updateUser(this.auth.currentUser, true);
          onSignInResultExecute(user ? provider.providerId : null);
        } finally {
          this.signInProcess.value = 'done';
        }
      } else {
        await signInWithRedirect(this.auth, provider);
      }
    } finally {
      loader.stop(key);
    }
  }

  /**
   * 接続を解除
   * @param providerId
   * @protected
   */
  protected async unlink (providerId: ProviderIds) {
    if (!this.auth.currentUser) {
      throw new AppError('signInAnonymously() required debugMode!');
    }
    const { loader } = useLoading();
    const key = loader.start();
    try {
      this.signInProcess.value = 'processing';
      if (providerId === GesotenProviderId) {
        // 接続解除
        await useApi().user.postUserProviderUnlink({
          userAuthProviderUnlinkRequest: {
            providerId: GesotenProviderId,
          },
        });
      } else {
        await unlink(this.auth.currentUser, providerId);
      }
      await this.updateUser(this.auth.currentUser, true);
    } finally {
      this.signInProcess.value = 'done';
      loader.stop(key);
    }
    // 接続プロバイダが1つもなければログアウト
    if (!this.providers.value.isSnsLinked) {
      await this.signOut();
    }
  }

  /**
   * アクセストークンを取得
   */
  public async getAnimateAuthAccessToken () {
    return (await useApi().user.getUserProviderAccessToken({
      userAuthProviderAccessTokenRequest: {
        providerId: ProviderId.animate,
      },
    })).data.accessToken;
  }

  // protected authWindow: Window | null = null;
  /**
   * animateログイン
   * @param {string} accessToken
   */
  public signInAnimate (accessToken: string) {
    const url = new URL(getAnimateOAuthUrl());
    const { $storage } = useNuxtApp();
    const platformId = $storage.get('animatePlatformId') || useRuntimeConfig().public.firebase.animate.defaultPlatformId;
    const params = {
      platform_id: String(platformId),
      provider_id: ProviderId.animate,
      token: accessToken,
      source: getPlatformString() ?? '',
    };
    if (isMobileApp()) {
      // ネイティブから新しいウィンドウを開く
      const { $nativeBridge } = useNuxtApp();
      const openUrl = addUrlQuery(url.toString(), params);
      $nativeBridge.send('openUrl', {
        url: openUrl,
      });
    } else {
      // NOTE: popupだとモバイルで不具合が起こるためpopupではなくリダイレクトをおこなう
      location.href = addUrlQuery(url.toString(), {
        ...params,
        redirect_url: location.href,
      });
      // const target = this.signInMethod === 'popup' ? 'animate_auth' : '_self';
      // this.authWindow = openWindow(url.toString(), params, target);
    }
  }

  /**
   * アニメイト接続解除
   */
  public async unlinkAnimate () {
    await this.unlink(GesotenProviderId);
  }

  /**
   * カスタムトークンでログイン
   * @param providerId
   * @param token
   */
  public async signInCustomToken (providerId: string | null, token: string) {
    useLog('auth').debug('signInCustomToken', providerId, token);
    const { loader } = useLoading();
    const key = loader.start();
    try {
      this.signInProcess.value = 'processing';
      await signInWithCustomToken(this.auth, token);
      await this.updateUser(this.auth.currentUser, true);
      onSignInResultExecute(providerId);
    } finally {
      this.signInProcess.value = 'done';
      loader.stop(key);
    }
  }

  /**
   * サインアウトと認証ステータスの更新
   * @param isGameTokenReset
   */
  public async signOut (isGameTokenReset = true) {
    try {
      this.signInProcess.value = 'processing';
      if (isGameTokenReset) {
        useGameToken().value = '';
        await useGameTokenSync();
      }
      useUser().reset();
      useTutorial().reset();
      await signOut(this.auth);
    } finally {
      this.signInProcess.value = 'done';
    }
  }

  /**
   * 現在のユーザーを取得
   */
  public getCurrentUser () {
    return this.auth.currentUser;
  }

  /**
   * ユーザーの更新処理
   * @param user
   * @param force
   * @protected
   */
  protected async updateUser (user: User | null, force: boolean): Promise<string|void> {
    if (user) {
      const token = await this.updateIdToken(user, force);
      await this.updateProviders(force);
      return token;
    } else {
      this.resetIdToken();
    }
  }

  /**
   * Idトークンの更新
   *
   * @param {User} user
   * @param {boolean} force
   * @protected
   */
  protected async updateIdToken (user: User, force: boolean): Promise<string> {
    const idToken = await user.getIdToken(force);
    this.firebaseToken = idToken;
    this.updateIdTokenTime = new Date();
    useLog(logGroup).debug('update IdToken', user.uid, idToken, force);
    return idToken;
  }

  /**
   * Idトークンのリセット
   * @protected
   */
  protected resetIdToken () {
    this.firebaseToken = undefined;
    this.updateIdTokenTime = null;
    this.providers.value = new UserAuthProvider();
  }

  /**
   * IDトークンの強制更新が必要か
   *
   * @param {Date} date
   * @return {boolean}
   * @protected
   */
  protected isForceUpdateIdToken (date: Date) {
    // 更新日時に現在時間を保存しておく
    if (!this.updateIdTokenTime) {
      return true;
    }
    // 前回更新からの秒数差分を取得
    const diff = (date.getTime() - Math.floor(this.updateIdTokenTime.getTime())) / 1000;
    return diff > authConfig.forceUpdateIdTokenTime;
  }

  /**
   * 最新のトークンを取得
   *
   * @return {string}
   */
  public async getNewestIdToken (force = false) {
    const user = this.auth.currentUser;
    if (!user) {
      return;
    }
    const isForce = force || this.isForceUpdateIdToken(new Date());

    // 強制更新でなければ前回のIDトークンを返す
    if (!isForce && !this.updateIdTokenTask && this.firebaseToken) {
      return this.firebaseToken;
    }
    // 更新のタスクを追加
    if (!this.updateIdTokenTask) {
      this.updateIdTokenTask = this.updateIdToken(user, isForce);
    }
    const idToken = await this.updateIdTokenTask;
    this.updateIdTokenTask = null;
    return idToken;
  }

  /**
   * ログイン後の画面へ遷移
   */
  public async navigateSignInRedirectTo () {
    // ゲーム開始
    await navigateTo(authConfig.redirectTo);
  }

  /**
   * ログアウト後の画面へ遷移
   */
  public async navigateSignOutRedirectTo () {
    await navigateTo(authConfig.redirectSignIn);
  }

  // /**
  //  * popupからのサインイン
  //  * @param providerId
  //  * @param token
  //  * @param errorCode
  //  * @param message
  //  * @protected
  //  */
  // public async signInReceiveMessageToken (providerId: string, token?: string, errorCode?: string, message?: string) {
  //   if (this.authWindow) {
  //     this.authWindow.close();
  //   }
  //   if (token) {
  //     const { loader } = useLoading();
  //     const key = loader.start();
  //     try {
  //       await this.signInCustomToken(providerId, token);
  //     } finally {
  //       loader.stop(key);
  //     }
  //     return;
  //   }
  //   useReportAppError(new AnimateAuthenticationError(errorCode, message));
  // }

  /**
   * firebaseユーザーの変更監視
   */
  public async checkAuthState () {
    await new Promise<void>((resolve, reject) => {
      onAuthStateChanged(this.auth, async (user) => {
        useLog('Auth').debug('onAuthStateChanged', user);
        if (user) {
          useLog(logGroup).debug('SignIn User', user);
          try {
            await this.updateIdToken(user, true);
            resolve();
          } catch (e) {
            this.resetIdToken();
            reject(e);
          }
        } else {
          // User is signed out
          this.resetIdToken();
          resolve();
        }
      }, (error) => {
        reject(error);
      });
    });
    // await this.checkRedirectResult();
  }

  /**
   * 認証が必要か
   * @param {RouteLocationNormalized} route
   * @return boolean
   */
  public isRequiredAuth (route: RouteLocationNormalized) {
    const noRequiredRoutes = authConfig.noRequiredRoutes;
    for (const p of noRequiredRoutes) {
      if (typeof p === 'string') {
        if (route.path === p) {
          return false;
        }
      } else if (p instanceof RegExp) {
        if (route.path.match(p)) {
          return false;
        }
      }
    }
    return true;
  }
}

/**
 * FirebaseのAuthを取得
 */
export const getAuth = () => {
  const auth = getFirebaseAuth();
  const tenantId = useRuntimeConfig().public.firebase.auth.tenantId;
  auth.tenantId = tenantId && tenantId !== '' ? tenantId : null;
  return auth;
};

let userAuth: UserAuth;
/**
 * 認証系の処理
 */
export const useAuth = () => {
  if (!userAuth) {
    const auth = getAuth();
    const userAuthProviders = useState<UserAuthProvider>('userAuthProviders', () => new UserAuthProvider());
    const signInProcess = useState<SignInProcess>('signInProcess', () => 'none');
    userAuth = new UserAuth(auth, userAuthProviders, signInProcess);
  }
  return userAuth;
};

type SingInResultCallback = (providerId: string | null) => void;
let signInResultCallbacks: SingInResultCallback[] = [];
/**
 * 認証完了後のコールバック
 * @param func
 */
export const onSignInResult = (func: SingInResultCallback) => {
  signInResultCallbacks.push(func);
  onBeforeUnmount(() => {
    signInResultCallbacks = signInResultCallbacks.filter(v => v !== func);
  });
  useLog('onSignInResult').debug(signInResultCallbacks);
  // if (isAuthCallback) {
  //   authCallbacksExecute(useAuth().user.value);
  // }
};

const onSignInResultExecute = (providerId: string | null) => {
  useLog('onSignInResultExecute').debug(signInResultCallbacks);
  if (signInResultCallbacks.length <= 0) {
    return;
  }
  for (const k in signInResultCallbacks) {
    const c = signInResultCallbacks[k];
    c(providerId);
  }
};
