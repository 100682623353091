/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserNews
 */
export interface UserNews {
    /**
     * お知らせID
     * @type {number}
     * @memberof UserNews
     */
    id: number;
    /**
     * お知らせCODE
     * @type {string}
     * @memberof UserNews
     */
    code: string;
    /**
     * お知らせCATEGORY
     * @type {string}
     * @memberof UserNews
     */
    category: UserNewsCategoryEnum;
    /**
     * お知らせ見出し
     * @type {string}
     * @memberof UserNews
     */
    title: string;
    /**
     * お知らせ本文
     * @type {string}
     * @memberof UserNews
     */
    body: string;
    /**
     * お知らせサムネイル
     * @type {string}
     * @memberof UserNews
     */
    thumbnail: string;
    /**
     * お知らせバナーCODE
     * @type {string}
     * @memberof UserNews
     */
    bannerCd: string;
    /**
     * 見出し有無フラグ
     * @type {boolean}
     * @memberof UserNews
     */
    noTitleFlg: boolean;
    /**
     * お知らせ公開日付
     * @type {string}
     * @memberof UserNews
     */
    startedAt: string;
    /**
     * お知らせ終了日付
     * @type {string}
     * @memberof UserNews
     */
    endedAt: string;
    /**
     * 既読フラグ
     * @type {boolean}
     * @memberof UserNews
     */
    readed: boolean;
    /**
     * お知らせパターンNO
     * @type {number}
     * @memberof UserNews
     */
    patternNo: UserNewsPatternNoEnum;
    /**
     * バナーコード
     * @type {string}
     * @memberof UserNews
     */
    resourceBannerCd: string;
    /**
     * バナーコードパーティションNo
     * @type {number}
     * @memberof UserNews
     */
    resourcePartition: number;
}


/**
 * @export
 */
export const UserNewsCategoryEnum = {
    Event: 'event',
    Important: 'important',
    Gacha: 'gacha',
    Other: 'other'
} as const;
export type UserNewsCategoryEnum = typeof UserNewsCategoryEnum[keyof typeof UserNewsCategoryEnum];

/**
 * @export
 */
export const UserNewsPatternNoEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type UserNewsPatternNoEnum = typeof UserNewsPatternNoEnum[keyof typeof UserNewsPatternNoEnum];


/**
 * Check if a given object implements the UserNews interface.
 */
export function instanceOfUserNews(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "code" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "body" in value;
    isInstance = isInstance && "thumbnail" in value;
    isInstance = isInstance && "bannerCd" in value;
    isInstance = isInstance && "noTitleFlg" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "readed" in value;
    isInstance = isInstance && "patternNo" in value;
    isInstance = isInstance && "resourceBannerCd" in value;
    isInstance = isInstance && "resourcePartition" in value;

    return isInstance;
}

export function UserNewsFromJSON(json: any): UserNews {
    return UserNewsFromJSONTyped(json, false);
}

export function UserNewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNews {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'code': json['code'],
        'category': json['category'],
        'title': json['title'],
        'body': json['body'],
        'thumbnail': json['thumbnail'],
        'bannerCd': json['banner_cd'],
        'noTitleFlg': json['no_title_flg'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'readed': json['readed'],
        'patternNo': json['pattern_no'],
        'resourceBannerCd': json['resource_banner_cd'],
        'resourcePartition': json['resource_partition'],
    };
}

export function UserNewsToJSON(value?: UserNews | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'code': value.code,
        'category': value.category,
        'title': value.title,
        'body': value.body,
        'thumbnail': value.thumbnail,
        'banner_cd': value.bannerCd,
        'no_title_flg': value.noTitleFlg,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'readed': value.readed,
        'pattern_no': value.patternNo,
        'resource_banner_cd': value.resourceBannerCd,
        'resource_partition': value.resourcePartition,
    };
}

