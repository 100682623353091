/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PanelmissionBonusFinished } from './PanelmissionBonusFinished';
import {
    PanelmissionBonusFinishedFromJSON,
    PanelmissionBonusFinishedFromJSONTyped,
    PanelmissionBonusFinishedToJSON,
} from './PanelmissionBonusFinished';

/**
 * 
 * @export
 * @interface PanelmissionBonusFinishedResponse
 */
export interface PanelmissionBonusFinishedResponse {
    /**
     * 
     * @type {PanelmissionBonusFinished}
     * @memberof PanelmissionBonusFinishedResponse
     */
    data: PanelmissionBonusFinished;
}

/**
 * Check if a given object implements the PanelmissionBonusFinishedResponse interface.
 */
export function instanceOfPanelmissionBonusFinishedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function PanelmissionBonusFinishedResponseFromJSON(json: any): PanelmissionBonusFinishedResponse {
    return PanelmissionBonusFinishedResponseFromJSONTyped(json, false);
}

export function PanelmissionBonusFinishedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionBonusFinishedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': PanelmissionBonusFinishedFromJSON(json['data']),
    };
}

export function PanelmissionBonusFinishedResponseToJSON(value?: PanelmissionBonusFinishedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': PanelmissionBonusFinishedToJSON(value.data),
    };
}

