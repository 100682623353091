/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoryBonus } from './StoryBonus';
import {
    StoryBonusFromJSON,
    StoryBonusFromJSONTyped,
    StoryBonusToJSON,
} from './StoryBonus';
import type { StoryMission } from './StoryMission';
import {
    StoryMissionFromJSON,
    StoryMissionFromJSONTyped,
    StoryMissionToJSON,
} from './StoryMission';
import type { StoryNeed } from './StoryNeed';
import {
    StoryNeedFromJSON,
    StoryNeedFromJSONTyped,
    StoryNeedToJSON,
} from './StoryNeed';
import type { StoryPov } from './StoryPov';
import {
    StoryPovFromJSON,
    StoryPovFromJSONTyped,
    StoryPovToJSON,
} from './StoryPov';
import type { StoryRouteType } from './StoryRouteType';
import {
    StoryRouteTypeFromJSON,
    StoryRouteTypeFromJSONTyped,
    StoryRouteTypeToJSON,
} from './StoryRouteType';
import type { StorySub } from './StorySub';
import {
    StorySubFromJSON,
    StorySubFromJSONTyped,
    StorySubToJSON,
} from './StorySub';
import type { StoryVoice } from './StoryVoice';
import {
    StoryVoiceFromJSON,
    StoryVoiceFromJSONTyped,
    StoryVoiceToJSON,
} from './StoryVoice';

/**
 * 
 * @export
 * @interface Story
 */
export interface Story {
    /**
     * 何話目か
     * @type {number}
     * @memberof Story
     */
    storyNum: number;
    /**
     * チャプターコード
     * @type {string}
     * @memberof Story
     */
    mainChapterCd: string;
    /**
     * キャラコード いらんかも
     * @type {string}
     * @memberof Story
     */
    charaCd: string;
    /**
     * タイトル
     * @type {string}
     * @memberof Story
     */
    title: string;
    /**
     * クリア前に表示される文言
     * @type {string}
     * @memberof Story
     */
    preview: string;
    /**
     * 
     * @type {StoryRouteType}
     * @memberof Story
     */
    routeType: StoryRouteType;
    /**
     * 
     * @type {StoryVoice}
     * @memberof Story
     */
    voice: StoryVoice;
    /**
     * 
     * @type {StoryMission}
     * @memberof Story
     */
    mission: StoryMission;
    /**
     * 
     * @type {StoryBonus}
     * @memberof Story
     */
    bonus: StoryBonus;
    /**
     * 
     * @type {StoryPov}
     * @memberof Story
     */
    pov: StoryPov;
    /**
     * 
     * @type {StoryNeed}
     * @memberof Story
     */
    need: StoryNeed;
    /**
     * 本編ストーリーに紐づく特別ストーリー情報
     * @type {Array<StorySub>}
     * @memberof Story
     */
    sub: Array<StorySub>;
    /**
     * チャプターのチケット一覧
     * @type {Array<string>}
     * @memberof Story
     */
    ticketList: Array<string>;
    /**
     * チャプターに含まれる選択肢のチケット一覧
     * @type {Array<string>}
     * @memberof Story
     */
    answerTicketList: Array<string>;
}

/**
 * Check if a given object implements the Story interface.
 */
export function instanceOfStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "storyNum" in value;
    isInstance = isInstance && "mainChapterCd" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "preview" in value;
    isInstance = isInstance && "routeType" in value;
    isInstance = isInstance && "voice" in value;
    isInstance = isInstance && "mission" in value;
    isInstance = isInstance && "bonus" in value;
    isInstance = isInstance && "pov" in value;
    isInstance = isInstance && "need" in value;
    isInstance = isInstance && "sub" in value;
    isInstance = isInstance && "ticketList" in value;
    isInstance = isInstance && "answerTicketList" in value;

    return isInstance;
}

export function StoryFromJSON(json: any): Story {
    return StoryFromJSONTyped(json, false);
}

export function StoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Story {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storyNum': json['story_num'],
        'mainChapterCd': json['main_chapter_cd'],
        'charaCd': json['chara_cd'],
        'title': json['title'],
        'preview': json['preview'],
        'routeType': StoryRouteTypeFromJSON(json['route_type']),
        'voice': StoryVoiceFromJSON(json['voice']),
        'mission': StoryMissionFromJSON(json['mission']),
        'bonus': StoryBonusFromJSON(json['bonus']),
        'pov': StoryPovFromJSON(json['pov']),
        'need': StoryNeedFromJSON(json['need']),
        'sub': ((json['sub'] as Array<any>).map(StorySubFromJSON)),
        'ticketList': json['ticket_list'],
        'answerTicketList': json['answer_ticket_list'],
    };
}

export function StoryToJSON(value?: Story | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'story_num': value.storyNum,
        'main_chapter_cd': value.mainChapterCd,
        'chara_cd': value.charaCd,
        'title': value.title,
        'preview': value.preview,
        'route_type': StoryRouteTypeToJSON(value.routeType),
        'voice': StoryVoiceToJSON(value.voice),
        'mission': StoryMissionToJSON(value.mission),
        'bonus': StoryBonusToJSON(value.bonus),
        'pov': StoryPovToJSON(value.pov),
        'need': StoryNeedToJSON(value.need),
        'sub': ((value.sub as Array<any>).map(StorySubToJSON)),
        'ticket_list': value.ticketList,
        'answer_ticket_list': value.answerTicketList,
    };
}

