/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';
import type { UserStoryComplete } from './UserStoryComplete';
import {
    UserStoryCompleteFromJSON,
    UserStoryCompleteFromJSONTyped,
    UserStoryCompleteToJSON,
} from './UserStoryComplete';

/**
 * 
 * @export
 * @interface StoryCompleteResponse
 */
export interface StoryCompleteResponse {
    /**
     * 
     * @type {UserStoryComplete}
     * @memberof StoryCompleteResponse
     */
    data: UserStoryComplete;
    /**
     * 
     * @type {MetaCommon}
     * @memberof StoryCompleteResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the StoryCompleteResponse interface.
 */
export function instanceOfStoryCompleteResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function StoryCompleteResponseFromJSON(json: any): StoryCompleteResponse {
    return StoryCompleteResponseFromJSONTyped(json, false);
}

export function StoryCompleteResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryCompleteResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserStoryCompleteFromJSON(json['data']),
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function StoryCompleteResponseToJSON(value?: StoryCompleteResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserStoryCompleteToJSON(value.data),
        'meta': MetaCommonToJSON(value.meta),
    };
}

