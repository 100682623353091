/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserNewsReadResponseData
 */
export interface UserNewsReadResponseData {
    /**
     * お知らせ既読処理結果ステータス
     * @type {string}
     * @memberof UserNewsReadResponseData
     */
    status?: string;
}

/**
 * Check if a given object implements the UserNewsReadResponseData interface.
 */
export function instanceOfUserNewsReadResponseData(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserNewsReadResponseDataFromJSON(json: any): UserNewsReadResponseData {
    return UserNewsReadResponseDataFromJSONTyped(json, false);
}

export function UserNewsReadResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNewsReadResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function UserNewsReadResponseDataToJSON(value?: UserNewsReadResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': value.status,
    };
}

