/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { FriendStatusDeckCardInfoInner } from './FriendStatusDeckCardInfoInner';
import {
    FriendStatusDeckCardInfoInnerFromJSON,
    FriendStatusDeckCardInfoInnerFromJSONTyped,
    FriendStatusDeckCardInfoInnerToJSON,
} from './FriendStatusDeckCardInfoInner';

/**
 * 
 * @export
 * @interface FriendStatus
 */
export interface FriendStatus {
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof FriendStatus
     */
    nickname: string;
    /**
     * 自己紹介文言
     * @type {string}
     * @memberof FriendStatus
     */
    introduction: string;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof FriendStatus
     */
    playerCode: string;
    /**
     * アバターのサムネイルURL
     * @type {string}
     * @memberof FriendStatus
     */
    avatarThumbnailUrl?: string;
    /**
     * フォローフラグ 1:フォロー済み
     * @type {boolean}
     * @memberof FriendStatus
     */
    followFlg: boolean;
    /**
     * フォロワーフラグ 1:フォローされている
     * @type {boolean}
     * @memberof FriendStatus
     */
    followerFlg: boolean;
    /**
     * 攻略対象キャラコード
     * @type {string}
     * @memberof FriendStatus
     */
    charaCd: string;
    /**
     * ミニゲームポイント
     * @type {number}
     * @memberof FriendStatus
     */
    minigameP: number;
    /**
     * アバターポイント
     * @type {number}
     * @memberof FriendStatus
     */
    avatarP: number;
    /**
     * フォロワー数
     * @type {number}
     * @memberof FriendStatus
     */
    followerCount: number;
    /**
     * 最大フォロワー数
     * @type {number}
     * @memberof FriendStatus
     */
    maxFollowerCount: number;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof FriendStatus
     */
    lastLogin: string;
    /**
     * デッキの総魅力値
     * @type {number}
     * @memberof FriendStatus
     */
    deckP: number;
    /**
     * デッキに設定されているカードのリスト
     * @type {Array<FriendStatusDeckCardInfoInner>}
     * @memberof FriendStatus
     */
    deckCardInfo: Array<FriendStatusDeckCardInfoInner>;
    /**
     * いいね送信済みフラグ 1:送信済み
     * @type {boolean}
     * @memberof FriendStatus
     */
    likeFlg: boolean;
    /**
     * 受け取ったいいね数
     * @type {number}
     * @memberof FriendStatus
     */
    totalReceivedLikeCount: number;
}

/**
 * Check if a given object implements the FriendStatus interface.
 */
export function instanceOfFriendStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "introduction" in value;
    isInstance = isInstance && "playerCode" in value;
    isInstance = isInstance && "followFlg" in value;
    isInstance = isInstance && "followerFlg" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "minigameP" in value;
    isInstance = isInstance && "avatarP" in value;
    isInstance = isInstance && "followerCount" in value;
    isInstance = isInstance && "maxFollowerCount" in value;
    isInstance = isInstance && "lastLogin" in value;
    isInstance = isInstance && "deckP" in value;
    isInstance = isInstance && "deckCardInfo" in value;
    isInstance = isInstance && "likeFlg" in value;
    isInstance = isInstance && "totalReceivedLikeCount" in value;

    return isInstance;
}

export function FriendStatusFromJSON(json: any): FriendStatus {
    return FriendStatusFromJSONTyped(json, false);
}

export function FriendStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nickname': json['nickname'],
        'introduction': json['introduction'],
        'playerCode': json['player_code'],
        'avatarThumbnailUrl': !exists(json, 'avatar_thumbnail_url') ? undefined : json['avatar_thumbnail_url'],
        'followFlg': json['follow_flg'],
        'followerFlg': json['follower_flg'],
        'charaCd': json['chara_cd'],
        'minigameP': json['minigame_p'],
        'avatarP': json['avatar_p'],
        'followerCount': json['follower_count'],
        'maxFollowerCount': json['max_follower_count'],
        'lastLogin': json['last_login'],
        'deckP': json['deck_p'],
        'deckCardInfo': ((json['deck_card_info'] as Array<any>).map(FriendStatusDeckCardInfoInnerFromJSON)),
        'likeFlg': json['like_flg'],
        'totalReceivedLikeCount': json['total_received_like_count'],
    };
}

export function FriendStatusToJSON(value?: FriendStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nickname': value.nickname,
        'introduction': value.introduction,
        'player_code': value.playerCode,
        'avatar_thumbnail_url': value.avatarThumbnailUrl,
        'follow_flg': value.followFlg,
        'follower_flg': value.followerFlg,
        'chara_cd': value.charaCd,
        'minigame_p': value.minigameP,
        'avatar_p': value.avatarP,
        'follower_count': value.followerCount,
        'max_follower_count': value.maxFollowerCount,
        'last_login': value.lastLogin,
        'deck_p': value.deckP,
        'deck_card_info': ((value.deckCardInfo as Array<any>).map(FriendStatusDeckCardInfoInnerToJSON)),
        'like_flg': value.likeFlg,
        'total_received_like_count': value.totalReceivedLikeCount,
    };
}

