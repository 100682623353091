/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * プレゼントタイプ
 * @export
 */
export const PresentType = {
    Avatar: 'avatar',
    Card: 'card',
    Item: 'item'
} as const;
export type PresentType = typeof PresentType[keyof typeof PresentType];


export function PresentTypeFromJSON(json: any): PresentType {
    return PresentTypeFromJSONTyped(json, false);
}

export function PresentTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresentType {
    return json as PresentType;
}

export function PresentTypeToJSON(value?: PresentType | null): any {
    return value as any;
}

