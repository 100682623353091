/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopItemBuyResponseData } from './ShopItemBuyResponseData';
import {
    ShopItemBuyResponseDataFromJSON,
    ShopItemBuyResponseDataFromJSONTyped,
    ShopItemBuyResponseDataToJSON,
} from './ShopItemBuyResponseData';

/**
 * 
 * @export
 * @interface ShopItemBuyResponse
 */
export interface ShopItemBuyResponse {
    /**
     * 
     * @type {ShopItemBuyResponseData}
     * @memberof ShopItemBuyResponse
     */
    data: ShopItemBuyResponseData;
}

/**
 * Check if a given object implements the ShopItemBuyResponse interface.
 */
export function instanceOfShopItemBuyResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ShopItemBuyResponseFromJSON(json: any): ShopItemBuyResponse {
    return ShopItemBuyResponseFromJSONTyped(json, false);
}

export function ShopItemBuyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItemBuyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ShopItemBuyResponseDataFromJSON(json['data']),
    };
}

export function ShopItemBuyResponseToJSON(value?: ShopItemBuyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ShopItemBuyResponseDataToJSON(value.data),
    };
}

