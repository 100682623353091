/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { LoginstampGetPanel } from './LoginstampGetPanel';
import {
    LoginstampGetPanelFromJSON,
    LoginstampGetPanelFromJSONTyped,
    LoginstampGetPanelToJSON,
} from './LoginstampGetPanel';

/**
 * 
 * @export
 * @interface LoginstampGet
 */
export interface LoginstampGet {
    /**
     * ログインスタンプタイトル
     * @type {string}
     * @memberof LoginstampGet
     */
    title: string;
    /**
     * ログインスタンプコード
     * @type {string}
     * @memberof LoginstampGet
     */
    loginstampCd: string;
    /**
     * ログイン回数
     * @type {number}
     * @memberof LoginstampGet
     */
    loginCount: number;
    /**
     * true：当日初回ログイン false：当日ログイン済み
     * @type {boolean}
     * @memberof LoginstampGet
     */
    isEnableLogin: boolean;
    /**
     * true：全て獲得後に最初に戻る false：パネル数分で終了
     * @type {boolean}
     * @memberof LoginstampGet
     */
    loopFlg: boolean;
    /**
     * 開始日時
     * @type {string}
     * @memberof LoginstampGet
     */
    startedAt: string;
    /**
     * 終了日時
     * @type {string}
     * @memberof LoginstampGet
     */
    endedAt: string | null;
    /**
     * パネル情報
     * @type {Array<LoginstampGetPanel>}
     * @memberof LoginstampGet
     */
    panels: Array<LoginstampGetPanel>;
}

/**
 * Check if a given object implements the LoginstampGet interface.
 */
export function instanceOfLoginstampGet(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "loginstampCd" in value;
    isInstance = isInstance && "loginCount" in value;
    isInstance = isInstance && "isEnableLogin" in value;
    isInstance = isInstance && "loopFlg" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "panels" in value;

    return isInstance;
}

export function LoginstampGetFromJSON(json: any): LoginstampGet {
    return LoginstampGetFromJSONTyped(json, false);
}

export function LoginstampGetFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginstampGet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'loginstampCd': json['loginstamp_cd'],
        'loginCount': json['login_count'],
        'isEnableLogin': json['is_enable_login'],
        'loopFlg': json['loop_flg'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'panels': ((json['panels'] as Array<any>).map(LoginstampGetPanelFromJSON)),
    };
}

export function LoginstampGetToJSON(value?: LoginstampGet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'loginstamp_cd': value.loginstampCd,
        'login_count': value.loginCount,
        'is_enable_login': value.isEnableLogin,
        'loop_flg': value.loopFlg,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'panels': ((value.panels as Array<any>).map(LoginstampGetPanelToJSON)),
    };
}

