/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDeckRental } from './UserDeckRental';
import {
    UserDeckRentalFromJSON,
    UserDeckRentalFromJSONTyped,
    UserDeckRentalToJSON,
} from './UserDeckRental';

/**
 * 
 * @export
 * @interface UserDeckRentalListResponseGuestList
 */
export interface UserDeckRentalListResponseGuestList {
    /**
     * 
     * @type {Array<UserDeckRental>}
     * @memberof UserDeckRentalListResponseGuestList
     */
    highRarityList: Array<UserDeckRental>;
    /**
     * 
     * @type {Array<UserDeckRental>}
     * @memberof UserDeckRentalListResponseGuestList
     */
    nearMinigamePList: Array<UserDeckRental>;
}

/**
 * Check if a given object implements the UserDeckRentalListResponseGuestList interface.
 */
export function instanceOfUserDeckRentalListResponseGuestList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "highRarityList" in value;
    isInstance = isInstance && "nearMinigamePList" in value;

    return isInstance;
}

export function UserDeckRentalListResponseGuestListFromJSON(json: any): UserDeckRentalListResponseGuestList {
    return UserDeckRentalListResponseGuestListFromJSONTyped(json, false);
}

export function UserDeckRentalListResponseGuestListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeckRentalListResponseGuestList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'highRarityList': ((json['high_rarity_list'] as Array<any>).map(UserDeckRentalFromJSON)),
        'nearMinigamePList': ((json['near_minigame_p_list'] as Array<any>).map(UserDeckRentalFromJSON)),
    };
}

export function UserDeckRentalListResponseGuestListToJSON(value?: UserDeckRentalListResponseGuestList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'high_rarity_list': ((value.highRarityList as Array<any>).map(UserDeckRentalToJSON)),
        'near_minigame_p_list': ((value.nearMinigamePList as Array<any>).map(UserDeckRentalToJSON)),
    };
}

