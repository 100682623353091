<template>
  <ModalAlert
    :show="props.show"
    title="通信エラー"
    ok-text="ホームへ"
    :z-index="props.zIndex"
    @click-ok="onClick"
  >
    <div v-if="props.error" class="text-center">
      <div>
        ページの読込に失敗しました<br>
        通信状態をお確かめの上<br>
        再度お試しください
      </div>
      <ModalErrorDebugErrorText :error="props.error" />
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>

const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});
interface Emits {
  (e: 'click'): void;
}
const emit = defineEmits<Emits>();

// NOTE: 連続で移動するとおかしくなるための対応
let isNavigating = false;
onBeforeUnmount(() => {
  isNavigating = true;
});
const onClick = () => {
  if (isNavigating) {
    return;
  }
  emit('click');
};
</script>
