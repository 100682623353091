/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeckSaveRequest
 */
export interface DeckSaveRequest {
    /**
     * 設定するデッキのリスト
     * @type {Array<any>}
     * @memberof DeckSaveRequest
     */
    deckList?: Array<any>;
}

/**
 * Check if a given object implements the DeckSaveRequest interface.
 */
export function instanceOfDeckSaveRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DeckSaveRequestFromJSON(json: any): DeckSaveRequest {
    return DeckSaveRequestFromJSONTyped(json, false);
}

export function DeckSaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeckSaveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deckList': !exists(json, 'deck_list') ? undefined : json['deck_list'],
    };
}

export function DeckSaveRequestToJSON(value?: DeckSaveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deck_list': value.deckList,
    };
}

