import { RouteLocationNormalized } from 'vue-router';
import { SoundManager } from '~/libs/sound';
import { Audio, useCurrentBgm } from '~/composables/useAudio';

// 音声が不要なページ
const noRequiredBgm = [
  'index',
  'title',
  'no-support',
  'version-up',
  'auth',
  'terms-of-service',
  'story-via',
  'story-play',
  /\/_debug\/?.*/,
  /\/debug\/?.*/,
  // 'gacha-result',
];
const bgmDuration = 300;

/**
 * 認証が必要か
 * @param {RouteLocationNormalized} route
 * @return boolean
 */
const isRequiredBgm = (route: RouteLocationNormalized) => {
  for (const p of noRequiredBgm) {
    if (typeof p === 'string') {
      if (route.name === p) {
        return false;
      }
    } else if (p instanceof RegExp) {
      if (route.path.match(p)) {
        return false;
      }
    }
  }
  return true;
};

/**
 * BGMの切り替え
 * @param now
 * @param next
 */
const fadeEndPlay = (now: Audio|null, next: Audio|null) => {
  if (now?.getAudioFileId() === next?.getAudioFileId() && now?.isPlaying) {
    return;
  }
  next?.fade({
    start: 0,
    end: 1,
    duration: bgmDuration,
  });
};

/**
 * 現在のページのBGM再生
 */
const playCurrentPageBgm = () => {
  const to = useRoute();
  const bgm = useCurrentBgm();
  if (!to.name || !isRequiredBgm(to)) {
    // 再生させない
    if (bgm && bgm.isPlaying) {
      bgm?.fade({
        start: bgm?.getVolume(),
        end: 0,
        duration: bgmDuration,
      });
    } else {
      bgm?.stop();
    }
  } else if (to.path.match(/\/gacha\/result\/?.*/)) {
    // 自分で制御するためにここでは何もしない
  } else if (to.path.match(/\/gacha\/?.*/)) {
    // ガチャBGM
    fadeEndPlay(bgm, useBgm(0, '1001', {
      preload: false,
    }));
  } else if (to.path.match(/\/commu\/?.*/)) {
    // 会話BGM
    fadeEndPlay(bgm, useBgm(0, '1014', {
      preload: false,
    }));
  } else if (to.path.match(/\/avatar\/?.*/)) {
    // ルームBGM
    fadeEndPlay(bgm, useBgm(0, '1004', {
      preload: false,
    }));
  } else if (to.path.match(/\/scenario\/?.*/)) {
    // シナリオ販売 自分で制御するためにここでは何もしない
  } else {
    // デフォルト
    fadeEndPlay(bgm, useBgm(0, '1002', {
      preload: false,
    }));
  }
};

/**
 * 明示的にBGMを指定して再生する
 */
export const playBgm = (partition: number, cd: string,) => {
  const bgm = useCurrentBgm();
  fadeEndPlay(bgm, useBgm(partition, cd, {
    preload: false,
  }));
};

export default defineNuxtPlugin((nextApp) => {
  // 音声再生周りのイベント登録
  SoundManager.registerEvents();
  // NOTE: Androidで正常に音が止まらない対応
  const { $nativeBridge } = useNuxtApp();
  $nativeBridge.subscribe('onWindowBlur', () => {
    SoundManager.suspend();
  });
  $nativeBridge.subscribe('onWindowFocus', () => {
    SoundManager.resume();
  });
  // BGMの切り替え
  nextApp.hooks.hookOnce('app:mounted', () => {
    playCurrentPageBgm();
  });
  nextApp.hooks.hook('page:transition:finish', () => {
    playCurrentPageBgm();
  });
});
