/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGachaItem } from './UserGachaItem';
import {
    UserGachaItemFromJSON,
    UserGachaItemFromJSONTyped,
    UserGachaItemToJSON,
} from './UserGachaItem';
import type { UserGachaLineupRarityProbabilityListInner } from './UserGachaLineupRarityProbabilityListInner';
import {
    UserGachaLineupRarityProbabilityListInnerFromJSON,
    UserGachaLineupRarityProbabilityListInnerFromJSONTyped,
    UserGachaLineupRarityProbabilityListInnerToJSON,
} from './UserGachaLineupRarityProbabilityListInner';

/**
 * 
 * @export
 * @interface UserGachaLineup
 */
export interface UserGachaLineup {
    /**
     * 
     * @type {Array<UserGachaLineupRarityProbabilityListInner>}
     * @memberof UserGachaLineup
     */
    rarityProbabilityList: Array<UserGachaLineupRarityProbabilityListInner>;
    /**
     * 
     * @type {Array<UserGachaItem>}
     * @memberof UserGachaLineup
     */
    itemProbabilityList: Array<UserGachaItem>;
}

/**
 * Check if a given object implements the UserGachaLineup interface.
 */
export function instanceOfUserGachaLineup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "rarityProbabilityList" in value;
    isInstance = isInstance && "itemProbabilityList" in value;

    return isInstance;
}

export function UserGachaLineupFromJSON(json: any): UserGachaLineup {
    return UserGachaLineupFromJSONTyped(json, false);
}

export function UserGachaLineupFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaLineup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rarityProbabilityList': ((json['rarity_probability_list'] as Array<any>).map(UserGachaLineupRarityProbabilityListInnerFromJSON)),
        'itemProbabilityList': ((json['item_probability_list'] as Array<any>).map(UserGachaItemFromJSON)),
    };
}

export function UserGachaLineupToJSON(value?: UserGachaLineup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rarity_probability_list': ((value.rarityProbabilityList as Array<any>).map(UserGachaLineupRarityProbabilityListInnerToJSON)),
        'item_probability_list': ((value.itemProbabilityList as Array<any>).map(UserGachaItemToJSON)),
    };
}

