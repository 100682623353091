/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * セールタイプ
 * @export
 */
export const BillingProductSaleType = {
    Normal: 'normal',
    Discount: 'discount',
    Card: 'card'
} as const;
export type BillingProductSaleType = typeof BillingProductSaleType[keyof typeof BillingProductSaleType];


export function BillingProductSaleTypeFromJSON(json: any): BillingProductSaleType {
    return BillingProductSaleTypeFromJSONTyped(json, false);
}

export function BillingProductSaleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingProductSaleType {
    return json as BillingProductSaleType;
}

export function BillingProductSaleTypeToJSON(value?: BillingProductSaleType | null): any {
    return value as any;
}

