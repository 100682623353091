/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopItemBuyType } from './ShopItemBuyType';
import {
    ShopItemBuyTypeFromJSON,
    ShopItemBuyTypeFromJSONTyped,
    ShopItemBuyTypeToJSON,
} from './ShopItemBuyType';

/**
 * 
 * @export
 * @interface ShopItemBuyRequest
 */
export interface ShopItemBuyRequest {
    /**
     * ショップアイテムコード
     * @type {string}
     * @memberof ShopItemBuyRequest
     */
    shopItemCd: string;
    /**
     * 購入するショップアイテムの数量
     * @type {number}
     * @memberof ShopItemBuyRequest
     */
    buyCount: number;
    /**
     * 
     * @type {ShopItemBuyType}
     * @memberof ShopItemBuyRequest
     */
    buyType: ShopItemBuyType;
    /**
     * 課金通貨(無料分)
     * @type {number}
     * @memberof ShopItemBuyRequest
     */
    freeGem: number;
    /**
     * 課金通貨(有料分)
     * @type {number}
     * @memberof ShopItemBuyRequest
     */
    purchasedGem: number;
    /**
     * 課金通貨(ゲソコイン)
     * @type {number}
     * @memberof ShopItemBuyRequest
     */
    gesoCoin: number;
    /**
     * ゲーム内通貨
     * @type {number}
     * @memberof ShopItemBuyRequest
     */
    gold: number;
}

/**
 * Check if a given object implements the ShopItemBuyRequest interface.
 */
export function instanceOfShopItemBuyRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shopItemCd" in value;
    isInstance = isInstance && "buyCount" in value;
    isInstance = isInstance && "buyType" in value;
    isInstance = isInstance && "freeGem" in value;
    isInstance = isInstance && "purchasedGem" in value;
    isInstance = isInstance && "gesoCoin" in value;
    isInstance = isInstance && "gold" in value;

    return isInstance;
}

export function ShopItemBuyRequestFromJSON(json: any): ShopItemBuyRequest {
    return ShopItemBuyRequestFromJSONTyped(json, false);
}

export function ShopItemBuyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItemBuyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopItemCd': json['shop_item_cd'],
        'buyCount': json['buy_count'],
        'buyType': ShopItemBuyTypeFromJSON(json['buy_type']),
        'freeGem': json['free_gem'],
        'purchasedGem': json['purchased_gem'],
        'gesoCoin': json['geso_coin'],
        'gold': json['gold'],
    };
}

export function ShopItemBuyRequestToJSON(value?: ShopItemBuyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop_item_cd': value.shopItemCd,
        'buy_count': value.buyCount,
        'buy_type': ShopItemBuyTypeToJSON(value.buyType),
        'free_gem': value.freeGem,
        'purchased_gem': value.purchasedGem,
        'geso_coin': value.gesoCoin,
        'gold': value.gold,
    };
}

