/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PanelmissionBonusAvatar
 */
export interface PanelmissionBonusAvatar {
    /**
     * アバターコード
     * @type {string}
     * @memberof PanelmissionBonusAvatar
     */
    avatarCd: string;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof PanelmissionBonusAvatar
     */
    resourcePartition: number;
    /**
     * 獲得場所
     * @type {string}
     * @memberof PanelmissionBonusAvatar
     */
    getSituation: string;
    /**
     * アバター名
     * @type {string}
     * @memberof PanelmissionBonusAvatar
     */
    avatarName: string;
    /**
     * アイテムコード
     * @type {string}
     * @memberof PanelmissionBonusAvatar
     */
    itemCd: string;
    /**
     * レアリティ
     * @type {number}
     * @memberof PanelmissionBonusAvatar
     */
    rarity: number;
    /**
     * 魅力値
     * @type {number}
     * @memberof PanelmissionBonusAvatar
     */
    charmValue: number;
    /**
     * キャラコード
     * @type {string}
     * @memberof PanelmissionBonusAvatar
     */
    charaCd: string;
    /**
     * 所有数制限に含むかどうか
     * @type {boolean}
     * @memberof PanelmissionBonusAvatar
     */
    notcountFlg: boolean;
    /**
     * アバターカテゴリコード
     * @type {string}
     * @memberof PanelmissionBonusAvatar
     */
    avatarCategoryCd: string;
    /**
     * アバタータイプ
     * @type {string}
     * @memberof PanelmissionBonusAvatar
     */
    avatarType: string;
}

/**
 * Check if a given object implements the PanelmissionBonusAvatar interface.
 */
export function instanceOfPanelmissionBonusAvatar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatarCd" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "getSituation" in value;
    isInstance = isInstance && "avatarName" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "rarity" in value;
    isInstance = isInstance && "charmValue" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "notcountFlg" in value;
    isInstance = isInstance && "avatarCategoryCd" in value;
    isInstance = isInstance && "avatarType" in value;

    return isInstance;
}

export function PanelmissionBonusAvatarFromJSON(json: any): PanelmissionBonusAvatar {
    return PanelmissionBonusAvatarFromJSONTyped(json, false);
}

export function PanelmissionBonusAvatarFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionBonusAvatar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatarCd': json['avatar_cd'],
        'resourcePartition': json['resource_partition'],
        'getSituation': json['get_situation'],
        'avatarName': json['avatar_name'],
        'itemCd': json['item_cd'],
        'rarity': json['rarity'],
        'charmValue': json['charm_value'],
        'charaCd': json['chara_cd'],
        'notcountFlg': json['notcount_flg'],
        'avatarCategoryCd': json['avatar_category_cd'],
        'avatarType': json['avatar_type'],
    };
}

export function PanelmissionBonusAvatarToJSON(value?: PanelmissionBonusAvatar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_cd': value.avatarCd,
        'resource_partition': value.resourcePartition,
        'get_situation': value.getSituation,
        'avatar_name': value.avatarName,
        'item_cd': value.itemCd,
        'rarity': value.rarity,
        'charm_value': value.charmValue,
        'chara_cd': value.charaCd,
        'notcount_flg': value.notcountFlg,
        'avatar_category_cd': value.avatarCategoryCd,
        'avatar_type': value.avatarType,
    };
}

