/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoryProgress } from './StoryProgress';
import {
    StoryProgressFromJSON,
    StoryProgressFromJSONTyped,
    StoryProgressToJSON,
} from './StoryProgress';
import type { StoryRouteType } from './StoryRouteType';
import {
    StoryRouteTypeFromJSON,
    StoryRouteTypeFromJSONTyped,
    StoryRouteTypeToJSON,
} from './StoryRouteType';
import type { UserStatusMission } from './UserStatusMission';
import {
    UserStatusMissionFromJSON,
    UserStatusMissionFromJSONTyped,
    UserStatusMissionToJSON,
} from './UserStatusMission';

/**
 * 
 * @export
 * @interface UserStatus
 */
export interface UserStatus {
    /**
     * 攻略対象キャラコード
     * @type {string}
     * @memberof UserStatus
     */
    charaCd: string;
    /**
     * ゲーム内通貨
     * @type {number}
     * @memberof UserStatus
     */
    gold: number;
    /**
     * 課金通貨(無料分 + 有料分)
     * @type {number}
     * @memberof UserStatus
     */
    gem: number;
    /**
     * 課金通貨(無料分)
     * @type {number}
     * @memberof UserStatus
     */
    freeGem: number;
    /**
     * 課金通貨(有料分)
     * @type {number}
     * @memberof UserStatus
     */
    purchasedGem: number;
    /**
     * ミニゲームポイント
     * @type {number}
     * @memberof UserStatus
     */
    minigameP: number;
    /**
     * 直近にクリアしたパラメータミッションの必要パラメータ
     * @type {number}
     * @memberof UserStatus
     */
    missionMinigamePBase: number;
    /**
     * アバターポイント
     * @type {number}
     * @memberof UserStatus
     */
    avatarP: number;
    /**
     * スタミナ
     * @type {number}
     * @memberof UserStatus
     */
    staminaP: number;
    /**
     * 残チケット（デフォルト枚数を超えていた場合はデフォルト枚数を返す）
     * @type {number}
     * @memberof UserStatus
     */
    remainTickets: number;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserStatus
     */
    playerCode: string;
    /**
     * 苗字
     * @type {string}
     * @memberof UserStatus
     */
    lastName: string;
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof UserStatus
     */
    nickname: string;
    /**
     * ユーザーの誕生日
     * @type {string}
     * @memberof UserStatus
     */
    birthday: string;
    /**
     * チュートリアルCD
     * @type {string}
     * @memberof UserStatus
     */
    tutorialCd: string;
    /**
     * 本編パラメータ(aルート用)
     * @type {number}
     * @memberof UserStatus
     */
    lovePA: number;
    /**
     * 本編パラメータ(bルート用)
     * @type {number}
     * @memberof UserStatus
     */
    lovePB: number;
    /**
     * 合計本編パラメータ
     * @type {number}
     * @memberof UserStatus
     */
    lovePC: number;
    /**
     * クリア回数
     * @type {number}
     * @memberof UserStatus
     */
    clearCount: number;
    /**
     * フォロー数
     * @type {number}
     * @memberof UserStatus
     */
    followCount: number;
    /**
     * フォロワー数
     * @type {number}
     * @memberof UserStatus
     */
    followerCount: number;
    /**
     * 最大フォロー数
     * @type {number}
     * @memberof UserStatus
     */
    maxFollowCount: number;
    /**
     * 最大フォロワー数
     * @type {number}
     * @memberof UserStatus
     */
    maxFollowerCount: number;
    /**
     * いままでのいいね受信総数
     * @type {number}
     * @memberof UserStatus
     */
    totalReceivedLikeCount: number;
    /**
     * 部屋アバター所持数
     * @type {number}
     * @memberof UserStatus
     */
    avatarCount: number;
    /**
     * アバター所持上限数
     * @type {number}
     * @memberof UserStatus
     */
    roomAvatarMax: number;
    /**
     * 部屋アバターコーデ保存上限数
     * @type {number}
     * @memberof UserStatus
     */
    roomCoordinateMax: number;
    /**
     * 自己紹介文言
     * @type {string}
     * @memberof UserStatus
     */
    introduction: string;
    /**
     * 選択中のルート
     * @type {number}
     * @memberof UserStatus
     */
    route: number;
    /**
     * クリアステータス
     * @type {Array<StoryRouteType>}
     * @memberof UserStatus
     */
    clearStatus: Array<StoryRouteType>;
    /**
     * 
     * @type {UserStatusMission}
     * @memberof UserStatus
     */
    mission: UserStatusMission;
    /**
     * マイページに表示するアバターにしているキャラコード
     * @type {string}
     * @memberof UserStatus
     */
    avatarCharaCd: string;
    /**
     * マイページに表示するアバターのサムネイルURL
     * @type {string}
     * @memberof UserStatus
     */
    avatarThumbnailUrl: string;
    /**
     * 
     * @type {StoryProgress}
     * @memberof UserStatus
     */
    storyProgress: StoryProgress;
    /**
     * すべてのルートのノーマルエンドを見終わった
     * @type {boolean}
     * @memberof UserStatus
     */
    allRoutesClearFlg: boolean;
    /**
     * ユーザーデータ作成時間
     * @type {string}
     * @memberof UserStatus
     */
    createdAt: string;
    /**
     * 「新しい物語を始める」ボタンの表示判定(false:通常時, true:新しく物語を始めるボタンを表示)
     * @type {boolean}
     * @memberof UserStatus
     */
    initStoryFlg: boolean;
    /**
     * エピローグ分岐確認の終了判定フラグ(false:エピローグを読了済みもしくはエピローグ発生していない場合, true:エピローグ発生中でお相手選択画面に遷移できない時)
     * @type {boolean}
     * @memberof UserStatus
     */
    isEpilogUnreadFlg: boolean;
    /**
     * お問い合わせフォームでユーザを一意に特定するためのIDとして扱う、暗号化された文字列
     * @type {string}
     * @memberof UserStatus
     */
    contactUsIdentity: string;
}

/**
 * Check if a given object implements the UserStatus interface.
 */
export function instanceOfUserStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "gold" in value;
    isInstance = isInstance && "gem" in value;
    isInstance = isInstance && "freeGem" in value;
    isInstance = isInstance && "purchasedGem" in value;
    isInstance = isInstance && "minigameP" in value;
    isInstance = isInstance && "missionMinigamePBase" in value;
    isInstance = isInstance && "avatarP" in value;
    isInstance = isInstance && "staminaP" in value;
    isInstance = isInstance && "remainTickets" in value;
    isInstance = isInstance && "playerCode" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "tutorialCd" in value;
    isInstance = isInstance && "lovePA" in value;
    isInstance = isInstance && "lovePB" in value;
    isInstance = isInstance && "lovePC" in value;
    isInstance = isInstance && "clearCount" in value;
    isInstance = isInstance && "followCount" in value;
    isInstance = isInstance && "followerCount" in value;
    isInstance = isInstance && "maxFollowCount" in value;
    isInstance = isInstance && "maxFollowerCount" in value;
    isInstance = isInstance && "totalReceivedLikeCount" in value;
    isInstance = isInstance && "avatarCount" in value;
    isInstance = isInstance && "roomAvatarMax" in value;
    isInstance = isInstance && "roomCoordinateMax" in value;
    isInstance = isInstance && "introduction" in value;
    isInstance = isInstance && "route" in value;
    isInstance = isInstance && "clearStatus" in value;
    isInstance = isInstance && "mission" in value;
    isInstance = isInstance && "avatarCharaCd" in value;
    isInstance = isInstance && "avatarThumbnailUrl" in value;
    isInstance = isInstance && "storyProgress" in value;
    isInstance = isInstance && "allRoutesClearFlg" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "initStoryFlg" in value;
    isInstance = isInstance && "isEpilogUnreadFlg" in value;
    isInstance = isInstance && "contactUsIdentity" in value;

    return isInstance;
}

export function UserStatusFromJSON(json: any): UserStatus {
    return UserStatusFromJSONTyped(json, false);
}

export function UserStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'gold': json['gold'],
        'gem': json['gem'],
        'freeGem': json['free_gem'],
        'purchasedGem': json['purchased_gem'],
        'minigameP': json['minigame_p'],
        'missionMinigamePBase': json['mission_minigame_p_base'],
        'avatarP': json['avatar_p'],
        'staminaP': json['stamina_p'],
        'remainTickets': json['remain_tickets'],
        'playerCode': json['player_code'],
        'lastName': json['last_name'],
        'nickname': json['nickname'],
        'birthday': json['birthday'],
        'tutorialCd': json['tutorial_cd'],
        'lovePA': json['love_p_a'],
        'lovePB': json['love_p_b'],
        'lovePC': json['love_p_c'],
        'clearCount': json['clear_count'],
        'followCount': json['follow_count'],
        'followerCount': json['follower_count'],
        'maxFollowCount': json['max_follow_count'],
        'maxFollowerCount': json['max_follower_count'],
        'totalReceivedLikeCount': json['total_received_like_count'],
        'avatarCount': json['avatar_count'],
        'roomAvatarMax': json['room_avatar_max'],
        'roomCoordinateMax': json['room_coordinate_max'],
        'introduction': json['introduction'],
        'route': json['route'],
        'clearStatus': ((json['clear_status'] as Array<any>).map(StoryRouteTypeFromJSON)),
        'mission': UserStatusMissionFromJSON(json['mission']),
        'avatarCharaCd': json['avatar_chara_cd'],
        'avatarThumbnailUrl': json['avatar_thumbnail_url'],
        'storyProgress': StoryProgressFromJSON(json['story_progress']),
        'allRoutesClearFlg': json['all_routes_clear_flg'],
        'createdAt': json['created_at'],
        'initStoryFlg': json['init_story_flg'],
        'isEpilogUnreadFlg': json['is_epilog_unread_flg'],
        'contactUsIdentity': json['contact_us_identity'],
    };
}

export function UserStatusToJSON(value?: UserStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'gold': value.gold,
        'gem': value.gem,
        'free_gem': value.freeGem,
        'purchased_gem': value.purchasedGem,
        'minigame_p': value.minigameP,
        'mission_minigame_p_base': value.missionMinigamePBase,
        'avatar_p': value.avatarP,
        'stamina_p': value.staminaP,
        'remain_tickets': value.remainTickets,
        'player_code': value.playerCode,
        'last_name': value.lastName,
        'nickname': value.nickname,
        'birthday': value.birthday,
        'tutorial_cd': value.tutorialCd,
        'love_p_a': value.lovePA,
        'love_p_b': value.lovePB,
        'love_p_c': value.lovePC,
        'clear_count': value.clearCount,
        'follow_count': value.followCount,
        'follower_count': value.followerCount,
        'max_follow_count': value.maxFollowCount,
        'max_follower_count': value.maxFollowerCount,
        'total_received_like_count': value.totalReceivedLikeCount,
        'avatar_count': value.avatarCount,
        'room_avatar_max': value.roomAvatarMax,
        'room_coordinate_max': value.roomCoordinateMax,
        'introduction': value.introduction,
        'route': value.route,
        'clear_status': ((value.clearStatus as Array<any>).map(StoryRouteTypeToJSON)),
        'mission': UserStatusMissionToJSON(value.mission),
        'avatar_chara_cd': value.avatarCharaCd,
        'avatar_thumbnail_url': value.avatarThumbnailUrl,
        'story_progress': StoryProgressToJSON(value.storyProgress),
        'all_routes_clear_flg': value.allRoutesClearFlg,
        'created_at': value.createdAt,
        'init_story_flg': value.initStoryFlg,
        'is_epilog_unread_flg': value.isEpilogUnreadFlg,
        'contact_us_identity': value.contactUsIdentity,
    };
}

