/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendList } from './UserFriendList';
import {
    UserFriendListFromJSON,
    UserFriendListFromJSONTyped,
    UserFriendListToJSON,
} from './UserFriendList';

/**
 * 
 * @export
 * @interface UserFriendFollowListResponseData
 */
export interface UserFriendFollowListResponseData {
    /**
     * フォロー上限
     * @type {number}
     * @memberof UserFriendFollowListResponseData
     */
    maxFollowCount: number;
    /**
     * フォローリスト
     * @type {Array<UserFriendList>}
     * @memberof UserFriendFollowListResponseData
     */
    friendList: Array<UserFriendList>;
}

/**
 * Check if a given object implements the UserFriendFollowListResponseData interface.
 */
export function instanceOfUserFriendFollowListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maxFollowCount" in value;
    isInstance = isInstance && "friendList" in value;

    return isInstance;
}

export function UserFriendFollowListResponseDataFromJSON(json: any): UserFriendFollowListResponseData {
    return UserFriendFollowListResponseDataFromJSONTyped(json, false);
}

export function UserFriendFollowListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendFollowListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxFollowCount': json['max_follow_count'],
        'friendList': ((json['friend_list'] as Array<any>).map(UserFriendListFromJSON)),
    };
}

export function UserFriendFollowListResponseDataToJSON(value?: UserFriendFollowListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max_follow_count': value.maxFollowCount,
        'friend_list': ((value.friendList as Array<any>).map(UserFriendListToJSON)),
    };
}

