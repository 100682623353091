/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EvnetMissionList
 */
export interface EvnetMissionList {
    /**
     * ミッションタイプ
     * @type {string}
     * @memberof EvnetMissionList
     */
    missionType: string;
    /**
     * ミッションコード
     * @type {string}
     * @memberof EvnetMissionList
     */
    missionCd: string;
    /**
     * 順序
     * @type {number}
     * @memberof EvnetMissionList
     */
    orderNo: number;
}

/**
 * Check if a given object implements the EvnetMissionList interface.
 */
export function instanceOfEvnetMissionList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionType" in value;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "orderNo" in value;

    return isInstance;
}

export function EvnetMissionListFromJSON(json: any): EvnetMissionList {
    return EvnetMissionListFromJSONTyped(json, false);
}

export function EvnetMissionListFromJSONTyped(json: any, ignoreDiscriminator: boolean): EvnetMissionList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionType': json['mission_type'],
        'missionCd': json['mission_cd'],
        'orderNo': json['order_no'],
    };
}

export function EvnetMissionListToJSON(value?: EvnetMissionList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_type': value.missionType,
        'mission_cd': value.missionCd,
        'order_no': value.orderNo,
    };
}

