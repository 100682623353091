<template>
  <ModalAlert
    :show="props.show"
    :title="errorName()"
    :is-enable-cancel="true"
    ok-text="リトライ"
    cancel-text="タイトルへ"
    :z-index="props.zIndex"
    @click-ok="onClick"
    @click-cancel="onClickCancel"
  >
    <div v-if="props.error" class="text-center">
      <div v-if="isNetworkError()">
        通信に失敗しました<br>
        通信状態をお確かめの上<br>
        再度お試しください
      </div>
      <div v-else>
        エラーが発生しました<br>
        再度お試しください
      </div>
      <ModalErrorDebugErrorText :error="props.error" />
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import { NetworkError } from '~/libs/errors';

const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});
interface Emits {
  (e: 'click'): void;
  (e: 'clickCancel'): void;
}
const emit = defineEmits<Emits>();

// NOTE: 連続で移動するとおかしくなるための対応
let isNavigating = false;
onBeforeUnmount(() => {
  isNavigating = true;
});
// リトライ
const onClick = () => {
  if (isNavigating) {
    return;
  }
  emit('click');
};
// タイトルへ
const onClickCancel = () => {
  if (isNavigating) {
    return;
  }
  emit('clickCancel');
};
const isNetworkError = () => {
  return props.error instanceof NetworkError;
};
const errorName = () => {
  return isNetworkError() ? '通信エラー' : 'エラー';
};
</script>
