import { tutorialConfig } from '~/configs/tutorial';

/**
 * データの設定
 * @param cd
 */
const set = (cd: string|null) => {
  // お相手選択完了したタイミングでadjustに送信
  if (useTutorial().isPrologueRead() && cd === tutorialConfig.tutorialCd.charaSelected) {
    useAdjust().sendEventTutorial();
  }
  useTutorial().tutorialCd.value = cd;
  return cd;
};

/**
 * 初期化
 */
const reset = () => {
  useTutorial().tutorialCd.value = null;
};

/**
 * 初回起動か判定
 */
const isFirst = () => useTutorial().tutorialCd.value === tutorialConfig.tutorialCd.first;

/**
 * 名前登録完了か判定
 */
const isNameRegistered = () => useTutorial().tutorialCd.value === tutorialConfig.tutorialCd.nameRegistered;

/**
 * プロローグシーン読了か判定
 */
const isPrologueRead = () => {
  return Number(tutorialConfig.tutorialCd.nameRegistered) < Number(useTutorial().tutorialCd.value) &&
      Number(useTutorial().tutorialCd.value) <= Number(tutorialConfig.tutorialCd.prologueSkip);
};

/**
 * プロローグ終了か判定
 */
const isPrologueEnd = () => useTutorial().tutorialCd.value === tutorialConfig.tutorialCd.prologueEnd;

/**
 * プロローグスキップ状態か判定
 */
const isPrologueSkip = () => useTutorial().tutorialCd.value === tutorialConfig.tutorialCd.prologueSkip;

/**
 * お相手選択完了か判定
 */
const isCharaSelected = () => useTutorial().tutorialCd.value === tutorialConfig.tutorialCd.charaSelected;

/**
 * チュートリアル終了か判定
 */
const isTutorialEnd = () => useTutorial().tutorialCd.value === tutorialConfig.tutorialCd.tutorialEnd;

/**
 * 画面間で共通で使用する基本ユーザーデータを保存しておく
 */
export const useTutorial = () => {
  const tutorialCd = useState<string|null>('tutorial_cd', () => null);
  return {
    tutorialCd,
    set,
    reset,
    isFirst,
    isNameRegistered,
    isPrologueRead,
    isPrologueEnd,
    isPrologueSkip,
    isCharaSelected,
    isTutorialEnd,
  };
};
