import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import { useUser } from '#imports';
import { resourcePath } from '~/libs/resource';
import type { ResourceType } from '~/libs/resource';
import { defaultStorage } from '~/libs/storage';
import { DatabaseLibrary } from '~/libs/database';
import { md5, currentDomainUrl } from '~/libs/utils';
import { getResourceUrl, isAnimatePlatform, isIOS, isAndroid, isIOSDevice, isAndroidDevice } from '~/libs/platform';
import { ApplicationDatabase } from '~/configs/database';
import { linkUrlConfig } from '~/configs/linkUrl';
import { accountConfig } from '~/configs/account';

/**
 * リソースのURLを取得
 * @param {string} type
 * @param {string|null} asset
 * @param {number} partition
 * @param {string} cd
 * @param {string} extension
 */
const resourceUrl = (type: ResourceType, asset: string|null, partition: number, cd: string, extension: string, subDirectory?: string) => {
  const filename = useRuntimeConfig().public.resourceEnableHash ? md5(cd) : cd;
  return `${getResourceUrl()}${resourcePath(type, asset, partition, (subDirectory ? `${subDirectory}/` : '') + filename, extension)}`;
};

/**
 * バージョンを取得
 */
const getVersion = () => {
  const appVersion = defaultStorage.get('appVersion');
  const buildNo = defaultStorage.get('buildNo');
  const nuxtAppVersion = useRuntimeConfig().public.appVersion;
  let version = '';
  if (appVersion) {
    version += `${appVersion}`;
    if (buildNo) {
      version += `(${buildNo})`;
    }
  }
  version += (version ? ', ' : '') + nuxtAppVersion;
  return version;
};

/**
 * お問い合わせURLの取得
 */
const getContactUsUrl = () => {
  let contactUsUrl = null;
  if (isAnimatePlatform()) {
    contactUsUrl = linkUrlConfig.contactUsUrl.animate;
  } else if (isIOS()) {
    contactUsUrl = linkUrlConfig.contactUsUrl.ios;
  } else {
    contactUsUrl = linkUrlConfig.contactUsUrl.android;
  }

  return addPlayerCode(contactUsUrl);
};

/**
 * 未成年課金に関するお問い合わせURLの取得
 */
const getContractMinorityUrl = () => {
  const minorityUrl = linkUrlConfig.minorityUrl;
  return addPlayerCode(minorityUrl);
};

/**
 * 資金決済法に関するお問い合わせURLの取得
 */
const getContractPaymentServiceActUrl = () => {
  const paymentServiceActUrl = linkUrlConfig.paymentServiceActUrl;
  return addPlayerCode(paymentServiceActUrl);
};

/**
 * アカウント削除に関するお問い合わせURLの取得
 */
const getContractAccountDeleteUrl = () => {
  let accountDeleteUrl = '';

  if (isIOSDevice() || isIOS()) {
    accountDeleteUrl = accountConfig.accountDeleteIOSUrl;
  } else if (isAndroidDevice() || isAndroid()) {
    accountDeleteUrl = accountConfig.accountDeleteAndroidUrl;
  }

  return addPlayerCode(accountDeleteUrl);
};

/**
 * お問い合わせURLにプレイヤーコードをパラメーターとして付与する。
 * @param url
 */
const addPlayerCode = (url: string) => {
  // ユーザーrefキャッシュデータの取得
  const playerCode = useUser().playerCode.value;
  // プレイヤーコードが取得できたら、URLのGETパラメータを置換する
  if (playerCode) {
    url = url.replace('{0}', playerCode);
    // プレイヤーコードが取得できなければ、URLのGETパラメータを削除する
  } else if (!playerCode && url.includes('?')) {
    url = url.substring(0, url.indexOf('?'));
  }

  return url;
};

export default defineNuxtPlugin(() => {
  const database = DatabaseLibrary.connection() as ApplicationDatabase;
  return {
    provide: {
      /**
       * リソースのURLを取得(画像)
       * @param {string} asset
       * @param {number} partition
       * @param {string} cd
       * @param {string} extension
       * @param {string} subDirectory
       */
      resourceTextureUrl: (asset: string, partition: number, cd: string, extension = 'png', subDirectory?: string) => {
        return resourceUrl('texture', asset, partition, cd, extension, subDirectory);
      },
      /**
       * リソースのURLを取得(BGM)
       * @param {number} partition
       * @param {string} cd
       * @param {string} extension
       */
      resourceBgmUrl: (partition: number, cd: string, extension: string) => {
        return resourceUrl('bgm', null, partition, cd, extension);
      },
      /**
       * リソースのURLを取得(SE)
       * @param {number} partition
       * @param {string} cd
       * @param {string} extension
       */
      resourceSeUrl: (partition: number, cd: string, extension: string) => {
        return resourceUrl('se', null, partition, cd, extension);
      },
      /**
       * リソースのURLを取得(Voice)
       * @param {srring} asset
       * @param {number} partition
       * @param {string} cd
       * @param {string} extension
       */
      resourceVoiceUrl: (asset: string, partition: number, cd: string, extension: string) => {
        return resourceUrl('voice', asset, partition, cd, extension);
      },

      /**
       * ローカルストレージへのアクセス
       */
      storage: defaultStorage,
      /**
       * デフォルトDBへのアクセス
       */
      database,
      /**
       * バージョンを取得
       */
      getVersion,
      /**
       * URLを取得
       */
      currentDomainUrl,
      /**
       * お問い合わせURLを取得
       */
      getContactUsUrl,
      getContractMinorityUrl,
      getContractPaymentServiceActUrl,
      getContractAccountDeleteUrl,
    }
  };
});
