/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ミニゲーム演出タイプ
 * @export
 */
export const MinigameDirectionType = {
    Nml: 'nml',
    Evt: 'evt'
} as const;
export type MinigameDirectionType = typeof MinigameDirectionType[keyof typeof MinigameDirectionType];


export function MinigameDirectionTypeFromJSON(json: any): MinigameDirectionType {
    return MinigameDirectionTypeFromJSONTyped(json, false);
}

export function MinigameDirectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinigameDirectionType {
    return json as MinigameDirectionType;
}

export function MinigameDirectionTypeToJSON(value?: MinigameDirectionType | null): any {
    return value as any;
}

