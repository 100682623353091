/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CardReadCardList } from './CardReadCardList';
import {
    CardReadCardListFromJSON,
    CardReadCardListFromJSONTyped,
    CardReadCardListToJSON,
} from './CardReadCardList';

/**
 * 
 * @export
 * @interface CardReadRequest
 */
export interface CardReadRequest {
    /**
     * カードコードの配列
     * @type {Array<CardReadCardList>}
     * @memberof CardReadRequest
     */
    cardList?: Array<CardReadCardList>;
}

/**
 * Check if a given object implements the CardReadRequest interface.
 */
export function instanceOfCardReadRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CardReadRequestFromJSON(json: any): CardReadRequest {
    return CardReadRequestFromJSONTyped(json, false);
}

export function CardReadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardReadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardList': !exists(json, 'card_list') ? undefined : ((json['card_list'] as Array<any>).map(CardReadCardListFromJSON)),
    };
}

export function CardReadRequestToJSON(value?: CardReadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_list': value.cardList === undefined ? undefined : ((value.cardList as Array<any>).map(CardReadCardListToJSON)),
    };
}

