/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendFollowerListResponseData } from './UserFriendFollowerListResponseData';
import {
    UserFriendFollowerListResponseDataFromJSON,
    UserFriendFollowerListResponseDataFromJSONTyped,
    UserFriendFollowerListResponseDataToJSON,
} from './UserFriendFollowerListResponseData';

/**
 * 
 * @export
 * @interface UserFriendFollowerListResponse
 */
export interface UserFriendFollowerListResponse {
    /**
     * 
     * @type {UserFriendFollowerListResponseData}
     * @memberof UserFriendFollowerListResponse
     */
    data: UserFriendFollowerListResponseData;
}

/**
 * Check if a given object implements the UserFriendFollowerListResponse interface.
 */
export function instanceOfUserFriendFollowerListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserFriendFollowerListResponseFromJSON(json: any): UserFriendFollowerListResponse {
    return UserFriendFollowerListResponseFromJSONTyped(json, false);
}

export function UserFriendFollowerListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendFollowerListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserFriendFollowerListResponseDataFromJSON(json['data']),
    };
}

export function UserFriendFollowerListResponseToJSON(value?: UserFriendFollowerListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserFriendFollowerListResponseDataToJSON(value.data),
    };
}

