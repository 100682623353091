/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MissionCompleteNextTicket
 */
export interface MissionCompleteNextTicket {
    /**
     * 次のチケットコード
     * @type {string}
     * @memberof MissionCompleteNextTicket
     */
    ticketCd: string;
    /**
     * 次のチケットのチャプターコード
     * @type {string}
     * @memberof MissionCompleteNextTicket
     */
    chapterCd: string;
    /**
     * 次のチケットの番号
     * @type {number}
     * @memberof MissionCompleteNextTicket
     */
    ticketNo: number;
}

/**
 * Check if a given object implements the MissionCompleteNextTicket interface.
 */
export function instanceOfMissionCompleteNextTicket(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ticketCd" in value;
    isInstance = isInstance && "chapterCd" in value;
    isInstance = isInstance && "ticketNo" in value;

    return isInstance;
}

export function MissionCompleteNextTicketFromJSON(json: any): MissionCompleteNextTicket {
    return MissionCompleteNextTicketFromJSONTyped(json, false);
}

export function MissionCompleteNextTicketFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionCompleteNextTicket {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticketCd': json['ticket_cd'],
        'chapterCd': json['chapter_cd'],
        'ticketNo': json['ticket_no'],
    };
}

export function MissionCompleteNextTicketToJSON(value?: MissionCompleteNextTicket | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticket_cd': value.ticketCd,
        'chapter_cd': value.chapterCd,
        'ticket_no': value.ticketNo,
    };
}

