/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDeckRentalLeaderCard } from './UserDeckRentalLeaderCard';
import {
    UserDeckRentalLeaderCardFromJSON,
    UserDeckRentalLeaderCardFromJSONTyped,
    UserDeckRentalLeaderCardToJSON,
} from './UserDeckRentalLeaderCard';

/**
 * 
 * @export
 * @interface UserDeckRental
 */
export interface UserDeckRental {
    /**
     * プレイヤーのアバターのサムネイルURL
     * @type {string}
     * @memberof UserDeckRental
     */
    avatarThumbnailUrl: string;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserDeckRental
     */
    playerCode: string;
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof UserDeckRental
     */
    nickname: string;
    /**
     * 自己紹介
     * @type {string}
     * @memberof UserDeckRental
     */
    introduction: string;
    /**
     * 最終ログイン日時。
     * @type {string}
     * @memberof UserDeckRental
     */
    lastLoginAt: string;
    /**
     * ゲストの場合：true, フォローしている場合：false
     * @type {boolean}
     * @memberof UserDeckRental
     */
    isGuest: boolean;
    /**
     * 
     * @type {UserDeckRentalLeaderCard}
     * @memberof UserDeckRental
     */
    leaderCard: UserDeckRentalLeaderCard;
}

/**
 * Check if a given object implements the UserDeckRental interface.
 */
export function instanceOfUserDeckRental(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatarThumbnailUrl" in value;
    isInstance = isInstance && "playerCode" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "introduction" in value;
    isInstance = isInstance && "lastLoginAt" in value;
    isInstance = isInstance && "isGuest" in value;
    isInstance = isInstance && "leaderCard" in value;

    return isInstance;
}

export function UserDeckRentalFromJSON(json: any): UserDeckRental {
    return UserDeckRentalFromJSONTyped(json, false);
}

export function UserDeckRentalFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeckRental {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatarThumbnailUrl': json['avatar_thumbnail_url'],
        'playerCode': json['player_code'],
        'nickname': json['nickname'],
        'introduction': json['introduction'],
        'lastLoginAt': json['last_login_at'],
        'isGuest': json['is_guest'],
        'leaderCard': UserDeckRentalLeaderCardFromJSON(json['leader_card']),
    };
}

export function UserDeckRentalToJSON(value?: UserDeckRental | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_thumbnail_url': value.avatarThumbnailUrl,
        'player_code': value.playerCode,
        'nickname': value.nickname,
        'introduction': value.introduction,
        'last_login_at': value.lastLoginAt,
        'is_guest': value.isGuest,
        'leader_card': UserDeckRentalLeaderCardToJSON(value.leaderCard),
    };
}

