/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 天井の設定
 * @export
 * @interface UserGachaGuaranteed
 */
export interface UserGachaGuaranteed {
    /**
     * 対象レアリティ
     * @type {number}
     * @memberof UserGachaGuaranteed
     */
    rarity?: number;
    /**
     * 必要なガチャ実行回数
     * @type {number}
     * @memberof UserGachaGuaranteed
     */
    count?: number;
}

/**
 * Check if a given object implements the UserGachaGuaranteed interface.
 */
export function instanceOfUserGachaGuaranteed(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserGachaGuaranteedFromJSON(json: any): UserGachaGuaranteed {
    return UserGachaGuaranteedFromJSONTyped(json, false);
}

export function UserGachaGuaranteedFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaGuaranteed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rarity': !exists(json, 'rarity') ? undefined : json['rarity'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function UserGachaGuaranteedToJSON(value?: UserGachaGuaranteed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rarity': value.rarity,
        'count': value.count,
    };
}

