/**
 * ログ
 */
// eslint-disable-next-line import/no-named-default
import { default as logger, LogLevel } from 'consola';

interface Logger{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log: (message: any, ...args: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: (message: any, ...args: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  warn: (message: any, ...args: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  debug: (message: any, ...args: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error: (message: any, ...args: any[]) => void;
}

/**
 * ログ出力を行う
 * console.logを踏襲
 */
export const useLog = (group: string | undefined = undefined): Logger => {
  const isDebugMode = useRuntimeConfig().public.debugMode || process.dev;
  logger.level = isDebugMode ? LogLevel.Verbose : LogLevel.Info;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getArgs = (message: any, ...args: any[]): [any, any[]] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let arg1: any = message;
    if (group) {
      if (typeof message === 'string') {
        arg1 = `[${group}] ${message}`;
      } else {
        args.unshift(message);
        arg1 = `[${group}]`;
      }
    }
    return [arg1, args];
  };
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    log: (message: any, ...args: any[]) => {
      logger.log.apply(null, getArgs(message, ...args));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    info: (message: any, ...args: any[]) => {
      logger.info.apply(null, getArgs(message, ...args));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    warn: (message: any, ...args: any[]) => {
      logger.warn.apply(null, getArgs(message, ...args));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    debug: (message: any, ...args: any[]) => {
      logger.debug.apply(null, getArgs(message, ...args));
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    error: (message: any, ...args: any[]) => {
      logger.error.apply(null, getArgs(message, ...args));
    },
  };
};
