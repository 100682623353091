/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EquipAvatarsCoordinate
 */
export interface EquipAvatarsCoordinate {
    /**
     * X座標
     * @type {number}
     * @memberof EquipAvatarsCoordinate
     */
    x?: number;
    /**
     * Y座標
     * @type {number}
     * @memberof EquipAvatarsCoordinate
     */
    y?: number;
    /**
     * 重なり順
     * @type {number}
     * @memberof EquipAvatarsCoordinate
     */
    zOrder?: number;
    /**
     * 角度
     * @type {number}
     * @memberof EquipAvatarsCoordinate
     */
    degree?: number;
    /**
     * 反転フラグ
     * @type {number}
     * @memberof EquipAvatarsCoordinate
     */
    reverseFlg?: number;
}

/**
 * Check if a given object implements the EquipAvatarsCoordinate interface.
 */
export function instanceOfEquipAvatarsCoordinate(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipAvatarsCoordinateFromJSON(json: any): EquipAvatarsCoordinate {
    return EquipAvatarsCoordinateFromJSONTyped(json, false);
}

export function EquipAvatarsCoordinateFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipAvatarsCoordinate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'x': !exists(json, 'x') ? undefined : json['x'],
        'y': !exists(json, 'y') ? undefined : json['y'],
        'zOrder': !exists(json, 'z_order') ? undefined : json['z_order'],
        'degree': !exists(json, 'degree') ? undefined : json['degree'],
        'reverseFlg': !exists(json, 'reverse_flg') ? undefined : json['reverse_flg'],
    };
}

export function EquipAvatarsCoordinateToJSON(value?: EquipAvatarsCoordinate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'x': value.x,
        'y': value.y,
        'z_order': value.zOrder,
        'degree': value.degree,
        'reverse_flg': value.reverseFlg,
    };
}

