/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostStoryCompleteRequest
 */
export interface PostStoryCompleteRequest {
    /**
     * チケットコード
     * @type {string}
     * @memberof PostStoryCompleteRequest
     */
    ticketCd: string;
    /**
     * 購入タイプ
     * @type {string}
     * @memberof PostStoryCompleteRequest
     */
    payType?: string;
    /**
     * 消費無償ジェム
     * @type {number}
     * @memberof PostStoryCompleteRequest
     */
    freeGem?: number;
    /**
     * 消費有償ジェム
     * @type {number}
     * @memberof PostStoryCompleteRequest
     */
    purchasedGem?: number;
    /**
     * 消費ゲソコイン
     * @type {number}
     * @memberof PostStoryCompleteRequest
     */
    gesoCoin?: number;
}

/**
 * Check if a given object implements the PostStoryCompleteRequest interface.
 */
export function instanceOfPostStoryCompleteRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ticketCd" in value;

    return isInstance;
}

export function PostStoryCompleteRequestFromJSON(json: any): PostStoryCompleteRequest {
    return PostStoryCompleteRequestFromJSONTyped(json, false);
}

export function PostStoryCompleteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostStoryCompleteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticketCd': json['ticket_cd'],
        'payType': !exists(json, 'pay_type') ? undefined : json['pay_type'],
        'freeGem': !exists(json, 'free_gem') ? undefined : json['free_gem'],
        'purchasedGem': !exists(json, 'purchased_gem') ? undefined : json['purchased_gem'],
        'gesoCoin': !exists(json, 'geso_coin') ? undefined : json['geso_coin'],
    };
}

export function PostStoryCompleteRequestToJSON(value?: PostStoryCompleteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticket_cd': value.ticketCd,
        'pay_type': value.payType,
        'free_gem': value.freeGem,
        'purchased_gem': value.purchasedGem,
        'geso_coin': value.gesoCoin,
    };
}

