/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserStatusMin } from './UserStatusMin';
import {
    UserStatusMinFromJSON,
    UserStatusMinFromJSONTyped,
    UserStatusMinToJSON,
} from './UserStatusMin';

/**
 * 
 * @export
 * @interface UserStatusMinResponse
 */
export interface UserStatusMinResponse {
    /**
     * 
     * @type {UserStatusMin}
     * @memberof UserStatusMinResponse
     */
    data: UserStatusMin;
}

/**
 * Check if a given object implements the UserStatusMinResponse interface.
 */
export function instanceOfUserStatusMinResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserStatusMinResponseFromJSON(json: any): UserStatusMinResponse {
    return UserStatusMinResponseFromJSONTyped(json, false);
}

export function UserStatusMinResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusMinResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserStatusMinFromJSON(json['data']),
    };
}

export function UserStatusMinResponseToJSON(value?: UserStatusMinResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserStatusMinToJSON(value.data),
    };
}

