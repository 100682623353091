/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 獲得できる報酬の情報
 * @export
 * @interface StoryBonus
 */
export interface StoryBonus {
    /**
     * １:報酬有り 0：報酬無し
     * @type {boolean}
     * @memberof StoryBonus
     */
    visible: boolean;
    /**
     * ミッションコード？？ プレゼントアイテムコードでは？API設計時に要確認
     * @type {string}
     * @memberof StoryBonus
     */
    missionCd: string;
}

/**
 * Check if a given object implements the StoryBonus interface.
 */
export function instanceOfStoryBonus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "visible" in value;
    isInstance = isInstance && "missionCd" in value;

    return isInstance;
}

export function StoryBonusFromJSON(json: any): StoryBonus {
    return StoryBonusFromJSONTyped(json, false);
}

export function StoryBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visible': json['visible'],
        'missionCd': json['mission_cd'],
    };
}

export function StoryBonusToJSON(value?: StoryBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visible': value.visible,
        'mission_cd': value.missionCd,
    };
}

