/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StorySub
 */
export interface StorySub {
    /**
     * チャプターコード
     * @type {string}
     * @memberof StorySub
     */
    chapterCd: string;
    /**
     * 先頭チケットコード
     * @type {string}
     * @memberof StorySub
     */
    ticketCd: string;
    /**
     * 彼目線かプレミアムか
     * @type {string}
     * @memberof StorySub
     */
    storyType: string;
}

/**
 * Check if a given object implements the StorySub interface.
 */
export function instanceOfStorySub(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "chapterCd" in value;
    isInstance = isInstance && "ticketCd" in value;
    isInstance = isInstance && "storyType" in value;

    return isInstance;
}

export function StorySubFromJSON(json: any): StorySub {
    return StorySubFromJSONTyped(json, false);
}

export function StorySubFromJSONTyped(json: any, ignoreDiscriminator: boolean): StorySub {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chapterCd': json['chapter_cd'],
        'ticketCd': json['ticket_cd'],
        'storyType': json['story_type'],
    };
}

export function StorySubToJSON(value?: StorySub | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chapter_cd': value.chapterCd,
        'ticket_cd': value.ticketCd,
        'story_type': value.storyType,
    };
}

