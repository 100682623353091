/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpecialStoryType } from './SpecialStoryType';
import {
    SpecialStoryTypeFromJSON,
    SpecialStoryTypeFromJSONTyped,
    SpecialStoryTypeToJSON,
} from './SpecialStoryType';

/**
 * 
 * @export
 * @interface UserSpecialStoryList
 */
export interface UserSpecialStoryList {
    /**
     * 親のスペシャルストーリーコード ない場合はNULL
     * @type {string}
     * @memberof UserSpecialStoryList
     */
    parentSpecialStoryCd: string;
    /**
     * スペシャルストーリーコード
     * @type {string}
     * @memberof UserSpecialStoryList
     */
    specialStoryCd: string | null;
    /**
     * true:既読 false:未読
     * @type {boolean}
     * @memberof UserSpecialStoryList
     */
    readFlg: boolean;
    /**
     * 
     * @type {SpecialStoryType}
     * @memberof UserSpecialStoryList
     */
    storyType: SpecialStoryType;
    /**
     * 開始日時
     * @type {string}
     * @memberof UserSpecialStoryList
     */
    openedAt: string;
}

/**
 * Check if a given object implements the UserSpecialStoryList interface.
 */
export function instanceOfUserSpecialStoryList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parentSpecialStoryCd" in value;
    isInstance = isInstance && "specialStoryCd" in value;
    isInstance = isInstance && "readFlg" in value;
    isInstance = isInstance && "storyType" in value;
    isInstance = isInstance && "openedAt" in value;

    return isInstance;
}

export function UserSpecialStoryListFromJSON(json: any): UserSpecialStoryList {
    return UserSpecialStoryListFromJSONTyped(json, false);
}

export function UserSpecialStoryListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSpecialStoryList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentSpecialStoryCd': json['parent_special_story_cd'],
        'specialStoryCd': json['special_story_cd'],
        'readFlg': json['read_flg'],
        'storyType': SpecialStoryTypeFromJSON(json['story_type']),
        'openedAt': json['opened_at'],
    };
}

export function UserSpecialStoryListToJSON(value?: UserSpecialStoryList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parent_special_story_cd': value.parentSpecialStoryCd,
        'special_story_cd': value.specialStoryCd,
        'read_flg': value.readFlg,
        'story_type': SpecialStoryTypeToJSON(value.storyType),
        'opened_at': value.openedAt,
    };
}

