/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserPrivateNews } from './UserPrivateNews';
import {
    UserPrivateNewsFromJSON,
    UserPrivateNewsFromJSONTyped,
    UserPrivateNewsToJSON,
} from './UserPrivateNews';

/**
 * 
 * @export
 * @interface UserPrivateNewsListResponse
 */
export interface UserPrivateNewsListResponse {
    /**
     * 
     * @type {Array<UserPrivateNews>}
     * @memberof UserPrivateNewsListResponse
     */
    data: Array<UserPrivateNews>;
}

/**
 * Check if a given object implements the UserPrivateNewsListResponse interface.
 */
export function instanceOfUserPrivateNewsListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserPrivateNewsListResponseFromJSON(json: any): UserPrivateNewsListResponse {
    return UserPrivateNewsListResponseFromJSONTyped(json, false);
}

export function UserPrivateNewsListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPrivateNewsListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserPrivateNewsFromJSON)),
    };
}

export function UserPrivateNewsListResponseToJSON(value?: UserPrivateNewsListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserPrivateNewsToJSON)),
    };
}

