/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeckRecommendResponseData } from './DeckRecommendResponseData';
import {
    DeckRecommendResponseDataFromJSON,
    DeckRecommendResponseDataFromJSONTyped,
    DeckRecommendResponseDataToJSON,
} from './DeckRecommendResponseData';

/**
 * 
 * @export
 * @interface DeckRecommendResponse
 */
export interface DeckRecommendResponse {
    /**
     * 
     * @type {DeckRecommendResponseData}
     * @memberof DeckRecommendResponse
     */
    data: DeckRecommendResponseData;
}

/**
 * Check if a given object implements the DeckRecommendResponse interface.
 */
export function instanceOfDeckRecommendResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function DeckRecommendResponseFromJSON(json: any): DeckRecommendResponse {
    return DeckRecommendResponseFromJSONTyped(json, false);
}

export function DeckRecommendResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeckRecommendResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': DeckRecommendResponseDataFromJSON(json['data']),
    };
}

export function DeckRecommendResponseToJSON(value?: DeckRecommendResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': DeckRecommendResponseDataToJSON(value.data),
    };
}

