/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ItemConsumeResponseData
 */
export interface ItemConsumeResponseData {
    /**
     * 使用したアイテムCD
     * @type {string}
     * @memberof ItemConsumeResponseData
     */
    itemCd: string;
    /**
     * 消費後のアイテム所持数
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    itemCount: number;
    /**
     * 残チケット数
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    remainingTicketCount: number;
    /**
     * ミニゲームポイント
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    minigameP: number;
    /**
     * ガチャポイント
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    gachaPoint: number;
    /**
     * 本編進行用パラメータ1
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    lovePA: number;
    /**
     * 本編進行用パラメータ2
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    lovePB: number;
    /**
     * 現在の体力
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    staminaPoint: number;
    /**
     * 部屋アバター所持可能数
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    clothesMax: number;
    /**
     * 開催中シナリオイベントのイベントパラメータ（使用対象がシナリオイベントパラメータUPアイテムではない場合は-1）
     * @type {number}
     * @memberof ItemConsumeResponseData
     */
    scenarioEventParameter: number;
}

/**
 * Check if a given object implements the ItemConsumeResponseData interface.
 */
export function instanceOfItemConsumeResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "remainingTicketCount" in value;
    isInstance = isInstance && "minigameP" in value;
    isInstance = isInstance && "gachaPoint" in value;
    isInstance = isInstance && "lovePA" in value;
    isInstance = isInstance && "lovePB" in value;
    isInstance = isInstance && "staminaPoint" in value;
    isInstance = isInstance && "clothesMax" in value;
    isInstance = isInstance && "scenarioEventParameter" in value;

    return isInstance;
}

export function ItemConsumeResponseDataFromJSON(json: any): ItemConsumeResponseData {
    return ItemConsumeResponseDataFromJSONTyped(json, false);
}

export function ItemConsumeResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ItemConsumeResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemCd': json['item_cd'],
        'itemCount': json['item_count'],
        'remainingTicketCount': json['remaining_ticket_count'],
        'minigameP': json['minigame_p'],
        'gachaPoint': json['gacha_point'],
        'lovePA': json['love_p_a'],
        'lovePB': json['love_p_b'],
        'staminaPoint': json['stamina_point'],
        'clothesMax': json['clothes_max'],
        'scenarioEventParameter': json['scenario_event_parameter'],
    };
}

export function ItemConsumeResponseDataToJSON(value?: ItemConsumeResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_cd': value.itemCd,
        'item_count': value.itemCount,
        'remaining_ticket_count': value.remainingTicketCount,
        'minigame_p': value.minigameP,
        'gacha_point': value.gachaPoint,
        'love_p_a': value.lovePA,
        'love_p_b': value.lovePB,
        'stamina_point': value.staminaPoint,
        'clothes_max': value.clothesMax,
        'scenario_event_parameter': value.scenarioEventParameter,
    };
}

