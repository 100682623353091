/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendFollowListResponseData } from './UserFriendFollowListResponseData';
import {
    UserFriendFollowListResponseDataFromJSON,
    UserFriendFollowListResponseDataFromJSONTyped,
    UserFriendFollowListResponseDataToJSON,
} from './UserFriendFollowListResponseData';

/**
 * 
 * @export
 * @interface UserFriendFollowListResponse
 */
export interface UserFriendFollowListResponse {
    /**
     * 
     * @type {UserFriendFollowListResponseData}
     * @memberof UserFriendFollowListResponse
     */
    data: UserFriendFollowListResponseData;
}

/**
 * Check if a given object implements the UserFriendFollowListResponse interface.
 */
export function instanceOfUserFriendFollowListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserFriendFollowListResponseFromJSON(json: any): UserFriendFollowListResponse {
    return UserFriendFollowListResponseFromJSONTyped(json, false);
}

export function UserFriendFollowListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendFollowListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserFriendFollowListResponseDataFromJSON(json['data']),
    };
}

export function UserFriendFollowListResponseToJSON(value?: UserFriendFollowListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserFriendFollowListResponseDataToJSON(value.data),
    };
}

