/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipAvatarsCoordinate } from './EquipAvatarsCoordinate';
import {
    EquipAvatarsCoordinateFromJSON,
    EquipAvatarsCoordinateFromJSONTyped,
    EquipAvatarsCoordinateToJSON,
} from './EquipAvatarsCoordinate';

/**
 * 
 * @export
 * @interface EquipAvatars
 */
export interface EquipAvatars {
    /**
     * ユーザアバターID
     * @type {string}
     * @memberof EquipAvatars
     */
    userAvatarId?: string;
    /**
     * 
     * @type {EquipAvatarsCoordinate}
     * @memberof EquipAvatars
     */
    coordinate?: EquipAvatarsCoordinate;
}

/**
 * Check if a given object implements the EquipAvatars interface.
 */
export function instanceOfEquipAvatars(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EquipAvatarsFromJSON(json: any): EquipAvatars {
    return EquipAvatarsFromJSONTyped(json, false);
}

export function EquipAvatarsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipAvatars {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userAvatarId': !exists(json, 'user_avatar_id') ? undefined : json['user_avatar_id'],
        'coordinate': !exists(json, 'coordinate') ? undefined : EquipAvatarsCoordinateFromJSON(json['coordinate']),
    };
}

export function EquipAvatarsToJSON(value?: EquipAvatars | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_avatar_id': value.userAvatarId,
        'coordinate': EquipAvatarsCoordinateToJSON(value.coordinate),
    };
}

