<template>
  <div v-show="isDebugMode">
    <div v-if="isDebugMode" class="text-gray-400 mt-2">
      <div>
        エラー内容: {{ debugMessage }}
      </div>
      <div v-if="error?.message">
        {{ error?.message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ApiError } from '~/libs/errors';

const props = defineProps<{
  error: Error | null,
}>();
const isDebugMode = useRuntimeConfig().public.debugMode;

const debugMessage = ref<string>();
/**
 * エラーメッセージの更新
 * @param error
 */
const updateMessage = (error: Error | null) => {
  debugMessage.value = '';
  if (error instanceof ApiError) {
    debugMessage.value = `(${error.statusCode}) ${error.statusText}`;
  }
};

watch(() => props, (current) => {
  updateMessage(current.error);
});
updateMessage(props.error);
</script>
