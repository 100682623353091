/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerialCodeSave } from './SerialCodeSave';
import {
    SerialCodeSaveFromJSON,
    SerialCodeSaveFromJSONTyped,
    SerialCodeSaveToJSON,
} from './SerialCodeSave';

/**
 * 
 * @export
 * @interface SerialCodeSaveResponse
 */
export interface SerialCodeSaveResponse {
    /**
     * 
     * @type {SerialCodeSave}
     * @memberof SerialCodeSaveResponse
     */
    data: SerialCodeSave;
}

/**
 * Check if a given object implements the SerialCodeSaveResponse interface.
 */
export function instanceOfSerialCodeSaveResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function SerialCodeSaveResponseFromJSON(json: any): SerialCodeSaveResponse {
    return SerialCodeSaveResponseFromJSONTyped(json, false);
}

export function SerialCodeSaveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerialCodeSaveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': SerialCodeSaveFromJSON(json['data']),
    };
}

export function SerialCodeSaveResponseToJSON(value?: SerialCodeSaveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': SerialCodeSaveToJSON(value.data),
    };
}

