/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AvatarMstResponse,
  CardMstResponse,
  CharaMstResponse,
  CommuMstResponse,
  ErrorResponse,
  EventListMstResponse,
  FirstUseBonusResponse,
  ItemMstResponse,
  MinigameDirectionMstResponse,
  MissionMstResponse,
  PresentItems,
  ResourceManifestResponse,
  SerialCodeCampaignMstResponse,
  ShopCombinedsales,
  ShopItemRequestEnum,
  ShopItems,
  SpecialStoryListMstResponse,
  StillMstResponse,
  StoryListMstResponse,
} from '../models';
import {
    AvatarMstResponseFromJSON,
    AvatarMstResponseToJSON,
    CardMstResponseFromJSON,
    CardMstResponseToJSON,
    CharaMstResponseFromJSON,
    CharaMstResponseToJSON,
    CommuMstResponseFromJSON,
    CommuMstResponseToJSON,
    ErrorResponseFromJSON,
    ErrorResponseToJSON,
    EventListMstResponseFromJSON,
    EventListMstResponseToJSON,
    FirstUseBonusResponseFromJSON,
    FirstUseBonusResponseToJSON,
    ItemMstResponseFromJSON,
    ItemMstResponseToJSON,
    MinigameDirectionMstResponseFromJSON,
    MinigameDirectionMstResponseToJSON,
    MissionMstResponseFromJSON,
    MissionMstResponseToJSON,
    PresentItemsFromJSON,
    PresentItemsToJSON,
    ResourceManifestResponseFromJSON,
    ResourceManifestResponseToJSON,
    SerialCodeCampaignMstResponseFromJSON,
    SerialCodeCampaignMstResponseToJSON,
    ShopCombinedsalesFromJSON,
    ShopCombinedsalesToJSON,
    ShopItemRequestEnumFromJSON,
    ShopItemRequestEnumToJSON,
    ShopItemsFromJSON,
    ShopItemsToJSON,
    SpecialStoryListMstResponseFromJSON,
    SpecialStoryListMstResponseToJSON,
    StillMstResponseFromJSON,
    StillMstResponseToJSON,
    StoryListMstResponseFromJSON,
    StoryListMstResponseToJSON,
} from '../models';

export interface GetAvatarMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetCardMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetCharaMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetCommuMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetEventListMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetFirstUseBonusRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetItemMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetMinigameDirectionMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetMissionMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetPresentItemMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetResourceManifestRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetSerialCodeCampaignMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetShopCombinedsaleMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetShopItemMstRequest {
    categoryType: ShopItemRequestEnum;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
}

export interface GetSpecialStoryListMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetStillMstRequest {
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

export interface GetStoryListMstRequest {
    charaCd: string;
    xAppVersion?: string;
    xLanguage?: string;
    xPlatform?: string;
    page?: number;
}

/**
 * 
 */
export class MasterApi extends runtime.BaseAPI {

    /**
     * アバター情報を取得する
     */
    async getAvatarMstRaw(requestParameters: GetAvatarMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AvatarMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/avatar/avatar`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AvatarMstResponseFromJSON(jsonValue));
    }

    /**
     * アバター情報を取得する
     */
    async getAvatarMst(requestParameters: GetAvatarMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AvatarMstResponse> {
        const response = await this.getAvatarMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * カード情報を取得する。
     */
    async getCardMstRaw(requestParameters: GetCardMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CardMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/card/card`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CardMstResponseFromJSON(jsonValue));
    }

    /**
     * カード情報を取得する。
     */
    async getCardMst(requestParameters: GetCardMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CardMstResponse> {
        const response = await this.getCardMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * キャラ情報を取得する。
     */
    async getCharaMstRaw(requestParameters: GetCharaMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CharaMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/chara/chara`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CharaMstResponseFromJSON(jsonValue));
    }

    /**
     * キャラ情報を取得する。
     */
    async getCharaMst(requestParameters: GetCharaMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CharaMstResponse> {
        const response = await this.getCharaMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * コミュマスタを取得する。
     */
    async getCommuMstRaw(requestParameters: GetCommuMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CommuMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/commu/commu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CommuMstResponseFromJSON(jsonValue));
    }

    /**
     * コミュマスタを取得する。
     */
    async getCommuMst(requestParameters: GetCommuMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CommuMstResponse> {
        const response = await this.getCommuMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * シナイベリストを取得する。
     */
    async getEventListMstRaw(requestParameters: GetEventListMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EventListMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/event/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EventListMstResponseFromJSON(jsonValue));
    }

    /**
     * シナイベリストを取得する。
     */
    async getEventListMst(requestParameters: GetEventListMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EventListMstResponse> {
        const response = await this.getEventListMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 初回利用特典を取得する。
     */
    async getFirstUseBonusRaw(requestParameters: GetFirstUseBonusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<FirstUseBonusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/tutorial/firstuse/bonus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => FirstUseBonusResponseFromJSON(jsonValue));
    }

    /**
     * 初回利用特典を取得する。
     */
    async getFirstUseBonus(requestParameters: GetFirstUseBonusRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<FirstUseBonusResponse> {
        const response = await this.getFirstUseBonusRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * アイテムマスターを取得する。
     */
    async getItemMstRaw(requestParameters: GetItemMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ItemMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/item/item`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ItemMstResponseFromJSON(jsonValue));
    }

    /**
     * アイテムマスターを取得する。
     */
    async getItemMst(requestParameters: GetItemMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ItemMstResponse> {
        const response = await this.getItemMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ミニゲーム演出マスタを取得する
     */
    async getMinigameDirectionMstRaw(requestParameters: GetMinigameDirectionMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MinigameDirectionMstResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/minigame/direction`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MinigameDirectionMstResponseFromJSON(jsonValue));
    }

    /**
     * ミニゲーム演出マスタを取得する
     */
    async getMinigameDirectionMst(requestParameters: GetMinigameDirectionMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MinigameDirectionMstResponse> {
        const response = await this.getMinigameDirectionMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ミッションマスタを取得する
     */
    async getMissionMstRaw(requestParameters: GetMissionMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MissionMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/mission/mission`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MissionMstResponseFromJSON(jsonValue));
    }

    /**
     * ミッションマスタを取得する
     */
    async getMissionMst(requestParameters: GetMissionMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MissionMstResponse> {
        const response = await this.getMissionMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * プレゼントアイテム情報を取得する。
     */
    async getPresentItemMstRaw(requestParameters: GetPresentItemMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PresentItems>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/present/item`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PresentItemsFromJSON(jsonValue));
    }

    /**
     * プレゼントアイテム情報を取得する。
     */
    async getPresentItemMst(requestParameters: GetPresentItemMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PresentItems> {
        const response = await this.getPresentItemMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * リソースマニュフェストを取得
     */
    async getResourceManifestRaw(requestParameters: GetResourceManifestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResourceManifestResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/resource/manifest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResourceManifestResponseFromJSON(jsonValue));
    }

    /**
     * リソースマニュフェストを取得
     */
    async getResourceManifest(requestParameters: GetResourceManifestRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResourceManifestResponse> {
        const response = await this.getResourceManifestRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * シリアルコードキャンペーンマスターを取得する。
     */
    async getSerialCodeCampaignMstRaw(requestParameters: GetSerialCodeCampaignMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SerialCodeCampaignMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/serialcode/serialcode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SerialCodeCampaignMstResponseFromJSON(jsonValue));
    }

    /**
     * シリアルコードキャンペーンマスターを取得する。
     */
    async getSerialCodeCampaignMst(requestParameters: GetSerialCodeCampaignMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SerialCodeCampaignMstResponse> {
        const response = await this.getSerialCodeCampaignMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * セット販売のマスタ情報を取得する。 
     */
    async getShopCombinedsaleMstRaw(requestParameters: GetShopCombinedsaleMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopCombinedsales>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/shop/combinedsale`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopCombinedsalesFromJSON(jsonValue));
    }

    /**
     * セット販売のマスタ情報を取得する。 
     */
    async getShopCombinedsaleMst(requestParameters: GetShopCombinedsaleMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopCombinedsales> {
        const response = await this.getShopCombinedsaleMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ショップアイテムのマスタ情報を取得する。  | shop_category_name         | memo                | |----                        |----                 | | story_ticket_list          | ストーリーチケット| | stamina_recover            | 体力回復アイテム(購入時に持ち物に入る) | | avatar_storage_up_item     | アバター保存枠増加| | parameter_up_item          | 親密度増加| | favorable_up_item          | 好感度増加| | card                       | カード| | combinedsale_item          | セット販売| | gacha                      | ガチャ| | story_sale                 | ストーリーアイテム(心の鍵など)| | scenario_parameter_up_item | シナリオイベントパラメーター増加| | avatar                     | アバター| | epilogue                   | シナイベエピローグ| | shop_story_ticket_list     | ショップ専用ストーリーチケット　※VAS以降はstory_ticket_list共用| | shop_story_sale            | ショップ専用ストーリーアイテム(心の鍵など)　※VAS以降はstory_sale共用| | collect_item               | 本編促進で集めるアイテム| | sp_recover                 | ライブイベント体力回復アイテム(購入時に持ち物に入る) | | stamina_recover_immediate  | 体力回復アイテム(購入時に即時使用する) | | sp_recover_immediate       | ライブイベント体力回復アイテム(購入時に即時使用する) |  ### 要注意!!  実際に、アイテムショップに並ぶアイテムは ``` show_flg == true && started_at, ended_atによる範囲が有効 ``` のものが並ぶ、並んだアイテムを購入することが出来るかどうかは、start,endの範囲による評価で行う。 
     */
    async getShopItemMstRaw(requestParameters: GetShopItemMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShopItems>> {
        if (requestParameters.categoryType === null || requestParameters.categoryType === undefined) {
            throw new runtime.RequiredError('categoryType','Required parameter requestParameters.categoryType was null or undefined when calling getShopItemMst.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/shop/item/{category_type}`.replace(`{${"category_type"}}`, encodeURIComponent(String(requestParameters.categoryType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShopItemsFromJSON(jsonValue));
    }

    /**
     * ショップアイテムのマスタ情報を取得する。  | shop_category_name         | memo                | |----                        |----                 | | story_ticket_list          | ストーリーチケット| | stamina_recover            | 体力回復アイテム(購入時に持ち物に入る) | | avatar_storage_up_item     | アバター保存枠増加| | parameter_up_item          | 親密度増加| | favorable_up_item          | 好感度増加| | card                       | カード| | combinedsale_item          | セット販売| | gacha                      | ガチャ| | story_sale                 | ストーリーアイテム(心の鍵など)| | scenario_parameter_up_item | シナリオイベントパラメーター増加| | avatar                     | アバター| | epilogue                   | シナイベエピローグ| | shop_story_ticket_list     | ショップ専用ストーリーチケット　※VAS以降はstory_ticket_list共用| | shop_story_sale            | ショップ専用ストーリーアイテム(心の鍵など)　※VAS以降はstory_sale共用| | collect_item               | 本編促進で集めるアイテム| | sp_recover                 | ライブイベント体力回復アイテム(購入時に持ち物に入る) | | stamina_recover_immediate  | 体力回復アイテム(購入時に即時使用する) | | sp_recover_immediate       | ライブイベント体力回復アイテム(購入時に即時使用する) |  ### 要注意!!  実際に、アイテムショップに並ぶアイテムは ``` show_flg == true && started_at, ended_atによる範囲が有効 ``` のものが並ぶ、並んだアイテムを購入することが出来るかどうかは、start,endの範囲による評価で行う。 
     */
    async getShopItemMst(requestParameters: GetShopItemMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShopItems> {
        const response = await this.getShopItemMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * スペシャルストーリーリストを取得する。
     */
    async getSpecialStoryListMstRaw(requestParameters: GetSpecialStoryListMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SpecialStoryListMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/special_story/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SpecialStoryListMstResponseFromJSON(jsonValue));
    }

    /**
     * スペシャルストーリーリストを取得する。
     */
    async getSpecialStoryListMst(requestParameters: GetSpecialStoryListMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SpecialStoryListMstResponse> {
        const response = await this.getSpecialStoryListMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * スチル情報を取得する。
     */
    async getStillMstRaw(requestParameters: GetStillMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StillMstResponse>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/still/still`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StillMstResponseFromJSON(jsonValue));
    }

    /**
     * スチル情報を取得する。
     */
    async getStillMst(requestParameters: GetStillMstRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StillMstResponse> {
        const response = await this.getStillMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ストーリーリストを取得する。
     */
    async getStoryListMstRaw(requestParameters: GetStoryListMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoryListMstResponse>> {
        if (requestParameters.charaCd === null || requestParameters.charaCd === undefined) {
            throw new runtime.RequiredError('charaCd','Required parameter requestParameters.charaCd was null or undefined when calling getStoryListMst.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xAppVersion !== undefined && requestParameters.xAppVersion !== null) {
            headerParameters['X-App-Version'] = String(requestParameters.xAppVersion);
        }

        if (requestParameters.xLanguage !== undefined && requestParameters.xLanguage !== null) {
            headerParameters['X-Language'] = String(requestParameters.xLanguage);
        }

        if (requestParameters.xPlatform !== undefined && requestParameters.xPlatform !== null) {
            headerParameters['X-Platform'] = String(requestParameters.xPlatform);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("isAuthorized", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/mst/story/list/{chara_cd}`.replace(`{${"chara_cd"}}`, encodeURIComponent(String(requestParameters.charaCd))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoryListMstResponseFromJSON(jsonValue));
    }

    /**
     * ストーリーリストを取得する。
     */
    async getStoryListMst(requestParameters: GetStoryListMstRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoryListMstResponse> {
        const response = await this.getStoryListMstRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
