/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuCharaMental
 */
export interface CommuCharaMental {
    /**
     * コミュレベル下限
     * @type {number}
     * @memberof CommuCharaMental
     */
    lowerLevel: number;
    /**
     * コミュレベル上限
     * @type {number}
     * @memberof CommuCharaMental
     */
    upperLevel: number;
    /**
     * キャラとの心の距離感
     * @type {string}
     * @memberof CommuCharaMental
     */
    message: string;
}

/**
 * Check if a given object implements the CommuCharaMental interface.
 */
export function instanceOfCommuCharaMental(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lowerLevel" in value;
    isInstance = isInstance && "upperLevel" in value;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function CommuCharaMentalFromJSON(json: any): CommuCharaMental {
    return CommuCharaMentalFromJSONTyped(json, false);
}

export function CommuCharaMentalFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaMental {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lowerLevel': json['lower_level'],
        'upperLevel': json['upper_level'],
        'message': json['message'],
    };
}

export function CommuCharaMentalToJSON(value?: CommuCharaMental | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lower_level': value.lowerLevel,
        'upper_level': value.upperLevel,
        'message': value.message,
    };
}

