export type AppBackFunc = () => boolean | undefined;
// 上書きする挙動
let stackAppBack: AppBackFunc[] = [];

/**
 * ページごとのアプリ
 */
export const defineAppBack = (callback: AppBackFunc) => {
  stackAppBack.unshift(callback);
  // unmount時に除外
  onUnmounted(() => {
    stackAppBack = stackAppBack.filter(v => v !== callback);
  });
};

/**
 * 戻るボタン、(Android backキー)を押した時の実行
 */
export const useAppBack = () => {
  // 挙動の上書き済みチェック
  for (const k in stackAppBack) {
    if (stackAppBack[k]()) {
      return;
    }
  }
  // デフォルトの挙動(履歴を戻る)
  const { $router } = useNuxtApp();
  $router.go(-1);
};
