/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConvertGoldCard } from './ConvertGoldCard';
import {
    ConvertGoldCardFromJSON,
    ConvertGoldCardFromJSONTyped,
    ConvertGoldCardToJSON,
} from './ConvertGoldCard';
import type { SynthesisCard } from './SynthesisCard';
import {
    SynthesisCardFromJSON,
    SynthesisCardFromJSONTyped,
    SynthesisCardToJSON,
} from './SynthesisCard';
import type { UnlockVoice } from './UnlockVoice';
import {
    UnlockVoiceFromJSON,
    UnlockVoiceFromJSONTyped,
    UnlockVoiceToJSON,
} from './UnlockVoice';
import type { UserGachaPresentList } from './UserGachaPresentList';
import {
    UserGachaPresentListFromJSON,
    UserGachaPresentListFromJSONTyped,
    UserGachaPresentListToJSON,
} from './UserGachaPresentList';

/**
 * 
 * @export
 * @interface UserGachaExecResponseData
 */
export interface UserGachaExecResponseData {
    /**
     * ガチャ実行後の所持サイト内通貨数
     * @type {number}
     * @memberof UserGachaExecResponseData
     */
    gold: number;
    /**
     * ガチャ実行後の所持ジェム数
     * @type {number}
     * @memberof UserGachaExecResponseData
     */
    point: number;
    /**
     * ガチャ実行後の汎用通常ガチャメダルアイテム数
     * @type {number}
     * @memberof UserGachaExecResponseData
     */
    medal: number;
    /**
     * ガチャ実行後の汎用★4以上確定ガチャメダルアイテム数
     * @type {number}
     * @memberof UserGachaExecResponseData
     */
    over4Medal: number;
    /**
     * ガチャ実行後の汎用★5確定ガチャメダルアイテム数
     * @type {number}
     * @memberof UserGachaExecResponseData
     */
    over5Medal: number;
    /**
     * 天井実行回数
     * @type {number}
     * @memberof UserGachaExecResponseData
     */
    guaranteedExecCount: number;
    /**
     * 
     * @type {Array<UserGachaPresentList>}
     * @memberof UserGachaExecResponseData
     */
    presentList: Array<UserGachaPresentList>;
    /**
     * 
     * @type {Array<SynthesisCard>}
     * @memberof UserGachaExecResponseData
     */
    synthesisCardList: Array<SynthesisCard>;
    /**
     * 
     * @type {Array<UnlockVoice>}
     * @memberof UserGachaExecResponseData
     */
    unlockVoiceList: Array<UnlockVoice>;
    /**
     * 
     * @type {Array<ConvertGoldCard>}
     * @memberof UserGachaExecResponseData
     */
    convertGoldCardList: Array<ConvertGoldCard>;
}

/**
 * Check if a given object implements the UserGachaExecResponseData interface.
 */
export function instanceOfUserGachaExecResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gold" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "medal" in value;
    isInstance = isInstance && "over4Medal" in value;
    isInstance = isInstance && "over5Medal" in value;
    isInstance = isInstance && "guaranteedExecCount" in value;
    isInstance = isInstance && "presentList" in value;
    isInstance = isInstance && "synthesisCardList" in value;
    isInstance = isInstance && "unlockVoiceList" in value;
    isInstance = isInstance && "convertGoldCardList" in value;

    return isInstance;
}

export function UserGachaExecResponseDataFromJSON(json: any): UserGachaExecResponseData {
    return UserGachaExecResponseDataFromJSONTyped(json, false);
}

export function UserGachaExecResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaExecResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gold': json['gold'],
        'point': json['point'],
        'medal': json['medal'],
        'over4Medal': json['over4_medal'],
        'over5Medal': json['over5_medal'],
        'guaranteedExecCount': json['guaranteed_exec_count'],
        'presentList': ((json['present_list'] as Array<any>).map(UserGachaPresentListFromJSON)),
        'synthesisCardList': ((json['synthesis_card_list'] as Array<any>).map(SynthesisCardFromJSON)),
        'unlockVoiceList': ((json['unlock_voice_list'] as Array<any>).map(UnlockVoiceFromJSON)),
        'convertGoldCardList': ((json['convert_gold_card_list'] as Array<any>).map(ConvertGoldCardFromJSON)),
    };
}

export function UserGachaExecResponseDataToJSON(value?: UserGachaExecResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gold': value.gold,
        'point': value.point,
        'medal': value.medal,
        'over4_medal': value.over4Medal,
        'over5_medal': value.over5Medal,
        'guaranteed_exec_count': value.guaranteedExecCount,
        'present_list': ((value.presentList as Array<any>).map(UserGachaPresentListToJSON)),
        'synthesis_card_list': ((value.synthesisCardList as Array<any>).map(SynthesisCardToJSON)),
        'unlock_voice_list': ((value.unlockVoiceList as Array<any>).map(UnlockVoiceToJSON)),
        'convert_gold_card_list': ((value.convertGoldCardList as Array<any>).map(ConvertGoldCardToJSON)),
    };
}

