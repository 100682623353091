/* eslint-disable import/no-duplicates */
import {
  format as dateFnsFormat, isAfter, isBefore, parse,
} from 'date-fns';
import { ja } from 'date-fns/locale';
/* eslint-enable import/no-duplicates */

const defaultDateFormat = 'yyyy-MM-dd HH:mm:ss';
const limitDateFormat = 'MM月dd日(iii) HH:mm';
/**
 * 本日をデフォルト形式(yyyy-MM-dd)にする
 */
export const formatToday = (): string => {
  return dateFnsFormat(new Date(), 'yyyy-MM-dd', { locale: ja });
};

/**
 * Dateオブジェクトを指定した形式にする
 */
export const formatDate = (date: string | number | Date, format: string = defaultDateFormat): string => {
  return dateFnsFormat(toDate(date), format, { locale: ja });
};

/**
 * Dateオブジェクトを期限の形式にする
 */
export const formatLimitDate = (date: string | number | Date): string => {
  return formatDate(date, limitDateFormat);
};

/**
 * Dateオブジェクトをデフォルト形式(yyyy-MM-dd)にする
 */
export const defaultFormatDate = (date: string | number | Date): string => {
  return dateFnsFormat(toDate(date), 'yyyy-MM-dd', { locale: ja });
};

/**
 * 文字列 -> Dateオブジェクトに変換
 */
export const parseDate = (date: string, format = defaultDateFormat): Date => {
  return parse(date, format, new Date());
};

/**
 * 文字列 -> Dateオブジェクトに変換
 */
export const toDate = (date: string | number | Date, format = defaultDateFormat): Date => {
  if (typeof date === 'string') {
    return parseDate(date, format);
  } else if (typeof date === 'number') {
    // JSのタイムスタンプはms
    return new Date(date * 1000);
  }
  return date;
};

/**
 * 比較先より後の日付か
 */
export const isAfterDate = (source: string | Date, destination: string | Date) => {
  const sourceDate = toDate(source);
  const destinationDate = toDate(destination);
  return isAfter(sourceDate, destinationDate);
};

/**
 * 比較先より前の日付か
 */
export const isBeforeDate = (source: string | Date, destination: string | Date) => {
  const sourceDate = toDate(source);
  const destinationDate = toDate(destination);
  return isBefore(sourceDate, destinationDate);
};

/**
 * 期限内のチェック
 */
export const inPeriod = (start: string | Date | null, end: string | Date | null, now: string | Date) => {
  const nowDate = toDate(now);
  if (start && isBefore(nowDate, toDate(start))) {
    return false;
  }
  return !(end && isAfter(nowDate, toDate(end)));
};

/**
 * 秒数を時刻テキスト(XX:XX:XX)に変換
 */
export const formatTextSeconds = (time: number): string => {
  const sMax = 60;
  const mMax = 60;
  const seconds = Math.floor(time % sMax);
  const minutes = (time >= sMax ? Math.floor(time / mMax % sMax) : 0);
  const hours = time >= (mMax * sMax) ? Math.floor(time / (mMax * sMax)) : 0;
  const s = Math.max(Math.min(seconds, sMax - 1), 0);
  const m = Math.max(Math.min(minutes, mMax - 1), 0);
  const h = Math.max(hours, 0);
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

/**
 * 秒数を時刻テキスト(XX:XX)に変換
 */
export const formatTextSecondsNoHour = (time: number): string => {
  const sMax = 60;
  const mMax = 60;
  const s = Math.floor(time % sMax);
  const m = Math.floor(time / mMax);
  return `${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
};

/**
 * 秒数を時刻テキスト(XX時間XX分)に変換
 */
export const formatJapaneseTextSeconds = (time: number): string => {
  const sMax = 60;
  const mMax = 60;
  const minutes = (time >= sMax ? Math.floor(time / mMax % sMax) : 0);
  const hours = time >= (mMax * sMax) ? Math.floor(time / (mMax * sMax)) : 0;
  const m = Math.max(Math.min(minutes, mMax - 1), 0);
  const h = Math.max(hours, 0);
  return `${h.toString().padStart(2, '0')}時間${m.toString().padStart(2, '0')}分`;
};

/**
 * 秒数から表示用残り時間を返却する
 * @param seconds
 */
export const getFormatRemainTime = (seconds: number): string => {
  if (seconds >= 86400) {
    return Math.floor(seconds / 86400).toString() + '日';
  } else if (seconds >= 3600) {
    return Math.floor(seconds / 3600).toString() + '時間';
  } else if (seconds >= 60) {
    return Math.floor(seconds / 60).toString() + '分';
  }
  return '1分';
};

/**
 * 秒数からフレンドリスト用の表示用最終ログイン時間を返却する
 * @param seconds
 */
export const getFormatFriendListLoginTime = (seconds: number): string => {
  if (seconds >= 86400) {
    return Math.floor(seconds / 86400).toString() + '日以上前';
  } else if (seconds >= 3600) {
    return Math.floor(seconds / 3600).toString() + '時間前';
  } else if (seconds > 300 && seconds < 3600) {
    // 現在日時と最終ログイン日時との差分が301秒(5分1秒)から3600秒(1時間)より前なら下記の表示
    // 5分ごとに表記する(ex.5分前10分前 etc)
    return (Math.floor(seconds / 300) * 5).toString() + '分前';
  }
  return '1分前';
};
