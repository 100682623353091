/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDeckRental } from './UserDeckRental';
import {
    UserDeckRentalFromJSON,
    UserDeckRentalFromJSONTyped,
    UserDeckRentalToJSON,
} from './UserDeckRental';
import type { UserMinigameTopAppealMessage } from './UserMinigameTopAppealMessage';
import {
    UserMinigameTopAppealMessageFromJSON,
    UserMinigameTopAppealMessageFromJSONTyped,
    UserMinigameTopAppealMessageToJSON,
} from './UserMinigameTopAppealMessage';
import type { UserMinigameTopDeck } from './UserMinigameTopDeck';
import {
    UserMinigameTopDeckFromJSON,
    UserMinigameTopDeckFromJSONTyped,
    UserMinigameTopDeckToJSON,
} from './UserMinigameTopDeck';
import type { UserMinigameTopRentaledBonus } from './UserMinigameTopRentaledBonus';
import {
    UserMinigameTopRentaledBonusFromJSON,
    UserMinigameTopRentaledBonusFromJSONTyped,
    UserMinigameTopRentaledBonusToJSON,
} from './UserMinigameTopRentaledBonus';

/**
 * 
 * @export
 * @interface UserMinigameTop
 */
export interface UserMinigameTop {
    /**
     * 修練度
     * @type {number}
     * @memberof UserMinigameTop
     */
    minigameP: number;
    /**
     * アバター魅力値
     * @type {number}
     * @memberof UserMinigameTop
     */
    avatarP: number;
    /**
     * デッキリスト
     * @type {Array<UserMinigameTopDeck>}
     * @memberof UserMinigameTop
     */
    deckList: Array<UserMinigameTopDeck>;
    /**
     * 吹き出し
     * @type {Array<UserMinigameTopAppealMessage>}
     * @memberof UserMinigameTop
     */
    appealMessageList: Array<UserMinigameTopAppealMessage>;
    /**
     * 1回のミニゲームに必要な体力
     * @type {number}
     * @memberof UserMinigameTop
     */
    requireStaminaP: number;
    /**
     * 
     * @type {UserDeckRental}
     * @memberof UserMinigameTop
     */
    recommendRental: UserDeckRental;
    /**
     * 
     * @type {UserMinigameTopRentaledBonus}
     * @memberof UserMinigameTop
     */
    rentaledBonus: UserMinigameTopRentaledBonus;
}

/**
 * Check if a given object implements the UserMinigameTop interface.
 */
export function instanceOfUserMinigameTop(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minigameP" in value;
    isInstance = isInstance && "avatarP" in value;
    isInstance = isInstance && "deckList" in value;
    isInstance = isInstance && "appealMessageList" in value;
    isInstance = isInstance && "requireStaminaP" in value;
    isInstance = isInstance && "recommendRental" in value;
    isInstance = isInstance && "rentaledBonus" in value;

    return isInstance;
}

export function UserMinigameTopFromJSON(json: any): UserMinigameTop {
    return UserMinigameTopFromJSONTyped(json, false);
}

export function UserMinigameTopFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMinigameTop {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minigameP': json['minigame_p'],
        'avatarP': json['avatar_p'],
        'deckList': ((json['deck_list'] as Array<any>).map(UserMinigameTopDeckFromJSON)),
        'appealMessageList': ((json['appeal_message_list'] as Array<any>).map(UserMinigameTopAppealMessageFromJSON)),
        'requireStaminaP': json['require_stamina_p'],
        'recommendRental': UserDeckRentalFromJSON(json['recommend_rental']),
        'rentaledBonus': UserMinigameTopRentaledBonusFromJSON(json['rentaled_bonus']),
    };
}

export function UserMinigameTopToJSON(value?: UserMinigameTop | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minigame_p': value.minigameP,
        'avatar_p': value.avatarP,
        'deck_list': ((value.deckList as Array<any>).map(UserMinigameTopDeckToJSON)),
        'appeal_message_list': ((value.appealMessageList as Array<any>).map(UserMinigameTopAppealMessageToJSON)),
        'require_stamina_p': value.requireStaminaP,
        'recommend_rental': UserDeckRentalToJSON(value.recommendRental),
        'rentaled_bonus': UserMinigameTopRentaledBonusToJSON(value.rentaledBonus),
    };
}

