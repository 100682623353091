/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserBadgeResponseData } from './UserBadgeResponseData';
import {
    UserBadgeResponseDataFromJSON,
    UserBadgeResponseDataFromJSONTyped,
    UserBadgeResponseDataToJSON,
} from './UserBadgeResponseData';

/**
 * 
 * @export
 * @interface UserBadgeResponse
 */
export interface UserBadgeResponse {
    /**
     * 
     * @type {UserBadgeResponseData}
     * @memberof UserBadgeResponse
     */
    data: UserBadgeResponseData;
}

/**
 * Check if a given object implements the UserBadgeResponse interface.
 */
export function instanceOfUserBadgeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserBadgeResponseFromJSON(json: any): UserBadgeResponse {
    return UserBadgeResponseFromJSONTyped(json, false);
}

export function UserBadgeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBadgeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserBadgeResponseDataFromJSON(json['data']),
    };
}

export function UserBadgeResponseToJSON(value?: UserBadgeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserBadgeResponseDataToJSON(value.data),
    };
}

