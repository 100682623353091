/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventBonusSpecialStory
 */
export interface EventBonusSpecialStory {
    /**
     * 特別ストーリーコード
     * @type {string}
     * @memberof EventBonusSpecialStory
     */
    specialStoryCd: string;
    /**
     * ストーリーチケットコード
     * @type {string}
     * @memberof EventBonusSpecialStory
     */
    storyTicketCd: string;
    /**
     * タイトル
     * @type {string}
     * @memberof EventBonusSpecialStory
     */
    title: string;
    /**
     * クリア前に表示される文言
     * @type {string}
     * @memberof EventBonusSpecialStory
     */
    preview: string;
}

/**
 * Check if a given object implements the EventBonusSpecialStory interface.
 */
export function instanceOfEventBonusSpecialStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "specialStoryCd" in value;
    isInstance = isInstance && "storyTicketCd" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "preview" in value;

    return isInstance;
}

export function EventBonusSpecialStoryFromJSON(json: any): EventBonusSpecialStory {
    return EventBonusSpecialStoryFromJSONTyped(json, false);
}

export function EventBonusSpecialStoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventBonusSpecialStory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specialStoryCd': json['special_story_cd'],
        'storyTicketCd': json['story_ticket_cd'],
        'title': json['title'],
        'preview': json['preview'],
    };
}

export function EventBonusSpecialStoryToJSON(value?: EventBonusSpecialStory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'special_story_cd': value.specialStoryCd,
        'story_ticket_cd': value.storyTicketCd,
        'title': value.title,
        'preview': value.preview,
    };
}

