<template>
  <div v-if="errorObj">
    <ModalErrorRetry
      v-if="errorType === 'retry'"
      :show="props.show"
      :error="errorObj"
      :z-index="zIndex"
      @click="onRetryClick"
      @click-cancel="onTitleClick"
    />
    <ModalErrorTitle v-else-if="errorType === 'title'" :show="props.show" :error="errorObj" :z-index="zIndex" @click="onTitleClick" />
    <ModalErrorUnAuth v-else-if="errorType === 'unAuth'" :show="props.show" :error="errorObj" :z-index="zIndex" @click="onExpiredClick" />
    <ModalErrorMaintenance v-else-if="errorType === 'maintenance'" :show="props.show" :error="errorObj" :z-index="zIndex" @click="onTitleClick" />
    <ModalErrorBan v-else-if="errorType === 'ban'" :show="props.show" :error="errorObj" :z-index="zIndex" @click="onContractUs" />
    <ModalErrorPlane v-else-if="errorType === 'plane'" :show="props.show" :error="errorObj" :z-index="zIndex" @click="onClickPlane" />
    <ModalErrorEventFinish v-else-if="errorType === 'eventEnd'" :show="props.show" :z-index="zIndex" @click="onClickEventEnd" />
    <ModalErrorBasic
      v-else
      :show="props.show"
      :error="errorObj"
      :z-index="zIndex"
      @click="onClick"
      @click-cancel="onTitleClick"
    />
  </div>
</template>

<script lang="ts" setup>
import { useNuxtApp } from '#app';
import {
  ApiAuthenticationError,
  ApiExpiredIdTokenError,
  convertShowError,
  MaintenanceError,
  RetryReloadError,
  FirebaseAppError,
  BanUserError,
  AppPlaneVisibleError, ApiError,
} from '~/libs/errors';
import { useRetryCount } from '~/composables/useRetryCount';
import { errorConfig } from '~/configs/errors';
import { isAnimatePlatform, isMobileApp } from '~/libs/platform';
import { openNewWindow } from '~/libs/utils';

type ErrorModalType = 'retry' | 'title' | 'unAuth' | 'maintenance' | 'ban' | 'plane' | 'basic' | 'eventEnd';
const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
}>(), {
  show: false,
});
const errorObj = ref<Error | null>();
const errorType = ref<ErrorModalType>('basic');
const zIndex = 1099;

const getErrorType = (e: Error|null): ErrorModalType => {
  let error = e;
  let isRetry = false;
  if (error instanceof RetryReloadError) {
    error = error.orgError;
    isRetry = true;
  }
  if (error instanceof MaintenanceError) {
    return 'maintenance';
  } else if (error instanceof BanUserError) {
    return 'ban';
  } else if (error instanceof FirebaseAppError) {
    return 'basic';
  } else if (error instanceof AppPlaneVisibleError) {
    return 'plane';
  } else if (error instanceof ApiExpiredIdTokenError || error instanceof ApiAuthenticationError) {
    return 'unAuth';
  } else if (error instanceof ApiError && error.code === errorConfig.errorCodes.notPeriodScenarioEventError) {
    return 'eventEnd';
  } else if (isRetry) {
    if (useRetryCount().value >= errorConfig.maxRetryCount) {
      return 'title';
    } else {
      return 'retry';
    }
  }
  return 'basic';
};
const updateError = (current: Error | null) => {
  // 表示するエラーを変換
  errorObj.value = current ? convertShowError(current) : null;

  errorType.value = getErrorType(current);
  if (current instanceof RetryReloadError) {
    if (errorType.value === 'retry') {
      useRetryCount().value = useRetryCount().value + 1;
    } else {
      useRetryCount().value = 0;
    }
  }
};
updateError(props.error);
watch(() => props.error, (current) => {
  updateError(current);
});

interface Emits {
  (e: 'click'): void;
  (e: 'clickCancel'): void;
}
const emit = defineEmits<Emits>();

const onClickPlane = async () => {
  // リンク指定がある時はそちらを優先する
  let backUrl: string|undefined;
  if (errorObj.value instanceof AppPlaneVisibleError) {
    backUrl = errorObj.value.backUrl;
  }
  if (backUrl) {
    // NOTE: 現在のページにリダイレクトするために、リンクをわざと変えている
    await navigateTo({
      path: backUrl,
      query: {
        retry: Date.now(),
      },
    }, {
      replace: true,
    });
  }
  emit('click');
};
const onClickEventEnd = async () => {
  emit('click');
  await navigateTo('/mypage');
};
const onClick = () => {
  useLoading().loader.stopAll();
  emit('click');
};
const onRetryClick = async () => {
  useLoading().loader.stopAll();
  emit('click');
  const route = useRoute();
  // router.push(route);
  // router.go(0);
  // NOTE: 現在のページにリダイレクトするために、リンクをわざと変えている
  await navigateTo({
    path: route.path,
    query: {
      ...route.query,
      retry: Date.now(),
    },
  }, {
    replace: true,
  });
};
const onTitleClick = async () => {
  emit('click');
  await navigateTo('/');
  useLoading().loader.stopAll();
};
const onExpiredClick = async () => {
  emit('click');
  if (isAnimatePlatform()) {
    await useAuth().signOut();
  } else {
    try {
      // ログアウトすると匿名ユーザーはまずいのでtokenを更新しチェックする
      await useAuth().getNewestIdToken(true);
    } catch (e) {
      // ここでのエラーはスルーして問題ないため
      useLog('error').debug('silent error', e);
    }
  }
  await navigateTo('/');
  useLoading().loader.stopAll();
};
/**
 * 外部ブラウザでお問い合わせフォームを表示
 */
const onContractUs = async () => {
  // お問い合わせURLの取得
  const { $getContactUsUrl } = useNuxtApp();
  const contactUsUrl = $getContactUsUrl();
  const { $nativeBridge } = useNuxtApp();

  if (isMobileApp()) {
    await $nativeBridge.send('openUrl', {
      url: contactUsUrl,
    });
  } else {
    openNewWindow(contactUsUrl);
  }
};
</script>
