/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * ストーリーのルート
 * @export
 */
export const StoryRouteType = {
    A: 'a',
    B: 'b',
    _0: '0'
} as const;
export type StoryRouteType = typeof StoryRouteType[keyof typeof StoryRouteType];


export function StoryRouteTypeFromJSON(json: any): StoryRouteType {
    return StoryRouteTypeFromJSONTyped(json, false);
}

export function StoryRouteTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryRouteType {
    return json as StoryRouteType;
}

export function StoryRouteTypeToJSON(value?: StoryRouteType | null): any {
    return value as any;
}

