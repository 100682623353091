/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGachaPortalPickup
 */
export interface UserGachaPortalPickup {
    /**
     * カード名称
     * @type {string}
     * @memberof UserGachaPortalPickup
     */
    name: string;
    /**
     * カード魅力値
     * @type {number}
     * @memberof UserGachaPortalPickup
     */
    charm: number;
    /**
     * カードレアリティ
     * @type {number}
     * @memberof UserGachaPortalPickup
     */
    rarity: number;
    /**
     * ボイスコード
     * @type {string}
     * @memberof UserGachaPortalPickup
     */
    voiceCd: string | null;
    /**
     * ボイスキャラコード
     * @type {string}
     * @memberof UserGachaPortalPickup
     */
    voiceCharaCd: string | null;
    /**
     * ボイスリソースパーティション
     * @type {number}
     * @memberof UserGachaPortalPickup
     */
    voiceResourcePartition: number | null;
}

/**
 * Check if a given object implements the UserGachaPortalPickup interface.
 */
export function instanceOfUserGachaPortalPickup(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "charm" in value;
    isInstance = isInstance && "rarity" in value;
    isInstance = isInstance && "voiceCd" in value;
    isInstance = isInstance && "voiceCharaCd" in value;
    isInstance = isInstance && "voiceResourcePartition" in value;

    return isInstance;
}

export function UserGachaPortalPickupFromJSON(json: any): UserGachaPortalPickup {
    return UserGachaPortalPickupFromJSONTyped(json, false);
}

export function UserGachaPortalPickupFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaPortalPickup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'charm': json['charm'],
        'rarity': json['rarity'],
        'voiceCd': json['voice_cd'],
        'voiceCharaCd': json['voice_chara_cd'],
        'voiceResourcePartition': json['voice_resource_partition'],
    };
}

export function UserGachaPortalPickupToJSON(value?: UserGachaPortalPickup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'charm': value.charm,
        'rarity': value.rarity,
        'voice_cd': value.voiceCd,
        'voice_chara_cd': value.voiceCharaCd,
        'voice_resource_partition': value.voiceResourcePartition,
    };
}

