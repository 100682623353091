/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PanelmissionBonus } from './PanelmissionBonus';
import {
    PanelmissionBonusFromJSON,
    PanelmissionBonusFromJSONTyped,
    PanelmissionBonusToJSON,
} from './PanelmissionBonus';

/**
 * 
 * @export
 * @interface PanelmissionPanel
 */
export interface PanelmissionPanel {
    /**
     * パネルコード
     * @type {string}
     * @memberof PanelmissionPanel
     */
    panelmissionPanelCd: string;
    /**
     * パネル名
     * @type {string}
     * @memberof PanelmissionPanel
     */
    panelmissionPanelName: string;
    /**
     * パネル詳細
     * @type {string}
     * @memberof PanelmissionPanel
     */
    panelmissionPanelText: string;
    /**
     * 「挑戦する」ボタンの遷移先
     * @type {string}
     * @memberof PanelmissionPanel
     */
    panelmissionPanelLink: string;
    /**
     * 
     * @type {PanelmissionBonus}
     * @memberof PanelmissionPanel
     */
    panelmissionBonus: PanelmissionBonus;
    /**
     * ヒントテキスト
     * @type {string}
     * @memberof PanelmissionPanel
     */
    hintText?: string;
    /**
     * ヒント画像コード
     * @type {string}
     * @memberof PanelmissionPanel
     */
    resourceHintCd?: string;
    /**
     * リソースパーティション番号
     * @type {number}
     * @memberof PanelmissionPanel
     */
    resourcePartition?: number;
    /**
     * ミッションを達成するために必要な操作の実行回数
     * @type {number}
     * @memberof PanelmissionPanel
     */
    needCount: number;
    /**
     * 今までのユーザー操作の実行回数
     * @type {number}
     * @memberof PanelmissionPanel
     */
    achievedCount: number;
    /**
     * 達成フラグ（trueは達成済み）
     * @type {boolean}
     * @memberof PanelmissionPanel
     */
    clearFlg: boolean;
    /**
     * 報酬受け取りフラグ（trueは受け取り済み）
     * @type {boolean}
     * @memberof PanelmissionPanel
     */
    rewardFlg: boolean;
}

/**
 * Check if a given object implements the PanelmissionPanel interface.
 */
export function instanceOfPanelmissionPanel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "panelmissionPanelCd" in value;
    isInstance = isInstance && "panelmissionPanelName" in value;
    isInstance = isInstance && "panelmissionPanelText" in value;
    isInstance = isInstance && "panelmissionPanelLink" in value;
    isInstance = isInstance && "panelmissionBonus" in value;
    isInstance = isInstance && "needCount" in value;
    isInstance = isInstance && "achievedCount" in value;
    isInstance = isInstance && "clearFlg" in value;
    isInstance = isInstance && "rewardFlg" in value;

    return isInstance;
}

export function PanelmissionPanelFromJSON(json: any): PanelmissionPanel {
    return PanelmissionPanelFromJSONTyped(json, false);
}

export function PanelmissionPanelFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionPanel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'panelmissionPanelCd': json['panelmission_panel_cd'],
        'panelmissionPanelName': json['panelmission_panel_name'],
        'panelmissionPanelText': json['panelmission_panel_text'],
        'panelmissionPanelLink': json['panelmission_panel_link'],
        'panelmissionBonus': PanelmissionBonusFromJSON(json['panelmission_bonus']),
        'hintText': !exists(json, 'hint_text') ? undefined : json['hint_text'],
        'resourceHintCd': !exists(json, 'resource_hint_cd') ? undefined : json['resource_hint_cd'],
        'resourcePartition': !exists(json, 'resource_partition') ? undefined : json['resource_partition'],
        'needCount': json['need_count'],
        'achievedCount': json['achieved_count'],
        'clearFlg': json['clear_flg'],
        'rewardFlg': json['reward_flg'],
    };
}

export function PanelmissionPanelToJSON(value?: PanelmissionPanel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'panelmission_panel_cd': value.panelmissionPanelCd,
        'panelmission_panel_name': value.panelmissionPanelName,
        'panelmission_panel_text': value.panelmissionPanelText,
        'panelmission_panel_link': value.panelmissionPanelLink,
        'panelmission_bonus': PanelmissionBonusToJSON(value.panelmissionBonus),
        'hint_text': value.hintText,
        'resource_hint_cd': value.resourceHintCd,
        'resource_partition': value.resourcePartition,
        'need_count': value.needCount,
        'achieved_count': value.achievedCount,
        'clear_flg': value.clearFlg,
        'reward_flg': value.rewardFlg,
    };
}

