/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPresentReceiveRequest
 */
export interface UserPresentReceiveRequest {
    /**
     * 種別
     * @type {string}
     * @memberof UserPresentReceiveRequest
     */
    presentType?: UserPresentReceiveRequestPresentTypeEnum;
    /**
     * プレゼントリストを取得した日時
     * @type {string}
     * @memberof UserPresentReceiveRequest
     */
    presentListAcquiredAt?: string;
    /**
     * 種別
     * @type {Array<number>}
     * @memberof UserPresentReceiveRequest
     */
    userPresentIds?: Array<number>;
    /**
     * 種別
     * @type {string}
     * @memberof UserPresentReceiveRequest
     */
    receiveType?: UserPresentReceiveRequestReceiveTypeEnum;
}


/**
 * @export
 */
export const UserPresentReceiveRequestPresentTypeEnum = {
    Item: 'item',
    Avatar: 'avatar',
    Card: 'card'
} as const;
export type UserPresentReceiveRequestPresentTypeEnum = typeof UserPresentReceiveRequestPresentTypeEnum[keyof typeof UserPresentReceiveRequestPresentTypeEnum];

/**
 * @export
 */
export const UserPresentReceiveRequestReceiveTypeEnum = {
    All: 'all',
    Limit: 'limit',
    Select: 'select'
} as const;
export type UserPresentReceiveRequestReceiveTypeEnum = typeof UserPresentReceiveRequestReceiveTypeEnum[keyof typeof UserPresentReceiveRequestReceiveTypeEnum];


/**
 * Check if a given object implements the UserPresentReceiveRequest interface.
 */
export function instanceOfUserPresentReceiveRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserPresentReceiveRequestFromJSON(json: any): UserPresentReceiveRequest {
    return UserPresentReceiveRequestFromJSONTyped(json, false);
}

export function UserPresentReceiveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresentReceiveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presentType': !exists(json, 'present_type') ? undefined : json['present_type'],
        'presentListAcquiredAt': !exists(json, 'present_list_acquired_at') ? undefined : json['present_list_acquired_at'],
        'userPresentIds': !exists(json, 'user_present_ids') ? undefined : json['user_present_ids'],
        'receiveType': !exists(json, 'receive_type') ? undefined : json['receive_type'],
    };
}

export function UserPresentReceiveRequestToJSON(value?: UserPresentReceiveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present_type': value.presentType,
        'present_list_acquired_at': value.presentListAcquiredAt,
        'user_present_ids': value.userPresentIds,
        'receive_type': value.receiveType,
    };
}

