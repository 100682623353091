/**
 * エラーレポートツールに送信する
 */
export const useMonitoring = () => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    notify: (e: any) => {
      if (useBugsnag()) {
        useBugsnag().notify(e);
      }
    },
  };
};
