/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 課金種別
 * @export
 */
export const BillingProductPurchaseType = {
    Consumable: 'consumable',
    NonConsumable: 'non_consumable',
    Subscription: 'subscription'
} as const;
export type BillingProductPurchaseType = typeof BillingProductPurchaseType[keyof typeof BillingProductPurchaseType];


export function BillingProductPurchaseTypeFromJSON(json: any): BillingProductPurchaseType {
    return BillingProductPurchaseTypeFromJSONTyped(json, false);
}

export function BillingProductPurchaseTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingProductPurchaseType {
    return json as BillingProductPurchaseType;
}

export function BillingProductPurchaseTypeToJSON(value?: BillingProductPurchaseType | null): any {
    return value as any;
}

