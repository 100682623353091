import { toDate } from '~/libs/date';

interface TutorialConfig {
  noRequiredRoutes: Array<string | RegExp>,
  tutorialCd: {
    [key: string]: string;
  },
  redirectRoute: {
    [key: string]: string;
  },
  prologueLastOrderNo: number,
}

export const tutorialConfig: TutorialConfig = {
  // リダイレクトが不要なページ
  noRequiredRoutes: [
    '/',
    '/att',
    '/auth',
    '/terms-of-service',
    '/title',
    '/no-support',
    '/version-up',
    '/update',
    '/account',
    '/story/play',
    /\/_debug\/?.*/,
    /\/debug\/?.*/,
  ],
  // チュートリアルコード
  tutorialCd: {
    first: '1', // 初回起動
    nameRegistered: '2', // 名前登録完了
    // 1000～1008はプロローグシーン1～9読了
    prologueEnd: '1099', // プロローグ終了(プロローグシーン10読了)
    prologueSkip: '1100', // プロローグスキップ状態
    charaSelected: '1101', // お相手選択完了
    tutorialEnd: '9999', // チュートリアル終了
  },
  // ステータスに応じた遷移先
  redirectRoute: {
    1: '/tutorial/name', // 初回起動
    2: '/tutorial/prologue', // 名前登録完了
    1000: '/tutorial/prologue', // プロローグシーン1読了
    1001: '/tutorial/prologue', // プロローグシーン2読了
    1002: '/tutorial/prologue', // プロローグシーン3読了
    1003: '/tutorial/prologue', // プロローグシーン4読了
    1004: '/tutorial/prologue', // プロローグシーン5読了
    1005: '/tutorial/prologue', // プロローグシーン6読了
    1006: '/tutorial/prologue', // プロローグシーン7読了
    1007: '/tutorial/prologue', // プロローグシーン8読了
    1008: '/tutorial/prologue', // プロローグシーン9読了
    1099: '/chara/select', // プロローグ終了(プロローグシーン10読了)
    1100: '/chara/select', // プロローグスキップ状態
    1101: '/mypage', // お相手選択完了
  },
  // プロローグ最後のシーン番号
  prologueLastOrderNo: 10,
};

/**
 * 初回利用キー
 * @export
 */
export const FirstUseKey = {
  CharaSelectFlg: 'charaSelectFlg', // キャラ選択チュートリアル
  GachaListFlg: 'gachaListFlg', // ガチャチュートリアル
  DeckSaveFlg: 'deckSaveFlg', // ユニット編成チュートリアル
  MinigameFlg: 'minigameFlg', // ミニゲームチュートリアル(見廻り)
  StoryTicketFlg: 'storyTicketFlg', // ストーリーチケットチュートリアル
  StoryListFlg: 'storyListFlg', // [ストーリーリスト]チュートリアル
  StoryListHisStoryFlg: 'storyListHisStoryFlg', // [ストーリーリスト]彼目線チュートリアル
  StoryListStillFlg: 'storyListStillFlg', // [ストーリーリスト]スチルチュートリアル
  StoryChoicesFlg: 'storyChoicesFlg', // 選択肢チュートリアル
  CompleteTicketParameterFlg: 'completeTicketParameterFlg', // 好感度チュートリアル
  CompleteTicketChapterFlg: 'completeTicketChapterFlg', // 1話読了チュートリアル(ストーリーのおさらい)
  ParameterMissionFlg: 'parameterMissionFlg', // 親密度ミッションチュートリアル(修練試練)
  AvatarMissionFlg: 'avatarMissionFlg', // アバターミッションチュートリアル（スタイルチャレンジ)
  AvatarTopFlg: 'avatarTopFlg', // アバターTOPチュートリアル(ルーム)
  AvatarPrinceFlg: 'avatarPrinceFlg', // アバター彼アバチュートリアル)(彼キャラ)
  AvatarRoomFlg: 'avatarRoomFlg', // アバター模様替えチュートリアル
  AvatarClosetFlg: 'avatarClosetFlg', // アバタークローゼットチュートリアル
  AvatarSellFlg: 'avatarSellFlg', // アバター売却チュートリアル
  PresentBoxFlg: 'presentBoxFlg', // プレゼントBOXチュートリアル(受取箱)
  CardSynthesisFlg: 'cardSynthesisFlg', // カード合成チュートリアル(自動合成)
  MypageCardFlg: 'mypageCardFlg', // マイページ彼カードチュートリアル(ホームカード)
  HisCollectionFlg: 'hisCollectionFlg', // 彼コレチュートリアル
  CommuFlg: 'commuFlg', // 会話
  AccountLinkFlg: 'accountLinkFlg', // データ連携連携/アカウント削除
  CompleteTutorialFlg: 'completeTutorialFlg', // チュートリアル突破
  EventParameterMissionFlg: 'eventParameterMissionFlg', // [シナリオイベント]討伐ミッション
  EventMinigameFlg: 'eventMinigameFlg', // [シナリオイベント]スペシャルデート
  RedrawGachaFlg: 'RedrawGachaFlg', // 引き直しガチャチュートリアル
  FriendFlg: 'friendFlg', // フレンドチュートリアル
  FriendLikeFlg: 'friendLikeFlg', // いいねリストチュートリアル
  FriendProfileFlg: 'friendProfileFlg', // フレンドプロフィールチュートリアル
} as const;
// eslint-disable-next-line no-redeclare
export type FirstUseKey = typeof FirstUseKey[keyof typeof FirstUseKey];

// 初回モーダルの切り替わり日時
export const FirstUseModalEndedAt = {
  CompleteTutorial: toDate('2023-08-28 11:59:59'),
};
