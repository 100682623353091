/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventStoryListMission } from './EventStoryListMission';
import {
    EventStoryListMissionFromJSON,
    EventStoryListMissionFromJSONTyped,
    EventStoryListMissionToJSON,
} from './EventStoryListMission';
import type { EventStoryListNextTicket } from './EventStoryListNextTicket';
import {
    EventStoryListNextTicketFromJSON,
    EventStoryListNextTicketFromJSONTyped,
    EventStoryListNextTicketToJSON,
} from './EventStoryListNextTicket';
import type { EventStoryListRequiredMissionParameter } from './EventStoryListRequiredMissionParameter';
import {
    EventStoryListRequiredMissionParameterFromJSON,
    EventStoryListRequiredMissionParameterFromJSONTyped,
    EventStoryListRequiredMissionParameterToJSON,
} from './EventStoryListRequiredMissionParameter';
import type { EventStoryRouteType } from './EventStoryRouteType';
import {
    EventStoryRouteTypeFromJSON,
    EventStoryRouteTypeFromJSONTyped,
    EventStoryRouteTypeToJSON,
} from './EventStoryRouteType';
import type { UserEventClearRoute } from './UserEventClearRoute';
import {
    UserEventClearRouteFromJSON,
    UserEventClearRouteFromJSONTyped,
    UserEventClearRouteToJSON,
} from './UserEventClearRoute';
import type { UserEventStoryClearMissionList } from './UserEventStoryClearMissionList';
import {
    UserEventStoryClearMissionListFromJSON,
    UserEventStoryClearMissionListFromJSONTyped,
    UserEventStoryClearMissionListToJSON,
} from './UserEventStoryClearMissionList';

/**
 * 
 * @export
 * @interface UserEventStoryStatus
 */
export interface UserEventStoryStatus {
    /**
     * 選択中のキャラコード
     * @type {string}
     * @memberof UserEventStoryStatus
     */
    charaCd: string | null;
    /**
     * 
     * @type {EventStoryRouteType}
     * @memberof UserEventStoryStatus
     */
    route: EventStoryRouteType;
    /**
     * クリア済みルート
     * @type {Array<UserEventClearRoute>}
     * @memberof UserEventStoryStatus
     */
    clearRouteList: Array<UserEventClearRoute>;
    /**
     * クリア済みミッション情報
     * @type {Array<UserEventStoryClearMissionList>}
     * @memberof UserEventStoryStatus
     */
    clearMissionList: Array<UserEventStoryClearMissionList>;
    /**
     * 
     * @type {EventStoryListNextTicket}
     * @memberof UserEventStoryStatus
     */
    nextTicket: EventStoryListNextTicket;
    /**
     * 
     * @type {EventStoryListRequiredMissionParameter}
     * @memberof UserEventStoryStatus
     */
    requiredMissionParameter: EventStoryListRequiredMissionParameter | null;
    /**
     * 
     * @type {EventStoryListMission}
     * @memberof UserEventStoryStatus
     */
    occurringMission: EventStoryListMission | null;
}

/**
 * Check if a given object implements the UserEventStoryStatus interface.
 */
export function instanceOfUserEventStoryStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "route" in value;
    isInstance = isInstance && "clearRouteList" in value;
    isInstance = isInstance && "clearMissionList" in value;
    isInstance = isInstance && "nextTicket" in value;
    isInstance = isInstance && "requiredMissionParameter" in value;
    isInstance = isInstance && "occurringMission" in value;

    return isInstance;
}

export function UserEventStoryStatusFromJSON(json: any): UserEventStoryStatus {
    return UserEventStoryStatusFromJSONTyped(json, false);
}

export function UserEventStoryStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventStoryStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'route': EventStoryRouteTypeFromJSON(json['route']),
        'clearRouteList': ((json['clear_route_list'] as Array<any>).map(UserEventClearRouteFromJSON)),
        'clearMissionList': ((json['clear_mission_list'] as Array<any>).map(UserEventStoryClearMissionListFromJSON)),
        'nextTicket': EventStoryListNextTicketFromJSON(json['next_ticket']),
        'requiredMissionParameter': EventStoryListRequiredMissionParameterFromJSON(json['required_mission_parameter']),
        'occurringMission': EventStoryListMissionFromJSON(json['occurring_mission']),
    };
}

export function UserEventStoryStatusToJSON(value?: UserEventStoryStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'route': EventStoryRouteTypeToJSON(value.route),
        'clear_route_list': ((value.clearRouteList as Array<any>).map(UserEventClearRouteToJSON)),
        'clear_mission_list': ((value.clearMissionList as Array<any>).map(UserEventStoryClearMissionListToJSON)),
        'next_ticket': EventStoryListNextTicketToJSON(value.nextTicket),
        'required_mission_parameter': EventStoryListRequiredMissionParameterToJSON(value.requiredMissionParameter),
        'occurring_mission': EventStoryListMissionToJSON(value.occurringMission),
    };
}

