/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MinigameDirectionType } from './MinigameDirectionType';
import {
    MinigameDirectionTypeFromJSON,
    MinigameDirectionTypeFromJSONTyped,
    MinigameDirectionTypeToJSON,
} from './MinigameDirectionType';

/**
 * 
 * @export
 * @interface MinigameDirection
 */
export interface MinigameDirection {
    /**
     * ミニゲーム演出コード
     * @type {string}
     * @memberof MinigameDirection
     */
    minigameDirectionCd: string;
    /**
     * 
     * @type {MinigameDirectionType}
     * @memberof MinigameDirection
     */
    type: MinigameDirectionType;
    /**
     * 表示メッセージ
     * @type {string}
     * @memberof MinigameDirection
     */
    message: string;
    /**
     * 立ち絵画像コード
     * @type {string}
     * @memberof MinigameDirection
     */
    bodyResourceImageCd: string;
    /**
     * 立ち絵リソースパーティション番号
     * @type {number}
     * @memberof MinigameDirection
     */
    bodyResourcePartition: number;
    /**
     * 表情差分画像コード
     * @type {string}
     * @memberof MinigameDirection
     */
    faceResourceImageCd: string;
    /**
     * 表情差分リソースパーティション番号
     * @type {number}
     * @memberof MinigameDirection
     */
    faceResourcePartition: number;
    /**
     * ストーリー背景画像コード
     * @type {string}
     * @memberof MinigameDirection
     */
    bgResourceImageCd: string;
    /**
     * ストーリー背景リソースパーティション番号
     * @type {number}
     * @memberof MinigameDirection
     */
    bgResourcePartition: number;
    /**
     * ボイスコード
     * @type {string}
     * @memberof MinigameDirection
     */
    voiceCd: string | null;
    /**
     * ボイスリソースパーティション
     * @type {number}
     * @memberof MinigameDirection
     */
    voiceResourcePartition: number | null;
    /**
     * キャラコード
     * @type {string}
     * @memberof MinigameDirection
     */
    charaCd: string | null;
    /**
     * キャラ名
     * @type {string}
     * @memberof MinigameDirection
     */
    charaName: string | null;
    /**
     * 確率(%)
     * @type {number}
     * @memberof MinigameDirection
     */
    probability: number;
}

/**
 * Check if a given object implements the MinigameDirection interface.
 */
export function instanceOfMinigameDirection(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "minigameDirectionCd" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "bodyResourceImageCd" in value;
    isInstance = isInstance && "bodyResourcePartition" in value;
    isInstance = isInstance && "faceResourceImageCd" in value;
    isInstance = isInstance && "faceResourcePartition" in value;
    isInstance = isInstance && "bgResourceImageCd" in value;
    isInstance = isInstance && "bgResourcePartition" in value;
    isInstance = isInstance && "voiceCd" in value;
    isInstance = isInstance && "voiceResourcePartition" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "charaName" in value;
    isInstance = isInstance && "probability" in value;

    return isInstance;
}

export function MinigameDirectionFromJSON(json: any): MinigameDirection {
    return MinigameDirectionFromJSONTyped(json, false);
}

export function MinigameDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinigameDirection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'minigameDirectionCd': json['minigame_direction_cd'],
        'type': MinigameDirectionTypeFromJSON(json['type']),
        'message': json['message'],
        'bodyResourceImageCd': json['body_resource_image_cd'],
        'bodyResourcePartition': json['body_resource_partition'],
        'faceResourceImageCd': json['face_resource_image_cd'],
        'faceResourcePartition': json['face_resource_partition'],
        'bgResourceImageCd': json['bg_resource_image_cd'],
        'bgResourcePartition': json['bg_resource_partition'],
        'voiceCd': json['voice_cd'],
        'voiceResourcePartition': json['voice_resource_partition'],
        'charaCd': json['chara_cd'],
        'charaName': json['chara_name'],
        'probability': json['probability'],
    };
}

export function MinigameDirectionToJSON(value?: MinigameDirection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'minigame_direction_cd': value.minigameDirectionCd,
        'type': MinigameDirectionTypeToJSON(value.type),
        'message': value.message,
        'body_resource_image_cd': value.bodyResourceImageCd,
        'body_resource_partition': value.bodyResourcePartition,
        'face_resource_image_cd': value.faceResourceImageCd,
        'face_resource_partition': value.faceResourcePartition,
        'bg_resource_image_cd': value.bgResourceImageCd,
        'bg_resource_partition': value.bgResourcePartition,
        'voice_cd': value.voiceCd,
        'voice_resource_partition': value.voiceResourcePartition,
        'chara_cd': value.charaCd,
        'chara_name': value.charaName,
        'probability': value.probability,
    };
}

