import { RouteLocationNormalized } from 'vue-router';

// 読み込み中のロードキー
let loadingKey: symbol|undefined;

const startLoading = () => {
  stopLoading();
  loadingKey = useLoading().loader.start();
};
/**
 * ローディングの停止
 * @param waitTime 遅延時間(ms)
 */
const stopLoading = (waitTime = 0) => {
  if (loadingKey) {
    const tmpKey = loadingKey;
    loadingKey = undefined;
    const stopLoadingExecute = () => {
      useLoading().loader.stop(tmpKey);
    };
    if (waitTime > 0) {
      setTimeout(stopLoadingExecute, 500);
    } else {
      stopLoadingExecute();
    }
  }
};
const pageEndName = (to: RouteLocationNormalized) => {
  const pageNames = to.name?.toString().split('-');
  if (!pageNames || pageNames.length <= 0) {
    return undefined;
  }
  return pageNames[pageNames.length - 1];
};
/**
 * イベント開催期間中にアクセスできるページかチェック
 * @param to
 */
const executeEventActivePage = (to: RouteLocationNormalized) => {
  const page = pageEndName(to);
  if (!page) {
    return false;
  }
  return ['top', 'end', 'mypage', 'bonus', 'ranking', 'epilogue'].includes(page);
};

export default defineNuxtPlugin((nuxtApp) => {
  // middlewareで対応するとちらつきが出るため、ページ読み込み開始時にローディングを止める
  nuxtApp.hook('page:finish', () => {
    stopLoading(1000);
  });
  addRouteMiddleware('event', async (to, _) => {
    stopLoading();
    if (to.path.match(/^\/event\//) && getRouteEventCd(to)) {
      const eventCd = getRouteEventCd(to);
      if (!eventCd) {
        return;
      }
      startLoading();
      try {
        // 先にラボ短時間を更新するために通信を行う
        await useUser().update({
          cache: true,
        });
        // イベント情報の取得
        if (!(await useEvent().updatePageEvent(to))) {
          // イベント情報が取得できない or イベントが完全に終了している
          return navigateTo('/event/end');
        }
        // イベントページで開催期間以外の場合
        const event = useEvent().event.value;
        if (event) {
          if (!isActiveEvent(event) && !executeEventActivePage(to)) {
            // イベント開催期間が終了している
            return navigateEventTo(eventCd, '/end');
          }
        }
      } catch (e) {
        stopLoading();
        useReportAppError(e);
        return abortNavigation();
      }
    }
  }, { global: true });
});
