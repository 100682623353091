/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventStoryRouteType } from './EventStoryRouteType';
import {
    EventStoryRouteTypeFromJSON,
    EventStoryRouteTypeFromJSONTyped,
    EventStoryRouteTypeToJSON,
} from './EventStoryRouteType';
import type { EventUserStatus } from './EventUserStatus';
import {
    EventUserStatusFromJSON,
    EventUserStatusFromJSONTyped,
    EventUserStatusToJSON,
} from './EventUserStatus';

/**
 * 
 * @export
 * @interface EventStatus
 */
export interface EventStatus {
    /**
     * 苗字
     * @type {string}
     * @memberof EventStatus
     */
    lastName: string;
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof EventStatus
     */
    nickname: string;
    /**
     * イベントにエントリーしているか
     * @type {boolean}
     * @memberof EventStatus
     */
    entryFlg: boolean;
    /**
     * 攻略対象キャラコード
     * @type {string}
     * @memberof EventStatus
     */
    charaCd: string;
    /**
     * 攻略対象イベントキャラコード
     * @type {string}
     * @memberof EventStatus
     */
    eventCharaCd: string;
    /**
     * 選択中のイベントルートコード
     * @type {string}
     * @memberof EventStatus
     */
    eventRouteCd: string;
    /**
     * 
     * @type {EventUserStatus}
     * @memberof EventStatus
     */
    userStatus: EventUserStatus;
    /**
     * ストーリー進行状況
     * @type {string}
     * @memberof EventStatus
     */
    storyStatus: string;
    /**
     * 初回キャラ選択時間
     * @type {string}
     * @memberof EventStatus
     */
    firstSelectTime: string | null;
    /**
     * フィーバーコード
     * @type {string}
     * @memberof EventStatus
     */
    feverCd: string;
    /**
     * フィーバーの残り時間(秒)
     * @type {number}
     * @memberof EventStatus
     */
    feverTime: number;
    /**
     * フィーバー終了時間
     * @type {string}
     * @memberof EventStatus
     */
    feverEndTime: string | null;
    /**
     * イベントポイント
     * @type {number}
     * @memberof EventStatus
     */
    eventP: number;
    /**
     * ランキングが集計されているか
     * @type {boolean}
     * @memberof EventStatus
     */
    rankingTallyFlg: boolean;
    /**
     * ランキング順位
     * @type {number}
     * @memberof EventStatus
     */
    ranking: number;
    /**
     * ランキング圏内か
     * @type {boolean}
     * @memberof EventStatus
     */
    rankinFlg: boolean;
    /**
     * クリアしたルート数（重複なし）
     * @type {number}
     * @memberof EventStatus
     */
    clearCount: number;
    /**
     * 全ルート数
     * @type {number}
     * @memberof EventStatus
     */
    maxClearCount: number;
    /**
     * 
     * @type {EventStoryRouteType}
     * @memberof EventStatus
     */
    routeType: EventStoryRouteType;
    /**
     * 残りスタミナポイント
     * @type {number}
     * @memberof EventStatus
     */
    staminaPoint: number;
    /**
     * クリアしたミッションがあるか
     * @type {boolean}
     * @memberof EventStatus
     */
    firstMissionClearFlg: boolean;
}

/**
 * Check if a given object implements the EventStatus interface.
 */
export function instanceOfEventStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "entryFlg" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "eventRouteCd" in value;
    isInstance = isInstance && "userStatus" in value;
    isInstance = isInstance && "storyStatus" in value;
    isInstance = isInstance && "firstSelectTime" in value;
    isInstance = isInstance && "feverCd" in value;
    isInstance = isInstance && "feverTime" in value;
    isInstance = isInstance && "feverEndTime" in value;
    isInstance = isInstance && "eventP" in value;
    isInstance = isInstance && "rankingTallyFlg" in value;
    isInstance = isInstance && "ranking" in value;
    isInstance = isInstance && "rankinFlg" in value;
    isInstance = isInstance && "clearCount" in value;
    isInstance = isInstance && "maxClearCount" in value;
    isInstance = isInstance && "routeType" in value;
    isInstance = isInstance && "staminaPoint" in value;
    isInstance = isInstance && "firstMissionClearFlg" in value;

    return isInstance;
}

export function EventStatusFromJSON(json: any): EventStatus {
    return EventStatusFromJSONTyped(json, false);
}

export function EventStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lastName': json['last_name'],
        'nickname': json['nickname'],
        'entryFlg': json['entry_flg'],
        'charaCd': json['chara_cd'],
        'eventCharaCd': json['event_chara_cd'],
        'eventRouteCd': json['event_route_cd'],
        'userStatus': EventUserStatusFromJSON(json['user_status']),
        'storyStatus': json['story_status'],
        'firstSelectTime': json['first_select_time'],
        'feverCd': json['fever_cd'],
        'feverTime': json['fever_time'],
        'feverEndTime': json['fever_end_time'],
        'eventP': json['event_p'],
        'rankingTallyFlg': json['ranking_tally_flg'],
        'ranking': json['ranking'],
        'rankinFlg': json['rankin_flg'],
        'clearCount': json['clear_count'],
        'maxClearCount': json['max_clear_count'],
        'routeType': EventStoryRouteTypeFromJSON(json['route_type']),
        'staminaPoint': json['stamina_point'],
        'firstMissionClearFlg': json['first_mission_clear_flg'],
    };
}

export function EventStatusToJSON(value?: EventStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'last_name': value.lastName,
        'nickname': value.nickname,
        'entry_flg': value.entryFlg,
        'chara_cd': value.charaCd,
        'event_chara_cd': value.eventCharaCd,
        'event_route_cd': value.eventRouteCd,
        'user_status': EventUserStatusToJSON(value.userStatus),
        'story_status': value.storyStatus,
        'first_select_time': value.firstSelectTime,
        'fever_cd': value.feverCd,
        'fever_time': value.feverTime,
        'fever_end_time': value.feverEndTime,
        'event_p': value.eventP,
        'ranking_tally_flg': value.rankingTallyFlg,
        'ranking': value.ranking,
        'rankin_flg': value.rankinFlg,
        'clear_count': value.clearCount,
        'max_clear_count': value.maxClearCount,
        'route_type': EventStoryRouteTypeToJSON(value.routeType),
        'stamina_point': value.staminaPoint,
        'first_mission_clear_flg': value.firstMissionClearFlg,
    };
}

