/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserStoryClearMissionListInner
 */
export interface UserStoryClearMissionListInner {
    /**
     * ミッションコード
     * @type {string}
     * @memberof UserStoryClearMissionListInner
     */
    missionCd: string;
    /**
     * ミッションタイプ
     * @type {string}
     * @memberof UserStoryClearMissionListInner
     */
    type: string;
    /**
     * クリアタイプ
     * @type {string}
     * @memberof UserStoryClearMissionListInner
     */
    clearType: string | null;
}

/**
 * Check if a given object implements the UserStoryClearMissionListInner interface.
 */
export function instanceOfUserStoryClearMissionListInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "clearType" in value;

    return isInstance;
}

export function UserStoryClearMissionListInnerFromJSON(json: any): UserStoryClearMissionListInner {
    return UserStoryClearMissionListInnerFromJSONTyped(json, false);
}

export function UserStoryClearMissionListInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStoryClearMissionListInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'type': json['type'],
        'clearType': json['clear_type'],
    };
}

export function UserStoryClearMissionListInnerToJSON(value?: UserStoryClearMissionListInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'type': value.type,
        'clear_type': value.clearType,
    };
}

