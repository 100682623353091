// import { Howl, Howler } from 'howler';
import { useNuxtApp } from '#app';
// import Hls from 'hls.js';
import { audioConfig } from '~/configs/audio';
import { parsePath } from '~/libs/utils';
import { Sound, SoundEventCallback, SoundFadeParam, SoundEventHandler, SoundManager, SoundOption } from '~/libs/sound';
import { useLog } from '~/composables/useLog';

const logGroup = 'useAudio';
const defaultExtension = audioConfig.defaultExtension;
const defaultTagDuration = 500;

// interface AudioPlay {
//   audio: Howl,
//   soundId: number,
// }
//
export interface AudioOption {
  /**
   * タグ(同一タグは同時再生されないように制御)
   */
  tag: string,
  /**
   * ボリューム(0.0-1.0)
   */
  volume: number,
  /**
   * 全体のボリューム(0.0-1.0)
   */
  gainVolume: number,
  /**
   * ループ再生するか
   */
  loop: boolean,
  /**
   * 自動再生するか
   */
  autoplay: boolean,
  /**
   * 画面を跨いだ時に自動でFadeOutして音声が停止するか
   */
  fadeOut: boolean,
  /**
   * fade再生時間
   */
  fadeOutDuration: number,
  /**
   * 再生読み込み待ちの時間(ms)
   */
  loadOnPlayLimitTime: number,
  /**
   * 生成と同時にloadしておくか
   */
  preload: boolean,
}

type AudioType = 'bgm' | 'se' | 'voice';
type AudioOnEvent = 'end' | 'load' | 'loadError' | 'fade';
export interface AudioInterface {
  play: () => void;
  stop: () => void;
}

/**
 * 通常音声の再生
 */
export class Audio extends SoundEventHandler<AudioOnEvent> implements AudioInterface {
  // protected howl: Howl;
  //
  // constructor (howl: Howl) {
  //   this.howl = howl;
  // }
  //
  // public play () {
  //   this.howl.play();
  // }
  //
  // public on (event: AudioOnEvent, func: AudioOnCallback) {
  //   this.howl.on(event, func);
  // }
  //
  // public stop () {
  //   this.howl.stop();
  // }
  // // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // protected wad: Wad | any;
  // /**
  //  * Wadオブジェクトの取得
  //  * @note ES moduleに対応していないため動的インポートをおこなう
  //  * @protected
  //  * @return Wad
  //  */
  // protected static async getWad () {
  //   if (!this.wad) {
  //     this.wad = await import('web-audio-daw').then(lib => lib.default || lib);
  //   }
  //   return this.wad;
  // }
  protected static audioIdNumber = 0;

  /**
   * Audioの連番数値を取得
   */
  public static generateAudioId () {
    Audio.audioIdNumber += 1;
    return Audio.audioIdNumber;
  }

  /**
   * Audioの同一音声チェック用ID
   */
  public static generateAudioFileId (audioUrls: string[]) {
    return audioUrls[0] ?? '';
  }

  protected type: AudioType;
  protected id: number;
  protected tag?: string;
  protected sound: Sound | null = null;
  protected audioUrls: string[];
  protected options: Partial<AudioOption>;
  // 読み込み完了済みか
  protected isLoaded: boolean;
  get isPlaying () { return this.sound?.playing ?? false; }
  get isLoading () { return this.sound?.loading ?? false; }
  // 読み込み完了後に再生するか
  protected loadOnPlay?: 'play' | 'fade';
  protected loadOnPlayFadeParam?: SoundFadeParam;
  protected loadOnPlayTimer: number;
  protected loadOnPlayLimitTime: number;
  protected volume: number;

  /**
   * idを取得
   */
  public getId () : number {
    return this.id;
  }

  /**
   * タグを取得
   */
  public getTag () : string | undefined {
    return this.tag;
  }

  /**
   * idを取得
   */
  public getAudioFileId () : string {
    return Audio.generateAudioFileId(this.audioUrls);
  }

  /**
   * Soundオブジェクトを取得
   */
  public getSound () {
    return this.sound;
  }

  /**
   * 現在のボリュームを取得
   */
  public getVolume () : number {
    return this.volume;
  }

  /**
   * ボリュームの更新
   */
  public setVolume (volume: number) {
    this.volume = volume;
    if (this.sound) {
      this.sound.volume = volume;
    }
  }

  /**
   * 種別を取得
   */
  public getType () {
    return this.type;
  }

  protected onSoundLoadCallback: SoundEventCallback;
  protected onSoundLoadErrorCallback: SoundEventCallback;
  protected onSoundEndCallback: SoundEventCallback;

  /**
   * 読込後の再生処理のイベント処理
   * @protected
   */
  protected onLoad () {
    this.registerSoundEvent(false);
    this.isLoaded = true;
    // 指定秒数以内なら再生
    if (this.loadOnPlay && (this.loadOnPlayLimitTime <= 0 || (this.loadOnPlayTimer + this.loadOnPlayLimitTime) >= Date.now())) {
      if (this.loadOnPlay === 'play') {
        this.play();
      } else if (this.loadOnPlay === 'fade' && this.loadOnPlayFadeParam) {
        this.fade(this.loadOnPlayFadeParam);
      }
    }
    this.fireEvent('load');
  }

  /**
   * 読込エラー後のイベント処理
   * @protected
   */
  protected onLoadError () {
    this.registerSoundEvent(false);
    this.sound?.disconnect();
    this.sound = null;
    this.fireEvent('loadError');
  }

  /**
   * 再生後のイベント処理
   * @protected
   */
  protected onSoundEnd () {
    this.stop();
    this.fireEvent('end');
  }

  constructor (type: AudioType, audios: string[], options: Partial<AudioOption> = {}) {
    super();
    this.type = type;
    this.id = Audio.generateAudioId();
    this.tag = options.tag;
    this.audioUrls = audios;
    this.options = options;
    this.isLoaded = false;
    this.loadOnPlay = undefined;
    this.loadOnPlayFadeParam = undefined;
    this.loadOnPlayTimer = 0;
    this.volume = options?.volume ?? 1;
    this.loadOnPlayLimitTime = options?.loadOnPlayLimitTime !== undefined ? options?.loadOnPlayLimitTime : 1000;
    this.onSoundLoadCallback = this.onLoad.bind(this);
    this.onSoundLoadErrorCallback = this.onLoadError.bind(this);
    this.onSoundEndCallback = this.onSoundEnd.bind(this);
    if (options.preload === undefined || options.preload) {
      this.init();
    }
  }

  /**
   * サウンドのイベント追加
   * @param enable
   * @protected
   */
  protected registerSoundEvent (enable: boolean) {
    this.sound?.off('load', this.onSoundLoadCallback);
    this.sound?.off('loadError', this.onSoundLoadErrorCallback);
    if (enable) {
      this.sound?.once('load', this.onSoundLoadCallback);
      this.sound?.once('loadError', this.onSoundLoadErrorCallback);
    }
  }

  /**
   * 初期化処理
   * @protected
   */
  protected init () {
    // 既に読み込み済みなら処理させない
    if (this.sound) {
      return;
    }
    try {
      const url = this.audioUrls[0];
      this.isLoaded = false;
      this.loadOnPlay = undefined;
      this.loadOnPlayFadeParam = undefined;
      this.loadOnPlayTimer = 0;
      const opts: SoundOption = {
        volume: this.volume,
        gainVolume: this.options?.gainVolume ?? 1,
        loop: this.options?.loop ?? false,
        group: this.type,
      };
      this.sound = Sound.createFromUrl(url, opts);
      this.registerSoundEvent(true);
      if (this.options.autoplay) {
        this.play();
      }
      if (this.options.fadeOut) {
        // ページ遷移で自動FadeOut
        useNuxtApp().hooks.hookOnce('page:start', () => {
          if (this.isPlaying) {
            this.fade({
              start: this.volume,
              end: 0,
              duration: this.options.fadeOutDuration,
            });
          }
        });
      }
    } catch (e) {
      useLog(logGroup).warn('audio init error.', e);
      this.release();
    }
  }

  /**
   * fade再生
   * @param params
   */
  public fade (params: SoundFadeParam) {
    this.init();
    audioManager.setTagAudio(this);
    if (this.isLoading) {
      this.loadOnPlay = 'fade';
      this.loadOnPlayFadeParam = params;
      this.loadOnPlayTimer = Date.now();
      return;
    }
    this.sound?.fade(params);
  }

  /**
   * 音声再生
   */
  public play () {
    this.init();
    audioManager.setTagAudio(this);
    if (this.isLoading) {
      this.loadOnPlay = 'play';
      this.loadOnPlayTimer = Date.now();
      return;
    }
    this.sound?.off('end', this.onSoundEndCallback);
    this.sound?.once('end', this.onSoundEndCallback);
    this.sound?.play();
  }

  public stop () {
    this.loadOnPlay = undefined;
    this.sound?.off('end', this.onSoundEndCallback);
    this.sound?.stop();
  }

  /**
   * 音声停止
   */
  public pause () {
    this.loadOnPlay = undefined;
    this.sound?.off('end', this.onSoundEndCallback);
    this.sound?.pause();
  }

  /**
   * Audioの解放
   */
  public release () {
    if (this.sound) {
      this.stop();
      this.sound.removeAllEvents();
      this.sound.disconnect();
      this.sound = null;
    }
    this.removeAllEvents();
  }
}

/**
 * 再生中のaudio全体を管理する
 */
class AudioManager {
  protected audioTagPool: { [key: string]: Audio };

  constructor () {
    this.audioTagPool = {};
  }

  /**
   * 全体のボリューム設定
   * @param v
   */
  public setVolume (v: number) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line import/namespace,no-import-assign
    // Pizzicato.volume = v;
    SoundManager.volume = v;
  }

  /**
   * 種別ごとのボリュームを設定
   * @param {AudioType} type
   */
  public updateAudioTypeVolume (type: AudioType) {
    SoundManager.setGroupGainNodeVolume(type, useAudioTypeVolume(type));
  }

  protected trashingAudioIds: number[] = [];
  /**
   * タグAudioの設定
   * @param audio
   */
  public setTagAudio (audio: Audio) {
    if (this.trashingAudioIds.includes(audio.getId())) {
      return;
    }
    const tag = audio.getTag();
    if (tag) {
      const old = this.audioTagPool[tag];
      if (old) {
        if (audio.getId() === old.getId()) {
          return;
        }
        // 削除用としてマーク
        this.trashingAudioIds.push(old.getId());
        if (old.isPlaying) {
          old.fade({
            start: old.getVolume(),
            end: 0,
            duration: defaultTagDuration,
          });
        } else {
          old.stop();
        }
        // 削除用のマークを外す
        this.trashingAudioIds = this.trashingAudioIds.filter(v => v !== old.getId());
      }
      this.audioTagPool[tag] = audio;
    }
  }

  /**
   * Audioの検索
   */
  public findTagAudio (tag: string) {
    return this.audioTagPool[tag];
  }
}
const audioManager = new AudioManager();

export const useAudioManager = () => {
  return audioManager;
};

/**
 * audioを取得する(mp3やwavなどの通常音声)
 * @param {AudioType} type
 * @param {string[]} audios
 * @param {Partial<AudioOption>} options
 * @return {Audio}
 */
export const useAudio = (type: AudioType, audios: string[], options: Partial<AudioOption> = {}) => {
  if (process.server) {
    return null;
  }
  if (audios.length <= 0) {
    return null;
  }
  // グループごとの音量調整
  audioManager.updateAudioTypeVolume(type);
  // Howler.volume(audioVolume);
  // const sound = new Howl({
  //   src: audios,
  //   html5: false,
  //   // autoplay: options?.autoplay ?? false,
  //   // volume: options?.volume ?? 1,
  //   // loop: options?.loop ?? false,
  //   // // Preloadは自動的にHTML5 Audioになるようなので設定させない
  //   // // preload: options?.preload !== undefined ? options?.preload : false,
  //   // onplay: (soundId: number) => {
  //   //   // useLog(logGroup).debug(`onplay ${soundId}`);
  //   //   audioManager.add(sound, soundId);
  //   // },
  //   // onstop: (soundId: number) => {
  //   //   // useLog(logGroup).debug(`onstop ${soundId}`);
  //   //   audioManager.remove(sound, soundId);
  //   // },
  //   // onend: (soundId: number) => {
  //   //   // useLog(logGroup).debug(`onend ${soundId}`);
  //   //   audioManager.remove(sound, soundId);
  //   // },
  //   // // モバイルブラウザ/Chrome/Safariでは自動再生が制限されている為、unlockイベントの発生を待って再生させる
  //   // onplayerror: () => {
  //   //   sound?.once('unlock', () => {
  //   //     if (options?.autoplay && sound && !sound.playing()) {
  //   //       sound.play();
  //   //     }
  //   //   });
  //   // },
  // });
  return new Audio(type, audios, options);
};

// /**
//  * HLSの再生
//  */
// export class HlsAudio implements AudioInterface {
//   /**
//    * 再生リスト
//    * @protected
//    */
//   protected playListUrl: string;
//   protected audio?: HTMLAudioElement;
//
//   constructor (playListUrl: string) {
//     this.playListUrl = playListUrl;
//     this.audio = undefined;
//   }
//
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   static Hls: any;
//
//   /**
//    * HLSオブジェクトの取得
//    * @note hls.jsがES moduleに対応していないため動的インポートをおこなう
//    * @protected
//    */
//   protected static async getHls () {
//     if (!HlsAudio.Hls) {
//       HlsAudio.Hls = await import('hls.js').then(lib => lib.default || lib);
//     }
//     return HlsAudio.Hls;
//   }
//
//   /**
//    * 音声を再生する
//    */
//   public async play () {
//     if (this.audio) {
//       this.audio.pause();
//       this.audio = undefined;
//     }
//     const Hls = await HlsAudio.getHls();
//     const freeAudio = HlsAudio.getFreeAudioElement();
//     if (!freeAudio) {
//       useLog(logGroup).warn('not found audio element.');
//       return;
//     }
//     if (Hls.isSupported()) {
//       this.audio = freeAudio;
//       const hls = new Hls();
//       hls.loadSource(this.playListUrl);
//       hls.attachMedia(this.audio);
//       this.audio.play().catch((e) => {
//         useLog(logGroup).error(e);
//       });
//     } else if (freeAudio.canPlayType('application/vnd.apple.mpegurl')) {
//       this.audio = freeAudio;
//       this.audio.src = this.playListUrl;
//       this.audio.play().catch((e) => {
//         useLog(logGroup).error(e);
//       });
//     } else {
//       useLog(logGroup).warn('not support stream audio.');
//     }
//   }
//
//   /**
//    * イベント
//    */
//   public on (_event: AudioOnEvent, _func: AudioOnCallback) {
//     // TODO
//   }
//
//   /**
//    * 停止処理
//    */
//   public stop () {
//     if (!this.audio) {
//       return;
//     }
//     this.audio.pause();
//     this.audio = undefined;
//   }
//
//   /**
//    * audio全体の管理
//    */
//   static audioList: HTMLAudioElement[] = [];
//
//   /**
//    * audioの追加
//    */
//   static addAudioElement () {
//     const audio = document.createElement('audio');
//     const elm = document.body.appendChild(audio);
//     this.audioList.push(elm);
//     return elm;
//   }
//
//   /**
//    * 空いているaudioの取得
//    */
//   static getFreeAudioElement () {
//     for (const elm of this.audioList) {
//       if (elm && elm.paused) {
//         return elm;
//       }
//     }
//     return this.addAudioElement();
//   }
//
//   /**
//    * audioの全削除
//    */
//   static removeAllAudioElement () {
//     for (const elm of this.audioList) {
//       document.body.removeChild(elm);
//     }
//     this.audioList = [];
//   }
// }
//
// /**
//  * HLS再生を取得する
//  * @param {string} audio
//  * @return {HlsAudio}
//  */
// export const useHlsAudio = (audio: string) => {
//   if (process.server) {
//     return null;
//   }
//   return new HlsAudio(audio);
// };

/**
 * SEを取得する
 * @param {string[]} audios
 * @param {Partial<AudioOption>} options
 * @return {AudioInterface}
 */
export const useSeAudio = (audios: string[], options: Partial<AudioOption> = {}) => {
  return useAudio('se', audios, {
    gainVolume: audioConfig.volumes.se,
    ...options,
  });
};

/**
 * SEを取得する
 * @param {number} partition
 * @param {string} cd
 * @param {Partial<AudioOption>} options
 * @return {AudioInterface}
 */
export const useSe = (partition: number, cd: string, options: Partial<AudioOption> = {}) => {
  return useSeAudio(useSeFiles(partition, cd), options);
};

/**
 * BGMを取得する
 * @param {string[]} audios
 * @param {Partial<AudioOption>} options
 * @return {AudioInterface}
 */
export const useBgmAudio = (audios: string[], options: Partial<AudioOption> = {}) => {
  return useAudio('bgm', audios, {
    tag: 'bgm',
    loop: true,
    loadOnPlayLimitTime: 0,
    gainVolume: audioConfig.volumes.bgm,
    ...options,
  });
};

/**
 * BGMを取得する
 * @param {number} partition
 * @param {string} cd
 * @param {Partial<AudioOption>} options
 * @return {AudioInterface}
 */
export const useBgm = (partition: number, cd: string, options: Partial<AudioOption> = {}) => {
  return useBgmAudio(useBgmFiles(partition, cd), options);
};

/**
 * 現在再生中のBGMを取得する
 * @return {AudioInterface}
 */
export const useCurrentBgm = () => {
  return audioManager.findTagAudio('bgm') ?? null;
};

/**
 * Voiceを取得する
 * @param {string[]} audios
 * @param {Partial<AudioOption>} options
 * @return {AudioInterface}
 */
export const useVoiceAudio = (audios: string[], options: Partial<AudioOption> = {}) => {
  return useAudio('voice', audios, {
    fadeOut: true,
    gainVolume: audioConfig.volumes.voice,
    ...options,
  });
  // const enableHash = useRuntimeConfig().public.resourceEnableHash;
  // // ハッシュしていない場合はローカル環境とみなして、元ファイルを再生させる
  // if (!enableHash) {
  //   return useAudio(audios);
  // }
  // if (audios.length <= 0) {
  //   return;
  // }
  // // ストリーム再生
  // return useHlsAudio(audios[0]);
};

/**
 * Voiceを取得する
 * @param {string} asset
 * @param {number} partition
 * @param {string} cd
 * @param options
 * @return {AudioInterface}
 */
export const useVoice = (asset: string, partition: number, cd: string, options: Partial<AudioOption> = {}) => {
  return useVoiceAudio(useVoiceFiles(asset, partition, cd), options);
};

// /**
//  * 全音声の停止
//  * @note iOSでbackgroundに行くと何故か停止できなくなるため全体で停止する用
// */
// export const useAudioAllStop = (fade = false, duration = 500) => {
//   audioManager.stopAll(fade, duration);
// };

/**
 * URLから対応拡張子の音声ファイルのURLを取得
 * @param {string} url ベースとなるURL
 * @return string[] 対応している拡張子のファイルURL配列
 */
export const useAudioFiles = (url: string): string[] => {
  const p = parsePath(url);
  const enableHash = useRuntimeConfig().public.resourceEnableHash;
  if (enableHash) {
    return audioConfig.sourceExtensions.map((e) => {
      return `${p.dir}/${p.name}.${e}`;
    });
  }
  return [`${p.dir}/${p.name}.${defaultExtension}`];
};

/**
 * SE用のURLを取得
 * @param {number} partition
 * @param {string} cd
 * @return string[] 対応している拡張子のファイルURL配列
 */
export const useSeFiles = (partition: number, cd: string): string[] => {
  const { $resourceSeUrl } = useNuxtApp();
  return useAudioFiles($resourceSeUrl(partition, cd, defaultExtension));
};

/**
 * BGM用のURLを取得
 * @param {number} partition
 * @param {string} cd
 * @return string[] 対応している拡張子のファイルURL配列
 */
export const useBgmFiles = (partition: number, cd: string): string[] => {
  const { $resourceBgmUrl } = useNuxtApp();
  return useAudioFiles($resourceBgmUrl(partition, cd, defaultExtension));
};

/**
 * Voice用のURLを取得
 * @param {string} asset
 * @param {number} partition
 * @param {string} cd
 * @return string[] 対応しているファイルURL
 */
export const useVoiceFiles = (asset: string, partition: number, cd: string): string[] => {
  const { $resourceVoiceUrl } = useNuxtApp();
  return useAudioFiles($resourceVoiceUrl(asset, partition, cd, defaultExtension));
  // const { $resourceVoiceUrl } = useNuxtApp();
  // const enableHash = useRuntimeConfig().public.resourceEnableHash;
  // let extension = defaultExtension;
  // if (enableHash) {
  //   extension = 'm3u8';
  // }
  // return [$resourceVoiceUrl(partition, cd, extension)];
};

// /**
//  * 全体ボリュームの更新
//  */
// let updateAudioVolumeSyncTimer: number|NodeJS.Timer = 0;
// const updateAudioVolume = () => {
//   // 過度に呼ばれるとフリーズするため
//   if (updateAudioVolumeSyncTimer) {
//     return;
//   }
//   updateAudioVolumeSyncTimer = setTimeout(() => {
//     audioManager.updateAudioVolume();
//     updateAudioVolumeSyncTimer = 0;
//   }, 100);
// };

/**
 * 音声全体のボリューム
 */
export const useAudioVolume = () => {
  return useState<number>('audioVolume', () => 1);
};

const defaultVolume = 0.5;
/**
 * SEのボリューム
 */
export const useSeVolume = (value?: number) => {
  const { $storage } = useNuxtApp();
  if (value !== undefined) {
    $storage.save('seVolume', value);
  }
  return $storage.getNumber('seVolume', defaultVolume);
};

/**
 * Voiceのボリューム
 */
export const useVoiceVolume = (value?: number) => {
  const { $storage } = useNuxtApp();
  if (value !== undefined) {
    $storage.save('voiceVolume', value);
  }
  return $storage.getNumber('voiceVolume', defaultVolume);
};

/**
 * BGMのボリューム
 */
export const useBgmVolume = (value?: number) => {
  const { $storage } = useNuxtApp();
  if (value !== undefined) {
    $storage.save('bgmVolume', value);
  }
  return $storage.getNumber('bgmVolume', defaultVolume);
};

/**
 * 種別ごとのボリュームを取得
 * @param {AudioType} type
 */
const useAudioTypeVolume = (type: AudioType) => {
  if (type === 'se') {
    return useSeVolume();
  } else if (type === 'bgm') {
    return useBgmVolume();
  } else if (type === 'voice') {
    return useVoiceVolume();
  } else {
    throw new Error(`invalid ${type}`);
  }
};
