/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventBonusPresent } from './EventBonusPresent';
import {
    EventBonusPresentFromJSON,
    EventBonusPresentFromJSONTyped,
    EventBonusPresentToJSON,
} from './EventBonusPresent';
import type { EventBonusSpecialStory } from './EventBonusSpecialStory';
import {
    EventBonusSpecialStoryFromJSON,
    EventBonusSpecialStoryFromJSONTyped,
    EventBonusSpecialStoryToJSON,
} from './EventBonusSpecialStory';

/**
 * 
 * @export
 * @interface EventBonus
 */
export interface EventBonus {
    /**
     * イベントボーナスコード
     * @type {string}
     * @memberof EventBonus
     */
    eventBonusCd: string;
    /**
     * 特典名
     * @type {string}
     * @memberof EventBonus
     */
    bonusName: string;
    /**
     * 訴求画像の表示有無
     * @type {boolean}
     * @memberof EventBonus
     */
    imgDispFlg: boolean;
    /**
     * 特典タイプ
     * @type {string}
     * @memberof EventBonus
     */
    bonusType: string;
    /**
     * ラブ度特典獲得に必要なラブ度
     * @type {number}
     * @memberof EventBonus
     */
    eventPoint: number;
    /**
     * 特典文言
     * @type {string}
     * @memberof EventBonus
     */
    description: string;
    /**
     * 表示順
     * @type {number}
     * @memberof EventBonus
     */
    orderNo: number;
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof EventBonus
     */
    eventCharaCd: string;
    /**
     * プレゼントリスト
     * @type {Array<EventBonusPresent>}
     * @memberof EventBonus
     */
    presentList: Array<EventBonusPresent>;
    /**
     * 特別ストーリーリスト
     * @type {Array<EventBonusSpecialStory>}
     * @memberof EventBonus
     */
    specialStoryList: Array<EventBonusSpecialStory>;
    /**
     * ランキング順位の開始範囲
     * @type {number}
     * @memberof EventBonus
     */
    from?: number;
    /**
     * ランキング順位の終了範囲
     * @type {number}
     * @memberof EventBonus
     */
    to?: number;
}

/**
 * Check if a given object implements the EventBonus interface.
 */
export function instanceOfEventBonus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventBonusCd" in value;
    isInstance = isInstance && "bonusName" in value;
    isInstance = isInstance && "imgDispFlg" in value;
    isInstance = isInstance && "bonusType" in value;
    isInstance = isInstance && "eventPoint" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "presentList" in value;
    isInstance = isInstance && "specialStoryList" in value;

    return isInstance;
}

export function EventBonusFromJSON(json: any): EventBonus {
    return EventBonusFromJSONTyped(json, false);
}

export function EventBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventBonusCd': json['event_bonus_cd'],
        'bonusName': json['bonus_name'],
        'imgDispFlg': json['img_disp_flg'],
        'bonusType': json['bonus_type'],
        'eventPoint': json['event_point'],
        'description': json['description'],
        'orderNo': json['order_no'],
        'eventCharaCd': json['event_chara_cd'],
        'presentList': ((json['present_list'] as Array<any>).map(EventBonusPresentFromJSON)),
        'specialStoryList': ((json['special_story_list'] as Array<any>).map(EventBonusSpecialStoryFromJSON)),
        'from': !exists(json, 'from') ? undefined : json['from'],
        'to': !exists(json, 'to') ? undefined : json['to'],
    };
}

export function EventBonusToJSON(value?: EventBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_bonus_cd': value.eventBonusCd,
        'bonus_name': value.bonusName,
        'img_disp_flg': value.imgDispFlg,
        'bonus_type': value.bonusType,
        'event_point': value.eventPoint,
        'description': value.description,
        'order_no': value.orderNo,
        'event_chara_cd': value.eventCharaCd,
        'present_list': ((value.presentList as Array<any>).map(EventBonusPresentToJSON)),
        'special_story_list': ((value.specialStoryList as Array<any>).map(EventBonusSpecialStoryToJSON)),
        'from': value.from,
        'to': value.to,
    };
}

