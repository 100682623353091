/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MissionCompleteAvatar
 */
export interface MissionCompleteAvatar {
    /**
     * プレミアクリアで獲得したストーリーのチケットコード
     * @type {string}
     * @memberof MissionCompleteAvatar
     */
    bonusStoryTicketCd: string | null;
    /**
     * プレミアクリアで獲得したストーリーのチャプターコード
     * @type {boolean}
     * @memberof MissionCompleteAvatar
     */
    bonusStoryChapterCd: boolean | null;
    /**
     * アバターミッションクリアタイプ avanml, avaprm
     * @type {string}
     * @memberof MissionCompleteAvatar
     */
    avatarMissionClearType: string | null;
}

/**
 * Check if a given object implements the MissionCompleteAvatar interface.
 */
export function instanceOfMissionCompleteAvatar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bonusStoryTicketCd" in value;
    isInstance = isInstance && "bonusStoryChapterCd" in value;
    isInstance = isInstance && "avatarMissionClearType" in value;

    return isInstance;
}

export function MissionCompleteAvatarFromJSON(json: any): MissionCompleteAvatar {
    return MissionCompleteAvatarFromJSONTyped(json, false);
}

export function MissionCompleteAvatarFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionCompleteAvatar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bonusStoryTicketCd': json['bonus_story_ticket_cd'],
        'bonusStoryChapterCd': json['bonus_story_chapter_cd'],
        'avatarMissionClearType': json['avatar_mission_clear_type'],
    };
}

export function MissionCompleteAvatarToJSON(value?: MissionCompleteAvatar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bonus_story_ticket_cd': value.bonusStoryTicketCd,
        'bonus_story_chapter_cd': value.bonusStoryChapterCd,
        'avatar_mission_clear_type': value.avatarMissionClearType,
    };
}

