import { versionCompare } from '~/libs/platform';

const versionFileUrl = '/version.json';
// バージョンチェックの期間(ms/毎回チェックを挟むと無駄な通信が走るため、キャッシュさせる期間はチェックしない)
const versionCheckPeriod = 1000 * 60 * 10;
// 削除するキャッシュ
const cacheKey = 'assets';
/**
 * バージョン情報
 */
interface VersionData {
  version: string,
}

let oldVersionCheckTime = 0;
/**
 * アップデートが必要か
 */
const checkUpdate = async () => {
  // 期間内ならバージョンチェックさせない
  const now = Date.now();
  if (oldVersionCheckTime && (now - oldVersionCheckTime) <= versionCheckPeriod) {
    return false;
  }
  // キャッシュの削除
  if ('caches' in window) {
    if (await caches.has(cacheKey)) {
      const c = await caches.open(cacheKey);
      await c.delete(versionFileUrl, {
        ignoreVary: true,
        ignoreSearch: true,
      });
    }
  }
  // 現在のバージョン
  const appVersion = useRuntimeConfig().public.appVersion;
  // バージョンファイルの取得
  const { data, error } = await useFetch<VersionData>(versionFileUrl, {
    parseResponse: JSON.parse,
  });
  if (error.value || !data.value) {
    // アップデートの更新は特に強制力がないため(アプリ再起動で同じことができるので)、ここではスルーする
    useLog('update').warn('update version check failed.', error.value);
    return false;
  }
  // 現在のバージョンと比較
  const requiredVersion = data.value.version;
  const isUpdate = (appVersion && requiredVersion && versionCompare(appVersion, requiredVersion) < 0);
  useLog('update').debug('check version', isUpdate, appVersion, requiredVersion);
  // チェック時刻を保存
  oldVersionCheckTime = now;
  return isUpdate;
};

/**
 * FRONTのリロード強制対応
 */
export default defineNuxtRouteMiddleware(async (to) => {
  // 更新が必要な場合はアップデートページへ
  if (to.name !== 'update' && (await checkUpdate())) {
    return navigateTo('/update', { replace: true, });
  }
});
