// import { getAnimateOAuthUrl } from '~/libs/platform';
//
// /**
//  * 別windowからのメッセージフォーマット
//  */
// interface ReceiveMessageData {
//   method: string,
//   data: {
//     provider_id: string,
//     token?: string,
//     error_code?: string,
//     error_message?: string,
//   },
// }
//
// /**
//  * 子フレームからのメッセージ
//  */
// const executeMessage = (_: ReceiveMessageData) => {
//   NOTE: モバイルブラウザ版だと通信が失敗する挙動があるため、特に何もしない
//   if (data.method === 'signIn') {
//     // カスタムトークンでログイン
//     useAuth().signInReceiveMessageToken(
//       data.data.provider_id,
//       data.data.token,
//       data.data.error_code,
//       data.data.error_message
//     ).catch((e) => {
//       useReportAppError(e);
//     });
//   }
// };
//
// /**
//  * フォーカスイベントを追加
//  * @param callback
//  */
// const onWindowFocus = (callback: () => void) => {
//   if ('visibilityState' in document) {
//     document.addEventListener('visibilitychange', () => {
//       if (document.visibilityState === 'visible') {
//         callback();
//       }
//     }, false);
//   } else if ('onfocus' in window) {
//     window.addEventListener('focus', () => {
//       callback();
//     }, false);
//   } else {
//     useLog('message').warn('not support message received in focus window.');
//   }
// };
//
// /**
//  * フォーカスされているか
//  */
// const isWindowFocus = () => {
//   // if ('visibilityState' in document) {
//   //   return document.visibilityState === 'visible';
//   // }
//   return document.hasFocus();
// };
//
// // 受け取ったメッセージ一覧
// const messageQueues: ReceiveMessageData[] = [];
/**
 * postMessageのデータ受け取り
 * TODO: モバイルブラウザでNetwork Errorが頻発するため、不要そうなら消す
 */
export default defineNuxtPlugin(() => {
  // // iframeや別ウィンドウからのメッセージを受け取る
  // if (!window) {
  //   return;
  // }
  // // メッセージ受信
  // window.addEventListener('message', (event: MessageEvent) => {
  //   // 認証されたドメイン以外は拒否する
  //   const url = new URL(getAnimateOAuthUrl());
  //   if (event.origin !== url.origin) {
  //     return;
  //   }
  //   const data: ReceiveMessageData = event.data;
  //   useLog('message').debug(`received message ${data}`);
  //   if (data) {
  //     if (isWindowFocus()) {
  //       executeMessage(data);
  //     } else {
  //       // メッセージqueueにためる
  //       messageQueues.push(data);
  //     }
  //   }
  // });
  // // フォーカス時に全てのqueueを実行
  // onWindowFocus(() => {
  //   useLog('message').debug(`received execute message ${messageQueues.length}`);
  //   while (messageQueues.length > 0) {
  //     const data = messageQueues.shift();
  //     if (data) {
  //       executeMessage(data);
  //     }
  //   }
  // });
});
