/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserCard } from './UserCard';
import {
    UserCardFromJSON,
    UserCardFromJSONTyped,
    UserCardToJSON,
} from './UserCard';

/**
 * 
 * @export
 * @interface CardListResponse
 */
export interface CardListResponse {
    /**
     * 
     * @type {Array<UserCard>}
     * @memberof CardListResponse
     */
    data: Array<UserCard>;
}

/**
 * Check if a given object implements the CardListResponse interface.
 */
export function instanceOfCardListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function CardListResponseFromJSON(json: any): CardListResponse {
    return CardListResponseFromJSONTyped(json, false);
}

export function CardListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserCardFromJSON)),
    };
}

export function CardListResponseToJSON(value?: CardListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserCardToJSON)),
    };
}

