/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventMinigameExecuteRequest
 */
export interface UserEventMinigameExecuteRequest {
    /**
     * デッキ番号
     * @type {number}
     * @memberof UserEventMinigameExecuteRequest
     */
    deckNo?: number;
    /**
     * まとめて実行フラグ
     * @type {number}
     * @memberof UserEventMinigameExecuteRequest
     */
    summaryFlg?: number;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserEventMinigameExecuteRequest
     */
    playerCode?: string;
}

/**
 * Check if a given object implements the UserEventMinigameExecuteRequest interface.
 */
export function instanceOfUserEventMinigameExecuteRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserEventMinigameExecuteRequestFromJSON(json: any): UserEventMinigameExecuteRequest {
    return UserEventMinigameExecuteRequestFromJSONTyped(json, false);
}

export function UserEventMinigameExecuteRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventMinigameExecuteRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deckNo': !exists(json, 'deck_no') ? undefined : json['deck_no'],
        'summaryFlg': !exists(json, 'summary_flg') ? undefined : json['summary_flg'],
        'playerCode': !exists(json, 'player_code') ? undefined : json['player_code'],
    };
}

export function UserEventMinigameExecuteRequestToJSON(value?: UserEventMinigameExecuteRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deck_no': value.deckNo,
        'summary_flg': value.summaryFlg,
        'player_code': value.playerCode,
    };
}

