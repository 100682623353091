/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserShopItem
 */
export interface UserShopItem {
    /**
     * shop_item_cd
     * @type {string}
     * @memberof UserShopItem
     */
    shopItemCd: string;
    /**
     * ユーザーごとの有効期限開始日時(制限がなければnull) 主に新人イベントアイテム
     * @type {string}
     * @memberof UserShopItem
     */
    useStartedAt: string | null;
    /**
     * ユーザーごとの有効期限終了日時(制限がなければnull) 主に新人イベントアイテム
     * @type {string}
     * @memberof UserShopItem
     */
    useEndedAt: string | null;
}

/**
 * Check if a given object implements the UserShopItem interface.
 */
export function instanceOfUserShopItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shopItemCd" in value;
    isInstance = isInstance && "useStartedAt" in value;
    isInstance = isInstance && "useEndedAt" in value;

    return isInstance;
}

export function UserShopItemFromJSON(json: any): UserShopItem {
    return UserShopItemFromJSONTyped(json, false);
}

export function UserShopItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserShopItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopItemCd': json['shop_item_cd'],
        'useStartedAt': json['use_started_at'],
        'useEndedAt': json['use_ended_at'],
    };
}

export function UserShopItemToJSON(value?: UserShopItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop_item_cd': value.shopItemCd,
        'use_started_at': value.useStartedAt,
        'use_ended_at': value.useEndedAt,
    };
}

