import { ResponseError } from 'api';
import { FirebaseError } from '@firebase/app';
import { AppError, NetworkError } from '~/libs/errors';

export const errorConfig = {
  /**
   * 最大リトライ回数
   */
  maxRetryCount: 3,
  /**
   * ハンドリングするエラー(これ以外はコンソール上のみ)
   */
  handleErrors: [
    NetworkError,
    AppError,
    ResponseError,
    FirebaseError,
  ],
  /**
   * クライアントで利用するエラーメッセージ
   */
  messages: {
    notFound: 'ページが見つかりません',
    tooManyRequests: 'リクエストが多すぎます',
    serverError: '通信エラー',
    unauthorized: '認証エラー',
    invalidError: '不明なエラーです',
    ban: 'このアカウントはBANされています',
    notCanBuyShopItemError: '販売期間が終了したため購入できませんでした',
    userItemCountMaxError: '所持数が上限に達しているため購入できません',
  },
  /**
   * 特別な処理をするエラーコード
   * NOTE: APIの config/original/error にて全てのエラーが定義(ここでは専用処理を行う場合に羅列する)
   */
  errorCodes: {
    // フレンドがいなかった
    friendKeyError: 311,
    // フレンドのいいね回数が上限だった
    friendLikeLimitError: 325,
    // フレンドが相互フォローじゃなかった
    notMutualFollowError: 328,
    // アカウントBAN
    userBannedError: 804,
    // IDトークンの更新
    forcesFirebaseIdToken: 2000,
    // トークンの有効期限切れ
    expiredIdToken: 2001,
    // アニメイト認証必須/アニメイトAPIトークンエラー
    animateInvalidAccessToken: 2005,
    // 存在しないプレイヤーコード指定
    playerCodeError: 115,
    // ガチャ有効期限切れ
    gachaDatePeriodError: 914,
    // アバター保存枠の上限を超える
    overMaxCapacityError: 1020,
    // クローゼットを受け取った際、所持枠が増加しなかった
    notUseItemError: 1204,
    // プレゼント受け取り期限切れ
    acceptLimitError: 1303,
    // プレゼント受け取り上限
    userItemCountMaxError: 1304,
    // 販売期間外のアイテム
    notCanBuyShopItemError: 1401,
    // ゴールド or ダイヤが足りない
    notGoldOrPointError: 1402,
    // イベント終了
    notPeriodScenarioEventError: 3001,
    // パネルミッション終了
    notPeriodPanelmissionError: 1906
  },
};
