/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserGachaLineupRarityProbabilityListInner
 */
export interface UserGachaLineupRarityProbabilityListInner {
    /**
     * 星◯
     * @type {number}
     * @memberof UserGachaLineupRarityProbabilityListInner
     */
    itemRarity: number;
    /**
     * 確率(%)
     * @type {number}
     * @memberof UserGachaLineupRarityProbabilityListInner
     */
    probability: number;
}

/**
 * Check if a given object implements the UserGachaLineupRarityProbabilityListInner interface.
 */
export function instanceOfUserGachaLineupRarityProbabilityListInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemRarity" in value;
    isInstance = isInstance && "probability" in value;

    return isInstance;
}

export function UserGachaLineupRarityProbabilityListInnerFromJSON(json: any): UserGachaLineupRarityProbabilityListInner {
    return UserGachaLineupRarityProbabilityListInnerFromJSONTyped(json, false);
}

export function UserGachaLineupRarityProbabilityListInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaLineupRarityProbabilityListInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemRarity': json['item_rarity'],
        'probability': json['probability'],
    };
}

export function UserGachaLineupRarityProbabilityListInnerToJSON(value?: UserGachaLineupRarityProbabilityListInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_rarity': value.itemRarity,
        'probability': value.probability,
    };
}

