/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPrivateNews
 */
export interface UserPrivateNews {
    /**
     * 重要なお知らせCODE
     * @type {string}
     * @memberof UserPrivateNews
     */
    privateNewsCd: string;
    /**
     * お知らせ見出し
     * @type {string}
     * @memberof UserPrivateNews
     */
    title: string;
    /**
     * お知らせ本文
     * @type {string}
     * @memberof UserPrivateNews
     */
    text: string;
    /**
     * 既読フラグ
     * @type {boolean}
     * @memberof UserPrivateNews
     */
    readFlg: boolean;
    /**
     * 表示開始日時
     * @type {string}
     * @memberof UserPrivateNews
     */
    startedAt: string | null;
}

/**
 * Check if a given object implements the UserPrivateNews interface.
 */
export function instanceOfUserPrivateNews(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "privateNewsCd" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "readFlg" in value;
    isInstance = isInstance && "startedAt" in value;

    return isInstance;
}

export function UserPrivateNewsFromJSON(json: any): UserPrivateNews {
    return UserPrivateNewsFromJSONTyped(json, false);
}

export function UserPrivateNewsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPrivateNews {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'privateNewsCd': json['private_news_cd'],
        'title': json['title'],
        'text': json['text'],
        'readFlg': json['read_flg'],
        'startedAt': json['started_at'],
    };
}

export function UserPrivateNewsToJSON(value?: UserPrivateNews | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'private_news_cd': value.privateNewsCd,
        'title': value.title,
        'text': value.text,
        'read_flg': value.readFlg,
        'started_at': value.startedAt,
    };
}

