/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AvatarListResponseData } from './AvatarListResponseData';
import {
    AvatarListResponseDataFromJSON,
    AvatarListResponseDataFromJSONTyped,
    AvatarListResponseDataToJSON,
} from './AvatarListResponseData';

/**
 * 
 * @export
 * @interface AvatarListResponse
 */
export interface AvatarListResponse {
    /**
     * 
     * @type {AvatarListResponseData}
     * @memberof AvatarListResponse
     */
    data: AvatarListResponseData;
}

/**
 * Check if a given object implements the AvatarListResponse interface.
 */
export function instanceOfAvatarListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function AvatarListResponseFromJSON(json: any): AvatarListResponse {
    return AvatarListResponseFromJSONTyped(json, false);
}

export function AvatarListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvatarListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': AvatarListResponseDataFromJSON(json['data']),
    };
}

export function AvatarListResponseToJSON(value?: AvatarListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AvatarListResponseDataToJSON(value.data),
    };
}

