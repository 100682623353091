/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AvatarMstParts } from './AvatarMstParts';
import {
    AvatarMstPartsFromJSON,
    AvatarMstPartsFromJSONTyped,
    AvatarMstPartsToJSON,
} from './AvatarMstParts';

/**
 * 
 * @export
 * @interface Avatar
 */
export interface Avatar {
    /**
     * アバターコード
     * @type {string}
     * @memberof Avatar
     */
    avatarCd: string;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof Avatar
     */
    resourcePartition: number;
    /**
     * 獲得場所
     * @type {string}
     * @memberof Avatar
     */
    getSituation: string;
    /**
     * 初期アバターかどうか
     * @type {boolean}
     * @memberof Avatar
     */
    initEquipFlg: boolean;
    /**
     * アバター名
     * @type {string}
     * @memberof Avatar
     */
    avatarName: string;
    /**
     * アイテムコード
     * @type {string}
     * @memberof Avatar
     */
    itemCd: string;
    /**
     * レアリティ
     * @type {number}
     * @memberof Avatar
     */
    rarity: number;
    /**
     * 魅力値
     * @type {number}
     * @memberof Avatar
     */
    charmValue: number;
    /**
     * キャラコード
     * @type {string}
     * @memberof Avatar
     */
    charaCd: string;
    /**
     * 所有数制限に含むかどうか
     * @type {boolean}
     * @memberof Avatar
     */
    notcountFlg: boolean;
    /**
     * アバターカテゴリコード
     * @type {string}
     * @memberof Avatar
     */
    avatarCategoryCd: string;
    /**
     * アバターカテゴリ
     * @type {string}
     * @memberof Avatar
     */
    avatarCategoryName: string;
    /**
     * アバタータイプ
     * @type {string}
     * @memberof Avatar
     */
    avatarType: string;
    /**
     * アバターパーツを構成するパーツ
     * @type {Array<AvatarMstParts>}
     * @memberof Avatar
     */
    parts: Array<AvatarMstParts>;
}

/**
 * Check if a given object implements the Avatar interface.
 */
export function instanceOfAvatar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatarCd" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "getSituation" in value;
    isInstance = isInstance && "initEquipFlg" in value;
    isInstance = isInstance && "avatarName" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "rarity" in value;
    isInstance = isInstance && "charmValue" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "notcountFlg" in value;
    isInstance = isInstance && "avatarCategoryCd" in value;
    isInstance = isInstance && "avatarCategoryName" in value;
    isInstance = isInstance && "avatarType" in value;
    isInstance = isInstance && "parts" in value;

    return isInstance;
}

export function AvatarFromJSON(json: any): Avatar {
    return AvatarFromJSONTyped(json, false);
}

export function AvatarFromJSONTyped(json: any, ignoreDiscriminator: boolean): Avatar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatarCd': json['avatar_cd'],
        'resourcePartition': json['resource_partition'],
        'getSituation': json['get_situation'],
        'initEquipFlg': json['init_equip_flg'],
        'avatarName': json['avatar_name'],
        'itemCd': json['item_cd'],
        'rarity': json['rarity'],
        'charmValue': json['charm_value'],
        'charaCd': json['chara_cd'],
        'notcountFlg': json['notcount_flg'],
        'avatarCategoryCd': json['avatar_category_cd'],
        'avatarCategoryName': json['avatar_category_name'],
        'avatarType': json['avatar_type'],
        'parts': ((json['parts'] as Array<any>).map(AvatarMstPartsFromJSON)),
    };
}

export function AvatarToJSON(value?: Avatar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_cd': value.avatarCd,
        'resource_partition': value.resourcePartition,
        'get_situation': value.getSituation,
        'init_equip_flg': value.initEquipFlg,
        'avatar_name': value.avatarName,
        'item_cd': value.itemCd,
        'rarity': value.rarity,
        'charm_value': value.charmValue,
        'chara_cd': value.charaCd,
        'notcount_flg': value.notcountFlg,
        'avatar_category_cd': value.avatarCategoryCd,
        'avatar_category_name': value.avatarCategoryName,
        'avatar_type': value.avatarType,
        'parts': ((value.parts as Array<any>).map(AvatarMstPartsToJSON)),
    };
}

