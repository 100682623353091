<template>
  <ModalAlert
    :show="props.show"
    :title="errorName()"
    :is-enable-cancel="true"
    ok-text="はい"
    cancel-text="タイトルへ"
    :z-index="props.zIndex"
    @click-ok="onClick"
    @click-cancel="onClickCancel"
  >
    <div v-if="props.error" class="text-center">
      <div v-if="isShowErrorMessage(props.error)">
        {{ props.error.message }}
      </div>
      <div v-else>
        <div>
          {{ errorName() }}が発生しました<br>
          通信状態をお確かめの上<br>
          再度お試しください
        </div>
      </div>
      <ModalErrorDebugErrorText :error="props.error" />
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
import { AppVisibleError, NetworkError } from '~/libs/errors';

const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});
interface Emits {
  (e: 'click'): void;
  (e: 'clickCancel'): void;
}
const emit = defineEmits<Emits>();
const onClick = () => {
  emit('click');
};
// タイトルへ
const onClickCancel = () => {
  emit('clickCancel');
};
const isNetworkError = () => {
  return props.error instanceof NetworkError;
};
const errorName = () => {
  return isNetworkError() ? '通信エラー' : 'エラー';
};
/**
 * エラーをそのままメッセージとして表示するか
 * @param error
 */
const isShowErrorMessage = (error: Error | null) => {
  if (!error) {
    return false;
  }
  return error instanceof AppVisibleError;
};
</script>
