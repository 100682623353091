/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GachaExecRequestBreakdown } from './GachaExecRequestBreakdown';
import {
    GachaExecRequestBreakdownFromJSON,
    GachaExecRequestBreakdownFromJSONTyped,
    GachaExecRequestBreakdownToJSON,
} from './GachaExecRequestBreakdown';

/**
 * 
 * @export
 * @interface GachaExecRequest
 */
export interface GachaExecRequest {
    /**
     * ガチャコード
     * @type {string}
     * @memberof GachaExecRequest
     */
    gachaCd: string;
    /**
     * ガチャ実行回数
     * @type {string}
     * @memberof GachaExecRequest
     */
    drawType: GachaExecRequestDrawTypeEnum;
    /**
     * 実行通過
     * @type {string}
     * @memberof GachaExecRequest
     */
    priceType: GachaExecRequestPriceTypeEnum;
    /**
     * 
     * @type {GachaExecRequestBreakdown}
     * @memberof GachaExecRequest
     */
    breakdown: GachaExecRequestBreakdown;
}


/**
 * @export
 */
export const GachaExecRequestDrawTypeEnum = {
    One: 'one',
    Ten: 'ten'
} as const;
export type GachaExecRequestDrawTypeEnum = typeof GachaExecRequestDrawTypeEnum[keyof typeof GachaExecRequestDrawTypeEnum];

/**
 * @export
 */
export const GachaExecRequestPriceTypeEnum = {
    Point: 'point',
    Gold: 'gold',
    Medal: 'medal',
    _4over: '4over',
    _5over: '5over',
    Discount: 'discount',
    Free: 'free'
} as const;
export type GachaExecRequestPriceTypeEnum = typeof GachaExecRequestPriceTypeEnum[keyof typeof GachaExecRequestPriceTypeEnum];


/**
 * Check if a given object implements the GachaExecRequest interface.
 */
export function instanceOfGachaExecRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gachaCd" in value;
    isInstance = isInstance && "drawType" in value;
    isInstance = isInstance && "priceType" in value;
    isInstance = isInstance && "breakdown" in value;

    return isInstance;
}

export function GachaExecRequestFromJSON(json: any): GachaExecRequest {
    return GachaExecRequestFromJSONTyped(json, false);
}

export function GachaExecRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GachaExecRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gachaCd': json['gacha_cd'],
        'drawType': json['draw_type'],
        'priceType': json['price_type'],
        'breakdown': GachaExecRequestBreakdownFromJSON(json['breakdown']),
    };
}

export function GachaExecRequestToJSON(value?: GachaExecRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gacha_cd': value.gachaCd,
        'draw_type': value.drawType,
        'price_type': value.priceType,
        'breakdown': GachaExecRequestBreakdownToJSON(value.breakdown),
    };
}

