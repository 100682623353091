/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendLikeReceive } from './UserFriendLikeReceive';
import {
    UserFriendLikeReceiveFromJSON,
    UserFriendLikeReceiveFromJSONTyped,
    UserFriendLikeReceiveToJSON,
} from './UserFriendLikeReceive';

/**
 * 
 * @export
 * @interface FriendLikeReceiveResponse
 */
export interface FriendLikeReceiveResponse {
    /**
     * 
     * @type {UserFriendLikeReceive}
     * @memberof FriendLikeReceiveResponse
     */
    data: UserFriendLikeReceive;
}

/**
 * Check if a given object implements the FriendLikeReceiveResponse interface.
 */
export function instanceOfFriendLikeReceiveResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function FriendLikeReceiveResponseFromJSON(json: any): FriendLikeReceiveResponse {
    return FriendLikeReceiveResponseFromJSONTyped(json, false);
}

export function FriendLikeReceiveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendLikeReceiveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserFriendLikeReceiveFromJSON(json['data']),
    };
}

export function FriendLikeReceiveResponseToJSON(value?: FriendLikeReceiveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserFriendLikeReceiveToJSON(value.data),
    };
}

