/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 消費コスト内訳
 * @export
 * @interface GachaExecRequestBreakdown
 */
export interface GachaExecRequestBreakdown {
    /**
     * 無償ダイヤ
     * @type {number}
     * @memberof GachaExecRequestBreakdown
     */
    freeGem: number;
    /**
     * 有償ダイヤ
     * @type {number}
     * @memberof GachaExecRequestBreakdown
     */
    purchasedGem: number;
    /**
     * ゲーム内通貨
     * @type {number}
     * @memberof GachaExecRequestBreakdown
     */
    coin: number;
    /**
     * ゲソコイン
     * @type {number}
     * @memberof GachaExecRequestBreakdown
     */
    gold: number;
}

/**
 * Check if a given object implements the GachaExecRequestBreakdown interface.
 */
export function instanceOfGachaExecRequestBreakdown(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "freeGem" in value;
    isInstance = isInstance && "purchasedGem" in value;
    isInstance = isInstance && "coin" in value;
    isInstance = isInstance && "gold" in value;

    return isInstance;
}

export function GachaExecRequestBreakdownFromJSON(json: any): GachaExecRequestBreakdown {
    return GachaExecRequestBreakdownFromJSONTyped(json, false);
}

export function GachaExecRequestBreakdownFromJSONTyped(json: any, ignoreDiscriminator: boolean): GachaExecRequestBreakdown {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'freeGem': json['freeGem'],
        'purchasedGem': json['purchasedGem'],
        'coin': json['coin'],
        'gold': json['gold'],
    };
}

export function GachaExecRequestBreakdownToJSON(value?: GachaExecRequestBreakdown | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'freeGem': value.freeGem,
        'purchasedGem': value.purchasedGem,
        'coin': value.coin,
        'gold': value.gold,
    };
}

