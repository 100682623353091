import { initializeApp } from 'firebase/app';
import { connectAuthEmulator } from 'firebase/auth';
import { defineNuxtPlugin, useRuntimeConfig } from '#app';
import { useLog } from '~/composables/useLog';
import { useAuth, getAuth } from '~/composables/useAuth';
import { useReportAppError } from '#imports';

const logGroup = 'Firebase Auth';
// トークンのチェックを1度のみ行う
let isCheckToken = false;
export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();
  const firebaseConfig = config.public.firebase;

  try {
    initializeApp(firebaseConfig.config);
    // エミュレータ起動
    if (firebaseConfig.useEmulator) {
      const auth = getAuth();
      connectAuthEmulator(auth, firebaseConfig.emulatorUrl, {
        disableWarnings: true,
      });
      useLog(logGroup).warn('Running in emulator mode. Do not use with production credentials.');
    }
    await useAuth().checkAuthState();

    // 起動時に自動ログイン処理
    nuxtApp.hook('page:finish', () => {
      if (isCheckToken) {
        return;
      }
      isCheckToken = true;
      // ブラウザ版アニメイトの認証時にリダイレクトされ、queryにてサインインする
      const token = useRoute().query.token;
      if (token) {
        const key = useLoading().loader.start();
        const provider = useRoute().query.provider;
        useAuth().signInCustomToken(provider ? String(provider) : '', String(token)).then(() => {
          useLoading().loader.stop(key);
        }).catch((e) => {
          useLoading().loader.stop(key);
          useReportAppError(e);
        });
      }
    });
  } catch (e) {
    throw createError({
      statusCode: 500,
      statusMessage: '認証初期化時にエラーが発生しました',
    });
    // useReportAppError(e);
  }
});
