/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * カードのフィルタータイプ
 * @export
 */
export const CardFilterType = {
    Nml: 'nml',
    Evt: 'evt'
} as const;
export type CardFilterType = typeof CardFilterType[keyof typeof CardFilterType];


export function CardFilterTypeFromJSON(json: any): CardFilterType {
    return CardFilterTypeFromJSONTyped(json, false);
}

export function CardFilterTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CardFilterType {
    return json as CardFilterType;
}

export function CardFilterTypeToJSON(value?: CardFilterType | null): any {
    return value as any;
}

