import type { StorageDefineConfig } from '~/libs/storage';

interface StorageConfig {
  // 保存するストレージ情報
  keys: StorageDefineConfig,
  // ハッシュ値更新時に削除するCacheのキー
  cache_keys: string[],
}

/**
 * ローカルストレージに保存するデータのキー
 */
export const storageKey = {
  showNewsDate: 'showNewsDate',
  autoOpenNews: 'autoOpenNews',
  showSaleDate: 'showSaleDate',
  showDiaDiscountDate: 'showDiaDiscountDate',
  deckSortType: 'deckSortType',
  cardMypageSortType: 'cardMypageSortType',
  rentalSortType: 'rentalSortType',
  eventFeverAppeal: 'eventFeverAppeal',
  showPresentStoryTicket: 'showPresentStoryTicket',
  showPresentStaminaItem: 'showPresentStaminaItem',
  showUseStaminaItem: 'showUseStaminaItem',
  showPerformanceMissionCds: 'showPerformanceMissionCds',
  appReviewRequest: 'appReviewRequest',
};

/**
 * ローカルストレージに保存するデータを定義
 */
export const storageConfig: StorageConfig = {
  keys: {
    // アプリのバージョン文字列
    appVersion: {
      type: 'string',
    },
    // アプリのビルド番号
    buildNo: {
      type: 'string',
    },
    // Android 通知の権限ダイアログを表示済みか
    showAndroidNotification: {
      type: 'boolean',
    },
    // iOS ATTモーダルを表示済みか
    showIOSATT: {
      type: 'boolean',
    },
    // 利用規約
    termsOfServiceBasicVersion: {
      type: 'string',
    },
    // 月額課金 利用規約
    termsOfServiceSubscriptionVersion: {
      type: 'string',
    },
    // 今日はお知らせ表示しない 最終日(YYYY-mm-dd)
    [storageKey.showNewsDate]: {
      type: 'string',
    },
    // 自動でお知らせモーダルが表示されたか
    [storageKey.autoOpenNews]: {
      type: 'boolean',
    },
    // セール訴求表示したか（1日1回）
    [storageKey.showSaleDate]: {
      type: 'string',
    },
    // ダイヤ増量キャンペーンモーダルを表示したか（1日1回）
    [storageKey.showDiaDiscountDate]: {
      type: 'string',
    },
    // 購入年齢確認(YYYY-MM)
    purchaseBirthDate: {
      type: 'string',
    },
    // 購入年齢確認(YYYY-MM)
    purchaseBirthDateConfirm: {
      type: 'boolean',
    },
    // 音量(BGM)
    bgmVolume: {
      type: 'number',
    },
    // 音量(SE)
    seVolume: {
      type: 'number',
    },
    // 音量(Voice)
    voiceVolume: {
      type: 'number',
    },
    // デッキ編成のソート順(src/libs/page/card/type.ts SortType)
    [storageKey.deckSortType]: {
      type: 'number',
    },
    // カードホーム設定のソート順(src/libs/page/card/type.ts SortType)
    [storageKey.cardMypageSortType]: {
      type: 'number',
    },
    // レンタルリストのソート順(src/libs/page/minigame/rental.ts SortType)
    [storageKey.rentalSortType]: {
      type: 'number',
    },
    // アニメイトプラットフォームID
    animatePlatformId: {
      type: 'number',
    },
    // イベントフィーバー請求情報(eventCd/feverCd/YYYY-MM-DD HH:ii:ss)
    eventFeverAppeal: {
      type: 'string',
    },
    // 今日は未受け取りストリーチケットがありますを表示しない 最終日(YYYY-mm-dd-[day,week])
    [storageKey.showPresentStoryTicket]: {
      type: 'string',
    },
    // 今日は未受け取り体力回復アイテムがありますを表示しない 最終日(YYYY-mm-dd-[day,week])
    [storageKey.showPresentStaminaItem]: {
      type: 'string',
    },
    // 今日は未使用の体力回復アイテムがありますを表示しない 最終日(YYYY-mm-dd-[day,week])
    [storageKey.showUseStaminaItem]: {
      type: 'string',
    },
    // ミッション画面に遷移した時に開始演出を表示するミッションコードを保存しておく、演出を出したあとはミッションコード削除
    // 複数入れる場合は「,」区切り（mission_001,mission_002）
    [storageKey.showPerformanceMissionCds]: {
      type: 'string',
    },
    // アプリのレビューリクエストを行った日付(YYYY-mm-dd HH:ii:ss)
    // 日付である必要はないが念の為
    [storageKey.appReviewRequest]: {
      type: 'string',
    },
  },
  cache_keys: [
    'assets',
  ],
};
