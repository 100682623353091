/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGachaPortalPickup } from './UserGachaPortalPickup';
import {
    UserGachaPortalPickupFromJSON,
    UserGachaPortalPickupFromJSONTyped,
    UserGachaPortalPickupToJSON,
} from './UserGachaPortalPickup';

/**
 * 
 * @export
 * @interface UserGachaPortal
 */
export interface UserGachaPortal {
    /**
     * カードコード
     * @type {string}
     * @memberof UserGachaPortal
     */
    cardCd: string;
    /**
     * ピックアップカードリソースパーティション
     * @type {number}
     * @memberof UserGachaPortal
     */
    cardResourcePartition: number;
    /**
     * 
     * @type {UserGachaPortalPickup}
     * @memberof UserGachaPortal
     */
    pickup: UserGachaPortalPickup | null;
}

/**
 * Check if a given object implements the UserGachaPortal interface.
 */
export function instanceOfUserGachaPortal(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "cardResourcePartition" in value;
    isInstance = isInstance && "pickup" in value;

    return isInstance;
}

export function UserGachaPortalFromJSON(json: any): UserGachaPortal {
    return UserGachaPortalFromJSONTyped(json, false);
}

export function UserGachaPortalFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaPortal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardCd': json['card_cd'],
        'cardResourcePartition': json['card_resource_partition'],
        'pickup': UserGachaPortalPickupFromJSON(json['pickup']),
    };
}

export function UserGachaPortalToJSON(value?: UserGachaPortal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_cd': value.cardCd,
        'card_resource_partition': value.cardResourcePartition,
        'pickup': UserGachaPortalPickupToJSON(value.pickup),
    };
}

