/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendLikeSend } from './UserFriendLikeSend';
import {
    UserFriendLikeSendFromJSON,
    UserFriendLikeSendFromJSONTyped,
    UserFriendLikeSendToJSON,
} from './UserFriendLikeSend';

/**
 * 
 * @export
 * @interface FriendLikeSendResponse
 */
export interface FriendLikeSendResponse {
    /**
     * 
     * @type {UserFriendLikeSend}
     * @memberof FriendLikeSendResponse
     */
    data: UserFriendLikeSend;
}

/**
 * Check if a given object implements the FriendLikeSendResponse interface.
 */
export function instanceOfFriendLikeSendResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function FriendLikeSendResponseFromJSON(json: any): FriendLikeSendResponse {
    return FriendLikeSendResponseFromJSONTyped(json, false);
}

export function FriendLikeSendResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendLikeSendResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserFriendLikeSendFromJSON(json['data']),
    };
}

export function FriendLikeSendResponseToJSON(value?: FriendLikeSendResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserFriendLikeSendToJSON(value.data),
    };
}

