/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeckSetNameRequest
 */
export interface DeckSetNameRequest {
    /**
     * 部隊番号（1～5）
     * @type {number}
     * @memberof DeckSetNameRequest
     */
    deckNo: number;
    /**
     * 部隊名称
     * @type {string}
     * @memberof DeckSetNameRequest
     */
    name: string;
}

/**
 * Check if a given object implements the DeckSetNameRequest interface.
 */
export function instanceOfDeckSetNameRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deckNo" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function DeckSetNameRequestFromJSON(json: any): DeckSetNameRequest {
    return DeckSetNameRequestFromJSONTyped(json, false);
}

export function DeckSetNameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeckSetNameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deckNo': json['deck_no'],
        'name': json['name'],
    };
}

export function DeckSetNameRequestToJSON(value?: DeckSetNameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deck_no': value.deckNo,
        'name': value.name,
    };
}

