/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DeckRecommendResponseDataCardListInner } from './DeckRecommendResponseDataCardListInner';
import {
    DeckRecommendResponseDataCardListInnerFromJSON,
    DeckRecommendResponseDataCardListInnerFromJSONTyped,
    DeckRecommendResponseDataCardListInnerToJSON,
} from './DeckRecommendResponseDataCardListInner';

/**
 * 
 * @export
 * @interface DeckRecommendResponseData
 */
export interface DeckRecommendResponseData {
    /**
     * おすすめデッキのカードのリスト
     * @type {Array<DeckRecommendResponseDataCardListInner>}
     * @memberof DeckRecommendResponseData
     */
    cardList: Array<DeckRecommendResponseDataCardListInner>;
}

/**
 * Check if a given object implements the DeckRecommendResponseData interface.
 */
export function instanceOfDeckRecommendResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardList" in value;

    return isInstance;
}

export function DeckRecommendResponseDataFromJSON(json: any): DeckRecommendResponseData {
    return DeckRecommendResponseDataFromJSONTyped(json, false);
}

export function DeckRecommendResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeckRecommendResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardList': ((json['card_list'] as Array<any>).map(DeckRecommendResponseDataCardListInnerFromJSON)),
    };
}

export function DeckRecommendResponseDataToJSON(value?: DeckRecommendResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_list': ((value.cardList as Array<any>).map(DeckRecommendResponseDataCardListInnerToJSON)),
    };
}

