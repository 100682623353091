/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PanelmissionPanel } from './PanelmissionPanel';
import {
    PanelmissionPanelFromJSON,
    PanelmissionPanelFromJSONTyped,
    PanelmissionPanelToJSON,
} from './PanelmissionPanel';
import type { PanelmissionType } from './PanelmissionType';
import {
    PanelmissionTypeFromJSON,
    PanelmissionTypeFromJSONTyped,
    PanelmissionTypeToJSON,
} from './PanelmissionType';

/**
 * 
 * @export
 * @interface Panelmission
 */
export interface Panelmission {
    /**
     * パネルミッションコード
     * @type {string}
     * @memberof Panelmission
     */
    panelmissionCd: string;
    /**
     * 
     * @type {PanelmissionType}
     * @memberof Panelmission
     */
    panelmissionType: PanelmissionType;
    /**
     * パネルミッション名
     * @type {string}
     * @memberof Panelmission
     */
    panelmissionName: string;
    /**
     * 説明画像
     * @type {string}
     * @memberof Panelmission
     */
    resourcePanelmissionCd?: string;
    /**
     * リソースパーティション番号
     * @type {number}
     * @memberof Panelmission
     */
    resourcePartition?: number;
    /**
     * 開催開始
     * @type {string}
     * @memberof Panelmission
     */
    startedAt?: string | null;
    /**
     * 開催終了
     * @type {string}
     * @memberof Panelmission
     */
    endedAt?: string | null;
    /**
     * コンプリートフラグ（trueはコンプリート済み）
     * @type {boolean}
     * @memberof Panelmission
     */
    completeFlg: boolean;
    /**
     * パネルの配列
     * @type {Array<PanelmissionPanel>}
     * @memberof Panelmission
     */
    panelList: Array<PanelmissionPanel>;
}

/**
 * Check if a given object implements the Panelmission interface.
 */
export function instanceOfPanelmission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "panelmissionCd" in value;
    isInstance = isInstance && "panelmissionType" in value;
    isInstance = isInstance && "panelmissionName" in value;
    isInstance = isInstance && "completeFlg" in value;
    isInstance = isInstance && "panelList" in value;

    return isInstance;
}

export function PanelmissionFromJSON(json: any): Panelmission {
    return PanelmissionFromJSONTyped(json, false);
}

export function PanelmissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Panelmission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'panelmissionCd': json['panelmission_cd'],
        'panelmissionType': PanelmissionTypeFromJSON(json['panelmission_type']),
        'panelmissionName': json['panelmission_name'],
        'resourcePanelmissionCd': !exists(json, 'resource_panelmission_cd') ? undefined : json['resource_panelmission_cd'],
        'resourcePartition': !exists(json, 'resource_partition') ? undefined : json['resource_partition'],
        'startedAt': !exists(json, 'started_at') ? undefined : json['started_at'],
        'endedAt': !exists(json, 'ended_at') ? undefined : json['ended_at'],
        'completeFlg': json['complete_flg'],
        'panelList': ((json['panel_list'] as Array<any>).map(PanelmissionPanelFromJSON)),
    };
}

export function PanelmissionToJSON(value?: Panelmission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'panelmission_cd': value.panelmissionCd,
        'panelmission_type': PanelmissionTypeToJSON(value.panelmissionType),
        'panelmission_name': value.panelmissionName,
        'resource_panelmission_cd': value.resourcePanelmissionCd,
        'resource_partition': value.resourcePartition,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'complete_flg': value.completeFlg,
        'panel_list': ((value.panelList as Array<any>).map(PanelmissionPanelToJSON)),
    };
}

