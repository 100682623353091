<template>
  <div id="app" @touchstart.passive="onTouchStart">
    <NuxtLayout>
      <NuxtPage :page-key="$route.fullPath" />
    </NuxtLayout>
    <!-- 商品購入モーダル -->
    <LazyModalShopBuyItem
      v-if="shopItemData"
      :show="true"
      :shop-item="shopItemData.shopItem"
      :user-item="shopItemData.userItem"
      :user-shop-item="shopItemData.userShopItem"
      :user-shop-limit-item="shopItemData.userShopLimitItem"
      :message="shopItemData.message"
      @on-success="onBuySuccess"
      @on-cancel="onBuyCancel"
    />
    <!-- セットアイテム商品購入モーダル -->
    <LazyModalShopBuyCombinedsaleItem
      v-if="shopCombinedsaleItemData"
      :show="true"
      :user-item="shopCombinedsaleItemData.userItem"
      :shop-combinedsale-item="shopCombinedsaleItemData.shopCombinedsaleItem"
      :user-shop-combinedsale-item="shopCombinedsaleItemData.userShopCombinedsaleItem"
      :user-shop-limit-item="shopCombinedsaleItemData.userShopLimitItem"
      @on-success="onCombinedsaleItemBuySuccess"
      @on-cancel="onCombinedsaleItemBuyCancel"
    />
    <LazyModalAlert
      :show="enableTitleBackModal"
      title="確認"
      ok-text="OK"
      :z-index="1060"
      :is-enable-cancel="true"
      @click-ok="onBackTitleOk"
      @click-cancel="onBackCancel"
    >
      <div class="text-center mb-2">
        タイトル画面に戻りますか？
      </div>
    </LazyModalAlert>
    <TheLoading />
    <ModalError :show="isError" :error="error" @click="onErrorClick" @click-cancel="onErrorClickCancel" />
    <ModalErrorLoad :show="isLoadError" :error="loadError" @click="onLoadErrorClick" />
    <Teleport to="body">
      <TransitionGroup name="snackbar" @after-leave="leaveSnackbar">
        <LazyModalSnackbar
          v-for="(v) of showSnackbar"
          :id="v.id"
          :key="v.id"
          :title="v.title"
          :text="v.text"
          :long="v.long"
          :left-image-asset="v.leftImageResourceAsset"
          :left-image-partition="v.leftImageResourcePartition"
          :left-image-cd="v.leftImageResourceCd"
          :left-image-path="v.leftImagePath"
          :timeout="v.timeout"
          :layout="v.layout"
          :message="v.message"
          :on-click-param="v.onClickParam"
          @on-click="v.onClick"
        />
      </TransitionGroup>
    </Teleport>
    <Teleport to="body">
      <TheTouchEffect />
    </Teleport>
    <DevOnly>
      <Teleport to="body">
        <TheDebugSafeArea v-if="safeAreaTest" />
      </Teleport>
    </DevOnly>
  </div>
</template>

<script lang="ts" setup>
import { config } from '@/configs/config';
import { clearAppError, useReportAppError, useAppError } from '~/composables/useAppError';
import { isMobileApp } from '~/libs/platform';
import { SnackbarData } from '~/composables/useSnackbar';
import { defineAppBack } from '~/composables/useAppBack';

const error = useAppError();
const isError = computed(() => !loadError.value && error.value !== null);
const isLoadError = computed(() => !!loadError.value);
const loadError = ref<Error | null>(null);
// navigationでのエラーハンドリング
useRouter().onError((e) => {
  if (e instanceof Error) {
    loadError.value = e;
  }
});
const onLoadErrorClick = () => {
  loadError.value = null;
  if (process.client) {
    location.href = '/mypage';
  }
};

useHead({
  title: config.title,
});

// /**
//  * dynamic importエラーを検知
//  * @param error
//  */
// const isDynamicImportError = (error: Error) => {
//   // chrome
//   if (error.message.includes('Failed to fetch dynamically imported module')) {
//     return true;
//   }
//   // safari
//   if (error.message.includes('Importing a module script failed')) {
//     return true;
//   }
//   return false;
// };
// const isExceptionError = ref<boolean>(false);
// const exceptionError = ref<Error|{statusCode: number, statusMessage: string}>();
// エラーモーダル処理
onErrorCaptured((error: Error) => {
  // // 再読み込み
  // if (process.client && error instanceof Error && isDynamicImportError(error)) {
  //   useLog('app').debug('dynamic import error');
  //   // 共通エラーに飛ばしたい(createError)が読み込めない可能性があるので読み込み済みのコンポーネントを表示
  //   isExceptionError.value = true;
  //   exceptionError.value = {
  //     statusCode: 500,
  //     statusMessage: '読み込みエラーが発生しました',
  //   };
  //   return;
  // }
  return !useReportAppError(error);
});
const onErrorClick = () => {
  clearAppError();
};
const onErrorClickCancel = () => {
  clearAppError();
};

// snackbar
const snackbar = useSnackbar().snackBar;
const showSnackbar = ref<SnackbarData[]>([]);
const leaveSnackbar = () => {
  updateSnackbar();
};
const updateSnackbar = () => {
  if (showSnackbar.value.length <= 0 && snackbar.value.length > 0) {
    showSnackbar.value = [snackbar.value[0]];
  }
};
watch(() => snackbar, (data) => {
  if (data.value.length <= 0 || showSnackbar.value.length <= 0 || data.value[0].id !== showSnackbar.value[0].id) {
    showSnackbar.value = [];
  }
  updateSnackbar();
}, {
  deep: true,
});
updateSnackbar();
const safeAreaTest = useRuntimeConfig().public.safeAreaTest;
// ショップアイテム購入
const shopItemData = useShop().shopItemData;
const onBuySuccess = async () => {
  useShop().shopItemData.value = undefined;

  const key = useLoading().loader.start({ blocked: true, });
  try {
    // APIからプレゼントデータを取得
    await Promise.all([
      useUser().updateStatus(),
      useAllWallet().update(),
    ]);
  } finally {
    useLoading().loader.stop(key);
  }
};
const onBuyCancel = () => {
  useShop().shopItemData.value = undefined;
};
// セットアイテム購入
const shopCombinedsaleItemData = useShop().shopCombinedsaleItemData;
const onCombinedsaleItemBuySuccess = () => {
  useShop().shopCombinedsaleItemData.value = undefined;
};
const onCombinedsaleItemBuyCancel = () => {
  useShop().shopCombinedsaleItemData.value = undefined;
  useShop().checkStatus.value = false;
};

useHead({
  bodyAttrs: {
    id: 'body',
  },
});

const { $nativeBridge } = useNuxtApp();
onBeforeMount(async () => {
  if (isMobileApp()) {
    // 初期化完了を通知
    await $nativeBridge.send('onInitialized');
    // スクリーンの常時スリープ無効設定を初期化
    await $nativeBridge.send('screenSleep', {
      isNeverSleep: false,
    });
  }
});
const onTouchStart = () => {
  // スマホでのCSS:activeを動作させるために入れているので、特に何もしない
};
// Androidのバックキーが押された場合
const enableTitleBackModal = ref<boolean>(false);
defineAppBack(() => {
  const route = useRoute();
  enableTitleBackModal.value = false;
  if (route.name === 'mypage') {
    enableTitleBackModal.value = true;
    return true;
  } else if (route.name && ['title', ''].includes(route.name.toString())) {
    if (isMobileApp()) {
      $nativeBridge.send('applicationQuit');
    }
    return true;
  }
});

/**
 * タイトルに戻る
 */
const onBackTitleOk = () => {
  enableTitleBackModal.value = false;
  const router = useRouter();
  router.push({ path: '/title', });
};
/**
 * タイトルに戻るのキャンセル
 */
const onBackCancel = () => {
  enableTitleBackModal.value = false;
};
</script>
<style lang="scss" scoped>
@use "assets/css/base" as base;
@use "assets/css/mixin" as mixin;

#app {
  width: 100%;
  height: 100%;

  @include mixin.min-aspect() {
    height: auto;
  }
}

/**
 * snackbar transaction
 */
.snackbar-enter-active,
.snackbar-leave-active {
  transition: transform 0.8s ease, opacity 0.8s ease;
}

.snackbar-enter-from,
.snackbar-leave-to {
  transform: translateY(-40px);
  opacity: 0;
}
</style>
