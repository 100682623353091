/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserBadgeResponseData
 */
export interface UserBadgeResponseData {
    /**
     * コミュ報酬の数
     * @type {number}
     * @memberof UserBadgeResponseData
     */
    commuBonus: number;
    /**
     * 未受け取りプレゼントの数
     * @type {number}
     * @memberof UserBadgeResponseData
     */
    present: number;
    /**
     * コレクション画面で存在しているバッジの数
     * @type {number}
     * @memberof UserBadgeResponseData
     */
    collection: number;
    /**
     * 何かしらのイベントが開催中かどうか
     * @type {boolean}
     * @memberof UserBadgeResponseData
     */
    eventFlg: boolean;
    /**
     * ガチャが表示されるべきか
     * @type {boolean}
     * @memberof UserBadgeResponseData
     */
    gachaFlg: boolean;
    /**
     * 特別ダイヤセールをやっているかどうか
     * @type {boolean}
     * @memberof UserBadgeResponseData
     */
    shopFlg: boolean;
    /**
     * パネルミッション達成済み未取得報酬数
     * @type {number}
     * @memberof UserBadgeResponseData
     */
    panelmissionBonus: number;
    /**
     * メッセージ未読の数
     * @type {number}
     * @memberof UserBadgeResponseData
     */
    notReadMessageCount: number;
}

/**
 * Check if a given object implements the UserBadgeResponseData interface.
 */
export function instanceOfUserBadgeResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commuBonus" in value;
    isInstance = isInstance && "present" in value;
    isInstance = isInstance && "collection" in value;
    isInstance = isInstance && "eventFlg" in value;
    isInstance = isInstance && "gachaFlg" in value;
    isInstance = isInstance && "shopFlg" in value;
    isInstance = isInstance && "panelmissionBonus" in value;
    isInstance = isInstance && "notReadMessageCount" in value;

    return isInstance;
}

export function UserBadgeResponseDataFromJSON(json: any): UserBadgeResponseData {
    return UserBadgeResponseDataFromJSONTyped(json, false);
}

export function UserBadgeResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserBadgeResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commuBonus': json['commu_bonus'],
        'present': json['present'],
        'collection': json['collection'],
        'eventFlg': json['event_flg'],
        'gachaFlg': json['gacha_flg'],
        'shopFlg': json['shop_flg'],
        'panelmissionBonus': json['panelmission_bonus'],
        'notReadMessageCount': json['not_read_message_count'],
    };
}

export function UserBadgeResponseDataToJSON(value?: UserBadgeResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commu_bonus': value.commuBonus,
        'present': value.present,
        'collection': value.collection,
        'event_flg': value.eventFlg,
        'gacha_flg': value.gachaFlg,
        'shop_flg': value.shopFlg,
        'panelmission_bonus': value.panelmissionBonus,
        'not_read_message_count': value.notReadMessageCount,
    };
}

