/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuCharaPortrait
 */
export interface CommuCharaPortrait {
    /**
     * 立ち絵画像コード
     * @type {string}
     * @memberof CommuCharaPortrait
     */
    bodyImageCd: string;
    /**
     * 立ち絵リソースパーティション番号
     * @type {number}
     * @memberof CommuCharaPortrait
     */
    bodyResourcePartition: number;
    /**
     * 表情差分画像コード
     * @type {string}
     * @memberof CommuCharaPortrait
     */
    faceImageCd: string;
    /**
     * 表情差分リソースパーティション番号
     * @type {number}
     * @memberof CommuCharaPortrait
     */
    faceResourcePartition: number;
    /**
     * ストーリー背景画像コード
     * @type {string}
     * @memberof CommuCharaPortrait
     */
    backgroundImageCd: string;
    /**
     * ストーリー背景リソースパーティション番号
     * @type {number}
     * @memberof CommuCharaPortrait
     */
    backgroundResourcePartition: number;
    /**
     * レベル下限
     * @type {number}
     * @memberof CommuCharaPortrait
     */
    lowerLevel: number;
    /**
     * レベル上限
     * @type {number}
     * @memberof CommuCharaPortrait
     */
    upperLevel: number;
}

/**
 * Check if a given object implements the CommuCharaPortrait interface.
 */
export function instanceOfCommuCharaPortrait(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "bodyImageCd" in value;
    isInstance = isInstance && "bodyResourcePartition" in value;
    isInstance = isInstance && "faceImageCd" in value;
    isInstance = isInstance && "faceResourcePartition" in value;
    isInstance = isInstance && "backgroundImageCd" in value;
    isInstance = isInstance && "backgroundResourcePartition" in value;
    isInstance = isInstance && "lowerLevel" in value;
    isInstance = isInstance && "upperLevel" in value;

    return isInstance;
}

export function CommuCharaPortraitFromJSON(json: any): CommuCharaPortrait {
    return CommuCharaPortraitFromJSONTyped(json, false);
}

export function CommuCharaPortraitFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaPortrait {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bodyImageCd': json['body_image_cd'],
        'bodyResourcePartition': json['body_resource_partition'],
        'faceImageCd': json['face_image_cd'],
        'faceResourcePartition': json['face_resource_partition'],
        'backgroundImageCd': json['background_image_cd'],
        'backgroundResourcePartition': json['background_resource_partition'],
        'lowerLevel': json['lower_level'],
        'upperLevel': json['upper_level'],
    };
}

export function CommuCharaPortraitToJSON(value?: CommuCharaPortrait | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body_image_cd': value.bodyImageCd,
        'body_resource_partition': value.bodyResourcePartition,
        'face_image_cd': value.faceImageCd,
        'face_resource_partition': value.faceResourcePartition,
        'background_image_cd': value.backgroundImageCd,
        'background_resource_partition': value.backgroundResourcePartition,
        'lower_level': value.lowerLevel,
        'upper_level': value.upperLevel,
    };
}

