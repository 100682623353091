/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGachaListResponseData } from './UserGachaListResponseData';
import {
    UserGachaListResponseDataFromJSON,
    UserGachaListResponseDataFromJSONTyped,
    UserGachaListResponseDataToJSON,
} from './UserGachaListResponseData';

/**
 * 
 * @export
 * @interface UserGachaListResponse
 */
export interface UserGachaListResponse {
    /**
     * 
     * @type {UserGachaListResponseData}
     * @memberof UserGachaListResponse
     */
    data: UserGachaListResponseData;
}

/**
 * Check if a given object implements the UserGachaListResponse interface.
 */
export function instanceOfUserGachaListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserGachaListResponseFromJSON(json: any): UserGachaListResponse {
    return UserGachaListResponseFromJSONTyped(json, false);
}

export function UserGachaListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserGachaListResponseDataFromJSON(json['data']),
    };
}

export function UserGachaListResponseToJSON(value?: UserGachaListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserGachaListResponseDataToJSON(value.data),
    };
}

