/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AvatarEquip } from './AvatarEquip';
import {
    AvatarEquipFromJSON,
    AvatarEquipFromJSONTyped,
    AvatarEquipToJSON,
} from './AvatarEquip';

/**
 * 
 * @export
 * @interface AvatarEquipListResponse
 */
export interface AvatarEquipListResponse {
    /**
     * 
     * @type {Array<AvatarEquip>}
     * @memberof AvatarEquipListResponse
     */
    data: Array<AvatarEquip>;
}

/**
 * Check if a given object implements the AvatarEquipListResponse interface.
 */
export function instanceOfAvatarEquipListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function AvatarEquipListResponseFromJSON(json: any): AvatarEquipListResponse {
    return AvatarEquipListResponseFromJSONTyped(json, false);
}

export function AvatarEquipListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvatarEquipListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(AvatarEquipFromJSON)),
    };
}

export function AvatarEquipListResponseToJSON(value?: AvatarEquipListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(AvatarEquipToJSON)),
    };
}

