/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ShopItemRequestEnum = {
    Avatar: 'avatar',
    Item: 'item',
    Epilogue: 'epilogue'
} as const;
export type ShopItemRequestEnum = typeof ShopItemRequestEnum[keyof typeof ShopItemRequestEnum];


export function ShopItemRequestEnumFromJSON(json: any): ShopItemRequestEnum {
    return ShopItemRequestEnumFromJSONTyped(json, false);
}

export function ShopItemRequestEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItemRequestEnum {
    return json as ShopItemRequestEnum;
}

export function ShopItemRequestEnumToJSON(value?: ShopItemRequestEnum | null): any {
    return value as any;
}

