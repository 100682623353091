import { ResponseError } from 'api';
import { FirebaseError } from '@firebase/app';

/**
 * アプリ共通エラー(エラーモーダルを表示)
 */
export class AppError extends Error {
  constructor (message: string, name?: string) {
    super(message);
    this.name = name ?? 'AppError';
  }
}
/**
 * アプリ共通エラー(エラーモーダルでエラーメッセージをデフォルト表示)
 */
export class AppVisibleError extends AppError {
  constructor (message: string, name?: string) {
    super(message);
    this.name = name ?? 'AppModalError';
  }
}
/**
 * アプリ共通エラー(エラーモーダルでエラーメッセージをそのまま表示)
 */
export class AppPlaneVisibleError extends AppVisibleError {
  readonly code: string;
  readonly backUrl: string;
  readonly okText: string;
  readonly isClose: boolean;
  constructor (message: string, name?: string, code?: string, backUrl?: string, okText?: string, isClose?: boolean) {
    super(message);
    this.name = name ?? 'AppModalError';
    this.code = code ?? '0';
    this.backUrl = backUrl ?? '';
    this.okText = okText ?? '';
    this.isClose = isClose ?? false;
  }
}
/**
 * アプリ共通エラー
 */
export class SystemError extends AppError {
  constructor (message: string) {
    super(message, 'SystemError');
  }
}
export class FirebaseAppError extends AppVisibleError {
  readonly code: string;
  constructor (e: FirebaseError) {
    super(getFirebaseErrorMessage(e, 'signIn') || '認証に失敗しました。しばらく時間をおいて再度お試しください');
    this.code = e.code;
  }
}
export class AnimateAuthenticationError extends AppError {
  readonly code: string;
  constructor (code?: string, message?: string) {
    super(message ?? '認証に失敗しました。しばらく時間をおいて再度お試しください');
    this.code = code ?? '0';
  }
}
/**
 * APIエラー
 */
export class NetworkError extends AppError {
  constructor (message: string, name?: string) {
    super(message, name ?? 'NetworkError');
  }
}
export class ApiError extends NetworkError {
  readonly code: number;
  readonly statusCode: number;
  readonly statusText: string;
  readonly response: Response;
  constructor (code: number, message: string, response: Response) {
    super(message, 'ApiError');
    this.code = code;
    this.response = response;
    this.statusCode = response.status;
    this.statusText = response.statusText;
  }
}
export class ApiAuthenticationError extends ApiError {
  constructor (code: number, message: string, response: Response) {
    super(code, message, response);
    this.name = 'ApiAuthenticationError';
  }
}
export class BanUserError extends ApiError {
  constructor (code: number, message: string, response: Response) {
    super(code, message, response);
    this.name = 'BanUserError';
  }
}
export class MaintenanceError extends ApiError {
  constructor (code: number, message: string, response: Response) {
    super(code, message, response);
    this.name = 'MaintenanceError';
  }
}
export class ApiExpiredIdTokenError extends ApiError {
  constructor (code: number, message: string, response: Response) {
    super(code, message, response);
    this.name = 'ApiExpiredIdTokenError';
  }
}
export class ApiAnimateExpiredIdTokenError extends ApiExpiredIdTokenError {
  constructor (code: number, message: string, response: Response) {
    super(code, message, response);
    this.name = 'ApiAnimateExpiredIdTokenError';
  }
}
/**
 * リソースダウンロード時のエラー
 */
export class ResourceDownloadError extends AppError {
  readonly orgError?: Error;
  constructor (e?: Error, url?: string) {
    super(`${e}(URL: ${url})`, 'ResourceDownloadError');
    this.orgError = e;
  }
}

/**
 * リトライエラー(リロード処理)
 */
export class RetryReloadError extends AppError {
  readonly orgError: Error;
  constructor (e: Error) {
    super(e.message, e.name);
    this.orgError = e;
  }
}

/**
 * 表示エラーメッセージに変換
 * @param {Error} error
 */
export const convertShowError = (error: Error) => {
  let e = error;
  // 元のエラーを取得する
  if (error instanceof RetryReloadError) {
    e = error.orgError;
  } else if (error instanceof ResourceDownloadError && error.orgError) {
    e = error.orgError;
  }
  if (e instanceof AppError) {
    return e;
  }
  if (e instanceof ResponseError) {
    return new AppError('通信エラーが発生しました');
  }
  if (e instanceof FirebaseError) {
    return new FirebaseAppError(e);
  }
  return e;
};

/**
 * Firebaseエラーの日本語化
 * @see https://tenderfeel.xsrv.jp/javascript/3820/
 * @param e
 * @param method
 */
const getFirebaseErrorMessage = (e: FirebaseError, method: 'signIn' | 'signUp' | 'signIn/popup'): string | null => {
  switch (e.code) {
    case 'auth/cancelled-popup-request':
    case 'auth/popup-closed-by-user':
      return null;
    case 'auth/email-already-in-use':
      return 'このメールアドレスは使用されています';
    case 'auth/credential-already-in-use':
      return 'このSNSアカウントは既に他のユーザーデータと連携しています';
    case 'auth/provider-already-linked':
      return '既に連携済みのSNSアカウントです';
    case 'auth/invalid-email':
      return 'メールアドレスの形式が正しくありません';
    case 'auth/user-disabled':
      return 'サービスの利用が停止されています';
    case 'auth/user-not-found':
      return 'メールアドレスまたはパスワードが違います';
    case 'auth/user-mismatch':
      if (method === 'signIn/popup') {
        return 'ユーザー情報が正しくありません';
      } else {
        return 'メールアドレスまたはパスワードが違います';
      }
    case 'auth/weak-password':
      return 'パスワードが短すぎます';
    case 'auth/wrong-password':
      return 'メールアドレスまたはパスワードが違います';
    case 'auth/popup-blocked':
      return '認証ポップアップがブロックされました。ポップアップブロックをご利用の場合は設定を解除してください';
    case 'auth/operation-not-supported-in-this-environment':
    case 'auth/auth-domain-config-required':
    case 'auth/operation-not-allowed':
    case 'auth/unauthorized-domain':
      return '現在この認証方法はご利用頂けません';
    case 'auth/requires-recent-login':
      return '認証の有効期限が切れています';
    case 'auth/web-storage-unsupported':
    default:
      if (method === 'signIn') {
        return '認証に失敗しました。しばらく時間をおいて再度お試しください';
      } else {
        return 'エラーが発生しました。しばらく時間をおいてお試しください';
      }
  }
};

/**
 * 表示用のエラー取得
 * @param e
 */
const getDisplayError = (e: Error | null) => {
  if (e instanceof RetryReloadError) {
    return e.orgError;
  }
  return e;
};

/**
 * エラーコードの取得
 * @param e
 * @return {number}
 */
export const getDisplayErrorCode = (e: Error | null) => {
  const error = getDisplayError(e);
  if (error instanceof ApiError) {
    return error.code;
  }
  return 0;
};
