/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 購入方法
 * @export
 */
export const ShopItemBuyType = {
    Gem: 'gem',
    Gold: 'gold'
} as const;
export type ShopItemBuyType = typeof ShopItemBuyType[keyof typeof ShopItemBuyType];


export function ShopItemBuyTypeFromJSON(json: any): ShopItemBuyType {
    return ShopItemBuyTypeFromJSONTyped(json, false);
}

export function ShopItemBuyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItemBuyType {
    return json as ShopItemBuyType;
}

export function ShopItemBuyTypeToJSON(value?: ShopItemBuyType | null): any {
    return value as any;
}

