/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuCharaStatusBonus } from './CommuCharaStatusBonus';
import {
    CommuCharaStatusBonusFromJSON,
    CommuCharaStatusBonusFromJSONTyped,
    CommuCharaStatusBonusToJSON,
} from './CommuCharaStatusBonus';

/**
 * 
 * @export
 * @interface UserCommuCharaStatus
 */
export interface UserCommuCharaStatus {
    /**
     * キャラコード
     * @type {string}
     * @memberof UserCommuCharaStatus
     */
    charaCd: string;
    /**
     * コミュキャラ限界突破コード
     * @type {string}
     * @memberof UserCommuCharaStatus
     */
    commuCharaLimitBreakCd: string;
    /**
     * コミュポイント累積
     * @type {number}
     * @memberof UserCommuCharaStatus
     */
    totalCommuPoint: number;
    /**
     * コミュレベル
     * @type {number}
     * @memberof UserCommuCharaStatus
     */
    commuLevel?: number;
    /**
     * コミュレベルアップ表示フラグ 1:表示 0:非表示
     * @type {boolean}
     * @memberof UserCommuCharaStatus
     */
    levelUpDispFlg: boolean;
    /**
     * 報酬リスト
     * @type {Array<CommuCharaStatusBonus>}
     * @memberof UserCommuCharaStatus
     */
    bonusList: Array<CommuCharaStatusBonus>;
}

/**
 * Check if a given object implements the UserCommuCharaStatus interface.
 */
export function instanceOfUserCommuCharaStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "commuCharaLimitBreakCd" in value;
    isInstance = isInstance && "totalCommuPoint" in value;
    isInstance = isInstance && "levelUpDispFlg" in value;
    isInstance = isInstance && "bonusList" in value;

    return isInstance;
}

export function UserCommuCharaStatusFromJSON(json: any): UserCommuCharaStatus {
    return UserCommuCharaStatusFromJSONTyped(json, false);
}

export function UserCommuCharaStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCommuCharaStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'commuCharaLimitBreakCd': json['commu_chara_limit_break_cd'],
        'totalCommuPoint': json['total_commu_point'],
        'commuLevel': !exists(json, 'commu_level') ? undefined : json['commu_level'],
        'levelUpDispFlg': json['level_up_disp_flg'],
        'bonusList': ((json['bonus_list'] as Array<any>).map(CommuCharaStatusBonusFromJSON)),
    };
}

export function UserCommuCharaStatusToJSON(value?: UserCommuCharaStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'commu_chara_limit_break_cd': value.commuCharaLimitBreakCd,
        'total_commu_point': value.totalCommuPoint,
        'commu_level': value.commuLevel,
        'level_up_disp_flg': value.levelUpDispFlg,
        'bonus_list': ((value.bonusList as Array<any>).map(CommuCharaStatusBonusToJSON)),
    };
}

