/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserFriendMessageList
 */
export interface UserFriendMessageList {
    /**
     * ユーザーソーシャルテーブルID
     * @type {number}
     * @memberof UserFriendMessageList
     */
    userSocialId: number;
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof UserFriendMessageList
     */
    nickname: string;
    /**
     * 自己紹介文言
     * @type {string}
     * @memberof UserFriendMessageList
     */
    introduction: string;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserFriendMessageList
     */
    playerCode: string;
    /**
     * アバターサムネイルのURL
     * @type {string}
     * @memberof UserFriendMessageList
     */
    avatarThumbnailUrl: string;
    /**
     * メッセージ内容
     * @type {string}
     * @memberof UserFriendMessageList
     */
    messageText: string;
    /**
     * メッセージ送信日時
     * @type {string}
     * @memberof UserFriendMessageList
     */
    sendedAt: string;
    /**
     * メッセージ発信者判定
     * 
     * * 0 - フレンド
     * * 1 - 自分
     * * 2 - 他人
     * @type {number}
     * @memberof UserFriendMessageList
     */
    senderType: UserFriendMessageListSenderTypeEnum;
    /**
     * キャラコード
     * @type {string}
     * @memberof UserFriendMessageList
     */
    charaCd: string;
}


/**
 * @export
 */
export const UserFriendMessageListSenderTypeEnum = {
    FRIEND: 0,
    ME: 1,
    OTHER: 2
} as const;
export type UserFriendMessageListSenderTypeEnum = typeof UserFriendMessageListSenderTypeEnum[keyof typeof UserFriendMessageListSenderTypeEnum];


/**
 * Check if a given object implements the UserFriendMessageList interface.
 */
export function instanceOfUserFriendMessageList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userSocialId" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "introduction" in value;
    isInstance = isInstance && "playerCode" in value;
    isInstance = isInstance && "avatarThumbnailUrl" in value;
    isInstance = isInstance && "messageText" in value;
    isInstance = isInstance && "sendedAt" in value;
    isInstance = isInstance && "senderType" in value;
    isInstance = isInstance && "charaCd" in value;

    return isInstance;
}

export function UserFriendMessageListFromJSON(json: any): UserFriendMessageList {
    return UserFriendMessageListFromJSONTyped(json, false);
}

export function UserFriendMessageListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendMessageList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userSocialId': json['user_social_id'],
        'nickname': json['nickname'],
        'introduction': json['introduction'],
        'playerCode': json['player_code'],
        'avatarThumbnailUrl': json['avatar_thumbnail_url'],
        'messageText': json['message_text'],
        'sendedAt': json['sended_at'],
        'senderType': json['sender_type'],
        'charaCd': json['chara_cd'],
    };
}

export function UserFriendMessageListToJSON(value?: UserFriendMessageList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user_social_id': value.userSocialId,
        'nickname': value.nickname,
        'introduction': value.introduction,
        'player_code': value.playerCode,
        'avatar_thumbnail_url': value.avatarThumbnailUrl,
        'message_text': value.messageText,
        'sended_at': value.sendedAt,
        'sender_type': value.senderType,
        'chara_cd': value.charaCd,
    };
}

