/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MissionAvatarConditions } from './MissionAvatarConditions';
import {
    MissionAvatarConditionsFromJSON,
    MissionAvatarConditionsFromJSONTyped,
    MissionAvatarConditionsToJSON,
} from './MissionAvatarConditions';

/**
 * 
 * @export
 * @interface MissionAvatar
 */
export interface MissionAvatar {
    /**
     * ミッションコード
     * @type {string}
     * @memberof MissionAvatar
     */
    missionCd: string;
    /**
     * 
     * @type {MissionAvatarConditions}
     * @memberof MissionAvatar
     */
    conditions: MissionAvatarConditions;
}

/**
 * Check if a given object implements the MissionAvatar interface.
 */
export function instanceOfMissionAvatar(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "conditions" in value;

    return isInstance;
}

export function MissionAvatarFromJSON(json: any): MissionAvatar {
    return MissionAvatarFromJSONTyped(json, false);
}

export function MissionAvatarFromJSONTyped(json: any, ignoreDiscriminator: boolean): MissionAvatar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'conditions': MissionAvatarConditionsFromJSON(json['conditions']),
    };
}

export function MissionAvatarToJSON(value?: MissionAvatar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'conditions': MissionAvatarConditionsToJSON(value.conditions),
    };
}

