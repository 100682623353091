import { SystemError } from '~/libs/errors';
import { storageConfig } from '~/configs/storage';

/**
 * ストレージ定義
 */
export interface StorageDefine {
  type: 'string' | 'number' | 'object' | 'boolean',
  nullable?: boolean,
}
export type StorageDefineConfig = {[key: string]: StorageDefine};

/**
 * localのStorageへのアクセス
 */
class StorageLibrary {
  protected config: StorageDefineConfig;

  constructor (config: StorageDefineConfig) {
    this.config = config;
  }

  /**
   * データの設定
   * @param {string} key
   * @param {any} val
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  save (key: string, val: any) {
    localStorage.setItem(key, this.toStorageData(key, val));
  }

  /**
   * データの取得
   * @param {string} key
   * @param {any} defaultValue
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get (key: string, defaultValue: any = null): any {
    const val = localStorage.getItem(key);
    if (val === null) {
      return defaultValue;
    }
    return this.toDefineData(key, val);
  }

  /**
   * データの数値型取得
   * @param key
   * @param defaultValue
   */
  getNumber (key: string, defaultValue = 0): number {
    const val = this.get(key, defaultValue);
    if (typeof val === 'number') {
      return val;
    }
    return defaultValue;
  }

  /**
   * データの取得
   * @param {string} key
   * @ts-ignore
   */
  remove (key: string) {
    if (!this.config[key]) {
      throw new SystemError(`not define storageData key "${key}"`);
    }
    localStorage.removeItem(key);
  }

  /**
   * 設定の取得
   * @param key
   * @protected
   */
  protected getDefine (key: string) {
    const data = this.config[key];
    if (!data) {
      throw new SystemError(`not define storageData key "${key}"`);
    }
    return data;
  }

  /**
   * 保存用データに変換
   *
   * @param key
   * @param val
   * @protected
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected toStorageData (key: string, val: any) : string {
    const define = this.getDefine(key);
    const type = typeof val;
    if (val === null && define.nullable) {
      return '';
    }
    if (type !== define.type) {
      throw new SystemError(`invalid key ${key} is typeof ${type}. ${define.type} required.`);
    }
    if (type === 'string') {
      return val;
    } else if (type === 'number') {
      return val.toString();
    } else if (type === 'boolean') {
      return val.toString();
    } else if (type === 'object') {
      return JSON.stringify(val);
    }
    throw new SystemError(`not support key ${key} typeof val ${type}.`);
  }

  /**
   * 取得用データに変換
   *
   * @param key
   * @param val
   * @protected
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected toDefineData (key: string, val: string) : any {
    const define = this.getDefine(key);
    const type = define.type;
    if (type === 'string') {
      return val;
    } else if (type === 'number') {
      return Number(val);
    } else if (type === 'boolean') {
      return (val.toLowerCase() === 'true');
    } else if (type === 'object') {
      return JSON.parse(val);
    }
    throw new SystemError(`not support key ${key} typeof val ${type}.`);
  }
}

export type { StorageLibrary };
export const defaultStorage = new StorageLibrary(storageConfig.keys);

/**
 * 全てのキャッシュを削除
 */
export const deleteAllCache = async () => {
  if (!('caches' in window)) {
    return;
  }

  const keys = await caches.keys();
  await Promise.all(keys.map((key) => {
    return caches.delete(key);
  }));
};
