/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EquipAvatars } from './EquipAvatars';
import {
    EquipAvatarsFromJSON,
    EquipAvatarsFromJSONTyped,
    EquipAvatarsToJSON,
} from './EquipAvatars';

/**
 * 
 * @export
 * @interface AvatarSaveRequest
 */
export interface AvatarSaveRequest {
    /**
     * サムネのbase64
     * @type {string}
     * @memberof AvatarSaveRequest
     */
    thumbnailImage?: string;
    /**
     * 配置情報
     * @type {Array<EquipAvatars>}
     * @memberof AvatarSaveRequest
     */
    equipAvatars?: Array<EquipAvatars>;
}

/**
 * Check if a given object implements the AvatarSaveRequest interface.
 */
export function instanceOfAvatarSaveRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AvatarSaveRequestFromJSON(json: any): AvatarSaveRequest {
    return AvatarSaveRequestFromJSONTyped(json, false);
}

export function AvatarSaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvatarSaveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'thumbnailImage': !exists(json, 'thumbnail_image') ? undefined : json['thumbnail_image'],
        'equipAvatars': !exists(json, 'equip_avatars') ? undefined : ((json['equip_avatars'] as Array<any>).map(EquipAvatarsFromJSON)),
    };
}

export function AvatarSaveRequestToJSON(value?: AvatarSaveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'thumbnail_image': value.thumbnailImage,
        'equip_avatars': value.equipAvatars === undefined ? undefined : ((value.equipAvatars as Array<any>).map(EquipAvatarsToJSON)),
    };
}

