import { useNuxtApp } from '#app';
import { getBinaryUrl, isMobileApp, versionCompare } from '~/libs/platform';
import { useLog, useReportAppError } from '#imports';

interface AppBinary {
  version: string,
  url: string,
  minimumOsVersion: string,
}

interface AppBinaryResponse {
  version: string,
  url: string,
  minimum_os_version: string,
}

/**
 * バージョン情報を更新
 */
const fetch = async () => {
  const url = getBinaryUrl();
  if (!url) {
    return;
  }
  try {
    const response = await useFetch<never>(url, {
      mode: 'cors',
    });
    if (!response.error.value) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const data: AppBinaryResponse = response.data.value;
      useAppBinary().binary.value = {
        version: data?.version ?? '',
        url: data?.url ?? '',
        minimumOsVersion: data?.minimum_os_version ?? '',
      };
      useLog('useAppBinary').debug('fetch', useAppBinary().binary.value);
    }
  } catch (e) {
    useReportAppError(e);
  }
};

/**
 * バイナリバージョンの更新
 * @param binary
 */
const update = async (binary: AppBinary) => {
  useAppBinary().binary.value = binary;
  // 強制バージョンアップへ移動
  if (!useAppBinary().check()) {
    await useAppBinary().navigateVersionUp();
  }
};

/**
 * バージョンのチェック
 */
const check = () => {
  if (!isMobileApp()) {
    // アプリ以外はスルー
    return true;
  }
  // 必要バージョン情報
  const binary = useAppBinary().binary.value;
  if (!binary) {
    // バイナリ情報が更新されていなければ許可
    return true;
  }
  const { $storage } = useNuxtApp();
  const version = $storage.get('appVersion');
  if (!version || (typeof version !== 'string')) {
    // バージョン情報が取得できない
    return true;
  }
  return versionCompare(version, binary.version) >= 0;
};

/**
 * バージョン更新ページへ移動
 */
const navigateVersionUp = async () => {
  if (useRoute().path !== '/version-up') {
    return await navigateTo('/version-up');
  }
};

/**
 * 画面間で共通で使用する情報を保存しておく
 */
export const useAppBinary = () => {
  const binary = useState<AppBinary>('app_binary');
  return {
    binary,
    fetch,
    update,
    check,
    navigateVersionUp,
  };
};
