import { defaultStorage } from '~/libs/storage';

/**
 * 端末情報
 */
export enum Device {
  ios = 'ios',
  android = 'android',
  unknown = 'unknown',
}

/**
 * デバイス種別を取得(User-Agentから取得)
 */
export const getDevice = () => {
  if (isIOSDevice()) {
    return Device.ios;
  } else if (isAndroidDevice()) {
    return Device.android;
  }
  return Device.unknown;
};

/**
 * iOSデバイスか判定
 * @return {boolean}
 */
export const isIOSDevice = () => {
  return /iP(hone|(o|a)d)/.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
};

/**
 * Androidデバイスか判定
 * @return {boolean}
 */
export const isAndroidDevice = () => {
  return /android/i.test(navigator.userAgent);
};

/**
 * UnityEditorデバイスか判定
 * @return {boolean}
 */
export const isUnityEditorDevice = () => {
  return /UnityEditor/i.test(navigator.userAgent);
};

/**
 * OSのバージョンを取得
 * @param {string|undefined} ua
 * @return {string|undefined} 15.0などの文字列
 */
export const getOSVersion = (ua?: string) => {
  const userAgent = ua ?? navigator.userAgent;
  const matches1 = userAgent.match(/iOS(.*) ([\d_\\.]+)/i);
  if (matches1 && matches1.length >= 2) {
    return matches1[2].replaceAll('_', '.');
  }
  const matches2 = userAgent.match(/iP(hone|(o|a)d)(.*) ([\d_\\.]+)/i);
  if (matches2 && matches2.length >= 4) {
    return matches2[4].replaceAll('_', '.');
  }
  // NOTE: 現状iOSのバージョンのみ
  return undefined;
};

/**
 * アプリ版 or ブラウザ版の判定
 */
export const isNativeAppli = () => {
  const appVersion = defaultStorage.get('appVersion');
  return !(!appVersion);
};
