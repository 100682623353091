/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopCombinedsale } from './ShopCombinedsale';
import {
    ShopCombinedsaleFromJSON,
    ShopCombinedsaleFromJSONTyped,
    ShopCombinedsaleToJSON,
} from './ShopCombinedsale';

/**
 * 
 * @export
 * @interface ShopCombinedsales
 */
export interface ShopCombinedsales {
    /**
     * 
     * @type {Array<ShopCombinedsale>}
     * @memberof ShopCombinedsales
     */
    data: Array<ShopCombinedsale>;
}

/**
 * Check if a given object implements the ShopCombinedsales interface.
 */
export function instanceOfShopCombinedsales(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ShopCombinedsalesFromJSON(json: any): ShopCombinedsales {
    return ShopCombinedsalesFromJSONTyped(json, false);
}

export function ShopCombinedsalesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopCombinedsales {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ShopCombinedsaleFromJSON)),
    };
}

export function ShopCombinedsalesToJSON(value?: ShopCombinedsales | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(ShopCombinedsaleToJSON)),
    };
}

