/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ガチャ実行コスト
 * @export
 * @interface UserGachaPrice
 */
export interface UserGachaPrice {
    /**
     * ガチャタイプ
     * @type {string}
     * @memberof UserGachaPrice
     */
    type: UserGachaPriceTypeEnum;
    /**
     * 1回コスト
     * @type {number}
     * @memberof UserGachaPrice
     */
    one: number;
    /**
     * 10回コスト
     * @type {number}
     * @memberof UserGachaPrice
     */
    ten: number;
    /**
     * 1日1回のコスト
     * @type {number}
     * @memberof UserGachaPrice
     */
    limited?: number;
}


/**
 * @export
 */
export const UserGachaPriceTypeEnum = {
    Card: 'card',
    Avatar: 'avatar',
    Gold: 'gold'
} as const;
export type UserGachaPriceTypeEnum = typeof UserGachaPriceTypeEnum[keyof typeof UserGachaPriceTypeEnum];


/**
 * Check if a given object implements the UserGachaPrice interface.
 */
export function instanceOfUserGachaPrice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "one" in value;
    isInstance = isInstance && "ten" in value;

    return isInstance;
}

export function UserGachaPriceFromJSON(json: any): UserGachaPrice {
    return UserGachaPriceFromJSONTyped(json, false);
}

export function UserGachaPriceFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaPrice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'one': json['one'],
        'ten': json['ten'],
        'limited': !exists(json, 'limited') ? undefined : json['limited'],
    };
}

export function UserGachaPriceToJSON(value?: UserGachaPrice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'one': value.one,
        'ten': value.ten,
        'limited': value.limited,
    };
}

