/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopCombinedsaleItemPresent } from './ShopCombinedsaleItemPresent';
import {
    ShopCombinedsaleItemPresentFromJSON,
    ShopCombinedsaleItemPresentFromJSONTyped,
    ShopCombinedsaleItemPresentToJSON,
} from './ShopCombinedsaleItemPresent';
import type { ShopCombinedsaleItemSpecialStory } from './ShopCombinedsaleItemSpecialStory';
import {
    ShopCombinedsaleItemSpecialStoryFromJSON,
    ShopCombinedsaleItemSpecialStoryFromJSONTyped,
    ShopCombinedsaleItemSpecialStoryToJSON,
} from './ShopCombinedsaleItemSpecialStory';
import type { ShopCombinedsaleItemStill } from './ShopCombinedsaleItemStill';
import {
    ShopCombinedsaleItemStillFromJSON,
    ShopCombinedsaleItemStillFromJSONTyped,
    ShopCombinedsaleItemStillToJSON,
} from './ShopCombinedsaleItemStill';

/**
 * 
 * @export
 * @interface ShopCombinedsaleItem
 */
export interface ShopCombinedsaleItem {
    /**
     * セット販売アイテムコード
     * @type {string}
     * @memberof ShopCombinedsaleItem
     */
    combinedsaleItemCd?: string;
    /**
     * ショップアイテムのアイテムコード
     * @type {string}
     * @memberof ShopCombinedsaleItem
     */
    itemCd: string;
    /**
     * アイテム名
     * @type {string}
     * @memberof ShopCombinedsaleItem
     */
    itemName: string;
    /**
     * リソースアイテムコード
     * @type {string}
     * @memberof ShopCombinedsaleItem
     */
    resourceItemCd: string;
    /**
     * リソースパーティション番号
     * @type {number}
     * @memberof ShopCombinedsaleItem
     */
    resourcePartition: number;
    /**
     * ショップアイテムコード(購入時のリクエストに利用する必要がある)
     * @type {string}
     * @memberof ShopCombinedsaleItem
     */
    shopItemCd: string;
    /**
     * 1回のアイテム最大購入可能数
     * @type {number}
     * @memberof ShopCombinedsaleItem
     */
    oneTimeLimitCount: number;
    /**
     * セット販売の詳細説明文言
     * @type {string}
     * @memberof ShopCombinedsaleItem
     */
    description: string;
    /**
     * 購入に必要なジェム数
     * @type {number}
     * @memberof ShopCombinedsaleItem
     */
    gemPrice: number;
    /**
     * 割引前の購入に必要なジェム数(割引きが無い場合、nullとなる)
     * @type {number}
     * @memberof ShopCombinedsaleItem
     */
    undiscountedGemPrice: number | null;
    /**
     * 有償ダイヤ限定購入フラグ
     * @type {boolean}
     * @memberof ShopCombinedsaleItem
     */
    purchasedOnlyFlg: boolean;
    /**
     * クライアント側で利用する表示順序(0に近いほうが優先的に扱われる)
     * @type {number}
     * @memberof ShopCombinedsaleItem
     */
    orderNo: number;
    /**
     * 配信開始日時から数えた、購入締め切り(N秒)。startの日時+limit_timeを超えたアイテムは購入することが出来ない。
     * @type {number}
     * @memberof ShopCombinedsaleItem
     */
    limitTime: number;
    /**
     * 
     * @type {Array<ShopCombinedsaleItemPresent>}
     * @memberof ShopCombinedsaleItem
     */
    presentList: Array<ShopCombinedsaleItemPresent>;
    /**
     * 
     * @type {Array<ShopCombinedsaleItemSpecialStory>}
     * @memberof ShopCombinedsaleItem
     */
    specialStoryList: Array<ShopCombinedsaleItemSpecialStory>;
    /**
     * 
     * @type {Array<ShopCombinedsaleItemStill>}
     * @memberof ShopCombinedsaleItem
     */
    stillList: Array<ShopCombinedsaleItemStill>;
}

/**
 * Check if a given object implements the ShopCombinedsaleItem interface.
 */
export function instanceOfShopCombinedsaleItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemName" in value;
    isInstance = isInstance && "resourceItemCd" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "shopItemCd" in value;
    isInstance = isInstance && "oneTimeLimitCount" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "gemPrice" in value;
    isInstance = isInstance && "undiscountedGemPrice" in value;
    isInstance = isInstance && "purchasedOnlyFlg" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "limitTime" in value;
    isInstance = isInstance && "presentList" in value;
    isInstance = isInstance && "specialStoryList" in value;
    isInstance = isInstance && "stillList" in value;

    return isInstance;
}

export function ShopCombinedsaleItemFromJSON(json: any): ShopCombinedsaleItem {
    return ShopCombinedsaleItemFromJSONTyped(json, false);
}

export function ShopCombinedsaleItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopCombinedsaleItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'combinedsaleItemCd': !exists(json, 'combinedsale_item_cd') ? undefined : json['combinedsale_item_cd'],
        'itemCd': json['item_cd'],
        'itemName': json['item_name'],
        'resourceItemCd': json['resource_item_cd'],
        'resourcePartition': json['resource_partition'],
        'shopItemCd': json['shop_item_cd'],
        'oneTimeLimitCount': json['one_time_limit_count'],
        'description': json['description'],
        'gemPrice': json['gem_price'],
        'undiscountedGemPrice': json['undiscounted_gem_price'],
        'purchasedOnlyFlg': json['purchased_only_flg'],
        'orderNo': json['order_no'],
        'limitTime': json['limit_time'],
        'presentList': ((json['present_list'] as Array<any>).map(ShopCombinedsaleItemPresentFromJSON)),
        'specialStoryList': ((json['special_story_list'] as Array<any>).map(ShopCombinedsaleItemSpecialStoryFromJSON)),
        'stillList': ((json['still_list'] as Array<any>).map(ShopCombinedsaleItemStillFromJSON)),
    };
}

export function ShopCombinedsaleItemToJSON(value?: ShopCombinedsaleItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'combinedsale_item_cd': value.combinedsaleItemCd,
        'item_cd': value.itemCd,
        'item_name': value.itemName,
        'resource_item_cd': value.resourceItemCd,
        'resource_partition': value.resourcePartition,
        'shop_item_cd': value.shopItemCd,
        'one_time_limit_count': value.oneTimeLimitCount,
        'description': value.description,
        'gem_price': value.gemPrice,
        'undiscounted_gem_price': value.undiscountedGemPrice,
        'purchased_only_flg': value.purchasedOnlyFlg,
        'order_no': value.orderNo,
        'limit_time': value.limitTime,
        'present_list': ((value.presentList as Array<any>).map(ShopCombinedsaleItemPresentToJSON)),
        'special_story_list': ((value.specialStoryList as Array<any>).map(ShopCombinedsaleItemSpecialStoryToJSON)),
        'still_list': ((value.stillList as Array<any>).map(ShopCombinedsaleItemStillToJSON)),
    };
}

