/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCommuChara
 */
export interface UserCommuChara {
    /**
     * キャラコード
     * @type {string}
     * @memberof UserCommuChara
     */
    charaCd: string;
    /**
     * コミュレベル
     * @type {number}
     * @memberof UserCommuChara
     */
    level: number;
    /**
     * コミュレベルアップ表示フラグ 1:表示 0:非表示
     * @type {boolean}
     * @memberof UserCommuChara
     */
    levelUpDispFlg: boolean;
}

/**
 * Check if a given object implements the UserCommuChara interface.
 */
export function instanceOfUserCommuChara(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "levelUpDispFlg" in value;

    return isInstance;
}

export function UserCommuCharaFromJSON(json: any): UserCommuChara {
    return UserCommuCharaFromJSONTyped(json, false);
}

export function UserCommuCharaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCommuChara {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'level': json['level'],
        'levelUpDispFlg': json['level_up_disp_flg'],
    };
}

export function UserCommuCharaToJSON(value?: UserCommuChara | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'level': value.level,
        'level_up_disp_flg': value.levelUpDispFlg,
    };
}

