/**
 * ユーザーのお財布情報
 */
export interface AnimateWallet {
  coin: number,
  paymentUrl: string,
}

// キャッシュする時間
const cacheTime = 60000;
// 前回のキャッシュ時間(ms)
let cacheSetTime = 0;

interface UpdateOption {
  cache: boolean,
}
/**
 * データの更新
 */
const update = async (options?: UpdateOption) => {
  // キャッシュ有効な場合はリクエストさせない
  if (options?.cache && useAnimateWallet().wallet.value && (cacheSetTime + cacheTime) >= Date.now()) {
    return useAnimateWallet().wallet.value;
  }
  const response = (await useApi().user.getAnimateWallet()).data;
  useAnimateWallet().wallet.value = {
    coin: response.coin,
    paymentUrl: response.paymentUrl,
  };
  cacheSetTime = Date.now();
  return useAnimateWallet().wallet.value;
};
/**
 * 画面間で共通で使用する基本財布情報を保存しておく
 */
export const useAnimateWallet = () => {
  const wallet = useState<AnimateWallet>('animate_wallet', () => {
    return {
      coin: 0,
      paymentUrl: '',
    };
  });
  return {
    wallet,
    update,
  };
};
