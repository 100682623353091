/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserStatusMin
 */
export interface UserStatusMin {
    /**
     * 攻略対象キャラコード
     * @type {string}
     * @memberof UserStatusMin
     */
    charaCd: string;
    /**
     * ゲーム内通貨
     * @type {number}
     * @memberof UserStatusMin
     */
    gold: number;
    /**
     * 課金通貨(無料分 + 有料分)
     * @type {number}
     * @memberof UserStatusMin
     */
    gem: number;
    /**
     * 課金通貨(無料分)
     * @type {number}
     * @memberof UserStatusMin
     */
    freeGem: number;
    /**
     * 課金通貨(有料分)
     * @type {number}
     * @memberof UserStatusMin
     */
    purchasedGem: number;
    /**
     * ミニゲームポイント
     * @type {number}
     * @memberof UserStatusMin
     */
    minigameP: number;
    /**
     * 直近にクリアしたパラメータミッションの必要パラメータ
     * @type {number}
     * @memberof UserStatusMin
     */
    missionMinigamePBase: number;
    /**
     * アバターポイント
     * @type {number}
     * @memberof UserStatusMin
     */
    avatarP: number;
    /**
     * スタミナ
     * @type {number}
     * @memberof UserStatusMin
     */
    staminaP: number;
    /**
     * 残チケット（デフォルト枚数を超えていた場合はデフォルト枚数を返す）
     * @type {number}
     * @memberof UserStatusMin
     */
    remainTickets: number;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserStatusMin
     */
    playerCode: string;
    /**
     * 苗字
     * @type {string}
     * @memberof UserStatusMin
     */
    lastName: string;
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof UserStatusMin
     */
    nickname: string;
    /**
     * ユーザーの誕生日
     * @type {string}
     * @memberof UserStatusMin
     */
    birthday: string;
    /**
     * チュートリアルCD
     * @type {string}
     * @memberof UserStatusMin
     */
    tutorialCd: string;
}

/**
 * Check if a given object implements the UserStatusMin interface.
 */
export function instanceOfUserStatusMin(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "gold" in value;
    isInstance = isInstance && "gem" in value;
    isInstance = isInstance && "freeGem" in value;
    isInstance = isInstance && "purchasedGem" in value;
    isInstance = isInstance && "minigameP" in value;
    isInstance = isInstance && "missionMinigamePBase" in value;
    isInstance = isInstance && "avatarP" in value;
    isInstance = isInstance && "staminaP" in value;
    isInstance = isInstance && "remainTickets" in value;
    isInstance = isInstance && "playerCode" in value;
    isInstance = isInstance && "lastName" in value;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "birthday" in value;
    isInstance = isInstance && "tutorialCd" in value;

    return isInstance;
}

export function UserStatusMinFromJSON(json: any): UserStatusMin {
    return UserStatusMinFromJSONTyped(json, false);
}

export function UserStatusMinFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserStatusMin {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'gold': json['gold'],
        'gem': json['gem'],
        'freeGem': json['free_gem'],
        'purchasedGem': json['purchased_gem'],
        'minigameP': json['minigame_p'],
        'missionMinigamePBase': json['mission_minigame_p_base'],
        'avatarP': json['avatar_p'],
        'staminaP': json['stamina_p'],
        'remainTickets': json['remain_tickets'],
        'playerCode': json['player_code'],
        'lastName': json['last_name'],
        'nickname': json['nickname'],
        'birthday': json['birthday'],
        'tutorialCd': json['tutorial_cd'],
    };
}

export function UserStatusMinToJSON(value?: UserStatusMin | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'gold': value.gold,
        'gem': value.gem,
        'free_gem': value.freeGem,
        'purchased_gem': value.purchasedGem,
        'minigame_p': value.minigameP,
        'mission_minigame_p_base': value.missionMinigamePBase,
        'avatar_p': value.avatarP,
        'stamina_p': value.staminaP,
        'remain_tickets': value.remainTickets,
        'player_code': value.playerCode,
        'last_name': value.lastName,
        'nickname': value.nickname,
        'birthday': value.birthday,
        'tutorial_cd': value.tutorialCd,
    };
}

