/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendList } from './UserFriendList';
import {
    UserFriendListFromJSON,
    UserFriendListFromJSONTyped,
    UserFriendListToJSON,
} from './UserFriendList';

/**
 * 
 * @export
 * @interface UserFriendFollowerListResponseData
 */
export interface UserFriendFollowerListResponseData {
    /**
     * フォロワー上限
     * @type {number}
     * @memberof UserFriendFollowerListResponseData
     */
    maxFollowerCount: number;
    /**
     * フォロワーリスト
     * @type {Array<UserFriendList>}
     * @memberof UserFriendFollowerListResponseData
     */
    friendList: Array<UserFriendList>;
}

/**
 * Check if a given object implements the UserFriendFollowerListResponseData interface.
 */
export function instanceOfUserFriendFollowerListResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "maxFollowerCount" in value;
    isInstance = isInstance && "friendList" in value;

    return isInstance;
}

export function UserFriendFollowerListResponseDataFromJSON(json: any): UserFriendFollowerListResponseData {
    return UserFriendFollowerListResponseDataFromJSONTyped(json, false);
}

export function UserFriendFollowerListResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendFollowerListResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'maxFollowerCount': json['max_follower_count'],
        'friendList': ((json['friend_list'] as Array<any>).map(UserFriendListFromJSON)),
    };
}

export function UserFriendFollowerListResponseDataToJSON(value?: UserFriendFollowerListResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'max_follower_count': value.maxFollowerCount,
        'friend_list': ((value.friendList as Array<any>).map(UserFriendListToJSON)),
    };
}

