export const appConfig = {
  appName: 'モルガナティック・アイドル',
};

export const shopVerifyAgeConfig = {
  // 年齢確認認証の年齢
  confirmAge: 20,
};

// バイナリバージョン情報
export const binaryConfig = {
  uri: {
    ios: '/BINARY.app.json',
    android: '/BINARY.gl.json',
  },
};
