/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AnimateWalletResponseData } from './AnimateWalletResponseData';
import {
    AnimateWalletResponseDataFromJSON,
    AnimateWalletResponseDataFromJSONTyped,
    AnimateWalletResponseDataToJSON,
} from './AnimateWalletResponseData';

/**
 * 
 * @export
 * @interface AnimateWalletResponse
 */
export interface AnimateWalletResponse {
    /**
     * 
     * @type {AnimateWalletResponseData}
     * @memberof AnimateWalletResponse
     */
    data: AnimateWalletResponseData;
}

/**
 * Check if a given object implements the AnimateWalletResponse interface.
 */
export function instanceOfAnimateWalletResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function AnimateWalletResponseFromJSON(json: any): AnimateWalletResponse {
    return AnimateWalletResponseFromJSONTyped(json, false);
}

export function AnimateWalletResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnimateWalletResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': AnimateWalletResponseDataFromJSON(json['data']),
    };
}

export function AnimateWalletResponseToJSON(value?: AnimateWalletResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': AnimateWalletResponseDataToJSON(value.data),
    };
}

