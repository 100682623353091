/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpecialStoryPresent } from './SpecialStoryPresent';
import {
    SpecialStoryPresentFromJSON,
    SpecialStoryPresentFromJSONTyped,
    SpecialStoryPresentToJSON,
} from './SpecialStoryPresent';
import type { SpecialStoryVoice } from './SpecialStoryVoice';
import {
    SpecialStoryVoiceFromJSON,
    SpecialStoryVoiceFromJSONTyped,
    SpecialStoryVoiceToJSON,
} from './SpecialStoryVoice';

/**
 * 
 * @export
 * @interface SpecialStorySpecialStory
 */
export interface SpecialStorySpecialStory {
    /**
     * スペシャルストーリーコード
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    specialStoryCd: string;
    /**
     * チャプターコード
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    chapterCd: string;
    /**
     * ルート
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    route: string;
    /**
     * チケットコード
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    ticketCd: string;
    /**
     * キャラコード
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    charaCd: string;
    /**
     * プレゼント
     * @type {Array<SpecialStoryPresent>}
     * @memberof SpecialStorySpecialStory
     */
    presentList: Array<SpecialStoryPresent>;
    /**
     * ストーリータイプ
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    type: string;
    /**
     * タイトル
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    title: string;
    /**
     * チケットコード
     * @type {number}
     * @memberof SpecialStorySpecialStory
     */
    orderNo: number;
    /**
     * story_chapter_mst の order_no
     * @type {number}
     * @memberof SpecialStorySpecialStory
     */
    chapterOrderNo: number;
    /**
     * 入手場所
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    getSituation: string;
    /**
     * 説明
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    description: string;
    /**
     * 
     * @type {SpecialStoryVoice}
     * @memberof SpecialStorySpecialStory
     */
    voice: SpecialStoryVoice;
    /**
     * 開始日時
     * @type {number}
     * @memberof SpecialStorySpecialStory
     */
    startedAt: number;
    /**
     * 終了日時
     * @type {string}
     * @memberof SpecialStorySpecialStory
     */
    endedAt: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof SpecialStorySpecialStory
     */
    specialStoryList: Array<object>;
}

/**
 * Check if a given object implements the SpecialStorySpecialStory interface.
 */
export function instanceOfSpecialStorySpecialStory(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "specialStoryCd" in value;
    isInstance = isInstance && "chapterCd" in value;
    isInstance = isInstance && "route" in value;
    isInstance = isInstance && "ticketCd" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "presentList" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "chapterOrderNo" in value;
    isInstance = isInstance && "getSituation" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "voice" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "specialStoryList" in value;

    return isInstance;
}

export function SpecialStorySpecialStoryFromJSON(json: any): SpecialStorySpecialStory {
    return SpecialStorySpecialStoryFromJSONTyped(json, false);
}

export function SpecialStorySpecialStoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialStorySpecialStory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'specialStoryCd': json['special_story_cd'],
        'chapterCd': json['chapter_cd'],
        'route': json['route'],
        'ticketCd': json['ticket_cd'],
        'charaCd': json['chara_cd'],
        'presentList': ((json['present_list'] as Array<any>).map(SpecialStoryPresentFromJSON)),
        'type': json['type'],
        'title': json['title'],
        'orderNo': json['order_no'],
        'chapterOrderNo': json['chapter_order_no'],
        'getSituation': json['get_situation'],
        'description': json['description'],
        'voice': SpecialStoryVoiceFromJSON(json['voice']),
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'specialStoryList': json['special_story_list'],
    };
}

export function SpecialStorySpecialStoryToJSON(value?: SpecialStorySpecialStory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'special_story_cd': value.specialStoryCd,
        'chapter_cd': value.chapterCd,
        'route': value.route,
        'ticket_cd': value.ticketCd,
        'chara_cd': value.charaCd,
        'present_list': ((value.presentList as Array<any>).map(SpecialStoryPresentToJSON)),
        'type': value.type,
        'title': value.title,
        'order_no': value.orderNo,
        'chapter_order_no': value.chapterOrderNo,
        'get_situation': value.getSituation,
        'description': value.description,
        'voice': SpecialStoryVoiceToJSON(value.voice),
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'special_story_list': value.specialStoryList,
    };
}

