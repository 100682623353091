/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BillingProductBuyResult } from './BillingProductBuyResult';
import {
    BillingProductBuyResultFromJSON,
    BillingProductBuyResultFromJSONTyped,
    BillingProductBuyResultToJSON,
} from './BillingProductBuyResult';
import type { ShopProductBuyResponseDataUserWallet } from './ShopProductBuyResponseDataUserWallet';
import {
    ShopProductBuyResponseDataUserWalletFromJSON,
    ShopProductBuyResponseDataUserWalletFromJSONTyped,
    ShopProductBuyResponseDataUserWalletToJSON,
} from './ShopProductBuyResponseDataUserWallet';

/**
 * 
 * @export
 * @interface ShopProductBuyResponseData
 */
export interface ShopProductBuyResponseData {
    /**
     * 
     * @type {BillingProductBuyResult}
     * @memberof ShopProductBuyResponseData
     */
    result: BillingProductBuyResult;
    /**
     * メッセージ内容
     * @type {string}
     * @memberof ShopProductBuyResponseData
     */
    message: string;
    /**
     * 
     * @type {ShopProductBuyResponseDataUserWallet}
     * @memberof ShopProductBuyResponseData
     */
    userWallet: ShopProductBuyResponseDataUserWallet;
}

/**
 * Check if a given object implements the ShopProductBuyResponseData interface.
 */
export function instanceOfShopProductBuyResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "userWallet" in value;

    return isInstance;
}

export function ShopProductBuyResponseDataFromJSON(json: any): ShopProductBuyResponseData {
    return ShopProductBuyResponseDataFromJSONTyped(json, false);
}

export function ShopProductBuyResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopProductBuyResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': BillingProductBuyResultFromJSON(json['result']),
        'message': json['message'],
        'userWallet': ShopProductBuyResponseDataUserWalletFromJSON(json['user_wallet']),
    };
}

export function ShopProductBuyResponseDataToJSON(value?: ShopProductBuyResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': BillingProductBuyResultToJSON(value.result),
        'message': value.message,
        'user_wallet': ShopProductBuyResponseDataUserWalletToJSON(value.userWallet),
    };
}

