/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuLevel } from './CommuLevel';
import {
    CommuLevelFromJSON,
    CommuLevelFromJSONTyped,
    CommuLevelToJSON,
} from './CommuLevel';

/**
 * 
 * @export
 * @interface CommuLevelPattern
 */
export interface CommuLevelPattern {
    /**
     * レベルパターンコード
     * @type {string}
     * @memberof CommuLevelPattern
     */
    levelPatternCd: string;
    /**
     * レベルリスト
     * @type {Array<CommuLevel>}
     * @memberof CommuLevelPattern
     */
    levelList: Array<CommuLevel>;
}

/**
 * Check if a given object implements the CommuLevelPattern interface.
 */
export function instanceOfCommuLevelPattern(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "levelPatternCd" in value;
    isInstance = isInstance && "levelList" in value;

    return isInstance;
}

export function CommuLevelPatternFromJSON(json: any): CommuLevelPattern {
    return CommuLevelPatternFromJSONTyped(json, false);
}

export function CommuLevelPatternFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuLevelPattern {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'levelPatternCd': json['level_pattern_cd'],
        'levelList': ((json['level_list'] as Array<any>).map(CommuLevelFromJSON)),
    };
}

export function CommuLevelPatternToJSON(value?: CommuLevelPattern | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'level_pattern_cd': value.levelPatternCd,
        'level_list': ((value.levelList as Array<any>).map(CommuLevelToJSON)),
    };
}

