/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';
import type { UserSpecialStoryList } from './UserSpecialStoryList';
import {
    UserSpecialStoryListFromJSON,
    UserSpecialStoryListFromJSONTyped,
    UserSpecialStoryListToJSON,
} from './UserSpecialStoryList';

/**
 * 
 * @export
 * @interface UserSpecialStoryListResponse
 */
export interface UserSpecialStoryListResponse {
    /**
     * 
     * @type {Array<UserSpecialStoryList>}
     * @memberof UserSpecialStoryListResponse
     */
    data: Array<UserSpecialStoryList>;
    /**
     * 
     * @type {MetaCommon}
     * @memberof UserSpecialStoryListResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the UserSpecialStoryListResponse interface.
 */
export function instanceOfUserSpecialStoryListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function UserSpecialStoryListResponseFromJSON(json: any): UserSpecialStoryListResponse {
    return UserSpecialStoryListResponseFromJSONTyped(json, false);
}

export function UserSpecialStoryListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSpecialStoryListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserSpecialStoryListFromJSON)),
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function UserSpecialStoryListResponseToJSON(value?: UserSpecialStoryListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserSpecialStoryListToJSON)),
        'meta': MetaCommonToJSON(value.meta),
    };
}

