/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMypage
 */
export interface UserMypage {
    /**
     * マイページで表示するカードコード
     * @type {string}
     * @memberof UserMypage
     */
    cardCd: string;
    /**
     * 特別ショップ有無フラグ(0:特別ショップなし 1:特別ショップあり)
     * @type {boolean}
     * @memberof UserMypage
     */
    isSpecialSale: boolean;
    /**
     * 残り時間(秒)
     * @type {number}
     * @memberof UserMypage
     */
    remainTime: number | null;
    /**
     * バナー押下時の遷移情報
     * @type {string}
     * @memberof UserMypage
     */
    url: string;
    /**
     * 課金ショップ訴求バナーCD
     * @type {string}
     * @memberof UserMypage
     */
    billingBannerCd: string;
    /**
     * セールの残り時間(秒)
     * @type {number}
     * @memberof UserMypage
     */
    billingRemainTime: number;
    /**
     * ストーリー既読フラグ(0:未読 1:既読)
     * @type {boolean}
     * @memberof UserMypage
     */
    isStoryRead: boolean;
}

/**
 * Check if a given object implements the UserMypage interface.
 */
export function instanceOfUserMypage(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "isSpecialSale" in value;
    isInstance = isInstance && "remainTime" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "billingBannerCd" in value;
    isInstance = isInstance && "billingRemainTime" in value;
    isInstance = isInstance && "isStoryRead" in value;

    return isInstance;
}

export function UserMypageFromJSON(json: any): UserMypage {
    return UserMypageFromJSONTyped(json, false);
}

export function UserMypageFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMypage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cardCd': json['card_cd'],
        'isSpecialSale': json['is_special_sale'],
        'remainTime': json['remain_time'],
        'url': json['url'],
        'billingBannerCd': json['billing_banner_cd'],
        'billingRemainTime': json['billing_remain_time'],
        'isStoryRead': json['is_story_read'],
    };
}

export function UserMypageToJSON(value?: UserMypage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'card_cd': value.cardCd,
        'is_special_sale': value.isSpecialSale,
        'remain_time': value.remainTime,
        'url': value.url,
        'billing_banner_cd': value.billingBannerCd,
        'billing_remain_time': value.billingRemainTime,
        'is_story_read': value.isStoryRead,
    };
}

