/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPresent
 */
export interface UserPresent {
    /**
     * プレゼントアイテムコード
     * @type {string}
     * @memberof UserPresent
     */
    presentItemCd: string;
    /**
     * ユーザープレゼントのID
     * @type {number}
     * @memberof UserPresent
     */
    userPresentId: number;
    /**
     * プレゼント取得時間
     * @type {string}
     * @memberof UserPresent
     */
    addPresentTime: string;
    /**
     * 受取期限
     * @type {string}
     * @memberof UserPresent
     */
    acceptLimitTime: string;
}

/**
 * Check if a given object implements the UserPresent interface.
 */
export function instanceOfUserPresent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "presentItemCd" in value;
    isInstance = isInstance && "userPresentId" in value;
    isInstance = isInstance && "addPresentTime" in value;
    isInstance = isInstance && "acceptLimitTime" in value;

    return isInstance;
}

export function UserPresentFromJSON(json: any): UserPresent {
    return UserPresentFromJSONTyped(json, false);
}

export function UserPresentFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'presentItemCd': json['present_item_cd'],
        'userPresentId': json['user_present_id'],
        'addPresentTime': json['add_present_time'],
        'acceptLimitTime': json['accept_limit_time'],
    };
}

export function UserPresentToJSON(value?: UserPresent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'present_item_cd': value.presentItemCd,
        'user_present_id': value.userPresentId,
        'add_present_time': value.addPresentTime,
        'accept_limit_time': value.acceptLimitTime,
    };
}

