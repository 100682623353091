/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserGachaLineup } from './UserGachaLineup';
import {
    UserGachaLineupFromJSON,
    UserGachaLineupFromJSONTyped,
    UserGachaLineupToJSON,
} from './UserGachaLineup';

/**
 * 
 * @export
 * @interface UserGachaLineupRarityProbability
 */
export interface UserGachaLineupRarityProbability {
    /**
     * 
     * @type {UserGachaLineup}
     * @memberof UserGachaLineupRarityProbability
     */
    normal: UserGachaLineup;
    /**
     * 
     * @type {UserGachaLineup}
     * @memberof UserGachaLineupRarityProbability
     */
    over4: UserGachaLineup;
    /**
     * 
     * @type {UserGachaLineup}
     * @memberof UserGachaLineupRarityProbability
     */
    over5: UserGachaLineup;
}

/**
 * Check if a given object implements the UserGachaLineupRarityProbability interface.
 */
export function instanceOfUserGachaLineupRarityProbability(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "normal" in value;
    isInstance = isInstance && "over4" in value;
    isInstance = isInstance && "over5" in value;

    return isInstance;
}

export function UserGachaLineupRarityProbabilityFromJSON(json: any): UserGachaLineupRarityProbability {
    return UserGachaLineupRarityProbabilityFromJSONTyped(json, false);
}

export function UserGachaLineupRarityProbabilityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserGachaLineupRarityProbability {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'normal': UserGachaLineupFromJSON(json['normal']),
        'over4': UserGachaLineupFromJSON(json['over4']),
        'over5': UserGachaLineupFromJSON(json['over5']),
    };
}

export function UserGachaLineupRarityProbabilityToJSON(value?: UserGachaLineupRarityProbability | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'normal': UserGachaLineupToJSON(value.normal),
        'over4': UserGachaLineupToJSON(value.over4),
        'over5': UserGachaLineupToJSON(value.over5),
    };
}

