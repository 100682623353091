/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * ミッションに必要なパラメータ値
 * @export
 * @interface EventStoryListRequiredMissionParameter
 */
export interface EventStoryListRequiredMissionParameter {
    /**
     * スペシャルルートに必要なパラメータ値
     * @type {number}
     * @memberof EventStoryListRequiredMissionParameter
     */
    routeA: number;
    /**
     * プレミアルートに必要なパラメータ値
     * @type {number}
     * @memberof EventStoryListRequiredMissionParameter
     */
    routeB: number;
    /**
     * シークレットルートに必要なパラメータ値
     * @type {number}
     * @memberof EventStoryListRequiredMissionParameter
     */
    routeC: number;
    /**
     * 討伐度ミッションクリアに必要なパラメータ
     * @type {number}
     * @memberof EventStoryListRequiredMissionParameter
     */
    parameter: number;
}

/**
 * Check if a given object implements the EventStoryListRequiredMissionParameter interface.
 */
export function instanceOfEventStoryListRequiredMissionParameter(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "routeA" in value;
    isInstance = isInstance && "routeB" in value;
    isInstance = isInstance && "routeC" in value;
    isInstance = isInstance && "parameter" in value;

    return isInstance;
}

export function EventStoryListRequiredMissionParameterFromJSON(json: any): EventStoryListRequiredMissionParameter {
    return EventStoryListRequiredMissionParameterFromJSONTyped(json, false);
}

export function EventStoryListRequiredMissionParameterFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventStoryListRequiredMissionParameter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'routeA': json['route_a'],
        'routeB': json['route_b'],
        'routeC': json['route_c'],
        'parameter': json['parameter'],
    };
}

export function EventStoryListRequiredMissionParameterToJSON(value?: EventStoryListRequiredMissionParameter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'route_a': value.routeA,
        'route_b': value.routeB,
        'route_c': value.routeC,
        'parameter': value.parameter,
    };
}

