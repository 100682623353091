/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CharaSaveRequest
 */
export interface CharaSaveRequest {
    /**
     * キャラコード
     * @type {string}
     * @memberof CharaSaveRequest
     */
    charaCd: string;
    /**
     * リセットフラグ 1:進捗度リセット 0:続きから
     * @type {boolean}
     * @memberof CharaSaveRequest
     */
    resetFlg?: boolean;
}

/**
 * Check if a given object implements the CharaSaveRequest interface.
 */
export function instanceOfCharaSaveRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;

    return isInstance;
}

export function CharaSaveRequestFromJSON(json: any): CharaSaveRequest {
    return CharaSaveRequestFromJSONTyped(json, false);
}

export function CharaSaveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CharaSaveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
        'resetFlg': !exists(json, 'reset_flg') ? undefined : json['reset_flg'],
    };
}

export function CharaSaveRequestToJSON(value?: CharaSaveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
        'reset_flg': value.resetFlg,
    };
}

