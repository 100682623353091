/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * セット販売施策種別
 * 
 * * 'nml': 通常
 * * 'new': 新人セット
 * * 'gacha': ガチャイベント
 * * 'event': シナリオイベント
 * * 'scenariosale': シナリオ販売
 * * 'collect': 本編促進
 * * 'storycomp': 本編クリア後セット
 * * 'rookie': 新人シナリオイベント
 * * 'my_birthday': マイバースデーシナリオ販売
 * @export
 */
export const ShopSaleMstType = {
    Normal: 'normal',
    New: 'new',
    Gacha: 'gacha',
    Event: 'event',
    Scenariosale: 'scenariosale',
    Collect: 'collect',
    Storycomp: 'storycomp',
    Rookie: 'rookie',
    MyBirthday: 'my_birthday'
} as const;
export type ShopSaleMstType = typeof ShopSaleMstType[keyof typeof ShopSaleMstType];


export function ShopSaleMstTypeFromJSON(json: any): ShopSaleMstType {
    return ShopSaleMstTypeFromJSONTyped(json, false);
}

export function ShopSaleMstTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopSaleMstType {
    return json as ShopSaleMstType;
}

export function ShopSaleMstTypeToJSON(value?: ShopSaleMstType | null): any {
    return value as any;
}

