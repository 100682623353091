/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopCombinedsaleItemStill
 */
export interface ShopCombinedsaleItemStill {
    /**
     * スチルコード
     * @type {string}
     * @memberof ShopCombinedsaleItemStill
     */
    stillCd: string;
    /**
     * クライアント側で利用する表示順序(0に近いほうが優先的に扱われる)
     * @type {number}
     * @memberof ShopCombinedsaleItemStill
     */
    orderNo: number;
    /**
     * 商品説明
     * @type {string}
     * @memberof ShopCombinedsaleItemStill
     */
    description: string;
}

/**
 * Check if a given object implements the ShopCombinedsaleItemStill interface.
 */
export function instanceOfShopCombinedsaleItemStill(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "stillCd" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function ShopCombinedsaleItemStillFromJSON(json: any): ShopCombinedsaleItemStill {
    return ShopCombinedsaleItemStillFromJSONTyped(json, false);
}

export function ShopCombinedsaleItemStillFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopCombinedsaleItemStill {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stillCd': json['still_cd'],
        'orderNo': json['order_no'],
        'description': json['description'],
    };
}

export function ShopCombinedsaleItemStillToJSON(value?: ShopCombinedsaleItemStill | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'still_cd': value.stillCd,
        'order_no': value.orderNo,
        'description': value.description,
    };
}

