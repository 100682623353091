/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopProductBuyResponseData } from './ShopProductBuyResponseData';
import {
    ShopProductBuyResponseDataFromJSON,
    ShopProductBuyResponseDataFromJSONTyped,
    ShopProductBuyResponseDataToJSON,
} from './ShopProductBuyResponseData';

/**
 * 
 * @export
 * @interface ShopProductBuyResponse
 */
export interface ShopProductBuyResponse {
    /**
     * 
     * @type {ShopProductBuyResponseData}
     * @memberof ShopProductBuyResponse
     */
    data: ShopProductBuyResponseData;
}

/**
 * Check if a given object implements the ShopProductBuyResponse interface.
 */
export function instanceOfShopProductBuyResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function ShopProductBuyResponseFromJSON(json: any): ShopProductBuyResponse {
    return ShopProductBuyResponseFromJSONTyped(json, false);
}

export function ShopProductBuyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopProductBuyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ShopProductBuyResponseDataFromJSON(json['data']),
    };
}

export function ShopProductBuyResponseToJSON(value?: ShopProductBuyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ShopProductBuyResponseDataToJSON(value.data),
    };
}

