/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PanelmissionNotice } from './PanelmissionNotice';
import {
    PanelmissionNoticeFromJSON,
    PanelmissionNoticeFromJSONTyped,
    PanelmissionNoticeToJSON,
} from './PanelmissionNotice';

/**
 * 
 * @export
 * @interface PanelmissionNoticeResponse
 */
export interface PanelmissionNoticeResponse {
    /**
     * 
     * @type {Array<PanelmissionNotice>}
     * @memberof PanelmissionNoticeResponse
     */
    data: Array<PanelmissionNotice>;
}

/**
 * Check if a given object implements the PanelmissionNoticeResponse interface.
 */
export function instanceOfPanelmissionNoticeResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function PanelmissionNoticeResponseFromJSON(json: any): PanelmissionNoticeResponse {
    return PanelmissionNoticeResponseFromJSONTyped(json, false);
}

export function PanelmissionNoticeResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionNoticeResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PanelmissionNoticeFromJSON)),
    };
}

export function PanelmissionNoticeResponseToJSON(value?: PanelmissionNoticeResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(PanelmissionNoticeToJSON)),
    };
}

