<template>
  <ModalAlert
    :show="props.show"
    title="イベントは終了しました"
    size="md"
    :is-enable-ok="false"
    :close-button="true"
    :z-index="props.zIndex"
    @close="onClick"
  >
    <div class="py-2 px-3 -mx-2 h-full flex flex-col justify-center">
      <div>
        このイベントは終了しました<br>
        ホームに移動します
      </div>
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});
interface Emits {
  (e: 'click'): void;
}
const emit = defineEmits<Emits>();
const onClick = () => {
  emit('click');
};
</script>
