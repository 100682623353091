/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShopItemBuyResponseData
 */
export interface ShopItemBuyResponseData {
    /**
     * 購入後のジェム保有数
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    gem: number;
    /**
     * 購入後の無償ジェム
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    freeGem: number;
    /**
     * 購入後の有償ジェム
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    purchasedGem: number;
    /**
     * 購入後のゲソコイン(取得できなければ-1、ゴールドで購入時はサーバ側でアニメイトAPI実行しないのでnullが返ってくる)
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    gesoCoin: number | null;
    /**
     * ゴールド
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    gold: number;
    /**
     * 購入したアイテムのアイテムコード
     * @type {string}
     * @memberof ShopItemBuyResponseData
     */
    itemCd: string;
    /**
     * 購入後のアイテム所持数。アバター保存枠を購入したときは常に0のはず
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    itemCount: number;
    /**
     * 購入後のユーザアバターID。アバターを購入した時以外は-1で返す。(購入後即着替えページへ遷移させたい時に利用する)
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    userAvatarId: number;
    /**
     * 購入後のアバターポイント
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    avatarP: number;
    /**
     * 購入後のミニゲームポイント
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    minigameP: number;
    /**
     * 購入後のスタミナ値
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    staminaP: number;
    /**
     * 購入後の好感度A
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    loveP1: number;
    /**
     * 購入後の好感度B
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    loveP2: number;
    /**
     * 購入後のクローゼット所持数(アバター所持枠数)
     * @type {number}
     * @memberof ShopItemBuyResponseData
     */
    roomAvatarMax: number;
}

/**
 * Check if a given object implements the ShopItemBuyResponseData interface.
 */
export function instanceOfShopItemBuyResponseData(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "gem" in value;
    isInstance = isInstance && "freeGem" in value;
    isInstance = isInstance && "purchasedGem" in value;
    isInstance = isInstance && "gesoCoin" in value;
    isInstance = isInstance && "gold" in value;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemCount" in value;
    isInstance = isInstance && "userAvatarId" in value;
    isInstance = isInstance && "avatarP" in value;
    isInstance = isInstance && "minigameP" in value;
    isInstance = isInstance && "staminaP" in value;
    isInstance = isInstance && "loveP1" in value;
    isInstance = isInstance && "loveP2" in value;
    isInstance = isInstance && "roomAvatarMax" in value;

    return isInstance;
}

export function ShopItemBuyResponseDataFromJSON(json: any): ShopItemBuyResponseData {
    return ShopItemBuyResponseDataFromJSONTyped(json, false);
}

export function ShopItemBuyResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItemBuyResponseData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gem': json['gem'],
        'freeGem': json['free_gem'],
        'purchasedGem': json['purchased_gem'],
        'gesoCoin': json['geso_coin'],
        'gold': json['gold'],
        'itemCd': json['item_cd'],
        'itemCount': json['item_count'],
        'userAvatarId': json['user_avatar_id'],
        'avatarP': json['avatar_p'],
        'minigameP': json['minigame_p'],
        'staminaP': json['stamina_p'],
        'loveP1': json['love_p1'],
        'loveP2': json['love_p2'],
        'roomAvatarMax': json['room_avatar_max'],
    };
}

export function ShopItemBuyResponseDataToJSON(value?: ShopItemBuyResponseData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gem': value.gem,
        'free_gem': value.freeGem,
        'purchased_gem': value.purchasedGem,
        'geso_coin': value.gesoCoin,
        'gold': value.gold,
        'item_cd': value.itemCd,
        'item_count': value.itemCount,
        'user_avatar_id': value.userAvatarId,
        'avatar_p': value.avatarP,
        'minigame_p': value.minigameP,
        'stamina_p': value.staminaP,
        'love_p1': value.loveP1,
        'love_p2': value.loveP2,
        'room_avatar_max': value.roomAvatarMax,
    };
}

