/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserMinigameHistoryHistoriesInner
 */
export interface UserMinigameHistoryHistoriesInner {
    /**
     * ミニゲーム実施日時
     * @type {string}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    playedAt: string;
    /**
     * キャラCD
     * @type {string}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    charaCd: string;
    /**
     * 加算したゴールド
     * @type {number}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    addGold: number;
    /**
     * ミニゲーム実行後の所持ゴールド
     * @type {number}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    afterGold: number;
    /**
     * 加算したミニゲームポイント
     * @type {number}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    addMinigameP: number;
    /**
     * ミニゲーム実行後のミニゲームポイント
     * @type {number}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    afterMinigameP: number;
    /**
     * ミニゲーム実行後のスタミナポイント
     * @type {number}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    consumedStaminaP: number;
    /**
     * 消費スタミナポイント
     * @type {number}
     * @memberof UserMinigameHistoryHistoriesInner
     */
    afterStaminaP: number;
}

/**
 * Check if a given object implements the UserMinigameHistoryHistoriesInner interface.
 */
export function instanceOfUserMinigameHistoryHistoriesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "playedAt" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "addGold" in value;
    isInstance = isInstance && "afterGold" in value;
    isInstance = isInstance && "addMinigameP" in value;
    isInstance = isInstance && "afterMinigameP" in value;
    isInstance = isInstance && "consumedStaminaP" in value;
    isInstance = isInstance && "afterStaminaP" in value;

    return isInstance;
}

export function UserMinigameHistoryHistoriesInnerFromJSON(json: any): UserMinigameHistoryHistoriesInner {
    return UserMinigameHistoryHistoriesInnerFromJSONTyped(json, false);
}

export function UserMinigameHistoryHistoriesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMinigameHistoryHistoriesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playedAt': json['played_at'],
        'charaCd': json['chara_cd'],
        'addGold': json['add_gold'],
        'afterGold': json['after_gold'],
        'addMinigameP': json['add_minigame_p'],
        'afterMinigameP': json['after_minigame_p'],
        'consumedStaminaP': json['consumed_stamina_p'],
        'afterStaminaP': json['after_stamina_p'],
    };
}

export function UserMinigameHistoryHistoriesInnerToJSON(value?: UserMinigameHistoryHistoriesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'played_at': value.playedAt,
        'chara_cd': value.charaCd,
        'add_gold': value.addGold,
        'after_gold': value.afterGold,
        'add_minigame_p': value.addMinigameP,
        'after_minigame_p': value.afterMinigameP,
        'consumed_stamina_p': value.consumedStaminaP,
        'after_stamina_p': value.afterStaminaP,
    };
}

