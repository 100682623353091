/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FriendMessageSendRequest
 */
export interface FriendMessageSendRequest {
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof FriendMessageSendRequest
     */
    playerCode?: string;
    /**
     * 送りたいメッセージの内容
     * @type {string}
     * @memberof FriendMessageSendRequest
     */
    message?: string;
    /**
     * ユーザーソーシャルID
     * @type {number}
     * @memberof FriendMessageSendRequest
     */
    userSocialId?: number;
}

/**
 * Check if a given object implements the FriendMessageSendRequest interface.
 */
export function instanceOfFriendMessageSendRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FriendMessageSendRequestFromJSON(json: any): FriendMessageSendRequest {
    return FriendMessageSendRequestFromJSONTyped(json, false);
}

export function FriendMessageSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendMessageSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'playerCode': !exists(json, 'player_code') ? undefined : json['player_code'],
        'message': !exists(json, 'message') ? undefined : json['message'],
        'userSocialId': !exists(json, 'user_social_id') ? undefined : json['user_social_id'],
    };
}

export function FriendMessageSendRequestToJSON(value?: FriendMessageSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'player_code': value.playerCode,
        'message': value.message,
        'user_social_id': value.userSocialId,
    };
}

