/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SerialCodeCampaignBonus } from './SerialCodeCampaignBonus';
import {
    SerialCodeCampaignBonusFromJSON,
    SerialCodeCampaignBonusFromJSONTyped,
    SerialCodeCampaignBonusToJSON,
} from './SerialCodeCampaignBonus';

/**
 * 
 * @export
 * @interface SerialCodeCampaign
 */
export interface SerialCodeCampaign {
    /**
     * キャンペーン（施策）コード
     * @type {string}
     * @memberof SerialCodeCampaign
     */
    serialCodeCampaignCd: string;
    /**
     * キャンペーン名称
     * @type {string}
     * @memberof SerialCodeCampaign
     */
    name: string;
    /**
     * 説明
     * @type {string}
     * @memberof SerialCodeCampaign
     */
    description: string;
    /**
     * 共通キーフラグ 0:キャンペーン内でユニークなキーを発行する、1:共通のキーを発行する
     * @type {boolean}
     * @memberof SerialCodeCampaign
     */
    commonKeyFlg: boolean;
    /**
     * キャンペーン内での複数回付与フラグ 0:キャンペーンごとに特典付与が１回のみ、1:同一キャンペーン内で複数回特典付与が可能
     * @type {boolean}
     * @memberof SerialCodeCampaign
     */
    repeatableFlg: boolean;
    /**
     * キー発行件数
     * @type {number}
     * @memberof SerialCodeCampaign
     */
    keyCount: number;
    /**
     * 特典リスト
     * @type {Array<SerialCodeCampaignBonus>}
     * @memberof SerialCodeCampaign
     */
    bonusList: Array<SerialCodeCampaignBonus>;
    /**
     * 表示開始日時。
     * @type {string}
     * @memberof SerialCodeCampaign
     */
    startedAt: string | null;
    /**
     * 表示終了日時。
     * @type {string}
     * @memberof SerialCodeCampaign
     */
    endedAt: string | null;
}

/**
 * Check if a given object implements the SerialCodeCampaign interface.
 */
export function instanceOfSerialCodeCampaign(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "serialCodeCampaignCd" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "commonKeyFlg" in value;
    isInstance = isInstance && "repeatableFlg" in value;
    isInstance = isInstance && "keyCount" in value;
    isInstance = isInstance && "bonusList" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;

    return isInstance;
}

export function SerialCodeCampaignFromJSON(json: any): SerialCodeCampaign {
    return SerialCodeCampaignFromJSONTyped(json, false);
}

export function SerialCodeCampaignFromJSONTyped(json: any, ignoreDiscriminator: boolean): SerialCodeCampaign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialCodeCampaignCd': json['serial_code_campaign_cd'],
        'name': json['name'],
        'description': json['description'],
        'commonKeyFlg': json['common_key_flg'],
        'repeatableFlg': json['repeatable_flg'],
        'keyCount': json['key_count'],
        'bonusList': ((json['bonus_list'] as Array<any>).map(SerialCodeCampaignBonusFromJSON)),
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
    };
}

export function SerialCodeCampaignToJSON(value?: SerialCodeCampaign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serial_code_campaign_cd': value.serialCodeCampaignCd,
        'name': value.name,
        'description': value.description,
        'common_key_flg': value.commonKeyFlg,
        'repeatable_flg': value.repeatableFlg,
        'key_count': value.keyCount,
        'bonus_list': ((value.bonusList as Array<any>).map(SerialCodeCampaignBonusToJSON)),
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
    };
}

