/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventFever
 */
export interface EventFever {
    /**
     * フィーバーコード
     * @type {string}
     * @memberof EventFever
     */
    eventFeverCd: string;
    /**
     * フィーバータイプ
     * @type {string}
     * @memberof EventFever
     */
    feverType: string;
    /**
     * フィーバーゴールド倍率
     * @type {number}
     * @memberof EventFever
     */
    feverGrantGold: number;
    /**
     * フィーバーパラメーター倍率
     * @type {number}
     * @memberof EventFever
     */
    feverGrantParameter: number;
    /**
     * フィーバー発生に必要なミニゲームポイント
     * @type {number}
     * @memberof EventFever
     */
    requiredEventMinigameP: number;
    /**
     * 優先順
     * @type {number}
     * @memberof EventFever
     */
    orderNo: number;
    /**
     * フィーバー文言
     * @type {string}
     * @memberof EventFever
     */
    feverText: string;
    /**
     * フィーバー開始日時
     * @type {string}
     * @memberof EventFever
     */
    feverStartedAt: string;
    /**
     * フィーバー終了日時
     * @type {string}
     * @memberof EventFever
     */
    feverEndedAt: string;
    /**
     * フィーバー残り時間
     * @type {number}
     * @memberof EventFever
     */
    feverTime: number;
}

/**
 * Check if a given object implements the EventFever interface.
 */
export function instanceOfEventFever(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventFeverCd" in value;
    isInstance = isInstance && "feverType" in value;
    isInstance = isInstance && "feverGrantGold" in value;
    isInstance = isInstance && "feverGrantParameter" in value;
    isInstance = isInstance && "requiredEventMinigameP" in value;
    isInstance = isInstance && "orderNo" in value;
    isInstance = isInstance && "feverText" in value;
    isInstance = isInstance && "feverStartedAt" in value;
    isInstance = isInstance && "feverEndedAt" in value;
    isInstance = isInstance && "feverTime" in value;

    return isInstance;
}

export function EventFeverFromJSON(json: any): EventFever {
    return EventFeverFromJSONTyped(json, false);
}

export function EventFeverFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventFever {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventFeverCd': json['event_fever_cd'],
        'feverType': json['fever_type'],
        'feverGrantGold': json['fever_grant_gold'],
        'feverGrantParameter': json['fever_grant_parameter'],
        'requiredEventMinigameP': json['required_event_minigame_p'],
        'orderNo': json['order_no'],
        'feverText': json['fever_text'],
        'feverStartedAt': json['fever_started_at'],
        'feverEndedAt': json['fever_ended_at'],
        'feverTime': json['fever_time'],
    };
}

export function EventFeverToJSON(value?: EventFever | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_fever_cd': value.eventFeverCd,
        'fever_type': value.feverType,
        'fever_grant_gold': value.feverGrantGold,
        'fever_grant_parameter': value.feverGrantParameter,
        'required_event_minigame_p': value.requiredEventMinigameP,
        'order_no': value.orderNo,
        'fever_text': value.feverText,
        'fever_started_at': value.feverStartedAt,
        'fever_ended_at': value.feverEndedAt,
        'fever_time': value.feverTime,
    };
}

