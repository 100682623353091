/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PresentItem } from './PresentItem';
import {
    PresentItemFromJSON,
    PresentItemFromJSONTyped,
    PresentItemToJSON,
} from './PresentItem';

/**
 * 
 * @export
 * @interface PresentItems
 */
export interface PresentItems {
    /**
     * 
     * @type {Array<PresentItem>}
     * @memberof PresentItems
     */
    data: Array<PresentItem>;
}

/**
 * Check if a given object implements the PresentItems interface.
 */
export function instanceOfPresentItems(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function PresentItemsFromJSON(json: any): PresentItems {
    return PresentItemsFromJSONTyped(json, false);
}

export function PresentItemsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PresentItems {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(PresentItemFromJSON)),
    };
}

export function PresentItemsToJSON(value?: PresentItems | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(PresentItemToJSON)),
    };
}

