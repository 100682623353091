import {
  ShopCombinedsale,
  ShopCombinedsaleItem,
  UserShopCombinedsale,
  UserShopCombinedsaleItem,
  ShopSaleType,
  UserShopItem, ShopItemRequestEnum
} from 'api';
import { isAnimatePlatform } from '~/libs/platform';
import {
  getShopItemData,
  ShopItemData,
  ShopCombinedsaleItemData,
  getEnableBuyShopLimitItemFromShopItemCd
} from '~/libs/page/shop/type';
import { AppVisibleError } from '~/libs/errors';
import { openNewWindow } from '~/libs/utils';
import { inPeriod } from '~/libs/date';

interface ShopItemBuyData extends ShopItemData {
  message: string,
}

type ShopCombinedsaleItemBuyData = ShopCombinedsaleItemData

/**
 * 購入モーダル用のデータを取得
 * @param {string} shopItemCd
 */
const getBuyData = async (shopItemCd: string): Promise<ShopItemData> => {
  // APIからデータを取得
  const [
    userShopItemListData,
    userShopLimitItemListData,
    userItemData,
    shopItemMstData,
  ] = await Promise.all([
    useApi().user.getShopItemList(),
    useApi().user.getShopLimitItemList(),
    useApi().user.getItemList(),
    useApi().master.getShopItemMst({
      categoryType: ShopItemRequestEnum.Item
    }),
    useUser().update(),
    useAllWallet().update(),
  ]);
  const userShopItems = userShopItemListData.data;
  const userShopLimitItemList = userShopLimitItemListData.data;
  const shopItems = shopItemMstData.data;
  const userItems = userItemData.data;

  // アイテムデータを取得
  const userShopItem = userShopItems.find((userShopItem: UserShopItem) => {
    return (userShopItem.shopItemCd === shopItemCd);
  });
  if (!userShopItem) {
    throw new AppVisibleError('ショップアイテムが見つかりませんでした');
  }
  const data = getShopItemData(userShopItem, shopItems, userItems, userShopLimitItemList);
  if (!data) {
    throw new AppVisibleError('ショップアイテムデータが見つかりませんでした');
  }
  return data;
};

/**
 * 購入モーダルを呼び出す
 * @param {ShopItemBuyData} data
 */
const buyFromData = (data: ShopItemBuyData) => {
  useShop().shopItemData.value = data;
};

/**
 * 購入モーダルを呼び出す
 * @param {string} shopItemCd
 * @param {string} message
 */
const buy = async (shopItemCd: string, message = '') => {
  const key = useLoading().loader.start({ blocked: true, });
  try {
    // 購入するショップアイテムデータを設定
    const data = await getBuyData(shopItemCd);
    buyFromData({
      shopItem: data.shopItem,
      userItem: data.userItem,
      userShopItem: data.userShopItem,
      userShopLimitItem: data.userShopLimitItem,
      message,
    });
  } catch (e) {
    useReportAppError(e);
  } finally {
    useLoading().loader.stop(key);
  }
};

/**
 * 購入モーダル用のデータを取得
 * @param {string} combinedsaleCd
 * @param {string} combinedsaleItemCd
 * @param {ShopSaleType} shopSaleType
 */
const getBuyCombinedsaleItemData = async (combinedsaleCd: string, combinedsaleItemCd: string, shopSaleType: ShopSaleType): Promise<ShopCombinedsaleItemData> => {
  // APIからデータを取得
  const [
    userShopLimitItemListData,
    userShopCombinedsaleData,
    shopCombinedsaleMstData,
  ] = await Promise.all([
    useApi().user.getShopLimitItemList(),
    useApi().user.getShopCombinedsaleList({
      type: shopSaleType,
    }),
    useApi().master.getShopCombinedsaleMst(),
    useUser().update(),
    useAllWallet().update(),
  ]);
  const userShopLimitItemList = userShopLimitItemListData.data;
  const userShopCombinedsaleList = userShopCombinedsaleData.data;
  const shopCombinedsaleMst = shopCombinedsaleMstData.data;

  // アイテムデータを取得
  const shopCombinedsale = shopCombinedsaleMst.find((shopCombinedsale: ShopCombinedsale) => {
    return (shopCombinedsale.combinedsaleCd !== combinedsaleCd);
  });
  if (!shopCombinedsale) {
    throw new AppVisibleError('ショップアイテムが見つかりませんでした');
  }
  const shopCombinedsaleItem = shopCombinedsale.combinedsaleItemList.find((shopCombinedsaleItem: ShopCombinedsaleItem) => {
    return (shopCombinedsaleItem.combinedsaleItemCd !== combinedsaleItemCd);
  });
  if (!shopCombinedsaleItem) {
    throw new AppVisibleError('ショップアイテムが見つかりませんでした');
  }
  const userShopCombinedsale = userShopCombinedsaleList.find((userShopCombinedsale: UserShopCombinedsale) => {
    return (userShopCombinedsale.combinedsaleCd !== combinedsaleCd);
  });
  if (!userShopCombinedsale || !inPeriod(userShopCombinedsale.startedAt, userShopCombinedsale.endedAt, useLabotan().date)) {
    throw new AppVisibleError('ショップアイテムが見つかりませんでした');
  }
  const userShopCombinedsaleItem = userShopCombinedsale.combinedsaleItemList.find((userShopCombinedsaleItem: UserShopCombinedsaleItem) => {
    return (userShopCombinedsaleItem.combinedsaleItemCd !== combinedsaleItemCd);
  });
  if (!userShopCombinedsaleItem || !inPeriod(userShopCombinedsaleItem.startedAt, userShopCombinedsaleItem.endedAt, useLabotan().date)) {
    throw new AppVisibleError('ショップアイテムが見つかりませんでした');
  }
  const userShopLimitItem = getEnableBuyShopLimitItemFromShopItemCd(shopCombinedsaleItem.shopItemCd, userShopLimitItemList);
  return {
    shopCombinedsaleItem,
    userShopCombinedsaleItem,
    userShopLimitItem,
  };
};

/**
 * 購入モーダルを呼び出す
 * @param {ShopCombinedsaleItemBuyData} data
 */
const buyCombinedsaleItemFromData = (data: ShopCombinedsaleItemBuyData) => {
  useShop().shopCombinedsaleItemData.value = data;
  useShop().checkStatus.value = true;
};

/**
 * 購入モーダルを呼び出す
 * @param {string} combinedsaleCd
 * @param {string} combinedsaleItemCd
 * @param {ShopSaleType} shopSaleType
 */
const buyCombinedsaleItem = async (combinedsaleCd: string, combinedsaleItemCd: string, shopSaleType: ShopSaleType) => {
  const key = useLoading().loader.start({ blocked: true, });
  try {
    // 購入するショップアイテムデータを設定
    const data = await getBuyCombinedsaleItemData(combinedsaleCd, combinedsaleItemCd, shopSaleType);
    buyCombinedsaleItemFromData(data);
  } catch (e) {
    useReportAppError(e);
  } finally {
    useLoading().loader.stop(key);
  }
};

/**
 * 商品購入モーダルの呼び出し
 */
export const useShop = () => {
  const shopItemData = useState<ShopItemBuyData|undefined>('shopItemBuyData');
  const shopCombinedsaleItemData = useState<ShopCombinedsaleItemBuyData|undefined>('shopCombinedsaleItemBuyData');
  const checkStatus = useState<boolean>('checkStatus', () => false);
  return {
    shopItemData,
    shopCombinedsaleItemData,
    checkStatus,
    buy,
    buyFromData,
    buyCombinedsaleItem,
    buyCombinedsaleItemFromData,
  };
};

/**
 * 各プラットフォームの購入ページへ遷移
 * @param {string} shopUrl
 */
export const navigateToPurchasePage = async (shopUrl = '/shop/dia') => {
  if (isAnimatePlatform()) {
    // ゲソコイン購入ページへ
    const animateWallet = useAnimateWallet().wallet;
    if (animateWallet.value && animateWallet.value.paymentUrl) {
      // アニメイトは購入ページを新しいウィンドウで開く必要がある
      openNewWindow(animateWallet.value.paymentUrl);
    } else {
      useLog('common/point').warn('アニメイトの購入URLが取得できません');
    }
  } else {
    // ダイヤ購入ページへ
    await navigateTo(shopUrl);
  }
};
