import { CommonNaming } from '~/configs/common';

/**
 * shopItemの共通購入呼び出しを行うCdを定義
 */
export const shopItemCds = {
  // ストーリーチケット
  storyTicket: 'SIC_TICKET_STORY',
  // 体力回復アイテム
  staminaRecovery: 'SIC_RECOVER_HP100',
  // クローゼット
  closet: 'SIC_CLOSET',
  // 心の鍵
  keyHeart: 'SIC_KEY_HEART',
  // ガチャチケット
  gachaTicket: 'SIC_GACHA_MEDAL',
};

/**
 * shopItemのカテゴリ名を定義
 */
export const shopItemCategoryCd = {
  // ストーリーチケット
  storyTicketList: 'story_ticket_list',
  // 体力回復アイテム(購入時に持ち物に入る)
  staminaRecover: 'stamina_recover',
  // アバター保存枠増加
  avatarStorageUpItem: 'avatar_storage_up_item',
  // 親密度増加
  parameterUpItem: 'parameter_up_item',
  // 好感度増加
  favorableUpItem: 'favorable_up_item',
  // カード
  card: 'card',
  // セット販売
  combinedsaleItem: 'combinedsale_item',
  // ガチャ
  gacha: 'gacha',
  // ストーリーアイテム(心の鍵など)
  storySale: 'story_sale',
  // シナリオイベントパラメーター増加
  scenarioParameterUpItem: 'scenario_parameter_up_item',
  // アバター
  avatar: 'avatar',
  // シナイベエピローグ
  epilogue: 'epilogue',
  // ショップ専用ストーリーチケット※VAS以降はstory_ticket_list共用
  shopStoryTicketList: 'shop_story_ticket_list',
  // ショップ専用ストーリーアイテム(心の鍵など)※VAS以降はstory_sale共用
  shopStorySale: 'shop_story_sale',
  // 本編促進で集めるアイテム
  collectItem: 'collect_item',
  // ライブイベント体力回復アイテム(購入時に持ち物に入る)
  spRecover: 'sp_recover',
  // 体力回復アイテム(購入時に即時使用する)
  staminaRecoverImmediate: 'stamina_recover_immediate',
  // ライブイベント体力回復アイテム(購入時に即時使用する)
  spRecoverImmediate: 'sp_recover_immediate',
};

/**
 * プロダクト購入画面の表示エラーコード
 */
export const productErrorCode = {
  // 成功
  success: 0,
  // 既に購入済み
  alreadyDoing: 2203,
  // 購入回数オーバー
  limitOver: 2205,
  // 不明
  unknown: 2201,
  // プロダクトが見つからない
  notFoundProduct: 15000,
  // 初期化エラー
  initFailed: 15003,
  // 保留中
  pending: 16004,
};

/**
 * ミッション専用ショップタイプ
 */
export const missionShopType = {
  parameter: 'parameter',
  routeA: 'route_a',
  routeB: 'route_b',
  cardAnnounce: 'card_announce',
  eventParameter: 'event_parameter',
};

/**
 * shopConfig
 */
interface ShopConfig {
  missionFilter: Record<string, string[]>, // Key = 絞り込みタイプ
  missionShopName: Record<string, string>,
}

export const shopConfig: ShopConfig = {
  // ミッション専用ショップアイテムリスト->絞り込みに使うショップアイテムカテゴリコード
  missionFilter: {
    [missionShopType.parameter]: [
      'parameter_up_item',
    ],
    [missionShopType.routeA]: [
      'favorable_up_item',
    ],
    [missionShopType.routeB]: [
      'favorable_up_item',
    ],
    [missionShopType.cardAnnounce]: [
      'favorable_up_item',
    ],
    [missionShopType.eventParameter]: [
      'scenario_parameter_up_item',
    ],
  },
  missionShopName: {
    [missionShopType.parameter]: CommonNaming.minigameP,
    [missionShopType.routeA]: CommonNaming.loveP,
    [missionShopType.routeB]: CommonNaming.loveP,
    [missionShopType.cardAnnounce]: CommonNaming.loveP,
    [missionShopType.eventParameter]: CommonNaming.eventP,
  },
};

export const storyClearShopItemCdList = [
  's_shop_i_0005',
  's_shop_i_0006',
  's_shop_i_0007',
  's_shop_i_0008',
];
