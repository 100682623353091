import { Dexie } from 'dexie';

/**
 * スキーマの定義
*/
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Model {}
export class ResourceHashModel implements Model {
  path: string;
  hash: string;
  constructor (path: string, hash: string) {
    this.path = path;
    this.hash = hash;
  }

  public static keys (): string[] {
    return ['path', 'hash'];
  }
}
export interface ApplicationDatabase extends Dexie {
  resourceHash: Dexie.Table<ResourceHashModel, string>
}

interface DatabaseConnectionConfig {
  // スキーマのバージョン
  // ここを変更しないとスキーマが変更されません
  version: number,
  // スキーマ情報。値にはカラムの名前を列挙する
  schemes: Record<string, string[]>
}
interface DatabaseConfig {
  // デフォルトの接続先
  default: string,
  // 接続先情報
  connections: Record<string, DatabaseConnectionConfig>,
}
/**
 * IndexDBに保存するデータを定義
 * NOTE: IndexDBは10MBの容量制限があるので注意すること
 */
export const databaseConfig: DatabaseConfig = {
  default: 'application',
  connections: {
    application: {
      version: 1,
      schemes: {
        resourceHash: ResourceHashModel.keys(),
      }
    },
  },
};
