/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserDeckCardListInner
 */
export interface UserDeckCardListInner {
    /**
     * 位置番号（1～5）
     * @type {number}
     * @memberof UserDeckCardListInner
     */
    position: number;
    /**
     * カードコード
     * @type {string}
     * @memberof UserDeckCardListInner
     */
    cardCd?: string;
}

/**
 * Check if a given object implements the UserDeckCardListInner interface.
 */
export function instanceOfUserDeckCardListInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "position" in value;

    return isInstance;
}

export function UserDeckCardListInnerFromJSON(json: any): UserDeckCardListInner {
    return UserDeckCardListInnerFromJSONTyped(json, false);
}

export function UserDeckCardListInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeckCardListInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': json['position'],
        'cardCd': !exists(json, 'card_cd') ? undefined : json['card_cd'],
    };
}

export function UserDeckCardListInnerToJSON(value?: UserDeckCardListInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'card_cd': value.cardCd,
    };
}

