// 読み込み中のロードキー
let loadingKey: symbol|undefined;

const startLoading = () => {
  stopLoading();
  return useLoading().loader.start();
};
const stopLoading = () => {
  if (loadingKey) {
    useLoading().loader.stop(loadingKey);
    loadingKey = undefined;
  }
};
export default defineNuxtPlugin((nuxtApp) => {
  // 初めのページで一瞬画面が映るため、ページ読み込み開始時にローディングを止める
  if (useRoute().name !== 'index') {
    loadingKey = startLoading();
  }
  nuxtApp.hook('page:finish', () => {
    stopLoading();
  });
});
