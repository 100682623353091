/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 彼目線を解放するのに必要なアイテムの数、ない場合はnull
 * @export
 * @interface StoryNeedKey
 */
export interface StoryNeedKey {
    /**
     * 必要キー数
     * @type {number}
     * @memberof StoryNeedKey
     */
    count: number;
    /**
     * 必要ポイント数
     * @type {number}
     * @memberof StoryNeedKey
     */
    point: number;
}

/**
 * Check if a given object implements the StoryNeedKey interface.
 */
export function instanceOfStoryNeedKey(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "point" in value;

    return isInstance;
}

export function StoryNeedKeyFromJSON(json: any): StoryNeedKey {
    return StoryNeedKeyFromJSONTyped(json, false);
}

export function StoryNeedKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryNeedKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'point': json['point'],
    };
}

export function StoryNeedKeyToJSON(value?: StoryNeedKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'point': value.point,
    };
}

