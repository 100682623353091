import { Device, getDevice, isAnimatePlatform, isUnityEditorDevice } from '~/libs/platform';
import { isNativeAppli } from '~/libs/device';

/**
 * サポート端末のチェック
 */
const isSupportDevice = () => {
  const device = getDevice();
  if (device === Device.ios || device === Device.android || process.dev) {
    return true;
  }
  return isUnityEditorDevice();
};

export default defineNuxtRouteMiddleware((to, _) => {
  const isNoSupportPage = to.path === '/no-support';
  const isRoot = to.path === '/';

  // ルートパス以外 && アプリ版以外 && アニメイト向けFQDN以外 のアクセスを拒否する。
  if (!process.dev && !isRoot && !isNativeAppli() && !isAnimatePlatform()) {
    if (!isNoSupportPage) {
      return navigateTo('/no-support');
    }
    return;
  }

  // サポート端末のチェック
  if (isSupportDevice()) {
    if (isNoSupportPage) {
      return navigateTo('/');
    }
    return;
  }
  if (!isNoSupportPage) {
    return navigateTo('/no-support');
  }
});
