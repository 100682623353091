/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SpecialStoryUnlockPayType } from './SpecialStoryUnlockPayType';
import {
    SpecialStoryUnlockPayTypeFromJSON,
    SpecialStoryUnlockPayTypeFromJSONTyped,
    SpecialStoryUnlockPayTypeToJSON,
} from './SpecialStoryUnlockPayType';

/**
 * 
 * @export
 * @interface SpecialStoryUnlockRequest
 */
export interface SpecialStoryUnlockRequest {
    /**
     * チケットコード
     * @type {string}
     * @memberof SpecialStoryUnlockRequest
     */
    storyTicketCd: string;
    /**
     * 
     * @type {SpecialStoryUnlockPayType}
     * @memberof SpecialStoryUnlockRequest
     */
    payType: SpecialStoryUnlockPayType;
    /**
     * 消費無償ジェム
     * @type {number}
     * @memberof SpecialStoryUnlockRequest
     */
    freeGem: number;
    /**
     * 消費有償ジェム
     * @type {number}
     * @memberof SpecialStoryUnlockRequest
     */
    purchasedGem: number;
    /**
     * 消費ゲソコイン
     * @type {number}
     * @memberof SpecialStoryUnlockRequest
     */
    gesoCoin: number;
}

/**
 * Check if a given object implements the SpecialStoryUnlockRequest interface.
 */
export function instanceOfSpecialStoryUnlockRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "storyTicketCd" in value;
    isInstance = isInstance && "payType" in value;
    isInstance = isInstance && "freeGem" in value;
    isInstance = isInstance && "purchasedGem" in value;
    isInstance = isInstance && "gesoCoin" in value;

    return isInstance;
}

export function SpecialStoryUnlockRequestFromJSON(json: any): SpecialStoryUnlockRequest {
    return SpecialStoryUnlockRequestFromJSONTyped(json, false);
}

export function SpecialStoryUnlockRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SpecialStoryUnlockRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storyTicketCd': json['story_ticket_cd'],
        'payType': SpecialStoryUnlockPayTypeFromJSON(json['pay_type']),
        'freeGem': json['free_gem'],
        'purchasedGem': json['purchased_gem'],
        'gesoCoin': json['geso_coin'],
    };
}

export function SpecialStoryUnlockRequestToJSON(value?: SpecialStoryUnlockRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'story_ticket_cd': value.storyTicketCd,
        'pay_type': SpecialStoryUnlockPayTypeToJSON(value.payType),
        'free_gem': value.freeGem,
        'purchased_gem': value.purchasedGem,
        'geso_coin': value.gesoCoin,
    };
}

