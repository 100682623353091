/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 課金アイテムの購入結果
 * @export
 */
export const BillingProductBuyResult = {
    Doing: 'doing',
    Success: 'success',
    Retry: 'retry',
    Cancel: 'cancel',
    Pending: 'pending'
} as const;
export type BillingProductBuyResult = typeof BillingProductBuyResult[keyof typeof BillingProductBuyResult];


export function BillingProductBuyResultFromJSON(json: any): BillingProductBuyResult {
    return BillingProductBuyResultFromJSONTyped(json, false);
}

export function BillingProductBuyResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingProductBuyResult {
    return json as BillingProductBuyResult;
}

export function BillingProductBuyResultToJSON(value?: BillingProductBuyResult | null): any {
    return value as any;
}

