/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventBonus } from './UserEventBonus';
import {
    UserEventBonusFromJSON,
    UserEventBonusFromJSONTyped,
    UserEventBonusToJSON,
} from './UserEventBonus';

/**
 * 
 * @export
 * @interface UserEventBonusListResponse
 */
export interface UserEventBonusListResponse {
    /**
     * 
     * @type {Array<UserEventBonus>}
     * @memberof UserEventBonusListResponse
     */
    data: Array<UserEventBonus>;
}

/**
 * Check if a given object implements the UserEventBonusListResponse interface.
 */
export function instanceOfUserEventBonusListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserEventBonusListResponseFromJSON(json: any): UserEventBonusListResponse {
    return UserEventBonusListResponseFromJSONTyped(json, false);
}

export function UserEventBonusListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventBonusListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserEventBonusFromJSON)),
    };
}

export function UserEventBonusListResponseToJSON(value?: UserEventBonusListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserEventBonusToJSON)),
    };
}

