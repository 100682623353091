/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserDeckCardListInner } from './UserDeckCardListInner';
import {
    UserDeckCardListInnerFromJSON,
    UserDeckCardListInnerFromJSONTyped,
    UserDeckCardListInnerToJSON,
} from './UserDeckCardListInner';

/**
 * 
 * @export
 * @interface UserDeck
 */
export interface UserDeck {
    /**
     * 部隊番号（1～5）
     * @type {number}
     * @memberof UserDeck
     */
    deckNo: number;
    /**
     * 部隊名称
     * @type {string}
     * @memberof UserDeck
     */
    deckName: string;
    /**
     * 主力部隊フラグ 1:主力部隊 0:通常部隊
     * @type {boolean}
     * @memberof UserDeck
     */
    mainDeckFlg: boolean;
    /**
     * 部隊のトータルパラメータ
     * @type {number}
     * @memberof UserDeck
     */
    deckP: number;
    /**
     * デッキに設定されているカードのリスト
     * @type {Array<UserDeckCardListInner>}
     * @memberof UserDeck
     */
    cardList: Array<UserDeckCardListInner>;
}

/**
 * Check if a given object implements the UserDeck interface.
 */
export function instanceOfUserDeck(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deckNo" in value;
    isInstance = isInstance && "deckName" in value;
    isInstance = isInstance && "mainDeckFlg" in value;
    isInstance = isInstance && "deckP" in value;
    isInstance = isInstance && "cardList" in value;

    return isInstance;
}

export function UserDeckFromJSON(json: any): UserDeck {
    return UserDeckFromJSONTyped(json, false);
}

export function UserDeckFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deckNo': json['deck_no'],
        'deckName': json['deck_name'],
        'mainDeckFlg': json['main_deck_flg'],
        'deckP': json['deck_p'],
        'cardList': ((json['card_list'] as Array<any>).map(UserDeckCardListInnerFromJSON)),
    };
}

export function UserDeckToJSON(value?: UserDeck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deck_no': value.deckNo,
        'deck_name': value.deckName,
        'main_deck_flg': value.mainDeckFlg,
        'deck_p': value.deckP,
        'card_list': ((value.cardList as Array<any>).map(UserDeckCardListInnerToJSON)),
    };
}

