import { sleep } from '~/libs/utils';

// 早すぎるルーティング変更と判断する時間(ミリ秒)
const tooFastRouteChangeDetermineTime = 200;
// 早すぎるルーティング変更の時にsleepさせる時間(ミリ秒)
const tooFastRouteChangeSleepTime = 150;
// 最後にルーティングした時間
let lastRouteTime = 0;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default defineNuxtRouteMiddleware(async (to, _) => {
  const now = new Date().getTime();
  // 早すぎるルーティング変更の時にはsleepを入れる対応
  // Uncaught DOMException: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.
  // @see https://github.com/nuxt/nuxt/issues/13350
  if ((now - lastRouteTime) < tooFastRouteChangeDetermineTime) {
    await sleep(tooFastRouteChangeSleepTime / 1000);
  }
  lastRouteTime = now;
});
