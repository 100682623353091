/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopItemConstraintType } from './ShopItemConstraintType';
import {
    ShopItemConstraintTypeFromJSON,
    ShopItemConstraintTypeFromJSONTyped,
    ShopItemConstraintTypeToJSON,
} from './ShopItemConstraintType';

/**
 * 
 * @export
 * @interface UserShopLimitItem
 */
export interface UserShopLimitItem {
    /**
     * shop_item_cd
     * @type {string}
     * @memberof UserShopLimitItem
     */
    shopItemCd: string;
    /**
     * 
     * @type {ShopItemConstraintType}
     * @memberof UserShopLimitItem
     */
    constraintType: ShopItemConstraintType;
    /**
     * 購入数(ユーザーが対象のショップアイテムを何個購入したか)
     * @type {number}
     * @memberof UserShopLimitItem
     */
    buyCount: number;
    /**
     * 購入上限数(対象のショップアイテムに設けられている上限個数)
     * @type {number}
     * @memberof UserShopLimitItem
     */
    buyLimit: number;
}

/**
 * Check if a given object implements the UserShopLimitItem interface.
 */
export function instanceOfUserShopLimitItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shopItemCd" in value;
    isInstance = isInstance && "constraintType" in value;
    isInstance = isInstance && "buyCount" in value;
    isInstance = isInstance && "buyLimit" in value;

    return isInstance;
}

export function UserShopLimitItemFromJSON(json: any): UserShopLimitItem {
    return UserShopLimitItemFromJSONTyped(json, false);
}

export function UserShopLimitItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserShopLimitItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shopItemCd': json['shop_item_cd'],
        'constraintType': ShopItemConstraintTypeFromJSON(json['constraint_type']),
        'buyCount': json['buy_count'],
        'buyLimit': json['buy_limit'],
    };
}

export function UserShopLimitItemToJSON(value?: UserShopLimitItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shop_item_cd': value.shopItemCd,
        'constraint_type': ShopItemConstraintTypeToJSON(value.constraintType),
        'buy_count': value.buyCount,
        'buy_limit': value.buyLimit,
    };
}

