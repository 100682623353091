/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SelectRoute
 */
export interface SelectRoute {
    /**
     * 表示位置
     * @type {string}
     * @memberof SelectRoute
     */
    position: string;
    /**
     * ルートタイプ
     * @type {string}
     * @memberof SelectRoute
     */
    routeType: string;
}

/**
 * Check if a given object implements the SelectRoute interface.
 */
export function instanceOfSelectRoute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "position" in value;
    isInstance = isInstance && "routeType" in value;

    return isInstance;
}

export function SelectRouteFromJSON(json: any): SelectRoute {
    return SelectRouteFromJSONTyped(json, false);
}

export function SelectRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): SelectRoute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'position': json['position'],
        'routeType': json['route_type'],
    };
}

export function SelectRouteToJSON(value?: SelectRoute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'position': value.position,
        'route_type': value.routeType,
    };
}

