/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventParameterMission
 */
export interface EventParameterMission {
    /**
     * ミッションコード
     * @type {string}
     * @memberof EventParameterMission
     */
    missionCd: string;
    /**
     * 達成値
     * @type {number}
     * @memberof EventParameterMission
     */
    compTerms: number;
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof EventParameterMission
     */
    portraitEventCharaCd: string;
}

/**
 * Check if a given object implements the EventParameterMission interface.
 */
export function instanceOfEventParameterMission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "compTerms" in value;
    isInstance = isInstance && "portraitEventCharaCd" in value;

    return isInstance;
}

export function EventParameterMissionFromJSON(json: any): EventParameterMission {
    return EventParameterMissionFromJSONTyped(json, false);
}

export function EventParameterMissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventParameterMission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'missionCd': json['mission_cd'],
        'compTerms': json['comp_terms'],
        'portraitEventCharaCd': json['portrait_event_chara_cd'],
    };
}

export function EventParameterMissionToJSON(value?: EventParameterMission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mission_cd': value.missionCd,
        'comp_terms': value.compTerms,
        'portrait_event_chara_cd': value.portraitEventCharaCd,
    };
}

