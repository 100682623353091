/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CommuBonusType } from './CommuBonusType';
import {
    CommuBonusTypeFromJSON,
    CommuBonusTypeFromJSONTyped,
    CommuBonusTypeToJSON,
} from './CommuBonusType';

/**
 * 
 * @export
 * @interface CommuCharaBonus
 */
export interface CommuCharaBonus {
    /**
     * コミュキャラ報酬コード
     * @type {string}
     * @memberof CommuCharaBonus
     */
    commuCharaBonusCd: string;
    /**
     * 
     * @type {CommuBonusType}
     * @memberof CommuCharaBonus
     */
    bonusType: CommuBonusType;
    /**
     * 報酬獲得に必要なコミュレベル
     * @type {number}
     * @memberof CommuCharaBonus
     */
    level: number;
    /**
     * 報酬タイトル表示用
     * @type {string}
     * @memberof CommuCharaBonus
     */
    titleText: string;
    /**
     * カードコード
     * @type {string}
     * @memberof CommuCharaBonus
     */
    cardCd: string;
    /**
     * プレゼントアイテムコード
     * @type {string}
     * @memberof CommuCharaBonus
     */
    presentItemCd: string;
    /**
     * スチルコード
     * @type {string}
     * @memberof CommuCharaBonus
     */
    stillCd: string;
    /**
     * スペシャルストーリーコード
     * @type {string}
     * @memberof CommuCharaBonus
     */
    specialStoryCd: string;
    /**
     * ストーリーチケットコード
     * @type {string}
     * @memberof CommuCharaBonus
     */
    storyTicketCd: string;
}

/**
 * Check if a given object implements the CommuCharaBonus interface.
 */
export function instanceOfCommuCharaBonus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "commuCharaBonusCd" in value;
    isInstance = isInstance && "bonusType" in value;
    isInstance = isInstance && "level" in value;
    isInstance = isInstance && "titleText" in value;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "presentItemCd" in value;
    isInstance = isInstance && "stillCd" in value;
    isInstance = isInstance && "specialStoryCd" in value;
    isInstance = isInstance && "storyTicketCd" in value;

    return isInstance;
}

export function CommuCharaBonusFromJSON(json: any): CommuCharaBonus {
    return CommuCharaBonusFromJSONTyped(json, false);
}

export function CommuCharaBonusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaBonus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'commuCharaBonusCd': json['commu_chara_bonus_cd'],
        'bonusType': CommuBonusTypeFromJSON(json['bonus_type']),
        'level': json['level'],
        'titleText': json['title_text'],
        'cardCd': json['card_cd'],
        'presentItemCd': json['present_item_cd'],
        'stillCd': json['still_cd'],
        'specialStoryCd': json['special_story_cd'],
        'storyTicketCd': json['story_ticket_cd'],
    };
}

export function CommuCharaBonusToJSON(value?: CommuCharaBonus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'commu_chara_bonus_cd': value.commuCharaBonusCd,
        'bonus_type': CommuBonusTypeToJSON(value.bonusType),
        'level': value.level,
        'title_text': value.titleText,
        'card_cd': value.cardCd,
        'present_item_cd': value.presentItemCd,
        'still_cd': value.stillCd,
        'special_story_cd': value.specialStoryCd,
        'story_ticket_cd': value.storyTicketCd,
    };
}

