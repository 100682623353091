/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShopCombinedsaleItem } from './ShopCombinedsaleItem';
import {
    ShopCombinedsaleItemFromJSON,
    ShopCombinedsaleItemFromJSONTyped,
    ShopCombinedsaleItemToJSON,
} from './ShopCombinedsaleItem';
import type { ShopSaleMstType } from './ShopSaleMstType';
import {
    ShopSaleMstTypeFromJSON,
    ShopSaleMstTypeFromJSONTyped,
    ShopSaleMstTypeToJSON,
} from './ShopSaleMstType';

/**
 * 
 * @export
 * @interface ShopCombinedsale
 */
export interface ShopCombinedsale {
    /**
     * セット販売コード
     * @type {string}
     * @memberof ShopCombinedsale
     */
    combinedsaleCd: string;
    /**
     * 登録から14日間限定◆新人アリスセット
     * @type {string}
     * @memberof ShopCombinedsale
     */
    saleName: string;
    /**
     * 
     * @type {ShopSaleMstType}
     * @memberof ShopCombinedsale
     */
    saleMstType: ShopSaleMstType;
    /**
     * セット販売施策に紐付いているチケットの情報(紐付いているチケットが存在しない場合は空文字列を返す)
     * @type {string}
     * @memberof ShopCombinedsale
     */
    digestTicketCd: string;
    /**
     * セット販売施策BGMコード(シナリオ販売のBGMとして使用する。対象のBGMがない場合は空文字列を返す)
     * @type {string}
     * @memberof ShopCombinedsale
     */
    bgmCd: string;
    /**
     * 
     * @type {Array<ShopCombinedsaleItem>}
     * @memberof ShopCombinedsale
     */
    combinedsaleItemList: Array<ShopCombinedsaleItem>;
    /**
     * リソースパーティション番号
     * @type {number}
     * @memberof ShopCombinedsale
     */
    resourcePartition: number;
    /**
     * リソースセット販売コード
     * @type {string}
     * @memberof ShopCombinedsale
     */
    resourceCombinedsaleCd: string;
}

/**
 * Check if a given object implements the ShopCombinedsale interface.
 */
export function instanceOfShopCombinedsale(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "combinedsaleCd" in value;
    isInstance = isInstance && "saleName" in value;
    isInstance = isInstance && "saleMstType" in value;
    isInstance = isInstance && "digestTicketCd" in value;
    isInstance = isInstance && "bgmCd" in value;
    isInstance = isInstance && "combinedsaleItemList" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "resourceCombinedsaleCd" in value;

    return isInstance;
}

export function ShopCombinedsaleFromJSON(json: any): ShopCombinedsale {
    return ShopCombinedsaleFromJSONTyped(json, false);
}

export function ShopCombinedsaleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopCombinedsale {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'combinedsaleCd': json['combinedsale_cd'],
        'saleName': json['sale_name'],
        'saleMstType': ShopSaleMstTypeFromJSON(json['sale_mst_type']),
        'digestTicketCd': json['digest_ticket_cd'],
        'bgmCd': json['bgm_cd'],
        'combinedsaleItemList': ((json['combinedsale_item_list'] as Array<any>).map(ShopCombinedsaleItemFromJSON)),
        'resourcePartition': json['resource_partition'],
        'resourceCombinedsaleCd': json['resource_combinedsale_cd'],
    };
}

export function ShopCombinedsaleToJSON(value?: ShopCombinedsale | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'combinedsale_cd': value.combinedsaleCd,
        'sale_name': value.saleName,
        'sale_mst_type': ShopSaleMstTypeToJSON(value.saleMstType),
        'digest_ticket_cd': value.digestTicketCd,
        'bgm_cd': value.bgmCd,
        'combinedsale_item_list': ((value.combinedsaleItemList as Array<any>).map(ShopCombinedsaleItemToJSON)),
        'resource_partition': value.resourcePartition,
        'resource_combinedsale_cd': value.resourceCombinedsaleCd,
    };
}

