/**
 * アプリのローディング状態
 */
export class LoadingData {
  isActive = false;
  isProgress = false;
  progressNum = 0;
  progressTotal = 0;
  // 画面ブロックのみを行うか(spinnerのみ表示)
  blocked = false;

  constructor (props?: Partial<LoadingData>) {
    // noinspection TypeScriptValidateTypes
    Object.assign(this, props);
  }

  /**
   * データの上書き
   * @param props
   */
  assign (props?: Partial<LoadingData>) {
    // noinspection TypeScriptValidateTypes
    Object.assign(this, props);
  }
}
type LoadingDataMap = Map<symbol, LoadingData>;
export class LoadingDataManager {
  // eslint-disable-next-line no-use-before-define
  // private static instance: LoadingDataManager;
  protected dataMap: LoadingDataMap;
  protected totalData: LoadingData;

  constructor () {
    this.dataMap = new Map<symbol, LoadingData>();
    this.totalData = new LoadingData();
  }

  // static getInstance () {
  //   LoadingDataManager.instance = LoadingDataManager.instance || new LoadingDataManager();
  //   return LoadingDataManager.instance;
  // }

  /**
   * 合計データの取得
   */
  getTotalData () {
    return this.totalData;
  }

  /**
   * 合計の情報を取得
   */
  protected updateTotalData () {
    const loadingArr = Array.from(this.dataMap.values());
    const progressData = loadingArr.filter(v => v.isProgress);
    this.totalData.assign({
      isActive: loadingArr.length > 0,
      isProgress: progressData.length > 0,
      progressNum: progressData.reduce((a, x) => a + x.progressNum, 0),
      progressTotal: progressData.reduce((a, x) => a + x.progressTotal, 0),
      blocked: loadingArr.length > 0 ? loadingArr[0]?.blocked : false,
    });
    useLoading().total.value = this.totalData;
  }

  /**
   * キーの取得
   * @protected
   */
  protected getKey (): symbol {
    return Symbol('loading');
    // return `loading-${Math.random().toString(32).substring(2)}`;
  }

  /**
   * ローディングの開始
   * @param opts
   */
  start (opts?: { isProgress?: boolean, total?: number, blocked?: boolean }): symbol {
    const key = this.getKey();
    // assert(!this.dataMap.has(key));
    const data = new LoadingData({
      isActive: true,
      isProgress: opts?.isProgress || false,
      progressNum: 0,
      progressTotal: opts?.total || 0,
      blocked: opts && opts.blocked,
    });
    this.dataMap.set(key, data);
    this.updateTotalData();
    return key;
  }

  /**
   * 進行度の更新
   * @param key
   * @param num
   */
  setProgress (key: symbol, num: number) {
    if (!this.dataMap.has(key)) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const data = this.dataMap.get(key)!;
    data.progressNum = num;
    this.dataMap.set(key, data);
    this.updateTotalData();
  }

  /**
   * ローディングの停止
   * @param key
   */
  stop (key: symbol) {
    if (this.dataMap.has(key)) {
      this.dataMap.delete(key);
      this.updateTotalData();
    }
  }

  /**
   * ローディングの全停止
   */
  stopAll () {
    this.dataMap.clear();
    this.updateTotalData();
  }
}

/**
 * ローディング状態を設定
 */
export function useLoading () {
  const loader = useState<LoadingDataManager>('loading', () => new LoadingDataManager());
  const total = useState<LoadingData>('loadingTotal', () => {
    return loader.value.getTotalData();
  });
  return {
    loader: loader.value,
    total,
  };
}
