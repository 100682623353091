/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * イベントの状態
 * @export
 */
export const EventUserStatus = {
    Nsc: 'nsc',
    Nml: 'nml',
    Mis: 'mis',
    End: 'end'
} as const;
export type EventUserStatus = typeof EventUserStatus[keyof typeof EventUserStatus];


export function EventUserStatusFromJSON(json: any): EventUserStatus {
    return EventUserStatusFromJSONTyped(json, false);
}

export function EventUserStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventUserStatus {
    return json as EventUserStatus;
}

export function EventUserStatusToJSON(value?: EventUserStatus | null): any {
    return value as any;
}

