<template>
  <div class="particle-window">
    <div v-for="[k, v] in particleList" :key="k" :style="getStyle(v)" class="particle">
      <div class="particle-item particle-item-circle delay-1" />
      <div class="particle-item particle-item-circle delay-2" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { randomStr } from '~/libs/utils';

interface Particle {
  x: number,
  y: number,
}
const particleList = ref<Map<string, Particle>>(new Map());
// particleの有効期限(ミリ秒)
const particleTime = 600;
const particleDelayMax = 200;
const particleTimeCss = `${particleTime}ms`;
const particleDelayCss2 = `${particleDelayMax}ms`;
const removeParticle = (id: string) => {
  if (particleList.value && particleList.value.has(id)) {
    particleList.value.delete(id);
  }
};
const onClick = (e: MouseEvent | TouchEvent) => {
  let p: Particle | null = null;
  if (e instanceof MouseEvent) {
    p = {
      x: e.clientX,
      y: e.clientY,
    };
  } else if (e.changedTouches && e.changedTouches.length > 0) {
    p = {
      x: e.changedTouches[0].clientX,
      y: e.changedTouches[0].clientY,
    };
  }
  nextTick(() => {
    if (p) {
      const k = randomStr();
      particleList.value.set(k, p);
      setTimeout(((id: string) => {
        removeParticle(id);
      }).bind(this, k), particleTime + particleDelayMax);
    }
  });
};
onMounted(() => {
  if (process.client) {
    document.addEventListener('click', onClick);
  }
});
onBeforeUnmount(() => {
  if (process.client) {
    document.removeEventListener('click', onClick);
  }
});

const getStyle = (data: Particle) => {
  return {
    left: `${data.x}px`,
    top: `${data.y}px`,
  };
};
</script>

<style lang="scss" scoped>
@use "assets/css/base" as base;

.particle-window {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;

  @include base.zIndexFromLayer("overlay", 10);
}

.particle {
  position: absolute;
  width: 76px;
  height: 76px;
  margin-left: -38px;
  margin-top: -38px;
  pointer-events: none;
}

.particle-item {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  background: center no-repeat;
  background-size: cover;
  animation: tap-effect ease-in-out forwards;
  // stylelint-disable-next-line value-keyword-case
  animation-duration: v-bind(particleTimeCss);
  // stylelint-disable-next-line plugin/no-unsupported-browser-features
  mix-blend-mode: luminosity;

  &.particle-item-circle {
    background-image: url("~/assets/images/tap_effect/shape.png");
  }

  &.delay-2 {
    // stylelint-disable-next-line value-keyword-case
    animation-delay: v-bind(particleDelayCss2);
  }
}

@keyframes tap-effect {
  0% {
    opacity: 0;
    transform: scale(30%);
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(120%);
  }
}
</style>
