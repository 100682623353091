import { useNuxtApp } from '#app';
import { useLog } from '~/composables/useLog';
import { isMobileApp } from '~/libs/platform';
import { useReportAppError, useAuth, useLoading } from '#imports';
import { useAdjustId, useAdvertisingId } from '~/composables/useAdjust';
import { useATT } from '~/composables/useATT';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NativeBridgeCallback = (args: any) => void | Promise<void>;
type NativeBridgeMap = {[k: string]: NativeBridgeCallback};
const logGroup = 'NativeBridge';

/**
 * send待ちデータ
 */
interface SendStack {
  id: number,
  method: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  args: any,
  callback: () => void,
}

export enum PlayerPrefsName {
  gameToken = 'gameToken',
}

/**
 * Native <-> JS連携
 */
export class NativeBridge {
  readonly senderPrefix = 'uniwebview://action?json=';
  protected observers: {[k: string]: NativeBridgeMap} = {};
  /**
   * native連携用のiframe
   * @protected
   */
  protected senderFrame?: HTMLIFrameElement;

  /**
   * native送信用の待ち行列
   */
  protected sendStacks: SendStack[] = [];

  /**
   * 実行中のデータ
   * @protected
   */
  protected executeStack?: SendStack;

  /**
   * 次の実行関数
   * @protected
   */
  protected executeStackCallback: () => void;

  /**
   * 待ち時間(ms)/0なら即時実行
   * @note: 連続で呼び出す場合、iOSだと最後の通知しか行かないため非同期にて待つ処理を追加
   * @protected
   */
  protected sendInterval = 100;

  /**
   * 実行ID番号
   * @protected
   */
  protected sendId = 0;

  /**
   * 実行状態
   * @protected
   */
  protected sendStatus: 'waining' | 'running' = 'waining';

  /**
   * 通知送信の有効有無
   * @protected
   */
  protected isActive = true;

  /**
   * アクティブ状態を取得
   */
  getActive () {
    return this.isActive;
  }

  /**
   * アクティブ状態を変更
   * @param active
   */
  setActive (active: boolean) {
    this.isActive = active;
    useLog(logGroup).debug('NativeBridge.setActive()', this.isActive);
  }

  /**
   * 解放処理
   */
  destroy () {
    if (this.senderFrame) {
      document.body.removeChild(this.senderFrame);
      this.senderFrame = undefined;
    }
    useLog(logGroup).debug('NativeBridge.destroy()');
  }

  constructor () {
    this.executeStackCallback = this.executeStackCallbackFunc.bind(this);
  }

  /**
   * コールバックの実行関数
   * @protected
   */
  protected executeStackCallbackFunc () {
    this.sendStatus = 'waining';
    this.executeStack?.callback();
    this.executeStack = undefined;
    this.sendExecute();
  }

  /**
   * iframe送信の初期化
   */
  getSender () {
    if (!this.senderFrame) {
      const frame = document.createElement('iframe');
      frame.src = 'about:blank';
      frame.width = String(1);
      frame.height = String(1);
      frame.frameBorder = String(0);
      frame.style.cssText = 'width:0; height:0; border:none;';
      this.senderFrame = document.body.appendChild(frame);
    }
    return this.senderFrame;
  }

  /**
   * 送信IDの発番
   * @protected
   */
  protected getSendId () {
    this.sendId += 1;
    return this.sendId;
  }

  /**
   * JSからNative関数の呼び出し
   * @param method
   * @param args
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send (method: string, args: any = {}) {
    return new Promise((resolve) => {
      const id = this.getSendId();
      this.sendStacks.push({
        id,
        method,
        args,
        callback: () => {
          resolve(undefined);
        },
      });
      this.sendExecute();
    });
  }

  /**
   * 送信スタックの消化
   * @protected
   */
  protected sendExecute () {
    if (this.sendStatus !== 'waining') {
      return;
    }
    const data = this.sendStacks.shift();
    if (!data) {
      return;
    }
    return this.sendRequest(data);
  }

  /**
   * nativeへデータ送信
   * @param data
   * @protected
   */
  protected sendRequest (data: SendStack) {
    this.sendStatus = 'running';
    const params = {
      name: data.method,
      params: data.args,
    };
    const url = `${this.senderPrefix}${JSON.stringify(params)}`;
    if (isMobileApp()) {
      useLog(logGroup).debug(`NativeBridge.send(active: ${this.isActive})`, data.id, data.method, data.args);
      if (this.isActive) {
        const sender = this.getSender();
        if (sender) {
          sender.src = url;
        } else {
          location.href = url;
        }
      }
    } else {
      useLog(logGroup).warn('NativeBridge.send only run native app.', data.id, data.method, data.args);
    }
    if (this.sendInterval > 0) {
      this.executeStack = data;
      setTimeout(this.executeStackCallback, this.sendInterval);
    } else {
      data.callback();
      this.sendExecute();
    }
  }

  /**
   * NativeからJS関数の呼び出し
   * @param method
   * @param args
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async call (method: string, args: any = {}) {
    useLog(logGroup).debug('NativeBridge.call', method, args);
    if (method in this.observers) {
      for (const k in this.observers[method]) {
        const r = this.observers[method][k](args);
        if (r instanceof Promise) {
          await r;
        }
      }
    }
  }

  /**
   * Nativeからの呼び出しを監視
   * @param method
   * @param callback
   */
  subscribe (method: string, callback: NativeBridgeCallback) {
    if (!(method in this.observers)) {
      this.observers[method] = {};
    }
    let k = this.generateKey();
    while (k in this.observers[method]) {
      k = this.generateKey();
    }
    this.observers[method][k] = callback;
    return k;
  }

  /**
   * ランダムなキーを生成
   * @protected
   */
  protected generateKey () {
    return Math.random().toString(32).substring(2);
  }

  /**
   * Nativeからの呼び出しを監視を停止
   * @param method
   * @param key
   */
  stopSubscribe (method: string, key: string) {
    if (method in this.observers && key in this.observers[method]) {
      delete this.observers[method][key];
    }
  }
}

/**
 * ネイティブからの呼び出し時のエラー補足
 * @param e
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const catchNativeBridgeCall = (e: any) => {
  useReportAppError(e);
};

/**
 * onPurchaseInit()のパラメータ
 * @see https://docs.unity3d.com/ja/560/ScriptReference/Purchasing.InitializationFailureReason.html
 */
export type PurchaseInitResponseError =
  'PurchasingUnavailable' |
  'NoProductsAvailable' |
  'AppNotKnown';
/**
 * Unityレスポンスからエラーメッセージを取得
 * @param {PurchaseResponseError} error
 */
export const getErrorMessageByPurchaseInitResponseErrorCode = (error: PurchaseInitResponseError | string) => {
  if (error === 'PurchasingUnavailable') {
    return '購入機能が利用できません';
  } else if (error === 'NoProductsAvailable') {
    return 'プロダクトが見つかりません';
  } else if (error === 'AppNotKnown') {
    return '初期化エラーが発生しました';
  }
  return '不明なエラーが発生しました';
};
export enum PurchaseInitResponseResult {
  // 初期化中
  processing = -1,
  // 成功
  success = 1,
  // 失敗
  error = 0,
}
export interface PurchaseInitResponse {
  result: PurchaseInitResponseResult | number,
  productIDList: string[],
  error?: PurchaseInitResponseError | string,
}

/**
 * onPurchaseInit()のパラメータ
 * @see https://docs.unity3d.com/ja/560/ScriptReference/Purchasing.PurchaseFailureReason.html
 */
export type PurchaseResponseError =
  'PurchasingUnavailable' |
  'ExistingPurchasePending' |
  'ProductUnavailable' |
  'SignatureInvalid' |
  'UserCancelled' |
  'PaymentDeclined' |
  'DuplicateTransaction' |
  'Unknown';
/**
 * Unityレスポンスからエラーメッセージを取得
 * @param {PurchaseResponseError} error
 */
export const getErrorMessageByPurchaseResponseErrorCode = (error: PurchaseResponseError | string) => {
  if (error === 'PurchasingUnavailable') {
    return '購入機能が利用できません。';
  } else if (error === 'ExistingPurchasePending') {
    return 'すでに購入処理中です。';
  } else if (error === 'ProductUnavailable') {
    return 'ストアで購入できる商品ではありません。';
  } else if (error === 'SignatureInvalid') {
    return '課金レシートの検証に失敗しました。';
  } else if (error === 'UserCancelled') {
    return '課金がキャンセルされました。';
  } else if (error === 'PaymentDeclined') {
    return '支払いに問題がありました。';
  }
  return '不明なエラーが発生しました。';
};
export enum PurchaseResponseResult {
  // 失敗
  error = 0,
  // 成功
  success = 1,
  // コンビニ決済待ち
  pending = 2,
}
export interface PurchaseResponse {
  productID: string,
  result: PurchaseResponseResult | number,
  error?: PurchaseResponseError | string,
  receipt?: string,
  localizedPrice: number,
  isoCurrencyCode: string,
}

/**
 * AppVersion
 */
export interface AppVersionResponse {
  // バージョン名
  version: string,
  // ビルド番号
  buildNo: string,
}

/**
 * iOS ATT
 */
export enum IOSATTStatus {
  // 失敗
  none = 0, // まだ質問されていない
  limit = 1, // 端末の権限が制限されている
  deny = 2, // アクセスを拒否
  allow = 3, // アクセスを許可
}
export interface IOSATTStatusResponse {
  status: IOSATTStatus,
}

/**
 * Adjust
 */
export interface AdjustResponse {
  // AdjustデバイスID
  adjustID: string,
  // 広告ID(IDFA/AAID)
  advertisingID: string,
}

/**
 * 永続データ
 */
export interface PlayerPrefsResponse {
  // キー
  key: string,
  // 値
  value: string,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type NativeBridgeCallArgs = Map<string, string> | any;

/**
 * 文字列に変換可能なら変換
 * @param {any} param
 * @return {string|undefined}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const toString = (param: any) => {
  if (typeof param === 'string') {
    return param;
  } else if (typeof param === 'number') {
    return String(param);
  }
  return undefined;
};

/**
 * nativeから呼び出す関数を定義
 *
 * @note 何かしらの値を返す必要があります(アプリ側でエラーが出力されます)
 * @note 非同期は対応していない模様
 * @note 引数は1つでオブジェクト型(Map<string, string>)で統一する
 */
export class NativeBridgeCall {
  /**
   * カスタムトークンでの認証処理
   *
   * @param {NativeBridgeCallArgs} args
   */
  auth (args: NativeBridgeCallArgs) {
    if (!args || typeof args !== 'object') {
      useLog(logGroup).warn(`Invalid args auth(${args})`);
      return false;
    }
    const token = toString(args.token);
    if (!token) {
      useLog(logGroup).warn(`Invalid args auth(${args})`);
      return false;
    }
    const key = useLoading().loader.start();
    const provider = toString(args.provider);
    useAuth().signInCustomToken(provider ?? '', token).then(() => {
      useLoading().loader.stop(key);
    }).catch((e) => {
      useLoading().loader.stop(key);
      catchNativeBridgeCall(e);
    });
    nativeBridge.call('auth', args).catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * ログ出力
   *
   * @param {NativeBridgeCallArgs} args
   */
  log (args: NativeBridgeCallArgs) {
    nativeBridge.call('log', args).catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * アプリのバージョン文字列の設定
   * @param {NativeBridgeCallArgs} args
   */
  setAppVersion (args: NativeBridgeCallArgs) {
    let version: string|undefined;
    let buildNo: string|undefined;
    if (args && typeof args === 'object') {
      version = toString(args.version);
      buildNo = toString(args.buildNo);
    }
    if (!version) {
      useLog(logGroup).warn(`Invalid args setAppVersion(${args})`);
      return false;
    }
    const { $storage } = useNuxtApp();
    $storage.save('appVersion', version);
    $storage.save('buildNo', buildNo);
    const params: AppVersionResponse = {
      version: version ?? '',
      buildNo: buildNo ?? '',
    };
    nativeBridge.call('setAppVersion', params).catch(catchNativeBridgeCall);
    return true;
  }

  // /**
  //  * 認証の結果を受け取る
  //  *
  //  * @param {NativeBridgeCallArgs} args
  //  */
  // onSignIn (args: NativeBridgeCallArgs) {
  //   const params = {
  //     idToken,
  //     provider,
  //     errorCode,
  //   };
  //   nativeBridge.call('onSignIn', params).catch(catchNativeBridgeCall);
  //   return true;
  // }

  /**
   * UnityIAPの初期化結果を受け取る
   *
   * @param {NativeBridgeCallArgs} args
   */
  onPurchaseInit (args: NativeBridgeCallArgs) {
    if (!args || typeof args !== 'object') {
      useLog(logGroup).warn(`Invalid args onPurchaseInit(${args})`);
      return false;
    }
    const params: PurchaseInitResponse = {
      result: args.result ?? PurchaseInitResponseResult.error,
      productIDList: args.productIDList ?? [],
      error: toString(args.error),
    };
    nativeBridge.call('onPurchaseInit', params).catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * フォアグランドに移動した際に呼ばれる
   */
  onWindowFocus () {
    nativeBridge.call('onWindowFocus').catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * バックグランドに移動した際に呼ばれる
   */
  onWindowBlur () {
    nativeBridge.call('onWindowBlur').catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * アイテムの購入結果を受け取る
   *
   * @param {NativeBridgeCallArgs} args
   */
  onPurchase (args: NativeBridgeCallArgs) {
    if (!args || typeof args !== 'object') {
      useLog(logGroup).warn(`Invalid args onPurchase(${args})`);
      return false;
    }
    const params: PurchaseResponse = {
      productID: args.productID ?? '',
      result: args.result ?? PurchaseResponseResult.error,
      receipt: toString(args.receipt),
      error: toString(args.error),
      localizedPrice: args.localizedPrice ?? 0,
      isoCurrencyCode: args.isoCurrencyCode ?? '',
    };
    nativeBridge.call('onPurchase', params).catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * iOS ATTモーダルを表示する必要がある場合に呼ばれる
   *
   * @return {boolean}
   */
  onIOSATT () {
    // iOS ATTモーダルを表示させるよう設定
    useATT().set(true);
    nativeBridge.call('onIOSATT').catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * iOS ATTモーダルの選択結果を設定する
   *
   * @return {boolean}
   */
  setIOSATTStatus (args: NativeBridgeCallArgs) {
    if (!args || typeof args !== 'object') {
      useLog(logGroup).warn(`Invalid args setIOSATTStatus(${args})`);
      return false;
    }
    const params: IOSATTStatusResponse = {
      status: args.status ?? IOSATTStatus.none,
    };
    nativeBridge.call('setIOSATTStatus', params).catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * AdjustデバイスID、広告ID(IDFA/AAID)を設定する
   *
   * @return {boolean}
   */
  setAdjustID (args: NativeBridgeCallArgs) {
    if (!args || typeof args !== 'object') {
      useLog(logGroup).warn(`Invalid args setAdjustID(${args})`);
      return false;
    }
    const params: AdjustResponse = {
      adjustID: args.adjustID ?? '',
      advertisingID: args.advertisingID ?? '',
    };
    // 広告ID,AdjustIDの設定
    useAdjustId().value = params.adjustID;
    useAdvertisingId().value = params.advertisingID;

    nativeBridge.call('setAdjustID', params).catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * Androidのバックキーが押された場合に呼ばれる
   *
   * @return {boolean}
   */
  onAndroidBack () {
    nativeBridge.call('onAndroidBack').catch(catchNativeBridgeCall);
    return true;
  }

  /**
   * 永続データを取得した際に呼ばれる
   *
   * @return {boolean}
   */
  onGetPlayerPrefs (args: NativeBridgeCallArgs) {
    if (!args || typeof args !== 'object') {
      useLog(logGroup).warn(`Invalid args onGetPlayerPrefs(${args})`);
      return false;
    }
    const params: PlayerPrefsResponse = {
      key: args.key ?? '',
      value: args.value ?? '',
    };
    nativeBridge.call('onGetPlayerPrefs', params).catch(catchNativeBridgeCall);
    return true;
  }

//   /**
//    * SafeAreaの設定を行う
//    *
//    * @param {NativeBridgeCallArgs} args
//    * @return {boolean}
//    */
//   setSafeArea (args: NativeBridgeCallArgs) {
//     const style = document.createElement('style');
//     style.type = 'text/css';
//     style.innerHTML = `
// :root {
//   --safe-area-top: ${top}px;
//   --safe-area-right: ${right}px;
//   --safe-area-bottom: ${bottom}px;
//   --safe-area-left: ${left}px;
// }`;
//     document.head.appendChild(style);
//     nativeBridge.call('setSafeArea').catch(catchNativeBridgeCall);
//     return true;
//   }
}

export const nativeBridge = new NativeBridge();
export const nativeBridgeCall = new NativeBridgeCall();
