export type ResourceType = 'texture' | 'voice' | 'bgm' | 'se';
/**
 * リソースパスを生成
 * @param {ResourceType} type
 * @param {string|null} asset
 * @param {number} partition
 * @param {string} filename
 * @param {string} extension
 */
export const resourcePath = (type: ResourceType, asset: string|null, partition: number, filename: string, extension: string) => {
  return `/${type}/${asset ? `${asset}/` : ''}${partition}/${filename}.${extension}`;
};
