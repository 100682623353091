/**
 * イベントの開催期限
 * @param limitDate
 */
export const getLimitDateWord = (limitDate: string) => {
  return `${limitDate}まで開催中`;
};

/**
 * 特典種別一覧
 */
export const BonusType = {
  firstEntryBonus: 'feb', // 初日エントリー参加特典
  entryBonus: 'eb', // エントリー特典
  // firstEndBonus: '1eb', // 初回ENDクリア特典（以降、数字が増える度に◯ENDクリア特典）
  allEndBonus: 'aeb', // 全ENDクリア特典
  earlyAllEndBonus: 'eab', // 早期全ENDクリア特典
  allPremiumBonus: 'apeb', // 全PremiereENDクリア特典
  allSweetEndBonus: 'aseb', // 全SweetENDクリア特典
  earlyAllPremiumBonus: 'eapb', // 早期全PremiereENDクリア特典
  earlyAllSweetBonus: 'easb', // 早期全SweetENDクリア特典
  earlyGoldBonus: 'egb', // 早期クリア特典ゴールド
  earlySilverBonus: 'esb', // 早期クリア特典シルバー
  premiumEndBonus: 'peb', // PremiereENDクリア特典
  sweetEndBonus: 'seb', // SweetENDクリア特典
  bothEndBonus: 'beb', // 両(Premiere&Sweet)ENDクリア特典
  lastRankingBonus: 'lrb', // ランキング特典
  favorite: 'favo', // イベント好感度達成特典
  parameterClear: 'pclear', // イベントパラメータ達成特典
  route2EndBonus: 'r2eb', // Part1＆Part2連動クリア特典
  secretEndBonus: 'sceb', // SecretEND特典
  allSecretEndBonus: 'asceb', // 全SecretENDクリア特典
  earlyAllSecretEndBonus: 'eascb', // 早期全SecretEND特典
  allEpilogue: 'aepi', // 全エピローグ購入特典
  // distinctPrefix: 'd1eb', // 重複なし1ENDクリア特典（以降、数字が増える度に重複なし◯ENDクリア特典）
  // firstEntryBonus: 'ed1eb', // 早期重複なし1ENDクリア特典（以降、数字が増える度に早期重複なし◯ENDクリア特典）
};
/**
 * マイページで表示する次の特典種別
 */
export const NextBonusTypeList = [
  BonusType.earlyAllPremiumBonus,
  BonusType.earlyAllSweetBonus,
  BonusType.earlyAllEndBonus,
  BonusType.earlyGoldBonus,
  BonusType.earlySilverBonus,
  BonusType.allEndBonus,
  BonusType.lastRankingBonus,
];
/**
 * マイページで表示する次の特典テキスト
 */
export const BonusTypeNameMypage = {
  allEndBonus: '全ENDクリアでGET', // 全ENDクリア特典
  earlyAllEndBonus: '早期全END\n特典GETまで', // 早期全ENDクリア特典
  earlyAllPremiumBonus: '早期全プレミアムEND\n特典GETまで', // 早期全PremiereENDクリア特典
  earlyAllSweetBonus: '早期全スペシャルEND\n特典GETまで', // 早期全SweetENDクリア特典
  earlyGoldBonus: '早期指定END特典GETまで', // 早期クリア特典ゴールド
  earlySilverBonus: '早期指定END特典GETまで', // 早期クリア特典シルバー
  lastRankingBonus: 'ランキング入賞でGET', // ランキング特典
};
export const getNextBonusTypeNameMypage = (type: string) => {
  switch (type) {
    case BonusType.allEndBonus: return BonusTypeNameMypage.allEndBonus;
    case BonusType.earlyAllEndBonus: return BonusTypeNameMypage.earlyAllEndBonus;
    case BonusType.earlyAllPremiumBonus: return BonusTypeNameMypage.earlyAllPremiumBonus;
    case BonusType.earlyAllSweetBonus: return BonusTypeNameMypage.earlyAllSweetBonus;
    case BonusType.earlyGoldBonus: return BonusTypeNameMypage.earlyGoldBonus;
    case BonusType.earlySilverBonus: return BonusTypeNameMypage.earlySilverBonus;
    case BonusType.lastRankingBonus: return BonusTypeNameMypage.lastRankingBonus;
  }
};

export const FeverType = {
  all: 'all', // 全体フィーバー
  afterCharaSelect: 'acs', // イベント参加後フィーバー
  item: 'item', // アイテム使用によるフィーバー
  eventMinigameP: 'emp', // ラブ度達成によるフィーバー
};

// EventStoryConfig の実装
export const eventStoryConfig = {
  // 分岐ルートの名称
  routeName: {
    en: {
      ROUTE_A: 'Sweet END',
      ROUTE_B: 'Premium END',
      ROUTE_C: 'Secret END',
    },
    ja: {
      ROUTE_A: 'スウィートEND',
      ROUTE_B: 'プレミアムEND',
      ROUTE_C: 'シークレットEND',
    },
  },
};
// 同一イベントフィーバー請求を取りやめる期間
export const eventFeverAppealSleepTime = 3600 * 6;
