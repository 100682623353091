/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * リーダーカード情報
 * @export
 * @interface UserDeckRentalLeaderCard
 */
export interface UserDeckRentalLeaderCard {
    /**
     * カードネーム
     * @type {string}
     * @memberof UserDeckRentalLeaderCard
     */
    name: string;
    /**
     * キャラコード
     * @type {string}
     * @memberof UserDeckRentalLeaderCard
     */
    charaCd: string;
    /**
     * カードコード
     * @type {string}
     * @memberof UserDeckRentalLeaderCard
     */
    cardCd: string;
    /**
     * カード説明
     * @type {string}
     * @memberof UserDeckRentalLeaderCard
     */
    getSituation: string;
    /**
     * 合成回数
     * @type {number}
     * @memberof UserDeckRentalLeaderCard
     */
    synthesisCount: number;
    /**
     * パラメータ
     * @type {number}
     * @memberof UserDeckRentalLeaderCard
     */
    parameter: number;
    /**
     * イラスト変更フラグ 1:変更可 0:変更不可
     * @type {boolean}
     * @memberof UserDeckRentalLeaderCard
     */
    changeIllustFlg: boolean;
    /**
     * カードリソースパーティション
     * @type {number}
     * @memberof UserDeckRentalLeaderCard
     */
    resourcePartition: number;
    /**
     * レアリティ
     * @type {number}
     * @memberof UserDeckRentalLeaderCard
     */
    rarity: number;
}

/**
 * Check if a given object implements the UserDeckRentalLeaderCard interface.
 */
export function instanceOfUserDeckRentalLeaderCard(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "cardCd" in value;
    isInstance = isInstance && "getSituation" in value;
    isInstance = isInstance && "synthesisCount" in value;
    isInstance = isInstance && "parameter" in value;
    isInstance = isInstance && "changeIllustFlg" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "rarity" in value;

    return isInstance;
}

export function UserDeckRentalLeaderCardFromJSON(json: any): UserDeckRentalLeaderCard {
    return UserDeckRentalLeaderCardFromJSONTyped(json, false);
}

export function UserDeckRentalLeaderCardFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDeckRentalLeaderCard {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'charaCd': json['chara_cd'],
        'cardCd': json['card_cd'],
        'getSituation': json['get_situation'],
        'synthesisCount': json['synthesis_count'],
        'parameter': json['parameter'],
        'changeIllustFlg': json['change_illust_flg'],
        'resourcePartition': json['resource_partition'],
        'rarity': json['rarity'],
    };
}

export function UserDeckRentalLeaderCardToJSON(value?: UserDeckRentalLeaderCard | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'chara_cd': value.charaCd,
        'card_cd': value.cardCd,
        'get_situation': value.getSituation,
        'synthesis_count': value.synthesisCount,
        'parameter': value.parameter,
        'change_illust_flg': value.changeIllustFlg,
        'resource_partition': value.resourcePartition,
        'rarity': value.rarity,
    };
}

