import { useAuth } from '~/composables/useAuth';
import { authConfig } from '~/configs/auth';

export default defineNuxtRouteMiddleware((to, _) => {
  if (!process.client) {
    return;
  }
  const auth = useAuth();
  const isAuth = auth.isRequiredAuth(to);
  // 未認証で認証が必要なrouteの場合は認証ページへリダイレクト
  if (!auth.getCurrentUser() && isAuth) {
    return navigateTo(authConfig.redirectSignIn, { replace: true, });
  }
  // if (token.value && !isAuth) {
  //   return await navigateTo(authConfig.redirectTo, { replace: true, });
  // }
});
