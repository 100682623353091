<template>
  <ModalAlert
    :show="props.show"
    title="メンテナンス"
    ok-text="タイトル画面へ"
    :z-index="props.zIndex"
    @click-ok="onClick"
  >
    <div v-if="props.error" class="text-center">
      <CommonText v-if="message" class="mt-4" :text="message" />
      <ModalErrorDebugErrorText :error="props.error" />
    </div>
  </ModalAlert>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  error: Error | null,
  show?: boolean,
  // eslint-disable-next-line vue/require-default-prop
  zIndex?: number,
}>(), {
  show: false,
});
interface Emits {
  (e: 'click'): void;
}
const emit = defineEmits<Emits>();

const message = computed(() => {
  return props.error?.message ?? '';
});
const onClick = () => {
  emit('click');
};
</script>
