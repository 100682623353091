/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoryNeedKey } from './StoryNeedKey';
import {
    StoryNeedKeyFromJSON,
    StoryNeedKeyFromJSONTyped,
    StoryNeedKeyToJSON,
} from './StoryNeedKey';
import type { StoryNeedTicket } from './StoryNeedTicket';
import {
    StoryNeedTicketFromJSON,
    StoryNeedTicketFromJSONTyped,
    StoryNeedTicketToJSON,
} from './StoryNeedTicket';

/**
 * 1チケット読むのに必要なチケット数など
 * @export
 * @interface StoryNeed
 */
export interface StoryNeed {
    /**
     * 
     * @type {StoryNeedTicket}
     * @memberof StoryNeed
     */
    ticket: StoryNeedTicket;
    /**
     * 
     * @type {StoryNeedKey}
     * @memberof StoryNeed
     */
    key: StoryNeedKey | null;
}

/**
 * Check if a given object implements the StoryNeed interface.
 */
export function instanceOfStoryNeed(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ticket" in value;
    isInstance = isInstance && "key" in value;

    return isInstance;
}

export function StoryNeedFromJSON(json: any): StoryNeed {
    return StoryNeedFromJSONTyped(json, false);
}

export function StoryNeedFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryNeed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ticket': StoryNeedTicketFromJSON(json['ticket']),
        'key': StoryNeedKeyFromJSON(json['key']),
    };
}

export function StoryNeedToJSON(value?: StoryNeed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ticket': StoryNeedTicketToJSON(value.ticket),
        'key': StoryNeedKeyToJSON(value.key),
    };
}

