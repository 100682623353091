/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserEventMinigameHistoryResponseData } from './UserEventMinigameHistoryResponseData';
import {
    UserEventMinigameHistoryResponseDataFromJSON,
    UserEventMinigameHistoryResponseDataFromJSONTyped,
    UserEventMinigameHistoryResponseDataToJSON,
} from './UserEventMinigameHistoryResponseData';

/**
 * 
 * @export
 * @interface UserEventMinigameHistoryResponse
 */
export interface UserEventMinigameHistoryResponse {
    /**
     * 
     * @type {UserEventMinigameHistoryResponseData}
     * @memberof UserEventMinigameHistoryResponse
     */
    data: UserEventMinigameHistoryResponseData;
}

/**
 * Check if a given object implements the UserEventMinigameHistoryResponse interface.
 */
export function instanceOfUserEventMinigameHistoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserEventMinigameHistoryResponseFromJSON(json: any): UserEventMinigameHistoryResponse {
    return UserEventMinigameHistoryResponseFromJSONTyped(json, false);
}

export function UserEventMinigameHistoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventMinigameHistoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserEventMinigameHistoryResponseDataFromJSON(json['data']),
    };
}

export function UserEventMinigameHistoryResponseToJSON(value?: UserEventMinigameHistoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserEventMinigameHistoryResponseDataToJSON(value.data),
    };
}

