/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserFriendLikeSend
 */
export interface UserFriendLikeSend {
    /**
     * 送信相手の名前(ニックネーム)
     * @type {string}
     * @memberof UserFriendLikeSend
     */
    sendNickname: string;
    /**
     * 送信いいね数
     * @type {number}
     * @memberof UserFriendLikeSend
     */
    sendLikeCount: number;
    /**
     * 獲得サイト内通貨
     * @type {number}
     * @memberof UserFriendLikeSend
     */
    getGold: number;
    /**
     * 獲得タイトル名
     * @type {string}
     * @memberof UserFriendLikeSend
     */
    title: string;
    /**
     * メッセージ
     * @type {string}
     * @memberof UserFriendLikeSend
     */
    message: string;
    /**
     * 送信できたユーザーのプレイヤーコードの配列
     * @type {Array<string>}
     * @memberof UserFriendLikeSend
     */
    successPlayerCode: Array<string>;
}

/**
 * Check if a given object implements the UserFriendLikeSend interface.
 */
export function instanceOfUserFriendLikeSend(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sendNickname" in value;
    isInstance = isInstance && "sendLikeCount" in value;
    isInstance = isInstance && "getGold" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "successPlayerCode" in value;

    return isInstance;
}

export function UserFriendLikeSendFromJSON(json: any): UserFriendLikeSend {
    return UserFriendLikeSendFromJSONTyped(json, false);
}

export function UserFriendLikeSendFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendLikeSend {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sendNickname': json['send_nickname'],
        'sendLikeCount': json['send_like_count'],
        'getGold': json['get_gold'],
        'title': json['title'],
        'message': json['message'],
        'successPlayerCode': json['success_player_code'],
    };
}

export function UserFriendLikeSendToJSON(value?: UserFriendLikeSend | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'send_nickname': value.sendNickname,
        'send_like_count': value.sendLikeCount,
        'get_gold': value.getGold,
        'title': value.title,
        'message': value.message,
        'success_player_code': value.successPlayerCode,
    };
}

