/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FriendFollowRequest
 */
export interface FriendFollowRequest {
    /**
     * フォローするフレンドのプレイヤーコードの配列
     * @type {Array<string>}
     * @memberof FriendFollowRequest
     */
    friendList: Array<string>;
}

/**
 * Check if a given object implements the FriendFollowRequest interface.
 */
export function instanceOfFriendFollowRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "friendList" in value;

    return isInstance;
}

export function FriendFollowRequestFromJSON(json: any): FriendFollowRequest {
    return FriendFollowRequestFromJSONTyped(json, false);
}

export function FriendFollowRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): FriendFollowRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'friendList': json['friend_list'],
    };
}

export function FriendFollowRequestToJSON(value?: FriendFollowRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'friend_list': value.friendList,
    };
}

