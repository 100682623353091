<template>
  <ThePage v-if="props.show" class="overlay-page top-0 left-0 right-0" :style="getStyles()">
    <div class="overlay overflow-x-hidden overflow-y-auto flex flex-col main full-window">
      <div class="w-full h-full flex justify-center" :class="{ 'safe-area-all': props.full, }">
        <div class="app-container">
          <slot />
        </div>
      </div>
    </div>
  </ThePage>
</template>

<script lang="ts" setup>
const props = withDefaults(defineProps<{
  show?: boolean,
  zIndex?: number,
  full?: boolean,
}>(), {
  show: false,
  zIndex: undefined,
  full: true,
});
const getStyles = () => {
  return {
    'z-index': props.zIndex,
  };
};
</script>

<style lang="scss" scoped>
@use "assets/css/base" as base;

.overlay-page {
  @include base.zIndexFromLayer("overlay", 0);

  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 66%);
  position: absolute !important;
}
</style>
