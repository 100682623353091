/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Event } from './Event';
import {
    EventFromJSON,
    EventFromJSONTyped,
    EventToJSON,
} from './Event';

/**
 * 
 * @export
 * @interface EventListMstResponse
 */
export interface EventListMstResponse {
    /**
     * 
     * @type {Array<Event>}
     * @memberof EventListMstResponse
     */
    data: Array<Event>;
}

/**
 * Check if a given object implements the EventListMstResponse interface.
 */
export function instanceOfEventListMstResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function EventListMstResponseFromJSON(json: any): EventListMstResponse {
    return EventListMstResponseFromJSONTyped(json, false);
}

export function EventListMstResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventListMstResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(EventFromJSON)),
    };
}

export function EventListMstResponseToJSON(value?: EventListMstResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(EventToJSON)),
    };
}

