/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MetaCommon } from './MetaCommon';
import {
    MetaCommonFromJSON,
    MetaCommonFromJSONTyped,
    MetaCommonToJSON,
} from './MetaCommon';
import type { UserShopItem } from './UserShopItem';
import {
    UserShopItemFromJSON,
    UserShopItemFromJSONTyped,
    UserShopItemToJSON,
} from './UserShopItem';

/**
 * 
 * @export
 * @interface ShopItemListResponse
 */
export interface ShopItemListResponse {
    /**
     * 
     * @type {Array<UserShopItem>}
     * @memberof ShopItemListResponse
     */
    data: Array<UserShopItem>;
    /**
     * 
     * @type {MetaCommon}
     * @memberof ShopItemListResponse
     */
    meta: MetaCommon;
}

/**
 * Check if a given object implements the ShopItemListResponse interface.
 */
export function instanceOfShopItemListResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "meta" in value;

    return isInstance;
}

export function ShopItemListResponseFromJSON(json: any): ShopItemListResponse {
    return ShopItemListResponseFromJSONTyped(json, false);
}

export function ShopItemListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShopItemListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserShopItemFromJSON)),
        'meta': MetaCommonFromJSON(json['meta']),
    };
}

export function ShopItemListResponseToJSON(value?: ShopItemListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(UserShopItemToJSON)),
        'meta': MetaCommonToJSON(value.meta),
    };
}

