/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuCharaReadRequest
 */
export interface CommuCharaReadRequest {
    /**
     * キャラコード
     * @type {string}
     * @memberof CommuCharaReadRequest
     */
    charaCd: string;
}

/**
 * Check if a given object implements the CommuCharaReadRequest interface.
 */
export function instanceOfCommuCharaReadRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "charaCd" in value;

    return isInstance;
}

export function CommuCharaReadRequestFromJSON(json: any): CommuCharaReadRequest {
    return CommuCharaReadRequestFromJSONTyped(json, false);
}

export function CommuCharaReadRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuCharaReadRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'charaCd': json['chara_cd'],
    };
}

export function CommuCharaReadRequestToJSON(value?: CommuCharaReadRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chara_cd': value.charaCd,
    };
}

