import { binaryConfig } from '~/configs/app';

export * from '~/libs/device';

/**
 * プラットフォーム種別
 */
export enum Platform {
  ios = 1,
  android,
  animate,
}

/**
 * サーバー情報
 */
export enum Server {
  Local = 'local',
  Dev = 'dev',
  Staging = 'staging',
  StagingLoad = 'stgload',
  Production = 'production',
}

let platform: Platform;
/**
 * プラットフォーム種別を取得(ドメインから取得)
 */
export const getPlatform = () => {
  if (platform) {
    return platform;
  }
  const pattern = useRuntimeConfig().public.platform.pattern;
  if (typeof pattern !== 'string') {
    return null;
  }
  const matches = location.host.match(new RegExp(pattern, 'i'));
  if (!matches || matches.length <= 1) {
    return null;
  }
  const str = matches[2];
  switch (str) {
    case 'app': platform = Platform.ios; break;
    case 'gl': platform = Platform.android; break;
    case 'anm': platform = Platform.animate; break;
    default: return null;
  }
  return platform;
};

/**
 * APIのURLを取得
 */
export const getApiUrl = () => {
  const platform = getPlatform();
  const urls = useRuntimeConfig().public.platform.apiUrl;
  switch (platform) {
    case Platform.ios: return urls.ios;
    case Platform.android: return urls.android;
    case Platform.animate: return urls.animate;
  }
  // デフォルトのURLを返す
  return useRuntimeConfig().public.apiUrl;
};

/**
 * APIのURLを取得
 */
export const getApiUrlPath = (path = '') => {
  return `${getApiUrl()}${useRuntimeConfig().public.apiPath}${path}`;
};

/**
 * アニメイトの認証URLを取得
 */
export const getAnimateOAuthUrl = () => {
  const path = useRuntimeConfig().public.firebase.animate.oauthPath;
  return `${getApiUrl()}${path}`;
};

/**
 * リソースのURLを取得
 */
export const getResourceUrl = () => {
  const platform = getPlatform();
  const urls = useRuntimeConfig().public.platform.resourceUrl;
  switch (platform) {
    case Platform.ios: return urls.ios;
    case Platform.android: return urls.android;
    case Platform.animate: return urls.animate;
  }
  // デフォルトのURLを返す
  return useRuntimeConfig().public.resourceUrl;
};

/**
 * ストーリー（アドベンチャーパート）のURLを取得
 */
export const getStoryUrl = () => {
  const platform = getPlatform();
  const urls = useRuntimeConfig().public.platform.storyUrl;
  switch (platform) {
    case Platform.ios: return urls.ios;
    case Platform.android: return urls.android;
    case Platform.animate: return urls.animate;
  }
  // デフォルトのURLを返す
  return useRuntimeConfig().public.storyUrl;
};

/**
 * ストーリー（アドベンチャーパート）向けCookieのドメインを取得
 */
export const getStoryCookieDomain = () => {
  const platform = getPlatform();
  const urls = useRuntimeConfig().public.platform.cookieDomain;
  switch (platform) {
    case Platform.ios: return urls.ios;
    case Platform.android: return urls.android;
    case Platform.animate: return urls.animate;
  }
  // デフォルトのURLを返す
  return useRuntimeConfig().public.cookieDomain;
};

/**
 * モバイルアプリか判別
 */
export const isMobileApp = () => {
  const platform = getPlatform();
  if (platform === null) {
    return false;
  }
  return [Platform.ios, Platform.android].includes(platform);
};

/**
 * iOS端末か判別
 */
export const isIOS = () => {
  return getPlatform() === Platform.ios;
};

/**
 * iOS端末か判別
 */
export const isAndroid = () => {
  return getPlatform() === Platform.android;
};

/**
 * アニメイトプラットフォームか判別
 */
export const isAnimatePlatform = () => {
  return getPlatform() === Platform.animate;
};

/**
 * プラットフォーム種別を文字列で取得
 */
export const getPlatformString = () => {
  switch (getPlatform()) {
    case Platform.ios: return 'ios';
    case Platform.android: return 'android';
    case Platform.animate: return 'animate';
  }
  return null;
};

/**
 * プラットフォーム種別を表示用テキスト文字列で取得
 */
export const getPlatformText = () => {
  switch (getPlatform()) {
    case Platform.ios: return 'iOS';
    case Platform.android: return 'Android';
    case Platform.animate: return 'アニメイト';
  }
  return null;
};

/**
 * サーバー種別を取得(ドメインから取得)
 */
export const getServer = () => {
  const hostname = location.host.split(':')[0] ?? '';
  if (hostname === 'localhost') {
    return Server.Local;
  }
  const matches = hostname.match(/^(.+?)-/i);
  if (!matches || matches.length <= 1) {
    return Server.Production;
  }
  const str = matches[1];
  switch (str) {
    case 'local': return Server.Local;
    case 'dev': return Server.Dev;
    case 'stg': return Server.Staging;
    case 'stgload': return Server.StagingLoad;
  }
  return Server.Production;
};

/**
 * ローカルサーバーか
 */
export const isLocalServer = () => {
  return getServer() === Server.Local;
};

/**
 * 開発サーバーか
 */
export const isDevServer = () => {
  return getServer() === Server.Dev;
};

/**
 * STGサーバーか
 */
export const isStagingServer = () => {
  return getServer() === Server.Staging;
};

/**
 * 本番サーバーか
 */
export const isProductionServer = () => {
  return getServer() === Server.Production;
};

/**
 * バージョン情報URLを取得
 */
export const getBinaryUrl = (): string|undefined => {
  const platform = getPlatform();
  const urls = useRuntimeConfig().public.platform.apiUrl;
  switch (platform) {
    case Platform.ios: return `${urls.ios}${binaryConfig.uri.ios}`;
    case Platform.android: return `${urls.android}${binaryConfig.uri.android}`;
  }
};

/**
 * バージョン文字列比較処理
 * @description 数値のみ、もしくは「.」区切りの数値を対象とする
 */
export const versionCompare = (a: string, b: string): number => {
  if (a === b) {
    return 0;
  }
  // バージョンを"."でユニットに切り分ける
  const aUnits = a.split('.');
  const bUnits = b.split('.');
  // ユニット数が少ない方の表記に探索幅を合わせる
  const len = Math.min(aUnits.length, bUnits.length);
  // 探索幅に従ってユニット毎に比較していく
  for (let i = 0; i < len; i++) {
    const a = parseInt(aUnits[i]);
    const b = parseInt(bUnits[i]);
    // A > B
    if (a > b) {
      return 1;
    }
    // B > A
    if (a < b) {
      return -1;
    }
  }
  // 個々のユニットが完全に一致している場合はユニット数が多い方が大きいとする
  if (aUnits.length > bUnits.length) {
    return 1;
  }
  if (aUnits.length < bUnits.length) {
    return -1;
  }
  // 上記の処理で判定仕切れない場合は同一とみなす
  return 0;
};

/**
 * アドベンチャーパートの再生モードを取得
 * @return boolean
 * true: レガシーモード。vueからtyranoに遷移する時、実質ページのリダイレクトを行う。location.hrefを書き換える。画面遷移に時間が掛かる。
 * false: 新モード。vueからtyranoに遷移する時、実質tyranoを子のiframeで立ち上げる。vueの親frameを保持したままで、子のtyranoのiframeを全画面に表示させる。
 *        画面遷移の時間は短くなるがiOSではクラッシュが増える傾向にある。
 */
export const getStoryLegacyMode = () => {
  const platform = getPlatform();
  const modes = useRuntimeConfig().public.platform.storyLegacyMode;
  switch (platform) {
    case Platform.ios: return modes.ios;
    case Platform.android: return modes.android;
    case Platform.animate: return modes.animate;
  }
  // デフォルトのbooleanを返す
  return useRuntimeConfig().public.storyLegacyMode;
};
