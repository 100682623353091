/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventEpiloguePurchaseStatus } from './EventEpiloguePurchaseStatus';
import {
    EventEpiloguePurchaseStatusFromJSON,
    EventEpiloguePurchaseStatusFromJSONTyped,
    EventEpiloguePurchaseStatusToJSON,
} from './EventEpiloguePurchaseStatus';

/**
 * 
 * @export
 * @interface UserEventEpilogueChara
 */
export interface UserEventEpilogueChara {
    /**
     * エピローグアイテムコード
     * @type {string}
     * @memberof UserEventEpilogueChara
     */
    epilogItemCd: string;
    /**
     * キャラコード
     * @type {string}
     * @memberof UserEventEpilogueChara
     */
    charaCd: string;
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof UserEventEpilogueChara
     */
    eventCharaCd: string;
    /**
     * 
     * @type {EventEpiloguePurchaseStatus}
     * @memberof UserEventEpilogueChara
     */
    purchaseStatus: EventEpiloguePurchaseStatus;
}

/**
 * Check if a given object implements the UserEventEpilogueChara interface.
 */
export function instanceOfUserEventEpilogueChara(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "epilogItemCd" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "purchaseStatus" in value;

    return isInstance;
}

export function UserEventEpilogueCharaFromJSON(json: any): UserEventEpilogueChara {
    return UserEventEpilogueCharaFromJSONTyped(json, false);
}

export function UserEventEpilogueCharaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventEpilogueChara {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'epilogItemCd': json['epilog_item_cd'],
        'charaCd': json['chara_cd'],
        'eventCharaCd': json['event_chara_cd'],
        'purchaseStatus': EventEpiloguePurchaseStatusFromJSON(json['purchase_status']),
    };
}

export function UserEventEpilogueCharaToJSON(value?: UserEventEpilogueChara | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'epilog_item_cd': value.epilogItemCd,
        'chara_cd': value.charaCd,
        'event_chara_cd': value.eventCharaCd,
        'purchase_status': EventEpiloguePurchaseStatusToJSON(value.purchaseStatus),
    };
}

