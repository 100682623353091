/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserMinigameTopDeckListCardListInner } from './UserMinigameTopDeckListCardListInner';
import {
    UserMinigameTopDeckListCardListInnerFromJSON,
    UserMinigameTopDeckListCardListInnerFromJSONTyped,
    UserMinigameTopDeckListCardListInnerToJSON,
} from './UserMinigameTopDeckListCardListInner';

/**
 * 
 * @export
 * @interface UserMinigameTopDeck
 */
export interface UserMinigameTopDeck {
    /**
     * デッキ番号
     * @type {number}
     * @memberof UserMinigameTopDeck
     */
    deckNo: number;
    /**
     * デッキ名称
     * @type {string}
     * @memberof UserMinigameTopDeck
     */
    deckName: string;
    /**
     * メインデッキフラグ
     * @type {boolean}
     * @memberof UserMinigameTopDeck
     */
    mainDeckFlg: boolean;
    /**
     * デッキの合計魅力値
     * @type {number}
     * @memberof UserMinigameTopDeck
     */
    deckP: number;
    /**
     * 
     * @type {Array<UserMinigameTopDeckListCardListInner>}
     * @memberof UserMinigameTopDeck
     */
    cardList: Array<UserMinigameTopDeckListCardListInner>;
    /**
     * 予想最低獲得修練度
     * @type {number}
     * @memberof UserMinigameTopDeck
     */
    expectGetMinigameP: number;
    /**
     * 予想最高獲得修練度
     * @type {number}
     * @memberof UserMinigameTopDeck
     */
    expectMaxMinigameP: number;
}

/**
 * Check if a given object implements the UserMinigameTopDeck interface.
 */
export function instanceOfUserMinigameTopDeck(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "deckNo" in value;
    isInstance = isInstance && "deckName" in value;
    isInstance = isInstance && "mainDeckFlg" in value;
    isInstance = isInstance && "deckP" in value;
    isInstance = isInstance && "cardList" in value;
    isInstance = isInstance && "expectGetMinigameP" in value;
    isInstance = isInstance && "expectMaxMinigameP" in value;

    return isInstance;
}

export function UserMinigameTopDeckFromJSON(json: any): UserMinigameTopDeck {
    return UserMinigameTopDeckFromJSONTyped(json, false);
}

export function UserMinigameTopDeckFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserMinigameTopDeck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deckNo': json['deck_no'],
        'deckName': json['deck_name'],
        'mainDeckFlg': json['main_deck_flg'],
        'deckP': json['deck_p'],
        'cardList': ((json['card_list'] as Array<any>).map(UserMinigameTopDeckListCardListInnerFromJSON)),
        'expectGetMinigameP': json['expect_get_minigame_p'],
        'expectMaxMinigameP': json['expect_max_minigame_p'],
    };
}

export function UserMinigameTopDeckToJSON(value?: UserMinigameTopDeck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deck_no': value.deckNo,
        'deck_name': value.deckName,
        'main_deck_flg': value.mainDeckFlg,
        'deck_p': value.deckP,
        'card_list': ((value.cardList as Array<any>).map(UserMinigameTopDeckListCardListInnerToJSON)),
        'expect_get_minigame_p': value.expectGetMinigameP,
        'expect_max_minigame_p': value.expectMaxMinigameP,
    };
}

