/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommuBonusRequest
 */
export interface CommuBonusRequest {
    /**
     * 付与する報酬リスト
     * @type {Array<any>}
     * @memberof CommuBonusRequest
     */
    bonusList?: Array<any>;
}

/**
 * Check if a given object implements the CommuBonusRequest interface.
 */
export function instanceOfCommuBonusRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CommuBonusRequestFromJSON(json: any): CommuBonusRequest {
    return CommuBonusRequestFromJSONTyped(json, false);
}

export function CommuBonusRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CommuBonusRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bonusList': !exists(json, 'bonus_list') ? undefined : json['bonus_list'],
    };
}

export function CommuBonusRequestToJSON(value?: CommuBonusRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bonus_list': value.bonusList,
    };
}

