/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserItem
 */
export interface UserItem {
    /**
     * アイテムコード
     * @type {string}
     * @memberof UserItem
     */
    itemCd: string;
    /**
     * アイテム所持個数
     * @type {number}
     * @memberof UserItem
     */
    itemCount: number;
}

/**
 * Check if a given object implements the UserItem interface.
 */
export function instanceOfUserItem(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "itemCd" in value;
    isInstance = isInstance && "itemCount" in value;

    return isInstance;
}

export function UserItemFromJSON(json: any): UserItem {
    return UserItemFromJSONTyped(json, false);
}

export function UserItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'itemCd': json['item_cd'],
        'itemCount': json['item_count'],
    };
}

export function UserItemToJSON(value?: UserItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item_cd': value.itemCd,
        'item_count': value.itemCount,
    };
}

