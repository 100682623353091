import { Md5 } from 'ts-md5';

/**
 * 遅延処理
 * @param time
 */
export const sleep = (time: number) => {
  return new Promise(resolve => setTimeout(resolve, time * 1000));
};

/**
 * post送信
 */
export const postForm = (url: string, params: Record<string, string> = {}, target = '_self') => {
  sendForm('POST', url, params, target);
};

/**
 * form送信
 */
export const sendForm = (method: string, url: string, params: Record<string, string> = {}, target = '_self') => {
  const form = document.createElement('form');
  form.method = method;
  form.action = url;
  if (target) {
    form.target = target;
  }
  for (const k in params) {
    const request = document.createElement('input');
    const val = String(params[k]);
    request.type = 'hidden';
    request.name = k;
    request.value = val;
    form.appendChild(request);
  }
  document.body.appendChild(form);
  form.submit();
};

/**
 * URLにクエリパラメータを付与
 */
export const addUrlQuery = (url: string, params?: Record<string, string>, base?: string) => {
  const requestUrl = new URL(url, base);
  if (params) {
    for (const k in params) {
      requestUrl.searchParams.append(k, params[k]);
    }
  }
  return requestUrl.toString();
};

/**
 * 新しいウィンドウで開く
 * @note iPhoneの場合、clickイベントなどで呼ばないとポップアップがブロックされるので注意
 */
export const openWindow = (url: string, params?: Record<string, string>, target = '_self') => {
  const newWin = window.open(addUrlQuery(url, params), target, 'noreferrer');
  try {
    newWin?.focus();
  } catch (e) {}
  return newWin;
};

export const openNewWindow = (url: string, params?: Record<string, string>) => {
  return openWindow(url, params, '_blank');
};

/**
 * 数値をバイト表示に変換
 *
 * @param byte
 * @param point
 * @param com
 */
export const numberToByteString = (byte: number, point = 0, com = 1024) => {
  const suffix = ['Byte', 'KB', 'MB', 'GB', 'TB', 'PB', 'ZB', 'YB'];
  const target = byte > 0 ? Math.floor(Math.log(byte) / Math.log(com)) : 0;

  return (byte > 0 ? byte / Math.pow(com, Math.floor(target)) : 0).toFixed(point) + ' ' + suffix[target];
};

/**
 * 数値を金額表示に変換
 *
 * @param num
 */
export const numberToPriceString = (num: number) => {
  return `¥${num}`;
};

/**
 * パスを分解
 * @param path
 */
export const parsePath = (path: string) => {
  const paths = path.split('/').reverse();
  const dir = paths.slice(1).reverse().join('/');
  const name = paths[0].split('.')[0];
  const ext = paths[0].split('.')[1];

  return {
    dir,
    name,
    base: `${name}.${ext}`,
    ext,
  };
};

/**
 * 正規表現のエスケープ処理
 * @param str
 */
export const escapeStringRegexp = (str: string) => {
  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a `\xnn` escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return str
    .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    .replace(/-/g, '\\x2d');
};

/**
 * ランダムな文字列を生成
 * @param length
 */
export const randomStr = (length = 16) => {
  const S = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  return Array.from(crypto.getRandomValues(new Uint8Array(length))).map(n => S[n % S.length]).join('');
};

/**
 * 文字列の先頭のみ大文字に変換
 * @param {string} str 対象の文字列
 * @return {string} 変換された文字列を返す
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * MD5ハッシュ変換
 * @param {string} str
 */
export const md5 = (str: string) => {
  return Md5.hashStr(str);
};

/**
 * 現在のドメインをベースにURL取得
 * @param {string} path
 */
export const currentDomainUrl = (path = '') => {
  const url = new URL(document.URL);
  return `${url.protocol}//${url.hostname}${url.port ? ':' + url.port : ''}${path}`;
};

/**
 * 改行をそのまま表示
 * @param str
 */
export const nl2br = (str:string) => {
  str = str.replace(/\r\n/g, '<br />');
  str = str.replace(/(\n|\r)/g, '<br />');
  return str;
};
