/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EventBonus } from './EventBonus';
import {
    EventBonusFromJSON,
    EventBonusFromJSONTyped,
    EventBonusToJSON,
} from './EventBonus';
import type { EventChara } from './EventChara';
import {
    EventCharaFromJSON,
    EventCharaFromJSONTyped,
    EventCharaToJSON,
} from './EventChara';
import type { EventEpilog } from './EventEpilog';
import {
    EventEpilogFromJSON,
    EventEpilogFromJSONTyped,
    EventEpilogToJSON,
} from './EventEpilog';
import type { EventFever } from './EventFever';
import {
    EventFeverFromJSON,
    EventFeverFromJSONTyped,
    EventFeverToJSON,
} from './EventFever';
import type { EventParameterMission } from './EventParameterMission';
import {
    EventParameterMissionFromJSON,
    EventParameterMissionFromJSONTyped,
    EventParameterMissionToJSON,
} from './EventParameterMission';
import type { EventRouteMission } from './EventRouteMission';
import {
    EventRouteMissionFromJSON,
    EventRouteMissionFromJSONTyped,
    EventRouteMissionToJSON,
} from './EventRouteMission';
import type { EventRouteName } from './EventRouteName';
import {
    EventRouteNameFromJSON,
    EventRouteNameFromJSONTyped,
    EventRouteNameToJSON,
} from './EventRouteName';
import type { EventSceneSetting } from './EventSceneSetting';
import {
    EventSceneSettingFromJSON,
    EventSceneSettingFromJSONTyped,
    EventSceneSettingToJSON,
} from './EventSceneSetting';
import type { EventStory } from './EventStory';
import {
    EventStoryFromJSON,
    EventStoryFromJSONTyped,
    EventStoryToJSON,
} from './EventStory';

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * シナイベコード
     * @type {string}
     * @memberof Event
     */
    eventCd: string;
    /**
     * タイトル
     * @type {string}
     * @memberof Event
     */
    title: string;
    /**
     * 開始日時
     * @type {string}
     * @memberof Event
     */
    startedAt: string;
    /**
     * 終了日時
     * @type {string}
     * @memberof Event
     */
    endedAt: string;
    /**
     * セット販売コード
     * @type {string}
     * @memberof Event
     */
    combinedsaleCd: string;
    /**
     * ランキング表示開始日時
     * @type {string}
     * @memberof Event
     */
    rankingStartedAt: string;
    /**
     * ランキング表示終了日時
     * @type {string}
     * @memberof Event
     */
    rankingEndedAt: string;
    /**
     * リソースパーティション
     * @type {number}
     * @memberof Event
     */
    resourcePartition: number;
    /**
     * サンプルアバター画像枚数
     * @type {number}
     * @memberof Event
     */
    sampleAvatarImageCount: number;
    /**
     * エピローグの有無
     * @type {boolean}
     * @memberof Event
     */
    epilogFlg: boolean;
    /**
     * プロローグチケットコード
     * @type {string}
     * @memberof Event
     */
    eventPrologueTicketCd: string;
    /**
     * ルートリスト
     * @type {Array<EventRouteName>}
     * @memberof Event
     */
    routeNameList: Array<EventRouteName>;
    /**
     * フィーバーリスト
     * @type {Array<EventFever>}
     * @memberof Event
     */
    feverList: Array<EventFever>;
    /**
     * 特典リスト
     * @type {Array<EventBonus>}
     * @memberof Event
     */
    bonusList: Array<EventBonus>;
    /**
     * ストーリーリスト
     * @type {Array<EventStory>}
     * @memberof Event
     */
    storyList: Array<EventStory>;
    /**
     * イベントキャラリスト
     * @type {Array<EventChara>}
     * @memberof Event
     */
    eventChara: Array<EventChara>;
    /**
     * エピローグリスト
     * @type {Array<EventEpilog>}
     * @memberof Event
     */
    eventEpilogList: Array<EventEpilog>;
    /**
     * イベントパラメーターミッション
     * @type {Array<EventParameterMission>}
     * @memberof Event
     */
    eventParameterMission: Array<EventParameterMission>;
    /**
     * イベントルートミッション
     * @type {Array<EventRouteMission>}
     * @memberof Event
     */
    eventRouteMission: Array<EventRouteMission>;
    /**
     * シーン設定リスト
     * @type {Array<EventSceneSetting>}
     * @memberof Event
     */
    sceneSettingList: Array<EventSceneSetting>;
}

/**
 * Check if a given object implements the Event interface.
 */
export function instanceOfEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventCd" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "startedAt" in value;
    isInstance = isInstance && "endedAt" in value;
    isInstance = isInstance && "combinedsaleCd" in value;
    isInstance = isInstance && "rankingStartedAt" in value;
    isInstance = isInstance && "rankingEndedAt" in value;
    isInstance = isInstance && "resourcePartition" in value;
    isInstance = isInstance && "sampleAvatarImageCount" in value;
    isInstance = isInstance && "epilogFlg" in value;
    isInstance = isInstance && "eventPrologueTicketCd" in value;
    isInstance = isInstance && "routeNameList" in value;
    isInstance = isInstance && "feverList" in value;
    isInstance = isInstance && "bonusList" in value;
    isInstance = isInstance && "storyList" in value;
    isInstance = isInstance && "eventChara" in value;
    isInstance = isInstance && "eventEpilogList" in value;
    isInstance = isInstance && "eventParameterMission" in value;
    isInstance = isInstance && "eventRouteMission" in value;
    isInstance = isInstance && "sceneSettingList" in value;

    return isInstance;
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventCd': json['event_cd'],
        'title': json['title'],
        'startedAt': json['started_at'],
        'endedAt': json['ended_at'],
        'combinedsaleCd': json['combinedsale_cd'],
        'rankingStartedAt': json['ranking_started_at'],
        'rankingEndedAt': json['ranking_ended_at'],
        'resourcePartition': json['resource_partition'],
        'sampleAvatarImageCount': json['sample_avatar_image_count'],
        'epilogFlg': json['epilog_flg'],
        'eventPrologueTicketCd': json['event_prologue_ticket_cd'],
        'routeNameList': ((json['route_name_list'] as Array<any>).map(EventRouteNameFromJSON)),
        'feverList': ((json['fever_list'] as Array<any>).map(EventFeverFromJSON)),
        'bonusList': ((json['bonus_list'] as Array<any>).map(EventBonusFromJSON)),
        'storyList': ((json['story_list'] as Array<any>).map(EventStoryFromJSON)),
        'eventChara': ((json['event_chara'] as Array<any>).map(EventCharaFromJSON)),
        'eventEpilogList': ((json['event_epilog_list'] as Array<any>).map(EventEpilogFromJSON)),
        'eventParameterMission': ((json['event_parameter_mission'] as Array<any>).map(EventParameterMissionFromJSON)),
        'eventRouteMission': ((json['event_route_mission'] as Array<any>).map(EventRouteMissionFromJSON)),
        'sceneSettingList': ((json['scene_setting_list'] as Array<any>).map(EventSceneSettingFromJSON)),
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_cd': value.eventCd,
        'title': value.title,
        'started_at': value.startedAt,
        'ended_at': value.endedAt,
        'combinedsale_cd': value.combinedsaleCd,
        'ranking_started_at': value.rankingStartedAt,
        'ranking_ended_at': value.rankingEndedAt,
        'resource_partition': value.resourcePartition,
        'sample_avatar_image_count': value.sampleAvatarImageCount,
        'epilog_flg': value.epilogFlg,
        'event_prologue_ticket_cd': value.eventPrologueTicketCd,
        'route_name_list': ((value.routeNameList as Array<any>).map(EventRouteNameToJSON)),
        'fever_list': ((value.feverList as Array<any>).map(EventFeverToJSON)),
        'bonus_list': ((value.bonusList as Array<any>).map(EventBonusToJSON)),
        'story_list': ((value.storyList as Array<any>).map(EventStoryToJSON)),
        'event_chara': ((value.eventChara as Array<any>).map(EventCharaToJSON)),
        'event_epilog_list': ((value.eventEpilogList as Array<any>).map(EventEpilogToJSON)),
        'event_parameter_mission': ((value.eventParameterMission as Array<any>).map(EventParameterMissionToJSON)),
        'event_route_mission': ((value.eventRouteMission as Array<any>).map(EventRouteMissionToJSON)),
        'scene_setting_list': ((value.sceneSettingList as Array<any>).map(EventSceneSettingToJSON)),
    };
}

