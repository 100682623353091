/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPresentDeleteResponseDataReceiveCount
 */
export interface UserPresentDeleteResponseDataReceiveCount {
    /**
     * 増加数：アイテム
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    item?: number;
    /**
     * 増加数：ポイント
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    point?: number;
    /**
     * 増加数：サイト内通貨
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    gold?: number;
    /**
     * 増加数：彼カード
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    card?: number;
    /**
     * 増加数：部屋アバター
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    rav?: number;
    /**
     * 増加数：マイアバター
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    mav?: number;
    /**
     * 増加数：彼アバター
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    pav?: number;
    /**
     * 増加数：クローゼット
     * @type {number}
     * @memberof UserPresentDeleteResponseDataReceiveCount
     */
    closet?: number;
}

/**
 * Check if a given object implements the UserPresentDeleteResponseDataReceiveCount interface.
 */
export function instanceOfUserPresentDeleteResponseDataReceiveCount(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserPresentDeleteResponseDataReceiveCountFromJSON(json: any): UserPresentDeleteResponseDataReceiveCount {
    return UserPresentDeleteResponseDataReceiveCountFromJSONTyped(json, false);
}

export function UserPresentDeleteResponseDataReceiveCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresentDeleteResponseDataReceiveCount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'item': !exists(json, 'item') ? undefined : json['item'],
        'point': !exists(json, 'point') ? undefined : json['point'],
        'gold': !exists(json, 'gold') ? undefined : json['gold'],
        'card': !exists(json, 'card') ? undefined : json['card'],
        'rav': !exists(json, 'rav') ? undefined : json['rav'],
        'mav': !exists(json, 'mav') ? undefined : json['mav'],
        'pav': !exists(json, 'pav') ? undefined : json['pav'],
        'closet': !exists(json, 'closet') ? undefined : json['closet'],
    };
}

export function UserPresentDeleteResponseDataReceiveCountToJSON(value?: UserPresentDeleteResponseDataReceiveCount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'item': value.item,
        'point': value.point,
        'gold': value.gold,
        'card': value.card,
        'rav': value.rav,
        'mav': value.mav,
        'pav': value.pav,
        'closet': value.closet,
    };
}

