/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PanelmissionNotice
 */
export interface PanelmissionNotice {
    /**
     * パネルミッションCD
     * @type {string}
     * @memberof PanelmissionNotice
     */
    panelmissionCd: string;
    /**
     * パネルミッションパネルCD
     * @type {string}
     * @memberof PanelmissionNotice
     */
    panelmissionPanelCd: string;
    /**
     * パネルミッション名
     * @type {string}
     * @memberof PanelmissionNotice
     */
    panelmissionName: string;
    /**
     * パネル名称
     * @type {string}
     * @memberof PanelmissionNotice
     */
    panelName: string;
    /**
     * 目標値
     * @type {number}
     * @memberof PanelmissionNotice
     */
    needCount: number;
    /**
     * 現在値
     * @type {number}
     * @memberof PanelmissionNotice
     */
    achievedCount: number;
}

/**
 * Check if a given object implements the PanelmissionNotice interface.
 */
export function instanceOfPanelmissionNotice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "panelmissionCd" in value;
    isInstance = isInstance && "panelmissionPanelCd" in value;
    isInstance = isInstance && "panelmissionName" in value;
    isInstance = isInstance && "panelName" in value;
    isInstance = isInstance && "needCount" in value;
    isInstance = isInstance && "achievedCount" in value;

    return isInstance;
}

export function PanelmissionNoticeFromJSON(json: any): PanelmissionNotice {
    return PanelmissionNoticeFromJSONTyped(json, false);
}

export function PanelmissionNoticeFromJSONTyped(json: any, ignoreDiscriminator: boolean): PanelmissionNotice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'panelmissionCd': json['panelmission_cd'],
        'panelmissionPanelCd': json['panelmission_panel_cd'],
        'panelmissionName': json['panelmission_name'],
        'panelName': json['panel_name'],
        'needCount': json['need_count'],
        'achievedCount': json['achieved_count'],
    };
}

export function PanelmissionNoticeToJSON(value?: PanelmissionNotice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'panelmission_cd': value.panelmissionCd,
        'panelmission_panel_cd': value.panelmissionPanelCd,
        'panelmission_name': value.panelmissionName,
        'panel_name': value.panelName,
        'need_count': value.needCount,
        'achieved_count': value.achievedCount,
    };
}

