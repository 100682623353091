/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 彼アバターリスト
 * @export
 * @interface EquipHisAvatarList
 */
export interface EquipHisAvatarList {
    /**
     * 選択しているキャラコード
     * @type {number}
     * @memberof EquipHisAvatarList
     */
    avatarCharaCd: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof EquipHisAvatarList
     */
    userAvatarId: Array<number>;
}

/**
 * Check if a given object implements the EquipHisAvatarList interface.
 */
export function instanceOfEquipHisAvatarList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatarCharaCd" in value;
    isInstance = isInstance && "userAvatarId" in value;

    return isInstance;
}

export function EquipHisAvatarListFromJSON(json: any): EquipHisAvatarList {
    return EquipHisAvatarListFromJSONTyped(json, false);
}

export function EquipHisAvatarListFromJSONTyped(json: any, ignoreDiscriminator: boolean): EquipHisAvatarList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatarCharaCd': json['avatar_chara_cd'],
        'userAvatarId': json['user_avatar_id'],
    };
}

export function EquipHisAvatarListToJSON(value?: EquipHisAvatarList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar_chara_cd': value.avatarCharaCd,
        'user_avatar_id': value.userAvatarId,
    };
}

