/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MissionAvatar } from './MissionAvatar';
import {
    MissionAvatarFromJSON,
    MissionAvatarFromJSONTyped,
    MissionAvatarToJSON,
} from './MissionAvatar';
import type { MissionCardAnnounce } from './MissionCardAnnounce';
import {
    MissionCardAnnounceFromJSON,
    MissionCardAnnounceFromJSONTyped,
    MissionCardAnnounceToJSON,
} from './MissionCardAnnounce';
import type { MissionParameter } from './MissionParameter';
import {
    MissionParameterFromJSON,
    MissionParameterFromJSONTyped,
    MissionParameterToJSON,
} from './MissionParameter';
import type { MissionRoute } from './MissionRoute';
import {
    MissionRouteFromJSON,
    MissionRouteFromJSONTyped,
    MissionRouteToJSON,
} from './MissionRoute';

/**
 * 
 * @export
 * @interface Mission
 */
export interface Mission {
    /**
     * アバターミッション
     * @type {Array<MissionAvatar>}
     * @memberof Mission
     */
    avatar: Array<MissionAvatar>;
    /**
     * パラメータミッション
     * @type {Array<MissionParameter>}
     * @memberof Mission
     */
    parameter: Array<MissionParameter>;
    /**
     * カード予告ミッション
     * @type {Array<MissionCardAnnounce>}
     * @memberof Mission
     */
    cardAnnounce: Array<MissionCardAnnounce>;
    /**
     * ルート分岐ミッション
     * @type {Array<MissionRoute>}
     * @memberof Mission
     */
    route: Array<MissionRoute>;
}

/**
 * Check if a given object implements the Mission interface.
 */
export function instanceOfMission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "avatar" in value;
    isInstance = isInstance && "parameter" in value;
    isInstance = isInstance && "cardAnnounce" in value;
    isInstance = isInstance && "route" in value;

    return isInstance;
}

export function MissionFromJSON(json: any): Mission {
    return MissionFromJSONTyped(json, false);
}

export function MissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Mission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar': ((json['avatar'] as Array<any>).map(MissionAvatarFromJSON)),
        'parameter': ((json['parameter'] as Array<any>).map(MissionParameterFromJSON)),
        'cardAnnounce': ((json['card_announce'] as Array<any>).map(MissionCardAnnounceFromJSON)),
        'route': ((json['route'] as Array<any>).map(MissionRouteFromJSON)),
    };
}

export function MissionToJSON(value?: Mission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar': ((value.avatar as Array<any>).map(MissionAvatarToJSON)),
        'parameter': ((value.parameter as Array<any>).map(MissionParameterToJSON)),
        'card_announce': ((value.cardAnnounce as Array<any>).map(MissionCardAnnounceToJSON)),
        'route': ((value.route as Array<any>).map(MissionRouteToJSON)),
    };
}

