import { UserStatusMin, UserStatus } from 'api';
import { useNuxtApp } from '#app';
import { isMobileApp } from '~/libs/platform';
import { useWallet } from '~/composables/useWallet';
import { useApi } from '~/composables/useApi';
import { useTutorial } from '~/composables/useTutorial';
import { getBugsnagDeviceId } from '~/libs/bugsnag';

// キャッシュする時間
const cacheTime = 60000;
// 前回のキャッシュ時間(ms)
let cacheSetTime = 0;

interface UpdateOption {
  cache: boolean,
}
const update = async (options?: UpdateOption) => {
  // キャッシュ有効な場合はリクエストさせない
  if (options?.cache && useUser().user.value && (cacheSetTime + cacheTime) >= Date.now()) {
    return useUser().user.value;
  }
  const user = (await useApi().user.getUserStatusmin()).data;
  useUser().user.value = user;
  useUser().playerCode.value = user.playerCode;
  useWallet().set({
    gold: user.gold,
    freeGem: user.freeGem,
    purchasedGem: user.purchasedGem,
  });
  cacheSetTime = Date.now();
  return user;
};

// 前回のキャッシュ時間(ms)
let cacheStatusSetTime = 0;
const updateStatus = async (options?: UpdateOption) => {
  // キャッシュ有効な場合はリクエストさせない
  if (options?.cache && useUser().userStatus.value && (cacheStatusSetTime + cacheTime) >= Date.now()) {
    return useUser().userStatus.value;
  }
  const status = (await useApi().user.getUserStatus()).data;
  useUser().userStatus.value = status;
  cacheStatusSetTime = Date.now();
  return status;
};

/**
 * ゲーム開始APIを実行
 */
const gameStart = async () => {
  // NOTE: title画面で実行したいがplayerCodeを取得するために認証後にユーザーを作成している
  const bugsnagDeviceId = getBugsnagDeviceId();
  const res = await useApi().user.postUserGamestart({
    userGameStartRequest: {
      bugsnagDeviceId,
    },
  });
  const playerCode = res.data.playerCode;
  const tutorialCd = res.data.tutorialCd;
  useUser().playerCode.value = playerCode;
  useTutorial().set(tutorialCd);
  useGameToken().value = res.data.gameToken || '';
  await useGameTokenSync();
  // プレイヤーコードをアプリに送信
  if (isMobileApp()) {
    const { $nativeBridge } = useNuxtApp();
    await $nativeBridge.send('playerCode', {
      code: playerCode,
    });
  }
};

/**
 * データのリセット
 */
const reset = () => {
  // useUser().user.value = undefined;
  useUser().playerCode.value = '';
};

/**
 * 画面間で共通で使用する基本ユーザーデータを保存しておく
 */
export const useUser = () => {
  const user = useState<UserStatusMin>('user');
  const userStatus = useState<UserStatus>('userStatus');
  const playerCode = useState<string>('userPlayerCode');
  return {
    user,
    userStatus,
    playerCode,
    update,
    updateStatus,
    reset,
    gameStart,
  };
};
