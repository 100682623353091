/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserPresentReceiveResponseData } from './UserPresentReceiveResponseData';
import {
    UserPresentReceiveResponseDataFromJSON,
    UserPresentReceiveResponseDataFromJSONTyped,
    UserPresentReceiveResponseDataToJSON,
} from './UserPresentReceiveResponseData';

/**
 * 
 * @export
 * @interface UserPresentReceiveResponse
 */
export interface UserPresentReceiveResponse {
    /**
     * 
     * @type {UserPresentReceiveResponseData}
     * @memberof UserPresentReceiveResponse
     */
    data: UserPresentReceiveResponseData;
}

/**
 * Check if a given object implements the UserPresentReceiveResponse interface.
 */
export function instanceOfUserPresentReceiveResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;

    return isInstance;
}

export function UserPresentReceiveResponseFromJSON(json: any): UserPresentReceiveResponse {
    return UserPresentReceiveResponseFromJSONTyped(json, false);
}

export function UserPresentReceiveResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPresentReceiveResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': UserPresentReceiveResponseDataFromJSON(json['data']),
    };
}

export function UserPresentReceiveResponseToJSON(value?: UserPresentReceiveResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': UserPresentReceiveResponseDataToJSON(value.data),
    };
}

