/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 発生しうるミッションの情報 前提として1チャプター1ミッション
 * @export
 * @interface StoryMission
 */
export interface StoryMission {
    /**
     * １:ミッション有り 0：無し
     * @type {boolean}
     * @memberof StoryMission
     */
    visible: boolean;
    /**
     * avatar：アバターミッション parameter：親密度ミッション card_announce：カード予告ミッション route：ルート分岐ミッション event_choice_route：イベントルート分岐ミッション event_parameter：イベントパラメータミッション
     * @type {string}
     * @memberof StoryMission
     */
    type: StoryMissionTypeEnum;
    /**
     * ミッションコード、ない場合は空文字
     * @type {string}
     * @memberof StoryMission
     */
    missionCd: string;
    /**
     * ミッションが発生するチケット、ない場合は空文字
     * @type {string}
     * @memberof StoryMission
     */
    storyTicketCd: string;
}


/**
 * @export
 */
export const StoryMissionTypeEnum = {
    Avatar: 'avatar',
    Parameter: 'parameter',
    CardAnnounce: 'card_announce',
    Route: 'route',
    EventChoiceRoute: 'event_choice_route',
    EventParameter: 'event_parameter'
} as const;
export type StoryMissionTypeEnum = typeof StoryMissionTypeEnum[keyof typeof StoryMissionTypeEnum];


/**
 * Check if a given object implements the StoryMission interface.
 */
export function instanceOfStoryMission(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "visible" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "missionCd" in value;
    isInstance = isInstance && "storyTicketCd" in value;

    return isInstance;
}

export function StoryMissionFromJSON(json: any): StoryMission {
    return StoryMissionFromJSONTyped(json, false);
}

export function StoryMissionFromJSONTyped(json: any, ignoreDiscriminator: boolean): StoryMission {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'visible': json['visible'],
        'type': json['type'],
        'missionCd': json['mission_cd'],
        'storyTicketCd': json['story_ticket_cd'],
    };
}

export function StoryMissionToJSON(value?: StoryMission | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'visible': value.visible,
        'type': value.type,
        'mission_cd': value.missionCd,
        'story_ticket_cd': value.storyTicketCd,
    };
}

