<template>
  <canvas ref="canvas" class="w-full h-full" />
</template>

<script lang="ts" setup>

interface Props {
  x: number, // 描画開始位置X
  y: number, // 描画開始位置Y
  width: number, // 描画サイズ横幅
  height: number, // 描画サイズ縦幅
  color: string, // 描画色
}

const props = defineProps<Props>();
const canvas = ref<HTMLCanvasElement>();

watch(() => canvas.value, () => {
  if (canvas.value) {
    canvas.value.setAttribute('width', props.width.toString());
    canvas.value.setAttribute('height', props.height.toString());

    const ctx = canvas.value.getContext('2d');
    if (ctx) {
      ctx.fillStyle = props.color;
      ctx.fillRect(props.x, props.y, props.width, props.height);
    }
  }
});

</script>
