/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * イベントシーンの種類
 * @export
 */
export const EventSceneType = {
    Mypage: 'mypage',
    Result: 'result',
    Itemuse: 'itemuse'
} as const;
export type EventSceneType = typeof EventSceneType[keyof typeof EventSceneType];


export function EventSceneTypeFromJSON(json: any): EventSceneType {
    return EventSceneTypeFromJSONTyped(json, false);
}

export function EventSceneTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventSceneType {
    return json as EventSceneType;
}

export function EventSceneTypeToJSON(value?: EventSceneType | null): any {
    return value as any;
}

