/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserEventClearRoute
 */
export interface UserEventClearRoute {
    /**
     * イベントキャラコード
     * @type {string}
     * @memberof UserEventClearRoute
     */
    eventCharaCd: string;
    /**
     * イベントルートコード
     * @type {string}
     * @memberof UserEventClearRoute
     */
    eventRouteCd: string;
    /**
     * aルートクリア
     * @type {boolean}
     * @memberof UserEventClearRoute
     */
    clearAFlg: boolean;
    /**
     * bルートクリア
     * @type {boolean}
     * @memberof UserEventClearRoute
     */
    clearBFlg: boolean;
    /**
     * cルートクリア
     * @type {boolean}
     * @memberof UserEventClearRoute
     */
    clearCFlg: boolean;
}

/**
 * Check if a given object implements the UserEventClearRoute interface.
 */
export function instanceOfUserEventClearRoute(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventCharaCd" in value;
    isInstance = isInstance && "eventRouteCd" in value;
    isInstance = isInstance && "clearAFlg" in value;
    isInstance = isInstance && "clearBFlg" in value;
    isInstance = isInstance && "clearCFlg" in value;

    return isInstance;
}

export function UserEventClearRouteFromJSON(json: any): UserEventClearRoute {
    return UserEventClearRouteFromJSONTyped(json, false);
}

export function UserEventClearRouteFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserEventClearRoute {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventCharaCd': json['event_chara_cd'],
        'eventRouteCd': json['event_route_cd'],
        'clearAFlg': json['clear_a_flg'],
        'clearBFlg': json['clear_b_flg'],
        'clearCFlg': json['clear_c_flg'],
    };
}

export function UserEventClearRouteToJSON(value?: UserEventClearRoute | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'event_chara_cd': value.eventCharaCd,
        'event_route_cd': value.eventRouteCd,
        'clear_a_flg': value.clearAFlg,
        'clear_b_flg': value.clearBFlg,
        'clear_c_flg': value.clearCFlg,
    };
}

