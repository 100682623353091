/* tslint:disable */
/* eslint-disable */
/**
 * モルガナティック・アイドルAPI
 * モルガナティック・アイドル用API定義
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserFriendListLeaderCardInfo } from './UserFriendListLeaderCardInfo';
import {
    UserFriendListLeaderCardInfoFromJSON,
    UserFriendListLeaderCardInfoFromJSONTyped,
    UserFriendListLeaderCardInfoToJSON,
} from './UserFriendListLeaderCardInfo';

/**
 * 
 * @export
 * @interface UserFriendList
 */
export interface UserFriendList {
    /**
     * 名前(ニックネーム)
     * @type {string}
     * @memberof UserFriendList
     */
    nickname: string;
    /**
     * 自己紹介文言
     * @type {string}
     * @memberof UserFriendList
     */
    introduction: string;
    /**
     * プレイヤーコード
     * @type {string}
     * @memberof UserFriendList
     */
    playerCode: string;
    /**
     * アバターのサムネイルURL
     * @type {string}
     * @memberof UserFriendList
     */
    avatarThumbnailUrl?: string;
    /**
     * 攻略対象キャラコード
     * @type {string}
     * @memberof UserFriendList
     */
    charaCd: string;
    /**
     * アバターポイント
     * @type {number}
     * @memberof UserFriendList
     */
    avatarP: number;
    /**
     * 初回ログイン日時
     * @type {string}
     * @memberof UserFriendList
     */
    firstLogin: string;
    /**
     * 最終ログイン日時
     * @type {string}
     * @memberof UserFriendList
     */
    lastLogin: string;
    /**
     * 部隊のトータルパラメータ
     * @type {number}
     * @memberof UserFriendList
     */
    deckP: number;
    /**
     * フォローフラグ 1:フォロー済み
     * @type {boolean}
     * @memberof UserFriendList
     */
    followFlg: boolean;
    /**
     * フォロワーフラグ 1:フォローされている
     * @type {boolean}
     * @memberof UserFriendList
     */
    followerFlg: boolean;
    /**
     * いいねした日時
     * @type {string}
     * @memberof UserFriendList
     */
    sendLike: string;
    /**
     * いいね送信済みフラグ 1:送信済み
     * @type {boolean}
     * @memberof UserFriendList
     */
    sendLikeFlg: boolean;
    /**
     * いいねされた日時
     * @type {string}
     * @memberof UserFriendList
     */
    receivedLike: string;
    /**
     * いままでのいいね受信総数
     * @type {number}
     * @memberof UserFriendList
     */
    totalReceivedLikeCount: number;
    /**
     * 
     * @type {UserFriendListLeaderCardInfo}
     * @memberof UserFriendList
     */
    leaderCardInfo: UserFriendListLeaderCardInfo;
}

/**
 * Check if a given object implements the UserFriendList interface.
 */
export function instanceOfUserFriendList(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "nickname" in value;
    isInstance = isInstance && "introduction" in value;
    isInstance = isInstance && "playerCode" in value;
    isInstance = isInstance && "charaCd" in value;
    isInstance = isInstance && "avatarP" in value;
    isInstance = isInstance && "firstLogin" in value;
    isInstance = isInstance && "lastLogin" in value;
    isInstance = isInstance && "deckP" in value;
    isInstance = isInstance && "followFlg" in value;
    isInstance = isInstance && "followerFlg" in value;
    isInstance = isInstance && "sendLike" in value;
    isInstance = isInstance && "sendLikeFlg" in value;
    isInstance = isInstance && "receivedLike" in value;
    isInstance = isInstance && "totalReceivedLikeCount" in value;
    isInstance = isInstance && "leaderCardInfo" in value;

    return isInstance;
}

export function UserFriendListFromJSON(json: any): UserFriendList {
    return UserFriendListFromJSONTyped(json, false);
}

export function UserFriendListFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFriendList {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nickname': json['nickname'],
        'introduction': json['introduction'],
        'playerCode': json['player_code'],
        'avatarThumbnailUrl': !exists(json, 'avatar_thumbnail_url') ? undefined : json['avatar_thumbnail_url'],
        'charaCd': json['chara_cd'],
        'avatarP': json['avatar_p'],
        'firstLogin': json['first_login'],
        'lastLogin': json['last_login'],
        'deckP': json['deck_p'],
        'followFlg': json['follow_flg'],
        'followerFlg': json['follower_flg'],
        'sendLike': json['send_like'],
        'sendLikeFlg': json['send_like_flg'],
        'receivedLike': json['received_like'],
        'totalReceivedLikeCount': json['total_received_like_count'],
        'leaderCardInfo': UserFriendListLeaderCardInfoFromJSON(json['leader_card_info']),
    };
}

export function UserFriendListToJSON(value?: UserFriendList | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nickname': value.nickname,
        'introduction': value.introduction,
        'player_code': value.playerCode,
        'avatar_thumbnail_url': value.avatarThumbnailUrl,
        'chara_cd': value.charaCd,
        'avatar_p': value.avatarP,
        'first_login': value.firstLogin,
        'last_login': value.lastLogin,
        'deck_p': value.deckP,
        'follow_flg': value.followFlg,
        'follower_flg': value.followerFlg,
        'send_like': value.sendLike,
        'send_like_flg': value.sendLikeFlg,
        'received_like': value.receivedLike,
        'total_received_like_count': value.totalReceivedLikeCount,
        'leader_card_info': UserFriendListLeaderCardInfoToJSON(value.leaderCardInfo),
    };
}

